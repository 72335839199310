import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';

const Partners = inject("stores") (
    observer (
        class Partners extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeAuth = this.props.stores.storeAuth;
            }

            componentDidMount() {
                window.scrollTo(0, 0);
            }

            componentDidUpdate() {
                window.scrollTo(0, 0);
            }
      
            render() {
                return (
                    <Template
                        type = 'default'
                        header = "partnership"
                        nav_name = {"partners"}
                    >
                        <div className="content-segment">
                            <ContentText
                                page = {"partnership"}
                            />
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Aanvraagformulier</h2>
                            </div>
                            {!this.storeAuth.user.is_partner
                            ?   <div className="content-segment__content">
                                    <p>Klik <Link to="partner-registration">hier</Link> om het partnerformulier in te vullen.</p>
                                </div>
                            :   <div className="content-segment__content">
                                    <p>Je bent reeds als partner geregistreerd.</p>
                                </div>
                            }
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Partners;
