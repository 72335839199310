import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { Link } from 'react-router-dom';

import ImgCover from '../../elements/img-cover';
import GeneratePartnerLink from '../../components/forms/form-generate-partnerlink';

import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
} from 'react-share';

const ContentDialog = inject("stores") (
    observer (
        class ContentDialog extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            render() {
                return (
                    this.props.children
                )
            }
        }
    )
)

const AccountTechnical = inject("stores") (
    observer (
        class AccountTechnical extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.urlAPP = process.env.REACT_APP_URL;
                //this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlAPI = "https://www.succesboeken.nl/api";
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    foldout: false,
                    shop: this.storeGeneral.shop,
                    url: 'www.succesboeken.nl'
                }
            }

            componentDidMount = () => {
                let baseUrlCovers;
                let baseExt;
                if (this.storeBooks.bookList_sb.length === 0) {
                    this.storeBooks.getSbPublications('current');
                }

                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    //if (this.storeGeneral.shop === 'SHOP_ALL') {
                        baseUrlCovers = '/public/images/CoversVKg-all/';
                        baseExt = 'png';
                    // } else {
                    //     baseUrlCovers = '/public/images/CoversVKg/';
                    //     baseExt = 'jpg';
                    // }
                } else {
                    baseUrlCovers = '/public/images/CoversVKg/';
                    baseExt = 'jpg';
                }

                this.setState({
                    baseUrlCovers: baseUrlCovers,
                    baseExt: baseExt
                })
            }

            expandBlocks = (e) => {
                this.setState(prevState => ({
                    foldout: !prevState.foldout
                }));
            }

            errorPartnerCode = () => {
                return (
                    <div className="content-segment">
                        <div className="content-segment__content">
                            <div className="form__feedback--error">
                                LET OP: er is een fout opgetreden, hierdoor kloppen de hieronder gegenereerde links nog niet.<br/>
                                Neem contact met ons op via dit <Link to="/contact">formulier</Link> zodat we dit kunnen herstellen.
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                )
            }

            generatePartnerLink = async(sku) => {
                let title = '';
                let shop;
                
                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    shop = 'SHOP_ALL';
                } else {
                    shop = 'SHOP_SB';
                }

                if (sku !== 0) {
                    const book = await this.storeBooks.getBookBySku(sku, shop);
                    if (book.stock > 0) {
                        title = book.title;
                        this.openDialogCode(sku, title, false);
                    } else {
                        // this.storeUi.setContentComponent(
                        //     <ContentDialog>
                        //         <div>DIT BOEK IS MOMENTEEL NIET VERKRIJGBAAR</div>
                        //     </ContentDialog>
                        // )
                        // this.storeUi.openDialogContent();
                        title = book.title;
                        this.openDialogCode(sku, title, true);
                    }
                } else {
                    title = "Succesboeken.nl";
                    this.openDialogCode(sku, title, false);
                }
            }

            openDialogCode = (sku, title, alert) => {
                let url_shop = "www.succesboeken.nl";
                let url = (sku === 0)  ? ("https://" + url_shop + "/" + "?pc=" + this.storeUser.user_data.partner_code) : ("https://" + url_shop + "/book/" + sku + "?pc=" + this.storeUser.user_data.partner_code);

                this.storeUi.setContentComponent(
                    <ContentDialog
                        className = "content-scrollable"
                    >
                        <div className="content-manual">
                            <h2>Code om te kopieren</h2>
                            {this.storeUser.user_data.partner_code === null &&
                                this.errorPartnerCode()
                            }
                            {alert &&
                                <div className="content-manual__alert">Dit boek is momenteel niet leverbaar. Er kunnen dus geen bestellingen van plaatsvinden.</div>
                            }
                            <p>Kopieer de hieronder aangegeven code en plak deze in de code van je eigen website of social media. Je kunt kiezen uit een link met daarin de titel van het boek of een link met daarin de cover van het gekozen boek:</p>
                            
                            <div className="content-segment__content">
                                <p className="p--emphasis">
                                    <strong>Let op:</strong>Indien je een Content Management Systeem (CMS) gebruikt om je website te beheren is het vaak voldoende om alleen de url te gebruiken. Deze vind je onderaan deze pagina bij: "Direct webadres doorgeven". Kijk in de handleiding van je CMS om te zien hoe je dit toepast.
                                </p>
                            </div>

                            <h3>Link met titel</h3>
                            <p className="content-manual__subtitle">Gebruik onderstaande code voor een link met alleen tekst:</p>
                            <span className="content-manual__code-wrapper">
                                {sku === 0
                                ?   <div className="content-manual__code">
                                        {"<a href='https://" + url_shop + "/" + "?pc=" + this.storeUser.user_data.partner_code + "' target='_blank'>" +
                                            title +
                                        "</a>"}
                                    </div>
                                :   <div className="content-manual__code">
                                        {"<a href='https://" + url_shop + "/book/" + sku + "?pc=" + this.storeUser.user_data.partner_code + "' target='_blank'>" +
                                            title +
                                        "</a>"}
                                    </div>
                                }
                            </span>
                            <p className="content-manual__subtitle">Resultaat:</p>
                            <a href={url} target='_blank'>{title}</a>
                            
                            <h3>Link met cover</h3>
                            <p className="content-manual__subtitle">Gebruik onderstaande code om de cover in je link te tonen:</p>
                            <span className="content-manual__code-wrapper">
                                {sku === 0
                                ?   <div className="content-manual__code">
                                        {"<a href='https://" + url_shop + "/" + "?pc=" + this.storeUser.user_data.partner_code + "' target='_blank'>" +
                                            "<img src='" + this.urlAPI + "/public/images/branding/sb_logo.png' style='border: solid 1px #CCC'>" +
                                        "</a>"}
                                    </div>
                                :   <div className="content-manual__code">
                                        {"<a href='https://" + url_shop + "/book/" + sku + "?pc=" + this.storeUser.user_data.partner_code + "' target='_blank'>" +
                                            "<img src='" + this.urlAPI + this.state.baseUrlCovers + sku + "." + this.state.baseExt + "' style='border: solid 1px #CCC'>" +
                                        "</a>"}
                                    </div>
                                }
                            </span>
                            <p className="content-manual__subtitle">Resultaat:</p>
                            <a href={url} target='_blank'>
                                <img src={this.urlAPI + this.state.baseUrlCovers + sku + "."+ this.state.baseExt} className="content-manual__code-result" />
                            </a>

                            <h3>Direct webadres doorgeven</h3>
                            <p className="content-manual__subtitle">Gebruik onderstaande code indien je het webadres van het boek wilt doorgeven. Dit webadres kan direct in je browser ingevuld worden:</p>
                            <span className="content-manual__code-wrapper">
                                {sku === 0
                                ?   <div className="content-manual__code">
                                        {"https://" + url_shop + "/" + "?pc=" + this.storeUser.user_data.partner_code}
                                    </div>
                                :   <div className="content-manual__code">
                                        {"https://" + url_shop + "/book/" + sku + "?pc=" + this.storeUser.user_data.partner_code}
                                    </div>
                                }
                            </span>
                        </div>
                    </ContentDialog>
                )
                this.storeUi.openDialogContent();
            }

            openDialogCodeLogo = (version) => {
                let url_shop = "www.succesboeken.nl";
                                
                this.storeUi.setContentComponent(
                    <div className="content-manual">
                        {this.storeUser.user_data.partner_code === null &&
                            this.errorPartnerCode()
                        }
                        <h2>Link</h2>
                        <p>Kopieer de hieronder aangegeven code en plak deze in je website of social media:</p>
                        <span className="content-manual__code-wrapper">
                            {version == "full" &&
                                <div className="content-manual__code">
                                    {"<a href='https://" + url_shop + "/" + "?pc=" + this.storeUser.user_data.partner_code + "' target='_blank'>" +
                                        "<img src='" + this.urlAPI + "/public/images/branding/sb_logo-full.png' border='0'>" +
                                    "</a>"}
                                </div>
                            }
                            {version == "img" &&
                                <div className="content-manual__code">
                                    {"<a href='https://" + url_shop + "/" + "?pc=" + this.storeUser.user_data.partner_code + "' target='_blank'>" +
                                        "<img src='" + this.urlAPI + "/public/images/branding/sb_logo-img.png' border='0'>" +
                                    "</a>"}
                                </div>
                            }
                            {version == "text" &&
                                <div className="content-manual__code">
                                    {"<a href='https://" + url_shop + "/" + "?pc=" + this.storeUser.user_data.partner_code + "' target='_blank'>" +
                                        "<img src='" + this.urlAPI + "/public/images/branding/sb_logo-text.png' border='0'>" +
                                    "</a>"}
                                </div>
                            }
                        </span>
                    </div>
                );
                this.storeUi.openDialogContent();
            }

            render() {
                let aspectClass;
              
                return (
                    <React.Fragment>
                        {this.storeUser.user_data.partner_code === null &&
                            this.errorPartnerCode()
                        }
                        
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p>Hieronder wordt toegelicht hoe je een boek kunt promoten via verschillende social media of via je eigen website.</p>
                                <p>Kijk hieronder welke methode voor jou bruikbaar is.</p>
                                <p className="p--emphasis">
                                    <strong>Let op:</strong>Het kan zijn dat er nog een aanpassing nodig is voor jouw specifieke systeem of Content Management Systeem. Voor hulp verwijzen we je naar de online beschikbare handleidingen of de ontwikkelaar van je site. Door alle verschillende manieren en systemen om content op je website te plaatsen, is het onmogelijk om ze hier allemaal te benoemen en toe te lichten.
                                </p>
                                <p>Natuurlijk kun je voor vragen contact met ons opnemen via dit <Link to="/contact">formulier</Link>, of door direct een email te sturen aan <a href="mailto:info@succesboeken.nl?subject=Vraag over gebruik partnercode">info@succesboeken.nl</a>.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <h2>Hoe promoot ik een boek?</h2>
                                <p>Je kunt een boek eenvoudig promoten door het delen van een link naar het boek via je eigen site of via social media. Deze link bevat het isbn-nummer van het boek en je partnercode. Hierdoor weten wij bij een bestelling dat deze via jouw link is binnengekomen.</p> 
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <h2>Hoe krijg ik deze link?</h2>
                                <p>Als je een boek wilt promoten via social media kun je dit het eenvoudigst doen via de buttons op de detailpagina van een boek. Zodra je ingelogd bent wordt dan namelijk je partnercode met deze link meegestuurd.</p>
                                <p>Je kunt de code voor de gewenste link ook zelf maken via de hieronder aangegeven werkwijze of door de betreffende aangegeven code te kopieren. Daarna kun je deze code overnemen op de gewenste plek van je eigen website of social media (gebruik voor social media alleen de methode bij 'Link met titel', die getoond wordt in de popup bij het genereren van de link via &eacute;&eacute;n van onderstaande methodes).</p> 
                                <p>Indien je een van onze eigen boeken wilt promoten hoef je hieronder alleen maar op de desbetreffende cover te klikken, waarna de link direct voor jou gegenereerd wordt.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <h2>Kopieer de code voor de boeken van de eigen uitgeverij:</h2>
                                <p>Klik op een cover voor de code:</p>
                                <ul className={"cover--previews " + (this.state.foldout ? "--expanded" : "--collapsed")}>
                                    {this.storeBooks.bookList_sb.map((book) => (
                                        <li className="cover--previews__cover --link" onClick={() => this.openDialogCode(book.sku, book.title, false)}>
                                            <ImgCover
                                                sku = {book.sku}
                                                aspect = {aspectClass}
                                            />
                                        </li>
                                    ))}
                                    <li className="actionShowMore" onClick={(e) => this.expandBlocks(e)}>
                                        {this.state.foldout 
                                            ?   i18n.t("lists.show_less_covers")
                                            :   i18n.t("lists.show_more_covers")
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="content-segment">
                            <div className="content-segment__content content-manual">
                                <h2>Link naar onze website</h2>
                                <p>Je kunt een van onderstaande logo's gebruiken om op te nemen op je website. Klik op het logo om de code voor de link te genereren. Je bezoekers worden dan naar de homepage van onze site geleid en zodra zij een bestelling plaatsen, wordt je partnercode meegestuurd.</p>
                                <div className=" content-manual__examples">
                                    <ul>
                                        <li onClick={() => this.openDialogCodeLogo("full")}>
                                            <div className="example--logo --link">
                                                <img src={this.urlAPI + "/public/images/branding/sb_logo-full.png"} width="200px" />
                                            </div>
                                        </li>
                                        <li onClick={() => this.openDialogCodeLogo("img")}>
                                            <div className="example--logo --link">
                                                <img src={this.urlAPI + "/public/images/branding/sb_logo-img.png"} width="50px" />
                                            </div>
                                        </li>
                                        <li onClick={() => this.openDialogCodeLogo("text")}>
                                            <div className="example--logo --link">
                                                <img src={this.urlAPI + "/public/images/branding/sb_logo-text.png"} width="200px" />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <h2>Zelf de code voor een link maken</h2>
                                <p>Volg de volgende procedure indien je de code zelf wilt maken of indien je een boek dat hierboven niet in de lijst met boeken van de eigen uitgeverij staat, wilt promoten.</p> 
                                <ul className="list--bullets">
                                    <li>Zoek het ISBN-nummer op. Je kunt dit vinden op de detailpagina van het boek onder 'Specificaties'. Let op of het boek nog leverbaar is.</li>
                                    <li>Vul dit nummer hieronder in en klik op 'Genereer code'.</li>
                                    <li>Kopieer de gewenste versie voor de link en gebruik deze op je website of in social media (gebruik voor social media alleen de methode bij 'Link met titel', die getoond wordt in de popup bij het genereren van de link via onderstaande methode)</li>
                                </ul>
                                <div className="form-fieldset">
                                    <GeneratePartnerLink
                                        generatePartnerLink = {this.generatePartnerLink}  
                                        link_sb = {false}                 
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <h2>Hoe promoot ik een boek via social media zoals Facebook, LinkedIn etc?</h2>
                                <p>Het eenvoudigst is om een boek te delen door gebruik te maken van één van de social-mediabuttons op de detailpagina van een boek:</p>
                                <ul className="list list--icons">
                                    <li><FacebookIcon size={'1.75rem'} round={true}/></li>
                                    <li><LinkedinIcon size={'1.75rem'} round={true}/></li>
                                    <li><TwitterIcon size={'1.75rem'} round={true}/></li>
                                    <li><WhatsappIcon size={'1.75rem'} round={true}/></li>
                                    {/* <li><RedditIcon size={'1.75rem'} round={true}/></li> */}
                                    <li><TelegramIcon size={'1.75rem'} round={true}/></li>
                                    {/* <li><TumblrIcon size={'1.75rem'} round={true}/></li> */}
                                </ul>
                                <p>Dan wordt er een bericht op je tijdlijn geplaatst met daarin direct een link naar het boek met daarbij gebuik van je partnercode.</p>
                                
                                <p>Als partner kan je ook een boek via social media promoten door bovenstaande uitleg voor het zelf maken van een link te gebruiken.</p>
                                <p>Gebruik alleen de methode voor 'Link met titel' (die getoond wordt in de popup bij het genereren van de link via &eacute;&eacute;n van bovenstaande methodes) in een bericht op het betreffende social media platform.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <h2>Hoe promoot ik een boek via een Content Management Systeem als WordPress, Drupal of Joomla?</h2>
                                <p>Voor gebruikers van WordPress, Drupal of Joomla</p>
                                <p>In een Content Management Systeem kun je eenvoudig een link toevoegen, eventueel met plaatje. Bij het maken van een bericht in de editor-omgeving kun je de code voor de link plaatsen. Daar de exacte manier verschilt per Content Management Systeem verwijzen we hiervoor naar de specifieke handleidingen die hiervoor beschikbaar zijn en je eenvoudig op internet kunt vinden.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <h2>Hoe promoot ik een boek via mijn website?</h2>
                                <p>Ook hiervoor gebruik je de code die je hierboven kunt genereren. Afhankelijk van de gebruikte programmertaal plaats je de code op de pagina.</p>
                                <p>De ontwikkelaar van je site kan je hier zeker mee helpen.</p>
                            </div>
                         </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default AccountTechnical;


