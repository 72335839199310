import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import BlockProductGroup from './block-product-group';

const BlockProductGroups = inject("stores") (
    observer (
        class BlockProductGroups extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeProducts = this.props.stores.storeProducts;
            }

            handleOnClickGroup = (e, product) => {
                this.storeUi.setPageView('page', 1);
                this.storeUi.setActiveProductGroup(product.query_value);
                this.storeUi.setActiveSubGroup('');
                this.storeUi.setActiveLanguage('');
                this.storeUi.setActiveCategory(product.name);
                this.storeUi.setActiveSubCategory('');
                this.storeUi.setNavMobile(false);

                let url = "/products/" + product.name;
                this.props.history.push(url);
            }
  
            render() {
                const activeClass = (this.storeUi.active_product_group !== '' && this.storeUi.active_product_group !== 99) ? ' --active' : '' 

                return (
                    <div className="block block--sidebar block--product-groups">
                        {!this.props.clean &&
                            <div className="block__block-title">
                                {i18n.t("block.product_groups.header")}
                            </div> 
                        }
                        <ul className={"block__block-list" + activeClass}>
                            {this.storeProducts.media_list && this.storeProducts.media_list.map((product, i) => ( 
                                <li key={i}>
                                    <span 
                                        className={"block--categories__cat" + (product.name == this.storeUi.app_interface.active_product_group ? ' --active' : '')} 
                                        onClick={(e)=>this.handleOnClickGroup(e, product)}
                                        dangerouslySetInnerHTML={{ __html: product.name}}
                                    />
                                    <BlockProductGroup 
                                        key={i}
                                        product_group = {product}
                                        product_group_name = {product.name}
                                        active_group = {this.storeUi.active_product_group}
                                    /> 
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockProductGroups));

