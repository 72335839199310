import { observable, computed, action, decorate } from "mobx";

import StoreGeneral from './store-general';
import StoreUi from './store-ui';
import StoreAuth from './store-auth';
import StoreHistory from './store-history';
import StoreUser from './store-user';
import StoreBooks from './store-books';
import StoreBooksAll from './store-books-all';
import StoreContent from './store-content';
import StoreProducts from './store-products';
import StoreEBooks from './store-ebooks';
import StoreForms from './store-forms';
import StoreOrder from './store-order';
import StoreCart from './store-cart';
import StoreWishlist from './store-wishlist';
import StoreDiscounts from './store-discounts';
import StoreCatalog from './store-catalog';
import StoreCatalogs from './store-catalogs';
import StoreShipping from'./store-shipping';
import StoreStock from'./store-stock';
import StoreEvents from'./store-events';
import StorePartners from'./store-partners';

class StoreRoot {
    constructor() {
        this.storeGeneral = new StoreGeneral(this);
        this.storeAuth = new StoreAuth(this);
        this.storeHistory = new StoreHistory(this);
        this.storeUi = new StoreUi(this);
        this.storeContent = new StoreContent(this);
        this.storePartners = new StorePartners(this);
        this.storeUser = new StoreUser(this);
        this.storeBooks = new StoreBooks(this);
        this.storeBooksAll = new StoreBooksAll(this);
        this.storeProducts = new StoreProducts(this);
        this.storeEBooks = new StoreEBooks(this);
        this.storeForms = new StoreForms(this);
        this.storeOrder = new StoreOrder(this);
        this.storeShipping = new StoreShipping(this);
        this.storeCart = new StoreCart(this);
        this.storeWishlist = new StoreWishlist(this);
        this.storeDiscounts = new StoreDiscounts(this);
        this.storeCatalog = new StoreCatalog(this);
        this.storeCatalogs = new StoreCatalogs(this);
        this.storeStock = new StoreStock(this);
        this.storeEvents = new StoreEvents(this);
        
    }
}

export default StoreRoot;