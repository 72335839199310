import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";
import moment from "moment";

import * as account from '../communicator/account';
import * as tools from '../communicator/tools';
import * as login from '../communicator/login';
import * as sort from '../utilities/sort';
import * as ui from '../utilities/ui';

function autoSave(store) {
    mobx.autorun(() => {
        const json = JSON.stringify(store.user_data);
        localStorage.setItem('APP_USER', json)
    });
}

class StoreUser {
    user_data = { 
        access_level: 0,
        user_id: 0,
        is_loggedin: false,
        is_reseller: false,
        is_partner: false,
        partner_status: '',
        partner_code: '',
        reseller_discount: 0,
        reseller_discount_3party: 0,
        error_login: false,
        profile: {
            firstname: '',
            lastname: '',
            email: '',
            phonenr: '',
            address: '',
            housenr: '',
            addition: '',
            zipcode: '',
            city: '',
            country: '',
            country_code: '',
            company: '',
            nrKvK: '',
            nrVAT: '',
            validVAT: false,
            has_subscription: 0
        },
        business: {
            company: '',
            nrKvK: '',
            nrVAT: '',
            validVAT: false
        },
        partner: {
            website: '',
            bank_account: '',
            account_holder: '',
            account_city: ''
        }
    }

    partner_data = {
        profile: {},
        business: {},
        password: {},
        partner: {}
    }

    user_settings = {}

    form_feedback = {}
    commission_orders = []
    commission_payouts = []

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storePartners = this.storeRoot.storePartners;
        //this.storeUi = this.storeRoot.storeUi;
        this.getUserDataFromLS();
        autoSave(this);
    }

    save(json) {
        this.saveToLS(json);
    }

    saveToLS(json) {
        localStorage.setItem('APP_USER', json)
    }

    setLogoutState() {
        this.user_data.user_id = 0;
        this.user_data.access_level = 0;
        this.user_data.is_loggedin = false;
        this.user_data.is_admin = false;
        this.user_data.is_partner = false;
        this.user_data.is_reseller = false;
        this.user_data.partner_status = '';
    }

    getUserDataFromLS() {
        let data;
        let user_id = 0;
        if (localStorage.getItem('APP_USER')) {
            data = JSON.parse(localStorage.getItem('APP_USER'));
            if (data) {
                this.user_data = data;
                if (this.user_data.user_id !== 0) {
                    user_id = this.user_data.user_id;
                }
                this.storeAuth.setAuth(data);
            } 
        } 
        return user_id;
    }

    clearFormFeedback() {
        this.form_feedback = {};
    }

    async getCommissions() {
        //const partner_code = this.user_data.partner_code;
        const userId = this.storeAuth.user.user_id;
        let commissions;

        try {
            let returnData = await account.getCommissionOrders(userId);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.commission_orders = returnData.data;
                    commissions = returnData.data;
                    return commissions;
                } else {
                    this.commission_orders = [];
                    commissions =[];
                    return commissions;
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.storeAuth.setLoginState(false);
                this.setLoginState(false);
            })
        }

        return commissions;
    }

    async getPayouts() {
        const userId = this.storeAuth.user.user_id;
        let commission_payouts;

        try {
            let returnData = await account.getCommissionPayouts(userId);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.commission_payouts = returnData.data;
                    commission_payouts = returnData.data
                    return commission_payouts;
                } else {
 
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.storeAuth.setLoginState(false);
                this.setLoginState(false);
            })
        }

        return commission_payouts;
    }

    setCommissionsOpen(commissions) {
        this.commissions_open = commissions;
    }

    commissionFee(order, amount, fee) {
        let commission = this.user_data.commission;
        if (fee === null || fee === '') {
            commission = 10;
        } else {
            commission = fee;
        }

        // const partnerFees = this.storePartners.partnerFees;
        // const idx = partnerFees
        //     .findIndex((item) => parseInt(item.bookId) === parseInt(order.item_id));
        
        // if (idx !== -1) {
        //     commission = parseInt(partnerFees[idx].percFee);
        // }

        let total = amount * (commission / 100);

        return total;
    }

    commissionOrderExclVat(amount, vat) {
        let total = amount / (1 + (vat / 100));
        return total;
    }

    commissionFeeInclVat(order, amount, fee) {
        let commission = this.user_data.commission;
        if (fee === null || fee === '') {
            commission = 10;
        }

        const partnerFees = this.storePartners.partnerFees;
        const idx = partnerFees
            .findIndex((item) => parseInt(item.bookId) === parseInt(order.item_id));
        
        if (idx !== -1) {
            commission = parseInt(partnerFees[idx].percFee);
        }

        let total = amount * (commission / 100);
        return total;
    }

    commissionPartnerInclVat(order, amount, fee) {
        let commission;

        if (fee === null || fee === '') {
            commission = 10;
        } else {
            commission = fee;
        }

        let total = amount * (commission / 100);
        return total;
    }

    commissionFeeExclVat(order, amount, fee, vat) {
        let commission = this.user_data.commission;
        if (fee === null || fee === '') {
            commission = 10;
        }

        const partnerFees = this.storePartners.partnerFees;
        const idx = partnerFees
            .findIndex((item) => parseInt(item.bookId) === parseInt(order.item_id));
        
        if (idx !== -1) {
            commission = parseInt(partnerFees[idx].percFee);
        }

        let total = (amount * (commission / 100)) / (1 + (vat / 100));
        return total;
    }

    commissionPartnerExclVat(order, amount, fee, vat) {
        let commission;
        
        if (fee === null || fee === '') {
            commission = 10;
        } else {
            commission = fee;
        }

        let total = (amount * (commission / 100)) / (1 + (vat / 100));
        return total;
    }

    get commissionTotalExclVat() {
        let total = 0; 
        total = this.commissionTotalInclVat - (this.commissionTotalVatLow + this.commissionTotalVatHigh);
        return total;
    }

    get commissionTotalInclVat() {
        let total = 0;
        let fee = 0;

        this.commissions_open && this.commissions_open.map(order => {
            let amount = order.order_total;

            if (parseInt(order.item_discount) === 1) {
                fee = order.partner_perc_sb_current;
                if (fee === null || fee === '') {
                    fee = 30;
                }
            } else {
                fee = order.partner_perc_3rd_current;
                if (fee === null || fee === '') {
                    fee = 10;
                }
            };
            
            total = total + this.commissionFee(order, amount, fee)
        })     
        return total;
    }

    get commissionTotalVatLow() {
        let total = 0;
        let vat = 0;
        let fee = 0;
        let feePerc = 0;

        this.commissions_open && this.commissions_open.map(order => {
            if (order.order_vat == 6 || order.order_vat == 9) {
                if (parseInt(order.item_discount) === 1) {
                    feePerc = order.partner_perc_sb_current;
                    if (feePerc === null || feePerc === '') {
                        feePerc = 30;
                    }
                } else {
                    feePerc = order.partner_perc_3rd_current;
                    if (feePerc === null || feePerc === '') {
                        feePerc = 10;
                    }
                };

                fee = order.order_total * (feePerc/100);

                vat = parseFloat(fee) - parseFloat(fee / (1 + (order.order_vat/100)));
                total = total + vat;
            }
        })     

        return total;
    }

    get commissionTotalVatHigh() {
        let total = 0;
        let vat = 0;
        let fee = 0;
        let feePerc = 0;

        this.commissions_open && this.commissions_open.map(order => {
            if (order.order_vat == 19 || order.order_vat == 21) {
                if (order.item_discount === 1) {
                    feePerc = order.partner_perc_sb_current;
                    if (feePerc === null || feePerc === '') {
                        feePerc = 30;
                    }
                } else {
                    feePerc = order.partner_perc_3rd_current;
                    if (feePerc === null || feePerc === '') {
                        feePerc = 10;
                    }
                };

                fee = order.order_total * (feePerc/100);

                vat = parseFloat(fee) - parseFloat(fee / (1 + (order.order_vat/100)));
                total = total + vat;
            }
        })     
        return total;
    }

    get ordersTotalExclVat() {
        let total = 0;
        total = this.ordersTotalInclVat - (this.ordersTotalVatLow + this.ordersTotalVatHigh);
        return total;
    }

    get ordersTotalInclVat() {
        let total = 0;
        this.commissions_open && this.commissions_open.map(order => {
            total = total + parseFloat(order.order_total);
        })     
        return total;
    }

    get ordersTotalVatLow() {
        let total = 0;
        let vat = 0;
        this.commissions_open && this.commissions_open.map(order => {
            if (order.order_vat == 6 || order.order_vat == 9) {
                vat = parseFloat(order.order_total) - parseFloat(order.order_total / (1 + (order.order_vat/100)));
                total = total + vat;
            }
        })     
        return total;
    }

    get ordersTotalVatHigh() {
        let total = 0;
        let vat = 0;
        this.commissions_open && this.commissions_open.map(order => {
            if (order.order_vat === 19 || order.order_vat === 21) {
                vat = parseFloat(order.order_total) - parseFloat(order.order_total / (1 + (order.order_vat/100)));
                total = total + vat;
            }
        })     
        return total;
    }

    get payoutsTotalInclVat() {
        let total = 0;
        this.commission_payouts.map(payout => {
            total = total + parseFloat(payout.total);
        })     
        return total;
    }

    setLoginState(login) {
        this.user_data.is_loggedin = login;
    }

    resetUserData() {
        this.user_data.user_id = 0;
        this.user_data.partner_code = '';
        this.user_data.partner_start = '';
        this.user_data.is_loggedin = false;
        this.partner_status = '';
        this.user_data.profile = {
            firstname: '',
            lastname: '',
            email: '',
            phonenr: '',
            address: '',
            housenr: '',
            addition: '',
            zipcode: '',
            city: '',
            country: '',
            country_code: '',
            company: '',
            nrKvK: '',
            nrVAT: '',
            validVAT: false,
            has_subscription: 0
        };
        this.user_data.business = {
            company: '',
            nrKvK: '',
            nrVAT: '',
            validVAT: false
        };
        this.user_data.partner = {
            website: '',
            bank_account: '',
            account_holder: '',
            account_city: ''
        }
    }

    setUserData(data) {
        if (data.id) {
            this.user_data.user_id = data.id;
            this.user_data.access_level = data.access_level;
            if ('admin' in data) {
                this.user_data.is_admin = (data.admin === "on" || data.admin === "1")
                    ?   true
                    :   false
            }
        }

        const profile = {
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            phonenr: data.phonenr,
            address: data.address,
            housenr: data.housenr,
            addition: data.addition,
            zipcode: data.zipcode,
            city: data.city,
            country: data.country !== null ? 'data.country' : 'Nederland',
            country_code: data.country_code !== null ? data.country_code : 'NL',
            company: data.company,
            nrKvK: data.nrKvK,
            nrVAT: data.nrVAT,
            validVAT: data.validVAT ? data.validVAT : false,
            has_subscription: data.has_subscription
        }

        const business = {
            company: data.company,
            nrKvK: data.nrKvK,
            nrVAT: data.nrVAT,
            validVAT: data.validVAT ? data.validVAT : false
        }

        const partner = {
            website: data.website,
            bank_account: data.bank_account,
            account_holder: data.account_holder,
            account_city: data.account_city
        }

        this.user_data.profile = profile;
        this.user_data.business = business;
        this.user_data.partner = partner;
        if (data.partner_code !== '' && data.partner_code !== null) {this.user_data.is_partner = "on"};
        this.user_data.partner_status = data.is_partner;
        this.user_data.partner_code = data.partner_code;
        this.user_data.partner_start = data.partner_start;
        
        this.user_data.discount = data.discount;
        this.user_data.discount_3party = data.discount_3party;
        if (this.storeAuth.user.is_reseller) this.user_data.reseller_discount = data.discount;
        if (this.storeAuth.user.is_reseller) this.user_data.reseller_discount_3party = data.discount_3party;
        
        this.user_data.commission = parseInt(data.commission);
        this.user_data.commission_current = parseInt(data.commission_current);
        this.user_data.commission_3party = parseInt(data.commission_3party);
        this.user_data.commission_3party_current = parseInt(data.commission_3party_current);
    }

    async registerClient(formControls) {
    //     this.storeGeneral.startLoader();

    //     try {
    //         let returnData = await account.addUser(formControls.email.value, formControls.password.value, formControls.firstname.value, formControls.lastname.value, formControls.has_subscription.value, 'NL', formControls.phonenr.value);
    //         runInAction(() => {
    //             let data = returnData.data[0];
    //             if (!('error' in data)) {
    //                 this.setLoginState(true);
    //                 this.storeAuth.setAuth(data);
    //                 this.setUserData(data);
    //                 this.form_feedback = returnData.msg;
    //             } else {
    //                 this.storeAuth.setLoginState(false);
    //                 this.setLoginState(false);
    //             } 
    //         })
    //     } catch (error) {
    //         runInAction(() => {
    //             this.storeAuth.setLoginState(false);
    //             this.setLoginState(false);
    //         })
    //     } finally {
    //         this.storeGeneral.stopLoader();
    //     }
    }

    async registerUser(formControls, form) {
        this.storeGeneral.startLoader();

        try {
            let returnData = await account.addUser(formControls, form);
            runInAction(() => {
                let data = returnData.data[0];
                if (!('error' in data)) {
                    this.setLoginState(true);
                    this.storeAuth.setAuth(data);
                    this.setUserData(data);
                    this.form_feedback = returnData.msg.fb;
                } else {
                    this.storeAuth.setLoginState(false);
                    this.setLoginState(false);
                } 
            })
        } catch (error) {
            runInAction(() => {
                this.storeAuth.setLoginState(false);
                this.setLoginState(false);
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    setDataBusiness(form_data) {
        this.user_data.business['company'] = form_data.company.value;
        this.user_data.business['nrKvK'] = form_data.nrKvK.value;
        this.user_data.business['nrVAT'] = form_data.nrVAT.value;
    }

    async changeUserData(formControls) {
        let profile = formControls.profile.formControls;
        let password = formControls.password.formControls;
        let business = formControls.business.formControls;
        let partner = formControls.partner.formControls;

        let country;

        let idx = this.storeGeneral.countriesList.findIndex(country => country.code == profile.country_select.value);
        if (idx !== -1) {
            country = this.storeGeneral.countriesList[idx].displayValue;
        } else {
            country = profile.country_select.value;
        }

        let form_data = {
            firstname: profile.firstname.value,
            lastname: profile.lastname.value,
            address: profile.address.value,
            housenr: profile.housenr.value,
            addition: profile.addition.value,
            zipcode: profile.zipcode.value,
            city: profile.city.value,
            country: country,
            country_code: profile.country_select.value,
            has_subscription: profile.has_subscription.value,
            email: profile.email.value,
            phonenr: profile.phonenr.value,
            company: ('company' in business && business.company.value !== '') ? business.company.value : '',
            nrKvK: ('nrKvK' in business && business.nrKvK.value !== '') ? business.nrKvK.value : '',
            nrVAT: ('nrVAT' in business && business.nrVAT.value !== '') ? business.nrVAT.value : '',
            website: ('website' in partner && partner.website.value !== '') ? partner.website.value : '',
            bank_account: ('bank_account' in partner && partner.bank_account.value !== '') ? partner.bank_account.value : '',
            account_holder: ('account_holder' in partner && partner.account_holder.value !== '') ? partner.account_holder.value : '',
            account_city: ('account_city' in partner && partner.account_city.value !== '') ? partner.account_city.value : ''
        }

        if  ('password' in password && password.password.value !== '' && formControls.password.formIsValid) {
            form_data.password = password.password.value;
        }

        try {
            let returnData = await account.editUser(this.user_data.user_id, form_data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.setUserData(returnData.data[0]);
                    this.form_feedback = returnData.data[1];
                    //this.restoreFields();
                } else {
                    //this.setLoginState(false);
                    // this.setState({
                    //     error: true
                    // })
                } 
            })
        } catch (error) {
            runInAction(() => {
                //this.setLoginState(false);
            })
        }
    }

    async changeUserProfile(formControls) {
        let profile = formControls;
        let country;

        let idx = this.storeGeneral.countriesList.findIndex(country => country.code == formControls.country_select.value);
        if (idx !== -1) {
            country = this.storeGeneral.countriesList[idx].displayValue;
        } else {
            country = profile.country_select.value;
        }

        let form_data = {
            firstname: profile.firstname.value,
            lastname: profile.lastname.value,
            address: profile.address.value,
            housenr: profile.housenr.value,
            addition: profile.addition.value,
            zipcode: profile.zipcode.value,
            city: profile.city.value,
            country: country,
            country_code: profile.country_select.value,
            has_subscription: profile.has_subscription.value,
            email: profile.email.value,
            phonenr: profile.phonenr.value
        }

        try {
            let returnData = await account.editUserProfile(this.user_data.user_id, form_data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.setUserData(returnData.data[0]);
                    this.form_feedback = returnData.data[1];
                } else {
                } 
            })
        } catch (error) {
            runInAction(() => {
                //this.setLoginState(false);
            })
        }
    }

    async changeBusinessProfile(formControls) {
        let business = formControls;

        let form_data = {
            company: business.company.value,
            nrKvK: business.nrKvK.value,
            nrVAT: business.nrVAT.value,
        }

        try {
            let returnData = await account.editBusinessProfile(this.user_data.user_id, form_data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    //this.setUserData(returnData.data[0]);
                    this.form_feedback = returnData.data[1];
                } else {
                } 
            })
        } catch (error) {
            runInAction(() => {
                //this.setLoginState(false);
            })
        }
    }

    async checkEmailExists(email) {
        let emailAvailable;
        try {
            let returnData = await account.checkEmailExists(email);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    emailAvailable = true;
                    return emailAvailable;
                } else {
                    emailAvailable = false;
                    return emailAvailable;
                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return emailAvailable;
    }

    setPartnerData(section, formControls) {
        let data = {};
        Object.keys(formControls).map((key, i) => {
            data[key] = formControls[key].value;
        })
        this.partner_data[section] = data;
    }

    setPartnerDataExtra(data) {
        this.partner_data['extra'] = data;
    }

    async submitFormPartner() {
        this.storeGeneral.startLoader();

        if (this.storeAuth.user.is_loggedin) {
            try {
                let returnData = await account.editUserFromPartnerForm(this.user_data.user_id, this.partner_data);
                runInAction(() => {
                    let data = returnData.data[0];
                    if (!('error' in data)) {
                        this.setUserData(data);
                        this.storeAuth.setAuth(data);
                        this.form_feedback = returnData.data[1];
                    } else {
                    } 
                })
            } catch (error) {
                runInAction(() => {
                    //this.setLoginState(false);
                })
            } finally {
                this.storeGeneral.stopLoader();
            }
        } else {
            try {
                let returnData = await account.addUserFromPartnerForm(this.partner_data);
                runInAction(() => {
                    let data = returnData.data[0];
                    if (!('error' in data)) {
                        this.setLoginState(true);
                        this.storeAuth.setAuth(data);
                        this.setUserData(data);
                        this.form_feedback.msg = returnData['msg'];
                    } else {
                        this.storeAuth.setLoginState(false);
                        this.setLoginState(false);
                    } 
                })
            } catch (error) {
                runInAction(() => {
                    this.storeAuth.setLoginState(false);
                    this.setLoginState(false);
                })
            } finally {
                this.storeGeneral.stopLoader();
            }
        }
    }

    async unSubscribe(email) {
        try {
            let returnData = await account.unSubscribe(email);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.form_feedback = returnData.data[0];
                } else {
                    this.form_feedback = returnData.data[0];
                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        } finally {

        }
    }

    async sendEmailUnSubscribe(email) {
        this.storeGeneral.startLoader();
        try {
            let returnData = await account.sendEmailUnSubscribe(email);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.form_feedback = returnData.data[0];
                } else {
                    this.form_feedback = returnData.data[0];
                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async getReviewerData() {
        let reviewer;
        let email = this.user_data.profile.email;

        this.storeGeneral.startLoader();
        try {
            let returnData = await account.getReviewerData(email);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    return reviewer = returnData.data;
                } else {

                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        } finally {
            this.storeGeneral.stopLoader();
        }
        return reviewer;
    }

    async refreshUserData() {
        this.storeGeneral.startLoader();
        let id = this.user_data.user_id;
        let data;

        try {
            let returnData = await account.refreshUserData(id);
            runInAction(() => {
                data = returnData.data[0];
                if (!('error' in data)) {
                    this.storeAuth.refreshAuth(data);
                    //this.user_data.is_partner = ((data.is_partner === 'on' || data.is_partner === 1 || data.is_partner === '1') && data.partner_code !== '') ? true : false;
                    this.user_data.partner_code = data.partner_code;
                    this.user_data.partner_status = data.is_partner;
                    this.user_data.discount = data.discount;
                    this.user_data.discount_3party = data.discount3Party;
                } 

                return data;
            })
        } catch (error) {
            runInAction(() => {

            })
        } finally {
            this.storeGeneral.stopLoader();
        }

        return data;
    }

    async checkSubscription(id) {
        let hasSubscription = 0;
        try {
            let returnData = await account.checkSubscription(id);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    if (returnData.data[0].has_subscription !== null) {
                        hasSubscription = returnData.data[0].has_subscription;
                    } else {
                        hasSubscription = 0;
                    }
                    this.user_data.profile.has_subscription = hasSubscription;
                    return hasSubscription;
                } else {
                    this.form_feedback = returnData.data[0];
                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        } finally {
            this.storeGeneral.stopLoader();
        }
        return hasSubscription;
    }
}

decorate(StoreUser, {
    commission_orders: observable,
    commissions_open: observable,
    commission_payouts: observable,
    form_feedback: observable,
    partner_data: observable,
    user_data: observable,
    user_settings: observable,
    store_interface: observable,
    changeUserData: action,
    changeBusinessProfile: action,
    changeUserProfile: action,
    checkEmailExists: action,
    checkSubscription: action,
    clearFormFeedback: action,
    getCommissions: action,
    getPayouts: action,
    getReviewerData: action,
    getUserDataFromLS: action,
    refreshUserData: action,
    registerClient: action,
    registerUser: action,
    resetUserData: action,
    sendEmailUnSubscribe: action,
    setCommissionsOpen: action,
    submitFormPartner: action,
    setDataBusiness: action,
    setLoginState: action,
    setLogoutState: action,
    setPartnerData: action,
    setUserData: action,
    commissionFee: action,
    commissionFeeExclVat: action,
    commissionFeeInclVat: action,
    commissionOrderExclVat: action,
    commissionTotalExclVat: computed,
    commissionTotalInclVat: computed,
    commissionTotalVatLow: computed,
    commissionTotalVatHigh: computed,
    ordersTotalExclVat: computed,
    ordersTotalInclVat: computed,
    ordersTotalVatLow: computed,
    ordersTotalVatHigh: computed,
    payoutsTotalInclVat: computed,
    unSubscribe: action
})

export default StoreUser;