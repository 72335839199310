import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import NewWindow from 'react-new-window';
import { withRouter } from "react-router-dom";
import moment from "moment";
import i18n from "i18next";

import * as utilities from '../../utilities/formats';
import * as account from '../../communicator/account';
import DialogConfirmation from '../dialogs/dialog-confirmation';

import ImgCover from '../../elements/img-cover';

const ContentDialog = inject("stores") (
    observer (
        class ContentDialog extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            render() {
                return (
                    this.props.children
                )
            }
        }
    )
)

const OpenOrderLine = inject("stores") (
    observer (
        class OpenOrderLine extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    sku: 0 
                }
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = async() => {
                this.getISBN(this.props.id);
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.id !== this.props.id) {
                    this.getISBN(this.props.id);
                }
            }

            getISBN = async(id) => {
                let isbn;

                if (id.toString().length < 10) {
                    isbn = await this.storeBooks.getBookISBN(id);
                } else {
                    isbn = id;
                }

                this.setState({
                    sku: isbn
                })
            }

            render() {
                return (
                    <div className="order-summary__image">
                        <ImgCover
                            sku = {this.state.sku}
                        />
                    </div>
                )
            }
        }
    )
)

const AccountOpenOrder = inject("stores") (
    observer (
        class AccountOpenOrder extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    order_total_incl: 0,
                    order_data: [],
                    isDialogOpen: false
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            async componentDidMount() {
                let open_order_data = await this.storeOrder.getOrderLinesData(this.props.order.order_id);
                this.setState({
                    order_data: open_order_data
                })
            }

            async componentDidUpdate(prevProps) {
                if (prevProps.order.order_id !== this.props.order.order_id) {
                    let open_order_data = await this.storeOrder.getOrderLinesData(this.props.order.order_id);
                    this.setState({
                        order_data: open_order_data
                    })
                }
            }

            async getOrderLineDetails(order_line) {
                let book = {};
                book = await this.storeBooks.returnBookDetails(order_line.book_id);
                let order_data = this.state.order_data;
                order_data.push(book);
                this.setState({
                    order_data: order_data
                })
            }
            processOpenOrder = (e) => {
                let total = 0;
                let total_row = 0;
                this.state.order_data && this.state.order_data.map((order_line) => {
                    if (order_line.book_id !== undefined && parseInt(order_line.book_id) !== 0) {
                        total_row = order_line.costs * order_line.amount * (1 - (order_line.discount / 100));
                        total = total + total_row;
                    }
                });

                this.storeUi.setUseCaseOrder('open_order');
                this.storeUi.setOrderToProcess(this.props.order.order_id, total);
                this.storeUi.setOrderToProcessKey("billing_country_code", this.props.order.billing_country_code);
                this.props.setView("payment");
            }

            formatCosts = (costs) => {
                return utilities.displayPrice(costs);
            }

            calculateRowPrice = (order_line) => {
                let total;
                total = order_line.costs * order_line.amount * (1 - (order_line.discount / 100));
                
                return utilities.displayPrice(total);
            }

            calcShippingCosts = (total) => {
                return utilities.displayPrice(total);
            }

            calcTotalCosts = () => {
                let total = 0;
                let total_row = 0;
                this.state.order_data && this.state.order_data.map((order_line) => {
                    if ((order_line.book_id !== null && parseInt(order_line.book_id) !== 0) || order_line.description == 'Verzendkosten') {
                        total_row = order_line.costs * order_line.amount * (1 - (order_line.discount / 100));
                        total_row = Math.round(total_row * 100) / 100;
                        total = total + total_row;
                    }
                });
                
                return utilities.displayPrice(total);
            }

            openDialog = () => {
                this.setState({
                    isDialogOpen: !this.state.isDialogOpen
                });
            }

            handleClose = () => {
                this.setState({ isDialogOpen: false })
            }
            
            onSubmit = () => {
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                if (id !== 0) {
                    (async() => await this.storeOrder.removeOrder(this.storeUser.user_data.user_id, this.props.order.order_id) )();
                }
                this.handleClose();
            }

            openDialogPaymentInfo = () => {
                this.storeUi.setContentComponent(
                    <ContentDialog
                        title = {'Betalingsinformatie bankoverschrijving'}
                    >
                        <div className="form-row form-row--feedback">
                            <span className="feedback__title">Je hebt gekozen voor handmatige overschijving.</span>
                            <p>Maak het totaalbedrag van <span className="amount amount--inline">{this.calcTotalCosts()}</span> van je bestelling over naar:</p>
                            <ul>
                                <React.Fragment>
                                    <li className="feedback__section">Rabobank te Zeist, rekening: NL56 RABO 0357 5205 80<br/>
                                        ten name van Belfra Publishers for Success bv
                                    </li>
                                    <li className="feedback__section">
                                        of:<br/>
                                        Triodosbank te Zeist, rekening: NL35 TRIO 0391 1028 26<br/>
                                        ten name van Belfra Publishers for Success bv
                                    </li>
                                    <li className="feedback__section">
                                        Dolderseweg 49<br/>
                                        3734 BB Den Dolder<br/>
                                        onder vermelding van "order <strong>{this.props.order.order_id}</strong>"
                                    </li>
                                </React.Fragment>

                                {(this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isNL) && (this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isBE) &&
                                    <li className="feedback__section">
                                        <b>Vanuit het buitenland:</b><br/>
                                        Gebruik de volgende gegevens voor een overschrijving vanuit het buitenland:<br/>
                                        Swift-adres (BIC#): RABONL2U<br/>
                                        IBAN: NL56 RABO 0357 5205 80<br/>
                                        ten name van Belfra Publishers for Success bv
                                        onder vermelding van  "order <strong>{this.props.order.order_id}</strong>"
                                    </li>
                                }

                                {(this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isNL) && (this.storeOrder.order_data.billing_address.country_code == this.storeOrder.settings_list.isBE) &&
                                    <li className="feedback__section">
                                        <b>Vanuit Belgi&euml;</b><br/>
                                        Gebruik de volgende gegevens voor een overschrijving vanuit Belgi&euml;:<br/>
                                        BNP Paribas Fortis rekening: BE71 0016 6035 5969<br/>
                                        Swift-adres (BIC#): GEBABEBB<br/>
                                        ten name van Belfra Publishers for Success bv<br/>
                                        onder vermelding van  "order <strong>{this.props.order.order_id}</strong>"
                                    </li>
                                }
                            </ul>
                        </div>
                    </ContentDialog>
                )
                this.storeUi.openDialogContent();
            } 

            render() {
                return (
                    <React.Fragment>
                        <div className="order-summary">
                            <span className="order-summary__date">{moment(this.props.order.order_date.date).format("DD-MM-YYYY")}</span>
                            <div className="order-summary__id">
                                Order: {this.props.order.order_id}
                            </div>
                            <div>
                                {this.state.order_data && this.state.order_data.map((order_line) => (
                                    order_line.book_id !== '0' && order_line.book_id !== 0 && order_line.book_id !== '' && order_line.book_id !== null
                                    ?   <div className="order-summary__content">
                                            <OpenOrderLine
                                                id = {order_line.book_id}
                                            />
                                            <div className="order-summary__info">
                                                <span>
                                                    <span dangerouslySetInnerHTML={{ __html: order_line.description}} />: <span className="amount">{this.formatCosts(order_line.costs)}</span>, {order_line.amount}x
                                                    {order_line.sku !== 'undefined' &&
                                                        <span className="order-summary__sku">ISBN: {order_line.sku}</span>
                                                    }
                                                    {order_line.discount > 0 &&
                                                        <span className="order-summary__discount">(korting: {order_line.discount}%)</span>
                                                    }
                                                </span>
                                                <span className="amount">{this.calculateRowPrice(order_line)}</span>
                                            </div>
                                        </div>
                                    :   order_line.description === 'Verzendkosten' &&
                                            <div className="order-summary__info">
                                                <span>Verzendkosten:</span>
                                                <span className="amount">{this.calcShippingCosts(order_line.costs)}</span>
                                            </div>
                                ))}
                                <div className="order-summary__info order-summary__total">
                                    <span>Totaalbedrag:</span>
                                    <span className="amount">{this.calcTotalCosts()}</span>
                                </div>
                            </div>
                            <div className="order-summary__actions">
                                    {this.props.order.order_status === 'banktransfer' && (
                                        <p className="order-summary__descr">Je hebt aangegeven deze per bankoverschijving te voldoen.<br/>
                                        <a href="#" onClick={(e) => this.openDialogPaymentInfo()}>Bekijk bankoverschijvingsgegevens</a><br/>
                                        Je kunt ook alsnog besluiten deze op een andere manier te betalen</p>
                                    )}
                                <div className="order-summary__buttons">
                                    {this.props.order.order_status === 'banktransfer'
                                    ?   <button className="list--orders__button btn btn--primary --active" onClick={(e) => this.processOpenOrder(e)}>Betaal anders</button>
                                    :   <button className="list--orders__button btn btn--primary --active" onClick={(e) => this.processOpenOrder(e)}>Betaal nu</button>
                                    } 
                                    {((this.props.order.order_onaccount === null && this.props.order.order_fulfilled !== null && this.props.order.order_paid === null) ||
                                    (this.props.order.order_onaccount === null && this.props.order.order_banktransfer !== null && this.props.order.order_paid === null) ||
                                    (this.props.order.order_status === 'void' && this.props.order.order_onaccount === null && this.props.order.order_fulfilled === null && this.props.order.order_paid === null && this.props.order.order_banktransfer === null)) && 
                                        <button className="list--orders__button btn btn--secundary --active" onClick={(e) => this.openDialog(e)}>Verwijder bestelling</button>  
                                    }
                                </div>
                            </div>

                            <div onClick={(e) => this.openDialog(e)} className="order-summary__actions--delete --link">
                                <span className="icon icon--delete"></span>
                            </div>
                        </div>

                        {this.state.isDialogOpen &&
                            <DialogConfirmation
                                show = {this.state.isDialogOpen}
                                onClose = {this.handleClose}
                                title = {i18n.t("popups.delete_order.title")}
                                buttons = {
                                    [{
                                        text: "Annuleer",
                                        onClick: () => this.handleClose(),
                                        btnClass: "--secundary"
                                    },{
                                        text: "Verwijder",
                                        onClick: () => this.onSubmit(),
                                        btnClass: "--primary"
                                    }]
                                }>
                                <p>{i18n.t("popups.delete_order.description")}</p>
                            </DialogConfirmation>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(AccountOpenOrder);


