import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import Start from './pages/start';
import Account from './pages/account';
import Author from './pages/author';
import Authors from './pages/authors';
import Book from './pages/book';
import BooksNew from './pages/books-new';
import BooksTop60 from './pages/books-top60';
import Category from './pages/category';
import EBooks from './pages/ebooks-all';
import Events from './pages/content/events';
import Genre from './pages/genre';
import Genres from './pages/genres';
import Jobs from './pages/content/jobs';
import Languages from './pages/languages';
import LinkDownload from './pages/content/link-download';
import LinkDownloadDoorbreek from './pages/content/link-download-doorbreek';
import Login from './pages/login';
import NewsletterSubscription from './pages/newsletter-subscription';
import NewslettersOverview from './pages/newsletters-overview';
import OurPublications from './pages/our-publications';
import OwnBook from './pages/content/own-book';
import PaymentResult from './pages/order/payment-result';
import Products from './pages/products';
import Register from './pages/register';
import SearchAdvanced from './pages/content/search';
import SearchResults from './pages/search-results';
import ShoppingCart from './pages/shoppingcart';
import Sitemap from './pages/content/sitemap';
import WishList from './pages/wishlist';

import Address from './pages/content/address';
import Business from './pages/content/business';
import CallMeBack from './pages/content/call-me-back';
import Contact from './pages/content/contact';
import Disclaimer from './pages/content/disclaimer';
import EventSubscription from './pages/content/event-subscription';
import Mission from './pages/content/mission';
import Partner from './pages/partner';
import Partners from './pages/content/partners';
import PartnerAgreement from './pages/content/partner-agreement';
import PartnerRegistration from './pages/content/partner-registration';
import Press from './pages/content/press';
import PrivacyPolicy from './pages/content/privacy-policy';
import Remarks from './pages/content/remarks';
import TermsConditions from './pages/content/terms-conditions';
import Unsubscribe from './pages/content/unsubscribe';
import Videos from './pages/content/videos';
import VideosOverview from './pages/content/videos-overview';
import SiteExplanation from './pages/content/site-explanation';

import RedirectPayment from './components/redirect_payment';

import Error404 from './pages/error/error404';

import TestUpload from './pages/test/test-upload';
import TestAbort from './pages/test/test-abort';

const Routes = inject("stores") (
    observer (
        class Routes extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeOrder = this.props.stores.storeOrder;
            }

            render() {
                return (
                    <React.Fragment>
                        <Switch>
                            <Route exact 
                                path='/' 
                                component={Start}
                            />
                            <Route 
                                path='/all-books' 
                                component={Start} 
                            />
                            <Route 
                                path='/account/:tab?' 
                                component={Account} 
                            />
                            <Route 
                                path='/address' 
                                component={Address} 
                            />
                            {/* <Route path='/audiobooks' component={AudioBooks} /> */}
                            {/* <Route path='/audiobook/:sku' component={Book} /> */}
                            <Route 
                                path={[
                                    '/author/:id',
                                    '/auteur/:id'
                                ]} 
                                component={Author} />
                            <Route 
                                path='/authors' 
                                component={Authors} 
                            />
                            <Route 
                                path={[
                                    '/book/:sku/:tab?',
                                    '/boek/:sku/:tab?'
                                ]} 
                                component={Book} />
                            <Route 
                                path={[
                                    '/business', 
                                    '/zakelijk'
                                ]}
                                component={Business} />
                            <Route 
                                path={[
                                    '/call-me-back', 
                                    '/belterug',
                                    '/belmeterug'
                                ]}
                                component={CallMeBack} />
                            <Route 
                                path={[
                                    '/category/:name' , 
                                    '/categorie/:name' 
                                ]}
                                render={(routeProps) => (<Category {...routeProps} category={'category'}/>)}
                            />
                            <Route 
                                path={[
                                    '/contact', 
                                    '/vragen'
                                ]}
                                component={Contact} 
                            />
                            <Route 
                                path='/disclaimer' 
                                component={Disclaimer} 
                            />
                            <Route 
                                path='/ebooks' 
                                component={EBooks} 
                            />
                            <Route 
                                path={[
                                    '/events', 
                                    '/ontmoet-de-schrijver',
                                    '/ontmoetdeschrijver'
                                ]}
                                component={Events} 
                            />
                            <Route 
                                path={[
                                    '/inschrijving/:ref?', 
                                    '/subscription/:ref?'
                                ]}
                                component={EventSubscription} 
                            />
                            <Route 
                                path='/genre/:name' 
                                component={Genre} 
                            />
                            <Route 
                                path='/genres' 
                                component={Genres} 
                            />
                            <Route 
                                path={[
                                    '/jobs', 
                                    '/vacature',
                                    '/vacatures'
                                ]}
                                component={Jobs} 
                            />
                            <Route 
                                path={[
                                    '/languages/:lang?',
                                    '/taal/:lang?'
                                ]}
                                //render={(routeProps) => (<Languages {...routeProps} category={'language'}/>)}
                                component={Languages}
                            />
                            <Route 
                                path={[
                                    'download-placebo',
                                    '/link-download',
                                    '/link-download-placebo',
                                ]}
                                component={LinkDownload} 
                            />
                            <Route 
                                path={[
                                    '/download-doorbreek',
                                    '/link-download-doorbreek'
                                ]}
                                component={LinkDownloadDoorbreek} 
                            />
                            <Route 
                                path='/login' 
                                component={Login} 
                            />
                            <Route 
                                path={[
                                    '/mission', 
                                    '/missie'
                                ]}
                                component={Mission} 
                            />
                            <Route path={[
                                    '/new-books/:view?', 
                                    '/overzichtnw/:view?'
                                ]}
                                component={BooksNew} 
                            />
                            <Route 
                                path={[
                                    '/newsletter', 
                                    '/nieuwsbrief2'
                                ]}
                                component={NewsletterSubscription} 
                            />
                            <Route path={[
                                    '/overview-newsletters/:week?', 
                                    '/nieuwsbriefoverzicht/:week?'
                                ]}
                                component={NewslettersOverview} />
                            <Route 
                                path={[
                                    '/our-publications/:tab?', 
                                    '/onze-publicaties/:tab?',
                                    '/eigen-uitgaven/:tab?',
                                    '/eigenuitgaven/:tab?',
                                    '/boeken-eigen-uitgeverij/:tab?',
                                    '/boekeneigenuitgeverij/:tab?'
                                ]} 
                                component={OurPublications} />
                            <Route 
                                path={[
                                    '/own-book', 
                                    '/eigen-boek'
                                ]}
                                component={OwnBook} 
                            />
                            <Route 
                                path='/payment-result' 
                                component={PaymentResult} 
                            />
                            <Route 
                                path='/partner' 
                                component={Partner} 
                            />
                            <Route 
                                path={[
                                    '/partners', 
                                    '/partneruitleg'
                                ]}
                                component={Partners} 
                            />
                            <Route 
                                path='/partner-agreement' 
                                component={PartnerAgreement} 
                            />
                            <Route 
                                path='/partner-registration' 
                                component={PartnerRegistration} 
                            />
                            <Route path={[
                                    '/press', 
                                    '/pers',
                                    '/media'
                                ]}
                                component={Press} />
                            <Route 
                                path='/privacy-policy' 
                                component={PrivacyPolicy} 
                            />
                            <Route 
                                path='/products/:group?' 
                                render={(routeProps) => (<Products 
                                    {...routeProps} 
                                    group={this.storeUi.app_interface.active_product_group} 
                                    subgroup={this.storeUi.app_interface.active_subgroup} 
                                />)}
                            />
                            <Route 
                                path='/register' 
                                component={Register} 
                            />
                            <Route 
                                path='/remarks' 
                                component={Remarks} 
                            />
                            <Route 
                                path='/search-advanced' 
                                component={SearchAdvanced} 
                            />
                            <Route 
                                path='/search-results/:page?' 
                                component={SearchResults} 
                            />
                            <Route 
                                path='/site-explanation' 
                                component={SiteExplanation} 
                            />
                            <Route 
                                path='/shoppingcart/:view?' 
                                component={ShoppingCart} 
                            />
                            <Route 
                                path='/sitemap' 
                                component={Sitemap} 
                            />
                            <Route 
                                path={[
                                    '/subcategory/:name', 
                                    '/subcategorie/:name'
                                ]} 
                                render={(routeProps) => (<Category {...routeProps} category={'subcategory'}/>)}
                            />
                            <Route 
                                path='/terms-conditions' 
                                component={TermsConditions} 
                            />
                            <Route 
                                path={[
                                    '/top60'
                                ]}
                                component={BooksTop60} />
                            <Route 
                                path={[
                                    '/unsubscribe', 
                                    '/uitschrijven'
                                ]}
                                component={Unsubscribe} />
                            <Route 
                                path={[
                                    '/videos/:video?'
                                ]}
                                component={VideosOverview} 
                            />
                            <Route 
                                path={[
                                    '/videos-overview/:video?'
                                ]}
                                component={VideosOverview} 
                            />
                            <Route 
                                path='/wishlist' 
                                component={WishList} 
                            />

                            <Route 
                                path='/404' 
                                component={Error404} 
                            />

                            <Route 
                                path="/payment" 
                                render={(routeProps) => (<RedirectPayment {...routeProps} loc={this.storeOrder.order_data.order_status.url} />)}
                            />

                            <Route 
                                path='/test-upload' 
                                component={TestUpload} 
                            />
                            <Route 
                                path='/test-abort' 
                                component={TestAbort} 
                            />
                            
                            <Route 
                                component={Error404} 
                            />
                        </Switch>
                    </React.Fragment>
                );
            }
        }
    )
)

export default withRouter(Routes);