import React, { Component } from 'react';
import i18n from "i18next";

class FormTextIncomplete extends Component {
    render() {
        return (
            <div className="form-row form-row--help --incomplete">
                <span 
                    className="form-row--help__text"
                    dangerouslySetInnerHTML={{ __html: i18n.t("form_suggestions.incomplete")}}
                />
            </div>
        )
    }
}

export default FormTextIncomplete;
