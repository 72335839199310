import * as apifetch from './fetch.js';

export async function addUser(formControls, form) {
    const urlEndpoint = '/v2/accounts/register';

    const postData = {
        form: form,
        email: formControls.email.value,
        pw: formControls.password.value,
        firstname: formControls.firstname.value,
        lastname: formControls.lastname.value,
        subscription: formControls.has_subscription.value,
        country:  'Nederland',
        country_code:  'NL',
        phonenr: formControls.phonenr.value
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function addUserFromOrder(data) {
    const urlEndpoint = '/accounts/addUserFromOrder.php';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function addAnonymousUser(data) {
    const urlEndpoint = '/accounts/addAnonymousUser.php';
    
    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkPw(id, email, pw) {
    const urlEndpoint = '/accounts/checkPassword.php';
    
    const postData = {
        id: id,
        email: email,
        pw: pw
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function editUser(id, form_data) {
    const urlEndpoint = '/accounts/editUser.php';
    const postData = {
        id: id,
        form_data: form_data
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function editUserProfile(id, form_data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/accounts/editUserProfile.php';

    const postData = {
        id: id,
        form_data: form_data
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error edit user: ', error.message);
    }
}

export async function editBusinessProfile(id, data) {
    const urlEndpoint = '/v2/accounts/' + id + '/profilebusiness';

    const postData = {
        id: id,
        data: data
    }

    return apifetch.authFetch(urlEndpoint, 'PUT', postData);
}

// export async function XeditBusinessProfile(id, form_data) {
//     const urlAPI = process.env.REACT_APP_URL_API;
//     const urlEndpoint = urlAPI + '/accounts/editBusinessProfile.php';

//     const postData = {
//         id: id,
//         form_data: form_data
//     }

//     try {
//         let apiStr = urlEndpoint;
//         let response = await fetch(apiStr, {
//             method: 'POST',
//             body: JSON.stringify(postData),
//             headers: new Headers({
    
//             })
//         });
        
//         let responseJson = await response.json();
//         if (responseJson !== null) {
//             let arrData = [];
//             arrData = responseJson;

//             return arrData;
//         }
//     } catch (error) {
//         console.log('SbC fetch error edit user: ', error.message);
//     }
// }

export async function retrievePassword(email, sms, sms_count) {
    let urlEndpoint;
   
    const postData = {
        email: email,
        sms_count: sms_count
    }

    if (sms === 1 || sms === '1') {
        const urlAPI = process.env.REACT_APP_URL_API;
        urlEndpoint = urlAPI + '/sms/sendSmsPassword.php';

        try {
            let apiStr = urlEndpoint;
            let response = await fetch(apiStr, {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: new Headers({
        
                })
            });
            
            let responseJson = await response.json();
            if (responseJson !== null) {
                let arrData = [];
                arrData = responseJson;
    
                return arrData;
            }
        } catch (error) {
            console.log('SbC fetch error retrieve password: ', error.message);
        }
    } else {
        urlEndpoint = '/v2/accounts/password';

        return apifetch.authFetch(urlEndpoint, 'POST', postData);
    }
}

export async function getCommissionOrders(id) {
    const urlEndpoint = '/v2/accounts/' + id + '/commissions';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getCommissionPayouts(id) {
    const urlEndpoint = '/v2/accounts/' + id + '/payouts';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function checkEmailExists(email) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/accounts/checkEmailExists.php';

    const postData = {
        email: email
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error check email: ', error.message);
    }
}

export async function checkSubscription(id) {
    const urlEndpoint = '/v2/accounts/' + id + '/account';
      
    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function addUserFromPartnerForm(data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/accounts/addPartner.php';

    const postData = {
        data: data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData)
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        let arrData = {'data': [{error : 'An error has occurred'}]};
        return arrData;
    }
}

export async function editUserFromPartnerForm(id, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/accounts/editPartner.php';

    const postData = {
        id: id,
        data: data
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error edit user through partner form: ', error.message);
    }
}

export async function retrieveOrderId(id) {
    const urlEndpoint = '/v2/accounts/' + id + '/order';
      
    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function saveWishList(id, data) {
    const urlEndpoint = '/v2/accounts/' + id + '/wishlist';

    const postData = {
        wishlist: data
    }
       
    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function retrieveWishList(id) {
    const urlEndpoint = '/v2/accounts/' + id + '/wishlist';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function saveShoppingCart(id, data, order_id, guid) {
    const urlEndpoint = '/v2/accounts/' + id + '/cart';
      
    let guid_value;

    if (guid === undefined || guid === 'undefined' || guid === null) {
        guid_value = '';
    } else {
        guid_value = guid;
    }
    const postData = {
        id: id,
        order_id: order_id,
        guid: guid_value,
        cart: data
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function retrieveShoppingCart(id) {
    const urlEndpoint = '/v2/accounts/' + id + '/cart';
      
    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function retrieveShoppingCart2(id) {
    //SbC DEPRICATED?
    const urlEndpoint = '/v2/accounts/' + id + '/cart';
      
    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function deleteShoppingCart(id) {
    const urlEndpoint = '/v2/accounts/' + id + '/cart';
      
    return apifetch.authFetch(urlEndpoint, 'DELETE');
}

export async function unSubscribe(email) {
    const urlEndpoint = '/accounts/unSubscribeUser.php';

    const postData = {
        email: email
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function sendEmailUnSubscribe(email) {
    const urlEndpoint = '/email/sendFormUnsubscribe.php';

    const postData = {
        email: email
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getReviewerData(email) {
    const urlEndpoint = '/accounts/getReviewerData.php';

    const postData = {
        email: email
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function refreshUserData(id) {
    const urlEndpoint = '/v2/accounts/' + id;

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function retrieveOrderItems(id) {
    //SbC id - orderid -> move to order???
    const urlEndpoint = '/v2/accounts/' + id + '/orderitems';

    return apifetch.authFetch(urlEndpoint, 'GET');
}
