import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

const IconPreviewCoverPdf = inject("stores") (
    observer (
        class IconPreviewCoverPdf extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;
            }

            render() {
                return (
                    <div className="book-tools__tool-link book-tools__tool-link--cover">
                        <a href={this.urlPublic + "/public/images/coversGrootPDF/" + this.props.sku + ".pdf"}
                            target="_blank"
                            className="link--pdf"
                        >
                            PDF PREVIEW
                        </a>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(IconPreviewCoverPdf);

