import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';
import FormCallMeBack from '../../components/forms/form-call-me-back';

const CallMeBack = inject("stores") (
    observer (
        class CallMeBack extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }
      
            render() {
                return (
                    <Template
                        type = {'default'}
                        header = {"call_me_back"}
                    >
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p>Wanneer je hieronder je gegevens invult en op de verstuur button klikt, bellen we je zo snel mogelijk terug.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <FormCallMeBack />
                            </div>
                        </div>  
                    </Template>
                )
            }
        }
    )
)

export default CallMeBack;
