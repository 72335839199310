//import 'react-app-polyfill/ie11';
import React , { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";
import App from './app';
import ScrollToTop from './elements/scroll';
import StoreRoot from './stores/store-root';

import Loader from './elements/loader';

import * as serviceWorker from './serviceWorker';

const startApp = () => {
    const storeRoot = new StoreRoot();

    ReactDOM.render(
        <Suspense fallback={<Loader />}> 
            <I18nextProvider i18n={i18n}>
                <Provider stores={storeRoot}>
                    <BrowserRouter>
                        <ScrollToTop />
                        <App />
                    </BrowserRouter>
                </Provider>
            </I18nextProvider>
        </Suspense>,
        document.getElementById('root')
    );
}

if (!window.cordova) {
    startApp()
} else {
    document.addEventListener('deviceready', startApp, false)
}

serviceWorker.unregister();