import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import SelectBook from '../../elements/form-input-select-book';

const RowSelectBook = inject("stores") (
    observer (
        class RowSelectBook extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    formControls: { 
                        select: {
                            name: this.props.name,
                            label: this.props.label,
                            placeholder: this.props.placeholder ? this.props.placeholder : i18n.t("forms.placeholder." + this.props.name),
                            value: this.props.value,
                            touched: false,
                            options: this.props.options,
                            validationRules: this.props.validationRules,
                            validationMsg: i18n.t("form_errors.general"),
                            valid: true,
                            required: this.props.required
                        }
                    }
                }
            }

            componentDidMount = () => {

            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.options !== this.props.options) {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls['select']
                    };
                    updatedFormElement.options = this.props.options;
                    updatedControls['select'] = updatedFormElement;
                    this.setState({
                        formControls: updatedControls
                    })
                }

                if (prevProps.selected !== this.props.selected) {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls['select']
                    };
                    updatedFormElement.value = this.props.selected;
                    updatedControls['select'] = updatedFormElement;
                    this.setState({
                        formControls: updatedControls
                    })
                }
            }

            handleChange = (event, action) => {     
                const name = 'select';
                const value = event.target.value;
          
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };

                updatedFormElement.value = value;
                if (this.state.formControls[name].value === "") {
                    updatedFormElement.touched = false;
                } else {
                    updatedFormElement.touched = true;
                }  
                
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
                updatedControls[name] = updatedFormElement;
                
                this.setState({
                    formControls: updatedControls,
                }); 

                this.props.onValidate(this.props.name, value, updatedFormElement.valid);
            }

            render() {
                const objRules = this.props.validationRules;
                const required = (Object.keys(objRules).length > 0 && 'isRequired' in objRules)
                    ?   ' --required'
                    :   ''

                    return (
                    <div 
                        className={"form-row form-row--input" + (
                            (Object.keys(objRules).length > 0 && 'isRequired' in objRules && 
                             this.state.formControls.select.touched === true && 
                             this.state.formControls.select.value === '') ||
                             (!this.state.formControls.select.valid && (Object.keys(objRules).length > 0) && this.state.formControls.textinput.value !== '')
                                ?   " --error" 
                                :   ''
                            )} 
                    >
                        <div className="form-column">
                            <label className={"form-label" + required}>{this.props.label}</label>
                        </div>
                        <div className="form-column">
                        <SelectBook
                            name="select"
                            placeholder = {this.state.formControls.select.placeholder}
                            value = {this.state.formControls.select.value}
                            options = {this.state.formControls.select.options ? this.state.formControls.select.options : []}
                            touched = {this.state.formControls.select.touched}
                            onChange = {this.handleChange}
                            valueSelected = {this.state.formControls.select.value}
                            defaultValue = {this.state.formControls.select.value}
                        />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(RowSelectBook);
