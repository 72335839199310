import * as apifetch from './fetch.js';

export async function getAuthors() {
    const urlEndpoint = '/v2/authors';
  
    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getBooksForAuthor(shop, str, firstname, lastname, page_view) {
    let str2 = str.replace(/&apos;/g, "'");
    str2 = str2.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    str2 = str2.replace(/,/g, "&comma;");

    const urlEndpoint = '/v2/authors/' + str2 + '/list';
   
    const postData = {
        shop: shop,
        id: str,
        str: str,
        firstname: firstname,
        lastname: lastname,
        page_view: page_view
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function getBookCountForAuthorSimple(shop, str) {
    let str2 = str.replace(/&apos;/g, "'");
    str2 = str2.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    str2 = str2.replace(/,/g, "&comma;");

    const urlEndpoint = '/v2/authors/' + str2 + '/countsimple';

    const postData = {
        shop: shop,
        str: str2
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}