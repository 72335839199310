import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Swiper, Slide } from 'react-dynamic-swiper'
import { withRouter } from "react-router-dom";
import 'swiper/css/swiper.css';
import {
    isBrowser,
    isMobile,
    isIOS
} from "react-device-detect";
import * as rdd from "react-device-detect";

import * as content from '../../utilities/content';

import BlockBooksPromobook from '../block/block-books-promobook';

const CarouselPromoBooks = inject("stores") (
    observer (
        class CarouselPromoBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.state = {
                  
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.play !== this.props.play) {
                    if (this.props.play === 'play') {
                        this.reactSwiper.swiper().autoplay.start();
                    } else {
                        this.reactSwiper.swiper().autoplay.stop();
                    }
                }
            }

            handleOnClick = async(e, sku) => {
                this.storeUi.setActiveSubCategory('');
                this.storeUi.setActiveCategory('');
                
                this.storeUi.setCurrentBook(sku);
                const book = await this.storeBooks.returnBookDetailsBySku(this.storeGeneral.shop, sku);
                const id = content.formatTitle(book.title);

                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    url = "/book/" + sku + "/" + id;
                } else {
                    url = "/book/" + sku;
                }
                this.props.history.push(url);
            }

            render() {
                return (
                    rdd.isMobile &&
                        <div className="carousel--promobooks">
                            <Swiper 
                                ref={(c) => { this.reactSwiper = c }}
                                swiperOptions={{
                                    slidesPerView: 1,
                                    initialSlide: 1,
                                    autoplay: this.storeUi.carousel_home === 'play' 
                                        ?   {
                                                delay: 6000,
                                                disableOnInteraction: false
                                            }
                                        :   false,
                                    loop: false,
                                    speed: 1000
                                }}
                                {...this.state.options}
                            >
                                {this.props.promoBooks && this.props.promoBooks.map((item, i) => (
                                    (item.sku !== null && item.sku !== '') &&
                                        <Slide key={i}>
                                            <div 
                                                className="carousel--banner__slide" 
                                                onClick={(e) => this.handleOnClick(e, item.sku)}
                                            >
                                                <div 
                                                    className="carousel--banner__slide-content"
                                                >
                                                    <BlockBooksPromobook
                                                        sku = {item.sku}
                                                    />
                                                </div>
                                            </div>
                                        </Slide>
                                ))}
                            </Swiper>
                        </div>
                )
            }
        }
    )
)

export default withRouter(CarouselPromoBooks);

