import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as account from '../../communicator/account';
import validate from '../../utilities/validation-rules';

import RowText from '../form-rows/row-text';

import Checkbox from '../../elements/form-input-checkbox';
import Select from '../../elements/form-input-select';

const FormProfile = inject("stores") (
    observer (
        class FormProfile extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    error_email: false,
                    error_email_empty: false,
                    formIsValid: false,
                    formControls: { 
                        firstname: {
                            type: "text",
                            value: this.storeUser.user_data.is_loggedin
                                ?   this.storeUser.user_data.profile.firstname
                                :   '',
                            validationRules: {
                                isRequired: true,
                            },
                            valid: (this.props.view === "cart" || this.props.form === "partner")
                            ?   this.storeUser.user_data.profile.firstname !== '' 
                                ?   true 
                                :   false
                            :   true
                        },
                        lastname: {
                            type: "text",
                            value: this.storeUser.user_data.is_loggedin
                                ?   this.storeUser.user_data.profile.lastname
                                :   this.props.view === "cart" ? this.storeOrder.order_data.client_profile.lastname : this.storeUser.user_data.profile.lastname,
                            validationRules: {
                                isRequired: true,
                            },
                            valid: (this.props.view === "cart" || this.props.form === "partner")
                            ?   this.storeUser.user_data.profile.lastname !== ''
                                ?   true
                                :   false
                            :   true
                        },         
                        email: {
                            type: "email",
                            value: this.storeAuth.user.is_loggedin
                                ?   this.storeUser.user_data.profile.email
                                :   this.props.view === "cart" 
                                    ?   this.props.email
                                        ?   this.props.email
                                        :   this.storeOrder.order_data.client_profile.email 
                                    : this.storeUser.user_data.profile.email,
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: this.storeUser.user_data.is_loggedin
                                ?   true
                                :   false
                        },
                        phonenr: {
                            type: "text",
                            value: this.storeUser.user_data.is_loggedin
                                ?   this.storeUser.user_data.profile.phonenr
                                :   this.props.view === "cart" ? this.storeOrder.order_data.client_profile.phonenr : this.storeUser.user_data.profile.phonenr,
                            validationRules: {  
                                isRequired: true,
                                isPhoneNumber: true
                            },
                            valid: (this.props.view === "cart" || this.props.form === "partner")
                            ?   this.storeUser.user_data.profile.phonenr !== ''
                                ?   true
                                :   false
                            :   true
                        },
                        address: {
                            type: "text",
                            value: 
                                this.storeUser.user_data.is_loggedin
                                ?   this.storeUser.user_data.profile.address !== null 
                                    ?   this.storeUser.user_data.profile.address
                                    :   ''
                                :   this.props.view === "cart" ? this.storeOrder.order_data.client_profile.address : this.storeUser.user_data.profile.address,
                            validationRules: 
                                (this.props.view === "cart" || this.props.form === "partner")
                                    ?   {isRequired: true}
                                    :   {}
                            ,
                            valid: (this.props.view === "cart" || this.props.form === "partner")
                            ?   this.storeUser.user_data.profile.address !== '' && this.storeUser.user_data.profile.address !== null
                                ?   true
                                :   false
                            :   true
                        },
                        housenr: {
                            type: "text",
                            value: this.storeUser.user_data.is_loggedin 
                                ?   this.storeUser.user_data.profile.housenr !== null
                                    ?   this.storeUser.user_data.profile.housenr
                                    :   ''
                                :   this.props.view === "cart" ? this.storeOrder.order_data.client_profile.housenr : this.storeUser.user_data.profile.housenr,
                            validationRules: 
                                (this.props.view === "cart" || 
                                this.props.view === "partner" ||
                                (this.props.view === "cart" && this.storeOrder.order_data.client_id > Number(this.storeUi.settings_list["requiredHousenr"])))
                                    ?   {isRequired: true}
                                    :   {}
                            ,
                            valid: (this.props.view === "cart" || this.props.form === "partner")
                            ?   this.storeUser.user_data.profile.housenr !== ''
                                ?   true
                                :   false
                            :   true
                        },
                        addition: {
                            type: "text",
                            value: this.storeUser.user_data.is_loggedin
                                ?   this.storeUser.user_data.profile.addition !== null
                                    ?   this.storeUser.user_data.profile.addition
                                    :   ''
                                :   this.props.view === "cart" ? this.storeOrder.order_data.client_profile.addition : this.storeUser.user_data.profile.addition,
                            validationRules: {},
                            validationMsg: i18n.t("form_errors.addition"),
                            valid: true
                        },
                        zipcode: {
                            type: "text",
                            value: this.storeUser.user_data.is_loggedin
                                ?   this.storeUser.user_data.profile.zipcode !== null
                                    ?   this.storeUser.user_data.profile.zipcode
                                    :   ''
                                :   this.props.view === "cart" ? this.storeOrder.order_data.client_profile.zipcode : this.storeUser.user_data.profile.zipcode,
                            validationRules: 
                                (this.props.view === "cart" || this.props.form === "partner")
                                    ?   {isRequired: true}
                                    :   {}
                            ,
                            valid: (this.props.view === "cart" || this.props.form === "partner")
                            ?   this.storeUser.user_data.profile.zipcode !== '' && this.storeUser.user_data.profile.zipcode !== null
                                ?   true
                                :   false
                            :   true
                        },
                        city: {
                            type: "text",
                            value: this.storeUser.user_data.is_loggedin
                                ?   this.storeUser.user_data.profile.city !== null
                                    ?   this.storeUser.user_data.profile.city
                                    :   ''
                                :   this.props.view === "cart" ? this.storeOrder.order_data.client_profile.city : this.storeUser.user_data.profile.city,
                            validationRules: 
                                (this.props.view === "cart" || this.props.form === "partner")
                                    ?   {isRequired: true}
                                    :   {}
                            ,
                            valid: (this.props.view === "cart" || this.props.form === "partner")
                            ?   this.storeUser.user_data.profile.city !== '' && this.storeUser.user_data.profile.city !== null
                                ?   true
                                :   false
                            :   true
                        },
                        country_select: {
                            type: "select",
                            label: i18n.t("forms.label.country"),
                            value: this.storeUser.user_data.is_loggedin
                                ?   this.storeUser.user_data.profile.country_code
                                :   this.props.view === "cart" 
                                    ?   this.storeOrder.order_data.client_profile.country_code 
                                    :   this.storeUser.user_data.profile.country_code !== '' && this.storeUser.user_data.profile.country_code !== undefined && this.storeUser.user_data.profile.country_code !== null
                                        ?   this.storeUser.user_data.profile.country_code
                                        :   'NL',
                            placeholder: i18n.t("forms.placeholder.country"),
                            options: this.storeOrder.countriesList,
                            validationRules: {},
                            valid: true
                        },
                        has_subscription: {
                            label: i18n.t("forms.label.subscribe"),
                            value: this.storeAuth.user.is_loggedin 
                                ?   (this.storeUser.user_data.profile.has_subscription === "1" || this.storeUser.user_data.profile.has_subscription === 'on') 
                                    ?   1 
                                    :   0
                                :   1,
                            validationRules: {},
                            valid: true
                        }
                    }
                }
            }

            componentDidMount = async() => {
                if (this.storeOrder.countriesList.length === 0) {
                    await (async() => this.storeOrder.getCountries()) ();
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls['country_select']
                    };
                    updatedFormElement['options'] = this.storeOrder.countriesList;

                    updatedControls['country_select'] = updatedFormElement;
                    this.setState({
                        formControls: updatedControls
                    })
                }

                //SbC check has_subscription
                if (this.storeAuth.user.is_loggedin) {
                    const has_subscription = await this.storeUser.checkSubscription(this.storeAuth.user.user_id);
                
                    if (has_subscription !== this.state.formControls.has_subscription.value) {
                        const updatedControls = {
                            ...this.state.formControls
                        };
                        const updatedFormElement = {
                            ...updatedControls['has_subscription']
                        };
                        updatedFormElement['value'] = parseInt(has_subscription);
                        updatedFormElement.touched = true;
                        
                        updatedControls['has_subscription'] = updatedFormElement;

                        this.setState({
                            formControls: updatedControls
                        });
                    }
                }

                if ('email' in this.props && this.props.email !== '') {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls['email']
                    };
                    updatedFormElement['value'] = this.props.email;
                    updatedFormElement.touched = true;
                    updatedFormElement.valid = validate(this.props.email, updatedFormElement.validationRules);

                    updatedControls['email'] = updatedFormElement;

                    this.setState({
                        formControls: updatedControls
                    }, () => this.validateForm());
                } else {
                    this.validateForm()
                }
            } 

            componentDidUpdate = async(prevProps) => {
                //SbC countries sometimes not loaded yet
                if ( this.storeOrder.countriesList.length === 0) {
                    await (async() => this.storeOrder.getCountries()) ();
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls['country_select']
                    };
                    updatedFormElement['options'] = this.storeOrder.countriesList;

                    updatedControls['country_select'] = updatedFormElement;
                    this.setState({
                        formControls: updatedControls
                    })
                }

                if (prevProps.loggedin !== this.props.loggedin) {
                    this.updateFormAfterLogin();
                }

                if (prevProps.email !== this.props.email) {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls['email']
                    };
                    updatedFormElement['value'] = this.props.email;
                    updatedFormElement.touched = true;
                    updatedFormElement.valid = validate(this.props.email, updatedFormElement.validationRules);

                    updatedControls['email'] = updatedFormElement;

                    this.setState({
                        formControls: updatedControls
                    }, () => this.validateRow('email', this.props.email, true, true));
                }
            }

            updateFormAfterLogin = () => {
                const updatedControls = {
                    ...this.state.formControls
                };
                let updatedFormElement = {};
                
                Object.keys(this.state.formControls).map((key, i) => {
                    updatedFormElement = {
                        ...updatedControls[key]
                    };
                    if (key !== 'country_select' && key !== 'has_subscription') {
                        updatedFormElement.value = this.storeOrder.order_data.client_profile[key];
                    }
                    if (key === 'country_select') {
                        updatedFormElement.value = this.storeOrder.order_data.client_profile['country_code'];
                    }
                    if (key === 'has_subscription') {
                        updatedFormElement.value = (this.storeUser.user_data.profile.has_subscription === "1" || this.storeUser.user_data.profile.has_subscription === 'on') ? 1 : 0;
                    }
                    updatedControls[key] = updatedFormElement;
                });

                this.setState({
                    formControls: updatedControls
                }, () => this.validateForm());
            }

            async checkEmail(value) {
                let emailAvailable = true;
                emailAvailable = await this.storeUser.checkEmailExists(value);
                return emailAvailable;    
            }

            handleCheckboxChange = (event) => {
                const name = 'has_subscription';

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...this.state.formControls[name],
                            value: (parseInt(this.state.formControls[name].value) === 1 || this.state.formControls[name].value === "on") ? 0 : 1,
                            selected: (parseInt(this.state.formControls[name].value) === 1 || this.state.formControls[name].value === "on") ? 0 : 1
                        }
                    }
                    
                }, () => this.validateForm());
            }

            handleBlur(e, name, val) {
                let countryCode;
                let value;

                //SbC store removed trailing spaces
                if (val !== null) {
                    if (val.length !== val.trimEnd().length) {
                        value = val.trim();
                        this.validateRow(name, value, true, true);
                    } else {
                        value = val;
                    }
                } else {
                    value = val;
                }

                if (name === "zipcode") {
                    //SbC check zipcode for Belgium
                    let zip_pattern_be = new RegExp(/^\d+$/);
                    if (zip_pattern_be.test(value) && value.length === 4) {
                        countryCode = 'BE';
                    }
                    //SbC check zipcode for Netherlands
                    let zip_pattern_nl = new RegExp(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i);
                    if (zip_pattern_nl.test(value)) {
                        countryCode = 'NL';
                    }

                    this.handleDropdown('auto', countryCode);
                }
            }

            handleDropdown = (action, countryCode) => {
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls['country_select']
                };
                updatedFormElement.value = countryCode;
                updatedControls['country_select'] = updatedFormElement;

                this.setState({
                    notificationCountry: action === 'auto' 
                    ?   (countryCode !== this.state.formControls.country_select.value && (countryCode === 'NL' || countryCode === 'BE')) ? true : false
                    :   false,
                    formControls: updatedControls
                }, () => this.validateForm()); 
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUi.clearFormFeedback();

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                if (name === 'email' && this.props.emailConnected) {
                    this.props.syncEmail(value);
                } 

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = async() => {
                let emailAvailable = true;
                let emailValue = this.state.formControls.email.value;

                if (emailValue !== '' && emailValue !== this.storeUser.user_data.profile.email) {
                    emailAvailable = await this.checkEmail(emailValue);                    
                }

                const formIsValid = emailAvailable && this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    error_email: !emailAvailable,
                    error_email_empty: emailValue === '' ? true : false,
                    formIsValid: formIsValid
                }, () => this.props.handleFormChange(this.state.formControls, this.state.formIsValid, 'profile'))
            }

            render() {
                const TextCheckbox = (props) => {
                    return (
                        <React.Fragment>
                            <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.subscribe")}}></span>
                            <a href="#" onClick={(e) => this.openPS(e)}>privacy statement</a>
                            <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.subscribe2")}}></span>
                            <br/>
                            <span>Partners worden altijd op de hoogte gehouden.</span>
                            
                        </React.Fragment>
                    )
                }

                return (
                    <form className="form">
                        {Object.keys(this.state.formControls).map((key, i) => (
                            <React.Fragment>
                                {(key !== "country_select" && 
                                  key !== "has_subscription") &&                               
                                    <React.Fragment>
                                        {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                            <RowText 
                                                type = {this.state.formControls[key].type}
                                                name = {key}
                                                value = {this.state.formControls[key].value}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                validationMsg = {this.state.formControls[key].validationMsg}
                                                onValidate = {this.validateRow}
                                                onBlur = {(e) => this.handleBlur(e, key, this.state.formControls[key].value)}
                                                disable = {this.state.formControls[key].disable}
                                                readOnly = {(!this.storeUser.user_data.is_loggedin && this.state.formControls[key].type === "email" && this.props.syncedEmail)
                                                    ?   true
                                                    :   false
                                                }
                                                validateRowOnMount = {this.storeUser.user_data.is_loggedin 
                                                    ?   true 
                                                    :   false
                                                }
                                                syncedEmail = {this.props.syncedEmail}
                                            />
                                        }
                                    </React.Fragment>
                                } 

                                {key === "firstname" && this.state.formControls.firstname.touched && (this.state.formControls.firstname.value === '' || this.state.formControls.firstname.value.length === 1) &&
                                    <span className="form-field--suggestion">{i18n.t("form_suggestions.firstname")}</span>
                                }

                                {key === "email" && this.state.error_email &&
                                    <span className="form-field--error --email">Dit e-mailadres is al in gebruik. Log <Link to='/login'>hier</Link> in met dit e-mailadres, of vul een ander e-mailadres is.</span>
                                }

                                {key === "email" && this.state.formControls.email.touched && this.state.error_email_empty &&
                                    <span className="form-field--error --email">Het e-mailadres kan niet leeg zijn.</span>
                                }

                                {key === "country_select" &&
                                    <div className="form-row form-row--select">
                                        {this.state.formControls['country_select'].label}
                                        <Select 
                                            name="country_select"
                                            value = {this.state.formControls.country_select.value}
                                            options = {this.state.formControls.country_select.options ? this.state.formControls.country_select.options : []}
                                            touched = {this.state.formControls.country_select.touched}
                                            onChange = {(e) => this.handleDropdown('dd', e.target.value)}
                                            valueSelected = {this.state.formControls.country_select.value}
                                            defaultValue = {this.state.formControls.country_select.value}
                                        />
                                    </div>
                                }

                                {key === "country_select" && this.state.notificationCountry &&
                                    <span className="form-field--error --email">Op basis van de postcode is het land aangepast.</span>
                                }

                                {key === "has_subscription" && this.props.option_subscription &&
                                    <div className="form-row form-row--input">
                                        <Checkbox 
                                            name = {key} 
                                            label = {<TextCheckbox 
                                                section = {key}
                                            />}
                                            value = {this.state.formControls[key].value}
                                            isSelected = {this.state.formControls[key].value}
                                            onCheckboxChange={this.handleCheckboxChange}
                                            className = {"--reduced --subscription"}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        ))}
                    </form>
                )
            }
        }
    )
)

export default withTranslation()(FormProfile);
