import React from 'react';

class SelectBook extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    constructOption(str1, str2, str3) {
        let str = str1;
        let trimmedStr;

        if (str3 !== undefined && str3 !== null && str3 !== '') {
            str = str + ' (' + str2 + " " + str3 + ")";
        }
        let length = 30;
        if (str.length > length) {
            trimmedStr = str.substring(0, length) + " ...";
        } else {
            trimmedStr = str;
        }
        return trimmedStr;
    }

    render() {
        let formControl = "form-control";

        return (
            <div className="form-group">
                <select 
                    className = {formControl} 
                    value = {this.props.defaultValue} 
                    onChange = {this.props.onChange} 
                    name = {this.props.name}
                >   
                    <option 
                        value = '0' 
                        key = '0'
                    >
                        {this.props.placeholder}
                    </option>
                    {this.props.options.map((option, i) => (
                        <option 
                            value = {i + 1} 
                            key = {option.value}
                            dangerouslySetInnerHTML={{ __html: this.constructOption(option.title, option.author_firstname, option.author_lastname)}}
                        >
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

export default SelectBook;