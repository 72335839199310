import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";


const TestAbort = inject("stores") (
    observer (
        class TestAbort extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            
                this.controller = new AbortController();
                this.signal = this.controller.signal;

                this.state = {
                    status: 'loading'
                }
            }

            componentDidMount = async() => {
                this.storeUi.setPageView('inview', 100);
                const books = this.storeBooks.getBooksWithAbort('SHOP_SB', this.storeUi.page_view, this.controller, this.signal);

                this.setState({
                    query: books
                })
                books.then((result) => {  
                    console.log('SbC loaded', this.state.query)
                    console.log('SbC result', result.data)
                    this.setState({
                        bookList: result.data
                    })
                });
            }

            componentDidUpdate = (prevProps) => {

            }

            handleClick = () => {
                this.state.query.cancel();
                this.setStatus("cancelled");
            }

            setStatus = (status) => {
                this.setState({
                    status: status
                })
            }

            render() {
                if (this.state.status === "loading") {
                return (
                    <React.Fragment>
                        <div style={{ position: 'absolute', top: 200, zIndex: 100 }}>
                            <div style={{ margin: "20px 0px 5px" }}>loading ...</div>
                            <button
                                onClick={() => this.handleClick()}
                            >
                            Cancel
                            </button>
                        </div>
                        <div>
                            {this.state.bookList && this.state.bookList.map((book) => (
                                <div>xxx</div>
                            ))}
                        </div>
                    </React.Fragment>
                )}

                if (this.state.status === "cancelled") {
                    return (
                        <React.Fragment>
                            <div style={{ position: 'absolute', top: 200, zIndex: 100 }}>
                                <div style={{ margin: "20px 0px 5px" }}>CANCELLED ...</div>
                                
                            </div>
                        </React.Fragment>
                    )
                }
            }
        }
    )
)

export default withRouter(TestAbort);

