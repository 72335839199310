import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as content from '../communicator/content';

class StoreContent {

    constructor() {

    }

    async getContentBlocks(block) {
        let result;

        try {
            const returnData = await content.getContentBlocks(block);
            runInAction(() => {
                result = returnData.data;

                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getPromoBooks() {
        let result;
        
        try {
            const returnData = await content.getPromoBooks();
            runInAction(() => {
                result = returnData.data;

                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getVideoLabels(view) {
        let result;

        try {
            const returnData = await content.getVideoLabels(view);
            runInAction(() => {
                result = returnData.data;

                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }
}

decorate(StoreContent, {
    getContentBlocks: action
})

export default StoreContent;