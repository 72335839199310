export function isInteger(x) {
    return !isNaN(x);
}

export function formatPrice(price) {
    const formattedPrice = (Math.round(price * 100) / 100).toFixed(2);
    return formattedPrice;
}

export function displayPrice(price) {
    const displayedPrice = parseFloat(price)
        .toFixed(2)
        .toString()
        .replace('.',',');
    return displayedPrice;
}

export function capitalizeFirstChar(str) {
    let str2 = '';
    if (str !== null && str !== undefined) {
        str2 = str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str2;
}

export function capitalizeTitle(str) {
    let formattedTitle = str;

    let test = /[A-Z]/.test(str);
    const numUpper = str.length - str.replace(/[A-Z]/g, '').length;
    const strLength = str.replace(/ /g,"").length;
   
    if (test && numUpper == strLength) {
        formattedTitle = formattedTitle.toLowerCase();
        formattedTitle = str.charAt(0).toUpperCase() + formattedTitle.slice(1);
    }

    return formattedTitle;
}

export function removeSpaces(str) {
    let str2 = str.replace(/\s+/g, ' ').trim();
    
    return str2;
}

export function replaceChars(str) {
    let str2 = str.replace(/[&]/g,"en");
    
    return str2;
}