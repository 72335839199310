import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import moment from "moment";

const BookTitle = inject("stores") (
    observer (
        class BookTitle extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                }
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {

            }

            constructTitle = () => {
                let title = this.props.book.title;
                let collectionName = '';
                let collectionPart = '';
                let versionTag = '';
                
                if (this.props.book.collection_name && this.props.book.collection_name !== null) {
                    collectionName = this.props.book.collection_name + ': ';
                    
                }
                if (this.props.book.collection_part && this.props.book.collection_part !== null) {
                    collectionPart = ' ' + this.props.book.collection_part;
                }
                if (this.props.book.version_tag && this.props.book.version_tag !== null) {
                    versionTag = ' (' + this.props.book.version_tag + ')';
                }

                title = collectionName + title + collectionPart + versionTag;

                return title;
            }
      
            render() {
                return (
                    <React.Fragment>
                        <h1 dangerouslySetInnerHTML={{ __html: this.constructTitle()}} />
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BookTitle);

