import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';

const BlockQuote = inject("stores") (
    observer (
        class BlockQuote extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirect: false
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = async() => {
            }

            handleClick = (e, sku) => {
                if (sku !== '' && sku !== undefined) {
                    const book = this.storeBooks.booksList
                        .filter((book) => book.sku == sku);
                    const id = book[0].id;

                    this.storeUi.setCurrentBook(sku);
                    this.storeBooks.getBookDetailsBySku(sku);

                    this.setState({
                        sku: sku,
                        redirect: this.props.match.params.sku ? false : true
                    });
                }
            }

            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/book/" + this.state.sku} 
                    />;
                }

                const quote = this.storeUi.quote;
                let author = quote.author;

                let quoteClass = "block block--quote";
                if (this.props.bg && this.props.bg !== '') {
                    quoteClass = quoteClass + ' ' + this.props.bg;
                }

                while (author && author.charAt(0) === '-') {
                    author = author.substring(1);
                }


                return (
                    <div className={quoteClass}>
                        {quote &&
                            <div className="quote" onClick={(e) => this.handleClick(quote.isbn)}>
                                <div className="quote__title" 
                                    dangerouslySetInnerHTML={{ __html: quote.text }} 
                                />
                                <div className="quote__author"
                                    dangerouslySetInnerHTML={{ __html: author }}
                                /> 
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)

export default withRouter(BlockQuote);

