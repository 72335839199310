import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import FormLogin from '../forms/form-login';
import FormRetrievePassword from '../forms/form-retrieve-password';

import FormTextIncomplete from '../../elements/form-text-incomplete';

const CartLogin = inject("stores") (
    observer (
        class CartLogin extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    btn_class: '--disabled',
                    pw_card: false,
                    retrieve: false
                }
            }

            onNextAction = ()  => {
                const view = 'client';
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                this.storeOrder.setCartView(id, view);
                // let url = "/shoppingcart/" + view;
                // this.props.history.push(url);
            }

            rememberEmail = (email) => {
                this.setState({
                    email: email
                })
            }

            toggleRetrievePassword = (e) => {
                this.setState({
                    retrieve: !this.state.retrieve
                })
            }

            render() {
                return (
                    <div className="cart-data">
                        <div className="content-section--login --active">
                            {!this.state.retrieve 
                            ?   <React.Fragment>
                                    <div className="content-section--login-option">
                                        <h2>Log in om de bij ons bekende gegevens op te halen:</h2>
                                        <div className="form-wrapper">
                                            <FormLogin 
                                                onNext = {this.onNextAction}
                                                rememberEmail = {this.rememberEmail}
                                            />
                                            <div className="form__alternative-actions">
                                                <span className="link--read-more" onClick={(e) => this.toggleRetrievePassword(e)}>Ben je je wachtwoord vergeten of heb je nog geen wachtwoord?</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-section--login-option">
                                        <h2>Ben je nog geen klant bij ons?</h2>
                                        <span className="cart-data__subtitle">En/of heb je nog nooit iets gedownload bij ons?</span>
                                        <span className="cart-data__subtitle"><span className="content__textlink" onClick={() => this.onNextAction()}>Vul je gegevens in bij je bestelling</span></span>
                                    </div>
                                </React.Fragment>
                            :   <div className="content-section--retrieve">
                                    <div className="content-wrapper__segment">
                                        <div className="segment__header">{i18n.t("header.retrieve")}</div>
                                    </div>
                                    <FormRetrievePassword 
                                        email = {this.state.email}
                                    />
                                    <div className="form__alternative-actions">
                                        <span className="link--back" onClick={(e) => this.toggleRetrievePassword(e)}>Terug</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CartLogin));
