import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as ui from '../utilities/ui';

const ButtonPagingForward = inject("stores") (
    observer (
        class ButtonPagingForward extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    active: true,
                    btnClass: "--active"
                }
            }

            componentDidMount = () => {
            }

            componentDidUpdate = (prevProps, prevState) => {
            }

            pagingForward = async(btnClass) => {
                if (btnClass === '--active') {
                    this.setPage(btnClass);
                }
            }

            setPage = (btnClass) => {
                let from;
                let to;

                const total = this.storeUi.page_view.total;
                const inview = this.storeUi.page_view.inview;
                let pages = Math.ceil(parseInt(total) / parseInt(inview));

                if (btnClass === '--active') {
                    const page = this.props.page + 1;

                    if (this.storeUi.page_view.rangeTo < pages) {
                        from = this.storeUi.page_view.rangeFrom + 1;
                        to = this.storeUi.page_view.rangeTo + 1;
                    } else {
                        from = this.storeUi.page_view.rangeFrom;
                        to = this.storeUi.page_view.rangeTo;
                    }

                    this.storeUi.setPageView('page', page);
                    this.storeUi.setPageView('rangeFrom', from);
                    this.storeUi.setPageView('rangeTo', to);

                    this.props.setPage(page);
                }

                ui.handleScroll('top');
                this.storeUi.setFreezeScrolling(false);
            }

            render() {  
                let total = this.storeUi.page_view.total;
                
                if (this.props.type == 'search') {
                    if (this.storeGeneral.searchShop == 'OWN') {
                        total = this.storeUi.search_count_own;
                    }
                    if (this.storeGeneral.searchShop == 'SB') {
                        total = this.storeUi.search_count_sb;
                    }
                    if (this.storeGeneral.searchShop == 'ALL') {
                        total = this.storeUi.search_count_all;
                    }
                }

                const inview = this.storeUi.page_view.inview;
                let pages = Math.ceil(parseInt(total) / parseInt(inview));
                //let pages = this.props.pages

                const btnClass = (pages > 1 && this.props.page === this.storeUi.page_view.rangeTo && this.props.page === pages) || this.props.page === pages || total === null || pages === 1
                        ?   '--inactive'
                        :   '--active'

                return (
                    <div 
                        className = {"paging-forward-wrapper " + btnClass} 
                        onClick = {(e) => this.pagingForward(btnClass)}
                    >
                        <div className="paging-forward-wrapper__icon" />
                    </div>
                )
            }
        }
    )
)

export default withRouter(ButtonPagingForward);
