import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

const BlockPreviewCoverBack = inject("stores") (
    observer (
        class BlockPreviewCoverBack extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    hasCoverPng: false,
                    hasCoverJpg: false,
                    coverDir: "CoversAK"
                }
            }

            componentDidMount = async() => {
                this.checkCovers();
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.sku !== this.props.sku) {
                    this.checkCovers();
                }
            }

            checkCovers = async() => {
                let hasCoverPng = false;
                let hasCoverJpg = false;

                const returnPng = await this.storeUi.checkFile(this.props.sku, 'coverBackPng');
                if ('error' in returnPng[0]) {
                    hasCoverPng = false;
                } else {
                    hasCoverPng = true;
                }
                
                if (!hasCoverPng) {
                    const returnJpg = await this.storeUi.checkFile(this.props.sku, 'coverBackJpg');
                    if ('error' in returnJpg[0]) {
                        hasCoverJpg = false;
                    } else {
                        hasCoverJpg = true;
                    }
                }

                this.setState({
                    hasCoverPng: hasCoverPng,
                    hasCoverJpg: hasCoverJpg,
                    image: this.urlPublic + "/public/images/" + this.state.coverDir + "/" + this.props.sku + "." + (hasCoverPng ? "png" : "jpg")
                })
            }

            handleClick = () => {
                const ext = this.state.hasCoverPng 
                    ?   "png" 
                    :   "jpg"

                const url = this.urlPublic + "/public/images/" + this.state.coverDir + "/" + this.props.sku + "." + ext;
                window.open(url);
            }

            handleError = () => {
                this.setState({
                    image: this.urlPublic  + "/public/images/img-not-available.png"
                })
            }

            render() {
                return (
                    this.state.hasCoverPng || this.state.hasCoverJpg
                    ?   <div 
                            className="block-content--book__preview-back --link"
                            onClick = {(e) => this.handleClick()}
                        >
                            <img 
                                onError={() => this.handleError()} 
                                src={this.state.image} 
                                alt="back preview"
                                className={(this.props.soldout ? " --soldout" : "")}
                            />
                            <span className="circle plus"></span>
                        </div>
                    :   null
                )
            }
        }
    )
)

export default withTranslation()(BlockPreviewCoverBack);

