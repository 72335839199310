import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockVideoFilter = inject("stores") (
    observer (
        class BlockVideoFilter extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeContent = this.props.stores.storeContent;

                this.state = {
                    categories: [],
                    tags: [],
                    active: ''
                }
            }

            componentDidMount = async() => {
                const categories = await this.storeContent.getVideoLabels('categories');
                const tags = await this.storeContent.getVideoLabels('tags');

                this.setState({
                    categories: categories,
                    tags: tags
                })
            }


            componentDidUpdate(prevProps) {

            }

            handleOnClick = async(view, value) => {
                const videos = this.storeUi.getVideosByLabel(view, value)
                this.setState({
                    active: value
                })
            }

            removeFilter = async() => {
                const videos = this.storeUi.getVideos();
                this.setState({
                    active: ''
                })
            }

            render() {
                return (
                    <div className="block--video-filter">
                        <div className="content-segment__content">
                            <h2 className="block--categories__title">Toon video's voor categorie of trefwoord</h2>

                            {this.state.categories && this.state.categories.length > 0 &&
                                <div className="block block--categories">
                                    <span className="block--categories__title">Categorie&euml;n:</span>
                                    <ul className="block--categories__list">
                                        {this.state.categories.map((cat, i) => (
                                            <li 
                                                className={"block--tags__listitem --link" + (this.state.active === cat ? " --active" : "")}
                                                onClick={(e) => this.handleOnClick('category', cat)}
                                                dangerouslySetInnerHTML={{ __html: cat}}
                                            />
                                        ))}
                                    </ul>
                                </div>
                            }

                            {this.state.tags && this.state.tags.length > 0 &&
                                <div className="block block--tags">
                                    <span className="block--tags__title">Trefwoorden:</span>
                                    <ul className="block--tags__list">
                                        {this.state.tags.map((tag, i) => (
                                            <li 
                                                className={"block--tags__listitem --link" + (this.state.active === tag ? " --active" : "")}
                                                onClick={(e) => this.handleOnClick('tag', tag)}
                                                dangerouslySetInnerHTML={{ __html: tag}}
                                            />
                                        ))}
                                    </ul>
                                </div>
                            }

                            <span
                                className="block--video-filter__remove --link"
                                onClick={(e) => this.removeFilter()}
                            >
                                Verwijder filter
                            </span>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockVideoFilter);
