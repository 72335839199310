import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

const BlockPreviewPdf = inject("stores") (
    observer (
        class BlockPreviewPdf extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    hasPdf: false
                }

                this.controller = new AbortController();
                this.signal = this.controller.signal;
            }

            componentDidMount() {
                this.setTools();
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.sku !== this.props.sku) {
                    this.setTools();
                }
            }

            componentWillUnmount = () => {
                if (this.controller && this.state.query) {
                    this.state.query.cancel();
                }
            }

            async setTools() {
                const isbn = this.props.sku;
                if (!this.storeUi.search_overlay) {
                    const q = this.storeUi.checkFileWithAbort(this.props.sku, 'preview', this.controller, this.signal);

                    this.setState({
                        query: q
                    })
                    q.then((result) => {  
                        this.setState({
                            hasPdf: (result.data.length === 0 || 'error' in result.data[0]) ? false : true
                        })
                    });
                }
            }

            handleLinkPdf = (sku) => {
                const url = this.urlPublic + "/public/preview-pdf/" + sku + ".pdf";
                window.open(url);
            }

            render() {
                return (this.state.hasPdf
                    ?   <div className="book-tools__tool book-tools__tool--pdf">
                            <div className="book-tools__tool-link book-tools__tool-link--pdf">
                                <a href={this.urlPublic + "/public/preview-pdf/" + this.props.sku + ".pdf"}
                                    target="_blank"
                                    className="link--pdf"
                                />
                            </div>
                            {this.props.label &&
                                <span 
                                    className="book-tools__tool-title --link"
                                    onClick={(e) => this.handleLinkPdf(this.props.sku)}
                                    dangerouslySetInnerHTML={{ __html: this.props.labelTxt 
                                        ?   this.props.labelTxt
                                        :   i18n.t("book_tools.pdf")
                                    }}
                                />
                            }
                        </div>

                    :   null
                )
            }
        }
    )
)

export default withTranslation()(BlockPreviewPdf);

