import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import DialogConfirmation from '../dialogs/dialog-confirmation';
import * as utilities from '../../utilities/formats';
import validate from '../../utilities/validation-rules';

import i18next from 'i18next';

const FeedbackOrderDelivery = inject("stores") (
    observer (
        class FeedbackOrderDelivery extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {

                }
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = (prevProps) => {

            }

            render() {
                return (
                    <React.Fragment>
                        <div className="feedback feedback--order-delivery">
                            <span className="feedback--order-delivery__header">Let op:</span>
                            <span className="feedback--order-delivery__text">Bij een bestelling van meerdere artikelen zullen alle artikelen tegelijkertijd worden verstuurd. Bij verschillende levertijden kan het dus gebeuren dat een artikel later geleverd wordt dan de vermelde levertijd.</span>
                        </div>
                   </React.Fragment>
                )
            }
        }
    )
)

export default FeedbackOrderDelivery;
