import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import * as rdd from "react-device-detect";

import * as ui from '../../utilities/ui';

import NavigationToggleListitem from '../../elements/navigation-toggle-listitem';

const NavigationToggle = inject("stores") (
    observer (
        class NavigationToggle extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    shop: this.storeGeneral.shop
                };
            }

            componentDidMount = () => {
                /*
                 * SbC DEPRICATED
                 * Always enter on Zelfhulpboeken
                 */
                const shop = this.storeGeneral.getActiveShop();
                  this.setState({
                    shop: shop
                })
            }

            componentDidUpdate = async(prevProps) => {
                // if (prevProps.active_nav !== this.props.active_nav) {
                //     this.setState({
                //         active_nav: this.props.active_nav
                //     })
                // }

                // if (prevProps.activeShop !== this.props.activeShop) {
                //     this.setState({
                //         active_nav: this.props.active_nav
                //     })
                // }
            }

            removeSort = () => {
                const sorting_dir = 'DESC';
                const type = 'ID';

                this.storeUi.setPageView('sorting', type);
                this.storeUi.setPageView('order', sorting_dir);
            }

            handleNavClick = async(id, path) => {
                let results;

                ui.handleScroll('top');
                this.storeUi.setSearchOverlay(false);
                
                this.storeUi.showTooltip('tip', 0);
                this.storeUi.showTooltip('explanation', 0);

                this.storeUi.setActiveMainNav(0);
                this.storeUi.setActiveSubNav(0);

                this.storeUi.setActiveToggleNav(id);

                this.removeSort();

                // this.setState({
                //     active_nav: id
                // })

                switch(id) {
                    case 1:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        this.storeGeneral.setSearchShop('OWN');
                        //results = await this.storeUi.getSearchResultsSimple('SHOP_SB', this.storeUi.search_value, 'sb', this.storeUi.page_view); 
                        break;
                    case 2:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        this.storeGeneral.setSearchShop('SB');
                        //results = await this.storeUi.getSearchResultsSimple('SHOP_SB', this.storeUi.search_value, '', this.storeUi.page_view); 
                        break;
                    case 3:
                        this.storeGeneral.setShop("SHOP_ALL");
                        this.storeGeneral.setActiveShop("SHOP_ALL");
                        this.storeGeneral.setSearchShop('ALL');
                        //results = await this.storeUi.getSearchResultsSimple('SHOP_ALL', this.storeUi.search_value, '', this.storeUi.page_view, false); 
                        break;
                    default:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        //results = await this.storeUi.getSearchResultsSimple('SHOP_SB', this.storeUi.search_value, '', this.storeUi.page_view); 
                }

                const params = queryString.parse(this.props.location.search);
                const search_term = params.q;

                //if (search_term === '' || search_term === undefined) {
                    this.props.history.push(path);
                //}
            }

            render() {              
                return (
                    <div className="navigation--toggle">
                        <ul className="navigation--toggle__list">
                            {this.storeUi.toggle_nav.map((item, i) => (
                                <NavigationToggleListitem
                                    key = {i}
                                    item = {item}
                                    id = {item.id}
                                    sku = {item.tooltip}
                                    title = {i18n.t("navigation." + item.name)}
                                    subtitle = {i18n.t("navigation." + item.name + "_sub")}
                                    path = {item.path}
                                    tooltip = {item.tooltip}
                                    handleNavClick = {() => this.handleNavClick(item.id, item.path)}
                                />
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(NavigationToggle));
