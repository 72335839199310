import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import TemplateForms from '../../templates/template-forms';

import FormTextIncomplete from '../../elements/form-text-incomplete';
import RowText from '../form-rows/row-text';
import RowTextArea from '../form-rows/row-textarea';

const FormContact = inject("stores") (
    observer (
        class FormContact extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeForms = this.props.stores.storeForms;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                
                this.state = {
                    formControls: { 
                        firstname: {
                            type: "text",
                            label: i18n.t("forms.label.firstname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.firstname : '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        lastname: {
                            type: "text",
                            label: i18n.t("forms.label.lastname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.lastname : '',
                            validationRules: {},
                            valid: false
                        },         
                        email: {
                            type: "email",
                            label: i18n.t("forms.label.email"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.email : '',
                            validationRules: {
                                isRequired: true, 
                                isEmail: true
                            },
                            valid: false
                        },
                        phonenr: {
                            type: "text",
                            label: i18n.t("forms.label.phonenr"),
                            value: this.props.use_case === "incart" ? this.storeOrder.order_data.client_profile.phonenr : this.storeUser.user_data.profile.phonenr,
                            validationRules: {
                                isPhoneNumber: true
                            },
                            valid: false
                        },
                        question: {
                            type: "textarea",
                            label: i18n.t("forms.label.question"),
                            placeholder: i18n.t("forms.placeholder.question", {maxlength: this.storeUi.settings_list["maxLengthProfile"]}),
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        }
                    },
                    formIsValid: false,
                    formIsSubmitted: false,
                    btn_class: '--disabled',
                    error: false
                }
            }

            componentWillUnmount = () => {
                this.storeForms.clearFormFeedback();
            }

            componentDidMount = () => {
                this.initForm();
            }

            initForm = () => {
                const updatedControls = this.storeForms.initForm(this.state.formControls);

                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateRow = (name, value, valid) => {      
                this.storeForms.clearFormFeedback();

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            sendContactForm = (e) => {
                e.preventDefault();

                if (this.state.formIsValid) {
                    this.storeForms.sendFormContact(this.state.formControls);
                    this.afterSubmit('disable');
                }
            }

            afterSubmit = (action) => {  
                if (action === 'disable') {
                    this.setState({
                        formIsValid: false,
                        formIsSubmitted: true,
                        btn_class: '--disabled'
                    }); 
                }
            } 

            render() {
                return (
                    <TemplateForms
                        formClass = "contact"
                        type = 'vraag/opmerking'
                        msg_success = {i18n.t("form_feedback.success_contact")}
                    >
                        <form className="form form--contact">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                <React.Fragment>
                                    {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email") &&
                                        <RowText 
                                            type = {this.state.formControls[key].type}
                                            name = {key}
                                            label = {this.state.formControls[key].label}
                                            value = {this.state.formControls[key].value}
                                            validationRules = {this.state.formControls[key].validationRules}
                                            onValidate = {this.validateRow}
                                        />
                                    }
                                    {this.state.formControls[key].type === "textarea" &&
                                        <RowTextArea 
                                            name = {key}
                                            label = {this.state.formControls[key].label}
                                            value = {this.state.formControls[key].value}
                                            placeholder = {i18n.t("forms.placeholder.question2", {count: parseInt(this.storeUi.settings_list["maxLengthProfile"])})}
                                            maxLength = {this.storeUi.settings_list["maxLengthProfile"]}
                                            validationRules = {this.state.formControls[key].validationRules}
                                            onValidate = {this.validateRow}
                                        />
                                    }
                                </React.Fragment>
                            ))}

                            {this.state.btn_class !== '--active' && !this.state.formIsSubmitted &&
                                <FormTextIncomplete />
                            }
                        </form>

                        <div className="form-row form-row--buttons">
                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.sendContactForm(e)}>{i18n.t("button.submit")}</button>
                        </div>

                        <div className="form__feedback">
                            {'success' in this.storeForms.form_feedback &&
                                <span>{i18n.t("form_feedback.success", {type: 'vraag/opmerking'})}</span>
                            }
                            {'error' in this.storeForms.form_feedback &&
                                <span>{i18n.t("form_feedback.error", {type: 'vraag/opmerking'})}</span>
                            }
                        </div>
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormContact);
