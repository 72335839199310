import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { render } from 'react-dom';

const ImgWithFallback = ({
    src,
    fallback,
    bem,
    type = 'image/webp',
    ...delegated
  }) => {
    return (
      <picture>
        <source 
            srcSet={src} 
            type={type} 
            className = {bem}
            onError = {() => this.props.onError()} 
        />
        <img 
            src = {fallback}
            onError = {() => this.props.onError()} 
            {...delegated} />
      </picture>
    );
  };

const ImgCover = inject("stores") (
    observer (
        class ImgCover extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;
                this.storeGeneral = this.props.stores.storeGeneral;
                
                this.dir_fallback = [
                    "/public/images/CoversVKg-all/",
                    "/public/images/CoversVKg-all/",
                    "/public/images/CoversVKg/",
                    "/public/images/CoversVK/"
                ]

                this.state = {
                    current: 0,
                    not_available: false
                }
            }

            componentDidMount = () => {
                // this.setState({
                //     image: this.state.current === 0
                //         ?   this.props.sku && (this.urlPublic + "/public/images/CoversVKg-all/" + this.props.sku + ".png")
                //         :   this.props.sku && (this.urlPublic + this.dir_fallback[this.state.current] + this.props.sku + ".jpg"),
                //     not_available: false
                // })
                this.setState({
                    image: this.state.current === 0
                        ?   this.props.sku && (this.urlPublic + "/public/images/CoversVKg-webp/" + this.props.sku + ".webp")
                        :   this.props.sku && (this.urlPublic + this.dir_fallback[this.state.current] + this.props.sku + ".png"),
                    not_available: false
                })
            }

            componentDidUpdate(prevProps) {
                if (prevProps.sku !== this.props.sku) {
                    this.setState({
                        current: 0,
                        //image: this.props.sku && (this.urlPublic + "/public/images/CoversVKg-all/" + this.props.sku + ".png"),
                        image: this.props.sku && ("https://www.succesboeken.nl/api/public/images/CoversVKg-webp/" + this.props.sku + ".webp"),
                        not_available: false
                    });
                }
            }

            handleError = () => {
                let ext;

                if ((this.state.current >= 0) && (this.state.current < this.dir_fallback.length)) {
                    if (this.state.current === 0) {
                        ext = 'png';
                    } else {
                        ext = 'jpg';
                    }
                    this.setState({
                        image: this.urlPublic + this.dir_fallback[this.state.current] + this.props.sku + "." + ext,
                        //image: "https://www.succesboeken.nl/api/public/images/CoversVKg-webp/" + this.props.sku + ".webp",
                        current: this.state.current + 1,
                        not_available: false
                    });
                } else {
                    this.setState({
                        image: this.urlPublic  + "/public/images/img-not-available.png",
                        title: this.props.title,
                        not_available: true
                    })
                }
            }

            render() {
                const aspectRatio = this.props.aspect ? " " + this.props.aspect : ""

                return (
                    <React.Fragment>
                        {/* <ImgWithFallback
                            src = {this.state.image} 
                            fallback = {this.state.image} 
                            bem = {"cover" + aspectRatio} 
                            alt = {this.props.title ?  this.props.title : this.props.sku} 
                            onError = {() => this.handleError()} 

                        /> */}

                        <img 
                            onError = {() => this.handleError()} 
                            src = {this.state.image} 
                            className = {"cover" + aspectRatio + (this.props.soldout ? " --soldout" : "")} 
                            alt = {this.props.title ?  this.props.title : this.props.sku} 
                            loading = "lazy"
                        />

                        {this.state.not_available &&
                            <div className="cover-title--not-available">
                                {this.props.title &&
                                    <React.Fragment>
                                        <span className="cover-title--not-available__caption">Titel:</span>
                                        <span className="cover-title--not-available__txt" dangerouslySetInnerHTML={{ __html: this.state.title}} />
                                    </React.Fragment>
                                }
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default ImgCover;