import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import MetaTags from 'react-meta-tags';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import * as ui from '../../utilities/ui';

import Template from '../../templates/template';
import FormEventCouponCheck from '../../components/forms/form-event-couponcheck';
import FormEventSubscription from '../../components/forms/form-event-subscription';

const EventSubscription = inject("stores") (
    observer (
        class EventSubscription extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeEvents = this.props.stores.storeEvents;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    show_code_check: false,
                    show_orm: false
                }

                this.blockRef = React.createRef();
                this.blockRef2 = React.createRef();
            }

            componentDidMount = async() => {
                window.scrollTo(0, 0)

                const event = await this.storeEvents.getEvent('eventtest');
            }

            handleGotoTicket = (e) => {
                const sku = this.storeEvents.event.sku;
                let url = "/book/" + sku;
                this.props.history.push(url);
            }

            handleGotoRegister = (e) => {
                this.setState({
                    show_code_check: true
                }, () => this.scrollToCheck())
            }

            handleGotoForm = (e) => {
                this.setState({
                    show_form: true
                }, () => this.scrollToForm())
            }

            scrollToCheck = () => {
                ui.smoothScrollTo(0, this.blockRef.offsetTop - 80, 500)
            }

            scrollToForm = () => {
                ui.smoothScrollTo(0, this.blockRef2.offsetTop - 80, 500)
            }
      
            render() {
                return (
                    <Template
                        type = {'single-column'}
                        header = {"event_subscription"}
                        headerCustom = {"Ja, ik wil graag deelnemen aan " + this.storeEvents.event.title}
                        class_page = 'content-events'
                    >
                        <MetaTags>
                            <title>
                                Ja, ik wil graag deelnemen
                            </title>
                            <meta 
                                name="description" 
                                content="Neem contact met ons op" 
                            />
                            <meta 
                                property="og:title" 
                                content = 'Succesboeken.nl | Contact' 
                            />
                            <meta 
                                property="og:image" 
                                content="https://www.succesboeken.nl/api/public/images/branding/sb_logo-img.png" 
                            />
                            <link 
                                rel="canonical" 
                                href = "https://www.succesboeken.nl/remarks" 
                            />
                        </MetaTags>
                                
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p
                                    dangerouslySetInnerHTML={{ __html: this.storeEvents.event.description}}
                                />
                            </div>
                        </div>  

                        {(this.storeEvents.event.organisation || this.storeEvents.event.logo1 || this.storeEvents.event.logo2) &&    
                            <div className="content-segment">
                                <div className="content-segment__content">
                                    <h2>Organisatie</h2>
                                    {this.storeEvents.event.organisation &&
                                        <p>{this.storeEvents.event.organisation}</p>
                                    }
                                    {(this.storeEvents.event.logo1 || this.storeEvents.event.logo2) &&
                                        <div
                                            className="content__logos"
                                        >
                                            {this.storeEvents.event.logo1 &&
                                                <div
                                                    className="content__logo"
                                                >
                                                    <img src={this.urlAPI + "/public/images/events/" + this.storeEvents.event.logo1} />
                                                </div>
                                            }
                                            {this.storeEvents.event.logo1 &&
                                                <div
                                                    className="content__logo --color"
                                                >
                                                    <img src={this.urlAPI + "/public/images/events/" + this.storeEvents.event.logo2} />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        <div className="content-segment">
                            <div className="content-segment__content content__event-data">
                                <h2>Evenementgegevens</h2>
                                <div className="event-data">
                                    <div className="event-data__item-header">
                                        <h3>Datum</h3>
                                    </div>
                                    <div className="event-data__item">
                                        <p>Woensdag 27 september 2023</p>
                                    </div>
                                    <div className="event-data__item-header">
                                        <h3>Aanvang</h3>
                                    </div>
                                    <div className="event-data__item">
                                        <p>19.30 u.<br/>
                                        De zaal is open vanaf 19.00 u.</p>
                                    </div>
                                    <div className="event-data__item-header">
                                        <h3>Eindtijd</h3>
                                    </div>
                                    <div className="event-data__item">
                                        <p>&plusmn; 23.00 u.<br/>
                                        Dit is mede afhankelijk van de vragen die gesteld worden na afloop.</p>
                                    </div>
                                    <div className="event-data__item-header">
                                        <h3>Locatie</h3>
                                    </div>
                                    <div className="event-data__item">
                                        <p>Antropia<br/>
                                        Cultuur- en Congrescentrum<br/>
                                        Hoofdstraat 8<br/>
                                        3972 LA Driebergen
                                        </p>
                                    </div>
                                    <div className="event-data__item-header">
                                        <h3>Bereikbaarheid</h3>
                                    </div>
                                    <div className="event-data__item">
                                        <h4>Per auto</h4>
                                        <p>Parkeren is mogelijk in Q-park P&R garage.<br/>
                                        Navigeer naar:<br/>Stationsweg 15B of Oude Stationsweg 13, 3972 LA Driebergen<br/>
                                        Antropia ligt op een paar minuten lopen.</p>
                                        <h4>Per trein</h4>
                                        <p>Antropia ligt op een paar minuten lopen van treinstation Driebergen-Zeist.</p>
                                    </div>
                                    <div className="event-data__item-header">
                                        <h3>Toegangsprijs</h3>
                                    </div>
                                    <div className="event-data__item">
                                        <p>&euro; 20,-.</p>
                                    </div>
                                    <div className="event-data__item-header">
                                        <h3>Toegangskaarten</h3>
                                    </div>
                                    <div className="event-data__item">
                                        <p>Kaarten kun je aanschaffen in de webshop.</p>
                                        <p>Vergeet niet om bij afname van meerdere kaarten, de namen van de deelnemers in te vullen in het opmerkingenveld bij je bestelling.</p>
                                        <div 
                                            className="event-data__actions"
                                        >
                                            <a 
                                                className={"button button--primary button"} 
                                                onClick={(e) => this.handleGotoTicket(e)}
                                            >
                                                {i18n.t("button.goto_ticket")}
                                            </a>
                                        </div>            
                                        <p>Degene die 'vriend' zijn van de Stichting Clintel (jaarlijkse donatie) hoeven geen kaart te kopen; wel is het nodig je hier te registreren voor het evenement.</p>
                                        {/* <div 
                                            className="event-data__actions"
                                        >
                                            <a 
                                                className={"button button--primary button"} 
                                                onClick={(e) => this.handleGotoRegister(e)}
                                            >
                                                {i18n.t("button.goto_register_clintel")}
                                            </a>
                                        </div> */}
                                        <p>HELAAS IS DIT EVENT UITVERKOCHT</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div 
                            className="content-segment" 
                            ref={ (ref) => this.blockRef=ref }
                        >
                            <div className="content-segment__content">
                                <div 
                                    className={"content-segment__content--code-check" + (this.state.show_code_check ? " --active" : " --inactive")}
                                >
                                    <h2>Couponcode</h2>
                                    <p>Vul de per e-mail ontvangen couponcode in</p>
                                    <FormEventCouponCheck 
                                        event = {this.storeEvents.event.reference}
                                        code = {this.storeEvents.event.couponCode}
                                        handleSubmit = {this.handleGotoForm}
                                    />
                                </div>
                                <div
                                    className={"content-segment__content--form" + (this.state.show_form ? " --active" : " --inactive")}
                                    ref={ (ref) => this.blockRef2=ref }
                                >
                                    <h2>Registratieformulier</h2>
                                    {this.storeEvents.event.explanationForm &&
                                        <p>{this.storeEvents.event.explanationForm}</p>
                                    }
                                    <FormEventSubscription 
                                        event = {this.storeEvents.event}
                                        eventRef = {this.storeEvents.event.reference}
                                    />
                                </div>
                            </div>
                        </div>                           
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(EventSubscription));
