import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

const BlockDownloads = inject("stores") (
    observer (
        class BlockDownloads extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeBooks = this.props.stores.storeBooks;

                this.state = ({
                    downloads: []
                })
            }

            componentDidMount = async() => {
                const downloads = await this.storeBooks.getBookDownloads(this.props.sku);
                // console.log('SbC has download!', this.props.sku, this.storeBooks.list_downloads)
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.sku !== this.props.sku) {
                    const downloads = this.storeBooks.getBookDownloads(this.props.sku);
                }
            }

            render() {
                return (
                    this.storeBooks.list_downloads.length > 0 && 
                        <div className="block--downloads">
                            <h4>Beschikbare downloads bij dit boek</h4>
                            <ul>
                                {this.storeBooks.list_downloads.map((download) => (
                                <li>
                                    <a href={this.urlAPI + "/public/downloads/" + download.filename}
                                        target="_blank"
                                        className="link--pdf"
                                    >
                                        {download.title}
                                    </a>
                                </li>
                                ))}
                            </ul>
                        </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockDownloads);

