import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import TemplateSidebarRight from '../../templates/template-sidebar-right';

import CarouselBooks from '../content/carousel-books';

const BlockNewestBooks = inject("stores") (
    observer (
        class BlockNewestBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }
 
            render() {
                return (
                    <TemplateSidebarRight
                        class_modifier = "newest-books"
                        block_title = {i18n.t("block.newest.header")}
                        block_intro = {i18n.t("block.newest.intro")}
                    >
                        <div className="carousel--newest-books">
                            <CarouselBooks 
                                bookList = {this.storeBooks.bookList_newest} 
                                play = {this.storeUi.carousel_home}
                            />
                        </div>
                    </TemplateSidebarRight>
                )
            }
        }
    )
)

export default withTranslation()(BlockNewestBooks);

