import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as ui from '../utilities/ui';

const ButtonPagingFForward = inject("stores") (
    observer (
        class ButtonPagingFForward extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    active: true,
                    btnClass: "--active"
                }
            }

            componentDidMount = () => {
            }

            componentDidUpdate = (prevProps) => {
            }

            pagingFForward = (btnClass) => {
                if (btnClass === '--active') {
                    this.setPage(btnClass);
                }
            }

            setPage = (btnClass) => {
                let count;
                let added = 6;

                if (this.state.active) {
                    //SbC check for max
                    count = this.storeUi.page_view.rangeTo + 6;
                    if (count >= this.props.pages) {
                        added = this.props.pages - this.storeUi.page_view.rangeTo;
                        //added = count - this.props.pages;
                    }

                    const page = this.props.page + added;
                    const from = this.storeUi.page_view.rangeFrom + added;
                    const to = this.storeUi.page_view.rangeTo + added;

                    this.storeUi.setPageView('page', page);
                    this.storeUi.setPageView('rangeFrom', from);
                    this.storeUi.setPageView('rangeTo', to);

                    this.props.setPage(page);

                    if (count >= this.props.pages) {
                        this.setState({
                            active: true,
                            btnClass: "--active"
                        })
                    }
                }

                ui.handleScroll('top');
                this.storeUi.setFreezeScrolling(false);
            }

            render() {  
                let total = this.storeUi.page_view.total;

                if (this.props.type == 'search') {
                    if (this.storeGeneral.searchShop == 'OWN') {
                        total = this.storeUi.search_count_own;
                    }
                    if (this.storeGeneral.searchShop == 'SB') {
                        total = this.storeUi.search_count_sb;
                    }
                    if (this.storeGeneral.searchShop == 'ALL') {
                        total = this.storeUi.search_count_all;
                    }
                }

                const inview = this.storeUi.page_view.inview;
                let pages = Math.ceil(parseInt(total) / parseInt(inview));

                const btnClass = this.props.pages < 6 
                    ?   '--inactive' 
                    :   this.storeUi.page_view.rangeTo === pages
                        ?   '--inactive'
                        :   '--active'

                return (
                    <div 
                        className = {"paging-forward-wrapper " + btnClass} 
                        onClick = {(e) => this.pagingFForward(btnClass)}
                    >
                        <div className="paging-forward-wrapper__icon" />
                        <div className="paging-forward-wrapper__icon" />
                    </div>
                )
            }
        }
    )
)

export default withRouter(ButtonPagingFForward);
