import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Swiper, Slide } from 'react-dynamic-swiper'
import { withRouter } from "react-router-dom";
import 'swiper/css/swiper.css';

import * as content from '../../utilities/content';

import ImgCover from '../../elements/img-cover';

const CarouselBooks = inject("stores") (
    observer (
        class CarouselBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.play !== this.props.play) {
                    if (this.props.play === 'play') {
                        this.reactSwiper.swiper().autoplay.start();
                    } else {
                        this.reactSwiper.swiper().autoplay.stop();
                    }
                }
            }

            handleAddToCart = (e, book) => {
                e.preventDefault();
                e.stopPropagation();
                const value = 1;
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                this.storeOrder.addToCart(this.storeGeneral.shop, id, book, 'add', value);

                this.storeUi.openDialog();
            }

            handleOnClick = (e, book) => {
                const sku = book.sku;
                const id = content.formatTitle(book.title);

                //SbC reset left nav
                this.storeUi.setActiveSubCategory('');
                this.storeUi.setActiveCategory('');
                this.storeUi.setActiveToggleNav(1);
                
                this.storeUi.setCurrentBook(sku);

                //SBC DEPRICATED
                //this.storeBooks.getBookDetailsBySku(sku);
                
                this.setState({
                    sku: sku
                });

                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    url = "/book/" + sku + "/" + id;
                } else {
                    url = "/book/" + sku;
                }
                this.props.history.push(url);

                //SbC slide to next
                this.reactSwiper.swiper().slideNext()
            }

            goNext = () => {
                this.reactSwiper.swiper().slidePrev();
            }
             
            goPrev = () => {
                this.reactSwiper.swiper().slideNext();
            }

            checkSliderPos = () => {
                this.setState({
                    classPrevBtn: this.reactSwiper.swiper().isEnd ? 'swiper-button-disabled' : '',
                    classNextBtn: this.reactSwiper.swiper().isBeginning ? 'swiper-button-disabled' : ''
                });
            }
  
            render() {
                return (
                    <div className="carousel--books">
                        <Swiper 
                            ref={(c) => { this.reactSwiper = c }}
                            swiperOptions={{
                                slidesPerView: 1,
                                autoplay: this.storeUi.carousel_home === 'play' 
                                    ?   {
                                            delay: 3000,
                                            disableOnInteraction: false
                                        }
                                    :   false,
                                on: {
                                    slideChangeTransitionEnd: () => {
                                        this.checkSliderPos();
                                    }
                                },
                                loop: false,
                                speed: 750
                            }}
                            {...this.state.options}
                        >
                            {this.props.bookList.map((book, i) => (
                                <Slide key={i}>
                                    <div className="carousel--books__slide --link" onClick={(e) => this.handleOnClick(e, book)}>
                                        <div className="carousel--books__slide-content">
                                            <ImgCover
                                                sku = {book.sku}
                                            />
                                        </div>
                                        <span className="btn btn--icon carousel--books__btn--add" onClick={(e) => this.handleAddToCart(e, book)}>Add</span>
                                    </div>
                                </Slide>
                            ))}
                        </Swiper>
                        <div className={"swiper-button-prev-out " + this.state.classPrevBtn} onClick={() => this.goPrev()}></div>
                        <div className={"swiper-button-next-out " + this.state.classNextBtn} onClick={() => this.goNext()}></div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CarouselBooks);

