import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../templates/template';

import BlocksBooks from '../components/blocks/blocks-books';

const Products = inject("stores") (
    observer (
        class Products extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                }
                
                this.storeUi = this.props.stores.storeUi;
                this.storeProducts = this.props.stores.storeProducts;
            }

            componentDidMount = async() => {
                let total;
                let products;
                let parentgroup = '';

                let params = this.props.match.params;
                if (params.group !== undefined) {
                    parentgroup = await this.storeProducts.checkMainGroup(params.group);
                    if (parentgroup !== '') {
                        this.storeUi.setActiveProductGroup(params.group);
                        this.storeUi.setActiveSubGroup('');
                        this.storeUi.setActiveCategory('');
                        this.storeUi.setActiveLanguage('');

                        products = await this.storeProducts.getProductsForCategory(this.storeUi.active_product_group, this.storeUi.page_view);
                        total = await this.storeUi.getProductCountForCategory(this.storeUi.active_product_group);
                    } else {
                        parentgroup = await this.storeProducts.getMainGroup(params.group);
                        this.storeUi.setActiveProductGroup(parentgroup);
                        this.storeUi.setActiveSubGroup(params.group);
                        this.storeUi.setActiveCategory('');
                        this.storeUi.setActiveLanguage('');

                        products = await this.storeProducts.getProductsForSubGroup(this.storeUi.active_product_group, this.storeUi.active_subgroup, this.storeUi.page_view);
                        total = await this.storeUi.getProductCountForSubGroup(this.storeUi.active_product_group, this.storeUi.active_subgroup);
                    }
                    this.storeUi.setNavMobile(false);
                } 
                
                this.storeUi.setPageView('total', this.storeUi.count_total)
                this.handleScroll();
            }
           
            componentDidUpdate = async () => {
                let total;
                let products;
                const product_group = this.props.group;
                const subgroup = this.props.subgroup;
                
                if (subgroup !== undefined && subgroup !== '') {
                    products = await this.storeProducts.getProductsForSubGroup(product_group, subgroup, this.storeUi.page_view);
                    total = await this.storeUi.getProductCountForSubGroup(product_group, subgroup);
                } else {
                    this.storeProducts.getProductsForCategory(product_group, this.storeUi.page_view);
                    total = await this.storeUi.getProductCountForCategory(product_group);
                }

                this.storeUi.setPageView('total', this.storeUi.count_total);
                this.handleScroll();
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            handleType = (e, audio) => {
                this.setState({
                    value: audio.value
                })
                //this.storeProducts.getAudioBooks(audio.query_value);
            }
      
            render() {
                return (
                    <Template
                        type = 'default'
                    > 
                        <div className="content-segment">
                            <BlocksBooks 
                                type = "products"
                                group = {this.props.group} 
                                subgroup = {this.props.subgroup}
                                // group = {this.storeUi.active_product_group}
                                // subgroup = {this.storeUi.active_subgroup}
                            />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Products;
