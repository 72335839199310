import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import NewWindow from 'react-new-window';
import { RedditShareCount } from 'react-share';

const ShareMastodon = inject("stores") (
    observer (
        class ShareMastodon extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    share_window: false
                }
            }

            componentDidMount = () => {
                
            }

            componentDidUpdate = (prevProps) => {
                
            }

            handleOnClick = () => {
                this.setState({
                    share_window: !this.state.share_window
                })
            }
            
            render() {
                const title = 'Lees ook eens dit boek'
                const hashtags = ''
                const author = this.storeUi.settings_list['socialAuthor']
                const instance = this.storeUi.settings_list['urlMastodon']
                const mastodon_url = instance + "share?text=" + encodeURIComponent(title + "\n\n" + this.props.url + "\n\n" + hashtags + " " + author);

                return (
                    <React.Fragment>
                    <div className="social-link--mastodon" onClick={() => this.handleOnClick()}></div>

                    {this.state.share_window &&
                        <NewWindow
                            url = {mastodon_url}
                        >
                        </NewWindow>
                    }
                    </React.Fragment>
                )
            }
        }
    )
)

export default ShareMastodon;

