import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import moment from "moment";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as utilities from '../../utilities/formats';
import AccountCommissionPayouts from './account-commissions-payouts';

const AccountCommissionsOverviewIntervalItem = inject("stores") (
    observer (
        class AccountCommissionsOverviewIntervalItem extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    commissions: [],
                    showInterval: false
                }
            }

            componentDidMount = async() => {
                const invoiceId = this.props.orderItem.id_invoice;
                const order = await this.storeOrder.getDataInvoice(invoiceId);

                this.setState({
                    partner_perc_sb_current: order[0].partner_perc_sb_current,
                    partner_perc_3rd_current: order[0].partner_perc_3rd_current
                })
            }

            componentDidUpdate = async(prevProps) => {
                const invoiceId = this.props.orderItem.id_invoice;

                if (prevProps.orderItem.id !== this.props.orderItem.id) {
                    const order = await this.storeOrder.getDataInvoice(invoiceId);

                    this.setState({
                        partner_perc_sb_current: order[0].partner_perc_sb_current,
                        partner_perc_3rd_current: order[0].partner_perc_3rd_current
                    })
                }
            }

            getPercFee = (orderItem) => {
                let percFee = 0;

                if (orderItem.item_discount == 1) {
                    percFee = this.state.partner_perc_sb_current;
                    if (!percFee || percFee === null) {
                        percFee = 30;
                    }
                } else {
                    percFee = this.state.partner_perc_3rd_current;
                    if (!percFee || percFee === null) {
                        percFee = 10;
                    }
                }
                
                return percFee;
            }

            showIntervalData = () => {
                this.setState({
                    showInterval: !this.state.showInterval
                })
            }

            render() {
                const vat_nr = this.storeUser.user_data.profile.nrVAT;
                const orderItem = this.props.orderItem

                return (
                    <React.Fragment>
                        <li className="table--financial__row">
                            <div className="table--financial__cell table--financial__cell--date">{orderItem.order_date && moment(orderItem.order_date.date).format("DD-MM-YYYY")}</div>
                            {/* <div className="table--financial__cell table--financial__cell--date">{order.id_invoice}</div> */}
                            <div 
                                className="table--financial__cell table--financial__cell--title"
                                dangerouslySetInnerHTML={{ __html: orderItem.item_description}}
                            />
                            <div className="table--financial__cell table--financial__cell--amount">{orderItem.item_amount}</div>
                            <div className="table--financial__cell table--financial__cell--amount">
                                {vat_nr !== ''
                                    ?   utilities.displayPrice(orderItem.order_total)
                                    :   utilities.displayPrice(this.storeUser.commissionOrderExclVat(orderItem.order_total, orderItem.order_vat))
                                }
                            </div>
                            <div className="table--financial__cell table--financial__cell--amount">{this.getPercFee(orderItem)}%</div>
                            <div className="table--financial__cell table--financial__cell--amount">
                                {vat_nr !== ''
                                ?   utilities.displayPrice(this.storeUser.commissionPartnerInclVat(orderItem, orderItem.order_total, this.getPercFee(orderItem)))
                                :   utilities.displayPrice(this.storeUser.commissionPartnerExclVat(orderItem, orderItem.order_total, this.getPercFee(orderItem), orderItem.order_vat))
                                }
                            </div>
                        </li>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(AccountCommissionsOverviewIntervalItem);


