import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as login from '../communicator/login';

class StoreAuth {
    user = {
        user_id: 0,
        is_loggedin: false,
        is_admin: false,
        is_reseller: false,
        is_partner: false,
        access_level: 0,
        no_shipping_costs: false
    }

    constructor() {

    }

    async checkLogin(formControls) {
        let result;
        const data = {
            email: formControls.email.value,
            pw: formControls.password.value
        };
        
        try {
            const returnData = await login.checkLogin(data);
            runInAction(() => {
                result = returnData.data[0];
                if (!('error' in result)) {
                    this.user.user_id = result.id;
                    this.user.access_level = result.access_level;
                    this.user.is_loggedin = true;
                    this.user.is_admin = (result.admin === "on" || result.admin === "1")
                        ?   true
                        :   false
                    this.user.is_partner = (result.is_partner === "on" || result.is_partner === "1")
                        ?   true
                        :   false
                    this.user.is_reseller = (result.is_reseller === "on" || result.is_reseller === "1")
                        ?   true
                        :   false
                    this.user.no_shipping_costs = (result.no_shipping_costs === "on" || result.no_shipping_costs === "1")
                        ?   true
                        :   false
                } else {
                    this.user.is_loggedin = false;
                }
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.user.is_loggedin = false;
            })
        }

        return result;
    }

    refreshAuth(data) {
        if ('is_partner' in data) {
            this.user.is_partner = ((data.is_partner === "on" || data.is_partner === "1") && data.partner_code !== '')
                ?   true
                :   false
        }
    }

    setAuth(data) {
        if ('id' in data) {
            this.user.user_id = data.id;
        };
        if ('user_id' in data) {
            this.user.user_id = data.user_id;
        };
        this.user.access_level = data.access_level;
        if ('user_id' !== '' && 'user_id' !== '0' && 'user_id' !== 0) {
            this.user.is_loggedin = true;
        }
        if ('is_loggedin' in data) {
            this.user.is_loggedin = data.is_loggedin;
        }
        if ('is_admin' in data) {
            this.user.is_admin = (data.is_admin === "on" || data.is_admin === "1" || data.is_admin === true)
                ?   true
                :   false
        }
        if ('admin' in data) {
            this.user.is_admin = (data.admin === "on" || data.admin === "1")
                ?   true
                :   false
        }
        this.user.is_partner = data.is_partner;
        this.user.is_reseller = data.is_reseller;
        if ('no_shipping_costs' in data) {
            this.user.no_shipping_costs = data.no_shipping_costs;
        }
    }

    setLoginState(login) {
        this.user.is_loggedin = login;

        if (!login) {
            this.setLogoutState();
        }
    }

    setLogoutState() {
        this.user.user_id = 0;
        this.user.access_level = 0;
        this.user.is_loggedin = false;
        this.user.is_admin = false;
        this.user.is_partner = false;
        this.user.is_reseller = false;
        this.user.partner_status = '';
        this.user.no_shipping_costs = 0;
    }
}

decorate(StoreAuth, {
    user: observable,
    checkLogin: action,
    refreshAuth: action,
    setAuth: action,
    setLoginState: action,
    setLogoutState: action
})

export default StoreAuth;