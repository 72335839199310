import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import BlockCover from '../block/block-cover';

const BlockBooksPromoBook = inject("stores") (
    observer (
        class BlockBooksPromoBook extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {}
            }

            componentDidMount = async() => {
                const shop = 'SHOP_SB';
                const book = await this.storeBooks.getBookBySku(this.props.sku, shop);

                this.setState({
                    book: book
                })
            }

            handleOnClick = () => {
                const url = '/site-explanation';
                this.props.history.push(url);
            }
  
            render() {              
                return (
                    <div className="block__books--promo-book">
                        {this.state.book &&
                            <BlockCover 
                                sku = {this.props.sku}
                                book = {this.state.book}
                            />
                        }
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockBooksPromoBook));


