import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18n from "i18next";
import { reaction } from 'mobx';

import * as ui from '../../utilities/ui';

const BlockEBook = inject("stores") (
    observer (
        class BlockEBook extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.state = {
                    redirect: false,
                    foldout: false
                }

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeEBooks = this.props.stores.storeEBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
                if (this.props.ebook.has_paper_version || this.props.ebook.isSupplement) {
                    this.getEBookDetails();
                }
            }

            getEBookDetails = async() => {
                const ebook = await this.storeEBooks.getEBookDetails(this.props.ebook.sku);
                this.setState({
                    ebook: ebook,
                    stock: ebook && ebook.stock
                })
            }

            handleOnClick = (e, book) => {
                if (this.props.ebook.has_paper_version === 1) {
                    this.storeUi.setCurrentBook(book.sku);
                    this.storeBooks.getBookDetailsBySku(book.sku);

                    this.setState({
                        ebook_sku: book.sku,
                        redirect: true
                    })
                }
            }

            handleToLogin = (e) => {
                e.preventDefault();
                ui.handleScroll('top');
            }

            handleAddToCart = (e, book) => {
                e.preventDefault();
                e.stopPropagation();
                const value = 1;
                const id = (this.storeAuth.user.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                this.storeOrder.addToCart(this.storeGeneral.shop, id, book, 'add', value);
                this.storeUi.openDialog();
                
                this.setState({
                    redirect: false
                });
            }

            handleDownload = (e) => {
                e.preventDefault();
                e.stopPropagation();

                const url = this.urlAPI  + "/public/ebooks/" + this.props.ebook.url_download + ".pdf";
                window.open(url);
            }

            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/book/" + this.state.ebook_sku} 
                    />;
                }

                return (
                    <li className="block block--book">
                        <div className="block-content">
                            <div className="block-content__column">
                                <div 
                                    className={"block--ebook__image" + (this.props.ebook.has_paper_version === 1 ? " --link" : "")}
                                    onClick={(e)=>this.handleOnClick(e, this.props.ebook)}
                                >
                                    <img 
                                        src={this.urlAPI  + "/public/images/ebooks/" + this.props.ebook.cover} 
                                    />
                                </div>
                            </div>

                            <div className="block-content__column">
                                <div className="block-content--book__titles">
                                    {this.props.view !== 'covers' && 
                                        <div 
                                            className={"block-content--book__title" + (this.props.ebook.has_paper_version === 1 ? " --link" : "")} 
                                            onClick={(e)=>this.handleOnClick(e, this.props.ebook)}
                                        >
                                            {this.props.ebook.title}
                                        </div>
                                    }

                                    {this.props.view !== 'covers' && 
                                        <span 
                                            className="block-content--book__subtitle"
                                            dangerouslySetInnerHTML={{ __html: this.props.ebook.subtitle}}
                                        />
                                    }

                                    {(this.props.view === 'summaries' || this.props.view === 'list') &&
                                        <span className="block--ebook__author">{this.props.ebook.author}</span>
                                    }

                                    {(this.props.view === 'summaries' && this.props.ebook.language === 'EN') &&
                                        <div className="block--specs-summary__list">
                                            <ul>
                                                <li className="block--specs-summary__listitem">
                                                    {this.props.view !== 'covers' && 
                                                        
                                                        <span 
                                                            className="block-content--book__subtitle"
                                                        >
                                                            Engelstalig
                                                        </span>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    }                                   
                                </div>

                                {this.props.view === 'summaries' &&                 
                                    <span 
                                        className="block--ebook__descr"
                                        dangerouslySetInnerHTML={{ __html: this.props.ebook.txtDescription}}
                                    />
                                }

                                {(this.props.view === 'summaries' && this.props.ebook.txtRemark !== null && this.props.ebook.txtRemark !== '') &&
                                    <span 
                                        className="block--ebook__remark"
                                        dangerouslySetInnerHTML={{ __html: this.props.ebook.txtRemark}}
                                    />
                                }

                                {this.props.view === 'summaries' && 
                                    <React.Fragment>
                                    {this.props.ebook.has_paper_version === 1
                                    ?   <div>
                                            <span className="block--ebook__options">
                                                Je kunt een pdf downloaden of een<span 
                                                    onClick={(e)=>this.handleOnClick(e, this.props.ebook)} 
                                                    className="link--read-more">
                                                        papieren exemplaar
                                                </span>
                                                bestellen
                                            </span>
                                        </div>
                                    :   this.props.ebook.url_download &&
                                        <div>
                                                <span className="block--ebook__options">Alleen als e-book beschikbaar</span>
                                        </div>
                                    }
                                    </React.Fragment>
                                }

                                <div className="block--ebook__actions">
                                    {!this.storeAuth.user.is_loggedin &&
                                        <span onClick={(e) => this.handleToLogin(e)} className="link--read-more">Log in of meld je aan voor gratis download</span>
                                    }
                                    {this.storeAuth.user.is_loggedin &&
                                        this.props.ebook.url_download
                                        ?   <div className="block--ebook__btn">
                                                {this.props.view === 'summaries' &&
                                                    <span>Download pdf</span>
                                                }
                                                <div 
                                                    className="block--ebook__btn--download"
                                                    onClick={(e) => this.handleDownload(e)}
                                                />
                                            </div>
                                        :   <div className="block--ebook__btn">
                                                {this.props.view === 'summaries' &&
                                                    <span>Download pdf</span>
                                                }
                                                <div 
                                                    className="block--ebook__btn--download --disabled"
                                                />
                                            </div>
                                    }
                                    {this.props.ebook.has_paper_version === 1 &&
                                        <div className="block--ebook__btn">
                                            {this.props.view === 'summaries' &&
                                                <span>Koop een papieren exemplaar</span>
                                            }
                                            {(this.state.stock === 1 || this.state.stock === '1')
                                            ?   <div className="block--ebook__btn--add --link" onClick={(e) => this.handleAddToCart(e, this.state.ebook)}>Add</div>
                                            :   <div className="block--ebook__btn--add --disabled">Add</div>
                                            }
                                        </div>
                                    }
                                </div>

                                {(this.props.view === 'summaries' && (this.props.ebook.isSupplement === '1' || this.props.ebook.isSupplement === 1)) && 
                                <React.Fragment>
                                    <span 
                                        className="block--ebook__txtsupplement"
                                        dangerouslySetInnerHTML={{ __html: this.props.ebook.txtSupplement}}
                                    />
                                
                                    <div className="block--ebook__actions">
                                        <div className="block--ebook__btn">
                                            {this.props.view === 'summaries' &&
                                                <span>Koop een papieren exemplaar</span>
                                            }
                                            {(this.state.stock === 1 || this.state.stock === '1')
                                            ?   <div className="block--ebook__btn--add --link" onClick={(e) => this.handleAddToCart(e, this.props.ebook)}>Add</div>
                                            :   <div className="block--ebook__btn--add --disabled">Add</div>
                                            }
                                        </div>
                                    </div>
                                   </React.Fragment>
                                }

                            </div>
                        </div>
                    </li>
                )
            }
        }
    )
)

export default BlockEBook;

