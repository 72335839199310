import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

const BlocksCategories = inject("stores") (
    observer (
        class BlocksCategories extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirect: false,
                    foldout: false
                }
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            handleOnClick = (e, cat_name) => {
                this.storeUi.setActiveCategory(cat_name);
                this.setState({
                    redirect: true,
                    cat_name: cat_name
                });
            }

            expandBlocks = (e) => {
                this.setState(prevState => ({
                    foldout: !prevState.foldout
                }));
            }
  
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/category/" + this.state.cat_name} 
                    />;
                }

                const blockTitle = (this.props.blockTitle) ? this.props.blockTitle : 'Categorieën';
                // let categoriesList = [];
                // const idx = this.storeBooks.genresList.findIndex(genre => genre.id === this.props.genre_id)

                // if (idx === null || idx === -1) {
                //     categoriesList = this.storeBooks.categoriesList;
                // } else {
                //     let connList = this.storeBooks.connCategoriesGenresList
                //         .filter((conn) => conn.genre_id == this.props.genre_id)

                //      connList.map((conn) => {
                //         let catList = this.storeBooks.categoriesList
                //             .filter((cat) => cat.category_id == conn.category_id);
                //         categoriesList.push(catList[0]);
                //         return categoriesList;
                //     })                   
                // }

                return (
                    <div className="blocks blocks--categories">
                        <div className="blocks__blocks-title">
                            <div className="blocks__blocks-title--main">
                                {blockTitle}
                            </div>
                        </div> 
                        <div className="blocks__content">
                            <ul className={"blocks__list " + (this.state.foldout ? "--expanded" : "--collapsed")}>
                                {this.storeBooks.categoriesList.map((cat, i) => ( 
                                    cat.name !== '' && cat.name !== null &&
                                        <li key={i} className="block block--category">
                                            <div className="block--category__title" onClick={(e)=>this.handleOnClick(e, cat.name)}>{cat.name}</div>
                                        </li>
                                ))}
                                <li className="actionShowMore" onClick={(e) => this.expandBlocks(e)}>
                                    {this.state.foldout 
                                        ? "Toon minder categorieen"
                                        : "Toon alle categorieen"
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlocksCategories);

