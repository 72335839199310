import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import i18n from "i18next";

import * as account from '../../communicator/account';

import AccountOpenOrder from './account-open-order';
import AccountOpenOrderPayment from './account-open-order-payment';
import AccountOrderPaid from './account-order-paid';

const AccountOrders = inject("stores") (
    observer (
        class AccountOrders extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    order_id: 0,
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
                if (this.storeUser.user_data.is_loggedin) {
                    (async() => await this.storeOrder.getAccountOrders(this.storeUser.user_data.user_id) )();
                }
            }

            componentDidUpdate = () => {
                
            }

            setView = (view) => {
                this.storeUi.setAccountView(view);
            }

            render() {
                const orderList = this.storeUser.user_data.is_loggedin ? this.storeOrder.orderHistoryList : []

                let orderList_paid = [];
                let orderList_open = [];

                orderList.length > 0 && !('error' in orderList[0]) && (
                    orderList_paid = orderList
                        .filter((order) => order.order_paid !== null)
                );

                orderList.length > 0 && !('error' in orderList[0]) && (
                    orderList_open = orderList
                        .filter((order) => (order.order_status === 'void' && order.order_paid === null) || (order.order_status === 'banktransfer' && order.order_paid === null) || order.order_paid === null)
                );

                return (
                    <React.Fragment>
                        {this.storeUi.app_interface.view_account == 'overview' &&
                            <React.Fragment>
                                <div className="content">
                                    <h3>Openstaande bestellingen:</h3>
                                    {orderList_open.length > 0 && !('error' in orderList[0])
                                    ?   <ul className="list--orders">
                                            {orderList_open.map((order, i) => (
                                                    <li key={i} className="list--orders__order-item">
                                                        <div>
                                                            <AccountOpenOrder 
                                                                order_id = {order.order_id}
                                                                order = {order}
                                                                setView = {this.setView}
                                                            />
                                                        </div>
                                                    </li>
                                            ))}
                                        </ul>
                                    :   <p>Je hebt geen openstaande bestellingen.</p>
                                    }
                                </div>

                                <div className="content">
                                    <h3>Betaalde bestellingen:</h3>
                                    {orderList_paid.length > 0
                                    ?   <ul className="list--orders">
                                            {orderList_paid.map((order, i) => (
                                                <li key={i} className="list--orders__order-item">  
                                                    <div className="list--orders__details">
                                                        <AccountOrderPaid
                                                            order = {order}
                                                            setView = {this.setView}
                                                        />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    :   <p>Je hebt nog geen bestellingen betaald.</p>
                                    }
                                </div>
                            </React.Fragment>
                        }

                        {this.storeUi.app_interface.view_account == 'payment' &&
                            <AccountOpenOrderPayment 
                                setView = {this.setView}
                            />
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(AccountOrders);


