import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';

const LinkDownload = inject("stores") (
    observer (
        class LinkDownload extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }

            componentDidMount = () => {
                window.scrollTo(0, 0)
            }
      
            render() {
                return (
                    <Template
                        type = {'default'}
                        header = {"link_download"}
                    >
                        <div className="content-segment">
                            <p>Op deze audio MP3 staan de meditaties die behoren bij het boek Jij bent de placebo (ISBN 9789492665034, uitgegeven door Succesboeken.nl) en tevens ook staan afgedrukt achter in het boek. De eerste 9.50 min is een introductie van dr. Joe Dispenza; daarna volgen de meditaties.</p>
                            <p>De meditaties zijn bedoeld om de overtuigingen en percepties te veranderen die je beperken - de eerste stap in genezing. Jij bent de placebo combineert de nieuwste research in neurowetenschap, biologie, psychologie, hypnose, geconditioneerd gedrag en kwantumfysica om de werking van het placebo-effect te ontsluieren ... en laat zien hoe het schijnbaar onmogelijke, mogelijk kan worden.</p>
                            <p>Over de auteur: Dr. Joe Dispenza heeft een passie voor het onderwijzen van anderen hoe de laatste ontdekkingen toe te passen uit de neurowetenschappen en kwantumfysica om hun brein opnieuw te programmeren, ziekte te genezen en een meer vervuld leven te leiden. Als een van de experts uit de film What the BLEEP Do We (K)now!? geeft dr. Joe workshops en lezingen over de hele wereld. Hij heeft ook een programma ontworpen waarmee hij zijn model van transformatie naar het bedrijfsleven brengt.</p>
                            <br/>
                            <p><a href="https://www.succesboeken.nl/api/public/downloads/mp3/Introductie.mp3">Klik hier met rechter muisknop en kies opslaan om de Introductie mp3 te downloaden.</a></p>
                            <p><a href="https://www.succesboeken.nl/api/public/downloads/mp3/Meditatie.mp3">Klik hier met rechter muisknop en kies opslaan om de Meditaties mp3 te downloaden.</a></p>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(LinkDownload);
