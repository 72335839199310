import React, { Component } from 'react';
import { runInAction, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import TemplateForms from '../../templates/template-forms';

import validate from '../../utilities/validation-rules';
import TextInput from '../../elements/form-input-text';

const FormNewslettersSearch = inject("stores") (
    observer (
        class FormNewslettersSearch extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    formControls: {          
                        sarchTerm: {
                            label: i18n.t("forms.label.search_newsletters"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.search_newsletters"),
                            touched: false,
                            validationRules: {
                            },
                            validationMsg: i18n.t("form_errors.search_newsletters"),
                            required: false
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false
                }
            }

            componentDidMount = () => {             
                const updatedControls = {
                  ...this.state.formControls
                };
                let updatedFormElement = {};

                Object.keys(this.state.formControls).map((key, i) => {
                    updatedFormElement = {
                        ...updatedControls[key]
                    };

                    updatedFormElement.value = this.state.formControls[key].value;
                    if (updatedFormElement.value !== null && updatedFormElement.value !== undefined) {
                        updatedFormElement.valid = validate(updatedFormElement.value, updatedFormElement.validationRules);
                    }
                    updatedControls[key] = updatedFormElement;
                });
       
                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }); 
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
          
                updatedControls[name] = updatedFormElement;

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }); 

                this.storeUser.clearFormFeedback();
            }

            searchNewsletters = () => {
                this.props.searchNewsletters(this.state.formControls.sarchTerm.value);
            }

            render() {

                return (
                    <React.Fragment>
                        <div   
                            className="search-newsletters__form"
                        >
                            <div   
                                className="search-newsletters__input"
                            >
                                <form
                                    className="form"
                                >
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        <div className="form-row form-row--input" key={i}>
                                            <label className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}>{this.state.formControls[key].label}</label>
                                            <TextInput 
                                                name = {key} 
                                                placeholder = {this.state.formControls[key].placeholder}
                                                value = {this.state.formControls[key].value}
                                                touched = {this.state.formControls[key].touched}
                                                onChange = {this.handleChange}
                                                valid = {this.state.formControls[key].valid}
                                                msg = {this.state.formControls[key].validationMsg}
                                                is_secret = {this.state.formControls[key].is_secret}
                                                secret = {this.state.formControls[key].secret ? true: false} 
                                                showSecret = {() => this.showSecret(key)}
                                            />
                                        </div>
                                    ))}
                                </form>
                            </div>

                            <div   
                                className="search-newsletters__button"
                            >
                                <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.searchNewsletters(e)}>{i18n.t("button.search")}</button>
                            </div>
                        </div>
                        
                        {this.storeUser.form_feedback &&
                            <div className="form__feedback">
                                {'success' in this.storeUser.form_feedback &&
                                    <span>{i18n.t("form_feedback.success_unsubscribe")}</span>
                                }
                                {'error' in this.storeUser.form_feedback &&
                                    <span>{this.storeUser.form_feedback['error']}</span>
                                }
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(FormNewslettersSearch);
