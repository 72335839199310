import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Template from '../templates/template';

import BlocksTop60 from '../components/blocks/blocks-top60';

const BooksTop60 = inject("stores") (
    observer (
        class BooksTop60 extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUi = this.props.stores.storeUi;

                this.storeUi.setActiveMainNav(3);
            }

            async componentDidMount() {
                this.storeUi.setActiveSubCategory('');
                this.storeUi.setActiveCategory('');

                window.scrollTo(0, 0);
            }
     
            render() {
                return (
                    <Template
                        type = 'default'
                        header = {"books_top60"}
                        expanded = {["categories", "products"]}
                        nav_name = {"books_top60"}
                    >   
                        <div className="content-segment">
                            <p>{i18n.t("page_intros.books_top60")}</p>
                        </div>
                        <div className="content-segment">
                            <BlocksTop60 
                                type = "top60"
                                page = "top60"
                            />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(BooksTop60);
