import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import BlockProductGroup from './block-product-group';

const BlockOtherLanguages = inject("stores") (
    observer (
        class BlockOtherLanguages extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            handleOnClickLanguage = async(e, lang) => {
                this.storeUi.setPageView('page', 1);
                this.storeUi.setActiveProductGroup('');
                this.storeUi.setActiveSubGroup('');
                this.storeUi.setActiveCategory('');
                this.storeUi.setActiveLanguage(lang.abbr);
                this.storeUi.setNavMobile(false);
                
                let idx = this.storeUi.list_languages.findIndex(lang => lang.abbr === this.storeUi.active_language);
                (async() => await this.storeBooks.getBooksForLanguage(this.storeUi.list_languages[idx].query_value, this.storeUi.page_view) )();
                (async() => await this.storeUi.getBookCountForLanguage(this.storeUi.list_languages[idx].query_value) )();

                let url = "/languages/" + lang.display_value;
                this.props.history.push(url);
            }
  
            render() {
                const activeClass = (this.storeUi.active_product_group !== '' && this.storeUi.active_product_group !== 99) ? ' --active' : '' 

                return (
                    <div className="block block--sidebar block--product-groups">
                        {!this.props.clean &&
                            <div className="block__block-title">
                                {i18n.t("block.other_languages.header")}
                            </div> 
                        }
                        <ul className={"block__block-list" + activeClass}>
                            {this.storeUi.list_languages.map((lang, i) => ( 
                                <React.Fragment key={i}>
                                    {this.storeGeneral.shop === 'SHOP_SB' && lang.show_on_sb === 1 &&
                                        <li>
                                            <span 
                                                className={"block--categories__lang" + (lang.abbr == this.storeUi.active_language ? ' --active' : '')} 
                                                onClick={(e)=>this.handleOnClickLanguage(e, lang)}
                                                dangerouslySetInnerHTML={{ __html: lang.display_value}}
                                            />
                                        </li>
                                    }
                                    {this.storeGeneral.shop === 'SHOP_ALL' && lang.show_on_all === 1 &&
                                        <li>
                                            <span 
                                                className={"block--categories__lang" + (lang.abbr == this.storeUi.active_language ? ' --active' : '')} 
                                                onClick={(e)=>this.handleOnClickLanguage(e, lang)}
                                                dangerouslySetInnerHTML={{ __html: lang.display_value}}
                                            />
                                        </li>
                                    }
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockOtherLanguages));

