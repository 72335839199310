import React from 'react';
import i18n from "i18next";

const Salutation = (props) => {

    return (
        <span 
            className="content__salutation" 
            dangerouslySetInnerHTML={{ __html: i18n.t("inline_text.salutation")}}
        />
    );
}

export default Salutation;