import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Template from '../../templates/template';
import FormBusiness from '../../components/forms/form-business';
import FormPartner from '../../components/forms/form-partner';
import FormPassword from '../../components/forms/form-password';
import FormProfile from '../../components/forms/form-profile';

import TextArea from '../../elements/form-input-textarea';
import TextInput from '../../elements/form-input-text';
import Checkbox from '../../elements/form-input-checkbox';
import RadioYesNo from '../../elements/form-input-radio-yesno';

import ContentText from '../../components/content/content-text';
import PartnerAgreementText from './partner-agreement-text';

const PartnerRegistration = inject("stores") (
    observer (
        class PartnerRegistration extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    btn_class: '--disabled',
                    login: false,
                    "profile": {
                        formControls: {},
                        formIsValid: false
                    },
                    "password": {
                        formControls: {},
                        formIsValid: false
                    },
                    "business": {
                        formControls: {},
                        formIsValid: true
                    },
                    "partner": {
                        formControls: {},
                        formIsValid: false
                    },
                    formControls: {
                        motivation: {
                            label: i18n.t("forms.label.motivation"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.motivation"),
                            validationRules: {
                                isRequired: true
                            },
                            required: true,
                            valid: true
                        },
                        url_bookpage_yesno: {
                            label: i18n.t("forms.label.url_bookpage_yesno"),
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            options: [{
                                value: 1,
                                displayValue: 'Ja'
                            },{
                                value: 0,
                                displayValue: 'Nee'
                            }],
                            required: true,
                            valid: true
                        },
                        url_bookpage: {
                            label: i18n.t("forms.label.url_bookpage"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.url_bookpage"),
                            valid: true
                        },
                        subscribe: {
                            label: i18n.t("forms.label.subscribe"),
                            value: true,
                            touched: false,
                            valid: true
                        },
                        no_spam: {
                            label: i18n.t("forms.label.no_spam"),
                            value: false,
                            touched: false,
                            valid: true,
                            required: true
                        },
                        agreement: {
                            label: i18n.t("forms.label.agreement"),
                            value: false,
                            touched: false,
                            valid: true,
                            required: true
                        }
                    },
                    formIsValid: false,
                    recalc: false,
                    feedback: false,
                    str_motivation: 750
                };
                
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
            }

            componentDidMount() {
                //window.scrollTo(0, 0);
            }

            componentWillUnmount = () => {
                this.storeUser.form_feedback = {};
            }

            calcRemaining(name, str, max) {
                const l = str.length;
                const remaining = max - str.length;
                this.setState({
                    [name]: remaining
                })
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
          
                updatedControls[name] = updatedFormElement;

                if (name === 'motivation') {
                    this.calcRemaining('str_motivation', value, 750);
                }

                this.setState({
                    formControls: updatedControls,
                }, () => {
                    this.checkFormRegistrationIsValid();
                    this.setPartnerDataExtra();
                })               
            }

            handleCheckboxChange = (event) => {
                const name = event.target.name;

                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                ...updatedControls[name]
                };
                updatedFormElement.value = !this.state.formControls[name].value;
                updatedFormElement.selected = !this.state.formControls[name].value ? 1 : 0
                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                }, () => {this.checkFormRegistrationIsValid()})
            }

            handleFormChange = (formControls, formIsValid, section) => {
                Object.keys(this.state).map((key, i) => {
                    if (key == section) {
                        let updatedControls = {
                            ...this.state[key]
                        };
                        const updatedFormElement = {
                            formControls
                        };
                        updatedControls = updatedFormElement;
                        updatedControls['formIsValid'] = formIsValid;
        
                        this.setState({
                            [key]: updatedControls,
                            formIsValid: !formIsValid ? false : this.state.formIsValid,
                            btn_class: !formIsValid ? '--disabled' : this.state.btn_class
                        }, () => {this.checkFormRegistrationIsValid()})
                    }
                })

                this.storeUser.setPartnerData(section, formControls)
                this.storeUser.clearFormFeedback();
            }

            handleOnClick = (e, path) => {
                if (path === '/login') {
                    this.props.history.push(path);
                }
            }

            checkFormRegistrationIsValid() {
                let inputsValid;
                let checksValid = true;
                let motivationValid = false;
                let urlValid = false;

                if (this.storeUser.user_data.is_loggedin) {
                    inputsValid = (this.state.profile.formIsValid && this.state.business.formIsValid && this.state.partner.formIsValid) ? true : false;
                } else {
                    inputsValid = (this.state.profile.formIsValid &&  this.state.password.formIsValid && this.state.business.formIsValid && this.state.partner.formIsValid) ? true : false;
                    checksValid = (this.state.formControls.no_spam.value === true && this.state.formControls.agreement.value === true) ? true : false;
                }
                motivationValid = this.state.formControls.motivation.value !== '' ? true : false;
                urlValid = ((parseInt(this.state.formControls.url_bookpage_yesno.value) === 1 && this.state.formControls.url_bookpage.value !== '') || parseInt(this.state.formControls.url_bookpage_yesno.value) === 0 ) ? true : false;

                this.setState({
                    formIsValid: (inputsValid && checksValid && motivationValid && urlValid) ? true : false,
                    btn_class: (inputsValid && checksValid && motivationValid && urlValid) ? '--active' : '--disabled'
                });
            }

            setPartnerDataExtra = () => {
                let partnerDataExtra = {
                    'motivation' : this.state.formControls.motivation.value,
                    'url_bookpage' : this.state.formControls.url_bookpage.value
                }

                this.storeUser.setPartnerDataExtra(partnerDataExtra);
            }

            submitFormPartner = () => {
                if (this.state.formIsValid) {
                    this.storeUser.submitFormPartner();
                    window.scrollTo(0, 0);
                    this.setState({
                        feedback: true
                    })
                }
            }

            toggleSection = () => {
                this.setState({
                    login: !this.state.login
                })
            }

            onNextAction = ()  => {
                this.setState({
                    login: false,
                    recalc: true
                })
            }

            openPS = () => {
                this.storeUi.setContentComponent(
                    <ContentText
                        title = {i18n.t("page_headers.privacy_policy")}
                        page = {"privacy-policy"}
                        className = "content-scrollable"
                    />
                );
                this.storeUi.openDialogContent();
            }

            openPartnerAgreement = () => {
                this.storeUi.setContentComponent(
                    <PartnerAgreementText 
                        title = {i18n.t("page_headers.partner_agreement")}
                        className = "content-scrollable"
                    />
                );
                this.storeUi.openDialogContent();
            }

            render() {
                const TextCheckbox = (props) => {
                    switch(props.section) {
                        case 'subscribe':
                            return (
                                <React.Fragment>
                                    <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.subscribe1")}}></span>
                                    <a href="#" onClick={(e) => this.openPS(e)}>privacy statement</a>
                                    <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.subscribe2")}}></span>
                                    <br/>
                                    <span>Partners worden altijd op de hoogte gehouden.</span>
                                </React.Fragment>
                            )
                        case 'no_spam':
                            return (
                                <React.Fragment>
                                    <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.no_spam")}}></span>
                                </React.Fragment>
                            )
                        case 'agreement':
                            return (
                                <React.Fragment>
                                   <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.agreement1")}}></span>
                                    <a href="#" onClick={(e) => this.openPartnerAgreement(e)}>overeenkomst partner-programma</a>
                                    <span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.agreement2")}}></span>
                                </React.Fragment>
                            )
                    }
                }    

                return (
                    <Template
                        type = 'default'
                        header = {this.storeUser.user_data.partner_status === 'pend' 
                            ?   "partner_pending"
                            :   this.state.feedback
                                ?   "partner_processing"
                                :   "partner_signup"
                            }
                        noScroll = {true}
                    >   
                        <div className="content-segment">
                            {!this.state.login && (this.storeUser.user_data.partner_status !== 'on' && this.storeUser.user_data.partner_status !== 1 && this.storeUser.user_data.partner_status !== 'pend') && !this.state.feedback &&
                                <div className={"content-section--register " + (!this.state.login ? "--active" : '')}>
                                    <div className="content-segment__content">
                                        {this.storeUser.user_data.is_loggedin
                                            ?   <p>Wijzigingen via dit formulier worden ook in je accountgegevens aangebracht.</p>
                                            :   <p>{i18n.t("pages.partner.txt_haslogin")} <span className="content__textlink"onClick={(e)=>this.handleOnClick(e, '/login')}>{i18n.t("pages.partner.link_haslogin")}</span></p>
                                        }

                                        <div className="form-wrapper__help">
                                            <span className="form-wrapper__help--required">{i18n.t("form_feedback.required")}</span>
                                        </div>
                                    </div>

                                    <div className="form-fieldset">
                                        <div className="form-wrapper__row">
                                            <span className="form__fieldset-title">Persoonlijke gegevens</span>
                                        </div>

                                        <div className="form-wrapper">
                                            <FormProfile 
                                                view = {'partner'}
                                                form = {'partner'}
                                                handleFormChange = {this.handleFormChange} 
                                                client_profile = {this.storeUser.user_data.profile}
                                                use_case = {"inshop"}
                                                loggedin = {this.storeUser.user_data.is_loggedin}
                                                verifyEmail = {true}
                                                option_email = {true}
                                                option_subscription = {false}
                                            />
                                        </div>
                                    </div>
                                    
                                    {!this.storeUser.user_data.is_loggedin &&
                                        <div className="form-fieldset">
                                            <div className="form-wrapper__row">
                                                <span className="form__fieldset-title">Wachtwoord</span>
                                            </div>
                                            <FormPassword 
                                                handleFormChange = {this.handleFormChange} 
                                                required = {true}
                                            />
                                        </div>
                                    }
                                    
                                    <div className="form-fieldset">
                                        <div className="form-wrapper__row">
                                            <span className="form__fieldset-title">Partner gegevens</span>
                                            <span className="form__fieldset-subtitle">Vul onderstaande gegevens in in verband met uitbetalingen</span>
                                        </div>
                                        <FormPartner 
                                            handleFormChange = {this.handleFormChange} 
                                            data = {this.storeUser.user_data.partner}
                                        />
                                    </div>

                                    <div className="form-fieldset">
                                        <div className="form-wrapper__row">
                                            <span className="form__fieldset-title">Zakelijke gegevens</span>
                                            <span className="form__fieldset-subtitle">Vul onderstaande gegevens in, indien u geen particulier bent</span>
                                        </div>
                                        <FormBusiness 
                                            handleFormChange = {this.handleFormChange} 
                                            data = {this.storeUser.user_data.business}
                                            country_code = {this.state.profile.formControls.country_select && this.state.profile.formControls.country_select.value}
                                        />
                                    </div>
                                  
                                    <div className="form-fieldset">
                                        <div className="form-wrapper__row">
                                            <span className="form__fieldset-title">En verder</span>
                                        </div>
                                            <form className="form">
                                                <label className={"form-label" + (this.state.formControls.motivation.required ? ' --required' : '')}>{this.state.formControls.motivation.label}</label>
                                                <TextArea 
                                                    name = "motivation"
                                                    maxLength = {this.storeUi.settings_list["maxLengthProfile"]}
                                                    placeholder = {this.state.formControls.motivation.placeholder}
                                                    value = {this.state.formControls.motivation.value}
                                                    onChange = {this.handleChange}
                                                    valid = {this.state.formControls.motivation.valid}
                                                />
                                                <div className="form-column--feedback">
                                                    <span>nog {this.state.str_motivation} karakters over</span>
                                                </div>
                                                <label className={"form-label" + (this.state.formControls.url_bookpage_yesno.required ? ' --required' : '')}>{this.state.formControls.url_bookpage_yesno.label}</label>
                                                <RadioYesNo 
                                                    name = "url_bookpage_yesno" 
                                                    placeholder = {this.state.formControls.url_bookpage_yesno.placeholder}
                                                    value = {this.state.formControls.url_bookpage_yesno.value}
                                                    options = {this.state.formControls.url_bookpage_yesno.options}
                                                    direction = {"horizontal"}
                                                    onChange = {this.handleChange}
                                                    defaultValue = {this.state.formControls.url_bookpage_yesno.defaultValue}
                                                    selected = {this.state.formControls.url_bookpage_yesno.value}
                                                />
                                                <label className={"form-label" + (this.state.formControls.url_bookpage.required ? ' --required' : '')}>{this.state.formControls.url_bookpage.label}</label>
                                                <TextInput 
                                                    name = {'url_bookpage'} 
                                                    placeholder = {this.state.formControls['url_bookpage'].placeholder}
                                                    value = {this.state.formControls['url_bookpage'].value}
                                                    onChange = {this.handleChange}
                                                    valid = {this.state.formControls['url_bookpage'].valid}
                                                />
                                            </form>
                                    </div>
                                    
                                    <div className="content-segment__content">
                                        <form className="form">
                                            {Object.keys(this.state.formControls).map((key, i) => (
                                                (key !== 'motivation' && key !== 'url_bookpage_yesno' && key !== 'url_bookpage') &&
                                                    ((!this.storeUser.user_data.is_loggedin) || (this.storeUser.user_data.is_loggedin && key === 'subscribe')) &&
                                                        <div className="form-row form-row--input" key={i}>
                                                            <Checkbox 
                                                                name = {key} 
                                                                label = {<TextCheckbox 
                                                                    section = {key}
                                                                />}
                                                                isSelected = {this.state.formControls[key].value}
                                                                onCheckboxChange={this.handleCheckboxChange}
                                                                className = {"--reduced --noflex"}
                                                                required = {this.state.formControls[key].required}
                                                            />
                                                        </div>
                                            ))}
                                            
                                            {this.state.error &&
                                                <div className="form-row form-row--error">
                                                    <p>{i18n.t("errors.error_general")}</p>
                                                </div>
                                            }
                                        </form>
                                    </div>
                                    
                                    <div className="form-row form-row--buttons">
                                        <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.submitFormPartner(e)}>{i18n.t("button.submit_request")}</button>
                                    </div>
                                    <div className="form__feedback">
                                        {this.storeUser.form_feedback.msg && this.storeUser.form_feedback.msg.success &&
                                            <span>{this.storeUser.form_feedback.msg.success}</span>
                                        }
                                        {this.storeUser.form_feedback.msg && this.storeUser.form_feedback.msg.error &&
                                            <span className="form-field--error">{this.storeUser.form_feedback.msg.error}</span>
                                        }
                                    </div>
                                </div>
                            }

                            {!this.state.login && (this.storeUser.user_data.partner_status === 'on' || this.storeUser.user_data.partner_status === 1) &&
                                <div>Je bent reeds als partner geregistreerd.</div>
                            }

                            {!this.state.login && this.state.feedback &&
                                <div>
                                    <span>Dank voor je partneraanvraag.</span>
                                    <p>Deze wordt zo spoedig mogelijk beoordeeld.</p>
                                    
                                    <h3>Wat is het vervolg?</h3>
                                    <p>Wij zullen zo snel mogelijk per email laten weten of het assortiment boeken en producten van Succesboeken.nl in overeenstemming is met de door jou aangegeven website.</p>
                                    <p>Gezien de intentie van Succesboeken.nl - mensen helpen beter te worden - passen onze boeken en producten namelijk niet op iedere website.</p>
                                    <p>We laten dat weten door je een partnercode te sturen. Heb een paar dagen geduld.</p>
                                </div>
                            }
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(PartnerRegistration);
