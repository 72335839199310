import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import ImgCover from '../../elements/img-cover';
import ButtonDialog from '../../elements/button-dialog';

const DialogCompareWishLists = inject("stores") (
    observer (
        class DialogCompareWishLists extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeOrder = this.props.stores.storeOrder;
                this.storeWishlist = this.props.stores.storeWishlist;
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.props.onClose();
                }
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                return (
                    <div className="overlay">
                        <div className="dialog dialog--notification">
                            <div className="dialog-header">
                                {this.storeWishlist.wishlist.user.length > 0
                                ?   <h2>{this.props.title}</h2>
                                :   <h2>Verlanglijstje</h2>
                                }
                            </div>
                            <div className="dialog-content">
                                {/* {this.storeWishlist.wishlist.user.length > 0
                                ?   <React.Fragment>
                                        <p>Er bestaan twee verlanglijstjes:<br/>1 van een niet-ingelogde versie en 1 van een sessie waarbij je wel was ingelogd.</p>
                                        <p>Beide lijstjes kunnen door verschillende personen aangemaakt zijn.</p>
                                    </React.Fragment>
                                :   <p>Er is mogelijk iemand anders geweest die een verlanglijstje heeft gemaakt tijdens een niet-ingelogde sessie:</p>
                                } */}
                            
                                {/* <h4>Verlanglijstje van een niet-ingelogde sessie:</h4>
                                <ul className="dialog-content__list dialog-content__list--covers">
                                    {this.storeWishlist.wishlist.anonymous.map(item => (
                                        <li className="dialog-content__listitem">
                                            <ImgCover
                                                sku = {item.sku}
                                                aspect = {'--fixed-height'}
                                            />
                                        </li>
                                    ))}
                                </ul> */}

                                {/* {this.storeWishlist.wishlist.user.length > 0 &&
                                <React.Fragment>
                                    <h4>Verlanglijstje van de ingelogde sessie:</h4>
                                    <ul className="dialog-content__list dialog-content__list--covers">
                                        {this.storeWishlist.wishlist.user.map(item => (
                                            <li className="dialog-content__listitem">
                                                <ImgCover
                                                    sku = {item.sku}
                                                    aspect = {'--fixed-height'}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                                } */}

                                {/* {this.storeWishlist.wishlist.user.length > 0
                                ?    <p>Wil je deze verlanglijstjes samenvoegen?</p>
                                :    <p>Mag dit verlanglijstje van jou worden?</p>
                                } */}

<p>
                                    Er stonden nog producten op je verlanglijstje van een vorig bezoek. Deze zijn nu samengevoegd.
                                </p>
                            </div>
                            {this.props.buttons &&
                                <div className="dialog-footer">
                                    {this.props.buttons.map((button, i) => (
                                        <ButtonDialog
                                            key = {i}
                                            caption = {this.storeWishlist.wishlist.user.length > 0 
                                                ? button.text 
                                                : (button.action === 'keep' ? 'Nee, wel bewaren' : 'Ok!')
                                            }
                                            onAction = {button.onClick}
                                            btnClass = {button.btnClass}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                );
            }
        }
    )
)

export default DialogCompareWishLists;
