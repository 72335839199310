import React from 'react';
import { observer } from "mobx-react";
import PropTypes from 'prop-types';
import i18n from "i18next";

import ButtonDialog from '../../elements/button-dialog';

export default class DialogVideo extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount = () => {
        document.addEventListener("keydown", this.escFunction, false);
    }
    
    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose();
        }
    }

    handleClose = (e) => {
        this.props.onClose();
    }

    render() {
        if(!this.props.show) {
            return null;
        }

        let dialogClass = "dialog--";

        if (this.props.children.props && this.props.children.props.className && this.props.children.props.className !== '') {
            dialogClass = dialogClass + this.props.children.props.className;
        } else {
            dialogClass = dialogClass + 'content';
        }

        return (
            this.props.children.props && this.props.children.props.overlay === false
            ?   <div className={"dialog " + dialogClass}>
                    <div className="btn--close" onClick={(e) => this.handleClose(e)}><div className="btn--icon --link icon--close">Sluit</div></div>
                    <div className="dialog-header">
                        <h2>{this.props.children.props.title}</h2>
                    </div>
                    <div className="dialog-content">
                        {this.props.children}
                    </div>
                    {this.props.buttons &&
                        <div className="dialog-footer">
                            {this.props.buttons.map((button, i) => (
                                <ButtonDialog
                                    key = {i}
                                    caption = {button.text}
                                    onAction = {button.onClick}
                                    btnClass = {button.btnClass}
                                />
                            ))}
                        </div>
                    }
                </div>
            :   <div className="overlay">
                    <div className={"dialog " + dialogClass}>
                        <div className="btn--close" onClick={(e) => this.handleClose(e)}><div className="btn--icon --link icon--close">Sluit</div></div>
                        <div className="dialog-header">
                            <h2>{this.props.children.props && this.props.children.props.title}</h2>
                        </div>
                        <div className="dialog-content">
                            {this.props.children}
                        </div>
                        {this.props.buttons &&
                            <div className="dialog-footer">
                                {this.props.buttons.map((button, i) => (
                                    <ButtonDialog
                                        key = {i}
                                        caption = {button.text}
                                        onAction = {button.onClick}
                                        btnClass = {button.btnClass}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                </div>
            
        );
    }
}
