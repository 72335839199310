import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

const NavigationSb = inject("stores") (
    observer (
        class NavigationSb extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {

            }

            handleNavClick = (e, item) => {
                if (item.name === 'books_all') {
                    this.storeGeneral.setShop('SHOP_ALL');
                    this.storeBooks.getCategories('SHOP_ALL');
                } else {
                    this.storeGeneral.setShop('SHOP_SB');
                    this.storeBooks.getCategories('SHOP_SB');
                }

                this.storeUi.setActiveMainNav(0);
                this.storeUi.setActiveToggleNav(item.id);
                this.storeUi.setActiveSubNav(0);
                this.props.history.push(item.path);
            }

            render() {
                const nav_id = this.storeUi.active_togglenav

                const Li = (item) => {
                    const activeClass = (item.id === nav_id) ? ' --active' : '';
                    return (
                        <li className={"navigation--sb__listitem" + activeClass} onClick={(e) => this.handleNavClick(e, item)}>
                            <span dangerouslySetInnerHTML={{ __html: item.title}}></span>
                        </li>
                    )
                }

                return (
                    <div className="navigation--sb">
                        <ul className="navigation--sb__list">
                            {this.storeUi.toggle_nav.map((item, i) => (
                                <React.Fragment key={i}>
                                    {item.use === 'all' && (item.show && item.show.includes(this.storeGeneral.shop)) && (
                                        <Li 
                                            key = {i}
                                            id = {item.id}
                                            title = {i18n.t("navigation." + item.name)}
                                            path = {item.path}
                                            name = {item.name}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(NavigationSb));
