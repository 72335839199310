import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import * as login from '../../communicator/login';

import TextInput from '../../elements/form-input-text';

const FormGeneratePartnerLink = inject("stores") (
    observer (
        class FormGeneratePartnerLink extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    formControls: {          
                        isbn: {
                            label: i18n.t("forms.label.isbn"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.isbn"),
                            touched: false,
                            validationRules: {
                                isRequired: false
                            },
                            validationMsg: i18n.t("form_errors.isbn")
                        }
                    },
                    formIsValid: false,
                    btn_class: this.props.link_sb ? '--active' : '--disabled',
                    error: false
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentWillUnmount = () => {
                this.storeUser.clearFormFeedback();
            }

            handleChange = event => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
          
                updatedControls[name] = updatedFormElement;

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }); 
            }

            generatePartnerLink = (e) => {
                e.preventDefault();
                let sku;
                if (!this.props.link_sb) {
                    sku = this.state.formControls.isbn.value;
                }  else {
                    sku = 0;
                }
                if (this.state.formIsValid || this.props.link_sb) {
                    this.props.generatePartnerLink(sku);
                }
            } 

            render() {
                return (
                    <div className="form-wrapper">   
                        {!this.props.link_sb &&            
                            <form className="form">
                                {Object.keys(this.state.formControls).map((key, i) => (
                                    <div className="form-row form-row--input" key={i}>
                                        <label className="form-label">{this.state.formControls[key].label}</label>
                                        <TextInput 
                                            name = {key} 
                                            placeholder = {this.state.formControls[key].placeholder}
                                            value = {this.state.formControls[key].value}
                                            touched = {this.state.formControls[key].touched}
                                            onChange = {this.handleChange}
                                            valid = {this.state.formControls[key].valid}
                                            msg = {this.state.formControls[key].validationMsg}
                                        />
                                    </div>
                                ))}
                        </form>
                        }
                        <div className="form-row form-row--buttons">
                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.generatePartnerLink(e)}>{i18n.t("button.generate")}</button>
                        </div>
                     </div>
                )
            }
        }
    )
)

export default withTranslation()(FormGeneratePartnerLink);
