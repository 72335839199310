import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import queryString from 'query-string';

import Template from '../../templates/template';

import FormUnsubscribe from '../../components/forms/form-unsubscribe';

const Unsubscribe = inject("stores") (
    observer (
        class Unsubscribe extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {};
            }

            componentDidMount = () => {
                this.setEmail(this.props.location.search);
            }

            setEmail = async(urlString) => {
                const params = queryString.parse(urlString)
                
                if (params.email) {
                    const email = params.email;
                    this.setState({
                        email: email
                    })
                }
            }
      
            render() {
                return (
                    <Template
                        type = {'single-column'}
                        header = {"unsubscribe"}
                    >
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p>Helaas heb je aangegeven onze e-mails niet meer te willen ontvangen.</p>
                                <p>Echter Succesboeken.nl geeft boeken uit over <b>meer onderwerpen</b> dan het boek waar ons laatste bericht over gaat.</p>
                                <p>Een volgende uitgave kan wel interessant voor je zijn:<br/>
                                Onze boeken hebben o.a. betrekking op financi&euml;n, communicatie, denkpatronen, leiderschap, persoonlijke ontwikkeling en gezondheid. Kortom boeken waar je beter en wijzer van wordt.</p>
                                <p>Indien je zeker weet dat je onze berichtgeving over nieuwe uitgaven niet meer wenst te ontvangen, verstuur dan onderstaand formulier en je bent definitief van de mailinglist verwijderd. Wij respecteren je privacy.</p>
                                <p>Indien je je bedenkt, hoef je verder geen actie te ondernemen.<br/>
                                We blijven je dan op de hoogte houden.</p>
                            </div>

                            <div className="content-segment__content">
                                <FormUnsubscribe 
                                    email = {this.state.email}
                                />
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Unsubscribe;
