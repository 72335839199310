import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import moment from "moment";

const BlockNotifications = inject("stores") (
    observer (
        class BlockNotifications extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    reason: ""
                }
            }

            componentDidMount = async() => {
                if (Array.isArray(this.props.notifications)) {
                    this.props.notifications.map((notification) => {
                        if (this.props.sku === notification['sku']) {
                            this.setState({
                                reason: notification.reason
                            });
                        }
                    })
                }
            }

            componentDidUpdate = async(prevProps) => {

            }

            render() {
                return (
                    <React.Fragment>
                        <div
                            className="block-content--notification"
                            dangerouslySetInnerHTML={{ __html: this.state.reason}}
                        />
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockNotifications);

