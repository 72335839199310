import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import ButtonDialog from '../../elements/button-dialog';

const DialogPayment = inject("stores") (
    observer (
        class DialogPayment extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                const url = this.storeOrder.order_data.order_status.url

                const iframe = '<iframe src="'+ url + '" width="540" height="450"></iframe>';

                return (
                    <div className="overlay">
                        <div className="dialog dialog--form dialog--payment">
                            <div className="dialog-header">
                                <h2>{this.props.title}</h2>
                                <span>{this.props.subtitle}</span>
                            </div>
                            <div className="dialog-content">
                                <div dangerouslySetInnerHTML={{ __html: iframe }} />
                            </div>
                            <div className="dialog-footer">
                                {this.props.buttons && this.props.buttons.map((button, i) => (
                                    <ButtonDialog
                                        key = {i}
                                        caption = {button.text}
                                        onAction = {button.onClick}
                                        btnClass = {button.btnClass}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default DialogPayment;
