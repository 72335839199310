import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as partners from '../communicator/partners';

class StorePartners {

    partnerFees = []

    constructor() {
    }

    async getPartnerFees() {
        let result;

        try {
            const returnData = await partners.getPartnerFees();
            runInAction(() => {
                result = returnData.data;
                this.partnerFees =  returnData.data;

                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
       
        return result;
    }
}

decorate(StorePartners, {
    partnerFees: observable,
    getPartnerFees: action
})

export default StorePartners;