import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { Redirect } from 'react-router';

import FormPayment from '../../components/forms/form-payment';

const CartPayment = inject("stores") (
    observer (
        class CartPayment extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    btn_submit: "active",
                    redirect: false,
                    redirect_complete: false
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;

                this.refActions = React.createRef();
            }

            componentDidMount = () => {
                this.handleScroll();
                this.sendConfirmationOrder();
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            sendConfirmationOrder = () => {

            }

            setCartView = (view) => {
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                this.storeOrder.setCartView(id, view);
                this.handleScroll();
            }

            startPayment = async(payment_id, issuer_code) => {
                //SbC prevent overwriting closed order when on multiple devices
                let order_availability = await this.storeOrder.getOrderAvailability();
                if (order_availability === 'closed') {
                   let order_id = await this.storeOrder.createNewOrder(this.storeOrder.order_data.client_id, this.storeOrder.order_data.order_remark);
                   (async() => await this.storeOrder.saveOrderDataProfile() )();
                }

                if (this.storeOrder.order_data.order_id !== 0) {
                    let url = await this.storeOrder.handlePaymentProcess(this.storeOrder.order_data.order_id, this.storeOrder.order_data.order_guid, payment_id, issuer_code, 'direct_order');
                    if (url !== undefined) {
                        this.setState({
                            redirect: true
                        });
                    }
                }
            }

            scrollToBtn = () => {
                this.refActions.current.scrollIntoView({
                    behavior: 'smooth',
                    block: "end"
                });
            }

            completePayment = async() => {
                (async() => await this.storeOrder.handleBankTransfer() )();
                if (this.storeUser.user_data.is_loggedin) {
                    this.storeOrder.deleteShoppingCartFromDB(this.storeUser.user_data.user_id)
                } else {
                    this.storeOrder.deleteShoppingCartFromLS();
                }
                
                this.setState({
                    redirect_complete: true
                });
            }

            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/payment"} 
                    />;
                }

                if (this.state.redirect_complete) {
                    return <Redirect to={{
                        pathname: '/payment-result',
                        state: { guid: this.storeOrder.order_data.order_guid }
                    }}
                />;
                }

                //const payment_id = this.storeOrder.order_data.billing_address.country_code === this.storeOrder.settings_list.isNL 
                const payment_id = this.storeOrder.order_data.client_profile.country_code === this.storeOrder.settings_list.isNL 
                ?   this.storeOrder.order_data.payment.payment_method.id === 'XX'
                    ?   'IDEAL'
                    :   this.storeOrder.order_data.payment.payment_method.id
                :   this.storeOrder.order_data.payment.payment_method.id === 'XX'
                    ?   'BANKTRANS'
                    :   this.storeOrder.order_data.payment.payment_method.id

                    const issuer_code = this.storeOrder.order_data.payment.payment_issuer.code;

                const formValid = (payment_id === 'IDEAL' && issuer_code === '9999') ? false : true;
                const btn_class = (payment_id === 'IDEAL' && issuer_code === '9999') ? '--disabled' : '--active';

                return (
                    <div className="cart-data">
                        <h2>Kies hoe je wilt betalen</h2>
                        <FormPayment 
                            payment_method_id = {payment_id}
                            //country_code = {this.storeOrder.order_data.billing_address.country_code}
                            country_code = {this.storeOrder.order_data.client_profile.country_code}
                            onScrollToBtn = {this.scrollToBtn}
                        />

                        <div className="cart__actions" ref={this.refActions}>
                            <button className="btn btn--secundary" onClick={(e) => this.setCartView("review")}>{i18n.t("button.backto_review")}</button>
                            {payment_id !== 'BANKTRANS'
                            ?   formValid
                                ?   <button className={"btn btn--primary " + btn_class} onClick={(e) => this.startPayment(payment_id, issuer_code)}>{i18n.t("button.goto_payment_provider")}</button>
                                :   <button className={"btn btn--primary " + btn_class}>{i18n.t("button.goto_payment_provider")}</button>
                            :   <button className="btn btn--primary" onClick={(e) => this.completePayment()}>{i18n.t("button.complete_payment")}</button>
                            }
                        </div>

                        {payment_id !== 'BANKTRANS' &&
                            <div className="cart-data__feedback">
                                <p>Je komt op de volgende pagina in de betaalomgeving van je bank of onze betaalprovider MultiSafepay. Zij regelen alle betalingen voor de webshop van Succesboeken.nl en is een veilige betaalomgeving.</p>
                                <p>Houd dit scherm open totdat de melding 'Sluit het scherm om terug te keren naar de webwinkel' verschijnt.</p>
                            </div>
                        }

                        {payment_id === 'BANKTRANS' &&
                            <div className="cart-data__feedback">
                                <p>Je vindt op de volgende pagina de overschrijvingsgegevens.<br/>Deze zijn ook opgenomen in de bevestigingsmail die je dan toegezonden wordt.</p>
                                <p>Na ontvangst van de betaling wordt de bestelling onmiddellijk toegestuurd.</p>
                            </div>
                        }
                        
                    </div>
                )
            }
        }
    )
)

export default withRouter(CartPayment);
