import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import * as login from '../../communicator/login';

import RowText from '../form-rows/row-text';

const FormCheckEmail = inject("stores") (
    observer (
        class FormCheckEmail extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeWishlist = this.props.stores.storeWishlist;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formView: ['email'],
                    formControls: {          
                        email: {
                            type: 'email',
                            value: '',
                            validationRules: {
                                isRequired: false,
                                isEmail: false
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: false
                        },
                        password: {
                            type: 'password',
                            value: '',
                            validationRules: {
                                isRequired: false
                            },
                            valid: false
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false,
                    error_email: false,
                    show_feedback: false
                }

               
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.syncedEmail !== this.props.syncedEmail) {                   
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls['email']
                    };
                    updatedFormElement['value'] = this.props.syncedEmail;
                    updatedFormElement.touched = true;
                    updatedFormElement.valid = validate(this.props.syncedEmail, updatedFormElement.validationRules);

                    updatedControls['email'] = updatedFormElement;

                    this.setState({
                        formControls: updatedControls
                    }, () => this.validateRow('email', this.props.syncedEmail, updatedFormElement.valid));
                }
            } 

            async checkEmail(email, valid) {
                let emailAvailable = true;

                if (email != '') {
                    emailAvailable = await this.storeUser.checkEmailExists(email);
                }
                this.setState({
                    emailAvailable: emailAvailable,
                    error_email: emailAvailable ? false : true,
                    show_feedback: (email == '' || !valid) ? false : true
                })

                if (email !== '' && valid) {
                    this.props.showFormProfile(true);
                } else {
                    this.props.showFormProfile(false);
                }
            }

            validateRow = async(name, value, valid) => {  
                let emailAvailable = true;
                this.storeUi.clearFormFeedback();
                
                if (name == 'email') {
                    let testValid = validate(value, this.state.formControls.email.validationRules);
                    emailAvailable = await this.checkEmail(value, testValid);
                    this.props.rememberEmail(value);
                }

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, this.state.formControls[name].touched);

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            async loginUser(e) {
                e.preventDefault();
                if (this.state.formIsValid) {
                    this.storeUi.setDirectLogin(false);

                    try {
                        let login = await this.storeAuth.checkLogin(this.state.formControls);
                        runInAction(() => {
                            if (!('error' in login)) {
                                this.storeAuth.setLoginState(true);
                                this.storeUser.setLoginState(true);
                                this.storeOrder.setClientId(login.id);
                                this.storeUser.setUserData(login);
                                this.storeOrder.setOrderProfileFromUser(login);
                                if ((login.is_partner === 'on' || login.is_partner === 1) && login.partner_code !== '') {
                                    this.storeUi.setPartnerCode(login.partner_code);
                                    this.storeOrder.setAffiliateId(login.partner_code);
                                } 
                                this.storeUi.getUserDiscounts();
                                
                                this.storeWishlist.retrieveWishlistFromDB(login.id);

                                if (this.storeUi.app_interface.use_case === "inshop") {
                                    this.storeOrder.retrieveShoppingCartFromDB(login.id, 'login');
                                }

                                if (this.storeUi.app_interface.use_case === "inorder") {
                                    /* SbC concat carts */
                                    this.storeOrder.retrieveShoppingCartFromOrder(login.id);
                                }

                                //SbC check if causes double orderlines after login -> think not
                                this.storeOrder.editOrderItems();

                            } else {
                                this.storeUser.setLoginState(false);
                                this.setState({
                                    error: true
                                })
                            } 
                        })
                    } catch (error) {
                        runInAction(() => {
                            this.storeAuth.setLoginState(false);
                            this.storeUser.setLoginState(false);
                        })
                    }
                }
            }

            render() {

                return (
                    <React.Fragment>
                        <form className="form">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                (key === "email" || (key == 'password' && this.state.error_email) || this.storeUi.app_interface.login_direct) &&
                                    <React.Fragment>
                                        <RowText 
                                                type = {this.state.formControls[key].type}
                                                name = {key}
                                                value = {this.state.formControls[key].value}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                onValidate = {this.validateRow}
                                                syncedEmail = {this.props.syncedEmail}
                                            />

                                        {this.state.show_feedback && !this.storeUi.app_interface.login_direct &&
                                            <div className="form-row form-row--feedback">
                                                {key === "email" && this.state.error_email &&
                                                    <span className="form-field--success --email"><strong>Dit e-mailadres is bekend bij ons.</strong><br/>Vul hieronder je wachtwoord in om je gegevens op te halen.</span>
                                                }

                                                {key === "email" && !this.state.error_email && this.state.formControls.email.value !== '' &&
                                                    <span className="form-field--success --email-unknown">
                                                        <strong>Het blijkt dat dit e-mailadres nog niet bekend is bij ons:</strong><br/>Maak hieronder om privacyredenen, om makkelijker te winkelen en je bestellingen te beheren, een wachtwoord aan en vul je gegevens verder in.</span>
                                                }
                                            </div>
                                        }
                                    </React.Fragment>
                            ))}

                                
                        </form>
                        
                        {(this.state.error_email || this.storeUi.app_interface.login_direct) &&
                            <div className="form-row form-row--buttons">
                                <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.loginUser(e)}>{i18n.t("button.login")}</button>
                            </div>
                        }

                        {this.state.error &&
                            <div className="form-row form-row--error">
                                <p 
                                    dangerouslySetInnerHTML={{ __html: i18n.t("errors.error_login")}}
                                />
                            </div>
                        }
                    </React.Fragment>

                )
            }
        }
    )
)

export default withTranslation()(FormCheckEmail);
