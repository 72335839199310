import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';

const TermsConditions = inject("stores") (
    observer (
        class TermsConditions extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }
    
            render() {
                return (
                    <Template
                        type = {'default'}
                        header = {"general_terms"}
                    >
                        <div className="content-segment">
                            <ContentText
                                page = {"terms-conditions"}
                            />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default TermsConditions;
