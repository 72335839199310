import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

const BlockPreviewPdfExtra = inject("stores") (
    observer (
        class BlockPreviewPdfExtra extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    hasPdf: false,
                    pdfs: []
                }
            }

            componentDidMount() {
                this.setTools();
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.sku !== this.props.sku) {
                    this.setTools();
                }
            }

            componentWillUnmount = () => {

            }

            async setTools() {
                const isbn = this.props.sku;
                if (!this.storeUi.search_overlay) {
                    const pdfs = await this.storeUi.checkPdfExtra(this.props.sku);

                    this.setState({
                        hasPdf: ((pdfs && pdfs.length === 0) || (pdfs && 'error' in pdfs[0])) ? false : true,
                        pdfs: (pdfs && pdfs.length === 0) ? []  : pdfs
                    })
                }
            }

            handleLinkPdf = (filename) => {
                const url = this.urlPublic + "/public/preview-pdf-extra/" + filename + ".pdf";
                window.open(url);
            }

            render() {
                return (this.state.hasPdf
                    ?   this.state.pdfs && this.state.pdfs.map((pdf) => (
                            <div className="book-tools__tool book-tools__tool--pdf">
                                <div 
                                    className="book-tools__tool-link book-tools__tool-link--pdf --link"
                                    onClick={(e) => this.handleLinkPdf(pdf.filename)}
                                />                              
                                {this.props.label &&
                                    <span 
                                        className="book-tools__tool-title --link"
                                        onClick={(e) => this.handleLinkPdf(pdf.filename)}
                                        dangerouslySetInnerHTML={{ __html: pdf.labelText
                                            ?   pdf.labelText
                                            :   i18n.t("book_tools.pdf")
                                        }}
                                    />
                                }
                            </div>
                        ))

                    :   null
                )
            }
        }
    )
)

export default withTranslation()(BlockPreviewPdfExtra);

