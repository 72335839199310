import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import localization from 'moment/locale/nl';
import {isIOS} from 'react-device-detect';
import queryString from 'query-string';

import Template from '../templates/template';
import FormNewslettersSearch from '../components/forms/form-newsletters-search';
import SearchResultNewsletters from '../components/search/search-result-newsletters';

const NewslettersOverview = inject("stores") (
    observer (
        class NewslettersOverview extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    neswletter: {},
                    searchValue: ''
                }
            }

            componentDidMount = async() => {
                let url;
                const newsletters = await this.storeUi.getNewsletters();

                if (this.props.match.params.week) {
                    let wk = this.props.match.params.week;
                    
                    const idx = newsletters.findIndex(newsletter => newsletter.tag === ('mailing' + wk));
                    const newsletter = newsletters[idx];
                    this.storeUi.setActiveNewsletter(newsletter);

                    const search_term = await (async() => this.getSearchParams(this.props.location.search) )();

                    if (search_term == 'pdf') {
                        //if (this.state.newsletter.has_pdf === 1 && this.state.newsletter.has_php === 1) {
                            // url = this.urlAPI + "/" + this.state.newsletter.dir + "/wk" + this.state.newsletter.week + ".php?origin=site";
                            url = this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".php?origin=site";
                        // } else {
                        //     url = this.state.url;
                        // }
                    } else {
                        //if (this.state.newsletter.has_html === 1) {
                            //url = this.state.newsletter.url;
                        // } else {
                             url = this.urlAPI + "/newsletters/newsletter.php?mailing=mailing" + wk + "&origin=site";
                        // }
                    }
                    this.storeUi.openDialogNewsletter(url);
                }
            }

            componentDidUpdate = (prevProps) => {
                let url;
                if (this.props.match.params.week || prevProps.match.params.week !== this.props.match.params.week) {
                    document.body.classList.add('dialog-open');
                    let wk = this.props.match.params.week;
                    
                    if (this.state.newsletter) {
                        if (this.state.view == 'pdf') {
                            if (this.state.newsletter.has_pdf === 1 && this.state.newsletter.has_php === 1) {
                                url = this.urlAPI + "/" + this.state.newsletter.dir + "/wk" + this.state.newsletter.week + ".php?origin=site";
                            } else {
                                url = this.state.url;
                            }
                        } else {
                            if (this.state.newsletter.has_html === 1) {
                                url = this.state.newsletter.url;
                            } else {
                                url = this.urlAPI + "/newsletters/newsletter.php?mailing=mailing" + wk + "&origin=site";
                            }
                        }
                        this.storeUi.openDialogNewsletter(url);
                    }
                }

                if (!this.props.match.params.week) {
                    this.storeUi.closeDialogNewsletter();
                }
            }

            getSearchParams = async(searchString) => {
                const params = queryString.parse(searchString)
                let search_term;

                if (params.view) {
                    search_term = params.view;
                }

                return search_term;
            }
            
            convertWeek = (weeknr) => {
                let year = '20'+ weeknr.substring(0, 2);
                let week = weeknr.substring(2, 4);
                let date = year + '-'+ week;
                let day = moment(date, "YYYY-w").weekday(4).format("LL");

                return day;
            }

            handleClick = (url, newsletter, view) => {
                this.setState({
                    newsletter: newsletter,
                    view: view,
                    url: url
                });

                console.log('sBC newsletter', newsletter)
                this.storeUi.setActiveNewsletter(newsletter);
                document.body.classList.add('dialog-open');

                const wk = newsletter.week;
                const url_dialog = '/overview-newsletters/' + wk + '?view=' + view;
                this.props.history.push(url_dialog);
            }

            handleClickFromSearch = (url, week, view) => {
                // this.setState({
                //     newsletter: newsletter,
                //     view: view,
                //     url: url
                // });

                let newsletter = [];
                newsletter.week = week;

                this.storeUi.setActiveNewsletter(newsletter);
                document.body.classList.add('dialog-open');

                const wk = newsletter.week;
                const url_dialog = '/overview-newsletters/' + wk + '?view=' + view;
                this.props.history.push(url_dialog);
            }

            handleClickOutside = (url, newsletter, view) => {
                this.setState({
                    newsletter: newsletter,
                    view: view,
                    url: url
                });

                this.storeUi.setActiveNewsletter(newsletter);
                // document.body.classList.add('dialog-open');

                // const wk = newsletter.week;
                // const url_dialog = '/overview-newsletters/' + wk + '?view=' + view;
                // this.props.history.push(url_dialog);

                window.open(url);
            }

            searchNewsletters = async(value) => {
                const searchResult = await this.storeUi.getSearchResultsNewsletter(value);

                this.setState({
                    searchValue: value,
                    searchResult: searchResult
                })
            }

            clearResults = () => {
                console.log('SbC clear')
                this.setState({
                    searchValue: '',
                    searchResult: []
                })
            }

            render() {
                let newsletter_list = []
                newsletter_list = this.storeUi.newsletters;

                return (
                    <Template
                        type = 'single-column'
                        header = "newsletter_overview"
                    >
                        <div className="content-wrapper__segment content-wrapper__segment--search-newsletters">
                            <div
                                className = 'search-newsletters__header'
                            >
                                Ben je op zoek naar een link uit &eacute;&eacute;n van onze vorige nieuwsbrieven?
                                <span>(vanaf mei 2021)</span>
                            </div>
                            <FormNewslettersSearch
                                searchNewsletters = {this.searchNewsletters}
                            />
                            <SearchResultNewsletters
                                searchValue = {this.state.searchValue}
                                searchResult = {this.state.searchResult}
                                handleClick = {this.handleClickFromSearch}
                                clearResults = {this.clearResults}
                            />
                        </div>
                        
                        <div className="content-wrapper__segment">
                            <div
                                className = 'search-newsletters__header'
                            >
                                Hieronder tref je een overzicht van de wekelijks verzonden nieuwsbrieven van dit jaar en eerder.
                            </div>
                            <ul className="newsletters__list">
                            {newsletter_list.length > 0 && newsletter_list.map((newsletter, i) => (
                                newsletter.show_on_sb !== 0 &&
                                <li>
                                    {newsletter.has_file
                                    ?   <div className="newsletters__listitem">
                                            <span className="newsletter__subtitle">
                                                {this.convertWeek(newsletter.week)}
                                                <span>wk{newsletter.week}</span>
                                            </span>
                                            {parseInt(newsletter.week) >= 2105 &&
                                                <div className="newsletter__title-container">
                                                    <span className="newsletter__title">
                                                        {newsletter.title !== null && newsletter.title !== ''
                                                        ?   newsletter.title
                                                        :   'Nieuwsbrief'
                                                        } 
                                                    </span>
                                                    <span className="newsletter__actions">
                                                        {(newsletter.has_php === 1 && newsletter.title !== null && newsletter.title !== '') &&
                                                            <span className="link--read-more --link" onClick={() => this.handleClick(this.urlAPI + "/newsletters/newsletter.php?mailing=mailing" + newsletter.week + "&origin=site", newsletter, 'web')}>web</span>
                                                        }
                                                        {newsletter.has_pdf === 1 && (
                                                            <React.Fragment>
                                                            {isIOS 
                                                            ?   <a href={this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".php?origin=site"} target="_blank">
                                                                    pdf
                                                                </a>
                                                            :   <span className="link--read-more --link" onClick={() => this.handleClick(this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".php?origin=site", newsletter, 'pdf')}>
                                                                    pdf
                                                                </span>
                                                            }
                                                            </React.Fragment>
                                                        )}
                                                    </span>

                                                    {this.storeAuth.user.is_loggedin && this.storeAuth.user.is_admin &&
                                                        <ul className="newsletters__list--admin">
                                                            {(newsletter.has_php === 1 && newsletter.title !== null && newsletter.title !== '') &&
                                                                <li>
                                                                    <span className="newsletter__php --link" onClick={() => this.handleClick(this.urlAPI + "/newsletters/newsletter.php?mailing=mailing" + newsletter.week + "&origin=site", newsletter, 'web')}>
                                                                        {this.urlAPI + "/newsletters/newsletter.php?mailing=mailing" + newsletter.week}
                                                                    </span>
                                                                </li>
                                                            }

                                                            <li>
                                                                <a 
                                                                    href={this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf"} 
                                                                    className="newsletter__title newsletter__pdf" target="_blank"
                                                                >
                                                                    {(newsletter.title !== null && newsletter.title !== '') &&
                                                                        this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf"
                                                                    }
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        }
                                                </div>
                                            }
                                            {(parseInt(newsletter.week) >= 2030 && parseInt(newsletter.week) < 2105) && (
                                                isIOS 
                                                ?   <a href={this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf"} target="_blank">
                                                        {newsletter.title !== null && newsletter.title !== ''
                                                            ?   newsletter.title
                                                            :   'Nieuwsbrief'
                                                        }
                                                    </a>
                                                :   <div className="newsletter__title-container">
                                                        {/* <span 
                                                            className="newsletter__title --link" 
                                                            onClick={() => this.handleClick(this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf", newsletter, 'pdf')}
                                                        >
                                                            {newsletter.title !== null && newsletter.title !== ''
                                                            ?   newsletter.title
                                                            :   'Nieuwsbrief'
                                                            }
                                                        </span>
                                                        {this.storeAuth.user.is_loggedin && this.storeAuth.user.is_admin &&
                                                            <a href={this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf"} className="newsletter__pdf" target="_blank">
                                                                {(newsletter.title !== null && newsletter.title !== '') &&
                                                                this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf"
                                                                }
                                                            </a>
                                                        } */}
                                                        <a 
                                                            href={this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf"} 
                                                            className="newsletter__title newsletter__pdf" target="_blank"
                                                        >
                                                            {newsletter.title !== null && newsletter.title !== ''
                                                            ?   newsletter.title
                                                            :   'Nieuwsbrief'
                                                            }
                                                        </a>
                                                        {this.storeAuth.user.is_loggedin && this.storeAuth.user.is_admin &&
                                                            <a 
                                                                href={this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf"} 
                                                                className="newsletter__title newsletter__pdf" target="_blank"
                                                            >
                                                                {(newsletter.title !== null && newsletter.title !== '') &&
                                                                this.urlAPI + "/" + newsletter.dir + "/wk" + newsletter.week + ".pdf"
                                                                }
                                                            </a>
                                                        }
                                                    </div>
                                            )}
                                            {parseInt(newsletter.week) < 2030 &&
                                                <div className="newsletter__title-container">
                                                    <span 
                                                        className="newsletter__title --link" 
                                                        onClick={() => this.handleClickOutside(newsletter.url, newsletter, 'web')}
                                                    >
                                                        {newsletter.title !== null && newsletter.title !== ''
                                                        ?   newsletter.title
                                                        :   'Nieuwsbrief'
                                                        }
                                                    </span>
                                                    {this.storeAuth.user.is_loggedin && this.storeAuth.user.is_admin &&
                                                        <a 
                                                            href={newsletter.url} 
                                                            className="newsletter__title newsletter__pdf" 
                                                            target="_blank"
                                                        >
                                                            {newsletter.url}
                                                        </a>
                                                    }
                                                </div>
                                            }
                                            </div>
                                    :   <div className="newsletters__listitem">
                                            <span className="newsletter__subtitle">{this.convertWeek(newsletter.week)}</span>
                                            <span className="newsletter__title">{newsletter.title}</span>
                                        </div>
                                    }
                                    {!newsletter.has_file &&
                                        <span className="newsletter__sub">(Deze nieuwsbrief is momenteel niet beschikbaar)</span>
                                    }
                                </li>
                            ))}
                            </ul>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withRouter(NewslettersOverview);
