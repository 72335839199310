import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as content from '../../utilities/content';

import TemplateSidebarRight from '../../templates/template-sidebar-right';

import ImgCover from '../../elements/img-cover';
import BookPrice from '../../elements/book-price';
import BlockBookActions from './block-book-actions';

const BlockTop20 = inject("stores") (
    observer (
        class BlockTop20 extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirect: false
                }
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            handleOnClick = (e, book) => {
                e.preventDefault();
                const sku = book.sku;
                const id = content.formatTitle(book.title);

                //SbC reset left nav
                this.storeUi.setActiveSubCategory('');
                this.storeUi.setActiveCategory('');
                this.storeUi.setActiveToggleNav(1);

                this.storeUi.setCurrentBook(sku);
                
                //SbC DEPRICATED
                //this.storeBooks.getBookDetailsBySku(sku);

                this.setState({
                    sku: sku,
                    redirect: this.props.match.params.sku ? false : true
                });

                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    url = "/book/" + sku + "/" + id;
                } else {
                    url = "/book/" + sku;
                }
                this.props.history.push(url);
            }
      
            render() {
                // if (this.state.redirect) {
                //     return <Redirect 
                //         push to={"/book/" + this.state.sku} 
                //     />;
                // }

                return (
                    <TemplateSidebarRight
                        class_modifier = "top-20"
                        block_title = {i18n.t("block.top20.header")}
                        block_intro = {i18n.t("block.top20.intro")}
                    >
                        <div className="list list--top20">
                            <ul className="list--top20__list">
                                {this.storeBooks.bookList_top20 && this.storeBooks.bookList_top20.map((book, i) => (
                                    <li key={i} className="list--top20__listitem" onClick={(e) => this.handleOnClick(e, book)}>
                                        <ImgCover
                                            sku = {book.sku}
                                        />
                                        <div className="list--top20__actions">
                                            <BookPrice
                                                price = {book.price_incl}
                                                sku = {book.sku}
                                            />
                                            <BlockBookActions
                                                sku = {book.sku}
                                                book = {book}
                                                view = {["wishlist", "cart"]}
                                                display = {"icon"} 
                                                availability = "available"
                                            />
                                        </div>
                                        <span className="list--top20__ic--rank">{i + 1}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </TemplateSidebarRight>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(BlockTop20));
