import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import {
    isBrowser,
    isMobile,
    isIOS
} from "react-device-detect";
import * as rdd from "react-device-detect";

import BlockBooksPromobook from './block-books-promobook';
import CarouselPromobooks from '../content/carousel-promobooks';

const BlockBooksPromo = inject("stores") (
    observer (
        class BlockBooksPromo extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeContent = this.props.stores.storeContent;

                this.state = {};
            }

            componentDidMount = async() => {
                let title = i18n.t("block.select_segment.header");
                const promoBooks = await this.storeContent.getPromoBooks();

                promoBooks.map((item) => {
                    if ((item.sku === null || item.sku === '') && item.title !== null && item.title !== '') 
                    {
                        title = item.title
                    }
                });

                this.setState({
                    promoBooks: promoBooks,
                    title: title
                });
            }

            handleOnClick = () => {
                const url = '/site-explanation';
                this.props.history.push(url);
            }
  
            render() {              
                return (
                    <div className="block__books--promo">
                        <div 
                            className="block__block-title block__block-title--main"
                            dangerouslySetInnerHTML={{ __html: this.state.title}}
                        />
                        {!rdd.isMobile 
                        ?   <ul
                                className = "blocks__list blocks__list--covers blocks__list--promos"
                            >
                                {this.state.promoBooks && this.state.promoBooks.map((item, i) => (
                                    item.sku !== null &&
                                        <li>
                                            <BlockBooksPromobook
                                                sku = {item.sku}
                                            />
                                        </li>
                                ))}
                            </ul>
                        :   <CarouselPromobooks
                                promoBooks = {this.state.promoBooks}
                            />
                        }
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockBooksPromo));


