import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const FormFeedbackRequired = inject("stores") (
    observer (
        class FormFeedbackRequired extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }

            render() {
                return (    
                    <div className="form-wrapper__help">
                        <span className="form-wrapper__help--required">{i18n.t("form_feedback.required")}</span>
                    </div>
                );
            }
        }
    )
)

export default withTranslation()(FormFeedbackRequired);