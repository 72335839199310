import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import moment from "moment";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as utilities from '../../utilities/formats';
import AccountCommissionPayouts from './account-commissions-payouts';
import AccountCommissionsOverviewIntervalItem from './account-commissions-overview-interval-item';

const AccountCommissionsOverviewInterval = inject("stores") (
    observer (
        class AccountCommissionsOverviewInterval extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    commissions: [],
                    showInterval: false
                }
            }

            componentDidMount = async() => {
                const commissions = await this.storeUser.getCommissions();
                
                const settledDate = moment(this.props.payout.settled_date.date).format('YYYY-MM-DD');
                const startDatePayout = moment(settledDate).subtract(3, 'months').format('YYYY-MM-DD');
                const endDatePayout = settledDate;
              
                let commissionsInterval = [];
                if (commissions && commissions.length > 0) {
                    commissions.map((commission) => {
                        if (moment(commission.order_date.date).isBetween(startDatePayout, endDatePayout, 'day', '(]')) {
                            commissionsInterval.push(commission);
                        }
                    })
                }

                this.setState({
                    commissions: commissions,
                    commissionsInterval: commissionsInterval
                })
            }

            componentDidUpdate = (prevProps) => {

            }

            showIntervalData = () => {
                this.setState({
                    showInterval: !this.state.showInterval
                })
            }

            render() {
                const vat_nr = this.storeUser.user_data.profile.nrVAT;

                return (
                    <React.Fragment>
                        {this.state.showInterval
                        ?   this.state.commissionsInterval && this.state.commissionsInterval.length > 0
                            ?   <React.Fragment>
                                    {this.state.commissionsInterval.map(order =>
                                        <AccountCommissionsOverviewIntervalItem
                                            orderItem = {order}
                                            payout = {this.props.payout}
                                        />
                                    )}
                                    <li 
                                        className="table--financial__row table--financial__row--toggle --link" 
                                        onClick={() => this.showIntervalData()}>
                                        <div>- Verberg details</div>
                                    </li>
                                </React.Fragment>
                            :   <li className="table--financial__row">
                                    <div className="table--financial__cell--full">Er zijn nog geen commissies verkregen</div>
                                </li>
                        :   <li 
                                className="table--financial__row table--financial__row--toggle --link " 
                                onClick={() => this.showIntervalData()}>
                                    <div>+ Toon details</div>
                            </li>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(AccountCommissionsOverviewInterval);


