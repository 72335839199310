import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Swiper, Slide } from 'react-dynamic-swiper'
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import 'swiper/css/swiper.css';
import { Link } from 'react-router-dom';

import * as content from '../../utilities/content';

import ImgCover from '../../elements/img-cover';

const CarouselHome = inject("stores") (
    observer (
        class CarouselHome extends Component {

            constructor(props, {size}) {
                super(props);
                this.props = props; 
                this.state = {
                   active_slide: 0,
                   classPrevBtn: '',
                   classNextBtn: '',
                   classPauseBtn: '--pause'
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount() {
                if (this.storeUi.carousel_home == 'pause') {
                    this.reactSwiper.swiper().autoplay.stop();
                }
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.play !== this.props.play) {
                    if (this.props.play === 'play') {
                        this.reactSwiper.swiper().autoplay.start();
                    } else {
                        this.reactSwiper.swiper().autoplay.stop();
                    }
                }
            }

            handleOnClick = (e, book) => {
                const sku = book.sku;
                const id = content.formatTitle(book.title);

                this.storeUi.setCurrentBook(sku);
                this.storeUi.setActiveToggleNav(1);
                
                //SbC DEPRICATED
                //this.storeBooks.getBookDetailsBySku(sku);

                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    url = "/book/" + sku + "/" + id;
                } else {
                    url = "/book/" + sku;
                }
                this.props.history.push(url);
            }

            goNext = () => {
                this.reactSwiper.swiper().slidePrev();
            }
             
            goPrev = () => {
                this.reactSwiper.swiper().slideNext();
            }

           checkSliderPos = () => {
                this.setState({
                    classPrevBtn: this.reactSwiper.swiper().isEnd ? 'swiper-button-disabled' : '',
                    classNextBtn: this.reactSwiper.swiper().isBeginning ? 'swiper-button-disabled' : ''
                });
            }
    
            render() {
                //SbC breakpoint options for slider work the other way around ... 

                return (
                    <div className="carousel--home">
                        <div className="blocks__blocks-title">
                            <div className="blocks__blocks-title--main">Boeken van onze eigen uitgeverij</div>
                        </div>
                        <Swiper 
                            ref={(c) => { this.reactSwiper = c }}
                            swiperOptions={{
                                slidesPerView: 1,
                                autoplay: this.storeUi.carousel_home === 'play' 
                                    ?   {
                                            delay: 3000,
                                            disableOnInteraction: false
                                        }
                                    :   false,
                                autoHeight: false,
                                breakpoints: {
                                    350: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    540: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    896: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    1050: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    1250: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    4000: {
                                        slidesPerView: 4,
                                        spaceBetween: 20,
                                    }
                                },
                                on: {
                                    slideChangeTransitionEnd: () => {
                                        this.checkSliderPos();
                                    },
                                },
                                loop: false,
                                speed: 750
                            }}
                            {...this.state.options}   
                        >
                            {this.props.bookList && this.props.bookList.map((book, i) => (
                                <Slide key={i}>
                                    <div className="carousel--books__slide --link" onClick={(e) => this.handleOnClick(e, book)}>
                                        <div className="carousel--books__slide-content">
                                            <ImgCover
                                                sku = {book.sku}
                                            />
                                        </div>
                                    </div>
                                </Slide>
                            ))}
                        </Swiper>
                        <div className={"swiper-button-prev-out " + this.state.classPrevBtn} onClick={() => this.goPrev()}></div>
                        <div className={"swiper-button-next-out " + this.state.classNextBtn} onClick={() => this.goNext()}></div>

                        <div className="blocks__blocks-title--sub-link">
                            <Link to="/our-publications" className="link--read-more">Bekijk alle boeken van onze eigen uitgeverij</Link>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CarouselHome);

