import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { runInAction } from "mobx";

import * as account from '../../communicator/account';

import FormProfile from '../forms/form-profile';
import FormBusiness from '../../components/forms/form-business';
import FormPartner from '../../components/forms/form-partner';
import FormPassword from '../../components/forms/form-password';

const AccountProfile = inject("stores") (
    observer (
        class AccountProfile extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    "profile": {
                        formControls: {},
                        formIsValid: true
                    },
                    "password": {
                        formControls: {},
                        formIsValid: false
                    },
                    "business": {
                        formControls: {},
                        formIsValid: true
                    },
                    "partner": {
                        formControls: {},
                        formIsValid: true
                    },
                    formIsValid: true,
                    btn_class: '--active'
                };

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
                this.storeUi.setUseCase("inshop");
            }

            componentWillUnmount = () => {
                this.storeUser.form_feedback = {};
            }
            
            handleFormChange = (formControls, formIsValid, section) => {
                Object.keys(this.state).map((key, i) => {
                    if (key == section) {
                        let updatedControls = {
                            ...this.state[key]
                        };
                        const updatedFormElement = {
                            formControls
                        };
                        updatedControls = updatedFormElement;
                        updatedControls['formIsValid'] = formIsValid;
       
                        this.setState({
                            [key]: updatedControls,
                            // btn_class: (section === 'profile' && formIsValid === false)
                            // ?   '--disabled'
                            // :   '--active'
                        }, () => this.checkFormTotal())
                    }
                })

                this.storeUser.clearFormFeedback();
            }

            checkFormTotal() {
                if (this.state.profile.formIsValid &&
                    
                    this.state.business.formIsValid &&
                    this.state.partner.formIsValid) {
                        this.setState({
                            formIsValid: true,
                            btn_class: '--active'
                        })
                } else {
                    this.setState({
                        formIsValid: false,
                        btn_class: '--disabled'
                    })
                }
            }

            submitFormProfile = () => {
                if (this.state.formIsValid) {
                    this.storeUser.changeUserData(this.state);
                    
                    //SbC also change user data in order data
                    this.storeOrder.setDataProfile(this.state.profile.formControls);
                    this.storeOrder.setDataBusiness(this.state.business.formControls);
                }
            }
  
            render() {
                return (
                    <div className="content">
                        <div className="form-fieldset">
                            <div className="form-wrapper__row">
                                <span className="form__fieldset-title">Persoonlijke gegevens</span>
                            </div>
                            <FormProfile 
                                handleFormChange = {this.handleFormChange} 
                                use_case = {"inshop"}
                                option_subscription = {true}
                                syncedEmail = {null}
                            />
                        </div>

                        <div className="form-fieldset">
                            <div className="form-wrapper__row">
                                <span className="form__fieldset-title">Wachtwoord wijzigen</span>
                                <span className="form__fieldset-subtitle">Gebruik alleen indien je het wachtwoord wilt wijzigen</span>
                            </div>
                            <FormPassword 
                                handleFormChange = {this.handleFormChange} 
                                use_case = {"inshop"}
                                pw_reset = {true}
                            />
                            <div className="form__feedback--inline">
                                {this.state.password.formIsValid
                                ?   <span className="--success">Je wachtwoord wordt veranderd na het versturen van de wijzingen.</span>
                                :   <span>Je huidige wachtwoord wordt niet veranderd.</span>
                                }

                                {!this.state.password.formIsValid && (
                                    (this.state.password.formControls.password && this.state.password.formControls.password.value !== '') || 
                                    (this.state.password.formControls.password2 && this.state.password.formControls.password2.value !== '') ||
                                    (this.state.password.formControls.password_current && this.state.password.formControls.password_current.value !== '')
                                ) &&
                                    <span><br/>Controleer de ingevoerde wachtwoordgegevens.</span>
                                }
                            </div>
                        </div>
                        
                        <div className="form-fieldset">
                            <div className="form-wrapper__row">
                                <span className="form__fieldset-title">Ben je een zakelijke klant en/of partner?</span>
                                <span className="form__fieldset-subtitle">(dit o.a in verband met btw-uitsplitsing)</span>
                            </div>
                            <FormBusiness 
                                handleFormChange = {this.handleFormChange} 
                                data = {this.storeUser.user_data.business}
                                country_code = {this.state.profile.formControls.country_select && this.state.profile.formControls.country_select.value}
                            />
                        </div>

                        {this.storeAuth.user.is_partner &&
                            <div className="form-fieldset">
                                <div className="form-wrapper__row">
                                    <span className="form__fieldset-title">Partnergegevens</span>
                                    <span className="form__fieldset-subtitle">(in verband met commissiebetalingen)</span>
                                    <span className="form__fieldset-subtitle">Partner code: {this.storeUser.user_data.partner_code}</span>
                                </div>
                                <FormPartner 
                                    handleFormChange = {this.handleFormChange} 
                                    data = {this.storeUser.user_data.partner}
                                />
                            </div>
                        }

                        <div className="form-row form-row--buttons">
                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.submitFormProfile()}>Verstuur wijzigingen</button>
                        </div>

                        {Object.keys(this.storeUser.form_feedback).length > 0 && 
                            <div className="form__feedback">
                                {this.storeUser.form_feedback && this.storeUser.form_feedback.msg && this.storeUser.form_feedback.msg.success &&
                                    <span>{this.storeUser.form_feedback.msg.success}</span>
                                }
                                {this.storeUser.form_feedback && this.storeUser.form_feedback.msg && this.storeUser.form_feedback.msg.error &&
                                    <span className="form-field--error">{this.storeUser.form_feedback.msg.error}</span>
                                }
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)

export default AccountProfile;


