import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as books from '../communicator/books';

class StoreDiscounts {

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeBooks = this.storeRoot.storeBooks;
        this.storeOrder = this.storeRoot.storeOrder;
        this.storeUser = this.storeRoot.storeUser;
        this.storeUi = this.storeRoot.storeUi;
    }

    async calcItemDiscount(orderId, orderItem) {
        let discountPerc = 0;

        let discountPercOverruled = 0;
        let discountPercStandard = 0;
        let discountPercReseller = 0;
        let discountPercRule = 0;

        const hasDiscountsOverruled = this.checkDiscountsOverruled(orderId);
        const hasDiscountRule =  this.checkDiscountRule(orderItem);
        const isReseller = await this.checkIsReseller();
        const isDiscountAvailable = await this.checkDiscountAvailable(orderItem);

        if (isDiscountAvailable) {
            if (hasDiscountsOverruled) {
                discountPercOverruled = this.calcDiscountPercOverruled(orderId, orderItem);
                discountPerc = discountPercOverruled;
            } else {
                discountPercStandard = await this.calcDiscountPercStandard(orderItem);
                discountPerc = discountPercStandard;
                
                if (isReseller) {
                    discountPercReseller = await this.calcDiscountPercReseller(orderItem);
                    if (discountPercReseller > discountPerc) {
                        discountPerc = discountPercReseller;
                    }
                }

                if (hasDiscountRule) {
                    discountPercRule = this.calcDiscountPercRule(orderItem);
                    discountPerc = discountPercRule;
                }
            }
        }
   
        return discountPerc;
    }

    calcDiscountPercStandard = async(orderItem) => {
        //SbC better to get discounts from account again ..
        let perc = 0;
        const amount = parseInt(orderItem.amount);
        const minAmount = 2;

        const userId = this.storeAuth.user.is_loggedin ? this.storeAuth.user.user_id : 0;
        const result = await books.getBookDetailsBySku(orderItem.shop, orderItem.sku)
        const book = result.data[0];

        if (userId !== 0) {
            const userData = await this.storeUser.refreshUserData();

            if (amount >= minAmount) {             
                const discountStandardPerc = userData.discount;
                const discountStandard3PartyPerc = userData.discount_3party;

                if (this.checkIsSB(book)) {
                    perc = discountStandardPerc;
                } else {
                    perc = discountStandard3PartyPerc;
                }
            } else {
                perc = 0;
            }
        }

        if (perc === null) {
            perc = 0;
        }

        return perc;
    }

    calcDiscountPercReseller = async(orderItem) => {
        let perc = 0;
        const amount = parseInt(orderItem.amount);
        const minAmount = this.storeOrder.settings_list["resellerMinAmount"];

        const userId = this.storeAuth.user.is_loggedin ? this.storeAuth.user.user_id : 0;
        const result = await books.getBookDetailsBySku(orderItem.shop, orderItem.sku)
        const book = result.data[0];

        if (userId !== 0) {
            if (amount >= minAmount) {
                perc = this.getPercReseller(book);
            }
        }

        return perc;
    }

    calcDiscountPercRule = (item) => {
        let perc = 0;

        const amount = parseInt(item.amount);

        let rules = this.storeUi.userDiscounts
            .filter((rule) => rule.sku === item.sku && parseInt(rule.account_id) === this.storeUser.user_data.user_id && rule.is_active === 1)
            .sort((a, b) => a.minamount > b.minamount ? 1 : -1);

        let idx = -1;
        rules.map((rule, index) => {
            idx = (parseInt(amount) >= parseInt(rule.minamount)) ? index : idx;
            return idx;
        })

        let minAmount = 2;
        if (idx !== -1) {
            minAmount = rules[idx].minamount;
        }

        if (idx !== -1 && amount >= minAmount) {
            perc = rules[idx]["discount"];
        }

        return perc;
    }

    calcDiscountPercOverruled = (orderId, orderItem) => {
        let perc = 0;
        const amount = parseInt(orderItem.amount);
        const minAmount = 1;

        if (amount >= minAmount) {
            perc = this.getPercOverruled(orderId, orderItem);
        }

        return perc;
    }

    /*
     * SbC check KortingToepasbaar (discount_active) in item
     */
    checkDiscountAvailable = async(item) => {
        let discountAvailable = true;    

        // if (item.discount_active === 0) {
        //     discountAvailable = false;  
        // }
        //SbC TEMP
        if (item.sku === '9789492665740') {
            discountAvailable = false;  
        }

        return discountAvailable;
    }

    /*
     * SbC check DiscountsOverruled in Bestellingen
     */
    checkDiscountsOverruled = (orderId) => {
        let isDiscountsOverruled = false;

        if (parseInt(this.storeOrder.order_settings.discounts_overruled) === 1) {
            isDiscountsOverruled = true;
        }

        return isDiscountsOverruled;
    }

    checkIsReseller = async() => {
        //const isReseller = (this.storeAuth.user.is_reseller) ? true : false;  
        let isReseller = false;
        const userId = this.storeAuth.user.userId;

        if (userId !== 0 && userId !== undefined) {
            const userData = await this.storeUser.refreshUserData();
            if (userData.is_reseller == 1 || userData.is_reseller === 'on') {
                isReseller = true;
            } else {
                isReseller = false; 
            }
        }

        return isReseller;
    }

    checkDiscountRule = (item) => {
        // SbC check RulesDiscounts
        let hasDiscountRule = false;
        const amount = parseInt(item.amount);   
        
        let rules = this.storeUi.userDiscounts
            .filter((rule) => rule.sku === item.sku && parseInt(rule.account_id) === this.storeUser.user_data.user_id && rule.is_active === 1)
            .sort((a, b) => a.minamount > b.minamount ? 1 : -1);

        let idx = -1;
        let rule_minamount = 1;
        rules.map((rule, index) => {
            idx = (parseInt(amount) >= parseInt(rule.minamount) && parseInt(rule.minamount) >= parseInt(rule_minamount)) ? index : idx;
            return idx;
        })
        
        hasDiscountRule = (idx !== -1) ? true : false;

        return hasDiscountRule;
    }

    getMinAmount = () => {
        let minAmount = 2;

        return minAmount;
    }

    getMinAmountRule = () => {
        let minAmount = 3;

        return minAmount;
    }

    getPercReseller = (item) => {
        let perc;
        
        if (this.checkIsSB(item)) {
            perc = 30;    
        } else {
            perc = 10;
        }
        
        return perc;
    }

    //SbC get discount from orderline
    getPercOverruled = async(orderId, orderItem) => {
        let perc = 0;
        
        let cartLine = [];
        const dataOrderLines = await this.storeOrder.getOrderLinesData(orderId);

        cartLine = dataOrderLines
            .filter((orderLine) => parseInt(orderLine.book_id) === parseInt(orderItem.id) || orderLine.book_id === orderItem.sku);

        if (cartLine.length > 0) {
            perc = cartLine[0] 
                ?   cartLine[0].discount 
                :   0;
        }     
        
        return perc;
    }

    checkIsSB = (item) => {
        let isSB = false;

        isSB = (item && item.sb_edition === "eigen uitgaven")
            ?   true 
            :   false
        ;

        return isSB;
    }
}

decorate(StoreDiscounts, {
    calcDiscountItem: action
})

export default StoreDiscounts;