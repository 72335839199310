import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';

import CheckboxSearch from '../../elements/form-input-checkbox-search';
import SearchCountOverlay from './search-count-overlay';

const SearchSelectSegment = inject("stores") (
    observer (
        class SearchSelectSegment extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;

                this.state = {
                    formControls: {
                        option1: {
                            id: 1,
                            name: 'own',
                            value: this.storeGeneral.searchShop === 'OWN' ? 1 : 0,
                            displayValue: 'Boeken eigen uitgeverij',
                            selected: this.storeGeneral.searchShop === 'OWN' ? 1 : 0,
                            searchClass: this.storeGeneral.searchShop === 'OWN'
                                ?   '--active'
                                :   ''
                        },
                        option2: {
                            id: 2,
                            name: 'sb',
                            value: this.storeGeneral.searchShop === 'SB' ? 1 : 0,
                            displayValue: 'Alle zelfhulpboeken',
                            selected: this.storeGeneral.searchShop === 'SB' ? 1 : 0,
                            searchClass: this.storeGeneral.searchShop === 'ALL' 
                                ?   '' 
                                :   this.storeGeneral.searchShop === 'SB'
                                        ?   '--active'
                                        :   '--excluded' 
                        },
                        option3: {
                            id: 3,
                            name: 'all',
                            value: this.storeGeneral.searchShop === 'ALL' ? 1 : 0,
                            displayValue: 'Alle boeken',
                            selected: this.storeGeneral.searchShop === 'ALL' ? 1 : 0,
                            searchClass: this.storeGeneral.searchShop === 'ALL' 
                                ?   '--active' 
                                :   '--excluded' 
                        }
                    }
                }
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.searchSegment !== this.props.searchSegment) {
                    switch(this.props.searchSegment) {
                        case 'OWN':
                            this.handleCheckboxChange2(1);
                            break;
                        case 'SB':
                            this.handleCheckboxChange2(2);
                            break;
                        case 'ALL':
                            this.handleCheckboxChange2(3);
                            break;
                        default:
                            this.handleCheckboxChange2(2);
                    }
                }
            }

            handleCheckboxChange2 = (idx) => {
                const name = 'option' + idx;
                let option1;
                let option2;
                let option3;
                let searchClass1;
                let searchClass2;
                let searchClass3;

                if (idx === 1) {
                    option1 = 1; 
                    option2 = 0;
                    option3 = 0;
                    searchClass1 = '--active';
                    searchClass2 = '--excluded';
                    searchClass3 = '--excluded';
                }

                if (idx === 2) {
                    option1 = 0;
                    option2 = 1;
                    option3 = 0;
                    searchClass1 = '--included';
                    searchClass2 = '--active';
                    searchClass3 = '--excluded';
                }

                if (idx === 3) {
                    option1 = 0; 
                    option2 = 0;
                    option3 = 1;
                    searchClass1 = '--included';
                    searchClass2 = '--included';
                    searchClass3 = '--active';
                }

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        ['option1']: {
                            ...this.state.formControls['option1'],
                            value: option1,
                            selected: option1,
                            searchClass: searchClass1
                        },
                        ['option2']: {
                            ...this.state.formControls['option2'],
                            value: option2,
                            selected:  option2,
                            searchClass: searchClass2
                        },
                        ['option3']: {
                            ...this.state.formControls['option3'],
                            value: option3,
                            selected: option3,
                            searchClass: searchClass3
                        }
                    }
                })
            }

            handleCheckboxChange = (idx) => {
                const name = 'option' + idx;
                let option1;
                let option2;
                let option3;
                let searchClass1;
                let searchClass2;
                let searchClass3;

                if (idx === 1) {
                    option1 = 1; 
                    option2 = 0;
                    option3 = 0;
                    searchClass1 = '--active';
                    searchClass2 = '--excluded';
                    searchClass3 = '--excluded';
                }

                if (idx === 2) {
                    option1 = 0;
                    option2 = 1;
                    option3 = 0;
                    searchClass1 = '--included';
                    searchClass2 = '--active';
                    searchClass3 = '--excluded';
                }

                if (idx === 3) {
                    option1 = 0; 
                    option2 = 0;
                    option3 = 1;
                    searchClass1 = '--included';
                    searchClass2 = '--included';
                    searchClass3 = '--active';
                }

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        ['option1']: {
                            ...this.state.formControls['option1'],
                            value: option1,
                            selected: option1,
                            searchClass: searchClass1
                        },
                        ['option2']: {
                            ...this.state.formControls['option2'],
                            value: option2,
                            selected:  option2,
                            searchClass: searchClass2
                        },
                        ['option3']: {
                            ...this.state.formControls['option3'],
                            value: option3,
                            selected: option3,
                            searchClass: searchClass3
                        }
                    }
                    
                }, () => this.handleSelectSegmentChange(idx))
            }

            handleSelectSegmentChange = async(idx) => {
                const name = 'option' + idx;
                let path = '/';

                const params = queryString.parse(this.props.location.search);

                if (this.state.formControls.option3.selected) {
                    this.storeGeneral.setShop("SHOP_ALL");
                    this.storeGeneral.setActiveShop("SHOP_ALL");
                    this.storeUi.setActiveToggleNav(3);
                    this.storeGeneral.setSearchShop('ALL');
                    this.storeGeneral.setSearchSegment('ALL');
                    if (this.storeUi.search_overlay) {
                        path = this.storeUi.toggle_nav[2].path;
                        this.props.history.push(path);
                    }
                    if (!this.storeUi.search_overlay && params.section) {
                        path = "/search-results?q=" + this.storeUi.search_value + "&section=all";
                        this.props.history.push(path);
                    }
                } else if (this.state.formControls.option2.selected) {
                    this.storeGeneral.setShop("SHOP_SB");
                    this.storeGeneral.setActiveShop("SHOP_SB");
                    this.storeUi.setActiveToggleNav(2);
                    this.storeGeneral.setSearchShop('SB');
                    this.storeGeneral.setSearchSegment('SB');
                    if (this.storeUi.search_overlay) {
                        if (path == "/our-publications" || path == "/" || path == "/all-books") {
                            path = this.storeUi.toggle_nav[1].path;
                            this.props.history.push(path);
                        }
                    }
                    if (!this.storeUi.search_overlay && params.section) {
                        path = "/search-results?q=" + this.storeUi.search_value + "&section=zb";
                        this.props.history.push(path);
                    }
                } else if (this.state.formControls.option1.selected) {
                    this.storeGeneral.setActiveShop("SHOP_SB");
                    this.storeGeneral.setShop("SHOP_SB");
                    this.storeUi.setActiveToggleNav(1);
                    this.storeGeneral.setSearchShop('OWN');
                    this.storeGeneral.setSearchSegment('OWN');
                    if (this.storeUi.search_overlay) {
                        path = this.storeUi.toggle_nav[0].path;
                        this.props.history.push(path);
                    }
                    if (!this.storeUi.search_overlay && params.section) {
                        path = "/search-results?q=" + this.storeUi.search_value + "&section=sb";
                        this.props.history.push(path);
                    }
                }
               
                this.storeUi.setPageView('page', 1);
            } 

            render() {
                return (
                    <React.Fragment>
                        <div className="search-select-segment">
                            <div className="search-select-segment__results">
                                <div className="search-select-segment__list">
                                    
                                        {Object.keys(this.state.formControls).map((key, i) => (
                                            <div 
                                                className={"search-select-segment__listitem --link" + " " + this.state.formControls[key].searchClass}
                                                onClick = {() => this.handleCheckboxChange(this.state.formControls[key].id)}
                                            >
                                                <CheckboxSearch
                                                    name = {key} 
                                                    label = {this.state.formControls[key].displayValue}
                                                    isSelected = {this.state.formControls[key].selected}
                                                    onCheckboxChange = {() => this.handleCheckboxChange(this.state.formControls[key].id)}
                                                    className = {"--reduced"}
                                                    searchClass= {this.state.formControls[key].searchClass}
                                                />
                                                
                                                <SearchCountOverlay
                                                    searchShop = {this.state.formControls[key].name}
                                                    searchTerm = {this.storeUi.search_value}
                                                />
                                            </div>
                                        ))}
                                 
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(SearchSelectSegment);
