import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import logo_img from '../../assets/images/branding/sb_logo.png';
import logo_txt from '../../assets/images/branding/sb_logo-text.png';

const Branding = inject("stores") (
    observer (
        class Branding extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidUpdate = () => {
                
            } 

            handleOnClick = (e, path) => {
                this.storeUi.setUseCase("inshop");

                this.storeGeneral.setShop("SHOP_SB");
                this.storeGeneral.setActiveShop("SHOP_SB");
                this.storeGeneral.setSearchShop('SB');

                this.storeUi.setActiveMainNav(0);
                this.props.history.push(path);
            }

            render() {

                return (
                    <div className="branding" onClick={(e)=>this.handleOnClick(e, '/')}>
                        <img src={logo_img} className="branding__logo--img" alt="logo image succesboeken.nl" />
                        <span className="branding__text-container">
                            <img src={logo_txt} className="branding__logo--txt" alt="logo text succesboeken.nl" />                          
                        </span>
                    </div>
                )
            }
        }
    )
)

export default withRouter(Branding);
