import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import ContentText from '../content/content-text';

const DialogCookies = inject("stores") (
    observer (
        class DialogCookies extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    is_visible: this.props.visible
                };
            }

            componentDidUpdate(prevProps) {
                if (prevProps.visible !== this.props.visible) {
                    this.setState({
                        is_visible: true
                    });

                    setTimeout(() => { 
                        this.setState({
                            is_visible: false
                        });
                        this.storeUi.setCookies();
                    }, this.storeUi.cookie_duration);
                }
            }

            hideDialogCookies() {
                this.setState({
                    is_visible: false
                });
            }

            render() {  
                let class_visible = this.state.is_visible ? ' --fadeIn' : ' --fadeOut';

                return (
                    <div className={"dialog--content-cookies" + class_visible}>
                        <div className="btn--icon icon--clear --link" onClick={(e) => this.hideDialogCookies()}/>
                        <ContentText
                            page = {"cookies"}
                            className = "content-cookies"
                        />
                    </div>
                )
            }
        }
    )
)

export default DialogCookies;
