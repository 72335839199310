import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

const TestUpload = inject("stores") (
    observer (
        class TestUpload extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    genre_name: "",
                    genre_id: null
                }
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            async componentDidMount() {
                
            }
      
            render() {
                const getUploadParams = ({ meta }) => { 
                    return { 
                        url: 'https://httpbin.org/post' 
                        //url: 'http://api.localhost/content/uploadFile.php' 
                        //url: 'http://api.localhost/test/upload/testUpload.php' 
                    } 
                }
  
                // called every time a file's `status` changes
                const handleChangeStatus = ({ meta, file }, status) => { 
                    console.log(status, meta, file) 
                }
                
                // receives array of files that are done uploading when submit button is clicked
                const handleSubmit = (files, allFiles) => {
                  console.log(files.map(f => f.meta))
                  allFiles.forEach(f => f.remove())
                }

                return (
                    <div className="content-wrapper">
                        <div className="content-wrapper__content">
                            <div className="content-wrapper__segments">
                                <div className="content-wrapper__segment">
                                <Dropzone
                                    getUploadParams={({file}) => {
                                        const body = new FormData();
                                        body.append('fileField', file);
                                        console.log('SbC file::', file);
                                        return {
                                            url: 'http://api.localhost/test/upload/testUpload.php', 
                                            //url: 'https://httpbin.org/post',
                                            body
                                        }
                            
                                    }}
                                    onChangeStatus={handleChangeStatus}
                                    onSubmit={handleSubmit}
                                    accept="image/*,audio/*,video/*"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default TestUpload;
