import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Swiper, Slide } from 'react-dynamic-swiper'
import { withRouter } from "react-router-dom";
import 'swiper/css/swiper.css';
import sizeMe from 'react-sizeme';

import BlockBook from '../block/block-book';
import ImgCover from '../../elements/img-cover';

const CarouselNewsletter = inject("stores") (
    observer (
        class CarouselNewsletter extends Component {

            constructor(props, {size}) {
                super(props);
                this.props = props;
                this.state = {
                   active_slide: 0
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            setOptions = (swiper) => {
                this.swiper = swiper;
                this.swiper.slideTo(0);
            }

            handleOnClick = (sku) => {
                this.storeUi.setCurrentBook(sku);

                const book = this.storeBooks.booksList
                    .filter((book) => book.sku == sku);
                const id = book[0].id;

                this.storeBooks.getBookDetailsBySku(sku);
            }

            gotoSlide(slide, sku) {
                // if (this.swiper !== null && this.swiper !== undefined) {
                //     this.swiper.slideTo(slide);
                // }
                this.handleOnClick(sku);

                const url = "/book/" + sku; 
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <div className="carousel--newsletter">
                        
                        <Swiper swiperOptions={{
                                slidesPerView: 1,
                                autoHeight: false
                            }}
                            {...this.state.options}
                            onInitSwiper={(swiper, event) => {
                                this.setOptions(swiper);
                            }}   
                        >
                            {this.props.bookList && this.props.bookList.map((book, i) => (
                                <Slide key={i}>
                                    <div className="carousel--newsletter__slide block--book --link" onClick={(e) => this.handleOnClick(book.sku)}>
                                        <BlockBook 
                                            type = "summary"
                                            book = {{
                                                id: book.id,
                                                availability: "available"
                                            }}
                                            sku = {book.sku}
                                        />
                                    </div>
                                </Slide>
                            ))}
                        </Swiper>
                    
                        {/* <div className="swiper-pagination--covers">
                            {this.props.bookList && this.props.bookList.map((book, i) => (
                                <div className="swiper-pagination--covers__cover" onClick={() => this.gotoSlide(i, book.sku)}>
                                    <ImgCover
                                        sku = {book.sku}
                                    />
                                    {this.checkOnWishList(book.sku) &&
                                        <div className="cover__icon cover__icon--wishlist"></div>
                                    }
                                </div>
                            ))}
                        </div> */}
                    </div>
                )
            }
        }
    )
)

export default withRouter(CarouselNewsletter);

