import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';
import moment from "moment";

import BookLang from '../../elements/book-lang';

const BookDimensions = inject("stores") (
    observer (
        class BookDimensions extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.state = ({
                    width: 0,
                    length: 0,
                    thickness: 0
                })
            }

            componentDidMount = () => {
                this.setSizes();
            }

            setSizes = () => {
                const width = this.props.book.width 
                    ?   this.props.book.width 
                    :   0;
                const length = this.props.book.length 
                    ?   this.props.book.length
                    :   0;
                const thickness = this.props.book.thickness 
                    ?   this.props.book.thickness 
                    :   0;

                this.setState({
                    width: width,
                    length: length,
                    thickness: thickness
                }, () => {
                    this.setTitle()
                    this.setDimensions()}
                )
            }

            setTitle = () => {
                let title = 'Afmetingen';

                if (this.state.width !== 0 || this.state.length !== 0 || this.state.thickness !== 0) {
                    title = title + ' in mm (';
                    if (this.state.width !== 0) {
                        title = title + 'b';
                    }
                    if ((this.state.length !== 0 || this.state.thickness !== 0) && this.state.width !== 0) {
                        title = title + ' x ';
                    }
                    if (this.state.length !== 0) {
                        title = title + 'l';
                    }
                    if (this.state.length !== 0 && this.state.thickness !== 0) {
                        title = title + ' x ';
                    }
                    if (this.state.thickness !== 0) {
                        title = title + 'd';
                    }

                };

                if (this.state.width !== 0 || this.state.length !== 0 || this.state.thickness !== 0) {
                    title = title + ')';
                };

                this.setState({
                    title: title
                })
            }

            setDimensions = () => {
                let dimensions = '-';

                if (this.state.width !== 0 || this.state.length !== 0 || this.state.thickness !== 0) {
                    if (this.state.width !== 0) {
                        dimensions = this.state.width;
                    }
                    if ((this.state.length !== 0 || this.state.thickness !== 0) && this.state.width !== 0) {
                        dimensions = dimensions + ' x ';
                    } else {
                        if (this.state.width === 0) {
                            dimensions = '';
                        }
                    }
                    if (this.state.length !== 0) {
                        dimensions = dimensions + this.state.length;
                    }
                    if (this.state.length !== 0 && this.state.thickness !== 0) {
                        dimensions = dimensions + ' x ';
                    }
                    if (this.state.thickness !== 0) {
                        dimensions = dimensions + this.state.thickness;
                    }
                };

                this.setState({
                    dimensions: dimensions
                })
            }
        
            render() {
                return (
                    <React.Fragment>
                        <dt>{this.state.title}</dt>
                        <dd>{this.state.dimensions}</dd>
                    </React.Fragment>
                )
            }
        }
    )
)

const BlockSpecifications = inject("stores") (
    observer (
        class BlockSpecifications extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = () => {
            }

            componentDidUpdate = (prevProps) => {
            }

            render() {
                return (
                    <div className="segment__content book__specs">
                        <div className="book-specs__wrapper">
                            <div className="book-specs__column">
                                <dl>
                                    <dt>Auteur</dt>
                                    <dd>{this.props.book.author_firstname && (this.props.book.author_firstname + ' ')} {this.props.book.author_lastname ? this.props.book.author_lastname : '-'}</dd>

                                    {this.props.book.release_date &&
                                    <React.Fragment> 
                                        <dt>Oorspronkelijke verschijningsdatum</dt>
                                        <dd>{this.props.book.release_date && this.props.book.release_date.date ? moment(this.props.book.release_date.date).format("DD MMMM YYYY") : '-'}</dd>
                                    </React.Fragment> 
                                    }

                                    <dt>ISBN</dt>
                                    <dd>{this.props.book.sku ? this.props.book.sku : '-'}</dd>

                                    <dt>Aantal pagina's</dt>
                                    <dd>{this.props.book.pages ? this.props.book.pages : '-'}</dd>
                                </dl>
                            </div>
                            <div className="book-specs__column">
                                <dl>
                                    <dt>Taal</dt>
                                    <dd><BookLang
                                        book = {this.props.book}
                                        />
                                    </dd>

                                    <dt>Uitvoering</dt>
                                    <dd>{this.props.book.version ? this.props.book.version : '-'}</dd>

                                    <BookDimensions
                                        book = {this.props.book}
                                    />

                                    <dt>Gewicht in gr</dt>
                                    <dd>{this.props.book.weight ? this.props.book.weight : '-'}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockSpecifications);

