import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const TemplateSidebarRight = inject("stores") (
    observer (
        class TemplateSidebarRight extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }
 
            render() {
                return (
                    <div className={"block block--sidebar block--" + this.props.class_modifier}>
                        <div className="block__block-title">
                            {this.props.block_title}
                            {this.props.block_intro &&
                                <span className="block__block-subtitle--bold">{i18n.t(this.props.block_intro)}</span>
                            }
                        </div> 

                        {this.props.children}

                    </div>
                )
            }
        }
    )
)

export default TemplateSidebarRight;
