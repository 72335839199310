import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';

import PartnerAgreementText from './partner-agreement-text';

const PartnerAgreement = inject("stores") (
    observer (
        class PartnerAgreement extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount() {
                window.scrollTo(0, 0)
            }
      
            render() {
                return (
                    <Template
                        type = 'default'
                        header = "partner_agreement"
                    >
                        <PartnerAgreementText />
                    </Template>
                )
            }
        }
    )
)

export default PartnerAgreement;
