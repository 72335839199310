import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import * as rdd from "react-device-detect";

import CarouselNewsletter from '../content/carousel-newsletter';
import BlocksBooks from './blocks-books';
import BlockBook from '../block/block-book';

const BlocksNewsletter = inject("stores") (
    observer (
        class BlocksNewsletter extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    view: 'carousel'
                };

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.urlAPI = process.env.REACT_APP_URL_API;
            }

            toggleNewsletterView = (e) => {
                this.setState({
                    view_list: !this.state.view_list
                })
            }

            handleClick = (url) => {
                document.body.classList.add('dialog-open');
                this.storeUi.openDialogNewsletter(url);
            }

            handleViewNav = (e, view) => {
                switch(view) {
                    case "summaries":
                        this.setState({
                            view: 'summaries' 
                        })
                        break;
                    case "carousel":
                    default:
                        this.setState({
                            view: 'carousel' 
                        })
                }
            }
  
            render() {
                const Li = (view) => {
                    const activeClass = (view.view === this.state.view) ? ' --active' : '';
                    return (
                        <li className={"blocks-nav__listitem blocks-nav__listitem--" + view.view + activeClass} onClick={(e) => this.handleViewNav(e, view.view)}>
                            <span>lijst</span>
                        </li>
                    )
                }

                const url = this.urlAPI + "/mailings/" + this.storeUi.settings_list.latestNewsletter
                const week = parseInt(url.slice(url.length - 4));

                return (
                    <div className="blocks blocks--books blocks--newsletter">
                        <div className="blocks__blocks-title">
                            <div className="blocks__blocks-title--main">
                                {i18n.t("blocks.newsletter.header")}
                            </div> 
                            {/* {((rdd.isMobile && !this.storeAuth.user.is_loggedin) || 
                            (rdd.isMobile && this.storeAuth.user.is_loggedin && !(this.storeUser.user_data.profile.has_subscription === "1" || this.storeUser.user_data.profile.has_subscription === 'on'))) && 
                                <div className="blocks__blocks-subtitle">
                                    <Link 
                                        className="link--subscribe link--subscribe-subtitle link--read-more"
                                        to="/newsletter"
                                        dangerouslySetInnerHTML={{ __html: i18n.t("block.newsletter.link_subscribe_content")}} 
                                    />
                                </div>
                            } */}
                        </div>
                        <div className="blocks__content">
                            <div className="blocks--newsletter --large">
                                <BlocksBooks 
                                    type = "newsletter"
                                    default_view = "summaries"
                                    page = "newsletter"
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default BlocksNewsletter;

