import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import TemplateForms from '../../templates/template-forms';


import Checkbox from '../../elements/form-input-checkbox';
import TextArea from '../../elements/form-input-textarea';
import TextInput from '../../elements/form-input-text';
import FormTextIncomplete from '../../elements/form-text-incomplete';
import BlockReview from '../block/block-review';
import BlockImageUpload from '../block/block-image-upload';

const ContentPreview = inject("stores") (
    observer (
        class ContentPreview extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            render() {
                return (
                    this.props.children
                )
            }
        }
    )
)

const FormReview = inject("stores") (
    observer (
        class FormReview extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    btn_class: '--disabled',
                    formControls: { 
                        firstname: {
                            label: i18n.t("forms.label.firstname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.firstname : '',
                            placeholder: i18n.t("forms.placeholder.firstname"),
                            validationRules: {
                                isRequired: true
                            },
                            required: true
                        },
                        show_firstname: {
                            label: 'Mag getoond worden',
                            value: 1,
                            touched: false,
                            valid: true
                        },
                        lastname: {
                            label: i18n.t("forms.label.lastname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.lastname : '',
                            placeholder: i18n.t("forms.placeholder.lastname"),
                            validationRules: {
                                isRequired: true
                            },
                            required: true
                        }, 
                        show_lastname: {
                            label: 'Mag getoond worden',
                            value: 1,
                            touched: false,
                            valid: true
                        },        
                        email: {
                            label: i18n.t("forms.label.email"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.email : '',
                            placeholder: i18n.t("forms.placeholder.email"),
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            required: true
                        },
                        // profession: {
                        //     label: i18n.t("forms.label.profession"),
                        //     value: '',
                        //     placeholder: i18n.t("forms.placeholder.profession"),
                        //     valid: true
                        // },
                        show_profession: {
                            label: 'Mag getoond worden',
                            value: 0,
                            touched: false,
                            valid: true
                        }, 
                        // website: {
                        //     label: i18n.t("forms.label.website"),
                        //     value: '',
                        //     placeholder: i18n.t("forms.placeholder.website"),
                        //     valid: true
                        // },
                        show_website: {
                            label: 'Mag getoond worden',
                            value: 0,
                            touched: false,
                            valid: true
                        }, 
                        profile: {
                            label: i18n.t("forms.label.profile"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.profile", {maxlength: this.storeUi.settings_list["maxLengthProfile"]}),
                            valid: true
                        },
                        show_profile: {
                            label: 'Mag getoond worden',
                            value: 0,
                            touched: false,
                            valid: true
                        },
                        review_title: {
                            label: i18n.t("forms.label.review_title"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.review_title"),
                            valid: true
                        },
                        review: {
                            label: i18n.t("forms.label.review"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.review"),
                            validationRules: {
                                isRequired: true
                            },
                            required: true
                        }
                    },
                    upload_file: {},
                    error: false,
                    str_profile: this.storeUi.settings_list["maxLengthProfile"],
                    has_reviews: false
                }
            }

            componentDidMount = async() => {
                //SbC:: check for previous reviews
                //let return_data = await this.storeUser.getReviewerData();
                //let reviewer_data = return_data[0];

                const updatedControls = {
                    ...this.state.formControls
                };
                let updatedFormElement = {};
                let formIsValid = true;
                
                Object.keys(this.state.formControls).map((key, i) => {
                    updatedFormElement = {
                        ...updatedControls[key]
                    };
                    updatedFormElement.value = this.state.formControls[key].value;

                    // if (reviewer_data !== undefined && key === 'profession') {
                    //     updatedFormElement.value = reviewer_data.profession;
                    // }
                    // if (reviewer_data !== undefined && key === 'website') {
                    //     updatedFormElement.value = reviewer_data.website;
                    // }
                    // if (reviewer_data !== undefined && key === 'profile') {
                    //     updatedFormElement.value = reviewer_data.description;
                    // }

                    if (updatedFormElement.value !== null && updatedFormElement.value !== undefined) {
                        updatedFormElement.valid = validate(updatedFormElement.value, updatedFormElement.validationRules);
                    }
                    updatedControls[key] = updatedFormElement;
                })

                //SbC:: check if form is valid
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls
                });
            }

            componentWillUnmount = () => {
                this.storeUi.clearFormFeedback();
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
          
                updatedControls[name] = updatedFormElement;

                if (name === 'profile') {
                    this.calcRemaining('str_profile', value, this.storeUi.settings_list["maxLengthProfile"]);
                }

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid
                        ?   '--active'
                        :   '--disabled'
                })
            }

            calcRemaining(name, str, max) {
                const l = str.length;
                const remaining = max - str.length;
                this.setState({
                    [name]: remaining
                })
            }

            handleCheckboxChange = (event, name) => {
                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...this.state.formControls[name],
                            value: parseInt(this.state.formControls[name].value) === 1 ? 0 : 1,
                            selected: parseInt(this.state.formControls[name].value) === 1 ? 0 : 1
                        }
                    }
                    
                });
            }

            setImageUpload = (file) => {
                this.setState({
                    upload_file: file
                })
            }

            sendReviewForm = (e) => {
                e.preventDefault();
                let loggedin = this.storeUser.user_data.is_loggedin;
                let account_id = 0;

                if (this.storeUser.user_data.is_loggedin) {
                    account_id = this.storeUser.user_data.user_id;
                } 

                this.storeUi.addReview(this.props.book.sku, this.props.book.title, this.state.formControls, this.state.upload_file, loggedin, account_id);
                this.storeUi.sendFormReview(this.props.book.sku, this.props.book.title, this.state.formControls, this.state.upload_file, loggedin, account_id);
            }

            openDialogPreview = () => {
                this.storeUi.setContentComponent(
                    <ContentPreview
                        className = "content-scrollable"
                    >
                        <BlockReview 
                            view = 'preview'
                            src = {this.state.formControls}
                            img = {this.state.upload_file.base64}
                        />
                    </ContentPreview>
                )
                this.storeUi.openDialogContent();
            }

            render() {

                return (
                    <TemplateForms
                        type = 'recensie'
                        msg_success = {i18n.t("form_feedback.success_review")}
                    >
                        <div>
                            <div className="form-wrapper__row">
                                <span className="form__subtitle">Titel van beoordeelde boek:</span>
                                <h3 className="form__title">{this.props.book.title}</h3>
                                <span>
                                    Vul je review en je gegevens in en geef aan welke bij je review(s) getoond mogen worden.<br/>
                                    (je e-mailadres wordt sowieso niet getoond)
                                </span>
                            </div>

                            <form className="form form--review">
                                {Object.keys(this.state.formControls).map((key, i) => (
                                    <React.Fragment>
                                    {key !== 'review' && key !== 'profile' && (
                                        <div className="form-row form-row--input" key={i}>
                                            <div className="form-column">
                                                {key !== 'show_firstname' && 
                                                    key !== 'show_lastname' &&
                                                    key !== 'show_profession' &&
                                                    key !== 'show_website' &&
                                                    key !== 'show_profile' &&
                                                    <label className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}>{this.state.formControls[key].label}</label>
                                                }
                                            </div>
                                            <div className="form-column">
                                                {(key === 'email' && this.storeUser.user_data.is_loggedin) &&
                                                    <span className="form-column__input-non-editable">{this.state.formControls[key].value}</span>
                                                }
                                                {(key !== 'email' || (key === 'email' && !this.storeUser.user_data.is_loggedin)) && (
                                                    key !== 'show_firstname' && 
                                                    key !== 'show_lastname' &&
                                                    key !== 'show_profession' &&
                                                    key !== 'show_website' &&
                                                    key !== 'show_profile'
                                                 ) &&
                                                    <TextInput 
                                                        name = {key} 
                                                        placeholder = {this.state.formControls[key].placeholder}
                                                        value = {this.state.formControls[key].value}
                                                        onChange = {this.handleChange}
                                                        valid = {this.state.formControls[key].valid}
                                                    />
                                                }
                                                {(key === 'show_firstname' || 
                                                    key === 'show_lastname'
                                                ) &&
                                                    <Checkbox 
                                                        name = {key} 
                                                        label = {this.state.formControls[key].label}
                                                        value = {this.state.formControls[key].value}
                                                        isSelected = {this.state.formControls[key].value}
                                                        onCheckboxChange={(e) => this.handleCheckboxChange(e, key)}
                                                        className = {"--reduced"}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    )}

                                    {key === 'profile' && (
                                        <div className="form-row form-row--input">
                                            <div className="form-column">
                                                <label className={"form-label" + (this.state.formControls.profile.required ? ' --required' : '')}>{this.state.formControls['profile'].label}</label>
                                            </div>
                                            <div className="form-column">
                                                <TextArea 
                                                    name = "profile"
                                                    maxLength = {this.storeUi.settings_list["maxLengthProfile"]}
                                                    placeholder = {this.state.formControls.profile.placeholder}
                                                    value = {this.state.formControls.profile.value}
                                                    onChange = {this.handleChange}
                                                    valid = {this.state.formControls.profile.valid}
                                                />
                                                <div className="form-column--feedback">
                                                    <span>nog {this.state.str_profile} karakters over</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* 
                                        <div className="form-row form-row--input">
                                            <div className="form-column">
                                                <span>Pasfoto</span>
                                            </div>
                                            <div className="form-column">
                                                <BlockImageUpload   
                                                    setImageUpload = {this.setImageUpload}
                                                />
                                            </div>
                                        </div>
                                    */}

                                    </React.Fragment>
                                ))}

                                <div className="form-row form-row--input">
                                    <div className="form-column">
                                        <label className={"form-label" + (this.state.formControls.review.required ? ' --required' : '')}>{this.state.formControls['review'].label}</label>
                                    </div>
                                    <div className="form-column">
                                        <TextArea 
                                            name="review"
                                            placeholder = {this.state.formControls.review.placeholder}
                                            value = {this.state.formControls.review.value}
                                            onChange = {this.handleChange}
                                            valid = {this.state.formControls.review.valid}
                                        />
                                    </div>
                                </div>

                                {this.state.btn_class !== '--active' &&
                                    <FormTextIncomplete />
                                }
                             </form>

                            <div className="form-row form-row--buttons">
                                <button className={"btn btn--secundary " + this.state.btn_class} onClick={(e) => this.openDialogPreview(e)}>{i18n.t("button.preview")}</button>
                                <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.sendReviewForm(e)}>{i18n.t("button.submit")}</button>
                            </div>
                        </div>
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormReview);
