import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import i18next from 'i18next';
import { Redirect } from 'react-router';

import * as content from '../utilities/content';

import ImgCover from '../elements/img-cover';

const TemplateCartProduct = inject("stores") (
    observer (
        class TemplateCartProduct extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    redirect: false
                }
            }

            componentDidMount() {
                
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.book !== this.props.book) {
                    let description = this.constructTitle(this.props.book);
                    this.setState({
                        description: description
                    })
                }
            }

            constructTitle = () => {
                let title = this.props.book ? this.props.book.title : '';
                let collectionName = '';
                let collectionPart = '';
                let versionTag = '';
                
                if (this.props.book) {
                    if (this.props.book.collection_name && this.props.book.collection_name !== null) {
                        collectionName = this.props.book.collection_name + ': ';
                        
                    }
                    if (this.props.book.collection_part && this.props.book.collection_part !== null) {
                        collectionPart = ' ' + this.props.book.collection_part;
                    }
                    if (this.props.book.version_tag && this.props.book.version_tag !== null) {
                        versionTag = ' (' + this.props.book.version_tag + ')';
                    }
                }

                title = collectionName + title + collectionPart + versionTag;

                return title;
            }

            handleClose = () => {
                this.storeUi.closeDialogDelete();
            }

            handleOnClick = async(sku) => {   
                if (this.props.editable === true) {
                    const shop = await this.storeUi.checkShopForSku(sku);     
                    const book = await this.storeBooks.returnBookDetailsBySku(shop, sku);

                    this.setState({
                        book: book,
                        redirect: true
                    });
                }
            }

            openDialog = () => {
                this.storeUi.openDialogDelete(this.props.product);
            }

            render() {
                if (this.state.redirect) {
                    const id = content.formatTitle(this.state.book.title);
                    return <Redirect 
                        push to={"/book/" + this.props.product.sku + "/" + id} 
                    />;
                }

                return (
                    <React.Fragment>
                        <div className="products-table__row--details">
                            <div className="products-table__cell products-table__cell--details-wrapper">
                                <div 
                                    className={"products-table__cell--details-content" + (this.props.editable === true ? " --link" : "")} 
                                    onClick={() => this.handleOnClick(this.props.product.sku)}
                                >
                                    <div className="products-table__cell products-table__cell--sku">
                                        <ImgCover
                                            sku = {this.props.product.sku}
                                        />
                                    </div>
                                    <div className="products-table__cell products-table__cell--title"
                                        dangerouslySetInnerHTML={{ __html: this.state.description}}
                                    />
                                </div>
                            </div>

                            {this.props.children}

                            {this.props.editable === true &&
                                <div onClick={(e) => this.openDialog(e)} className="products-table__cell products-table__cell--icon products-table__cell--icon">
                                    <span className="icon icon--delete"></span>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default TemplateCartProduct;
