import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const SearchMinimumChars = inject("stores") (
    observer (
        class SearchMinimumChars extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            render() {

                return (
                    <React.Fragment>
                        <div className="search-results">
                            Gebruik een langere zoekterm (minimaal 3 letters)
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default SearchMinimumChars;
