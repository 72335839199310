import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const BlockAuthor = inject("stores") (
    observer (
        class BlockAuthor extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUser = this.props.stores.storeUser;
            }

            render() {
                const author = this.props.author
                const authorName = (author) => {
                    let name = author.firstname + ' ' + author.lastname;
                    return name;
                }

                return (
                    <div className="block-content">
                        <div className="block-content__column">
                            {author.profile_picture !== '' && author.profile_picture !== null &&
                                <div className="block-content--book__cover">
                                    <div className="cover__wrapper">
                                        <div className="content">
                                            <img src={this.urlAPI + "/public/images/authors/" + author.profile_picture} /> 
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="block-content__column">
                            {author.description !== '' && author.description !== null
                            ?   <div className="block-content--book__description--short">
                                    {author.description}
                                </div>
                            :   <div className="block-content--book__description--short">
                                    Er is geen verdere informatie over {authorName(author)} beschikbaar
                                </div>
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default BlockAuthor;

