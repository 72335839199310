import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";

import * as account from '../../communicator/account';
import * as payment from '../../communicator/payment';

import Radio from '../../elements/form-input-radio';
import RadioMethod from '../../elements/form-input-radio-method';
import RadioIssuer from '../../elements/form-input-radio-issuer';
import Select from '../../elements/form-input-select';

const FormPayment = inject("stores") (
    observer (
        class FormPayment extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    formControls: { 
                        payment_method: {
                            value: this.props.payment_method_id,
                            placeholder: 'Select your payment method',
                            valid: false,
                            touched: false,
                            options: this.storeUi.payment_providers
                        },
                        payment_issuer: {
                            value: '',
                            placeholder: 'Select your bank',
                            valid: false,
                            touched: false,
                            options: this.storeOrder.payment_issuers
                        }
                    },
                    error: false
                }
            }

            componentDidMount = () => {
                this.filterOptionsPaymentMethod();
            }

            async componentDidUpdate(prevProps) {
                if (prevProps.payment_method_id !== this.props.payment_method_id) {
                   this.filterOptionsPaymentMethod();

                    const name = 'payment_method';
                    const value = this.props.payment_method_id;

                    this.setState({
                        formControls: {
                            ...this.state.formControls,
                            [name]: {
                            ...this.state.formControls[name],
                            value: value
                        }
                        }
                    }); 
                 }
            }

            filterOptionsPaymentMethod = () => {
                const name = 'payment_method';
                let payment_providers = this.storeUi.payment_providers.slice(0);

                //SbC:: disable iDeal if not in NL or BE
                if (this.props.country_code !== this.storeOrder.settings_list.isNL && this.props.country_code !== 'BE') {
                    let idx = this.storeUi.payment_providers.findIndex((provider) => provider.id == 'IDEAL');
                    if (idx > -1) {
                        payment_providers.splice(idx, 1);
                    }
                    this.setState({
                        formControls: {
                            ...this.state.formControls,
                            [name]: {
                            ...this.state.formControls[name],
                                options: payment_providers
                            }
                        }
                    })
                }
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;
                let idx;

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                        ...this.state.formControls[name],
                        value: value
                      }
                    }
                }); 

                //SbC:: use original array as indices have changed if no iDeal available
                if (name === 'payment_method') {
                    idx = this.storeUi.payment_providers.findIndex((provider) => provider.id == value);
                    this.storeOrder.setPaymentData(name, value, this.storeUi.payment_providers[idx].displayValue)
                    if (this.props.type == "open_order") {
                        this.storeUi.setOrderToProcessKey("payment_method_id", value)
                    }
                } else {
                    this.storeOrder.setPaymentData(name, value, this.state.formControls[name].options[value].displayValue)
                    if (this.props.type == "open_order") {
                        this.storeUi.setOrderToProcessKey(name, value)
                    }
                }

                if (name === 'payment_issuer') {
                    this.props.onScrollToBtn();
                }
            }

            setOrderId = () => {
                if (this.props.type == "open_order") {
                    return this.storeUi.order_to_process.id
                } else {
                    return this.storeOrder.order_data.order_id
                }
            }       

            render() {
                const selectedMethod = this.props.country_code === this.storeOrder.settings_list.isNL 
                    ?   (this.props.payment_method_id) === 'XX'
                        ?   'IDEAL'
                        :   this.props.payment_method_id
                    :   this.props.payment_method_id === 'XX'
                        ?   'BANKTRANS'
                        :   this.props.payment_method_id

                return (
                    <div className="form-wrapper">
                        <div className="form-fieldset">
                            <form className="form --full-width">
                                <div className="form-row form-row--input">
                                    <label>Betaalmethode</label>
                                    <RadioMethod 
                                        name = "payment_method" 
                                        placeholder = {this.state.formControls.payment_method.placeholder}
                                        value = {this.state.formControls.payment_method.value}
                                        options = {this.state.formControls.payment_method.options}
                                        direction = {"vertical"}
                                        onChange = {this.handleChange}
                                        defaultValue = {this.props.payment_method_id}
                                        selected = {this.state.formControls.payment_method.value}
                                    />
                                </div>

                                {(this.state.formControls.payment_method.value === 'IDEAL') &&
                                <React.Fragment>
                                    <div className="form-row form-row--input">
                                        <label>Kies je bank</label>
                                        <RadioIssuer 
                                            name = "payment_issuer" 
                                            placeholder = {this.state.formControls.payment_issuer.placeholder}
                                            value = {this.state.formControls.payment_issuer.value}
                                            options = {this.state.formControls.payment_issuer.options}
                                            direction = {"horizontal"}
                                            onChange = {this.handleChange}
                                            defaultValue = {this.state.formControls.payment_issuer.defaultValue}
                                            selected = {this.storeOrder.order_data.payment.payment_issuer.code}
                                        />
                                    </div>
                                </React.Fragment>
                                }

                                {/* {(this.state.formControls.payment_method.value === 'BANKTRANS') 
                                ?   <div className="form-row form-row--feedback">
                                        <span className="feedback__title">Je hebt gekozen voor handmatige overschijving</span>
                                        <p>Maak het totaalbedrag van je bestelling over naar:</p>
                                        <ul>
                                            <React.Fragment>
                                                <li className="feedback__section">Rabobank te Zeist, rekening: NL56 RABO 0357 5205 80<br/>
                                                    ten name van Belfra Publishers for Success bv
                                                </li>
                                                <li className="feedback__section">
                                                    of:<br/>
                                                    Triodosbank te Zeist, rekening: NL35 TRIO 0391 1028 26<br/>
                                                    ten name van Belfra Publishers for Success bv
                                                </li>
                                                <li className="feedback__section">
                                                    Dolderseweg 49<br/>
                                                    3734 BB Den Dolder<br/>
                                                    onder vermelding van "order <strong>{this.setOrderId()}</strong>"
                                                </li>
                                            </React.Fragment>

                                            {(this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isNL) && (this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isBE) &&
                                                <li className="feedback__section">
                                                    <b>Vanuit het buitenland:</b><br/>
                                                    Gebruik de volgende gegevens voor een overschrijving vanuit het buitenland:<br/>
                                                    Swift-adres (BIC#): RABONL2U<br/>
                                                    IBAN: NL56 RABO 0357 5205 80<br/>
                                                    ten name van Belfra Publishers for Success bv
                                                    onder vermelding van  "order <strong>{this.setOrderId()}</strong>"
                                                </li>
                                            }

                                            {(this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isNL) && (this.storeOrder.order_data.billing_address.country_code == this.storeOrder.settings_list.isBE) &&
                                                <li className="feedback__section">
                                                    <b>Vanuit Belgi&euml;</b><br/>
                                                    Gebruik de volgende gegevens voor een overschrijving vanuit Belgi&euml;:<br/>
                                                    BNP Paribas Fortis rekening: BE71 0016 6035 5969<br/>
                                                    Swift-adres (BIC#): GEBABEBB<br/>
                                                    ten name van Belfra Publishers for Success bv<br/>
                                                    onder vermelding van  "order <strong>{this.setOrderId()}</strong>"
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                :   null
                                } */}

                                {this.state.error &&
                                    <div className="form-row form-row--error">
                                        <p>Er is een fout opgetreden. Controleer je invoergegevens.</p>
                                    </div>
                                }
                            </form>
                        </div>
                        
                    </div>
                )
            }
        }
    )
)

export default FormPayment;
