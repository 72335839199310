import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import sizeMe from 'react-sizeme';

import NavigationFooter from '../footer/navigation';
import FooterPayments from '../footer/payments';
import Disclaimer from '../footer/disclaimer';
import FooterSocial from '../footer/social';
import Salutation from '../../elements/txt-salutation';
import Version from '../footer/version';

const Footer = inject("stores") (
    observer (
        class Footer extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    footerHeight: 0
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;
            }

            componentDidMount = () => {

            }

            componentDidUpdate = () => {
                const { width, height } = this.props.size;
                this.storeUi.setHeightFooter(this.props.size.height);
            }
        
            render() {
                return (
                    <footer className="wrapper-footer">
                        <FooterSocial />
                        <FooterPayments />
                        <NavigationFooter />
                        <Salutation />
                        {this.storeGeneral.shop === 'SHOP_SB' &&
                            <Disclaimer />
                        }
                        <Version />
                    </footer>
                )
            }
        }
    )
)

export default sizeMe({ monitorHeight: true })(Footer);
