import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as products from '../communicator/products';

class StoreProducts {
    list_audiobooks = []
    list_products = []
    count_products = 0
    media_list = []
    productSubGroups = []

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeUi = this.storeRoot.storeUi;

        this.getProductGroups();
    }

    // async getAudioBooks(medium) {
    //     try {
    //         const returnData = await products.getAudioBooks(medium);
    //         runInAction(() => {
    //             this.list_audiobooks = returnData.data;
    //         })
    //     } catch (error) {
    //         runInAction(() => {
    //             this.state = "error"
    //         })
    //     }
    // }
    
    async getProductsForCategory(cat, page_view, stock_priority) {
        this.storeGeneral.startLoader();
        const priority = (stock_priority !== undefined) ? stock_priority : 1;

        try {
            const returnData = await products.getProductsForCategory(cat, page_view, priority);
            runInAction(() => {
                this.list_products = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async getProductsForSubGroup(cat, subgroup, page_view, stock_priority) {
        this.storeGeneral.startLoader();
        const priority = (stock_priority !== undefined) ? stock_priority : 1;
        try {
            const returnData = await products.getProductsForSubGroup(cat, subgroup, page_view, priority);
            runInAction(() => {
                this.list_products = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async getProductGroups() {
        try {
            const cats = await products.getProductGroups();
            runInAction(() => {
                this.media_list = cats.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getProductSubGroups(group) {
        let returnData;
        try {
            returnData = await products.getProductSubGroups(group);
            runInAction(() => {
                this.productSubGroups = returnData.data;
                return returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return returnData.data;
    }

    async checkMainGroup(name) {
        let result = '';
        try {
            const returnData = await products.checkMainGroup(name);
            runInAction(() => {
                result = returnData.data[0].product_group;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }

    async getMainGroup(name) {
        let result = '';
        try {
            const returnData = await products.getMainGroup(name);
            runInAction(() => {
                result = returnData.data[0].product_group;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }
}

decorate(StoreProducts, {
    count_products: observable,
    list_audiobooks: observable,
    list_products: observable,
    media_list: observable,
    productSubGroups: observable,
    checkMainGroup: action,
    getMainGroup: action,
    getProductsForCategory: action,
    getProductGroups: action,
    getProductSubGroups: action
})

export default StoreProducts;