import * as apifetch from './fetch.js';

export async function checkShopForSku(sku) {
    const urlEndpoint = '/catalog/checkShopForSku.php';

    const postData = {
        sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookDetails(shop, id) {
    //SbC DEPRICATED
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/getBookDetails.php';
    
    const postData = {
        shop: shop,
        book_id: id
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error book details: ', error.message);
    }
}

export async function getBookDetailsBySku(shop, sku) {
    const urlEndpoint = '/catalog/getBookDetailsBySku.php';
    const postData = {
        shop: shop,
        book_sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookISBN(id) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/getBookISBN.php';
    
    const postData = {
        book_id: id
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error book isbn: ', error.message);
    }
}

export async function getBookCategories(shop, sku) {
    const urlEndpoint = '/catalog/getBookCategories.php';
    const postData = {
        shop: shop,
        sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookTags(sku) {
    const urlEndpoint = '/catalog/getBookTags.php';
    const postData = {
        sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookAlert(sku) {
    const urlEndpoint = '/catalog/getBookAlert.php';
    const postData = {
        sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookDownloads(sku) {
    const urlEndpoint = '/catalog/getBookDownloads.php';  
    const postData = {
        sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}