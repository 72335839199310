import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import ImgIssuer from './img-issuer';

const RadioIssuer = inject("stores") (
    observer (
        class RadioIssuer extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;
            }

            render() {
                let formControl = "form-control";
                let direction = "--" + this.props.direction;

                return (
                    <div className={"form-group form-group--radio " + direction}>
                        <ul className="payment-issuers__list">
                            {this.props.options.map(option => (
                                <li className={"payment-issuers__listitem" + (parseInt(this.props.selected) === parseInt(option.code) ? " --selected" : '')} key={option.value}>
                                    <label>
                                        <input type="radio"
                                            name = {this.props.name}
                                            value = {option.value}
                                            onChange = {this.props.onChange}
                                            className = {formControl}
                                            defaultChecked = {this.props.defaultValue === option.value}
                                        />
                                        <ImgIssuer
                                            option = {option}
                                        />
                                        <span>{option.displayValue}</span>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            
            }
        }
    )
)

export default RadioIssuer;