import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FormRegister from '../components/forms/form-register';
import Salutation from '../elements/txt-salutation';

const Register = inject("stores") (
    observer (
        class Register extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    redirect: this.storeAuth.user.is_loggedin ? true : false
                }
            }

            componentDidMount = () => {
                window.scrollTo(0, 0);
            }

            componentWillUnmount = () => {
                this.storeUi.setReturnState("new_user");
            }

            onNextAction = () => {
                let url;
                if (this.storeUi.app_interface.use_case === "inorder") {
                    url = "/shoppingcart";
                    this.props.history.push(url);
                } else {
                    //SbC return to previous page
                    this.props.history.goBack();
                }
            }
 
            render() {
                if (this.storeAuth.user.is_loggedin) {
                    return <Redirect 
                        push to={"/"} 
                    />;
                }

                const class_feature = this.storeUi.checkFeature('SHOP_ALL') ? '--feature-all' : ''
                const pos_top = this.storeUi.app_interface.height_header

                return (
                    <div 
                        className={"content-wrapper" + ' ' + class_feature}
                        style = {{marginTop: pos_top}}
                    >
                        <div className="content-wrapper__middle">
                            <div className="content-wrapper__content">
                                <div className="segment__header">{i18n.t("header.create_account")}</div>
                            </div>

                            <div className="content-wrapper__content">
                                <div className="content-section--register --active">
                                    <div className="content-section--register__column content-section--register__column--left">
                                        <p>Vul onderstaande gegevens in om je aan te melden.</p>
                                        <FormRegister  
                                            form = {'register'}
                                            want_newsletter = {true} 
                                            show_subscription = {true}
                                        />
                                    </div>
                                    <div className="content-section--register__column content-section--register__column--right">
                                        <p>
                                            <strong>{i18n.t("pages.login.txt_haslogin")}</strong><br/>
                                            <span className="content__textlink">
                                                <Link to="/login">{i18n.t("pages.login.link_haslogin")}</Link>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                   
                            </div>

                            <div className="content-wrapper__content">
                                <Salutation />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Register));
