import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

const SearchResultSpellcheck = inject("stores") (
    observer (
        class SearchResultSpellcheck extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    spellcheck: []
                }
            }

            componentDidMount = () => {
                this.setState({
                    spellcheck: this.props.spellcheck
                })
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.spellcheck.length !== this.props.spellcheck.length) {
                    this.setState({
                        spellcheck: this.props.spellcheck
                    })
                }

                if (prevProps.searchValue !== this.props.searchValue) {
                    this.setState({
                        spellcheck: this.props.spellcheck
                    })
                }
            }

            searchIntention = (searchTerm) => {
                this.storeUi.setSearchValue(searchTerm);
            } 

            render() {
                return (
                    <div
                        className = "search-overlay__spellcheck"
                    >
                        <React.Fragment>
                            <div 
                                className = "search-overlay__spellcheck-header"
                            >
                                Bedoelde je misschien:
                            </div>
                            <ul 
                                className = "search-overlay__spellcheck-list"
                            >
                                {this.state.spellcheck.slice(0, 5).map((item) => (
                                    <li 
                                        className = '--link'
                                        onClick={(e) => this.searchIntention(item.word)}
                                    >
                                        {item.word}
                                    </li>
                                ))}
                                
                            </ul>
                        </React.Fragment>
                    </div>
                )
            }
        }
    )
)

export default withRouter(SearchResultSpellcheck);
