import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

const BlockBookCategories = inject("stores") (
    observer (
        class BlockBookCategories extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    cats: []
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.excluded = [
                    'eigen uitgaven', 'nieuw'
                ]

                // this.controller = new AbortController();
                // this.signal = this.controller.signal;
            }

            componentDidMount = async() => {
                let has_cats = false;
                const categories = await this.storeBooks.getBookCategories(this.props.sku);
                if (this.storeGeneral.shop === 'SHOP_ALL') {
                    this.setState({
                        cats: categories && categories.length > 0 ? categories : []
                    })
                } else {
                    categories[0] && Object.entries(categories[0]).map(([key, value]) => {
                        has_cats = (value !== null && value !== '') ?  true : has_cats;
                        return has_cats;
                    });
                        
                    this.setState({
                        cats: (has_cats && categories) && categories.length > 0 ? categories[0] : []
                    })
                }
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.sku !== this.props.sku) {
                    const categories = await this.storeBooks.getBookCategories(this.props.sku);
                    if (this.storeGeneral.shop === 'SHOP_ALL') {
                        this.setState({
                            cats: categories && categories.length > 0 ? categories : []
                        })
                    } else {
                        this.setState({
                            cats: categories && categories.length > 0 ? categories[0] : []
                        })
                    }
                }
            }

            handleOnClick(key, cat_name, is_main_cat) {
                let url;

                if (key === 'categorie' || key === 'categorie1' || key === 'categorie2' || key === 'categorie3') {
                    //SbC category selected
                    let id = 0;
                    this.storeUi.setPageView('page', 1);
                    //this.storeBooks.getBooksForCategory(cat_name, this.storeUi.page_view);
                    //this.storeUi.setShowSubCats(cat_name, false);

                    //this.storeUi.setActiveCategory(cat_name);
                    this.storeUi.setActiveSubCategory('');
                    this.storeUi.setActiveProductGroup('');

                    url = "/category/" + cat_name;
                }
                if (key === 'sub_categorie' || key === 'sub_categorie1' || key === 'sub_categorie2') {
                    //SbC subcategory selected
                    //this.storeUi.setActiveSubCategory(cat_name);    
                    this.storeUi.setNavMobile(false);
                    this.storeUi.setPageView('page', 1);
                    
                    url = "/subcategory/" + cat_name;
                }
                if (key === 'media') {
                    //SbC product group selected
                    this.storeUi.setPageView('page', 1);
                    this.storeUi.setActiveProductGroup(cat_name);
                    this.storeUi.setActiveSubGroup('');
                    this.storeUi.setActiveCategory('');
                    this.storeUi.setActiveLanguage('');
                    this.storeUi.setNavMobile(false);

                    url = "/products/" + cat_name;
                }

                if (key === 'nur') {
                    this.storeUi.setPageView('page', 1);

                    if (is_main_cat === 1) {
                        url = "/category/" + cat_name;
                    } else {
                        url = "/subcategory/" + cat_name;
                    }
                }

                this.props.history.push(url);
            }

            render() {
                if (this.state.cats && this.state.cats.length === 0) {
                    return null;
                } else {
                    return (
                        <div className="block block--categories">
                            <span className="block--categories__title">Categorieën</span>
                            <ul className="block--categories__list">
                                {(this.storeGeneral.shop === 'SHOP_ALL' && this.state.cats.length > 0) &&
                                    this.state.cats.map((cat, i) => (
                                        <li 
                                            className="block--tags__listitem --link" 
                                            onClick={(e) => this.handleOnClick('nur', cat['category'], cat['is_main_cat'])}
                                            dangerouslySetInnerHTML={{ __html: cat['display_value']}}
                                        />
                                    ))
                                }

                                {(this.storeGeneral.shop === 'SHOP_SB' && this.state.cats) &&
                                    Object.keys(this.state.cats).map((key, i) => (
                                        this.state.cats[key] !== null && !this.excluded.includes(this.state.cats[key]) && isNaN(this.state.cats[key]) &&
                                            <li 
                                                className="block--tags__listitem --link" 
                                                onClick={(e) => this.handleOnClick(key, this.state.cats[key])}
                                                dangerouslySetInnerHTML={{ __html: this.state.cats[key]}}
                                            />
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
            }
        }
    )
)

export default withTranslation()(withRouter(BlockBookCategories));


