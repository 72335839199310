import React, { Component } from 'react';
import { runInAction, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import validate from '../../utilities/validation-rules';
import * as ui from '../../utilities/ui';

import Checkbox from '../../elements/form-input-checkbox';
import TextInput from '../../elements/form-input-text';
import FormTextIncomplete from '../../elements/form-text-incomplete';
import ContentText from '../../components/content/content-text';
import RowText from '../form-rows/row-text';
import FormFeedbackRequired from '../../elements/forms/form-feedback-required';

const CheckLabel = inject("stores") (
    observer (
        class CheckLabel extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }
    
            openPP = () => {
                this.storeUi.setContentComponent(
                    <ContentText
                        title = {i18n.t("page_headers.privacy_policy")}
                        page = {"privacy-policy"}
                        className = "content-scrollable"
                    />
                );
                this.storeUi.openDialogContent();
            }
    
            render() {
                return (
                    <span><span dangerouslySetInnerHTML={{ __html: i18n.t("forms.label.subscribe")}} /> <a href='#' onClick={(e) => this.openPP(e)}>privacy statement</a></span>
                )
            }
        }
    )
)

const FormRegister = inject("stores") (
    observer (
        class FormRegister extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    form: this.props.form ? this.props.form : 'register',
                    formControls: { 
                        firstname: {
                            type: "text",
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        lastname: {
                            type: "text",
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },         
                        email: {
                            type: "email",
                            value: '',
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: false
                        },
                        email2: {
                            type: "email",
                            value: '',
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: false,
                            disable: ["paste"]
                        },
                        phonenr: {
                            type: "text",
                            value: '',
                            validationRules: {  
                                isPhoneNumber: true
                            },
                            valid: true
                        },
                        password: {
                            type: "password",
                            value: '',
                            validationRules: {
                                isRequired: true,
                                minLength: 5,
                                noForbiddenCharacters: true
                            },
                            validationMsg: 'ruleMsg',
                            valid: false
                        },
                        password2: {
                            type: "password",
                            value: '',
                            validationRules: {
                                isRequired: true,
                                minLength: 5,
                                noForbiddenCharacters: true
                            },
                            validationMsg: 'ruleMsg',
                            disable: ["paste"],
                            valid: false
                        },
                        has_subscription: {
                            label: <CheckLabel />,
                            value: this.props.want_newsletter ? (this.props.want_newsletter ? 1 : 0) : false,
                            validationRules: {},
                            valid: true
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false,
                    error_email: false,
                    error_email2: false,
                    error_email_match: false,
                    error_pw_match: false,
                    classFeedback: ''
                }
            }

            componentDidMount = () => {
                this.storeUser.clearFormFeedback();
            }

            componentWillUnmount = () => {
                this.storeUser.clearFormFeedback();
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUi.clearFormFeedback();

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = async() => {
                let matchingEmail;
                let error_email_match;

                let matchingPw;
                let error_pw_match;

                let emailAvailable = true;
                let emailAvailable2 = true;

                if (this.state.formControls.email.value !== '' && this.state.formControls.email2.value !== '') {
                    matchingEmail = ui.checkMatchingValues(this.state.formControls.email.value, this.state.formControls.email2.value);
                    error_email_match = !matchingEmail;
                }

                if (this.state.formControls.password.value !== '' && this.state.formControls.password2.value !== '') {
                    matchingPw = ui.checkMatchingValues(this.state.formControls.password.value, this.state.formControls.password2.value);
                    error_pw_match = !matchingPw;
                }

                console.log('SbC check characters')

                let emailValue = this.state.formControls.email.value;
                if (emailValue !== '') {
                    emailAvailable = await this.checkEmail(emailValue);                    
                }

                let emailValue2 = this.state.formControls.email2.value;
                if (emailValue2 !== '') {
                    emailAvailable2 = await this.checkEmail(emailValue2);                    
                }

                const formIsValid = matchingEmail && matchingPw && emailAvailable && emailAvailable2 && this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    error_firstname: this.state.formControls.firstname.value.length >= 2 ? false : this.state.error_firstname,
                    error_email: !emailAvailable,
                    error_email2: !emailAvailable2,
                    error_email_match: error_email_match,
                    error_pw_match: error_pw_match,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            async checkEmail(value) {
                let emailAvailable = await this.storeUser.checkEmailExists(value);
                return emailAvailable;    
            }

            handleBlur = () => {
                this.setState({
                    error_firstname: (this.state.formControls.firstname.value !== '' && this.state.formControls.firstname.value.length === 1) ? true : false
                })
            }

            registerUser = async(e) => {
                e.preventDefault();
                if (this.state.formIsValid && this.state.btn_class !== '--disabled') {
                    this.setState({
                        btn_class: '--disabled'
                    })
                    let registered = await this.storeUser.registerUser(this.state.formControls, this.state.form);
                    if ('success' in this.storeUser.form_feedback) {
                        this.props.onNext();
                    }
                }
            }

            handleCheckboxChange = (event) => {
                const name = 'has_subscription';

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...this.state.formControls[name],
                            value: !this.state.formControls[name].value,
                            selected: !this.state.formControls[name].value ? 1 : 0
                        }
                    }
                    
                })
            }

            handleClose = (e) => {
                this.setState({
                    classFeedback: '--inline'
                })
            }

            render() {
                return (
                    <div className="form-wrapper">
                        <FormFeedbackRequired />

                        <div className="form-fieldset">
                                 <form className="form form--register">
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        <React.Fragment>
                                            {key !== "has_subscription" &&
                                                <React.Fragment>
                                                    {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                                        <RowText 
                                                            type = {this.state.formControls[key].type}
                                                            name = {key}
                                                            value = {this.state.formControls[key].value}
                                                            validationRules = {this.state.formControls[key].validationRules}
                                                            validationMsg = {this.state.formControls[key].validationMsg}
                                                            onValidate = {this.validateRow}
                                                            onBlur = {this.handleBlur}
                                                            disable = {this.state.formControls[key].disable}
                                                        />
                                                    }
                                                </React.Fragment>
                                            }

                                            {key === "firstname" && this.state.error_firstname &&
                                                <span className="form-field--suggestion">{i18n.t("form_suggestions.firstname")}</span>
                                            }

                                            {((key === "email" && this.state.error_email) || (key === "email2" && this.state.error_email2)) &&
                                                <span className="form-field--error --email">{i18n.t("form_errors.email_in_use_extended")}</span>
                                            }

                                            {key === "email2" && this.state.error_email_match &&
                                                <span className="form-field--error --email">{i18n.t("form_errors.email_not_matching")}</span>
                                            }

                                            {key === "password2" && this.state.error_pw_match &&
                                                <span className="form-field--error --pw">{i18n.t("form_errors.pw_not_matching")}</span>
                                            }

                                            {key === "has_subscription" && (
                                                this.props.show_subscription &&
                                                    <div className="form-row form-row--input">
                                                        <Checkbox 
                                                            name = {"has_subscription"} 
                                                            label = {this.state.formControls["has_subscription"].label}
                                                            isSelected = {this.state.formControls["has_subscription"].value}
                                                            onCheckboxChange={this.handleCheckboxChange}
                                                            className = {"--reduced"}
                                                        />
                                                    </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {this.state.error &&
                                        <div className="form-row form-row--error">
                                            <p>{i18n.t("errors.error_general")}</p>
                                        </div>
                                    }
                                </form>

                                <div className="form-row form-row--buttons">
                                    <button 
                                        className={"btn btn--primary " + this.state.btn_class} 
                                        onClick={(e) => this.registerUser(e)}
                                    >
                                        {i18n.t("button.register")}
                                    </button>
                                </div>
                                
                                {Object.keys(this.storeUser.form_feedback).length > 0 &&
                                    <React.Fragment>
                                        {'error' in this.storeUser.form_feedback &&     
                                            <div className={"form__feedback form__feedback--dialog " + this.state.classFeedback}>
                                            <div className="btn--close" onClick={(e) => this.handleClose(e)}><div className="btn--icon --link icon--close">Sluit</div></div>
                                                <span>Er is helaas een fout opgetreden</span>
                                                <span>Probeer het later nog een keer.</span>
                                            </div>                  
                                        }
                                    </React.Fragment>
                                }
                            </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormRegister);
