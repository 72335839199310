import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

const NavigationSecundary = inject("stores") (
    observer (
        class NavigationSecundary extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
            }

            handleNavClick = (e, id, path) => {
                this.storeUi.setActiveMainNav(0);
                //this.storeUi.setActiveToggleNav(1);
                this.storeUi.setActiveSubNav(id);
                this.props.history.push(path);
            }

            render() {
                const nav_id = this.storeUi.active_subnav
                const partner_code = this.storeUi.app_interface.partner_code

                const Li = (item) => {
                    const activeClass = (item.id === nav_id) ? ' --active' : '';
                    return (
                        <li className={"navigation--secundary__listitem" + activeClass} onClick={(e) => this.handleNavClick(e, item.id, item.path)}>
                            <span dangerouslySetInnerHTML={{ __html: item.title}}></span>
                        </li>
                    )
                }

                return (
                    <div className="navigation--secundary">
                        <ul className="navigation--secundary__list">
                            {this.storeUi.sub_nav.map((item, i) => (
                                <React.Fragment key={i}>
                                    {item.use === 'all' && (item.show && item.show.includes(this.storeGeneral.shop)) && (
                                        <Li 
                                            key = {i}
                                            id = {item.id}
                                            title = {i18n.t("navigation." + item.name)}
                                            path = {item.path}
                                        />
                                    )}
                                    {item.use === 'partner' && (
                                        <React.Fragment key={i}>
                                            {(!this.storeAuth.user.is_loggedin && item.value === false) &&
                                            <Li 
                                                key = {i}
                                                id = {item.id}
                                                title = {i18n.t("navigation." + item.name)}
                                                path = {item.path}
                                            />}
                                            {(this.storeAuth.user.is_loggedin && (!this.storeAuth.user.is_partner && this.storeAuth.user.is_partner !== 'on') && !item.value) &&
                                            <Li 
                                                key = {i}
                                                id = {item.id}
                                                title = {i18n.t("navigation." + item.name)}
                                                path = {item.path}
                                            />}
                                            {(this.storeAuth.user.is_loggedin && (this.storeAuth.user.is_partner || this.storeAuth.user.is_partner === 'on') && item.value) &&
                                            <Li 
                                                key = {i}
                                                id = {item.id}
                                                title = {i18n.t("navigation." + item.name)}
                                                path = {item.path}
                                            />}
                                            </React.Fragment>
                                    )} 
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(NavigationSecundary));
