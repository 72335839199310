import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import BlockLatest from '../block/block-latest';
import BlockNewsletter from '../block/block-newsletter';
import BlockNewestBooks from '../block/block-newest-books';
import BlockShipping from '../block/block-shipping';
import BlockTop10 from '../block/block-top10';
import BlockTop20 from '../block/block-top20';

const SidebarRight = inject("stores") (
    observer (
        class SidebarRight extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                }

                this.storeGeneral = this.props.stores.storeGeneral;
            }

            componentDidMount = async() => {
                let list = this.storeGeneral.settings_list["sidebarTop10"];
                let tag = "top10" + list;
                let title = "blocks." + tag + ".subtitle";

                if (tag === 'top10undefined') {
                    const settings = await this.storeGeneral.getSettings();
                    list = this.storeGeneral.settings_list["sidebarTop10"];
                    tag = "top10" + list;
                    title = "blocks." + tag + ".subtitle";
                }

                this.setState({
                    tag: tag,
                    title: title 
                })
            }
        
            render() {
                return (
                    <div className="content-wrapper__sidebar content-wrapper__sidebar--right">
                        {(this.storeGeneral.shop === 'SHOP_ALL') &&
                            <React.Fragment>
                                <BlockShipping />
                                {this.state.tag &&
                                    <BlockTop10 
                                        tag = {this.state.tag}
                                        title = {this.state.title}
                                    />
                                }
                            </React.Fragment>
                        }
                        
                        {(this.storeGeneral.shop !== 'SHOP_ALL') &&
                            <React.Fragment>
                                <BlockLatest />
                                <BlockNewsletter />
                                <BlockShipping />
                                <BlockNewestBooks />
                                <BlockTop20 />
                            </React.Fragment>
                        }
                    </div>
                )
            }
        }
    )
)

export default SidebarRight;
