import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import ReactTooltip from "react-tooltip";

const DialogTooltip = inject("stores") (
    observer (
        class DialogTooltip extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                }
            }

            componentDidMount = async() => {
                const tooltip = await this.storeUi.getTooltip(this.props.sku);
                if (tooltip) {
                    this.setState({
                        title: tooltip[0].title,
                        text: tooltip[0].text,
                        visible: tooltip[0].show_on_sb
                    })
                }
            }

            composeTip = () => {
                let str = '<span class="tooltip__title">' + this.state.title + '</span>';
                str = str + '<span class="tooltip__text">' + this.state.text;

                if (this.props.delivery) {
                    str = str + ' ' + this.props.delivery;
                }

                str = str + '.</span>';
                            
                return str;
            }

            render() {  
                if (this.state.visible === 0 || this.state.visible === '0') {
                    return null;
                } else {
                    return ( 
                        <React.Fragment>
                            <div className="content__tooltip --link" 
                                data-tip={this.composeTip()}
                            >
                                i
                            </div>

                            <ReactTooltip 
                                type = {"light"}
                                effect = {"solid"}
                                html = {true}
                                border = {true}
                                borderColor = {"#CCC"}
                            />
                        </React.Fragment>
                    )
                }
            }
        }
    )
)

export default DialogTooltip;
