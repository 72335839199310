import moment from "moment";

const validate = (value, rules, msg) => {
    let isValid = true;
    let msgError = '';

    for (let rule in rules) {
        switch (rule) {
            case 'minLength': 
                // if (!msg) {
                //     isValid = isValid && minLengthValidator(value, rules[rule]); 
                // } else {
                    isValid = {
                        value: isValid && minLengthValidator(value, rules[rule]),
                        msg: "Gebruik minimaal " +  rules[rule] + " karakters"
                    }

                    if (!minLengthValidator(value, rules[rule])) {
                        msgError = "Gebruik minimaal " +  rules[rule] + " karakters";
                    }
                //}
                break;
            
            case 'maxLength': isValid = isValid && maxLengthValidator(value, rules[rule]); break;
            
            case 'isRequired': isValid = isValid && requiredValidator(value); break;
            
            case 'isEmail': isValid = isValid && emailValidator(value); break;
            
            case 'isPhoneNumber': isValid = isValid && phoneNumberValidator(value); break;

            case 'isEmailBasic': isValid = isValid && basicEmailValidator(value); break;

            case 'isInteger': isValid = isValid && integerValidator(value); break;

            case 'onlyNumbers': isValid = isValid && onlyNumbersValidator(value); break;

            case 'noForbiddenCharacters': 
                //if (!msg) {
                    //console.log('SbC check..no msg')
                    // isValid = {
                    //     value: isValid && noForbiddenCharactersValidator(value)
                    // }
                    if (!noForbiddenCharactersValidator(value)) {
                        msgError = 'Je gebruikt tekens, die niet toegestaan zijn';
                    }
                //} else {
                    //console.log('SbC check msg..')
                //     isValid = {
                //         value: isValid && noForbiddenCharactersValidator(value),
                //         msg: isValid.msg
                //     }
                //}
                break;
            
            case 'passCaptcha': isValid = isValid && captchaValidator(value); break;
            
            default: isValid = true;
        }

    }

    if (msgError !== '') {
        isValid = {
            value: false,
            msg:  msgError
        }
    }

    return isValid;
}

/**
 * minLength Val
 * @param  value 
 * @param  minLength
 * @return          
 */
const minLengthValidator = (value, minLength) => {
    return value.length >= minLength;
}

/**
 * maxLength Val
 * @param  value 
 * @param  minLength
 * @return          
 */
const maxLengthValidator = (value, maxLength) => {
    return value.length <= maxLength;
}

/**
 * Check to confirm that field is required
 * 
 * @param  value 
 * @return       
 */
const requiredValidator = value => {
    if (value && value !== null) {
        return value.trim() !== '';	
    } else {
        return false;
    }
}

/**
 * Check to confirm that field is integer
 * 
 * @param  value 
 * @return       
 */
const integerValidator = value => {	
    //SbC allow negatives and numbers
    //return /^-?[0-9]*$/.test(value);
    return Number.isInteger(Number(value)) === true;	
}

/**
 * Check to confirm only numbers in field
 * 
 * @param  value 
 * @return       
 */
const onlyNumbersValidator = value => {
    return /^\d+$/.test(value);	
}

/**
 * Check to confirm can be phone number
 * 
 * @param  value 
 * @return       
 */
const phoneNumberValidator = value => {
    var re = /^[\+]?([\ 0-9\-\.\(\)]+$)/;	
    return re.test(value);	
}

/**
 * Check to pass captcha
 * 
 * @param  value 
 * @return       
 */
const captchaValidator = value => {
    const days = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const today = moment().day();
    const isValid = (days.indexOf(value.toLowerCase()) > -1 && days.indexOf(value.toLowerCase()) == today) ? true : false;
    return isValid;
}

/**
 * Email validation - basic
 * 
 * @param value
 * @return 
 */
const basicEmailValidator = value => {
    var re = /[@]+[.]+[.(*)]$/;
    return re.test(String(value).toLowerCase());
}

/**
 * Email validation
 * 
 * @param value
 * @return 
 */
const emailValidator = value => {
    var last3 = value.slice(-3);
    if (last3 === '.co') {
        return false;
    } else {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    }
}

/**
 * Cheeck for forbidden characters
 * 
 * @param  value 
 * @return       
 */
const noForbiddenCharactersValidator = value => {
    //SbC characters forbidden in password
    var re = /["'`]/;
    return !re.test(value);	
}

export default validate;

