import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Template from '../../templates/template';

import SitemapAuthors from '../../components/sitemap/sitemap-authors';
import SitemapCats from '../../components/sitemap/sitemap-cats';
//import SitemapGenres from '../../components/sitemap/sitemap-genres';
import SitemapPages from '../../components/sitemap/sitemap-pages';

const Sitemap = inject("stores") (
    observer (
        class Sitemap extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUser = this.props.stores.storeUser;
            }

            render() {
                return (
                    <Template
                        type = 'single-column'
                        header = {"sitemap"}
                        nav_name = {"sitemap"}
                    >
                        {/* <div className="content-wrapper__segment">
                            <div className="sitemap__header">
                                {i18n.t("sitemap.genres")}
                            </div> 
                            <SitemapGenres
                            />
                        </div> */}
                        <div className="content-wrapper__segment">
                            <div className="sitemap__header">
                                {i18n.t("sitemap.categories_sb")}
                            </div> 
                            <SitemapCats 
                                shop = 'SHOP_SB'
                            />
                        </div>
                        {this.storeUi.checkFeature('SHOP_ALL') &&
                            <div className="content-wrapper__segment">
                                <div className="sitemap__header">
                                    {i18n.t("sitemap.categories_all")}
                                </div> 
                                <SitemapCats 
                                    shop = 'SHOP_ALL'
                                />
                            </div>
                        }
                        <div className="content-wrapper__segment">
                            <div className="sitemap__header">
                                {i18n.t("sitemap.other_pages")}
                            </div> 
                            <SitemapPages
                            />
                        </div>
                        <div className="content-wrapper__segment">
                            <div className="sitemap__header">
                                {this.storeGeneral.shop === 'SHOP_ALL'
                                ?   i18n.t("sitemap.authors_sb2")
                                :   i18n.t("sitemap.authors_sb")
                                }
                            </div> 
                            <SitemapAuthors 
                                authors_sb = {true}
                            />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(Sitemap);
