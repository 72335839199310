import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as ui from '../utilities/ui';

import Template from '../templates/template';
import FormRegister from '../components/forms/form-register';

const NewsletterSubscription = inject("stores") (
    observer (
        class NewsletterSubscription extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUser = this.props.stores.storeUser;
                
            }

            componentDidMount = () => {
                window.scrollTo(0, 0);

                if (!this.storeUser.user_data.is_loggedin) {
                    this.storeUi.setReturnState("new_user");
                } else {
                    this.storeUi.setReturnState("current_user");
                }
            }

            componentDidUpdate = () => {
                window.scrollTo(0, 0);
            }

            handleRoute = (e, url) => {
                e.preventDefault();
                if (url === '/login') {
                    this.storeUi.setReturnState("current_user");
                } else {
                    this.storeUi.setReturnState("new_user");
                }
                this.props.history.push(url);
            }
      
            render() {
                return (
                    <Template
                        type = 'single-column'
                        header = "free_newsletter"
                    >   
                        {!this.storeUser.user_data.is_loggedin && 
                            <React.Fragment>
                                <div className="content-segment">    
                                    <p>In deze nieuwsbrief delen we nieuws en informatie over de laatste ontwikkelingen op het gebied van gezondheid, voeding, niet-reguliere geneeswijzen en andere interessante zaken. Uiteraard komen ook de actualiteiten aan bod. Voor sommige mensen zal dit gesneden koek zijn, terwijl het voor andere mensen opzienbarend en soms zelfs confronterend kan zijn.</p>
                                </div>

                                <div className="content-segment">
                                    <h2>Ben je nog niet bekend bij ons?</h2>
                                    <p>En wil je onze nieuwsbrief ontvangen? Vul dan onderstaand formulier in en klik op 'Aanmelden'.</p>
                                    <p>Het lijkt of we veel vragen, maar het is voor je eigen veiligheid.</p>
                                    <FormRegister 
                                        form = {'newsletter'}
                                        want_newsletter = {true} 
                                        show_subscription = {false}
                                    />
                                </div>

                                <div className="content-segment">
                                    <h2>Kennen we je e-mailadres al en heb je al een wachtwoord voor onze website?</h2>
                                    <p>Dan is het niet nodig om bovenstaand formulier in te vullen. In dat geval kun je inloggen en de instelling bij 'Mijn gegevens' aanpassen.</p>
                                    <span onClick={(e) => this.handleRoute(e, "/login")} className="link--read-more">Ga naar inloggen</span>
                                </div>
                            </React.Fragment>
                        }

                        {this.storeUser.user_data.is_loggedin && this.storeUi.app_interface.return_state === "new_user" && 
                              <React.Fragment>
                                    <div className="content-segment">
                                        <span>Dank je voor je aanmelding. We houden je graag op de hoogte van nieuwe uitgaven en nieuws omtrent Succesboeken.nl.</span>
                                    </div>
                                </React.Fragment>
                        }
                            
                        {this.storeUser.user_data.is_loggedin && this.storeUser.user_data.profile.has_subscription !== '1' && this.storeUser.user_data.profile.has_subscription !== 'on' && this.storeUi.app_interface.return_state === "current_user" &&
                            <div className="content-segment"> 
                                <p>Wil je onze gratis nieuwsbrief ontvangen en op de hoogte blijven van onze nieuwe uitgaven en ander nieuws?</p>
                                <p>Pas de instelling op de pagina 'Mijn gegevens' aan door het vakje bij 'Ik ontvang graag de nieuwsbrief' aan te vinken.</p>
                                <span onClick={(e) => this.handleRoute(e, "/account")} className="link--read-more">Ga naar 'Mijn gegevens'</span>
                            </div>
                        }

                        {this.storeUser.user_data.is_loggedin && (this.storeUser.user_data.profile.has_subscription === '1' || this.storeUser.user_data.profile.has_subscription === 'on') && this.storeUi.app_interface.return_state === "current_user" &&
                            <div className="content-segment">   
                                <p>Je hebt reeds aangegeven dat je onze nieuwsbrief wilt ontvangen en op de hoogte wilt blijven van onze nieuwe uitgaven en ander nieuws.</p>
                                <p>Eventueel kun je zelf deze instelling op de pagina 'Mijn gegevens' aanpassen.</p>
                                <span onClick={(e) => this.handleRoute(e, "/account")} className="link--read-more">Ga naar 'Mijn gegevens'</span>
                            </div>
                        }
                    </Template>
                )
            }
        }
    )
)

export default withRouter(NewsletterSubscription);
