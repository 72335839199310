import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import moment from "moment";

import * as content from '../../utilities/content';

import BookPrice from '../../elements/book-price';
import ImgCover from '../../elements/img-cover';
import IconPreviewCoverPdf from '../../elements/ic-preview-cover-pdf';
import BlockBookActions from './block-book-actions';
import BlockBookSpecsSummary from './block-book-specs-summary';
import BlockBookStock from './block-book-stock';
import DialogTooltip from '../../elements/dialog-tooltip';
import BookAuthor from './book-author';
import BookTitle from './book-title';
import BookRanking from '../../elements/book-ranking';
import BlockPreviewCoverBack from './block-preview-coverback';
import BlockPreviewCoverFront from './block-preview-coverfront';
//import BlockPreviewPdf from './block-preview-pdf';
import BlockNotifications from './block-notifications';

// const controller = new AbortController();
// const { signal } = controller;

const BlockBook = inject("stores") (
    observer (
        class BlockBook extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    count: 0,
                    redirect: false,
                    has_cover_pdf: false,
                    nrReviews: 0
                }

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeCatalog = this.props.stores.storeCatalog;
            }

            componentDidMount = async() => {
                let notifications;
                let hasNotification = false;

                if (this.props.type === "productpage") {
                    notifications = await this.storeCatalog.getNotifications();
                }
                
                this.getProduct();

                if (this.props.type === "productpage") {
                    if (Array.isArray(notifications)) {
                        notifications.map((notification) => {
                            if (this.props.sku === notification['sku']) {
                                hasNotification = true;
                            }
                        })
                    }
                }

                if (this.props.type === "productpage") {
                    const has_pdf = await this.storeUi.checkFile(this.props.sku, 'cover');

                    let reviewsList = this.storeBooks.reviewsList;
                    let nrReviews = 0;
                    if (reviewsList && reviewsList.length > 0) {
                        nrReviews = reviewsList.length;
                    }

                    if ('error' in has_pdf[0]) {
                        this.setState({
                            sku: this.props.sku,
                            has_cover_pdf: false,
                            notifications: notifications,
                            hasNotification: hasNotification,
                            nrReviews: nrReviews
                        })
                    } else {
                        this.setState({
                            sku: this.props.sku,
                            has_cover_pdf: true,
                            notifications: notifications,
                            hasNotification: hasNotification,
                            nrReviews: nrReviews
                        })
                    }
                } else {
                    this.setState({
                        sku: this.props.sku,
                        book: this.props.book,
                        notifications: notifications,
                        hasNotification: hasNotification
                    })
                }
            }

            async componentDidUpdate(prevProps) {
                let notifications;
                let hasNotification = false;

                if (prevProps.sku !== this.props.sku) {
                    if (this.props.type === "productpage") {
                        this.getProduct();
                        const has_pdf = await this.storeUi.checkFile(this.props.sku, 'cover');

                        notifications = await this.storeCatalog.getNotifications();
                        if (Array.isArray(notifications)) {
                            notifications.map((notification) => {
                                if (this.props.sku === notification['sku']) {
                                    hasNotification = true;
                                }
                            })
                        }

                        if ('error' in has_pdf[0]) {
                            this.setState({
                                sku: this.props.sku,
                                has_cover_pdf: false,
                                notifications: notifications,
                                hasNotification: hasNotification,
                            })
                        } else {
                            this.setState({
                                sku: this.props.sku,
                                has_cover_pdf: true,
                                notifications: notifications,
                                hasNotification: hasNotification,
                            })
                        }
                    } else {
                        this.getProduct();
                        this.setState({
                            sku: this.props.sku
                        })
                    }
                }
            }

            async getProduct() {
                let count = 1;
                let count2 = 1;
                let book = this.props.book;
                let shop;
                let str;

                if (!this.props.has_details) {
                    if (this.storeUi.checkFeature('SHOP_ALL')) {
                        shop = 'SHOP_ALL';
                    } else {
                        shop = 'SHOP_SB';
                    }

                    shop = await this.storeUi.checkShopForSku(this.props.sku);

                    if (this.props.book.media === 'Event') {
                        shop = 'SHOP_SB';
                    }
                    book = await this.storeBooks.returnBookDetailsBySku(shop, this.props.sku);
                }

                if (book) {
                    if (book.author_prefix !== null) {
                        str = book.author_firstname + ' ' + book.author_prefix + ' ' + book.author_lastname;
                    } else {
                        str = book.author_firstname + ' ' + book.author_lastname;
                    }
                    //count = await this.storeUi.returnBookCountForAuthor(shop, str);
                    if (book.author2_lastname !== null) {
                        str = book.author2_firstname + ' ' + book.author2_lastname;
                        //count2 = await this.storeUi.returnBookCountForAuthor(shop, str);
                    }
                }
              
                this.setState({
                    book: book,
                    count: count,
                    count2: count2
                })
            }

            handleOnClick = (e, sku) => {
                if (this.props.match.params.sku !== sku) {
                    this.storeUi.setCurrentBook(sku);
                    this.storeBooks.getBookDetailsBySku(sku);
                    this.setState({
                        redirect: true
                    });
                }
            }

            handleOnClickReplacement = (e, sku) => {
                this.storeUi.setCurrentBook(sku);
                this.storeBooks.getBookDetailsBySku(sku);
                this.setState({
                    sku: sku,
                    redirect: this.props.match.params.sku ? false : true
                });

                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    //url = "/book/" + sku + "/" + id;
                    url = "/book/" + sku;
                } else {
                    url = "/book/" + sku;
                }
                this.props.history.push({
                    sku: sku,
                    pathname: url
                })
            }

            addEllipsis(descr) {
                // SbC shorten descr to 200
                let descrShort = '';

                if (descr) {
                    descrShort = descr.substring(0,200);
                }
                let lastStr = descrShort.split(" ").pop("");

                // SbC remove last string until space
                descrShort = descrShort.substring(0, descrShort.length - lastStr.length )
                let correctDescr = descrShort + '...';

                return correctDescr;               
            }

            pushHistory = (url) => {
                this.props.history.push(url);
            }
  
            render() {
                let current_book = this.state.book;
                let id;

                if (this.state.redirect) {
                    if (this.state.book.title) {
                        id = content.formatTitle(this.state.book.title);
                    }
                    if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                        return <Redirect 
                            push to={"/book/" + this.props.sku + "/" + id} 
                        />;
                    } else {
                        return <Redirect 
                            push to={"/book/" + this.props.sku} 
                        />;
                    }
                }

                return (
                    <React.Fragment>
                    {this.props.type === "productpage" && this.state.hasNotification &&
                        <BlockNotifications 
                            sku = {this.props.sku}
                            notifications = {this.state.notifications}
                            hasNotification = {this.state.hasNotification}
                            type = {this.props.type}
                        />
                    }
                    
                    <div className="block-content">
                        <div className="block-content__column">
                            <div className="block-content--book__cover">
                                <div className="cover__wrapper">
                                    <div 
                                        className={"content" + (this.props.type !== "productpage" ? " --link" : "")} 
                                        onClick={(e) => this.handleOnClick(e, this.props.sku)}
                                    >
                                        <ImgCover
                                            sku = {this.state.sku}
                                            title = {this.state.book && this.state.book.title}
                                            soldout = {(this.state.book && (parseInt(this.state.book.stock) === 2 || parseInt(this.state.book.stock) === 3)) ? true : false}
                                        />

                                        {(this.storeGeneral.settings_list.skuSoonAvailable !== '' && this.state.sku === this.storeGeneral.settings_list.skuSoonAvailable) &&
                                            <span className="cover__soon">Binnenkort</span>
                                        }

                                        {(this.state.book && parseInt(this.state.book.stock) === 2) &&
                                            <span className="cover__soldout">Uitverkocht</span>
                                        }

                                        {(this.state.book && parseInt(this.state.book.stock) === 3) &&
                                            <span className="cover__discontinued">Niet meer leverbaar</span>
                                        }

                                        {this.props.type !== "productpage" && this.storeGeneral.shop === "SHOP_ALL" &&
                                            <BookRanking
                                                sku = {this.state.sku}
                                            />
                                        }
                                        {this.props.type === "productpage" && this.state.has_cover_pdf &&
                                            <IconPreviewCoverPdf
                                                sku = {this.props.sku}
                                            />
                                        }
                                    </div>

                                    {/* {this.props.type !== "productpage" &&
                                        <BlockPreviewPdf
                                            sku = {this.state.sku}
                                            label = {true}
                                        />
                                    } */}

                                    {this.props.type === "productpage" &&
                                        <div 
                                            className={"block-content--book__preview-covers" + (this.props.type !== "productpage" ? " --link" : "")} 
                                            onClick={(e) => this.handleOnClick(e, this.props.sku)}
                                        >                                      
                                            <BlockPreviewCoverFront 
                                                sku = {this.props.sku}
                                                soldout = {(this.state.book && (parseInt(this.state.book.stock) === 2 || parseInt(this.state.book.stock) === 3)) ? true : false}
                                            />
                                            
                                            <BlockPreviewCoverBack 
                                                sku = {this.props.sku}
                                                soldout = {(this.state.book && (parseInt(this.state.book.stock) === 2 || parseInt(this.state.book.stock) === 3)) ? true : false}
                                            />
                                        </div>
                                    }
                                </div> 
                            </div>
                        </div>
                        <div className="block-content__column">
                            {(current_book && current_book.title !== null) && (
                                this.props.type !== "summary"
                                ?   <div 
                                        className="block-content--book__title"
                                        >
                                            <BookTitle 
                                                book = {current_book}
                                            />
                                    </div>
                                :   <div 
                                        className={"block-content--book__title" + (this.props.type === "summary" ? " --link" : '')}
                                        onClick={(e) => this.handleOnClick(e, this.props.sku)}>
                                            <BookTitle 
                                                book = {current_book}
                                            />
                                    </div>
                                )
                            }

                            {/* SbC:: In case book replaced */}
                            {(current_book && ('alternative' in current_book) && current_book.alternative !== null) &&
                                <div className="block-content--book__subtitle --link --alert" onClick={(e) => this.handleOnClickReplacement(e, current_book.alternative)}>
                                    {i18n.t("content.book_replaced")} {current_book.alternative}
                                </div>
                            }

                            {current_book && current_book.subtitle !== null &&
                                <div className="block-content--book__subtitle" dangerouslySetInnerHTML={{ __html: current_book.subtitle}}>
                                </div>
                            }
                            {current_book && 
                                <React.Fragment>
                                    <div className="block-content--book__info--short">
                                           <div className="block-content--book__author">
                                                {current_book.author_lastname !== '' && current_book.author_lastname !== null &&
                                                    <BookAuthor
                                                        author = {this.props.author}
                                                        author_firstname = {current_book.author_firstname}
                                                        author_prefix = {'author_prefix' in current_book ? current_book.author_prefix : ''}
                                                        author_lastname = {current_book.author_lastname}
                                                        is_author_page = {this.props.page === 'author' ? true : false}
                                                        pushHistory = {this.pushHistory}
                                                        count = {this.state.count}
                                                    />
                                                }
                                                {('author2_lastname' in current_book) && current_book.author2_lastname !== '' && current_book.author2_lastname !== null &&
                                                    <React.Fragment>
                                                        <BookAuthor
                                                            author = {this.props.author}
                                                            current_book = {current_book}
                                                            author_firstname = {current_book.author2_firstname}
                                                            author_prefix = {'author2_prefix' in current_book ? current_book.author2_prefix : ''}
                                                            author_lastname = {current_book.author2_lastname}
                                                            is_author_page = {this.props.page === 'author' ? true : false}
                                                            pushHistory = {this.pushHistory}
                                                            str_conn = {(current_book.author_lastname !== '' && current_book.author_lastname !== null) ? "en" : ""}
                                                            count = {this.state.count2}
                                                        />
                                                    </React.Fragment>
                                                }
                                                {((this.state.count > 1 || this.state.count2 > 1) && this.props.page !== 'author') &&
                                                    <span className="--link-all">
                                                        (bekijk alles van&nbsp;
                                                        {this.state.count > 1 &&
                                                            <BookAuthor
                                                                author = {this.props.author}
                                                                author_firstname = {current_book.author_firstname}
                                                                author_prefix = {'author_prefix' in current_book ? current_book.author_prefix : ''}
                                                                author_lastname = {current_book.author_lastname}
                                                                is_author_page = {this.props.page === 'author' ? true : false}
                                                                pushHistory = {this.pushHistory}
                                                                count = {this.state.count}
                                                            />
                                                        }
                                                        {current_book.author2_lastname !== '' && current_book.author2_lastname !== null &&
                                                            <React.Fragment>
                                                                <BookAuthor
                                                                    author = {this.props.author}
                                                                    author_firstname = {current_book.author2_firstname}
                                                                    author_prefix = {'author2_prefix' in current_book ? current_book.author2_prefix : ''}
                                                                    author_lastname = {current_book.author2_lastname}
                                                                    is_author_page = {this.props.page === 'author' ? true : false}
                                                                    pushHistory = {this.pushHistory}
                                                                    str_conn = {this.state.count > 1 ? "of" : ""}
                                                                    count = {this.state.count2}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                        ) 
                                                    </span>
                                                }
                                            </div>
                                        
                                        <div className="block-content--book__price">
                                            {!this.props.hasAlternative
                                            && (current_book && !('alternative' in current_book) || current_book.alternative === null) 
                                            && current_book.stock !== 2 
                                            && (current_book.price_incl !== undefined && current_book.price_incl !== null && current_book.price_incl !== 0 && current_book.price_incl !== 99.99) &&
                                                <BookPrice
                                                    price = {current_book.price_incl}
                                                />
                                            }
                                        </div>
                                    </div>

                                    {this.props.type !== "productpage" && this.storeUi.page_view.sorting === 'release_date' &&
                                        <div className="block-content--compact__subtitle">
                                            <ul className="block--specs-summary__list"><li className="block--specs-summary__listitem ">Verschijningsdatum: {current_book && current_book.release_date && moment(current_book.release_date.date).format('DD MMMM YYYY')}</li></ul>
                                        </div>
                                    }

                                    {(this.props.type === "productpage" || 
                                    (this.storeAuth.user.is_loggedin && this.storeAuth.user.is_admin)) &&
                                        <BlockBookSpecsSummary 
                                            book = {current_book} 
                                            viewTab = {this.props.viewTab}
                                            nrReviews = {this.state.nrReviews}
                                        />
                                    }

                                    {this.props.type !== "productpage" &&
                                        <div className="block-content--book__description--short">
                                            {(current_book && !current_book.description && current_book.short_description) &&
                                                <p dangerouslySetInnerHTML={{ __html: this.addEllipsis(current_book.short_description) }}></p>
                                            }
                                            {(current_book && current_book.description) &&
                                                <p dangerouslySetInnerHTML={{ __html: this.addEllipsis(current_book.description) }}></p>
                                            }
                                            <span className="book__read-more --link" onClick={(e) => this.handleOnClick(e, this.props.sku)}>Lees verder</span>
                                        </div>
                                    }
                                </React.Fragment>
                            }

                            {current_book && !this.props.hasAlternative &&
                                <React.Fragment>
                                    <BlockBookStock 
                                        sku = {current_book.sku}
                                        book = {current_book}
                                        stock_code = {current_book.stock_code}
                                    >
                                        {((current_book.lang && current_book.lang !== 'N') && current_book.stock === 1 && current_book.stock_code !== 21 ) &&
                                        (current_book.price_incl !== undefined && current_book.price_incl !== null && current_book.price_incl !== 0) && 
                                            <DialogTooltip
                                                sku = {'tt-delivery-foreign'}
                                                delivery = {current_book.delivery}
                                            />
                                        }
                                    </BlockBookStock>
                                    <div className="block-content block-content--actions">    
                                        <BlockBookActions
                                            sku = {this.props.sku}
                                            book = {current_book}
                                            view = {["wishlist", "cart"]}
                                            display = {"full"}
                                        />
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    
                   </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BlockBook);

