import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';

import BlockVideo from '../../components/block/block-video';

import BlockSocialSharing from '../../components/block/block-social-sharing';

const Videos = inject("stores") (
    observer (
        class Videos extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    social_tooltip: i18n.t("share.page_videos")
                }
            }

            componentDidMount() {
                window.scrollTo(0, 0);
                this.storeUi.getVideos();

                this.storeUi.setActiveMainNav(0);
                this.storeUi.setActiveVideo(-1);
            }

            onMouseEnterSocial = (social) => {
                this.setState({
                    social_tooltip: (social !== '' && social !== undefined) ? social : i18n.t("share.page_videos")
                })
            }

            render() {
                return (
                    <Template
                        type = 'default'
                        header = "videos"
                        nav_name = {"videos"}
                    >
                        <div className="content-segment">
                            <ContentText
                                page = {"videos"}
                            />
                        </div>
                        
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p>Bekijk ook ons YouTube kanaal:<br/>
                                    <a href="https://www.youtube.com/user/Succesboeken/videos" className="link--read-more" target="_blank">Succesboeken.nl op YouTube</a>
                                </p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p><span className="book-tools__tool-title">{this.state.social_tooltip}</span></p>
                                <BlockSocialSharing 
                                    type = 'video'
                                    sku = {"0"} 
                                    page = {"videos"}
                                    onMouseEnter = {this.onMouseEnterSocial}
                                />
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="blocks__list--video">
                                {this.storeUi.videos && this.storeUi.videos.map((video, idx) => (                              
                                    <BlockVideo
                                        video = {video}
                                        isDialogVideoOpen = {this.storeUi.isDialogVideoOpen}
                                        idx = {idx}
                                        active_video = {this.storeUi.active_video}
                                    />
                                ))}
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(Videos);
