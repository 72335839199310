import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';

import SocialLink from '../../elements/social-link';

const FooterSocial = inject("stores") (
    observer (
        class FooterSocial extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
            }

            render() {
                return (
                    <div className="footer__social">
                        <ul className="social-links">
                            {this.storeGeneral.social_links.map((social, i) => ( 
                                <li key={i}>
                                    <a href={social.url} target="_blank">
                                        <SocialLink 
                                            medium = {social.brand} 
                                        />
                                    </a>
                                </li>
                            ))}

                        </ul>
                    </div>
                )
            }
        }
    )
)

export default FooterSocial;