import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const BookLang = inject("stores") (
    observer (
        class BookLang extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
            }

            render() {
                const idx = this.storeUi.list_languages
                    .findIndex((item) => this.props.book.lang === item.query_value);
                const lang = (idx !== -1) ?  this.storeUi.list_languages[idx].display_value : 'Anderstalig';

                return (    
                    <span className="--lang">{lang}</span>
                );
            }
        }
    )
)

export default BookLang;