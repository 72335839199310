import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Template from '../templates/template';

import BlocksBooks from '../components/blocks/blocks-books';

const BooksNew = inject("stores") (
    observer (
        class BooksNew extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    view: ''
                }
            }

            async componentDidMount() {
                window.scrollTo(0, 0);

                this.storeUi.setActiveSubCategory('');
                this.storeUi.setActiveCategory('');
                
                const view =  this.props.match.params.view;

                if (this.storeUi.checkFeature('SHOP_ALL') && (view === 'week' || view === 'month')) {
                    this.storeGeneral.setActiveShop('SHOP_ALL');
                    this.resetView(view);
                } else {
                    this.storeGeneral.setActiveShop('SHOP_SB');
                    this.resetView('sb');
                }
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.match.params.view !== this.props.match.params.view) {
                    const view =  this.props.match.params.view;
                    this.resetView(view);
                }

                this.storeUi.setFreezeScrolling(false);
            }

            componentWillUnmount = () => {
                const position = window.pageYOffset;
                this.storeUi.setFreezePos(position);
            }
            
            resetView = async(view) => {
                let books;
                let count;

                switch (view) {
                    case 'month':
                        this.storeGeneral.setShop('SHOP_ALL');
                        this.storeUi.setActiveToggleNav(3);
                        this.storeUi.setActiveMainNav(3);
                        break;
                    case 'week':
                        this.storeGeneral.setShop('SHOP_ALL');
                        this.storeUi.setActiveToggleNav(3);
                        this.storeUi.setActiveMainNav(4);
                        break;
                    default:
                        this.storeGeneral.setShop('SHOP_SB');
                        this.storeUi.setActiveToggleNav(2);
                        this.storeUi.setActiveMainNav(1);
                }

                count = await this.storeUi.getBookCountForBooksNew(view, this.storeUi.page_view);
                this.storeUi.setPageView('total', count)
                
                books = await this.storeBooks.getBooksNew(view, this.storeUi.page_view);

                this.setState({
                    view: view,
                    count: count
                })
            }

            render() {
                return (
                    <Template
                        type = 'default'
                        header = {"books_new_" + this.state.view}
                        expanded = {["categories", "products"]}
                        nav_name = {"books_new"}
                        useScroll = {true}
                    >   
                        <div className="content-segment-clean">
                            <p>{i18n.t("page_subheaders.search_results_count")}: {this.state.count ? this.state.count : 0}</p>
                        </div>

                        {((this.state.view !== 'week' && this.state.view !== 'month') || (!this.storeUi.checkFeature('SHOP_ALL'))) && 
                            <React.Fragment>
                                <div className="content-segment">
                                    <p>{i18n.t("page_intros.books_new")}</p>
                                </div>
                                <div className="content-segment">
                                    <BlocksBooks 
                                        type = "new"
                                        page = "new"
                                    />
                                </div>
                            </React.Fragment>
                        }

                        {this.storeUi.checkFeature('SHOP_ALL') && (this.state.view === 'week' || this.state.view === 'month') &&
                            <div className="content-segment">
                                <BlocksBooks 
                                    type = "new"
                                    page = "new"
                                />
                            </div>
                        }
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(BooksNew);
