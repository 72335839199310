import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as utilities from '../../utilities/formats';

const BlockLoggedIn = inject("stores") (
    observer (
        class BlockLoggedIn extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {

                }
                this.urlMAINTENANCE = process.env.REACT_APP_URL_MAINTENANCE;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
                let firstname = '';

                if (this.storeUser.user_data.is_loggedin) {
                    //(async() => await this.storeOrder.getAccountOrders(this.storeUser.user_data.user_id) )();
                    this.storeOrder.getAccountOrders(this.storeUser.user_data.user_id);
                }
              
                if (this.storeUser.user_data.is_loggedin && this.storeUser.user_data && this.storeUser.user_data.profile) {
                    firstname = utilities.capitalizeFirstChar(this.storeUser.user_data.profile.firstname);

                    this.setState({
                        firstname: firstname,
                        lastname: this.storeUser.user_data.profile.lastname,
                    })
                }
            }

            componentDidUpdate = (prevProps) => {

            }

            handleClick = () => {
                this.storeUi.setActiveTab('orders');
                let url = "/account/";
                this.props.history.push(url);
            }

            handleAdmin = () => {
                const url = this.urlMAINTENANCE;
                window.location.assign(url);
                
            }

            render() {
                let hasOpenOrder = false;
                const orderList = this.storeUser.user_data.is_loggedin ? this.storeOrder.orderHistoryList : []

                orderList.length > 0 && (
                    orderList.map((order, i) => (
                        ((order.order_status === 'void' && order.order_paid === null) || (order.order_status === 'banktransfer' && order.order_paid === null)) ? hasOpenOrder = true : hasOpenOrder = hasOpenOrder
                    ))
                )

                return (
                    <div className="block block--sidebar block--loggedin">
                        <div className="block__block-title">Welkom<br/> 
                            {this.storeUser.user_data.is_loggedin
                            ?   this.state.firstname
                            :   <span>bij Succesboeken.nl</span>
                            }
                        </div>
                        {hasOpenOrder &&
                            <span className="block--loggedin block--loggedin__link link--read-more" onClick={() => this.handleClick()}>Je hebt nog een openstaande bestelling</span>
                        }
                        {this.storeAuth.user.is_loggedin && this.storeAuth.user.is_admin &&
                            <span className="block--loggedin block--loggedin__link link--read-more" onClick={() => this.handleAdmin()}>Beheer</span>
                        }
                    </div>
                )
            }
        }
    )
)

export default withRouter(BlockLoggedIn);

