import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import * as ui from '../../utilities/ui';

import BlockViews from './block-views';
import ButtonToggleExpand from '../../elements/button-toggle-expand';

const BlockTop10Foldout = inject("stores") (
    observer (
        class BlockTop10Foldout extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    view: "covers",
                    foldout: this.storeUi.foldout.top10
                }

                this.blockRef = React.createRef();
            }

            async componentDidMount() {
                const tag = this.props.tag;
                const books = await this.storeBooks.getBooksTop10(tag, this.props.nur, this.props.isAutofill);
                this.setState({
                    tag: tag,
                    section: this.props.section,
                    header: "blocks." + tag + ".header",
                    booksList: books
                })
            }

            async componentDidUpdate(prevProps) {
                if (prevProps.tag !== this.props.tag) {
                    const books = await this.storeBooks.getBooksTop10(this.props.tag, this.props.nur, this.props.isAutofill);
                    this.setState({
                        tag: this.props.tag,
                        section: this.props.section,
                        header: "blocks." + this.props.tag + ".header",
                        booksList: books
                    })
                }
            }

            handleBlocksNav = (e, view) => {
                this.setState({
                    view: view
                })
                //this.storeUi.setPageView('view', view)
            }

            handleGoto = (url) => {          
                this.props.handleGoto(url);
            }

            expandBlocks = (e) => {
                if (this.state.foldout) {
                    ui.handleScroll(this.blockRef.offsetTop - 80);
                    this.storeUi.setFoldout('top10', false)
                } else {
                    this.storeUi.setFoldout('top10', true)
                }
                this.setState(prevState => ({
                    foldout: !prevState.foldout
                }));
            }

            render() {
                let blockview = "covers";
                let class_foldout = "";
 
                if (this.state.view == '') {
                    blockview = 'covers';
                    class_foldout = " blocks__list--covers-foldout " + (this.state.foldout ? "--expanded" : "--collapsed");
                } else {
                    blockview = this.state.view;
                    if (blockview == 'covers') {
                        class_foldout = " blocks__list--covers-foldout " + (this.state.foldout ? "--expanded" : "--collapsed");
                    }
                    if (blockview == 'summaries') {
                        class_foldout = " blocks__list--summaries-foldout " + (this.state.foldout ? "--expanded" : "--collapsed");
                    }
                }

                const Li = (view) => {
                    const activeClass = (view.view === blockview) ? ' --active' : '';
                    return (
                        <li className={"blocks-nav__listitem blocks-nav__listitem--" + view.view + activeClass} onClick={(e) => this.handleBlocksNav(e, view.view)}>
                            <span>{view.view}</span>
                        </li>
                    )
                }

                return (
                    <div className="blocks__content" ref={ (ref) => this.blockRef=ref }>
                        <div className="blocks__nav">
                            <div className="blocks__nav-actions">
                                <div className="blocks-nav__list --column"></div>
                                <div className="blocks-nav__list">
                                    <ul className="blocks-nav__list--view">
                                        <Li 
                                            view = 'summaries'
                                        />
                                        <Li 
                                            view = 'covers'
                                        />
                                        <Li 
                                            view = 'list'
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <ul className={"blocks__list blocks__list--" + blockview + class_foldout}>
                            {this.state.booksList && this.state.booksList.map((book, i) => ( 
                                book !== undefined &&
                                <li className="block block--book" key = {i}>
                                    <BlockViews 
                                        view = {blockview}
                                        book = {book}
                                        page = {this.props.page}
                                        ranking = {true}
                                    />
                                </li>
                            ))}
                        </ul>
                            
                        {(blockview == 'covers' || blockview == 'summaries') &&
                            <React.Fragment>
                                <div className="blocks__list--actions">
                                    <ButtonToggleExpand
                                        foldout = {this.state.foldout}
                                        expandBlocks = {this.expandBlocks}
                                        txt_down = {"lists.show_more_top"}
                                        txt_up = {"lists.show_less_top"}
                                        amount = {10}
                                    />
                                </div>

                                <div className="blocks__list--actions blocks__list--action-icons">
                                    <div className="blocks__list--action" onClick={() => this.props.viewAll(this.props.section)}>
                                        <span className="link--read-more">Toon alle {i18n.t("blocks." + this.props.tag + ".all")}</span>
                                        <div class="paging-forward-wrapper --next"><div class="paging-forward-wrapper__icon"></div></div>
                                    </div>
                                    <div className="blocks__list--action">
                                        <Link 
                                            className="link"
                                            to="/category/top10"
                                        >
                                            <span>Toon alle Top 10's</span>
                                        </Link>
                                        <div 
                                            class="paging-forward-wrapper --next"
                                            onClick = {() => this.handleGoto("/category/top10")}
                                        >
                                            <div class="paging-forward-wrapper__icon"></div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                )
            }
        }
    )
)

const BlockTop10Expanded = inject("stores") (
    observer (
        class BlockTop10Expanded extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    view: "covers"
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            async componentDidMount() {
                const tag = this.props.tag;
                const books = await this.storeBooks.getBooksTop10(tag, this.props.nur, this.props.isAutofill);
                this.setState({
                    tag: tag,
                    section: this.props.section,
                    header: "blocks." + tag + ".header",
                    booksList: books
                })
            }

            async componentDidUpdate(prevProps) {
                if (prevProps.tag !== this.props.tag) {
                    const books = await this.storeBooks.getBooksTop10(this.props.tag, this.props.nur, this.props.isAutofill);
                    this.setState({
                        tag: this.props.tag,
                        section: this.props.section,
                        header: "blocks." + this.props.tag + ".header",
                        booksList: books
                    })
                }
            }

            handleBlocksNav = (e, view) => {
                this.setState({
                    view: view
                })
                this.storeUi.setPageView('view', view)
            }

            handleGoto = (url) => {          
                this.props.handleGoto(url);
            }

            render() {
                let blockview = "covers";
 
                if (this.state.view == '' ) {
                    if ( this.state.booksList.length < 5) {
                        blockview = 'summaries'
                    } else {
                        blockview = 'covers'
                    }
                } else {
                    blockview = this.state.view
                }

                const Li = (view) => {
                    const activeClass = (view.view === blockview) ? ' --active' : '';
                    return (
                        <li className={"blocks-nav__listitem blocks-nav__listitem--" + view.view + activeClass} onClick={(e) => this.handleBlocksNav(e, view.view)}>
                            <span>{view.view}</span>
                        </li>
                    )
                }

                return (
                    <div className="blocks__content">
                    <div className="blocks__nav">
                        <div className="blocks__nav-actions">
                            <div className="blocks-nav__list --column"></div>
                            <div className="blocks-nav__list">
                                <ul className="blocks-nav__list--view">
                                    <Li 
                                        view = 'summaries'
                                    />
                                    <Li 
                                        view = 'covers'
                                    />
                                    <Li 
                                        view = 'list'
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>

                    <ul className={"blocks__list blocks__list--" + blockview}>
                        {this.state.booksList && this.state.booksList.map((book, i) => ( 
                            book !== undefined &&
                            <li className="block block--book" key = {i}>
                                <BlockViews 
                                    view = {blockview}
                                    book = {book}
                                    page = {this.props.page}
                                    ranking = {true}
                                />
                            </li>
                        ))}
                    </ul>
                        
                    <div className="blocks__list--actions blocks__list--action-icons">
                        <div className="blocks__list--action" onClick={() => this.props.viewAll(this.props.section)}>
                            <span className="link--read-more">Toon alle {i18n.t("blocks." + this.props.tag + ".all")}</span>
                            <div class="paging-forward-wrapper --next"><div class="paging-forward-wrapper__icon"></div></div>
                        </div>
                        <div className="blocks__list--action">
                            <Link 
                                className="link"
                                to="/category/top10"
                            >
                                <span>Toon alle Top 10's</span>
                            </Link>
                            <div 
                                class="paging-forward-wrapper --next"
                                onClick = {() => this.handleGoto("/category/top10")}
                            >
                                <div class="paging-forward-wrapper__icon"></div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            }
        }
    )
)

const BlockTop10 = inject("stores") (
    observer (
        class BlockTop10 extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    view: this.props.view
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            async componentDidMount() {
                const tag = this.props.section.tag;
                const books = await this.storeBooks.getBooksTop10(tag, this.props.nur, this.props.isAutofill);

                this.setState({
                    tag: tag,
                    section: this.props.section,
                    header: "blocks." + tag + ".header",
                    booksList: books
                })
            }

            render() {
                return (
                    <div className="blocks--top10" ref={this.cardRef} >
                        <div className="blocks__blocks-title">
                            <div className="blocks__blocks-title--main">
                                <span className="blocks__blocks-title--main-section">Top 10</span>
                                <span 
                                    className="blocks__blocks-title--main-cat"
                                >
                                    {i18n.t("blocks." + this.props.section.tag + ".subtitle")}
                                </span>
                                {this.props.section.has_excel &&
                                    <span className="blocks__blocks-title--main-sub">(met positie in lijst van top 60 bestsellers van deze week)</span>
                                }
                            </div> 
                        </div> 
                        <ul className="blocks__list blocks__list--covers blocks__list--covers-foldout --collapsed">
                            {this.state.booksList && this.state.booksList.map((book, i) => ( 
                                book !== undefined &&
                                <li className="block block--book" key = {i}>
                                    <BlockViews 
                                        view = {"covers"}
                                        book = {book}
                                        page = {this.props.page}
                                        ranking = {true}
                                    />
                                </li>
                            ))}
                        </ul>

                        <div 
                            className="blocks__list--actions blocks__list--action-icons" 
                        >
                            <div 
                                className="blocks__list--action"
                                onClick={() => this.props.viewAll(this.props.section.section, this.props.nur, this.props.isAutofill)}
                            >
                                <span className="link--read-more">Toon gehele {this.props.section.display_value}</span>
                                <div class="paging-forward-wrapper --next"><div class="paging-forward-wrapper__icon"></div></div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

const BlocksTop10 = inject("stores") (
    observer (
        class BlocksTop10 extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    section: "",
                    view: "covers"
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = async() => {
                let nur;
                let sections = await this.storeBooks.getTop10s();
                let hasExcel;
                let isAutofill;
                
                const section = this.props.tag.substring(5);

                if (this.props.tag !== 'top10') {
                    const result = await this.storeBooks.getTop10NUR(this.props.tag);
                    if (result !== undefined && result !== null && result.data !== null) {
                        nur = result.data[0].nur;
                    } else {
                        nur = 0;
                    }

                    const idx = sections.findIndex(sect => sect.section === section);
                    hasExcel = sections[idx].has_excel;
                    isAutofill= sections[idx].isAutofill;
                } else {
                    nur = 0;
                }

                this.setState({
                    view_section: this.props.tag == 'top10' ? false : true,
                    sections: sections,
                    tag: this.props.tag,
                    nur: nur,
                    section: section,
                    hasExcel: hasExcel,
                    isAutofill: isAutofill,
                    header: "blocks." + this.props.tag + ".header"
                })
            }

           componentDidUpdate = async(prevProps) => {
                let nur = 0;
                let hasExcel;
                let isAutofill;

                if (prevProps.tag !== this.props.tag) {
                    const section = this.props.tag.substring(5);

                    if (this.props.tag !== 'top10') {
                        const result = await this.storeBooks.getTop10NUR(this.props.tag);
                        if (result !== undefined && result !== null) {
                            nur = result.data[0].nur;
                        } 

                        const idx = this.state.sections.findIndex(sect => sect.section === section);
                        hasExcel = this.state.sections[idx].has_excel;
                        isAutofill= this.state.sections[idx].isAutofill;
                    } 

                    this.setState({
                        view_section: this.props.tag == 'top10' ? false : true,
                        tag: this.props.tag,
                        nur: nur,
                        section: section,
                        hasExcel: hasExcel,
                        isAutofill: isAutofill,
                        header: "blocks." + this.props.tag + ".header"
                    })
                } 
            }

            viewAll = async(section, nur, isAutofill) => {
                const tag = "top10" + section;
                this.setState({
                    view_section: true,
                    tag: tag,
                    nur: nur,
                    isAutofill: isAutofill,
                    section: section,
                    header: "blocks." + tag + ".header"
                })

                const url = "/subcategory/" + tag;
                this.props.history.push(url);
            }

            viewAllCategory = (cat_name) => {
                this.storeBooks.getBooksForCategory(cat_name, this.storeUi.page_view);
                this.storeUi.setActiveCategory(cat_name);

                const url = "/category/" + cat_name;
                this.props.history.push(url);
            }

            handleGoto = (url) => {          
                this.props.history.push(url);
            }
  
            render() {
                return (
                    <div className="blocks blocks--books">
                        {(!this.state.view_section && this.props.type !== 'foldout') &&
                            <React.Fragment>
                                <div className="blocks__content">
                                    {this.state.sections && this.state.sections.map((section) => (
                                        <BlockTop10
                                            section = {section}
                                            nur = {section.nur}
                                            isAutofill = {section.isAutofill}
                                            viewAll = {this.viewAll}
                                        />
                                    ))}
                                </div>
                            </React.Fragment>
                        }

                        {(this.state.view_section && this.props.type !== 'foldout') &&
                            <React.Fragment>
                                <div className="blocks__blocks-title">
                                    <div className="blocks__blocks-title--main">
                                        {/* {i18n.t(this.state.header)} */}
                                        <span className="blocks__blocks-title--main-section">Top 10</span>
                                        <span 
                                            className="blocks__blocks-title--main-cat"
                                        >
                                            {i18n.t("blocks." + this.props.tag + ".subtitle")}
                                        </span>
                                        {(this.state.hasExcel && this.state.isAutofill !== 1) &&
                                            <span className="blocks__blocks-title--main-sub">(met positie in lijst van top 60 bestsellers van deze week)</span>    
                                        }
                                    </div> 
                                </div> 

                                {this.state.nur === 0
                                ?   <div>Er is hiervan geen top 10 gevonden</div>
                                :    <BlockTop10Expanded
                                        tag = {this.state.tag}
                                        nur = {this.state.nur}
                                        isAutofill = {this.state.isAutofill}
                                        section = {this.state.section}
                                        viewAll = {this.viewAllCategory}
                                        handleGoto = {this.handleGoto}
                                    />
                                }
                            </React.Fragment>
                        }

                        {this.props.type === 'foldout' &&
                            <React.Fragment>
                                <div className="blocks__blocks-title">
                                    <div className="blocks__blocks-title--main">
                                        {/* {i18n.t(this.state.header)} */}
                                        <span className="blocks__blocks-title--main-section">Top 10</span>
                                        <span className="blocks__blocks-title--main-cat">Thrillers</span>
                                        {(this.state.hasExcel && this.state.isAutofill !== 1) &&
                                            <span className="blocks__blocks-title--main-sub">(met positie in lijst van top 60 bestsellers van deze week)</span>
                                        }
                                    </div> 
                                </div>

                                <BlockTop10Foldout
                                    tag = {this.state.tag}
                                    nur = {this.state.nur}
                                    isAutofill = {this.state.isAutofill}
                                    section = {this.state.section}
                                    viewAll = {this.viewAllCategory}
                                    handleGoto = {this.handleGoto}
                                />
                            </React.Fragment>
                        }
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlocksTop10));

