import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as account from '../communicator/account';

function autoSave(store, save) {
    mobx.autorun(() => {
        const json = JSON.stringify(mobx.toJS(store));
        save(json);
    });
}

class StoreWishlist {
    wishlist = {
        'anonymous': [],
        'user': []
    }
    wishList_db = []
    isDialogConcatOpen = false;
    isDialogCompareWishlistsOpen = false
    lastAddedBook = {}

    constructor(storeRoot) {
        this.retrieveWishlist(0);

        autoSave(this.wishlist, this.save.bind(this));
    }

    save(json) {
        this.saveToLS(json);
    }

    saveToLS(json) {
        localStorage.setItem('SB_WISHLIST20', json)
    }

    openDialogConcat = (url) => {
        this.isDialogConcatOpen = true;
    }

    openDialogCompareWishlists = () => {
        this.isDialogCompareWishlistsOpen = true;
    }

    closeDialogConcat = () => {
        this.isDialogConcatOpen = false;
    }

    closeDialogCompareWishlists = () => {
        this.isDialogCompareWishlistsOpen = false;
    }

    async retrieveWishlist(id) {
        if (id !== undefined && id !== 0) {
            this.retrieveWishlistFromDB(id);
        } else {
            this.retrieveWishlistFromLS(id);
        }
    }

    async retrieveWishlistFromDB(id) {
        try {
            const returnData = await account.retrieveWishList(id);
            runInAction(() => {
                this.wishlist.user = returnData.data;

                if (this.wishlist.user.length >= 0 && this.wishlist.anonymous.length > 0) {
                    this.openDialogCompareWishlists();
                }

                if (this.wishist.user == null) {
                    this.wishlist.user = [];
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    retrieveWishlistFromLS(id) {
        if (localStorage.getItem('SB_WISHLIST20')) {
            this.wishlist = JSON.parse(localStorage.getItem('SB_WISHLIST20'));
        } else {
            this.wishlist = {
                'anonymous': [],
                'user': []
            };
        }
        if (this.wishlist == null) {
            this.wishlist = {
                'anonymous': [],
                'user': []
            };
        }
    }

    addToWishlist(sku, title, id) {
        let items = [];

        if (id !== undefined && id !== 0) {
            items = this.wishlist.user;
        } else {
            items = this.wishlist.anonymous;
        }

        if (!this.checkOnWishlist(id, sku)) {
            const wish_item = {
                sku: sku,
                title: title
            }
            items.push(wish_item);

            if (id !== undefined && id !== 0) {
                this.wishlist.user = items;
            } else {
                this.wishlist.anonymous = items;
            }

            if (id !== undefined && id !== 0) {
                this.saveWishlistToDB(id, this.wishlist);
            } else {
                this.saveWishlistToLS(this.wishlist);
            }
        }
        
        this.lastAddedBook.sku = sku;
    }

    removeFromWishlist(sku, id) {
        let items;

        if (id !== undefined && id !== 0) {
            items = this.wishlist.user;
        } else {
            items = this.wishlist.anonymous;
        }

        const idx = items.findIndex(item => item.sku === sku);
        items.splice(idx, 1);

        if (id !== undefined && id !== 0) {
            this.wishlist.user = items;
        } else {
            this.wishlist.anonymous = items;
        }

        this.saveWishlistToLS(this.wishlist);
        if (id !== undefined && id !== 0) {
            this.saveWishlistToDB(id, this.wishlist.user);
        }
    }

    checkOnWishlist = (id, sku) => {
        let items;
        let idx = -1;

        if (id !== undefined && id !== 0) {
            items = this.wishlist.user;
        } else {
            items = this.wishlist.anonymous;
        }

        if (items.length > 0) {
            idx = items
                .findIndex((item) => item.sku === sku);
        } else {
            idx = -1;
        }

        return (idx > -1) ? true : false;
    }

    async saveWishlistToDB(id, wishlist) {
        try {
            const returnData = await account.saveWishList(id, wishlist.user);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    saveWishlistToLS(wishlist) {
        const json = JSON.stringify(this.wishlist);
        localStorage.setItem('SB_WISHLIST20', json)
    }

    deleteWishlistFromLS() {
        if (localStorage.getItem('SB_WISHLIST20')) {
            this.cart.user = [];
        }
    }

    async compareWishLists(id, action) {
        const returnData = await account.retrieveWishList(id);
        const wishListDB = returnData.data;
        
        if (action === 'concat') {
            let wishListLS = this.wishlist.anonymous;

            wishListLS.map(item => {
                if (!this.checkOnWishlist(id, item.sku)) {
                    const wish_item = {
                        sku: item.sku
                    }
                    this.wishlist.user.push(wish_item);
                }
            })
            wishListLS = [];
            this.wishlist.anonymous = [];
            
            this.saveWishlistToLS(this.wishlist);
            this.saveWishlistToDB(id, this.wishlist);
        }
        if (action === 'keep') {
        }

        this.closeDialogCompareWishlists();
    }

    countWishlist(id) {
        let items = [];

        if (id !== undefined && id !== 0) {
            items = this.wishlist.user;
        } else {
            items = this.wishlist.anonymous;
        }

        return items.length;
    }
}

decorate(StoreWishlist, {
    lastAddedBook: observable,
    wishlist: observable,
    isDialogConcatOpen: observable,
    isDialogCompareWishlistsOpen: observable,
    addToWishlist: action,
    removeFromWishlist: action,
    retrieveWishlist: action,
    deleteWishlistFromLS: action,
    closeDialogCompareWishlists: action,
    countWishlist: action,
    openDialogCompareWishlists: action,
})

export default StoreWishlist;