import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import ImgCover from '../../elements/img-cover';
import BookLang from '../../elements/book-lang';
import BlockBookStock from '../../components/block/block-book-stock';

import * as utilities from '../../utilities/formats';

const SearchOverlayItemShort = inject("stores") (
    observer (
        class SearchOverlayItemShort extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                }
            }

            componentDidMount = async() => {
                  let shop;

                if (this.props.intentions) {
                    shop = await this.storeUi.getShopForSku(this.props.item.sku);

                    this.setState({
                        shop: shop
                    })
                }
            }

            componentDidUpdate = async(prevProps) => {

            }

            handleOnClick = (e, result) => {
                e.preventDefault();
                this.storeUi.setCurrentBook(result.sku);

                if (this.props.shop) {
                    this.storeGeneral.setActiveShop(this.props.shop);
                } else {
                    this.storeGeneral.setActiveShop(this.state.shop);
                }

                if (this.props.shop) {
                    if (this.props.shop === 'SB') {
                        this.storeUi.setActiveToggleNav(2);
                        this.storeGeneral.setActiveShop('SHOP_SB');
                    }
                    if (this.props.shop === 'ALL') {
                        this.storeUi.setActiveToggleNav(3);
                        this.storeGeneral.setActiveShop('SHOP_ALL');
                    }
                } else {
                    if (this.state.shop === 'SHOP_SB') {
                        this.storeUi.setActiveToggleNav(2);
                        this.storeGeneral.setActiveShop('SHOP_SB');
                    }
                    if (this.state.shop === 'SHOP_ALL') {
                        this.storeUi.setActiveToggleNav(3);
                        this.storeGeneral.setActiveShop('SHOP_ALL');
                    }
                }
                
                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    //url = "/book/" + result.sku + "/" + id;
                    url = "/book/" + result.sku;
                } else {
                    url = "/book/" + result.sku;
                }
                this.props.history.push({
                    sku: result.sku,
                    pathname: url
                })

                this.storeUi.setSearchOverlay(false);
            }

            constructAuthor(firstname, prefix, lastname) {
                let author = '';

                if (this.props.item.author_firstname) {
                    author = this.props.item.author_firstname;
                    if (this.props.item.author_prefix) {
                        author = author + ' ' + this.props.item.author_prefix;
                    }
                    if (this.props.item.author_lastname) {
                        author = author + ' ' + this.props.item.author_lastname;
                    }
                } else {
                    if (this.props.item.author_lastname) {
                        author = this.props.item.author_lastname;
                    }
                    if (this.props.item.author_prefix) {
                        author = this.props.item.author_prefix + ' ' + author;
                    }
                }

                return author;
            }

            render() {
                return (
                    <li 
                        className={(this.props.item["[elevated]"] ? "--elevated" : "") + " --link"}
                        onClick={(e) => this.handleOnClick(e, this.props.item)}
                        data-content={this.state.elevatedHeader}
                    >
                        <div 
                            className="search-item"
                        >
                            <div 
                                className="search-item__img"
                            >
                                <ImgCover
                                    sku = {this.props.item.sku}
                                />
                            </div>
                            <div 
                                className="search-item__data"
                            >
                                <div>
                                    {this.props.item.title &&
                                        <span
                                            className="search-item__data--title"
                                            dangerouslySetInnerHTML={{ __html: utilities.capitalizeTitle(this.props.item.title)}}
                                        />
                                    }
                                    <span
                                        dangerouslySetInnerHTML={{ __html: this.constructAuthor(this.props.item.author_firstname, this.props.item.author_prefix, this.props.item.author_lastname)}}
                                    />
                                </div>
                                <div 
                                    className="search-item__specs"
                                >
                                    <ul className="block--specs-summary__list">
                                        <li className="block--specs-summary__listitem">
                                            <BookLang
                                                book = {this.props.item}
                                            />
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            
                        </div>
                    </li>
                )
            }
        }
    )
)

export default withRouter(SearchOverlayItemShort);
