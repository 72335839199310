import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';
import FormContact from '../../components/forms/form-contact';

const Remarks = inject("stores") (
    observer (
        class Remarks extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount() {
                window.scrollTo(0, 0);
            }
      
            render() {
                return (
                    <Template
                        type = {'single-column'}
                        header = {"remarks"}
                    >
                        <div className="content-segment">
                            <ContentText
                                page = {"contact"}
                            />
                        </div> 

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <FormContact />
                            </div>
                        </div>                           
                    </Template>
                )
            }
        }
    )
)

export default Remarks;
