import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SitemapPages = inject("stores") (
    observer (
        class SitemapPages extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    foldout: false
                }

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
            }
  
            render() {
                return (
                    <ul className="sitemap__list sitemap__list--pages">
                        <li className="navigation--footer__column">
                            <span className="navigation--footer__title">Succesboeken.nl</span>
                            <ul className="navigation--footer__list">
                                <li className="navigation--footer__listitem"><Link to="/our-publications">Boeken eigen uitgeverij</Link></li>
                                {this.storeUi.checkFeature('SHOP_ALL') &&
                                    <li className="navigation--footer__listitem"><Link to="/all-books">Alle boeken</Link></li>
                                }
                                <li className="navigation--footer__listitem"><Link to="/new-books">Nieuw toegevoegd</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/ebooks">Gratis e-boeken</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/events">Ontmoet de auteurs</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/press">Media/Pers</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/videos">Video's</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/terms-conditions">Algemene Voorwaarden</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/disclaimer">Disclaimer</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/sitemap">Sitemap</Link></li>
                            </ul>
                        </li>
                        <li className="navigation--footer__column">
                            <span className="navigation--footer__title">De uitgeverij</span>
                            <ul className="navigation--footer__list">
                                <li className="navigation--footer__listitem"><Link to="/our-publications">Onze uitgeverij</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/mission">Missie & Passie</Link></li>
                                {this.storeAuth.user.is_loggedin && this.storeUser.user_data.partner_code !== ''
                                    ?   <li className="navigation--footer__listitem"><Link to="/partner">Partnercodes</Link></li>
                                    :   <li className="navigation--footer__listitem"><Link to="/partners">Partner worden</Link></li>
                                }
                                <li className="navigation--footer__listitem"><Link to="/business">Zakelijk - Boekhandel</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/own-book">Een eigen boek schrijven</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/jobs">Vacatures</Link></li>
                            </ul>
                        </li>
                        <li className="navigation--footer__column">
                            <span className="navigation--footer__title">Contact</span>
                            <ul className="navigation--footer__list">
                                <li className="navigation--footer__listitem"><Link to="/contact">Opmerkingen/vragen?</Link></li>
                                <li className="navigation--footer__listitem"><Link to="/address">Adres</Link></li>
                            </ul>
                        </li>
                    </ul>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(SitemapPages));


