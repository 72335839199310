import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockReview = inject("stores") (
    observer (
        class BlockReview extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    reviewer: {},
                    review: {}
                }
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            async componentDidMount() {
                if (this.props.view === 'review') {
                    this.getReviewer(this.props.review.reviewer);
                    this.setState({
                        review: this.props.review
                    })
                }

                if (this.props.view === 'preview') {
                    this.convertPreviewData();
                }
            }

            getReviewer = async (id) => {
                const reviewer = await this.storeBooks.getReviewer(id);
                if (reviewer.length > 0)  {
                    reviewer[0].website = this.convertUrl(reviewer[0].website);
                    this.setState({
                        reviewer: reviewer[0]
                    })
                }
            }

            convertPreviewData() {
                const data = this.props.src;

                this.setState({
                    reviewer: {
                        img: this.props.img,
                        name: data.firstname.value + ' ' + data.lastname.value,
                        firstname: data.firstname.value,
                        lastname: data.lastname.value,
                        //profession: data.profession.value !== null ? '' : '',
                        //website: data.website.value !== null ? this.convertUrl(data.website.value) : '',
                        description: data.profile.value,
                        show_firstname: data.show_firstname.value,
                        show_lastname: data.show_lastname.value,
                        show_profession: data.show_profession.value,
                        show_website: data.show_website.value,
                        show_profile: data.show_profile.value,
                    },
                    review: {
                        title: data.review_title.value,
                        review: data.review.value
                    }
                })
            }
            
            convertUrl(data) {
                let url = data;
                if (url !== null) {
                    const prefix = data.substring(0, 4);
                    if (prefix !== 'http') {
                        url = 'http://' + url;
                    } 
                    return url;
                }
            }
  
            render() {
                return (
                    <div className="segment__content block--review">
                        <div className="block--review__header">
                            <span className="block--review__author">Recensie door:</span>
                        </div>  

                        <div className="block--review__reviewer">
                            {/* {(this.state.reviewer.img !== '' && this.state.reviewer.img !== null && this.state.reviewer.img !== undefined) &&
                                <div className="block--review__reviewer-photo">
                                    <img src={this.props.view === 'review' ? (this.urlAPI + '/public/images/reviewers/' + this.state.reviewer.img) : this.state.reviewer.img} />
                                </div>
                            } */}
                            <div className="block--review__reviewer-specs">
                                <dl>
                                    <dt>Naam</dt>
                                    <dd>
                                        {this.state.reviewer.show_firstname === 1 &&
                                            <span>{this.state.reviewer.firstname} </span>
                                        }
                                        {this.state.reviewer.show_lastname === 1 &&
                                            this.state.reviewer.lastname
                                        }
                                        {(this.state.reviewer.show_firstname !== 1 && this.state.reviewer.show_lastname !== 1) &&
                                            <span>(Naam bekend bij redactie)</span>
                                        }
                                    </dd>
                                    {/* {this.state.reviewer.show_profession === 1 && this.state.reviewer.profession !== null && this.state.reviewer.profession !== '' &&
                                        <React.Fragment>
                                            <dt>Beroep</dt>
                                            <dd>{this.state.reviewer.profession}</dd>
                                        </React.Fragment>
                                    }

                                    {this.state.reviewer.show_website === 1 && this.state.reviewer.website !== 'http://' && this.state.reviewer.website !== 'https://' &&
                                        <React.Fragment>
                                            <dt>Website</dt>
                                            <dd><a href={this.state.reviewer.website} target="_blank" className="link--read-more --link">{this.state.reviewer.website}</a></dd>
                                        </React.Fragment>
                                    } */}
                                
                                    {this.state.reviewer.show_profile === 1 && this.state.reviewer.description !== null && this.state.reviewer.description !== '' &&
                                        <React.Fragment>
                                            <dt>Beschrijving</dt>
                                            <dd><span dangerouslySetInnerHTML={{ __html: this.state.reviewer.description}}></span></dd>
                                        </React.Fragment>
                                    }
                                </dl>
                            </div>
                        </div>
                        
                        <div className="block--review__content">
                            {this.state.review.source !== null  && this.state.review.source !== undefined && this.state.review.source !== '' &&
                                <div className="block--review__content-text">Bron: {this.state.review.source}</div>
                            }
                            <div className="block--review__content-title" dangerouslySetInnerHTML={{ __html: this.state.review.title}}></div>
                            <div className="block--review__content-text" dangerouslySetInnerHTML={{ __html: this.state.review.review}}></div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockReview);


