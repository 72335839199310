import * as apifetch from './fetch.js';

// export async function checkLogin2(email, pw) {
//     const urlAPI = process.env.REACT_APP_URL_API;
//     const urlEndpoint = urlAPI + '/accounts/checkLogin.php';

//     const postData = {
//         email: email,
//         pw: pw
//     }

//     try {
//         let apiStr = urlEndpoint;
//         let response = await fetch(apiStr, {
//             method: 'POST',
//             mode: 'cors',
//             body: JSON.stringify(postData),
//             headers: {
  
//             }
//         });
//         let responseJson = await response.json();
//         if (responseJson !== null) {
//             let arrData = [];
//             arrData = responseJson;

//             return arrData;
//         }
//     } catch (error) {
//         console.log('SbC fetch error login: ', error.message);
//     }
// }

export async function checkLogin(data) {
    const urlEndpoint = '/v2/accounts/login';

    const postData = data;
       
    return apifetch.authFetch(urlEndpoint, 'POST', postData);
   // return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}