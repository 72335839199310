import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const BookPrice = inject("stores") (
    observer (
        class BookPrice extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = () => {
                
            }

            render() {
                let price = 0;
                let priceDecimals;

                if (this.props.price === null || this.props.price === undefined) {
                    const book = this.storeBooks.booksList
                        .filter((book) => book.sku === this.props.sku);
                    price = book[0] && book[0].price_incl;
                } else {
                    price = this.props.price;
                }

                const priceInt = Math.floor(price)
                const decimalPart = Math.round((price - Math.floor(price)) * 100)

                if ( decimalPart < 10) {
                    priceDecimals = '0' + decimalPart
                } else {
                    priceDecimals = decimalPart
                }
                
                return (
                    <span>{priceInt}<span>,{priceDecimals}</span></span>
                );
            }
        }
    )
)

export default BookPrice;