import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import * as ui from '../../utilities/ui';

import BlockCategory from './block-category';

const CategoriesLi = inject("stores") (
    observer (
        class CategoriesLi extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeCatalogs = this.props.stores.storeCatalogs;

                this.state = {
                    subs_active: true
                }
            }

            handleOnClickCat = async(cat_name) => { 
                let id;

                ui.smoothScrollTo(0, 0, 500); 
                this.storeUi.setFreezeScrolling(false);
                this.storeUi.setFreezePos(0);

                this.storeUi.setActiveProductGroup('');
                this.storeUi.setActiveSubGroup('');

                //this.storeGeneral.setActiveShop(this.storeGeneral.shop);
                if (this.storeGeneral.shop === 'SHOP_SB') {
                    id = 2;
                 } else {
                    id = 3;
                 }
                this.storeUi.setActiveToggleNav(id);

                this.storeCatalogs.setActiveCategory(cat_name);

                this.storeUi.setActiveSubCategory(
                    (cat_name !== this.storeUi.active_category || (cat_name === this.storeUi.active_category && this.storeUi.active_subcategory === '')) ? cat_name : ''
                );
                               
                if (cat_name !== this.storeUi.active_category ) {
                    this.storeCatalogs.setActiveCategory(cat_name);
                    this.storeUi.setActiveCategory(cat_name);

                    this.storeCatalogs.setActiveSubCategory('');                  
                    this.storeUi.setActiveSubCategory('');
                    
                    this.storeUi.setPageView('page', 1);
                } else {
                    this.storeUi.setActiveSubCats(cat_name);
                }

                this.setState({
                    subs_active: !this.state.subs_active
                })
                
                const htmlEntities = {
                    "&euml;": "ë"
                };
                cat_name = cat_name.replace('&euml;', match => htmlEntities[match]);

                this.storeUi.setNavMobile(false);
                let url = "/categorie/" + cat_name;
                this.props.history.push(url);
            }

            render() {
                return (
                    <li>
                        <div className={"block--categories__cat" + (this.props.name == this.storeUi.active_category ? ' --active' : '')}>
                            <span 
                                onClick={(e) => this.handleOnClickCat(this.props.name)}
                                dangerouslySetInnerHTML={{ __html: this.props.display_value ? this.props.display_value : this.props.name}}
                            />
                        </div>   
                        {this.props.name === this.storeUi.active_subcats &&
                            <BlockCategory 
                                category = {this.props.cat}
                                category_name = {this.props.name}
                                cat_active = {this.storeUi.active_category}
                                subs_active = {this.state.subs_active}
                            />
                        }
                    </li>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CategoriesLi));

