import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as account from '../communicator/account';

function autoSave(store, save) {
    mobx.autorun(() => {
        const json = JSON.stringify(mobx.toJS(store));
        save(json);
    });
}

class StoreHistory {
    listRecentlyViewed = {
        'anonymous': [],
        'user': []
    }

    constructor(storeRoot) {
        this.retrieveRecentlyViewed(0);

        autoSave(this.listRecentlyViewed, this.save.bind(this));
    }

    save(json) {
        this.saveToLS(json);
    }

    saveToLS(json) {
        localStorage.setItem('SB_RECENTLYVIEWED20', json)
    }

    retrieveRecentlyViewed(id) {
        if (localStorage.getItem('SB_RECENTLYVIEWED20')) {
            this.listRecentlyViewed = JSON.parse(localStorage.getItem('SB_RECENTLYVIEWED20'));
        } else {
            this.listRecentlyViewed = {
                'anonymous': [],
                'user': []
            };
        }
        if (this.listRecentlyViewed == null) {
            this.listRecentlyViewed = {
                'anonymous': [],
                'user': []
            };
        }
    }

    addToRecentlyViewed(sku, id) {
        let items = [];

        if (id !== undefined && id !== 0) {
            items = this.listRecentlyViewed.user;
        } else {
            items = this.listRecentlyViewed.anonymous;
        }

        if (!this.checkOnRecentlyViewed(id, sku)) {
            const itemViewed = {
                sku: sku
            }
            items.unshift(itemViewed);

            if (items.length > 5) {
                items.pop();
            }

            if (id !== undefined && id !== 0) {
                this.listRecentlyViewed.user = items;
            } else {
                this.listRecentlyViewed.anonymous = items;
            }

            if (id !== undefined && id !== 0) {
                //this.saveWishlistToDB(id, this.listRecentlyViewed);
            } else {
                this.saveRecentlyViewedToLS();
            }
        }
    }

    removeFromRecentlyViewed(sku, id) {
        let items;

        if (id !== undefined && id !== 0) {
            items = this.listRecentlyViewed.user;
        } else {
            items = this.listRecentlyViewed.anonymous;
        }

        const idx = items.findIndex(item => item.sku === sku);
        items.splice(idx, 1);

        if (id !== undefined && id !== 0) {
            this.listRecentlyViewed.user = items;
        } else {
            this.listRecentlyViewed.anonymous = items;
        }

        this.saveRecentlyViewedToLS();
        // if (id !== undefined && id !== 0) {
        //     this.saveWishlistToDB(id, this.listRecentlyViewed.user);
        // }
    }

    checkOnRecentlyViewed = (id, sku) => {
        let items;
        let idx = -1;

        if (id !== undefined && id !== 0) {
            items = this.listRecentlyViewed.user;
        } else {
            items = this.listRecentlyViewed.anonymous;
        }

        if (items.length > 0) {
            idx = items
                .findIndex((item) => item.sku === sku);
        } else {
            idx = -1;
        }

        return (idx > -1) ? true : false;
    }

    saveRecentlyViewedToLS() {
        const json = JSON.stringify(this.listRecentlyViewed);
        localStorage.setItem('SB_RECENTLYVIEWED20', json)
    }

}

decorate(StoreHistory, {
    listRecentlyViewed: observable,
    addToRecentlyViewed: action,
    removeFromRecentlyViewed: action,
    retrieveRecentlyViewed: action
})

export default StoreHistory;