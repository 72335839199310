import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import ButtonDialogClose from '../../elements/button-dialog-close';
import BlockCategories from '../block/block-categories';
import BlockProductGroups from '../block/block-product-groups';
import BlockAuthors from '../block/block-authors';
import SitemapCats from '../../components/sitemap/sitemap-cats';

const NavigationMobile = inject("stores") (
    observer (
        class NavigationMobile extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
            }

            handleClose = (e) => {
                this.storeUi.setNavMobile();
            }

            handleNavClick = (e, id, path) => {
                this.handleClose(e);
                this.storeUi.setActiveMainNav(id);
                this.props.history.push(path);
            }

            handleToggleClick = (id, path) => {
                this.handleClose();
                this.storeUi.setActiveMainNav(0);
                this.storeUi.setActiveSubNav(0);

                switch(id) {
                    case 1:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        break;
                    case 2:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        break;
                    case 3:
                        this.storeGeneral.setShop("SHOP_ALL");
                        this.storeGeneral.setActiveShop("SHOP_ALL");
                        break;
                    default:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                }


                this.props.history.push(path);
            }

            render() {
                const partner_code = this.storeUi.app_interface.partner_code
                
                return (
                    <div className={"navigation--mobile" + (this.storeUi.app_interface.nav_mobile ? " --active" : "")}>
                        <ButtonDialogClose
                            caption = {'Sluit'}
                            onAction = {(e) => this.handleClose(e)}
                            btnClass = {'icon--close'}
                        />

                        {this.storeUi.checkFeature('SHOP_ALL') &&
                            <div className="navigation--mobile__content">
                                <ul className="navigation--mobile__list">
                                    {this.storeUi.toggle_nav.map((item, i) => (
                                        <li key={i} className="navigation--mobile__listitem" onClick={(e) => this.handleToggleClick(item.id, item.path)}>
                                            <span dangerouslySetInnerHTML={{ __html: i18n.t("navigation." + item.name)}}/></li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="navigation--mobile__content">
                            <h3>Hoofdgroepen</h3>
                            <ul className="navigation--mobile__list">
                                {this.storeUi.main_nav.map((item, i) => (
                                    (item.use == "all" && (item.show && item.show.includes(this.storeGeneral.shop))) &&
                                    <li key={i} className="navigation--mobile__listitem" onClick={(e) => this.handleNavClick(e, item.id, item.path)}>
                                        <span dangerouslySetInnerHTML={{ __html: i18n.t("navigation." + item.name)}}/></li>
                                ))}
                            </ul>

                            <ul className="navigation--mobile__list">
                                {this.storeUi.sub_nav.map((item, i) => (
                                    (item.use === 'all' || (item.use === 'partner' && partner_code !== 0)  || (item.use === 'no-partner' && partner_code === 0)) &&
                                        <li key={i} className="navigation--mobile__listitem" onClick={(e) => this.handleNavClick(e, item.id, item.path)}>
                                            <span dangerouslySetInnerHTML={{ __html: i18n.t("navigation." + item.name)}} />
                                        </li>
                                ))}
                            </ul>

                            {/* <h3>Zoek</h3>
                            <ul className="navigation--mobile__list">
                                <li className="navigation--mobile__listitem" onClick={(e) => this.handleNavClick(e, 1, "/search-advanced")}>{i18n.t("navigation.search")}</li>
                            </ul> */}

                            {this.storeGeneral.shop == 'SHOP_SB' &&
                                <React.Fragment>
                                    <h3>{i18n.t("sitemap.categories_sb")}</h3>
                                    <BlockCategories 
                                        shop = 'SHOP_SB'
                                        clean = {true}
                                    />
                                </React.Fragment>
                            }

                            {this.storeUi.checkFeature('SHOP_ALL') &&
                                this.storeGeneral.shop == 'SHOP_ALL' &&
                                    <React.Fragment>
                                        <h3>{i18n.t("sitemap.categories_all")}</h3>
                                        <BlockCategories 
                                            shop = 'SHOP_ALL'
                                            clean = {true}
                                        />
                                    </React.Fragment>
                            }

                            {this.storeGeneral.shop == 'SHOP_SB' &&
                                <React.Fragment>
                                    <h3>Productgroepen</h3>
                                    <BlockProductGroups 
                                        clean = {true}
                                    />
                                </React.Fragment>
                            }

                            {this.storeGeneral.shop == 'SHOP_SB' &&
                                <React.Fragment>
                                    <h3>Auteurs</h3>                          
                                    <BlockAuthors 
                                        clean = {true}
                                    />
                                </React.Fragment>
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default  withRouter(withTranslation()(NavigationMobile));
