import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import CarouselBanner from '../content/carousel-banner';

const BlockLatest = inject("stores") (
    observer (
        class BlockLatest extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }
  
            render() {
                return (
                    <div className="block block--sidebar block--latest">
                        <div className="block__block-title">
                            {i18n.t("block.latest.header")}
                        </div> 
                        <div className="block__block-subtitle">
                            {i18n.t("block.latest.intro")}
                        </div>
                        <div className="block__block-content">
                            <CarouselBanner 
                                play = {this.storeUi.carousel_home}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockLatest);


