import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import moment from "moment";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as utilities from '../../utilities/formats';
import AccountCommissionPayouts from './account-commissions-payouts';
import AccountCommissionsOverviewItem from './account-commissions-overview-item';

const AccountCommissionsOverview = inject("stores") (
    observer (
        class AccountCommissionsOverview extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
                this.storePartners = this.props.stores.storePartners;

                this.state = {
                    commissions: [],
                    commissionsOpen: []
                }
            }

            componentDidMount = async() => {
                let commissionsOpen = [];
                let lastPayout;
                let orderDate;
                const commissions = await this.storeUser.getCommissions();
                const commission_payouts = await this.storeUser.getPayouts();

                if (commission_payouts && commission_payouts.length > 0) {
                    lastPayout = commission_payouts[0].settled_date.date;
                    //lastPayout ="2009-07-01";
                    lastPayout = moment(lastPayout).format('YYYY-MM-DD');

                    commissions.map((commission) => {
                        orderDate = moment(commission.order_date.date).format('YYYY-MM-DD');

                        if (moment(orderDate).isAfter(lastPayout, 'day')) {
                            commissionsOpen.push(commission);
                        }
                    })
                }

                if (!commission_payouts) {
                    commissions.map((commission) => {
                        commissionsOpen.push(commission);
                    })
                }

                this.storeUser.setCommissionsOpen(commissionsOpen);

                this.setState({
                    commissions: commissions,
                    commissionsOpen: commissionsOpen
                })
            }

            // getDiscountPerc = (order, type) => {
            //     let perc;

            //     if (type == 1) {
            //         perc = this.storeUser.user_data.commission;
            //     } else {
            //         perc = this.storeUser.user_data.commission_3party;
            //     }

            //     // this.storePartners.getPartnerFees();
            //     const partnerFees = this.storePartners.partnerFees;
            //     const idx = partnerFees
            //         .findIndex((item) => parseInt(item.bookId) === parseInt(order.item_id));
                
            //     if (idx !== -1) {
            //         perc = parseInt(partnerFees[idx].percFee);
            //     }

            //     return perc;
            // }

            render() {
                const vat_nr = this.storeUser.user_data.profile.nrVAT;

                return (
                    <React.Fragment>
                        <div className="content-segment">
                            <div className="content-segment__content">  

                                <h2>{i18n.t("commissions.hd_overview_open")}</h2>
                                <ul className="table--financial">
                                    <li className="table--financial__header">
                                        <div className="table--financial__cell table--financial__cell--date">{i18n.t("commissions.tbl_hd_date")}</div>
                                        {/* <div className="table--financial__cell table--financial__cell--date">{i18n.t("commissions.tbl_hd_invoice")}</div> */}
                                        <div className="table--financial__cell table--financial__cell--title">{i18n.t("commissions.tbl_hd_description")}</div>
                                        <div className="table--financial__cell table--financial__cell--amount">{i18n.t("commissions.tbl_hd_amount")}</div>
                                        <div className="table--financial__cell table--financial__cell--amount">
                                            {vat_nr !== ''
                                                ?   i18n.t("commissions.tbl_hd_price_incl_vat")
                                                :   i18n.t("commissions.tbl_hd_price_excl_vat")
                                            }
                                        </div>
                                        <div className="table--financial__cell table--financial__cell--amount">{i18n.t("commissions.tbl_hd_commission_perc")}</div>
                                        <div className="table--financial__cell table--financial__cell--amount">{i18n.t("commissions.tbl_hd_commission")}</div>
                                    </li>
                                    {this.state.commissionsOpen && this.state.commissionsOpen.length > 0
                                        ?   this.state.commissionsOpen.map(order =>
                                                <AccountCommissionsOverviewItem
                                                    orderItem = {order}
                                                />
                                            )
                                        :   <li className="table--financial__row">
                                                <div className="table--financial__cell--full">Er zijn nog geen commissies verkregen</div>
                                            </li>
                                    }
                                    {this.state.commissionsOpen && this.state.commissionsOpen.length > 0 &&
                                        <li className="table--financial__totals table--financial__totals--top">
                                            <div className="table--financial__cell"></div>
                                            <div className="table--financial__cell"></div>
                                            <div className="table--financial__cell table--financial__cell--label">{i18n.t("commissions.tbl_hd_total_excl_vat")}</div>
                                            <div className="table--financial__cell"></div>
                                            <div className="table--financial__cell table--financial__cell--amount"></div>
                                            <div className="table--financial__cell table--financial__cell--amount">{utilities.displayPrice(this.storeUser.commissionTotalExclVat)}</div>
                                        </li>
                                    }
                                    {this.state.commissionsOpen && this.state.commissionsOpen.length > 0 && vat_nr !== '' &&
                                        <React.Fragment>
                                            <li className="table--financial__totals">
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell table--financial__cell--label">{i18n.t("commissions.tbl_hd_vat_low")}</div>
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell table--financial__cell--amount"></div>
                                                <div className="table--financial__cell table--financial__cell--amount">{utilities.displayPrice(this.storeUser.commissionTotalVatLow)}</div>
                                            </li>
                                            <li className="table--financial__totals">
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell table--financial__cell--label">{i18n.t("commissions.tbl_hd_vat_high")}</div>
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell table--financial__cell--amount"></div>
                                                <div className="table--financial__cell table--financial__cell--amount">{utilities.displayPrice(this.storeUser.commissionTotalVatHigh)}</div>
                                            </li>
                                            <li className="table--financial__totals">
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell table--financial__cell--label">{i18n.t("commissions.tbl_hd_total_incl_vat")}</div>
                                                <div className="table--financial__cell"></div>
                                                <div className="table--financial__cell table--financial__cell--amount"></div>
                                                <div className="table--financial__cell table--financial__cell--amount">{utilities.displayPrice(this.storeUser.commissionTotalInclVat)}</div>
                                            </li>
                                        </React.Fragment>
                                    }
                                </ul>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(AccountCommissionsOverview);


