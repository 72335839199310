import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import {Player, ControlBar, ClosedCaptionButton, BigPlayButton} from 'video-react';
import '../assets/css/video-react.css';

const Video = inject("stores") (
    observer (
        class Video extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.urlAPP = process.env.REACT_APP_URL;
                this.urlAPI = process.env.REACT_APP_URL_API;

                if (this.urlAPP === 'http://localhost:3000') {
                    this.loc_cc = "./cc/";
                } else {
                    this.loc_cc = "../api/public/videos/cc/";
                }

                this.state = {
                    active_video_cc: "showing",
                    paused: true
                }
            }

            componentDidMount = () => {
                this.player.subscribeToStateChange(this.handleStateChange);
                
                if (this.props.location === 'popup') { 
                    if (this.props.current_state) {
                        this.player.seek(this.props.current_state.currentTime);
                    }

                    if (this.props.idx === this.storeUi.active_video) {
                        if (this.storeUi.active_video_location === 'popup') {
                            if (this.storeUi.isDialogVideoOpen) {
                                if (this.storeUi.active_video_playing) {
                                    this.play();
                                } else {
                                    this.pause();
                                }
                            }
                        }
                    }
                }

                if (this.props.location === 'list') { 
                    if (this.props.idx === this.storeUi.active_video) {
                        if (this.storeUi.active_video_location === 'popup') {
                            if (this.storeUi.isDialogVideoOpen) {
                                if (this.storeUi.active_video_playing) {
                                    this.play();
                                } else {
                                    this.pause();
                                }
                            }
                        }
                    }
                }
            }

            componentWillUnmount = () => {
                if (this.props.location === 'popup') { 
                    this.storeUi.setActiveVideo(this.props.idx - 100);
                    this.storeUi.setActiveVideoLocation('list');
                }
            }

            componentDidUpdate(prevProps) {
                //SbC stopping other videos when video idx starts
                if (this.props.location === 'list' && this.storeUi.active_video !== this.props.idx) {
                    this.pause();
                }

                if (this.props.location === 'list' && this.props.location === this.storeUi.active_video_location) {
                    if (this.storeUi.active_video_playing) {
                            if (this.props.idx == this.storeUi.active_video) {
                            this.player.play();
                        }
                    }
                }

                if (prevProps.active_video_location !== this.props.active_video_location) {
                    if (this.props.active_video_location === 'list') {
                        if (this.props.idx === this.storeUi.active_video) {
                            this.player.seek(this.props.current_state.currentTime);
                        }
                    }
                }

                if (prevProps.active_video_cc !== this.props.active_video_cc) {
                    this.setState({
                        active_video_cc: this.props.active_video_cc
                    })
                }
            }

            handleStateChange = (state, prevState) => { 
                if (this.props.location === 'list') {  
                    if (this.storeUi.active_video_location === 'list') { 
                        if (state.paused) {
                            if (this.props.idx === this.storeUi.active_video) {
                                this.storeUi.setActiveVideoPlaying(false);
                                this.pause();
                                this.setState({
                                    paused: state.paused
                                })
                            } else {
                                this.pause();
                            } 
                        }


                            if (!state.paused) {
                                this.storeUi.setActiveVideo(this.props.idx);
                                this.storeUi.setActiveVideoPlaying(true);
                                this.player.play();
                                this.setState({
                                    paused: false
                                })
                            }
                        } 
                }

                if (this.props.location === 'popup') {   
                    if (this.storeUi.active_video_location === 'popup') { 
                        if (!state.paused) {
                            this.storeUi.setActiveVideoPlaying(true);
                        }

                        if (state.paused) {
                            this.storeUi.setActiveVideoPlaying(false);
                        }
                    } else {
                        this.player.pause();
                    }
                }

                if (state && state.textTracks) {
                    state.textTracks[0].mode = this.state.active_video_cc;
                }

                this.setState({
                    player: state,
                    currentTime: state.currentTime
                });

                this.props.handleVideoState(state);
            }

            handleCC = () => {

            }

            play = () => {
                this.player.play();
            }
            
            pause() {
                this.player.pause();
            }
            
            load() {
                this.player.load();
            }
                      
            ref = player => {
                this.player = player;
            }

            render() {
                return (
                    <Player
                        ref = {this.ref}
                        poster = {this.props.video.poster}
                        key = {this.props.video.url}
                        preload="none"
                    >
                        <source 
                            src = {this.props.video.url} 
                            preload="none"
                        />
                        <track
                            kind = "captions"
                            src = {this.loc_cc + this.props.video.cc_file + ".vtt"} 
                            srcLang = "en"
                            label = "Engels"
                            default = {this.state.active_video_cc === 'showing' ? true : false}
                        />                      
                        {(this.props.video.cc_file !== '' && this.props.video.cc_file !== null) &&
                            <ControlBar 
                                autoHide = {true}
                            >
                            </ControlBar>
                        }
                    </Player>
                )
            }
        }
    )
)

export default withTranslation()(Video);
