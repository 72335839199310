import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';

const Disclaimer = inject("stores") (
    observer (
        class Disclaimer extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeBooks = this.props.stores.storeBooks;
            }
     
            render() {
                return (
                    <Template
                        type = 'default'
                        header = "disclaimer"
                    >
                        <div className="content-segment">
                            <ContentText
                                page = {"disclaimer"}
                            />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Disclaimer;
