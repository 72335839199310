import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';

const SearchResultNewsletters = inject("stores") (
    observer (
        class SearchResultNewsletters extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirect: false
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = async() => {
            }

            handleClick = (link, wk, view) => {
                const url = link;
                const newsletter = wk;

                this.props.handleClick(url, newsletter, view);
            }

            clearResults = () => {
                this.props.clearResults();
            }

            render() {
                return (
                    <div
                        className = 'search-newsletters'
                    >
                        {this.props.searchValue !== '' &&
                            <React.Fragment>
                                {this.props.searchResult.length > 0
                                ?    <React.Fragment>
                                        <div
                                            className = 'search-newsletters__header'
                                        >
                                            De volgende links met betrekking tot "{this.props.searchValue}" zijn gevonden: 
                                            <span
                                                className = "search-newsletters__clear --link"
                                                onClick = {() => this.clearResults()}
                                            >
                                                Verwijder resultaten
                                            </span>
                                        </div>

                                        {this.props.searchResult.map((result, i) => (
                                            <React.Fragment>
                                            {(i === 0 || (i >= 1 && this.props.searchResult[i].file !== this.props.searchResult[i - 1].file))
                                            ?   <div
                                                    className = {'search-newsletters__result search-newsletters__result--first' + (i!== 0 ? " --listitem" : "")}
                                                >
                                                    <span
                                                        className = 'search-newsletters__file --link'
                                                        onClick = {() => this.handleClick(result.file, result.file.substring(7, 11), 'web')}
                                                    >
                                                        <span
                                                            className = 'search-newsletters__date'
                                                        >
                                                            {result.date}
                                                            <span>{result.wk}</span>
                                                        </span>

                                                    </span>
                                                    <span
                                                        className = 'search-newsletters__match'
                                                    >
                                                        <span
                                                            className = 'search-newsletters__match--content'
                                                            dangerouslySetInnerHTML={{ __html: result.match}}
                                                        />
                                                    </span>
                                                </div>
                                                
                                            :   <div
                                                    className = 'search-newsletters__result'
                                                >
                                                    <span
                                                        className = 'search-newsletters__file'
                                                    />
                                                    <span
                                                        className = 'search-newsletters__match'
                                                    >
                                                        <span
                                                            className = 'search-newsletters__match--content'
                                                            dangerouslySetInnerHTML={{ __html: result.match}}
                                                        />
                                                    </span>
                                                </div>
                                            }
                                            </React.Fragment>
                                        ))}
                                       
                                    </React.Fragment>
                                :   <div
                                        className = 'search-newsletters__header'
                                    >
                                        Er zijn geen resultaten gevonden
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </div>
                )
            }
        }
    )
)

export default withRouter(SearchResultNewsletters);

