import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as content from '../../utilities/content';

import TemplateSidebarRight from '../../templates/template-sidebar-right';

import ImgCover from '../../elements/img-cover';
import BookPrice from '../../elements/book-price';
import BlockBookActions from './block-book-actions';

const BlockTop10 = inject("stores") (
    observer (
        class BlockTop10 extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirect: false
                }
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = async() => {
                let tag = this.props.tag;

                if (this.storeBooks.bookList_top10.length === 0) {
                    const result = await this.storeBooks.getTop10NUR(tag);

                    if (result !== undefined && result !== null && result.data !== null && result.data.length > 0) {
                        const nur = result.data[0].nur;
                        const return_data = await this.storeBooks.getBooksTop10(tag, nur, 0);
                        this.setState({
                            tag: tag,
                            bookList: return_data
                        })
                    } else {
                        this.setState({
                            tag: tag,
                            bookList: []
                        })
                    }
                } else {
                    this.setState({
                        tag: tag,
                        bookList: this.storeBooks.bookList_top10
                    })
                }
            }

            componentDidUpdate = async(prevProps) => {
                // if (prevProps.tag !== this.props.tag) {
                //     let tag = this.props.tag;

                //     const result = await this.storeBooks.getTop10NUR(tag);

                //     if (result !== undefined && result !== null && result.data !== null && result.data.length > 0) {
                //         const nur = result.data[0].nur;
                //         const return_data = await this.storeBooks.getBooksTop10(tag, nur, 0);
                //         this.setState({
                //             tag: tag,
                //             bookList: return_data
                //         })
                //     } else {
                //         this.setState({
                //             tag: tag,
                //             bookList: []
                //         })
                //     }
                // }
            }

            handleOnClick = (e, book) => {
                e.preventDefault();
                const sku = book.sku;
                const id = content.formatTitle(book.title);

                this.storeUi.setActiveSubCategory('');
                this.storeUi.setActiveCategory('');

                this.storeUi.setCurrentBook(sku);

                this.setState({
                    sku: sku,
                    redirect: this.props.match.params.sku ? false : true
                });

                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    url = "/book/" + sku + "/" + id;
                } else {
                    url = "/book/" + sku;
                }
                this.props.history.push(url);
            }
      
            render() {
                // if (this.state.redirect) {
                //     return <Redirect 
                //         push to={"/book/" + this.state.sku} 
                //     />;
                // }

                // if (this.state.redirect) {
                //     const id = content.formatTitle(this.state.book.title);
                //     return <Redirect 
                //         push to={"/book/" + this.props.sku + "/" + id} 
                //     />;
                // }

                return (
                    <TemplateSidebarRight
                        class_modifier = "top10"
                        block_title = {"Top 10"}
                        block_intro = {this.props.title}
                    >                       
                        <div className="list list--top10">                       
                            <ul className="list--top10__list">
                                {this.state.bookList && this.state.bookList.map((book, i) => (
                                    book &&
                                        <li key={i} className="list--top10__listitem" onClick={(e) => this.handleOnClick(e, book)}>
                                            <ImgCover
                                                sku = {book.sku}
                                                title = {book.title}
                                            />
                                            <div className="list--top10__actions">
                                                <BookPrice
                                                    price = {book.price_incl}
                                                    sku = {book.sku}
                                                />
                                                <BlockBookActions
                                                    sku = {book.sku}
                                                    book = {book}
                                                    view = {["wishlist", "cart"]}
                                                    display = {"icon"} 
                                                    availability = "available"
                                                />
                                            </div>
                                        </li>
                                ))}
                            </ul>

                            {this.state.bookList && this.state.bookList.length === 0 &&
                                <p>Er is hier geen Top 10 van bekend</p>
                            }
                        </div>

                        <div className="block__block-content">
                            <Link 
                                className="link--read-more"
                                to="/category/top10"
                            >
                                {i18n.t("block.top10.link_all")}
                            </Link>
                        </div>

                        
                    </TemplateSidebarRight>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(BlockTop10));
