import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

const BlockBookTags = inject("stores") (
    observer (
        class BlockBookTags extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    tags: []
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUser = this.props.stores.storeUser; 

                this.excluded = [
                    'eigen uitgaven', ' eigen uitgaven'
                ]
            }

            componentDidMount = async() => {
                const tags = await this.storeBooks.getBookTags(this.props.sku);
                this.setState({
                    tags: tags.length > 0 ? tags : []
                })
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.sku !== this.props.sku) {
                    const tags = await this.storeBooks.getBookTags(this.props.sku);
                    this.setState({
                        tags: tags.length > 0 ? tags : []
                    })
                }
            }

            handleOnClick(tag) {
                let search_term = tag;
                this.storeUi.setSearchValue(search_term);
                this.storeUi.setPageView('page', 1);
                this.storeUi.getSearchIntentions(search_term);
                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    if (this.storeGeneral.shop === 'SHOP_ALL') {
                        this.storeUi.getSearchResults(search_term, '', this.storeUi.page_view);
                    } else {
                        this.storeUi.getSearchResultsSB(search_term, '', this.storeUi.page_view);
                    }
                } else {
                    this.storeUi.getSearchResultsSB(search_term, '', this.storeUi.page_view);
                }
                const path = "/search-results?q=" + search_term;
                this.props.history.push(path);  
            }

            render() {
                if (this.state.tags && (this.state.tags.length === 0 || this.state.tags[0] === '')) {
                    return null;
                } else {
                    return (
                        <div className="block block--tags">
                            <span className="block--tags__title">Trefwoorden</span>
                            <ul className="block--tags__list">
                                {this.state.tags &&
                                    this.state.tags.map((tag, i) => (
                                        tag.substring(0, 1) !== '!' && tag.substring(0, 2) !== ' !' && !this.excluded.includes(tag) &&
                                            <li 
                                                className="block--tags__listitem --link" 
                                                onClick={(e) => this.handleOnClick(tag)}
                                            >
                                                {tag}
                                            </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
            }
        }
    )
)

export default withTranslation()(withRouter(BlockBookTags));


