import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import RowText from '../form-rows/row-text';

const FormPartner = inject("stores") (
    observer (
        class FormPartner extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formControls: { 
                        website: {
                            type: "text",
                            value: this.storeUser.partner_data.partner.website ? this.storeUser.partner_data.partner.website : this.storeUser.user_data.partner.website,
                            validationRules: {
                                isRequired: true
                            },
                            valid: this.storeUser.user_data.partner.website !== ''
                                ?   true
                                :   false
                        },
                        bank_account: {
                            type: "text",
                            value: this.storeUser.partner_data.partner.bank_account ? this.storeUser.partner_data.partner.bank_account : this.storeUser.user_data.partner.bank_account,
                            validationRules: {
                                isRequired: true
                            },
                            valid: this.storeUser.user_data.partner.bank_account !== ''
                                ?   true
                                :   false
                        },         
                        account_holder: {
                            type: "text",
                            value: this.storeUser.partner_data.partner.account_holder ? this.storeUser.partner_data.partner.account_holder : this.storeUser.user_data.partner.account_holder,
                            validationRules: {
                                isRequired: true
                            },
                            valid: this.storeUser.user_data.partner.account_holder !== ''
                                ?   true
                                :   false
                        },
                        account_city: {
                            type: "text",
                            value: this.storeUser.partner_data.partner.account_city ? this.storeUser.partner_data.partner.account_city : this.storeUser.user_data.partner.account_city,
                            validationRules: {
                                isRequired: true
                            },
                            valid: this.storeUser.user_data.partner.account_city !== ''
                                ?   true
                                :   false
                        }
                    },
                    error: false
                }
            }

            componentDidMount =() => {
                this.validateForm();
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUi.clearFormFeedback();

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = async() => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid
                }, () => this.props.handleFormChange(this.state.formControls, this.state.formIsValid, 'partner'))
            }

            render() {

                return (
                    <div className="form-wrapper">
                        <form className="form">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                <React.Fragment>
                                {this.state.formControls[key].type === "text" &&
                                    <RowText 
                                        type = {this.state.formControls[key].type}
                                        name = {key}
                                        value = {this.state.formControls[key].value}
                                        validationRules = {this.state.formControls[key].validationRules}
                                        validationMsg = {this.state.formControls[key].validationMsg}
                                        onValidate = {this.validateRow}
                                    />
                                }
                                </React.Fragment>
                            ))}
                        </form>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormPartner);
