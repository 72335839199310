import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import TemplateSidebarRight from '../../templates/template-sidebar-right';
import ContentText from '../../components/content/content-text';

const BlockShipping = inject("stores") (
    observer (
        class BlockShipping extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            openTC = (e) => {
                this.storeUi.setContentComponent(
                    <ContentText
                        title = {i18n.t("page_headers.general_terms")}
                        page = {"terms-conditions"}
                        className = "content-scrollable"
                    />
                );
                this.storeUi.openDialogContent();
            }
  
            render() {
                return (
                    <TemplateSidebarRight
                        class_modifier = "shipping"
                        block_title = {i18n.t("block.shipping.header")}
                    >
                        <div className="block__block-subtitle">
                            {i18n.t("block.shipping.text")}
                        </div> 
                        <div className="block__block-content">
                            <ul>
                                <li>voor orders vanaf € 20,- (incl. btw)</li>
                                <li>uitsluitend in Nederland</li>
                            </ul>
                            <span>(zie verder <a href="#" onClick={(e) => this.openTC(e)}>Algemene voorwaarden)</a></span>
                        </div>
                    </TemplateSidebarRight>
                )
            }
        }
    )
)

export default withTranslation()(BlockShipping);


