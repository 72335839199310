import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as ui from '../../utilities/ui';
import Template from '../../templates/template';

const Jobs = inject("stores") (
    observer (
        class Jobs extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;
            }

            componentDidMount = () => {
                ui.handleScroll('top');
                //this.storeUi.setActiveMainNav(1);
            }

            componentDidUpdate = () => {

            }
     
            render() {
                ui.handleScroll('top');

                return (
                    <Template
                        type = 'default'
                        header = {this.storeGeneral.shop === 'SHOP_SB' 
                            ?   "jobs"
                            :   "jobs_sb"
                        }
                        nav_name = {"jobs"}
                    >
                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Ken je iemand, of ben jij degene, die we zoeken?</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Wij geloven dat het voor de komende jaren cruciaal is om mensen eerlijk voor te lichten over onderwerpen als gezondheid, financi&euml;n, persoonlijke groei, klimaat, EU, etc.</p>
                                <p>En met eerlijk bedoelen wij: zonder industriële belangen, zonder politieke belangen, zonder eigenbelangen: in maar één belang, dat van de persoon zelf.</p>
                                <p>Daarom willen wij groeien als uitgeverij; we hebben een behoorlijke investering gedaan in ons pand met name om ruimte te creëren voor nieuwe collega's.</p>
                                <p>We willen een bijzonder bedrijf zijn, waar o.a. de medewerkers ook eigenaar van het bedrijf kunnen worden en waar veel aan sport/fitness gedaan kan worden. We huizen in de bossen nabij Zeist.</p>
                            </div>
                        </div>

                        {/* <div className="content-segment">
                            <div className="content-segment__header">
                                <h3>Opmaker (m/v)</h3>
                            </div>
                            <div className="content-segment__content">
                                <p>We willen graag in contact komen met iemand die bedreven is in het opmaken van boeken.</p>
                                <p>Kennis van Adobe Indesign, Photoshop en andere programma’s is noodzakelijk. We werken in een Windows omgeving.</p>
                                <h4>Werktijden:</h4>
                                <p>In overleg, locatie Den Dolder (om deel uit te maken van het team).</p>
                                <h4>Leeftijd:</h4>
                                <p>De kennis en motivatie is belangrijker dan de leeftijd.</p>
                                <h4>Woonplaats:</h4>
                                <p>In een straal van max 20 km van onze vestiging Den Dolder (Zeist).</p>
                            </div>
                        </div> */}

                        {/* <div className="content-segment">
                            <div className="content-segment__header">
                                <h3>Een duizendpoot (m/v)</h3>
                            </div>
                            <div className="content-segment__content">
                                <p>Die van gezond, maar lekker eten houdt en voor ons team de lunch kan klaarmaken.<br/>Wij zijn wars van zakjes, pakjes, blikvoer, supermarkteten, etc. Wij houden ook niet van 'dood' eten, dus hebben we de magnetron de deur uit gedaan.</p>
                                <p>Iemand die de bestellingen inpakt voor onze lezers/klanten.</p>
                                <h4>Werktijden:</h4>
                                <p>In overleg, locatie Den Dolder.</p>
                                <h4>Leeftijd:</h4>
                                <p>Motivatie en iemand die graag voor een ander zorgt, maar zichzelf niet uitvlakt is belangrijker dan de leeftijd.</p>
                                <h4>Woonplaats:</h4>
                                <p>In een straal van max 20 km van onze vestiging Den Dolder (Zeist).</p>
                            </div>
                        </div> */}

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h3>Rechterhand eigenaar (m/v)</h3>
                            </div>
                            <div className="content-segment__content">
                                <p>Wij zoeken iemand die wil leren wat de eigenaar/uitgever nu weet en doet. Heb je er ooit van gedroomd een uitgeverij te runnen of (mede) eigenaar van een uitgeverij te zijn? Mogelijk is dit je kans. Dit is geen parttime functie.</p>
                                <h4>Werkplaats:</h4>
                                <p>Den Dolder.</p>
                                <h4>Woonplaats:</h4>
                                <p>In een straal van max 20 km van onze vestiging Den Dolder (Zeist).</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h3>PR medewerker (m/v)</h3>
                            </div>
                            <div className="content-segment__content">
                                <p>Een boek is zo goed als je erover praat.</p>
                                <p>Wanneer je als uitgeverij een toptitel hebt, maar niemand praat erover, dan zullen weinig mensen het boek aanschaffen en wordt een toptitel een floptitel. Andersom is ook weleens gebeurd (niet bij onze uitgeverij).</p>
                                <p>Wij zoeken een PR medewerker die kan praten over een toptitel met:</p>
                                <ul>
                                    <li>de pers</li>
                                    <li>internetwinkels</li>
                                    <li>bloggers</li>
                                    <li>bedrijven</li>
                                    <li>groothandels</li>
                                    <li>therapeuten</li>
                                    <li>artsen</li>
                                    <li>etc.</li>
                                </ul>
                                <h4>Ervaring:</h4>
                                <p>Affiniteit met PR activiteiten is een pre;<br/>
                                Inhoudelijk achter onze huidige boeken staan is cruciaal;<br/>
                                Redactionele ervaring is een pre.</p>
                                <h4>Werktijden:</h4>
                                <p>In overleg, locatie Den Dolder (om deel uit te maken van het team).</p>
                                <h4>Leeftijd:</h4>
                                <p>De kennis en motivatie is belangrijker dan de leeftijd.</p>
                                <h4>Woonplaats:</h4>
                                <p>In een straal van max 20 km van onze vestiging Den Dolder (Zeist).</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h3>Over Succesboeken.nl</h3>
                            </div>
                            <div className="content-segment__content">
                                <p>Succesboeken.nl is een uitgeverij van zelfhulpboeken op het gebied van gezondheid, financiën en persoonlijke ontwikkeling. Qua boeken/informatie die we op de markt brengen hebben we een sterke filosofie ontwikkeld. Je zult achter deze filosofie moeten staan of ervoor open moeten willen staan.</p>
                                <p>Mail je cv naar Succesboeken.nl, inclusief je pasfoto. Schrijf begeleidend je motivatie en waarom je denkt dat we niet meer zonder jou kunnen.</p>
                                <p>Succesboeken.nl<br/>
                                e-mail: info@succesboeken.nl</p>
                                <p>Bellen om direct een afspraak te maken heeft geen zin. Naar aanleiding van de schriftelijke sollicitatie (per e-mail of gewone post) zullen we een aantal sollicitanten uitnodigen om zich te presenteren.</p>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withRouter(Jobs);
