import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Swiper, Slide } from 'react-dynamic-swiper'
import { withRouter } from "react-router-dom";
import 'swiper/css/swiper.css';

import * as content from '../../utilities/content';

const CarouselBanner = inject("stores") (
    observer (
        class CarouselBanner extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.state = {
                  
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = async() => {
                const banner = await this.storeUi.getBanner();
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.play !== this.props.play) {
                    if (this.props.play === 'play') {
                        this.reactSwiper.swiper().autoplay.start();
                    } else {
                        this.reactSwiper.swiper().autoplay.stop();
                    }
                }
            }

            handleOnClick = async(e, sku) => {
                this.storeUi.setActiveSubCategory('');
                this.storeUi.setActiveCategory('');
                
                this.storeUi.setCurrentBook(sku);
                const book = await this.storeBooks.returnBookDetailsBySku(this.storeGeneral.shop, sku);
                const id = content.formatTitle(book.title);

                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    url = "/book/" + sku + "/" + id;
                } else {
                    url = "/book/" + sku;
                }
                this.props.history.push(url);
            }

            render() {
                const banner = this.storeUi.banner;

                const bookSlides = [
                    {   
                        type: "text",
                        text: banner.text1
                    },{
                        type: "image",
                        url: banner.img
                    },{
                        type: "text",
                        text: banner.text2
                    },{
                        type: "image",
                        url: banner.img
                    }
                ]

                return (
                    <div className="carousel--banner">
                        <Swiper 
                            ref={(c) => { this.reactSwiper = c }}
                            swiperOptions={{
                                slidesPerView: 1,
                                initialSlide: 1,
                                autoplay: this.storeUi.carousel_home === 'play' 
                                    ?   {
                                            delay: 6000,
                                            disableOnInteraction: false
                                        }
                                    :   false,
                                loop: false,
                                speed: 1000
                            }}
                            {...this.state.options}
                        >
                            {bookSlides.map((slide, i) => (
                                <Slide key={i}>
                                    <div className="carousel--banner__slide" onClick={(e) => this.handleOnClick(e, banner.sku)}>
                                        <div className="carousel--banner__slide-content">
                                            {slide.type === "image"
                                            ?    <img src={this.urlAPI + "/public/images/banners/" + slide.url} alt="banner image promoted book"/>
                                            :    <span className="carousel--banner__slide-text" dangerouslySetInnerHTML={{ __html: slide.text}}></span>
                                            }
                                        </div>
                                    </div>
                                </Slide>
                            ))}
                        </Swiper>
                    </div>
                )
            }
        }
    )
)

export default withRouter(CarouselBanner);

