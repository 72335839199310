import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import NewWindow from 'react-new-window';
import { withRouter } from "react-router-dom";
import moment from "moment";
import i18n from "i18next";
import { Redirect } from 'react-router';

import * as account from '../../communicator/account';
import DialogConfirmation from '../dialogs/dialog-confirmation';

import FormPayment from '../forms/form-payment';

const AccountOpenOrderPayment = inject("stores") (
    observer (
        class AccountOpenOrderPayment extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    view: 'overview',
                    payment_window: false,
                    order_id: 0,
                    order_data: [],
                    redirect: false
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;

                this.refActions = React.createRef();
            }

            componentDidMount = async() => {
                let idx
                let order_data = await this.storeOrder.getOrderData(this.storeUi.order_to_process.id)
                this.setState({
                    order_data: order_data
                })
            }

            // checkPaymentResult = () => {
            //     this.storeOrder.getOrderPaymentDetails(this.state.order_id);
            //     this.storeOrder.unloadPaymentWindow();
            // }

            startPayment = async (payment_id, issuer_code) => {
                let order_data = {
                    order_total_incl: parseFloat(this.storeUi.order_to_process.total)
                        .toFixed(2)
                        .toString(),
                    firstname: this.state.order_data[0].firstname,
                    lastname:  this.state.order_data[0].lastname,
                    email:  this.state.order_data[0].email,
                    address: this.state.order_data[0].billing_address,
                    housenr: this.state.order_data[0].billing_housenr,
                    addition: this.state.order_data[0].billing_addition,
                    zipcode: this.state.order_data[0].billing_zipcode,
                    city: this.state.order_data[0].billing_city
                }
                let order_guid = this.state.order_data[0].guid;

                if (this.storeUi.order_to_process.id !== 0) {
                    let url = await this.storeOrder.handlePaymentProcess(this.storeUi.order_to_process.id, order_guid, payment_id, issuer_code, 'open_order', order_data);
                    if (url !== undefined) {
                        this.setState({
                            redirect: true
                        });
                    }
                }
            }

            completePayment = async () => {
                let order_data = {
                    order_total_incl: parseFloat(this.storeUi.order_to_process.total)
                        .toFixed(2)
                        .toString(),
                    firstname: this.state.order_data[0].firstname,
                    lastname:  this.state.order_data[0].lastname,
                    email:  this.state.order_data[0].email,
                    address: this.state.order_data[0].billing_address,
                    housenr: this.state.order_data[0].billing_housenr,
                    addition: this.state.order_data[0].billing_addition,
                    zipcode: this.state.order_data[0].billing_zipcode,
                    city: this.state.order_data[0].billing_city
                }

                this.storeOrder.handleBankTransfer(this.storeUi.order_to_process.id, 'open_order', order_data);
                if (this.storeUser.user_data.is_loggedin) {
                    this.storeOrder.deleteShoppingCartFromDB(this.storeUser.user_data.user_id)
                } else {
                    this.storeOrder.deleteShoppingCartFromLS();
                }
                await this.storeOrder.getAccountOrders(this.storeUser.user_data.user_id);
                this.setView("overview");
            }

            setView(view) {
                this.props.setView(view);
            }

            scrollToBtn = () => {
                this.refActions.current.scrollIntoView({
                    behavior: 'smooth',
                    block: "end"
                });
            }

            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/payment"} 
                    />;
                }

                let payment_method_id = this.storeUi.order_to_process.payment_method_id;
                const issuer_code = this.storeOrder.order_data.payment.payment_issuer.code;

                const btn_class = (this.storeUi.order_to_process.payment_method_id !== 'IDEAL')
                    ?   (payment_method_id === 0) ? '--disabled' : '--active'
                    :   (payment_method_id === 'IDEAL' && issuer_code === '9999') ? '--disabled' : '--active';

                return (
                    <div className="content">
                        <h2>Kies hoe je wilt betalen</h2>
                        <FormPayment 
                            type = {"open_order"}
                            payment_method_id = {payment_method_id}
                            country_code = {this.storeUi.order_to_process.billing_country_code}
                            onScrollToBtn = {this.scrollToBtn}
                        />
                        {payment_method_id !== 'BANKTRANS'
                        ?   <div className="cart-data__feedback">
                                <p>Klik hieronder op 'Start betaling' om de betaling af te ronden.<br/>
                                Je komt dan in de betaalomgeving van je bank of onze betaalprovider MultiSafepay. Zij regelen alle betalingen voor de webshop van Succesboeken.nl en is een veilige betaalomgeving.</p>
                                <p>Houd dit scherm open totdat de melding 'Sluit het scherm om terug te keren naar de webwinkel' verschijnt.</p>
                            </div>
                        :   <div className="cart-data__feedback">
                                <p>Klik hieronder op 'Voltooi bestelling' om de betaling via handmatige overschrijving te bevestigen.<br/>
                                Na ontvangst van de betaling wordt de bestelling onmiddellijk toegestuurd.</p>
                            </div>
                        }

                        <div className="cart__actions" ref={this.refActions}>
                            <button className="btn btn--secundary" onClick={(e) => this.setView("overview")}>{i18n.t("button.backto_review")}</button>
                            {payment_method_id !== 'BANKTRANS'
                            ?   <button className={"btn btn--primary " + btn_class} onClick={(e) => this.startPayment(payment_method_id, issuer_code)}>{i18n.t("button.goto_payment_provider")}</button>
                            :   <button className="btn btn--primary" onClick={(e) => this.completePayment()}>{i18n.t("button.complete_payment")}</button>
                            }
                        </div>
                    </div>
                       
                )
            }
        }
    )
)

export default withRouter(AccountOpenOrderPayment);


