import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";

const BlocksEvents = inject("stores") (
    observer (
        class BlocksEvents extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }
  
            render() {
                return (
                    <div className="blocks blocks--books">
                        <div className="blocks__blocks-title">
                            {i18n.t("blocks.events.header")}
                        </div> 
                        <div className="blocks__content">
                            <div className="block block--book">Event 1</div>
                            <div className="block block--book">Event 2</div>
                            <div className="block block--book">Event 3</div>
                        </div>
                        {this.props.view !== "all" &&
                            <div className="blocks__footer">
                                <Link to="/authors">Bekijk alle events</Link>
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)

export default BlocksEvents;

