import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as ebooks from '../communicator/ebooks';

class StoreEBooks {
    eBooksList = []

    constructor() {
        this.getEBooks('all');
    }

    async getEBooks() {
        try {
            const books = await ebooks.getEBooks();
            runInAction(() => {
                this.eBooksList = books.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getEBookDetails(sku) {
        let stock;
        try {
            const books = await ebooks.getEBookDetails(sku);
            runInAction(() => {
                stock = books.data[0];
                return stock;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return stock;
    }
}

decorate(StoreEBooks, {
    eBooksList: observable,
    getEBooks: action,
    getEBookDetails: action
})

export default StoreEBooks;