import React, { PureComponent } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import * as content from '../../utilities/content';

import SearchOverlayItemShort from './search-overlay-item-short';

const SearchResultAlternatives = inject("stores") (
    observer (
        class SearchResultAlternatives extends PureComponent {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    results: []
                }
            }

            componentDidMount = async() => {

            }

            componentDidUpdate = async(prevProps) => {
                window.scrollTo(0, 0);
            }

            onSubmit = () => {
                let search_term;
                search_term = this.storeUi.search_value;

                //SbC btn in list alternatives
                let shop = 'SHOP_ALL';
                let segment = 'ALL';
                if (this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_sb !== 0) {
                    shop = 'SHOP_SB';
                    segment = 'SB';
                }
                if (this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_sb === 0 && this.storeUi.search_count_all !== 0) {
                    shop = 'SHOP_ALL';
                    segment = 'ALL';
                }
                if (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_all !== 0) {
                    shop = 'SHOP_ALL';
                    segment = 'ALL';
                }
                this.storeGeneral.setShop(shop);
                this.storeGeneral.setActiveShop(shop);
                //this.storeGeneral.setSearchShop(segment);

                this.storeUi.setPageView('page', 1);
                this.storeUi.getSearchIntentions(search_term);
                this.storeUi.setSearchOverlay(false);
                this.storeUi.setSearchInputFocus(false);
               
                this.storeUi.saveSearchQuery(search_term);
                this.storeUi.setActiveMainNav(this.storeUi.app_interface.active_search_segment);

                const path = "/search-results";
                const str_search = "?q=" + search_term;

                this.props.history.push({
                    pathname: path,
                    search: str_search
                })
            }

            handleChangeSegment = (shop) => {
                let search_term;
                let altShop;
                let altShopSearch;
                search_term = this.storeUi.search_value;

                switch(shop) {
                    case 'Alle zelfhulpboeken':
                        altShop = 'SHOP_SB';
                        altShopSearch = 'SB';
                        this.storeUi.setActiveToggleNav(2);
                        break;
                    case 'Alle boeken':
                        altShop = 'SHOP_ALL';
                        altShopSearch = 'ALL';
                        this.storeUi.setActiveToggleNav(3);
                        break;
                    default:   
                }

                this.storeGeneral.setShop(altShop);
                this.storeGeneral.setActiveShop(altShop);
                //this.storeGeneral.setSearchShop(altShopSearch);
                
                this.storeUi.setPageView('page', 1);
                this.storeUi.getSearchIntentions(search_term);
                this.storeUi.setSearchOverlay(false);
                this.storeUi.setSearchInputFocus(false);
               
                this.storeUi.saveSearchQuery(search_term);
                this.storeUi.setActiveMainNav(this.storeUi.app_interface.active_search_segment);

                const path = "/search-results";
                const str_search = "?q=" + search_term;

                this.props.history.push({
                    pathname: path,
                    search: str_search
                })
            }

            render() {
                return (
                    <React.Fragment>                                               
                        {this.storeUi.checkFeature('SHOP_ALL') &&
                            <React.Fragment>
                                {this.storeGeneral.searchShop === 'OWN' && 
                                    <React.Fragment>
                                        {(this.storeUi.search_count_own === 0 && this.storeUi.search_count_sb > 1) &&
                                            <React.Fragment>
                                                <span
                                                    className = {"search-overlay__alternatives-header " + this.props.bemClass}
                                                >
                                                    {i18n.t('search.hd_alternatives')}
                                                        <span
                                                            className = "--link"
                                                            onClick = {() => this.handleChangeSegment('Alle zelfhulpboeken')}
                                                        >
                                                            Alle zelfhulpboeken
                                                        </span>
                                                </span>
                                                <ul className = "list--overlay2">
                                                    {this.storeUi.search_results_simple_sb && this.storeUi.search_results_simple_sb.map((item,i) => (
                                                    i < 10 &&
                                                        this.storeUi.searchTopItemTitleSku !== item.sku &&
                                                            <SearchOverlayItemShort
                                                                shop = {'SB'}
                                                                item = {item}
                                                            />
                                                    ))}
                                                </ul>
                                            </React.Fragment>
                                        }
                                        {(this.storeUi.search_count_own === 0 && this.storeUi.search_count_sb === 0 && this.storeUi.search_count_all > 1) &&
                                            <React.Fragment>
                                                <span
                                                    className = {"search-overlay__alternatives-header " + this.props.bemClass}
                                                >
                                                    {i18n.t('search.hd_alternatives')}
                                                        <span
                                                            className = "--link"
                                                            onClick = {() => this.handleChangeSegment('Alle boeken')}
                                                        >
                                                            Alle boeken:
                                                        </span>
                                                </span>
                                                <ul className = "list--overlay2">
                                                    {this.storeUi.search_results_simple_all && this.storeUi.search_results_simple_all.map((item,i) => (
                                                    i < 10 &&
                                                        <SearchOverlayItemShort
                                                            shop = {'ALL'}
                                                            item = {item}
                                                        />
                                                    ))}
                                                </ul>
                                            </React.Fragment>
                                        }
                                        </React.Fragment>
                                }

                                {this.storeGeneral.searchShop === 'SB' && 
                                    <React.Fragment>
                                        {(this.storeUi.search_count_sb === 0 && this.storeUi.search_count_all > 1) &&
                                            <React.Fragment>
                                            <span
                                                className = {"search-overlay__alternatives-header " + this.props.bemClass}
                                            >
                                                {i18n.t('search.hd_alternatives')}
                                                    <span
                                                        className = "--link"
                                                        onClick = {() => this.handleChangeSegment('Alle boeken')}
                                                    >
                                                        Alle boeken:
                                                    </span>
                                            </span>
                                            <ul className = "list--overlay2">
                                                {this.storeUi.search_results_simple_all && this.storeUi.search_results_simple_all.map((item,i) => (
                                                i < 10 &&
                                                    <SearchOverlayItemShort
                                                        shop = {'ALL'}
                                                        item = {item}
                                                    />
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }   
                            
                        {((this.storeGeneral.searchShop === 'OWN' && (this.storeUi.search_count_sb > 0 || this.storeUi.search_count_all > 0)) ||
                            (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_all > 0)
                        ) &&
                            <div className="form-row form-row--buttons">
                                <button 
                                    className="btn btn--primary --search"
                                    onClick={(e) => this.onSubmit()}
                                >
                                    {(this.storeGeneral.searchShop === 'OWN' && 
                                        this.storeUi.search_count_sb !== 0) 
                                        ?   this.storeUi.search_count_sb > 1
                                            ?   'Toon alle ' + this.storeUi.search_count_sb + ' resultaten in Alle zelfhulpboeken'
                                            :   'Toon meer informatie over dit resultaat'
                                        :   null
                                    }
                                    {(this.storeGeneral.searchShop === 'OWN' && 
                                        this.storeUi.search_count_sb === 0 &&
                                            this.storeUi.search_count_all !== 0) 
                                            ?   this.storeUi.search_count_all > 1
                                                ?   'Toon alle ' + this.storeUi.search_count_all + ' resultaten in Alle boeken'
                                                :   'Toon meer informatie over dit resultaat'
                                            :   null
                                    }
                                    {(this.storeGeneral.searchShop === 'SB' && 
                                        this.storeUi.search_count_all !== 0)
                                        ?   this.storeUi.search_count_all > 1
                                            ?   'Toon alle ' + this.storeUi.search_count_all + ' resultaten in Alle boeken'
                                            :   'Toon meer informatie over dit resultaat'
                                        :   null
                                    }                                   
                                </button>
                            </div>
                        }
       
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(SearchResultAlternatives);
