import React from 'react';
import { observer } from "mobx-react";
import PropTypes from 'prop-types';
import i18n from "i18next";

import ButtonDialog from '../../elements/button-dialog';
import ImgCover from '../../elements/img-cover';

export default class DialogError extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    componentDidMount = () => {
        document.addEventListener("keydown", this.escFunction, false);
    }
    
    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose();
        }
    }

    render() {
        if(!this.props.show) {
            return null;
        }

        return (
            <div className="overlay">
                <div className="dialog dialog--error">
                    <div className="dialog-header">
                        <h2>{this.props.title}</h2>
                    </div>
                    <div className="dialog-content">
                        <p>Er is een technische fout opgetreden.</p>
                        <p>Neem contact met ons op indien je problemen blijft ondervinden, dan zoeken we samen naar een oplossing.</p>
                        {this.props.children}
                    </div>
                    {this.props.buttons &&
                        <div className="dialog-footer">
                            {this.props.buttons.map((button, i) => (
                                <ButtonDialog
                                    key = {i}
                                    caption = {button.text}
                                    onAction = {button.onClick}
                                    btnClass = {button.btnClass}
                                />
                            ))}
                        </div>
                    }
                </div>
            </div>
        );
    }
}
