import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

import ReactPlayer from 'react-player'

const BlockPreviewVideo = inject("stores") (
    observer (
        class BlockPreviewVideo extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    filename: ''
                }
            }

            componentDidMount() {
                const preview_data = this.storeBooks.preview_video;
                this.setState({
                    location:  preview_data[0].location,
                    filename: preview_data[0].filename,
                    playing: false
                }) 
            }

            componentDidUpdate(prevProps) {
                if (prevProps.play !== this.props.play) {
                    this.setState({
                        playing: this.props.play
                    })
                }
            }

            startVideo = (location, filename) => {
                this.setState({
                    location: location,
                    filename: filename,
                    playing: true
                })
            }

            stopVideo = (filename) => {
                this.setState({
                    playing: false
                })
            }

            render() {
                const preview_data = this.storeBooks.preview_video
                
                if (preview_data.length == 0) {
                    return null;
                }

                return (
                    <div className="blocks blocks--video-clip">
                        <div className="blocks__blocks-title">
                            <div className="blocks__blocks-title--main">
                                {this.props.blockTitle}
                            </div>
                        </div> 
                        <div className="blocks__content">
                            <div className="wrapper-media__player--video">
                                {this.state.location === 'local' &&
                                    <ReactPlayer 
                                        url = {this.urlAPI + '/public/videos/' + this.state.filename}
                                        playing = {this.state.playing}
                                        controls = {true}
                                        width = '75%'
                                    />
                                }
                                {this.state.location !== 'local' &&
                                    <ReactPlayer 
                                        url = {'https://www.youtube.com/watch?v=' + this.state.filename} 
                                        playing = {this.state.playing}
                                        controls = {true}
                                        width = '75%'
                                    />
                                }
                            </div>
                            <div className="wrapper-media__playlist">
                                <ul className="list--media-playlist">
                                {preview_data.map(preview => (
                                    <li><span className="title --link" onClick={() => this.startVideo(preview.location, preview.filename)}>{preview.title}</span></li>
                                ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockPreviewVideo);

