import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import * as login from '../../communicator/login';
import TemplateForms from '../../templates/template-forms';

import TextInput from '../../elements/form-input-text';
import RowText from '../form-rows/row-text';
import Checkbox from '../../elements/form-input-checkbox';

const FormRetrievePassword = inject("stores") (
    observer (
        class FormRetrievePassword extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formControls: {          
                        email: {
                            type: "email",
                            value: this.props.email,
                            validationRules: {
                                isRequired: false,
                                isEmail: false
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            valid: false
                        },
                        sms: {
                            label: i18n.t("forms.label.sms"),
                            value: 0,
                            touched: false,
                            validationRules: {},
                            valid: true
                        }
                    },
                    formIsValid: false,
                    btn_class: (this.props.email !== '') ? '--active' : '--disabled',
                    error: false,
                    sms_count: 0
                }
            }

            componentWillUnmount = () => {
                this.storeUser.clearFormFeedback();
            }

            handleCheckboxChange = (event) => {
                const name = 'sms';

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...this.state.formControls[name],
                            value: (parseInt(this.state.formControls[name].value) === 1 || this.state.formControls[name].value === "on") ? 0 : 1,
                            selected: (parseInt(this.state.formControls[name].value) === 1 || this.state.formControls[name].value === "on") ? 0 : 1
                        }
                    }
                    
                });
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUi.clearFormFeedback();

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = async() => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    btn_class: formIsValid ? '--active' : '--disabled',
                    formIsValid: formIsValid
                })
            }

            retrievePassword = (e) => {
                e.preventDefault();
                if (this.state.btn_class === '--active') {
                    if (this.state.formControls.sms.value === 1) {
                        let sms_count = this.state.sms_count + 1;
                        this.setState({
                            sms_count: sms_count,
                            btn_class: '--disabled'
                        })
                    } else {
                        this.setState({
                            btn_class: '--disabled'
                        }) 
                    }
                    this.storeUi.retrievePassword(this.state.formControls, this.state.sms_count);
                }
            } 

            render() {
                return (
                    <TemplateForms
                        type = 'verzoek'
                        msg_success = {i18n.t("form_feedback.success_password")}
                    >
                        <form className="form">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                (key == 'email' && this.state.formControls[key].type === "email") &&
                                    <RowText 
                                        type = {this.state.formControls[key].type}
                                        name = {key}
                                        value = {this.state.formControls[key].value}
                                        validationRules = {this.state.formControls[key].validationRules}
                                        validationMsg = {this.state.formControls[key].validationMsg}
                                        onValidate = {this.validateRow}
                                        onBlur = {this.validateRow}
                                    />
                            ))}
                            <div className="form-row form-row--feedback">
                                Na het versturen van je e-mailadres ontvang je een e-mail met instructies.<br/>
                                <strong>Bij sommige providers kan dit langer duren dan per omgaand.<br/>Kijk ook in je spam-map.</strong>
                            </div>
                            
                            {this.storeUi.checkFeature('SMS_PW') &&
                                <React.Fragment>
                                    <div className="form-row form-row--feedback">
                                        <h4>Of</h4>
                                        <span>Maak gebruik van onze extra service om je wachtwoord per SMS te ontvangen (indien je telefoonnummer bij ons bekend is).</span>
                                    </div>
                                    <div className="form-row form-row--input">
                                        <Checkbox 
                                            name = {'sms'} 
                                            label = {this.state.formControls['sms'].label}
                                            value = {this.state.formControls['sms'].value}
                                            isSelected = {this.state.formControls['sms'].value}
                                            onCheckboxChange={this.handleCheckboxChange}
                                            className = {"--reduced"}
                                        />
                                    </div>
                                </React.Fragment>
                            }

                            {this.state.error &&
                                <div className="form-row form-row--error">
                                    <p>{i18n.t("errors.error_retrieve")}</p>
                                </div>
                            }    
                        </form>
                        <div className="form-row form-row--buttons">
                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.retrievePassword(e)}>{i18n.t("button.submit")}</button>
                        </div>
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormRetrievePassword);
