import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import Template from '../templates/template';

import BlocksBooks from '../components/blocks/blocks-books';

const Languages = inject("stores") (
    observer (
        class Languages extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                }
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = async () => {
                let total;
                let idx
                let params = this.props.match.params;

                if (this.storeUi.active_language !== '') {
                    idx = this.storeUi.list_languages.findIndex(lang => lang.abbr === this.storeUi.active_language);
                } else {
                    idx = this.storeUi.list_languages.findIndex(lang => lang.display_value === this.props.match.params.lang);
                }
                (async() => await this.storeBooks.getBooksForLanguage(this.storeUi.list_languages[idx].query_value, this.storeUi.page_view) )();
                (async() => await this.storeUi.getBookCountForLanguage(this.storeUi.list_languages[idx].query_value) )();

                this.storeUi.setPageView('total', this.storeUi.count_total);
                this.handleScroll();
            }
           
            componentDidUpdate = async() => {
                let total;
                let idx
                let params = this.props.match.params;

                if (this.storeUi.active_language !== '') {
                    idx = this.storeUi.list_languages.findIndex(lang => lang.abbr === this.storeUi.active_language);
                } else {
                    idx = this.storeUi.list_languages.findIndex(lang => lang.display_value === this.props.match.params.lang);
                }
                (async() => await this.storeBooks.getBooksForLanguage(this.storeUi.list_languages[idx].query_value, this.storeUi.page_view) )();
                (async() => await this.storeUi.getBookCountForLanguage(this.storeUi.list_languages[idx].query_value) )();

                this.storeUi.setPageView('total', this.storeUi.count_total);
                this.handleScroll();
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }
      
            render() {
                return (
                    <Template
                        type = {'default'}
                    > 
                        <div className="content-segment">
                            <div className="search-results">
                                <BlocksBooks 
                                    blockTitle = {i18n.t("blocks.languages.header")}
                                    type = "language"
                                    default_view = ""
                                    page = "language"
                                />
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Languages;
