import React from 'react';
import i18n from "i18next";

const ButtonDialogClose = (props) => {

    let btnClass = "btn--icon --link";

    if (props.btnClass && props.btnClass !== '') {
        btnClass = btnClass + ' ' + props.btnClass;
    }
  
    return (
        <div className="btn--close">
            {props.btnText && 
                <span className="--link" onClick={props.onAction}>Terug naar vorige pagina</span>
            }
            <div  
                className = {btnClass} 
                onClick = {props.onAction}
            >
                {props.caption}
            </div>
        </div>
    );
}

export default ButtonDialogClose;