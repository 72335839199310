import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';
import SearchInput from '../../components/search/search-input';

const SearchAdvanced = inject("stores") (
    observer (
        class SearchAdvanced extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount() {
                window.scrollTo(0, 0)
            }
      
            render() {
                return (
                    <Template
                        type = 'default'
                        header = "search"
                    >
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <SearchInput 
                                    position = {"onpage" }
                                />
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default SearchAdvanced;
