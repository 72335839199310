export async function sbFetch(endPoint, method, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndPoint = urlAPI + endPoint;
    let params;

    const controller = new window.AbortController();
    const signal = controller.signal;

    if (method === 'GET' || method === 'DELETE') {
        params = {
            method: method,
            headers: new Headers({
            }),
            signal : signal
        }
    } 

    if (method === 'POST') {
        params = {
            method: method,
            body: JSON.stringify(data),
            headers: new Headers({
            }),
            signal : signal
        }
    } 

    if (method === 'PUT') {
        params = {
            method: method,
            body: JSON.stringify(data),
            headers: new Headers({
            }),
            signal : signal
        }
    } 

    try {      
        let response = await fetch(urlEndPoint, params);
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error: ', urlEndPoint, error.message);
        if (error.name == 'AbortError') {
            console.log('SbC abort error')
        } else {
            throw error;
        }
    }
}

export async function authFetch(endPoint, method, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndPoint = urlAPI + endPoint;
    let params;

    const controller = new window.AbortController();
    const signal = controller.signal;

    const key = process.env.REACT_APP_API_KEY;

    if (method === 'DELETE') {
        params = { 
            method: method
        }

        if (process.env.REACT_APP_ENV !== 'dev') {
            params['headers'] = new Headers({
                Authorization: 'Bearer ' + key
            })
        }
    } 

    if (method === 'GET') {
        params = { 
            method: method,
            signal : signal
        }

        if (process.env.REACT_APP_ENV !== 'dev') {
            params['headers'] = new Headers({
                Authorization: 'Bearer ' + key
            })
        }
    } 

    if (method === 'POST') {
        params = {
            method: method,
            body: JSON.stringify(data),
            signal : signal
        }

        if (process.env.REACT_APP_ENV !== 'dev') {
            params['headers'] = new Headers({
                Authorization: 'Bearer ' + key
            })
        }
    } 

    if (method === 'PUT') {
        params = {
            method: method,
            body: JSON.stringify(data),
            signal : signal
        }

        if (process.env.REACT_APP_ENV !== 'dev') {
            params['headers'] = new Headers({
                Authorization: 'Bearer ' + key
            })
        }
    } 

    try {
        let response = await fetch(urlEndPoint, params);
        let responseJson = await response.json();
        if(responseJson !== null) {
            //let arrData = [];
            let arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC auth fetch error: ', urlEndPoint, method, error.message);
    }
}

export function abortFetch(endPoint, method, data, controller, signal) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndPoint = urlAPI + endPoint;
    
    const promise = new Promise(async (resolve) => {
        try {
            const response = await fetch(urlEndPoint, {
                method: method,
                body: JSON.stringify(data),
                signal
            });
            const data2 = await response.json();
            //assertIsCharacter(data);
            resolve(data2);
        } catch (error) {
            console.log('SbC fetch abort error')
        }
    });

    promise.cancel = () => controller.abort();

    return promise;
}