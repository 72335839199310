import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import i18n from "i18next";

const CartEmpty = inject("stores") (
    observer (
        class CartEmpty extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeUser = this.props.stores.storeUser;
            }

            componentDidMount = () => {
                if (this.props.section == "cart") {
                    this.storeOrder.deleteOrderLineShipping(this.props.orderId);
                }
            }

            continueShopping = (e) => {
                const id = this.storeUser.user_data.is_loggedin 
                    ?   this.storeUser.user_data.user_id 
                    :   0
                ;
                this.storeOrder.setCartView(id, "client");
                this.props.history.push("/");
            }

            render() {
                return (
                    <React.Fragment>
                        {this.props.section == "wishlist" &&
                            <div className="cart">
                                <p>Er staan nog geen boeken op je verlanglijstje.</p>
                            </div>
                        }

                        {this.props.section == "cart" &&
                            <div className="cart">
                                <p>Er staat nog niets in je winkelwagentje</p>
                            </div>
                        }

                        <div className="cart__actions">     
                            <button 
                                className="btn btn--primary" 
                                onClick={(e) => this.continueShopping(e)}
                            >
                                {i18n.t("button.continue_shopping")}
                            </button>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(CartEmpty);
