import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import FormReview from '../forms/form-review';
import BlockReview from './block-review';
import FormTextConditional from '../../elements/form-text-conditional';

const BlockReviews = inject("stores") (
    observer (
        class BlockReviews extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            async componentDidMount() {
            }

            async componentDidUpdate(prevProps) {
            }
  
            render() {
                return (
                    <div className="segment__content  book__reviews">
                        <div className="content-segment">
                            {this.storeBooks.reviewsList && this.storeBooks.reviewsList.length !== 0 &&
                                this.storeBooks.reviewsList.map(review => (
                                    <BlockReview
                                        view = 'review'
                                        review = {review} 
                                    />
                                ))
                            }
                        </div>

                        <div className="content-segment">
                            {this.storeBooks.reviewsList && this.storeBooks.reviewsList.length !== 0 &&
                                <div className="content-segment__header">
                                    <h3>Stuur een recensie op</h3>
                                </div>
                            }
                            <FormTextConditional />
                        </div>

                        <div className="content-wrapper__segment">
                            <FormReview 
                                book = {this.props.book}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockReviews);


