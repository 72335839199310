import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import moment from "moment";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as utilities from '../../utilities/formats';
import AccountCommissionsOverviewInterval from './account-commissions-overview-interval';

const AccountCommissionsPayouts = inject("stores") (
    observer (
        class AccountCommissionsPayouts extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    commission_payouts: []
                }
            }

            componentDidMount = async() => {
                const commissions = await this.storeUser.getCommissions();
                const commission_payouts = await this.storeUser.getPayouts();
              
                this.setState({
                    commissions: commissions,
                    commission_payouts: commission_payouts
                })
            }

            render() {
                const vat_nr = this.storeUser.user_data.profile.nrVAT;
                
                return (
                    <React.Fragment>
                        <div className="content-segment">
                            <div className="content-segment__content">

                                <h2>{i18n.t("commissions.hd_payouts")}</h2>
                                <ul className="table--financial">
                                    <li className="table--financial__header">
                                        <div className="table--financial__cell table--financial__cell--date">{i18n.t("commissions.tbl_hd_date")}</div>
                                        {/* <div className="table--financial__cell table--financial__cell"></div> */}
                                        <div className="table--financial__cell table--financial__cell--title">{i18n.t("commissions.tbl_hd_description")}</div>
                                        <div className="table--financial__cell table--financial__cell"></div>
                                        <div className="table--financial__cell table--financial__cell"></div>
                                        <div className="table--financial__cell table--financial__cell"></div>
                                        <div className="table--financial__cell table--financial__cell--amount">
                                            {vat_nr !== ''
                                                ?   i18n.t("commissions.tbl_hd_price_incl_vat")
                                                :   i18n.t("commissions.tbl_hd_price_excl_vat")
                                            }
                                        </div>
                                    </li>
                                    {this.state.commission_payouts && this.state.commission_payouts.length > 0
                                        ?   <React.Fragment>
                                                {this.state.commission_payouts.map(payout =>
                                                    <React.Fragment>
                                                        <li className="table--financial__header">
                                                            <div className="table--financial__cell table--financial__cell--sku">{payout.payout_date && moment(payout.payout_date.date).format("DD-MM-YYYY")}</div>
                                                            {/* <div className="table--financial__cell table--financial__cell"></div> */}
                                                            <div className="table--financial__cell table--financial__cell--title">{payout.description}</div>
                                                            <div className="table--financial__cell table--financial__cell"></div>
                                                            <div className="table--financial__cell table--financial__cell"></div>
                                                            <div className="table--financial__cell table--financial__cell"></div>
                                                            <div className="table--financial__cell table--financial__cell--amount">{utilities.displayPrice(payout.total)}</div>
                                                        </li>
                                                        {!payout.description.startsWith("Correctie") &&
                                                            <AccountCommissionsOverviewInterval
                                                                commissions = {this.state.commissions}
                                                                payout = {payout}
                                                            />
                                                        }
                                                    </React.Fragment>
                                                )}
                                                <li className="table--financial__totals table--financial__totals--top">
                                                    <div className="table--financial__cell table--financial__cell--label">{i18n.t("commissions.tbl_hd_total_payouts")}</div>
                                                    {/* <div className="table--financial__cell"></div> */}
                                                    <div className="table--financial__cell"></div>
                                                    <div className="table--financial__cell"></div>
                                                    <div className="table--financial__cell"></div>
                                                    <div className="table--financial__cell"></div>
                                                    <div className="table--financial__cell table--financial__cell--amount">{utilities.displayPrice(this.storeUser.payoutsTotalInclVat)}</div>
                                                </li>
                                            </React.Fragment>
                                        :   <li className="table--financial__row">
                                                <div className="table--financial__cell--full">Er zijn nog geen commissies uitgekeerd.</div>
                                            </li>
                                    }
                                </ul>
                            </div> 
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(AccountCommissionsPayouts);


