import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const AuthorName = (props) => {
    let name = props.author.lastname + ', ' + props.author.firstname;
    return name;
}

const SitemapAuthors = inject("stores") (
    observer (
        class SitemapAuthors extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    foldout: false
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            handleOnClickAuthor = (e, id, firstname, lastname) => {
                this.storeGeneral.setActiveShop('SHOP_SB');
                let name = firstname + ' ' + lastname;
                this.storeUi.setCurrentAuthor(name);
                this.storeBooks.getBooksForAuthor(this.storeUi.page_view, name, firstname, lastname);

                let url = "/author/" + name;
                this.props.history.push(url);
            }

            handleOnClickAlphabet  = (e, letter) => {

            }

            expandBlocks = (e) => {
                this.setState(prevState => ({
                    foldout: !prevState.foldout
                }));
            }
  
            render() {
                return (
                    <React.Fragment>
                        {/* <ul className="sitemap__list sitemap__list--alphabet">
                            <li className="alphabet__listitem" onClick={(e) => this.handleOnClickAlphabet(e, 'a')}>A</li>
                            <li className="alphabet__listitem" onClick={(e) => this.handleOnClickAlphabet(e, 'b')}>B</li>
                        </ul> */}
                        <ul className={"sitemap__list sitemap__list--authors " + (this.state.foldout ? "--expanded" : "--collapsed")}>
                            {this.storeBooks.authorsList.map((author, i) => ( 
                                <React.Fragment>
                                    {!this.props.authors_sb && author.is_sb !== 1 &&
                                        <li key={i} className="--link" onClick={(e)=>this.handleOnClickAuthor(e, author.id, author.firstname, author.lastname)}>
                                            <AuthorName 
                                                author = {author}
                                            />
                                        </li>
                                    }
                                    {this.props.authors_sb && author.is_sb === 1 &&
                                        <li key={i} className="--link" onClick={(e)=>this.handleOnClickAuthor(e, author.id, author.firstname, author.lastname)}>
                                            <AuthorName 
                                                author = {author}
                                            />
                                        </li>
                                    }
                                </React.Fragment>
                            ))}
                            <li className="actionShowMore" onClick={(e) => this.expandBlocks(e)}>
                                {this.state.foldout 
                                    ?   i18n.t("lists.show_less_authors")
                                    :   i18n.t("lists.show_more_authors")
                                }
                            </li>
                        </ul>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(SitemapAuthors));


