import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as rdd from "react-device-detect";

import FormLogin from '../components/forms/form-login';
import FormRegister from '../components/forms/form-register';
import FormRetrievePassword from '../components/forms/form-retrieve-password';
import Salutation from '../elements/txt-salutation';

const Login = inject("stores") (
    observer (
        class Login extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    redirect: this.storeUser.user_data.is_loggedin ? true : false,
                    login: true,
                    retrieve: false,
                    email: ''
                }
            }

            componentDidMount = () => {
                window.scrollTo(0, 0);
                this.storeUi.setActiveMainNav(0);
                this.storeUi.setActiveSubNav(0);
            }

            componentWillUnmount = () => {
                this.storeUi.setReturnState("new_user");
            }

            toggleSection = () => {
                this.setState({
                    login: !this.state.login
                })
            }

            onNextAction = () => {
                let url;
                if (this.storeUi.app_interface.use_case === "inorder") {
                    url = "/shoppingcart";
                    this.props.history.push(url);
                } else {
                    this.props.history.goBack();
                }
            }

            rememberEmail = (email) => {
                this.setState({
                    email: email
                })
            }

            toggleRetrievePassword = (e) => {
                this.storeUser.clearFormFeedback();
                this.setState({
                    retrieve: !this.state.retrieve
                })
            }
   
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/"} 
                    />;
                }

                const class_feature = this.storeUi.checkFeature('SHOP_ALL') ? '--feature-all' : ''
                const pos_top = this.storeUi.app_interface.height_header
                
                return (
                    <div 
                        className={"content-wrapper" + ' ' + class_feature}
                        style = {{marginTop: rdd.isMobile ? 0 : pos_top}}
                    >
                        <div className="content-wrapper__middle">
                            <div className="content-wrapper__content">
                                {!this.state.retrieve 
                                ?   <div className="segment__header">{i18n.t("header.login")}</div>
                                :   <div className="segment__header">{i18n.t("header.retrieve")}</div>
                                }
                            </div>
                            <div className="content-wrapper__content">
                                {!this.state.retrieve 
                                ?   <React.Fragment>
                                        <div className={"content-section--login " + (this.state.login ? "--active" : '')}>
                                            <div className="content-section--login__column content-section--login__column--left">
                                                <p className="content-section--login__header">
                                                    <strong>Bestaande klant of reeds gratis e-boeken gedownload?</strong><br/>
                                                    Vul s.v.p. hieronder je gebruikte e-mailadres en wachtwoord in om in te loggen.
                                                </p>
                                                <FormLogin 
                                                    onNext = {this.onNextAction}
                                                    rememberEmail = {this.rememberEmail}
                                                />
                                                <div className="form__alternative-actions">
                                                    <span className="link--read-more" onClick={(e) => this.toggleRetrievePassword(e)}>Ben je je wachtwoord vergeten?</span>         
                                                </div>
                                            </div>
                                            <div className="content-section--login__column content-section--login__column--right">
                                                {this.storeUi.app_interface.return_state !== 'current_user' && (
                                                    <React.Fragment>
                                                        <p className="content-section--login__header">
                                                            <strong>Kennen we je nog niet?</strong><br/>
                                                            <br/>
                                                            {/* <span className="content__textlink">
                                                                <Link to="/register">{i18n.t("pages.login.link_nologin")}</Link>
                                                            </span> */}
                                                            {i18n.t("pages.login.link_nologin")}
                                                        </p>
                                                        <FormRegister  
                                                            form = {'register'}
                                                            want_newsletter = {true} 
                                                            show_subscription = {true}
                                                            onNext = {this.onNextAction}
                                                        />
                                                    </React.Fragment>
                                                )
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>

                                :   <div className="content-section--retrieve">
                                          <FormRetrievePassword 
                                            email = {this.state.email}
                                        />
                                        <div className="form__alternative-actions">
                                            <span className="link--back" onClick={(e) => this.toggleRetrievePassword(e)}>Terug</span>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="content-wrapper__content">
                                <Salutation />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Login));
