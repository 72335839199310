import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import BlockSocialSharing from '../block/block-social-sharing';
import ButtonDialogClose from '../../elements/button-dialog-close';

const DialogNewsletter = inject("stores") (
    observer (
        class DialogNewsletter extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
                this.storeUi.closeDialogNewsletter();
            }

            onMouseEnterSocial = (social) => {
                this.setState({
                    social_tooltip: (social !== '' && social !== undefined) ? social : i18n.t("book_tools.social")
                })
            }

            onClose = () => {
                this.props.history.goBack();
                this.storeUi.closeDialogNewsletter();
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                const url = this.storeUi.app_interface.urlNewsletterOpen;
                const iframe = '<iframe src="'+ url + '" border="0"></iframe>';

                return (
                    <div className="overlay">
                        <div className="dialog dialog--newsletter">
                            
                            <div className="dialog-header">
                                <div className="header-content__payoff">Uitgeverij & de grootste website voor zelfontwikkeling</div>
                                <span>{this.props.subtitle}</span>
                                <BlockSocialSharing 
                                    type = 'newsletter'
                                    url = {url}
                                    onMouseEnter = {this.onMouseEnterSocial}
                                />
                                <ButtonDialogClose
                                    caption = {'Sluit'}
                                    onAction = {() => this.onClose()}
                                    btnClass = {'icon--close'}
                                    btnText = {true}
                                />
                            </div>
                            <div className="dialog-content">
                                <div dangerouslySetInnerHTML={{ __html: iframe }} />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withRouter(DialogNewsletter);
