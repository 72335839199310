import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Video from '../../elements/video';

const ContentDialog = inject("stores") (
    observer (
        class ContentDialog extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    active_video_cc: this.props.active_video_cc
                }
            }

            handleVideoCC = (cc) => {           
                this.setState({
                    active_video_cc: cc
                })
                this.props.handleVideoCC(cc);
            }

            componentDidMount = () => {
                this.storeUi.setActiveVideoLocation('popup');
            }

            componentWillUnmount = () => {
                // console.log('SbC unmounting')
                // this.storeUi.setActiveVideoDialog(false);
                // this.storeUi.setActiveVideoLocation('list');
            }

            setVideoPlaying = (playing) => {
                this.props.setVideoPlaying(playing);
            }

            render() {
                const childrenWithProps = React.Children.map(this.props.children, (child, index) => {
                    return React.cloneElement(child, {
                        location: 'popup',
                        active_video_cc: this.state.active_video_cc
                    });
                });

                return (
                    <React.Fragment>
                        {childrenWithProps}

                        <div 
                            className="block--video__actions" 
                        >
                            {this.props.video.has_cc_file == 1 &&
                                <div 
                                    className = {"block--video__cc --link" + (this.state.active_video_cc == "showing" ? " --active" : "")}
                                    onClick = {(e) => this.handleVideoCC(this.state.active_video_cc == 'showing' ? 'hidden' : 'showing')}
                                >
                                    cc
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

const BlockVideo = inject("stores") (
    observer (
        class BlockVideo extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    active_video_location: 'list',
                    active_video_playing: false,
                    active_video_cc: 'showing',
                    isPlaying: false,
                    isDialogVideoOpen: false
                }
            }

            componentDidUpdate(prevProps) {

            }

            handleClickEnlargeVideo = (video) => {
                this.storeUi.setActiveVideo(this.props.idx + 100);
                this.storeUi.setActiveVideoLocation('popup');
                this.setState({
                    active_video_location: 'popup',
                    isDialogVideoOpen: true
                }, () => this.openDialog());               
            }
            
            // changeCurrentTime(seconds) {
            //     return () => {
            //       const { player } = this.player.getState();
            //       this.player.seek(player.currentTime + seconds);
            //     };
            // }
            
            handleVideoState = (state) => {
                  this.setState({
                    player: state
                })
            }

            handleVideoPlaying = (playing) => {
                this.setState({
                    active_video_playing: playing
                })
            }

            setVideoPlaying = (playing) => {
                this.setState({
                    isPlaying: playing
                })
            }
            
            setDialogVideoOpen = (value) => {
                this.setState({
                    isDialogVideoOpen: value
                })
            }

            handleVideoCC = (cc) => {               
                this.setState({
                    active_video_cc: cc
                })
            }

            openDialog = async() => {
                let isOpen = await this.storeUi.setContentComponent(
                    <ContentDialog
                        className = "content-video"
                        video = {this.props.video}
                        active_video_cc = {this.state.active_video_cc}
                        handleVideoCC = {this.handleVideoCC}
                        setDialogVideoOpen =  {this.setDialogVideoOpen}
                        //setVideoPlaying = {this.setVideoPlaying}
                        location = {'popup'}
                        isPlaying = {this.props.isPlaying}
                    >
                        <Video
                            location = 'popup'
                            idx = {this.props.idx + 100}
                            video = {this.props.video}
                            active_video = {this.storeUi.active_video}
                            active_video_cc = {this.state.active_video_cc}
                            active_video_location = {this.storeUi.active_video_location}
                            current_state = {this.state.player}
                            handleVideoState = {this.handleVideoState}
                            handleVideoPlaying = {this.handleVideoPlaying}
                            handleVideoCC = {this.handleVideoCC}
                            isDialogVideoOpen = {this.state.isDialogVideoOpen}
                            isPlaying = {this.props.isPlaying}
                            setDialogVideoOpen =  {this.setDialogVideoOpen}
                            setVideoPlaying = {this.setVideoPlaying}
                        />
                        
                    </ContentDialog>   
                );

                this.storeUi.openDialogVideo();
                this.setDialogVideoOpen();
            }

            render() {
                return (
                    <div className="block--video">
                        <div className="content-segment__content">
                            <div className="block--video__player">
                                <Video
                                    location = 'list'
                                    idx = {this.props.idx}
                                    video = {this.props.video}
                                    active_video = {this.storeUi.active_video}
                                    active_video_cc = {this.state.active_video_cc}
                                    active_video_location = {this.storeUi.active_video_location}
                                    current_state = {this.state.player}
                                    handleVideoState = {this.handleVideoState}
                                    handleVideoCC = {this.handleVideoCC}
                                    isDialogVideoOpen = {this.state.isDialogVideoOpen}
                                    isPlaying = {this.state.isPlaying}
                                    setDialogVideoOpen =  {this.setDialogVideoOpen}
                                    setVideoPlaying = {this.setVideoPlaying}
                                />
                            </div>
                            
                            <div className="block--video__description">
                                <div className="content-segment__header">
                                    <h2 dangerouslySetInnerHTML={{ __html: this.props.video.title}}/>
                                    <span 
                                        className="content-segment__subtitle" 
                                        dangerouslySetInnerHTML={{ __html: this.props.video.subtitle}}
                                    />

                                    {this.props.video.language === "DE" &&
                                        <div className="content-segment__specs">Duitstalig</div>
                                    }
                                    {this.props.video.language === "FR" &&
                                        <div className="content-segment__specs">Franstalig</div>
                                    }
                                    {this.props.video.language === "EN" &&
                                        <div className="content-segment__specs">Engelstalig</div>
                                    }
                                    {this.props.video.language === "NL" &&
                                        <div className="content-segment__specs">Nederlandstalig</div>
                                    }
                                    {this.props.video.subtitles === "EN" &&
                                        <div className="content-segment__specs">Engels ondertiteld</div>
                                    }
                                    {this.props.video.subtitles === "NL" &&
                                        <div className="content-segment__specs">Nederlands ondertiteld</div>
                                    }
                                </div>
                                <div 
                                    className="block--video__actions" 
                                >
                                    {this.props.video.has_cc_file == 1 &&
                                        <div 
                                            className = {"block--video__cc --link" + (this.state.active_video_cc == 'showing' ? " --active" : "")}
                                            onClick = {(e) => this.handleVideoCC(this.state.active_video_cc == 'showing' ? 'hidden' : 'showing')}
                                        >
                                            cc
                                        </div>
                                    }
                                    <div 
                                        className="block--video__enlarge --link" 
                                        onClick={(e) => this.handleClickEnlargeVideo(this.props.video)}
                                    >
                                        Open
                                    </div>
                                </div>
                                <div className="content-segment__content--description">
                                    <p 
                                        dangerouslySetInnerHTML={{ __html: this.props.video.description}} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockVideo);
