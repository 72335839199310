import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import moment from "moment";

import BookLang from '../../elements/book-lang';

const BlockBookSpecsSummary = inject("stores") (
    observer (
        class BlockBookSpecsSummary extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = () => {
            }

            viewTab = (e, tab) => {
                this.props.viewTab(e, tab);
            }

            render() {
                return (
                    <div className="block block--specs-summary">
                        <ul className="block--specs-summary__list">
                            <li className="block--specs-summary__listitem">
                                <BookLang
                                    book = {this.props.book}
                                />
                            </li>
                            <li className="block--specs-summary__listitem">{this.props.book.version}</li>
                            <li className="block--specs-summary__listitem">ISBN: {this.props.book.sku}</li>
                            {this.props.book.pages > 0 &&
                                <li className="block--specs-summary__listitem">{this.props.book.pages} pagina's</li>
                            }
                        </ul>
                        {this.props.book.release_date &&
                            <ul className="block--specs-summary__list">
                                <li className="block--specs-summary__listitem ">Verschijningsdatum: {moment(this.props.book.release_date.date).format("DD MMMM YYYY")}</li>
                            </ul>
                        }
                        <ul className="block--specs-summary__list block--specs-summary__list--actions">
                            <li>
                                <div 
                                    className="blocks__list--actions blocks__list--action-icons" 
                                >
                                    <div 
                                        className="blocks__list--action"
                                        onClick = {(e) => this.viewTab(e, 'description')}       
                                    >
                                        <span className="link--read-more">Lees meer</span>
                                        <div className="paging-forward-wrapper --down"><div className="paging-forward-wrapper__icon"></div></div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div 
                                    className="blocks__list--actions blocks__list--action-icons" 
                                >
                                    <div 
                                        className="blocks__list--action"
                                        onClick = {(e) => this.viewTab(e, 'reviews')}       
                                    >
                                        {this.props.nrReviews === 0 
                                        ?   <span className="link--read-more">Schrijf een recensie</span>
                                        :   <span className="link--read-more">Lees recensies ({this.props.nrReviews})</span>
                                        }
                                        <div className="paging-forward-wrapper --down"><div className="paging-forward-wrapper__icon"></div></div>
                                    </div>
                                </div>
                            </li>
                            <li className="block--specs-summary__listitem ">
                                <div 
                                    className="blocks__list--actions blocks__list--action-icons" 
                                >
                                    <div 
                                        className="blocks__list--action"
                                        onClick = {(e) => this.viewTab(e, 'specifications')}       
                                    >
                                        <span className="link--read-more">Bekijk alle specificaties</span>
                                        <div className="paging-forward-wrapper --down"><div className="paging-forward-wrapper__icon"></div></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default BlockBookSpecsSummary;

