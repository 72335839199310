import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';

import TemplateForms from '../../templates/template-forms';

import TextArea from '../../elements/form-input-textarea';
import TextInput from '../../elements/form-input-text';
import FormTextIncomplete from '../../elements/form-text-incomplete';

const FormCallMeBack = inject("stores") (
    observer (
        class FormCallMeBack extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formControls: { 
                        firstname: {
                            label: i18n.t("forms.label.firstname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.firstname : '',
                            touched: false,
                            placeholder: i18n.t("forms.placeholder.firstname"),
                            validationRules: {
                                isRequired: true,
                                minLength: 2
                            },
                            required: true 
                        },
                        lastname: {
                            label: i18n.t("forms.label.lastname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.lastname : '',
                            touched: false,
                            placeholder: i18n.t("forms.placeholder.lastname"),
                            validationRules: {
                                isRequired: true,
                                minLength: 2
                            },
                            required: true 
                        },      
                        phonenr: {
                            label: i18n.t("forms.label.phonenr"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.phonenr : '',
                            touched: false,
                            placeholder: i18n.t("forms.placeholder.phonenr"),
                            validationRules: {
                                isRequired: true,
                                minLength: 8
                            },
                            required: true 
                        },  
                        email: {
                            label: i18n.t("forms.label.email"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.email : '',
                            touched: false,
                            placeholder: i18n.t("forms.placeholder.email"),
                            validationRules: {
                                isEmail: true
                            },
                            valid: true
                        },
                        question: {
                            label: i18n.t("forms.label.question"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.question"),
                            valid: true
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false
                }
            }

            componentWillUnmount = () => {
                this.storeUi.clearFormFeedback();
            }

            componentDidMount = () => {
                const updatedControls = {
                    ...this.state.formControls
                };
                let updatedFormElement = {};
                let formIsValid = true;
                
                Object.keys(this.state.formControls).map((key, i) => {
                    updatedFormElement = {
                        ...updatedControls[key]
                    };

                    updatedFormElement.value = this.state.formControls[key].value;
                    if (updatedFormElement.value !== null && updatedFormElement.value !== undefined) {
                        updatedFormElement.valid = validate(updatedFormElement.value, updatedFormElement.validationRules);
                    }
                    updatedControls[key] = updatedFormElement;

                })

                //SbC:: check if form is valid
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                        console.log('SBC call me back::', inputIdentifier,  updatedControls[inputIdentifier].valid, formIsValid)
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            handleChange = event => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
          
                updatedControls[name] = updatedFormElement;

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
                
                this.storeUi.clearFormFeedback();
            }

            sendContactForm = (e) => {
                e.preventDefault();
                this.storeForms.sendFormCallMeBack(this.state.formControls);
            }

            render() {
                return (
                    <TemplateForms
                        type = 'verzoek'
                        msg_success = "We nemen zo spoedig mogelijk contact met je op"
                    >
                        <form className="form form--contact">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                key != 'question' &&
                                    <div className="form-row form-row--input" key={i}>
                                        <div className="form-column">
                                        <label className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}>{this.state.formControls[key].label}</label>
                                        </div>
                                        <div className="form-column">
                                            <TextInput 
                                                name = {key} 
                                                placeholder = {this.state.formControls[key].placeholder}
                                                value = {this.state.formControls[key].value}
                                                touched = {this.state.formControls[key].touched}
                                                onChange = {this.handleChange}
                                                valid = {this.state.formControls[key].valid}
                                            />
                                        </div>
                                    </div>
                            ))}
                            <div className="form-row form-row--input">
                                <div className="form-column">
                                    <label className={"form-label" + (this.state.formControls["question"].required ? ' --required' : '')}>{this.state.formControls['question'].label}</label>
                                </div>
                                <div className="form-column">
                                    <TextArea 
                                        name="question"
                                        placeholder = {this.state.formControls.question.placeholder}
                                        value = {this.state.formControls.question.value}
                                        onChange = {this.handleChange}
                                        valid = {this.state.formControls.question.valid}
                                    />
                                </div>
                            </div>

                            {this.state.btn_class !== '--active' &&
                                <FormTextIncomplete />
                            }
                        </form>

                        <div className="form-row form-row--buttons">
                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.sendContactForm(e)}>{i18n.t("button.submit")}</button>
                        </div>
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormCallMeBack);
