import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const ImgIssuer = inject("stores") (
    observer (
        class ImgIssuer extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_PUBLIC;

                this.state = {
                    errored: false,
                    ext: ".svg"
                }
            }

            handleError = (e) => {
                if (!this.state.errored) {
                    this.setState({
                        errored: true,
                        ext: ".png"
                    })
                } 
            }

            render() {
                return (
                    <img 
                        src = {this.urlAPI + "/public/images/payment/" + this.props.option.code + this.state.ext}
                        onError = {(e) => this.handleError(e)} 
                    />           
                );
            
            }
        }
    )
)

export default ImgIssuer;