import React, { Component } from 'react';
import { runInAction, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import TemplateForms from '../../templates/template-forms';

import validate from '../../utilities/validation-rules';
import TextInput from '../../elements/form-input-text';

const FormUnsubscribe = inject("stores") (
    observer (
        class FormUnsubscribe extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    formControls: {          
                        email: {
                            label: i18n.t("forms.label.email"),
                            value: (this.props.email) 
                                ?   this.props.email 
                                :   (this.storeAuth.user.is_loggedin) 
                                    ?   this.storeUser.user_data.profile.email 
                                    :   '',
                            placeholder: i18n.t("forms.placeholder.email"),
                            touched: false,
                            validationRules: {
                                isRequired: false,
                                isEmail: false
                            },
                            validationMsg: i18n.t("form_errors.email"),
                            required: true
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false
                }
            }

            componentDidMount = () => {             
                const updatedControls = {
                  ...this.state.formControls
                };
                let updatedFormElement = {};

                Object.keys(this.state.formControls).map((key, i) => {
                    updatedFormElement = {
                        ...updatedControls[key]
                    };

                    updatedFormElement.value = this.state.formControls[key].value;
                    if (updatedFormElement.value !== null && updatedFormElement.value !== undefined) {
                        updatedFormElement.valid = validate(updatedFormElement.value, updatedFormElement.validationRules);
                    }
                    updatedControls[key] = updatedFormElement;
                });
       
                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }); 
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
          
                updatedControls[name] = updatedFormElement;

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                }); 

                this.storeUser.clearFormFeedback();
            }

            handleSubmit = (action) => {  
                if (action === 'disable') {
                    this.setState({
                        formIsValid: false,
                        btn_class: '--disabled'
                    }); 
                }
            } 

            // resetForm = () => {
            //     const updatedControls = {
            //         ...this.state.formControls
            //     };
            //     let updatedFormElement = {};

            //     Object.keys(this.state.formControls).map((key, i) => {
            //         updatedFormElement = {
            //             ...updatedControls[key]
            //         };

            //         updatedFormElement.value = '';
            //         updatedControls[key] = updatedFormElement;
            //     })
                
            //     this.setState({
            //         formControls: updatedControls,
            //         formIsValid: false,
            //         btn_class: '--disabled'
            //     })
            // } 

            unsubscribeUser = (e) => {
                e.preventDefault();
                if (this.state.formIsValid) {
                    this.storeUser.unSubscribe(this.state.formControls.email.value);
                    this.storeUser.sendEmailUnSubscribe(this.state.formControls.email.value);
                    //this.resetForm();
                    this.handleSubmit('disable');
                }
            }

            render() {

                return (
                    <TemplateForms
                        type = 'bericht'
                    >
                        <form className="form form--unsubscribe">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                <div className="form-row form-row--input" key={i}>
                                    <label className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}>{this.state.formControls[key].label}</label>
                                    <TextInput 
                                        name = {key} 
                                        placeholder = {this.state.formControls[key].placeholder}
                                        value = {this.state.formControls[key].value}
                                        touched = {this.state.formControls[key].touched}
                                        onChange = {this.handleChange}
                                        valid = {this.state.formControls[key].valid}
                                        msg = {this.state.formControls[key].validationMsg}
                                        is_secret = {this.state.formControls[key].is_secret}
                                        secret = {this.state.formControls[key].secret ? true: false} 
                                        showSecret = {() => this.showSecret(key)}
                                    />
                                </div>
                            ))}
                        </form>

                        <div className="form-row form-row--buttons">    
                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.unsubscribeUser(e)}>{i18n.t("button.unsubscribe")}</button>
                        </div>
                        
                        {this.storeUser.form_feedback &&
                            <div className="form__feedback">
                                {'success' in this.storeUser.form_feedback &&
                                    <span>{i18n.t("form_feedback.success_unsubscribe")}</span>
                                }
                                {'error' in this.storeUser.form_feedback &&
                                    <span>{this.storeUser.form_feedback['error']}</span>
                                }
                            </div>
                        }
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormUnsubscribe);
