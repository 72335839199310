import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as account from '../communicator/account';

class StoreCart {
    cart = {
        anonymous: [],
        user: []
    }
    cartChanges = false;
    cartTotalIncl = 0;

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeOrder = this.storeRoot.storeOrder;

        this.getCart();
    }

    async getCart() {
        let cart = [];
        let orderId = 0;
        let orderlines = [];

        const userId = this.storeAuth.user.is_loggedin ? this.storeAuth.user.user_id : 0;

        if (userId !== 0) {
            cart = await this.retrieveCartDB(userId);
            
            // SbC if additional orderline added
            orderId = await this.retrieveOrderId(userId);
            if (orderId !== 0 && orderId !== '0') {
                orderlines = await this.retrieveOrderlinesDB(userId);
                cart = this.concatCarts(cart, orderlines);
            } 

            this.cart.user = cart;
        } else {
            cart = await this.retrieveCartLS();
            
            this.cart.anonymous = cart;
            console.log('SbC new cart LS',  this.cart.anonymous)
        }
    }

    async retrieveCartLS() {
        if (localStorage.getItem('SB_ORDER')) {
            let data = JSON.parse(localStorage.getItem('SB_ORDER'));
            this.cart.anonymous = data.cart.anonymous;
        }
        this.cart.user = [];
    }

    async retrieveCartDB(id) {
        let cart;

        try {
            const returnData = await account.retrieveShoppingCart(id);
            runInAction(() => {
                cart = returnData.data.cart;
                return cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return cart;
    }

    async retrieveOrderId(id) {
        let orderId = 0;

        try {
            const returnData = await account.retrieveShoppingCart(id);
            runInAction(() => {
                if (returnData.data.order_id !== null) {
                    orderId = returnData.data.order_id;
                }
                return orderId;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return orderId;
    }

    async retrieveOrderlinesDB(orderId) {
        let orderlines;

        try {
            const returnData = await account.retrieveOrderItems(orderId);
            runInAction(() => {
                orderlines = returnData.data;
                return orderlines;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return orderlines;
    }

    concatCarts(cart, orderlines) {
        if (orderlines && orderlines.length > 0) {
            for (let i = 0; i < orderlines.length; i++) {
                let id = orderlines[i].book_id;
                let sku = orderlines[i].sku;
                // let idText = id.toString();
                // if (idText.length < 8) {
                //     sku = await this.getBookISBN(id);
                // } else {
                //     sku = id;
                // }

                let idx = cart
                    .findIndex((item) => item.sku === sku);
                    
                if (idx === -1) {
                    orderlines[i].status = 'added';
                    cart.push(orderlines[i]);
                    //cartDb[idx].amount =  orderlines[i].amount;
                    //cartDb[idx].discount =  orderlines[i].discount;

                    //onlineCart[idx].amount =  orderlines[i].amount;
                    //onlineCart[idx].discount =  orderlines[i].discount; 
                }
            }
        }
    }

}

decorate(StoreCart, {
    cart: observable,
    getCart: action,
    retrieveCartDB: action,
    retrieveCartLS: action,
    retrieveOrderIdDirect: action
})

export default StoreCart;