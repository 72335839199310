import React from 'react';

export default class FormInputFile extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            files: []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reset !== this.props.reset && this.props.reset == true) {
            this.handleReset();
        }
    }

    handleChange = (e) => {
        let files = e.target.files;
        var allFiles = [];
        for (var i = 0; i < files.length; i++) {
            let file = files[i];

            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file
                };

                allFiles.push(fileInfo);

                if (allFiles.length == files.length) {
                    if (this.props.multiple) {
                        this.props.onDone(allFiles);
                    } else {
                        this.props.onDone(allFiles[0]);
                    }
                }
            }
        }
    }

    handleReset = () => {
        let fileInfo = {};
        this.props.onDone(fileInfo);
        this.refs.file_ref.value = '';
    }

    render() {
        return (
            <input
                type = "file"
                onChange = {this.handleChange}
                multiple = {this.props.multiple}
                ref = "file_ref"
            />
        );
    }
}

FormInputFile.defaultProps = {
    multiple: false
};
