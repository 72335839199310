import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import * as rdd from "react-device-detect";

import DialogNavFoldout from '../components/dialogs/dialog-nav-foldout';

const NavigationToggleListitem = inject("stores") (
    observer (
        class NavigationToggleListitem extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;

                this.state = {
                }
            }

            componentDidMount = async() => {
            }

            hideTooltip = async() => {
                this.storeUi.showTooltip('tip', 0);
            }

            showTooltip = async() => {
                const tooltipTitle = this.storeUi.toggle_nav[this.props.id - 1].title;
                const tooltipText = this.storeUi.toggle_nav[this.props.id - 1].text;
                this.storeUi.showTooltip('tip', this.props.id);

                this.setState({
                    title: tooltipTitle,
                    text: tooltipText
                })
            }

            handleTooltip = async(e) => {
                e.stopPropagation();

                const tooltipTitle = this.storeUi.toggle_nav[this.props.id - 1].title;
                const tooltipText = this.storeUi.toggle_nav[this.props.id - 1].text;

                if (this.storeUi.toggle_nav[this.props.id - 1].showTip) {
                    this.hideTooltip();
                } else {
                    this.storeUi.showTooltip('tip', this.props.id);
                }

                this.setState({
                    title: tooltipTitle,
                    text: tooltipText
                })
            }

            render() {  
                const activeClass = (this.storeUi.active_togglenav === this.props.item.id) ? ' --active' : '';

                return (
                    <React.Fragment>
                        <li 
                            className={"navigation--toggle__listitem" + activeClass}
                            onClick={(e) => this.props.handleNavClick(this.props.item.id, this.props.item.path)}
                        >
                                <span 
                                    dangerouslySetInnerHTML={{ __html: this.props.title}}
                                />
                                <span 
                                    dangerouslySetInnerHTML={{ __html: this.props.subtitle}}
                                />

                                {!rdd.isMobile && 
                                    <div 
                                        className = "content__tooltip --link" 
                                        onMouseOver = {this.showTooltip}
                                        onMouseOut = {this.hideTooltip}
                                    >
                                        i
                                    </div>
                                }
                                {rdd.isMobile && 
                                    <div 
                                        className = "content__tooltip --link" 
                                        onClick = {async(e) => this.handleTooltip(e)}
                                    >
                                        i
                                    </div>
                                }
                            
                            {/* <DialogTooltipFoldout
                                id = {this.props.item.id}
                                sku = {this.props.item.tooltip}
                            /> */}
                        
                    
                        {/* {!rdd.isMobile && 
                            <div 
                                className = "content__tooltip --link" 
                                onMouseOver = {this.showTooltip}
                                onMouseOut = {this.hideTooltip}
                            >
                                i
                            </div>
                        }
                        {rdd.isMobile && 
                            <div 
                                className = "content__tooltip --link" 
                                onClick = {async(e) => this.handleTooltip(e)}
                            >
                                i
                            </div>
                        } */}
                        </li>

                        <DialogNavFoldout
                            type = 'tip'
                            id = {this.props.id}
                            title = {this.state.title}
                            description = {this.state.text}
                            mobile = {rdd.isMobile ? true : false}
                        />
                    </React.Fragment>
                )
            }
        }
    )
)

export default NavigationToggleListitem;
