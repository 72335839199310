import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';

const LinkDownloadDoorbreek = inject("stores") (
    observer (
        class LinkDownloadDoorbreek extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }

            componentDidMount = () => {
                window.scrollTo(0, 0)
            }
      
            render() {
                return (
                    <Template
                        type = {'default'}
                        header = {"link_download_doorbreek"}
                        subtitle = {"(je kunt de Mp3 bestanden onderin deze pagina downloaden)"}
                    >
                        <div className="content-segment">
                            <p>Op deze <strong>dubbele</strong> audio MP3 staan de meditaties die behoren bij het boek <i>Doorbreek de gewoonte jezelf te zijn</i> (ISBN 9789492665430, uitgegeven door Succesboeken.nl).</p>
                            <p>De eerste MP3 bevat 3.00 min introductie van dr. Joe Dispenza en daarna volgen de meditaties <i>Lichaamsdeel</i> – totale duur 1 u. 18 min.
                            <br/>
                            Op de tweede MP3 staan de meditaties <i>Waterstijging</i> – 1 u. 10 min.</p>
                            <p>Deze mp3-set bevat een vierweekse geleide meditatie om je te helpen bij het mentaal oefenen van het stappenplan dat beschreven staat in het boek van dr. Joe Dispenza <i>Doorbreek de gewoonte jezelf te zijn</i>. Deze mp3's zijn bedoeld om je van een bèta-hersengolffrequentie (de analytische geest) naar een alfa-staat te brengen en om coherente hersengolfpatronen te ontwikkelen en te handhaven.</p>
                            <p>Dr. Joe geeft instructies om je aandacht tijdens deze meditatie op verschillende delen van je lichaam te richten <i>in de ruimte</i> en <i>de ruimte rondom je lichaam</i>. Hierdoor verandert je brein en krijg je toegang tot het besturingssysteem van je onderbewuste geest - waar ongewenste gewoonten en programma’s liggen opgeslagen. Het doel is dat je leert hoe je in het nu kunt blijven en de kunst van het mediteren onder de knie krijgt. Als je je hersengolven eenmaal op de juiste manier hebt veranderd en diep in je onderbewustzijn bent binnengedrongen, word je persoonlijk begeleid voorbij je <i>oude zelf</i> en ga je een <i>nieuw zelf</i> en een nieuw leven creëren!</p>
                            <p>Over de auteur: Dr. Joe Dispenza heeft een passie voor het onderwijzen van anderen hoe de laatste ontdekkingen toe te passen uit de neurowetenschappen en kwantumfysica om hun brein opnieuw te programmeren, ziekte te genezen en een meer vervuld leven te leiden. Als een van de experts uit de film What the BLEEP Do We (K)now!? geeft dr. Joe workshops en lezingen over de hele wereld. Hij heeft ook een programma ontworpen waarmee hij zijn model van transformatie naar het bedrijfsleven brengt.</p>
                            <br/>
                            <p><a href="https://www.succesboeken.nl/api/public/downloads/mp3/Meditaties-Dispenza-Doorbreek-cd1.mp3">Klik hier met rechter muisknop en kies opslaan om de introductie en de eerste meditatie-mp3 te downloaden: Lichaamsdeel.</a></p>
                            <p><a href="https://www.succesboeken.nl/api/public/downloads/mp3/Meditaties-Dispenza-Doorbreek-cd2.mp3">Klik hier met rechter muisknop en kies opslaan om de tweede meditatie-mp3 te downloaden: Waterstijging.</a></p>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(LinkDownloadDoorbreek);
