import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as ui from '../utilities/ui';
import Template from '../templates/template';

import FormRegister from '../components/forms/form-register';
import BlocksEBooks from '../components/blocks/blocks-ebooks';

const EBooksAll = inject("stores") (
    observer (
        class EBooksAll extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount() {
                ui.handleScroll('top');
                this.storeUi.setActiveToggleNav(2);
            }

            componentDidUpdate() {
                //ui.handleScroll('top');
            }
      
            render() {
                return (
                    <Template
                        type = 'default'
                        header = {!this.storeAuth.user.is_loggedin ? "ebooks_all" : "ebooks"}
                        nav_name = {"ebooks"}
                    >
                        <div className="content-wrapper__segment">
                            <div className="content-segment">
                                <div className="content-segment__content content-segment__content--intro">
                                {!this.storeAuth.user.is_loggedin &&
                                    <p>{i18n.t("pages.ebooks.intro")}</p>
                                }
                                    <p>{i18n.t("pages.ebooks.intro2")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper__segment">
                            {!this.storeAuth.user.is_loggedin &&
                                <FormRegister 
                                    form = {'ebooks'}
                                    want_newsletter = {true} 
                                    show_subscription = {false}
                                />
                            }
                        </div>
                        <div className="content-wrapper__segment">
                            <BlocksEBooks />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(EBooksAll);
