import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as ui from '../utilities/ui';

const ButtonPagingBack = inject("stores") (
    observer (
        class ButtonPagingBack extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    active: true,
                    btnClass: "--active"
                }
            }

            componentDidMount = () => {
            }

            componentDidUpdate = (prevProps) => {
            }

            pagingBack = (btnClass) => {
                this.setPage(btnClass);
            }

            setPage = (btnClass) => {
                let from;
                let to;

                if (btnClass === '--active') {
                    const page = this.props.page - 1;

                    if (this.storeUi.page_view.rangeFrom !== 1) {
                        from = this.storeUi.page_view.rangeFrom - 1;
                        to = this.storeUi.page_view.rangeTo - 1;
                    } else {
                        from = this.storeUi.page_view.rangeFrom;
                        to = this.storeUi.page_view.rangeTo;
                    }

                    this.storeUi.setPageView('page', page);
                    this.storeUi.setPageView('rangeFrom', from);
                    this.storeUi.setPageView('rangeTo', to);

                    this.props.setPage();
                }

                ui.handleScroll('top');
                this.storeUi.setFreezeScrolling(false);
            }

            render() {  
                const btnClass = this.props.page === 1
                        ?   '--inactive'
                        :   '--active'

                return (
                    <div 
                        className = {"paging-back-wrapper " + btnClass} 
                        onClick = {(e) => this.pagingBack(btnClass)}
                    >
                        <div className="paging-back-wrapper__icon" />
                    </div>
                )
            }
        }
    )
)

export default withRouter(ButtonPagingBack);
