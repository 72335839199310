import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18n from "i18next";

const AuthorName = (props) => {
    let name = props.author.lastname + ', ' + props.author.firstname;
    return name;
}

const BlocksAuthors = inject("stores") (
    observer (
        class BlocksAuthors extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirect: false,
                    foldout: false
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            handleOnClick = (e, id) => {
                this.storeUi.setCurrentAuthor(id);
                this.storeBooks.getBooksForAuthor(this.storeUi.page_view, id);

                this.setState({
                    redirect: true,
                    author_id: id
                });
            }

            expandBlocks = (e) => {
                this.setState(prevState => ({
                    foldout: !prevState.foldout
                }));
            }
  
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/author/" + this.state.author_id} 
                    />;
                }

                const blockCat = (this.props.blockCat) ? this.props.blockCat : '';
                const blockTitle = (this.props.blockTitle) ? this.props.blockTitle : 'Auteurs';

                return (
                    <div className="blocks blocks--authors">
                        <div className="blocks__blocks-title">
                            <span className="blocks__blocks-title--main">{blockTitle}</span>
                        </div>
                        <div className="blocks__content">
                            <ul className={"blocks__list " + (this.state.foldout ? "--expanded" : "--collapsed")}>
                            {this.storeBooks.authorsList.map((author) => ( 
                                author.is_sb === 1 &&
                                    <li className="block block--author">
                                        <div 
                                            className="block--category__title" 
                                            onClick={(e)=>this.handleOnClick(e, author.id)}>
                                                <AuthorName
                                                    author = {author}
                                                />
                                        </div>
                                    </li>
                            ))}
                                <li className="block block--action actionShowMore" onClick={(e) => this.expandBlocks(e)}>
                                    {this.state.foldout 
                                        ? "Toon minder auteurs"
                                        : "Toon alle auteurs"
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default BlocksAuthors;

