import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const SearchIntentions = inject("stores") (
    observer (
        class SearchIntentions extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            searchIntention = (searchTerm) => {
                this.storeUi.setSearchValue(searchTerm);
            } 

            render() {
                return (
                    <React.Fragment>
                        {this.storeUi.search_intentions.length > 0
                        ?   <div className="search-intentions">
                                <div className="search-intentions__header">Of zocht je naar:</div>
                                <div className="search-intentions__results">
                                    <ul className="search-intentions__list">
                                        {this.storeUi.search_intentions.map((term) => (
                                            <li 
                                                className="search-intentions__listitem" 
                                                onClick={(e) => this.searchIntention(term.intention)}
                                            >
                                                {term.intention}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        :   null
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default SearchIntentions;
