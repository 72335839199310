import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';

const ContentDialog = inject("stores") (
    observer (
        class ContentDialog extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            render() {
                return (
                    this.props.children
                )
            }
        }
    )
)

const OwnBook = inject("stores") (
    observer (
        class OwnBook extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount() {
                window.scrollTo(0, 0);
                this.storeUi.setActiveMainNav(2);
            }

            openDialogSpecs = () => {
                this.storeUi.setContentComponent(
                    <ContentDialog
                        className = "content-scrollable"
                    >
                        <img src={this.urlAPI + "/public/images/content/opmaakspec_manuscript.jpg"} className="full-size"/>
                    </ContentDialog>
                )
                this.storeUi.openDialogContent();
            }
      
            render() {
                return (
                    <Template
                        type = {'default'}
                        header = {"own_book"}
                    >
                        <div className="content-segment">
                            <ContentText
                                page = {"own-book"}
                                dialog = {<ContentDialog
                                        className = "content-scrollable"
                                    >
                                        <img src={this.urlAPI + "/public/images/content/opmaakspec_manuscript.jpg"} className="full-size"/>
                                    </ContentDialog>
                                }
                                dialog_linktext = "Bekijk hier meer specificaties"
                                dialog_position = {2} 
                            />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default OwnBook;
