import * as apifetch from './fetch.js';

export async function getSettings() {
    const urlEndpoint = '/settings/getSettings.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getFeatures() {
    const urlEndpoint = '/settings/getFeatures.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getLanguages() {
    const urlEndpoint = '/settings/getLanguages.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}
