import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";
import queryString from 'query-string';
import * as rdd from "react-device-detect";

import countries from "i18n-iso-countries";
import * as account from '../communicator/account';
import * as sort from '../utilities/sort';
import * as books from '../communicator/books';
import * as monitoring from '../communicator/monitoring';
import * as payment from '../communicator/payment';
import * as order from '../communicator/order';
import * as orders from '../communicator/orders';
import * as settings from '../communicator/settings'
import * as tools from '../communicator/tools'

function autoSave(store, save) {
    mobx.autorun(() => {
        const json = JSON.stringify(mobx.toJS(store));
        save(json);
    });
}

class StoreOrder {
    country_default = 154;
    country_code_default = 'NL';
    country_default_displayValue = 'Nederland';

    order_settings = {
        shipping_free: 20,
        shipping_free_resellers: 250,
        resellers_discount_default: 30,
        no_shipping_costs: 0,
        custom_shipping_costs: 0,
        discounts_overruled: 0
    }

    order_data = {
        order_id: 0,
        order_guid: '',
        affiliate_id: 0,
        client_id: 0,
        client_settings: {
            is_partner: false,
            is_reseller: false,
            is_business: false,
            has_delivery_address: false,
        },
        client_profile: {
            firstname: '',
            lastname: '',
            email: '',
            phonenr: '',
            address: '',
            housenr: '',
            addition: '',
            zipcode: '',
            city: '',
            country: this.country_default_displayValue,
            country_select: this.country_default,
            country_code: this.country_code_default
        },
        billing_address: {
            address: '',
            housenr: '',
            addition: '',
            zipcode: '',
            city: '',
            country: this.country_default,
            country_select: this.country_default,
            country_code: this.country_code_default
        },
        delivery_address: { 
            delivery_name: '',
            delivery_company: '',
            address: '',
            housenr: '',
            addition: '',
            zipcode: '',
            city: '',
            country: this.country_default,
            country_select: this.country_default,
            country_code: this.country_code_default
        },
        company_profile: {
            name: '',
            nrKvK: 0,
            nrVAT: 0,
            validVAT: false
        },
        shopping_cart: {
            cart_total_incl: 0,
            vat_total: 0
        },
        shipping_costs: 0,
        vat_shipping_perc: 0,
        vat_shipping: 0,
        order_total_incl: 0,
        payment: {
            payment_method: {
                id: 'XX',
                title: 'IDEAL'
            },
            payment_issuer: {
                id: 99,
                code: '9999',
                title: ''  
            }
        },
        order_remark: '',
        order_status: {
            url: '',
            handling: '',
            payment_status: 'pending'
        }
    }

    order_interface = {
        cart_view: "client",
        error_window: false,
        payment_window: false,
        isDialogCompareCartsOpen: false,
        isDialogCompareWishListsOpen: false,
        isDialogConcatOpen: false,
        loader: false
    }

    cart = {
        anonymous: [],
        user: []
    }
    cartChanges = false;
    cartTotalIncl = 0;


    discounts_overruled = 0;
    lastAddedBook = {};
    orderHistoryList = []
    payment_issuers = []
    payment_status = 'pending'
    shopping_cart_db = []
    shopping_cart_db_id = 0
    shopping_cart_db_guid = ''
    shippingCosts = 0
    wishList = []
    wishList_db = []
    countriesList = []
    partner_code = {
        code: '',
        last_date: ''
    }
    settings_list = []

    constructor(storeRoot) {
        //this.storeRoot = storeRoot;
        //this.storeGeneral = this.storeRoot.storeGeneral;

        this.getOrderDataFromLS();
        autoSave(this, this.save.bind(this));
        this.getPaymentIssuers();
        this.getSettings();
        this.getCountries();
        this.setCountryDefault();
    }

    async getSettings() {
        let settingsList;
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {
                settingsList = returnData.data;
                settingsList.map(setting => {
                    this.settings_list[setting.SettingName] = setting.SettingValue;
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    save(json) {
        this.saveToLS(json);
    }

    saveToLS(json) {
        localStorage.setItem('SB_ORDER', json)
    }

    openDialogConcat = (url) => {
        this.order_interface.isDialogConcatOpen = true;
    }

    openDialogCompareCarts = (url) => {
        this.order_interface.isDialogCompareCartsOpen = true;
    }

    openDialogCompareWishLists = () => {
        this.order_interface.isDialogCompareWishListsOpen = true;
    }

    closeDialogConcat = () => {
        this.order_interface.isDialogConcatOpen = false;
    }

    closeDialogCompareCarts = () => {
        this.order_interface.isDialogCompareCartsOpen = false;
    }

    closeDialogCompareWishLists = () => {
        this.order_interface.isDialogCompareWishListsOpen = false;
    }

    startLoader() {
        this.order_interface.loader = true;
    }

    stopLoader() {
        this.order_interface.loader = false;
    }

    getOrderDataFromLS() {
        let data;
        
        if (localStorage.getItem('SB_ORDER')) {
            data = JSON.parse(localStorage.getItem('SB_ORDER'));
            this.order_data = data.order_data;
            this.order_settings = data.order_settings;

            if ('cart' in data) {
                this.cart.anonymous = data.cart.anonymous;
                this.cart.user = data.cart.user;
            } else {
                this.cart.anonymous = [];
                this.cart.user = [];
            }
        } 
    }

    async checkDiscountsOverruled() {
        let discount_overruled = 0;
        try {
            const returnData = await order.checkDiscountsOverruled(this.order_data.order_id);
            runInAction(() => {
                discount_overruled = returnData.data;
                
                if (returnData.data[0].discounts_overruled === 1 || returnData.data[0].discounts_overruled === '1') {
                    this.setOverruledDiscountsInCart();
                } 
                if (returnData.data[0].discounts_overruled == null) {
                    this.discounts_overruled = 0;
                    this.order_settings.discounts_overruled = 0;
                } else {
                    this.discounts_overruled = parseInt(returnData.data[0].discounts_overruled);
                    this.order_settings.discounts_overruled = parseInt(returnData.data[0].discounts_overruled);
                }
                return discount_overruled;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return discount_overruled;
    }

    async checkShippingCostsOverruled() {
        let no_shipping_costs;
        try {
            const returnData = await order.checkShippingCostsOverruled(this.order_data.order_id);
            runInAction(() => {
                no_shipping_costs = returnData.data;
                this.order_settings.no_shipping_costs = returnData.data[0].no_shipping_costs;
                this.no_shipping_costs = returnData.data[0].no_shipping_costs;
                return no_shipping_costs;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return no_shipping_costs;
    }

    async checkShippingCostsCustom() {
        let custom_shipping_costs;
        try {
            const returnData = await order.checkShippingCostsCustom(this.order_data.order_id);
            runInAction(() => {
                custom_shipping_costs = returnData.data;
                this.order_settings.custom_shipping_costs = returnData.data[0].custom_shipping_costs;
                this.custom_shipping_costs = returnData.data[0].custom_shipping_costs;
                return custom_shipping_costs;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return custom_shipping_costs;
    }

    setDiscountOverruled(book_id, book_sku, discount) {
        for (let i = 0; i < this.cart.user.length; i++) {
            if (book_sku === this.cart.user[i].sku) {
                this.cart.user[i].discount = discount;
            }
        } 
    }

    async setOverruledDiscountsInCart() {
        let order_lines = await this.getOrderLinesData(this.order_data.order_id);

        //Sbc vs20
        for (let i = 0; i < this.cart.user.length; i++) {
            for (let ii = 0; ii < order_lines.length; ii++) {
                if (parseInt(order_lines[ii].book_id) === this.cart.user[i].id) {
                    this.cart.user[i].discount = order_lines[ii].discount;
                    this.cart.user[i].discount_active = 1;
                }
            }
        }

        //SbC TODO
        if (this.order_data.client_id !== 0) {
            //this.saveShoppingCartToDB(this.order_data.client_id, this.order_data.shopping_cart['items']);
            this.saveShoppingCartToDB(this.order_data.client_id, this.cart.user);
        }
    }

    closeErrorWindow = () => {
        this.order_interface.error_window = false;
    }

    closePaymentWindow = () => {
        this.order_interface.payment_window = false;
    }

    clearPartnerCode() {
        this.partner_code.code = '';
        this.partner_code.last_date = '';
    }

    setPartnerCode(searchString) {
        const pc = this.partner_code.code.trim();
        const params = queryString.parse(searchString);

        if (pc !== params.pc && params.pc !== undefined) {
            this.partner_code.code = params.pc.trim();
            this.partner_code.last_date = new Date();
        }
    }

    async setCountryDefault() {
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {
                let settings_list = returnData.data;
                this.country_default = parseInt(settings_list[settings_list.findIndex(setting => setting.SettingName === 'defaultCountry')].SettingValue);
                this.country_code_default = settings_list[settings_list.findIndex(setting => setting.SettingName === 'defaultCountryCode')].SettingValue;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getCountries() {
        try {
            const returnData = await tools.getCountries();
            runInAction(() => {
                this.countriesList = returnData.data;
                this.translateCountries();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    translateCountries() {
        //SbC:: get country translations
        countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

        this.countriesList.map((country, i) => {
            //SbC missing values for AN and CS
            switch (country.code) {
                case 'AN':  
                    this.countriesList[i].displayValue = 'Nederlandse Antillen';
                    break;
                case 'CS':  
                    this.countriesList[i].displayValue = 'Servie en Montenegro';
                    break;
                default:    
                    this.countriesList[i].displayValue = countries.getName(country.code, "nl");
            }
        })

        this.countriesList = this.countriesList
            .sort(sort.compareValues('displayValue', 'asc'))
    }

    async getOrderAvailability() {
        let order_availability;
        let order_id = this.order_data.order_id;
        try {
            const returnData = await order.getOrderAvailability(order_id);
            runInAction(() => {
                order_availability = returnData.data;
                return order_availability;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return order_availability;
    }

    async createNewOrder(user_id, remark) {
        let order_id;
        let partner_code = this.partner_code.code;

        try {
            const new_order = await order.createNewOrder(user_id, partner_code);
            runInAction(() => {
                order_id = new_order.data[0].id;
                this.order_data.order_id = new_order.data[0].id;
                this.order_data.order_guid = new_order.data[0].guid;
                //SbC creates double orderlines!
                //this.addOrderItems(user_id);
                this.setOrderRemark(new_order.data[0].id, remark);

                //SbC DEPRICATED
                //this.saveShoppingCartToDB(user_id, this.cart.user);
                //(async() => await this.saveShoppingCartToDB(user_id, this.cart.user))();

                this.addOrderLineShipping();

                return order_id;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return order_id;
    }

    async editOrder(user_id, remark) {
        //SbC check if causes double orderline after login as anonymous -> think not
        (async() => await this.editOrderItems(user_id))();
        this.setOrderRemark(this.order_data.order_id, remark);

        if (user_id !== undefined && user_id !== 0) {
            this.saveShoppingCartToDB(user_id, this.cart.user)
        }
    }

    addOrderItems(userId) {
        // SbC DEPRECATED
        // if (userId === 0) {
        //     //console.log('SbC add anon')
        //     this.cart.anonymous.map((item) => {
        //         this.addOrderLine(item, 'add');
        //     })
        // } else {
        //     //console.log('SbC add user')
        //     this.cart.user.map((item) => {
        //         this.addOrderLine(item, 'add');
        //     })
        // }      
    }

    async editOrderItems(id) {
        let items;
        if (id !== undefined && id !== 0 && id !== '0') {
            items = this.cart.user;
        } else {
            items = this.cart.anonymous;
        }

        items.map(async (item) => {
           const edit_order_item = await this.addOrderLine(item, 'edit');
        })

        //const returnData = await this.editOrderLineShipping();
    }

    async saveOrderDataProfile() {
        let returnData;
        // try {
        //     const returnData = await order.saveOrderDataProfile(this.order_data.order_id, this.order_data);
        //     runInAction(async() => {
        //         const returnData = await this.editOrderLineShipping();
        //     })
        // } catch (error) {
        //     runInAction(() => {
        //         this.state = "error"
        //     })
        // }
        //returnData = await order.saveOrderDataProfile(this.order_data.order_id, this.order_data);
        //returnData = await this.editOrderLineShipping();

        try {
            const returnData = await order.saveOrderDataProfile(this.order_data.order_id, this.order_data);
            runInAction(async() => {
                //const returnData = await this.editOrderLineShipping();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async addOrderLine(item, action) {
        let book_id;
        let new_order_item;
              
        const userId = this.order_data.client_id;
        const orderId = await this.returnOrderId(userId);

        if (item) {
            if (item.book_id === null || item.book_id === undefined) {
                if (item.id === null || item.id === undefined) {
                    //book_id = item.sku;
                    book_id = item.idx;
                } else {
                    book_id = item.id;
                }
            } else {
                book_id = parseInt(item.book_id);
            }
        
            const order_line = {
                shop: item.shop,
                //order_id: this.order_data.order_id,
                book_id: book_id,
                sku: item.sku,
                description: item.description,
                amount: item.amount,
                weight: item.weight,
                price: item.price,
                discount: item.discount,
                //discount: 12,
                vat: item.vat_perc
            }       

            if (item.amount !== 0 && item.amount !== '0' && this.order_data.order_id !== 0 && this.order_data.order_id !== 99 && this.order_data.order_id !== '99') {
                if (action === 'add') {
                    new_order_item = await order.addOrderLine(order_line, this.order_data.order_id, action);

                    this.addOrderLineShipping();
                }
                if (action === 'edit') {
                    if (this.order_data.order_id !== 0) {
                        let orderlinesDirect = await this.retrieveOrderlinesDirect(this.order_data.client_id);
                        let idx = orderlinesDirect.findIndex(item => book_id.toString() === order_line.book_id.toString());
                        if (idx === -1) {
                            //SbC DEPRICATED??
                            //new_order_item = await order.addOrderLine(order_line, this.order_data.order_id, 'add');
                        } else {
                            new_order_item = await order.editOrderLine(order_line, this.order_data.order_id, 'edit');
                        }
                    } else {
                        //new_order_item = await order.editOrderLine(order_line, this.order_data.order_id, action);
                    }
                }
                if (action === 'replace') {
                    if (this.order_data.order_id !== 0) {
                        let orderlinesDirect = await this.retrieveOrderlinesDirect(this.order_data.client_id);
                        let idx = orderlinesDirect.findIndex(item => book_id.toString() === order_line.book_id.toString());
                        //if (idx === -1) {
                            // console.log('SbC add 2', action, order_line)
                            // new_order_item = await order.addOrderLine(order_line, this.order_data.order_id, 'add');
                        // } else {
                            new_order_item = await order.editOrderLine(order_line, this.order_data.order_id, 'edit');
                        // }
                    } else {
                        //new_order_item = await order.editOrderLine(order_line, this.order_data.order_id, action);
                    }
                }
            }
        }
    }

    async deleteOrderLine(item, action) {
        let bookId;
        
        if ('id' in item) {
            bookId = item.id;
        } else {
            bookId = item.book_id;
        }
        const order_line = {
            order_id: this.order_data.order_id,
            book_id: bookId,
            sku: item.sku
        }

        const returnData = await order.handleOrderItem(order_line, action);
    }

    /**
     * SbC only when creating new order
     */
    async addOrderLineShipping() {
        const action = 'add';
        const order_line = {
            order_id: this.order_data.order_id,
            price: this.order_data.shipping_costs,
            vat: this.order_data.vat_shipping_perc
        }

        //const returnData = await orders.handleOrderLineShipping(order_line, this.order_data.order_id);
        const returnData = await orders.addOrderLineShipping(order_line, this.order_data.order_id);
    }

    async editOrderLineShipping() {
        const action = 'edit';
        const order_line = {
            order_id: this.order_data.order_id,
            price:  this.order_data.shipping_costs,
            vat: this.order_data.vat_shipping_perc
        };

        if (this.order_data.order_id !== 0 && this.order_data.order_id !== "0") {
            //const returnData = await orders.handleOrderLineShipping(order_line, this.order_data.order_id);
            const returnData = await orders.editOrderLineShipping(order_line, this.order_data.order_id);
        }
    }

    saveRowPrice(loggedin, book_id, sku, total, discount) {
        let items;
        if (loggedin) {
            items = this.cart.user;
        } else {
            items = this.cart.anonymous;
        }
        const idx = items.findIndex(item => item.sku === sku);
        if (idx !== -1) {
            items[idx].product_total_incl = total;
            //items[idx].discount = discount;
        }

        this.calculateTotalCart(loggedin);
    }

    calculateTotalCart(loggedin) {
        let items;
        if (loggedin) {
            items = this.cart.user;
        } else {
            items = this.cart.anonymous;
        }

        let totalCart = 0;
        items.map((product, i) => {
            if (product.status !== 'deletedx' && product.amount > 0 && product.sku !== 0 && product.sku !== undefined) {
                totalCart = totalCart + parseFloat(product.product_total_incl);
            }
        })

        this.order_data.shopping_cart.cart_total_incl = parseFloat(totalCart).toFixed(2);
        this.cartTotalIncl = parseFloat(totalCart).toFixed(2);
    }

    get calculateTotalOrder() {
        let totalOrder = 0;

        totalOrder = parseFloat(this.cartTotalIncl) + parseFloat(this.order_data.shipping_costs);
        totalOrder = parseFloat(totalOrder).toFixed(2);

        return totalOrder;
    }

    calculateOrderVAT(loggedin, perc) {
        let items;
        if (loggedin) {
            items = this.cart.user;
        } else {
            items = this.cart.anonymous;
        }

        let vat_total = 0;
        let vat_perc;
        let vat_shipping = 0;

        items.map((product, i) => {
            if (product.status !== 'deletedx' && product.amount > 0 && product.sku !== 0 && product.sku !== undefined) {
                let total_incl = product.product_total_incl;

                vat_perc = parseInt(product.vat_perc);          
                if (parseInt(this.settings_list[perc]) === vat_perc) {
                    let vat = parseFloat(total_incl) - (parseFloat(total_incl) / (1 + vat_perc/100));
                    vat_total = parseFloat(vat_total) + parseFloat((Math.round(vat * 100) / 100));

                    if (vat_perc > vat_shipping) {
                        vat_shipping = vat_perc;
                    }
                    
                }
            }
        })

        if (vat_shipping === vat_perc) {
            vat_shipping = this.order_data.vat_shipping;
            vat_total = vat_total + vat_shipping;
        }

        return vat_total.toFixed(2);
    }

    calculateShippingCostsTotal() {      
        const costs = parseFloat(this.order_data.shipping_costs) + parseFloat(this.order_data.vat_shipping);
        this.order_data.shipping_costs = costs.toFixed(2);
        this.shippingCosts = costs.toFixed(2);
    }

    get calculateWeight() {
        let weight = 0;
        let weight_box = 80;
        if (this.settings_list["weightBox"] !== undefined) {
            weight_box = parseInt(this.settings_list["weightBox"]);
        }
        let items = [];

        const loggedin = this.order_data.client_id !== 0 ? true : false;
        if (loggedin) {
            items = this.cart.user;
        } else {
            items = this.cart.anonymous;
        }

        weight = weight + weight_box;
        items.map((product, i) => {
            if (product.status !== 'deletedx' && product.sku !== 0 && product.sku !== undefined) {
                if (product.weight === null || product.weight === '') {
                    //sbC to prevent miscalculation in case no weight set
                    let defaultWeight = 300;
                    weight = weight + defaultWeight;
                } else {
                    weight = weight + product.amount * parseInt(product.weight);
                }
                
                return weight;
            }
        })

        return weight;
    }

    //SbC DEPRICATED
    async calculateShipping(userId) {
        //SbC this is excl VAT !!
        //let costsShipping = 0;
        
        let shipping_abroad = false;
        if (this.order_data.client_settings.has_delivery_address === true ) {
            shipping_abroad = this.order_data.delivery_address.country_code === 'NL' ? false : true; //SbC is NL
        } else {
            shipping_abroad = this.order_data.client_profile.country_code === 'NL' ? false : true; //SbC is NL
        }

        //SbC calculate weight
        let weight = this.calculateWeight;
       
        //SbC calculate shipping from table
        if (!Object.is(weight, NaN)) {
            this.getShipping(userId, weight, shipping_abroad);
        }
    }

    setShippingCosts(shipping) {
        this.order_data.shipping_costs = parseFloat(shipping).toFixed(2);
        this.shippingCosts = parseFloat(shipping).toFixed(2);
    }

    setShippingVAT(vat_perc) {
        this.order_data.vat_shipping_perc = vat_perc;
        this.order_data.vat_shipping = (vat_perc / 100) * this.order_data.shipping_costs;
    }

    calculateTotal() {
        const total = this.calculateTotalOrder;

        const vat_low = this.calculateOrderVATLow;
        this.order_data.order_vat_low = vat_low;

        this.order_data.order_total_incl = total;
    }

    //SbC DEPRICATED
    async getShipping(userId, weight, shipping_abroad) {
        let shippingData;
        let costsShipping;
        let shippingType;

        let discountApplied = false;

        let items;
        if (userId !== 0) {
            items = this.cart.user;
        } else {
            items = this.cart.anonymous;
        }
    
        let is_reseller = this.order_data.client_settings.is_reseller;
        let is_partner = this.order_data.client_settings.is_partner;

        //SbC get from Boekenlijst2008 or user data setting in Accounts
        let discount_minamount = (is_reseller) ? this.settings_list["resellerMinAmount"] : 2; 

        let returnData;
        try {           
            if (this.order_data.order_id === 0 || this.order_data.order_id === '0') {
            //     //SbC no order id available yet
            //     console.log('SbC shipping data 1')
                returnData = await tools.getShippingCosts(weight);
            } else {
                //SbC new v2 shipping costs
                //console.log('SbC shipping data 2')
                returnData = await tools.getShippingCostsV2(this.order_data.order_id);
            }
            shippingData = returnData.data[0];
           
            runInAction(async() => {
                if (shipping_abroad) {
                    let idx = -1;
                    if (this.order_data.client_settings.has_delivery_address === true) {
                        idx = this.countriesList.findIndex(country => country.code == this.order_data.delivery_address.country_code);
                    } else {
                        idx = this.countriesList.findIndex(country => country.code == this.order_data.client_profile.country_code);
                    }
                  
                    //SbC calculate weight surcharge if applicable
                    if (idx !== 0) {
                        costsShipping = this.countriesList[idx].base_shipping;
                    
                        if (this.countriesList[idx].surcharge_weight !== null && this.countriesList[idx].surcharge_weight !== '') {
                            let surcharge =  weight/1000 * parseFloat(this.countriesList[idx].surcharge_weight);
                            costsShipping = parseFloat(costsShipping) + surcharge;
                        }
                    }
                    
                    //SbC set custom shipping costs for order
                    if (this.order_settings.custom_shipping_costs === 1) {
                        costsShipping = await this.getCustomShippingCosts();
                    }

                    //SbC set no shipping costs for order
                    if (this.order_settings.no_shipping_costs === 1) {
                        costsShipping = 0;
                    }

                    //SbC only downloads ..
                    if (weight <= 0) {
                        costsShipping = 0;
                    }
                } else {  
                    //SbC shipping check on total incl discounts                
                    let totalCart = 0;
                    // SbC package_type = 1; // as_package
                    // SbC package_type = 2; // as_letter
                    let package_type = 2;

                    items.map((product) => {
                        //SbC TODO min amount from database!
                        if (product.status !== 'deletedx' && product.sku !== 0 && product.sku !== undefined) {
                            //SbC no need for checking min-amount as product.discount takes that into account
                            discountApplied = (product.amount > 0 && product.discount > 0) ? true : discountApplied; 
                            discountApplied = (product.amount > 0 && product.discount > 0 && this.order_settings.discounts_overruled === 1) ? true : discountApplied;
                            
                            totalCart = (discountApplied) 
                                ?   totalCart + parseFloat(product.price)  * ((100 - product.discount)/100) * parseFloat(product.amount)
                                :   totalCart + parseFloat(product.price)  * parseFloat(product.amount)

                            //SbC check if package type is overruled for bottles etc
                            if (parseInt(product.package_type) === 1 || parseInt(product.weight) === 1 ) {
                                package_type = 1;
                            }
                        }
                    })
                    
                    if (items.length >= 3 && weight > 1000) {
                        package_type = 1;
                    }

                    if (weight > 1000) {
                        package_type = 1;
                    }

                    if (shippingData) { 
                        costsShipping = shippingData.as_letter !== null && package_type !== 1
                        ?   shippingData.as_letter <= shippingData.as_package 
                            ?   shippingData.as_letter 
                            :   shippingData.as_package
                        :   shippingData.as_package
                    }

                    if (!is_reseller && !discountApplied && totalCart >= this.order_settings.shipping_free) {
                        costsShipping = 0;
                    }

                    if (is_reseller && !discountApplied && totalCart >= this.order_settings.shipping_free) {
                        costsShipping = 0;
                    }

                    if (is_reseller && totalCart >= this.order_settings.shipping_free_resellers) {
                        costsShipping = 0;
                    }

                    //SbC free above 250 for all
                    if (totalCart >= this.order_settings.shipping_free_resellers) {
                        costsShipping = 0;
                    }

                    if (totalCart < 0) {
                        costsShipping = 0;
                    }

                    //SbC set custom shipping costs for order
                    let customShipping = await this.checkShippingCostsCustom();

                    if (customShipping[0].custom_shipping_costs === 1) {
                        costsShipping = await this.getCustomShippingCosts();
                    }

                    //SbC set no shipping costs for order
                    await this.checkShippingCostsOverruled();
                    if (this.order_settings.no_shipping_costs === 1) {
                        costsShipping = 0;
                    }

                    //SbC only downloads ..
                    if (weight <= 0) {
                        costsShipping = 0;
                    }  
                }
              
                // this.order_data.shipping_costs = parseFloat(costsShipping).toFixed(2);
                // this.shippingCosts = parseFloat(costsShipping).toFixed(2);

                this.calculateShippingVat(userId);
                this.calculateShippingCostsTotal();
                //SbC necessary from login in order if discount applied
                //const returnData = await this.editOrderLineShipping();

                this.calculateTotal();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }   
    }

    async getCustomShippingCosts() {
        let custom_shipping = 0;
        try {
            const returnData = await order.getCustomShippingCosts(this.order_data.order_id);
            runInAction(() => {
                custom_shipping = parseFloat(returnData.data[0].custom_shipping_costs) / (1 + parseFloat(returnData.data[0].shipping_vat) / 100);
                return custom_shipping;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return custom_shipping;
    }

    calculateShippingVat(id) {
        let items;
        let loggedin;
        
        if (id !== undefined && id !== 0 && id !== '0') {
            loggedin = true;
            items = this.cart.user;
        } else {
            loggedin = false;
            items = this.cart.anonymous;
        }
        
        let vat_perc = 9;
            items.map((product) => {
            if (product.vat_perc > vat_perc) {
                vat_perc = product.vat_perc;
            }
        })
        this.order_data.vat_shipping_perc = vat_perc;
        this.order_data.vat_shipping = (vat_perc / 100) * this.order_data.shipping_costs;
    }

    presetDataProfile(key, value) {
        if (this.order_data.client_profile[key] == '') {
            this.order_data.client_profile[key] = value;
        }
    }

    async removeFromCart(id, sku) {
        let items;
        let loggedin;

        if (id !== undefined && id !== 0 && id !== '0') {
            loggedin = true;
            items = this.cart.user;
        } else {
            loggedin = false;
            items = this.cart.anonymous;
        }

        let idx = items.findIndex(item => item.sku === sku);
        items[idx].status = "deletedx";
        items[idx].amount = 0;
        //items.splice(idx, 1);

        this.calculateTotalCart();

        if (id !== undefined && id !== 0 && id !== '0') {
            this.cart.user = items;
        } else {
            this.cart.anonymous = items;
        }

        if (id !== undefined && id !== 0) {
            (async() => await this.saveShoppingCartToDB(id, this.cart.user))();
        } else {
            this.saveShoppingCartToLS(id, this.cart);
        }
    }

    resetOrder(use_case, is_logged_in) {
        this.order_data.order_id = 0;
        this.order_data.order_guid = '';
        this.order_data.order_remark = '';

        this.resetOrderData(is_logged_in);
        this.resetBusinessData();
        this.resetDeliveryAddresData();
        this.resetPayment();

        if (use_case !== 'open_order') {
            this.resetCart(is_logged_in);
        }
    }

    resetOrderData(stay_logged_in) {
        this.order_data['order_id'] = 0;
        this.order_data['order_guid'] = '';
        this.order_data['order_remark'] = '';

        this.discounts_overruled = 0;
        this.order_settings.discounts_overruled = 0;

        if (!stay_logged_in && this.cart.anonymous.length === 0) {
            this.cartTotalIncl = 0;
            this.shippingCosts = 0;
        }

        if (stay_logged_in) {
            this.cartTotalIncl = 0;
            this.shippingCosts = 0;
        }

        if (!stay_logged_in) {
            this.order_data['affiliate_id'] = 0;
            this.order_data['client_id'] = 0;
            this.partner_code.code = '';
            this.partner_code.last_date = '';
        }

        this.order_data.client_profile['firstname'] = '';
        this.order_data.client_profile['lastname'] = '';
        this.order_data.client_profile['email'] = '';
        this.order_data.client_profile['phonenr'] = '';
        this.order_data.client_profile['address'] = '';
        this.order_data.client_profile['housenr'] = '';
        this.order_data.client_profile['addition'] = '';
        this.order_data.client_profile['zipcode'] = '';
        this.order_data.client_profile['city'] = '';
        this.order_data.client_profile['country'] = this.country_default;
        this.order_data.client_profile['country_select'] = this.country_default;
        this.order_data.client_profile['country_code'] = this.country_code_default;

        this.order_data.billing_address['address'] = '';
        this.order_data.billing_address['housenr'] = '';
        this.order_data.billing_address['addition'] = '';
        this.order_data.billing_address['zipcode'] = '';
        this.order_data.billing_address['city'] = '';
        this.order_data.billing_address['country'] = this.country_default;
        this.order_data.billing_address['country_select'] = this.country_default;
        this.order_data.billing_address['country_code'] = this.country_code_default;

        this.order_data.client_settings['has_delivery_address'] = false;
        this.resetDeliveryAddresData();

        this.order_data.client_settings['is_partner'] = false;
        this.order_data.client_settings['is_reseller'] = false;
        this.order_data.client_settings['is_business'] = false;
        this.order_data.client_settings['has_delivery_address'] = false;
        this.resetBusinessData();

        localStorage.removeItem('SB_ORDER');

        this.resetPayment();
    }

    resetCart(is_logged_in) {
        this.order_data.shopping_cart.cart_total_incl = 0;
        this.order_data.shopping_cart.vat_total = 0;

        if (is_logged_in) {
            this.cart.user = [];
            this.resetShoppingCartToDB(this.order_data.client_id);
        } else {
            this.cart.anonymous = [];
        }

        this.order_data.shipping_costs = 0;
        this.order_data.vat_shipping_perc = 0;
        this.order_data.vat_shipping = 0;
        this.order_data.order_total_incl = 0;
    }

    resetCartUser() {
        this.cart.user = [];
    }

    resetBusinessData() {
        this.order_data.company_profile['name'] = '';
        this.order_data.company_profile['nrKvK'] = '';
        this.order_data.company_profile['nrVAT'] = '';
    }

    resetDeliveryAddresData() {
        this.order_data.delivery_address['delivery_name'] = '';
        this.order_data.delivery_address['delivery_company'] = '';
        this.order_data.delivery_address['address'] = '';
        this.order_data.delivery_address['housenr'] = '';
        this.order_data.delivery_address['addition'] = '';
        this.order_data.delivery_address['zipcode'] = '';
        this.order_data.delivery_address['city'] = '';
        this.order_data.delivery_address['country'] = this.country_default;
        this.order_data.delivery_address['country_select'] = this.country_default;
        this.order_data.delivery_address['country_code'] = this.country_code_default;
    }

    copyDeliveryAddresData() {
        this.order_data.delivery_address['delivery_name'] = this.order_data.client_profile['firstname'] + ' ' + this.order_data.client_profile['lastname'];
        this.order_data.delivery_address['delivery_company'] = this.order_data.company_profile['name'];
        this.order_data.delivery_address['address'] = this.order_data.client_profile['address'];
        this.order_data.delivery_address['housenr'] = this.order_data.client_profile['housenr'];
        this.order_data.delivery_address['addition'] = this.order_data.client_profile['addition'];
        this.order_data.delivery_address['zipcode'] = this.order_data.client_profile['zipcode'];
        this.order_data.delivery_address['city'] = this.order_data.client_profile['city'];
        this.order_data.delivery_address['country'] = this.order_data.client_profile['country'];
        this.order_data.delivery_address['country_select'] = this.order_data.client_profile['country_select'];
        this.order_data.delivery_address['country_code'] = this.order_data.client_profile['country_code'];
    }

    resetPayment() {
        this.order_data.payment.payment_method.id = 'XX';
    }

    resetOrderStatus() {
        this.order_data.order_status.payment_status = 'pending';
    }

    getAffiliateId(id) {
        this.order_data.affiliate_id = id;
    }

    setAffiliateId(id) {
        this.order_data.affiliate_id = id;
       
        const pc = this.partner_code.code;
        if (pc !== id) {
            this.partner_code.code = id;
            this.partner_code.last_date = new Date();
        }

        this.activatePCUse(id);
    }

    async activatePCUse(pc) {
        try {
            const returnData = await order.activatePCUse(pc);
            runInAction(() => {
                let processData = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async setCartView(id, view) {
        //SbC cases discount to 0 in review
        //SbC DEPRICATED as abundant?

        //SBC not needed if discountsOverruled ...???
        //Changing back between DiscountsOverruled ????
        
        if (view !== 'review' && view !== 'client' && view !== 'payment') {
            (async() => await this.editOrderItems(id))();
        }

        //SbC copied from editOrderItems - needed due to possible discount
        //const returnData = await this.editOrderLineShipping();
        this.order_interface.cart_view = view;
    }

    setClientId(id) {
        this.order_data.client_id = id;
    }

    setOrderProfileFromUser(data) {
        this.order_data.client_profile = {};
        this.order_data.billing_address = {};
        this.order_data.delivery_address = {};
        this.order_data.company_profile = {};

        this.order_data.client_profile['firstname'] = data.firstname;
        this.order_data.client_profile['lastname'] = data.lastname;
        this.order_data.client_profile['email'] = data.email;
        this.order_data.client_profile['phonenr'] = data.phonenr;
        this.order_data.client_profile['address'] = data.address;
        this.order_data.client_profile['housenr'] = data.housenr;
        this.order_data.client_profile['addition'] = data.addition;
        this.order_data.client_profile['zipcode'] = data.zipcode;
        this.order_data.client_profile['city'] = data.city;
        this.order_data.client_profile['country'] = data.country_select !== null ? data.country_select : 'Nederland';
        this.order_data.client_profile['country_select'] = data.country_select !== null ? data.country_select : 'Nederland';
        this.order_data.client_profile['country_code'] = data.country_code !== null ? data.country_code : 'NL';

        this.order_data.company_profile['name'] = data.company;
        this.order_data.company_profile['nrKvK'] = data.nrKvK;
        this.order_data.company_profile['nrVAT'] = data.nrVAT;

        this.order_data.client_settings.is_reseller = (data.is_reseller === 1 || data.is_reseller === '1' || data.is_reseller === 'on') ? true : false;
        //his.order_data.client_settings.is_partner = (data.is_partner === 'on' || data.is_partner === 1 || data.is_partner === '1') ? true : false; 

        this.order_settings.no_shipping_costs = data.no_shipping_costs;
    }

    setDataProfile(form_data) {
        let idx;
        this.order_data.client_profile['firstname'] = form_data.firstname.value;
        this.order_data.client_profile['lastname'] = form_data.lastname.value;
        this.order_data.client_profile['email'] = form_data.email.value;
        this.order_data.client_profile['phonenr'] = form_data.phonenr.value;

        this.order_data.client_profile['address'] = form_data.address.value;
        this.order_data.client_profile['housenr'] = form_data.housenr.value;
        this.order_data.client_profile['addition'] = form_data.addition.value;
        this.order_data.client_profile['zipcode'] = form_data.zipcode.value;
        this.order_data.client_profile['city'] = form_data.city.value;
        this.order_data.client_profile['country'] =  parseInt(form_data.country_select.value);
        this.order_data.client_profile['country_select'] =  parseInt(form_data.country_select.value)
        idx = this.countriesList.findIndex(country => country.code == form_data.country_select.value);
        if (idx !== -1) {
            this.order_data.client_profile['country'] = this.countriesList[idx].displayValue;
            this.order_data.client_profile['country_code'] = this.countriesList[idx].code;
        }

        this.order_data.billing_address['address'] = form_data.address.value;
        this.order_data.billing_address['housenr'] = form_data.housenr.value;
        this.order_data.billing_address['addition'] = form_data.addition.value;
        this.order_data.billing_address['zipcode'] = form_data.zipcode.value;
        this.order_data.billing_address['city'] = form_data.city.value;
        this.order_data.billing_address['country'] = parseInt(form_data.country_select.value);
        this.order_data.billing_address['country_select'] = parseInt(form_data.country_select.value);
        if (idx !== -1) {
            this.order_data.billing_address['country_code'] = this.countriesList[idx].code;
            this.order_data.billing_address['country'] = this.countriesList[idx].displayValue;
        }

        //this.editOrderLineShipping();
    }

    setDataDeliveryAddress(form_data) {
        let idx;
        if (this.order_data.client_settings.has_delivery_address && form_data.address.value !== '' && form_data.city.value !== '') {
            this.order_data.delivery_address['delivery_name'] = form_data.delivery_name.value;
            this.order_data.delivery_address['delivery_company'] = form_data.delivery_company.value;
            this.order_data.delivery_address['address'] = form_data.address.value;
            this.order_data.delivery_address['housenr'] = form_data.housenr.value;
            this.order_data.delivery_address['addition'] = form_data.addition.value;
            this.order_data.delivery_address['zipcode'] = form_data.zipcode.value;
            this.order_data.delivery_address['city'] = form_data.city.value;
            this.order_data.delivery_address['country'] = parseInt(form_data.country_select.value);
            this.order_data.delivery_address['country_select'] = parseInt(form_data.country_select.value);
            idx = this.countriesList.findIndex(country => country.code == form_data.country_select.value);
            if (idx !== -1) {
                this.order_data.delivery_address['country'] = this.countriesList[idx].displayValue;
                this.order_data.delivery_address['country_code'] = this.countriesList[idx].code;
            }
        }  else {
            this.order_data.delivery_address['delivery_name'] = '';
            this.order_data.delivery_address['delivery_company'] = '';
            this.order_data.delivery_address['address'] = '';
            this.order_data.delivery_address['housenr'] = '';
            this.order_data.delivery_address['addition'] = '';
            this.order_data.delivery_address['zipcode'] = '';
            this.order_data.delivery_address['city'] = '';
            this.order_data.delivery_address['country'] = this.country_default;
            this.order_data.delivery_address['country_select'] = this.country_default;
            this.order_data.delivery_address['country_code'] = this.country_code_default;
        }

    }

    setDataBusiness(form_data) {
        if (this.order_data.client_settings.is_business) {
            this.order_data.company_profile['name'] = form_data.company.value;
            this.order_data.company_profile['nrKvK'] = form_data.nrKvK.value;
            this.order_data.company_profile['nrVAT'] = form_data.nrVAT.value;
        }
    }

    setSettingDeliveryAddress(setting) {
        this.order_data.client_settings.has_delivery_address = setting;
    }

    setSettingBusiness(setting, user_business) {
        this.order_data.client_settings.is_business = setting;
        if (!setting) {
            this.order_data.company_profile.name = '';
        } else {
            this.order_data.company_profile.name = user_business.company;
        }
    }

    setPaymentData(name, value, display_name) {
        this.order_data.payment[name] = {
            id: value,
            title: display_name
        }
        if (name === 'payment_issuer') {
            this.order_data.payment[name] = {
                code: this.payment_issuers[value].code
            }
        } 
        if (name === 'payment_method') {
            if (value !== 'IDEAL') {
                this.order_data.payment['payment_issuer'] = {
                    code: '9999'
                }
            }
        } 
    }

    async handlePaymentProcess(order_id, order_guid, payment_id, issuer_code, use_case, order_data) {
        this.saveUseCaseToLS(use_case);
        this.monitorStartPayment(order_id);
        
        let payment_data;
        let url;
        if (use_case == 'open_order') {
            payment_data = order_data;
        } else {
            payment_data = {
                order_total_incl: this.calculateTotalOrder,
                firstname: this.order_data.client_profile.firstname,
                lastname: this.order_data.client_profile.lastname,
                email: this.order_data.client_profile.email,
                address: this.order_data.client_profile.address,
                housenr: this.order_data.client_profile.housenr,
                addition: this.order_data.client_profile.addition,
                zipcode: this.order_data.client_profile.zipcode,
                city: this.order_data.client_profile.city
            }
        }
        try {
            //console.log('SbC total payment', payment_data.order_total_incl)
            const returnData = await payment.handlePaymentProcess(order_id, order_guid, payment_data, payment_id, issuer_code);
            runInAction(() => {
                let processData = returnData.data;
                if (processData.url !== null) {
                    this.order_data.order_status.url = processData.url;
                    this.order_data.order_status.payment_status = processData.process;
                    this.payment_status = processData.process;
                    url = processData.url;
                    return url;
                } else {
                    this.order_interface.error_window = true;
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return url;
    }

    async handleBankTransfer(id, order_id, use_case, order_data) {
        let payment_data;
        if (use_case == 'open_order') {
            payment_data = order_data;
        } else {
            order_id = this.order_data.order_id;
            payment_data = {
                order_total_incl: this.calculateTotalOrder,
                firstname: this.order_data.client_profile.firstname,
                lastname: this.order_data.client_profile.lastname,
                email: this.order_data.client_profile.email,
                address: this.order_data.client_profile.address,
                housenr: this.order_data.client_profile.housenr,
                addition: this.order_data.client_profile.addition,
                zipcode: this.order_data.client_profile.zipcode,
                city: this.order_data.client_profile.city
            }
            //console.log('SbC open order', order_id, this.order_data, payment_data)
        }
       
        try {
            const returnData = await payment.handleBankTransfer(order_id, payment_data);
            runInAction(() => {
                let processData = returnData;
                this.order_data.order_status.payment_status = 'banktransfer';
                this.payment_status = 'banktransfer';
                this.setCartView(id, "confirmation");
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getOrderId(guid) {
        let order_id;
        try {
            const returnData = await order.getOrderId(guid);
            runInAction(() => {
                order_id = returnData.data[0].order_id;
                return order_id;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return order_id;
    }

    async getOrderStatus(guid) {
        let payment_status;
        try {
            const returnData = await payment.getOrderStatus(guid);
            runInAction(() => {
                this.payment_status = returnData.data[0].order_status;
                payment_status = returnData.data[0].order_status;
                return payment_status;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return payment_status;
    }

    async getPaymentStatus(orderId) {
        let payment_status;
        try {
            const returnData = await payment.getPaymentStatus(orderId);
            runInAction(() => {
                payment_status = returnData.data[0].order_status;
                return payment_status;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return payment_status;
    }

    async setOrderStatus(client_id, order_id, order_status) {
        try {
            const returnData = await order.setOrderStatus(client_id, order_id, order_status);
            runInAction(() => {
                //SbC:: update orderHistoryList
                this.getAccountOrders(client_id);
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getAccountOrders(user_id) {
        //const user_id2 = 19241;
        this.startLoader();
        try {
            const returnData = await order.getAccountOrders(user_id);
            runInAction(() => {
                let orderData = returnData;
                this.orderHistoryList = orderData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.stopLoader();
        }
    }

    async getPaymentIssuers() {
        try {
            const returnData = await payment.getPaymentIssuers();
            runInAction(() => {
                let payment_issuers = returnData.data;

                payment_issuers.map((country, i) => {
                    payment_issuers[i].displayValue = payment_issuers[i].description;
                    payment_issuers[i].value = i;
                    this.payment_issuers[i] = payment_issuers[i];
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    unloadPaymentWindow() {
        this.order_interface.payment_window = false;
    }

    useCartDB() {
        //SbC reset ids to prevent order id mixups:
        if (this.shopping_cart_db_id !== null) {
            this.order_data.order_id = this.shopping_cart_db_id;
            this.order_data.order_guid = this.shopping_cart_db_guid;
        }

        // this.shopping_cart_db.map((item) => {
        //     if (item.status == 'deletedx') {
        //         console.log('sbc permanantly delete!')
        //     }
        // })
        this.cart.user = this.shopping_cart_db;
        this.shopping_cart_db = [];
        this.closeDialogCompareCarts()
    }

    async concatLSandDB() {
        let idx;
        let userId = this.order_data.client_id;
        this.cart.user = this.shopping_cart_db;

        this.cart.anonymous.map(async(item_anonymous) => {
            idx = this.cart.user
                .findIndex((item) => item.sku === item_anonymous.sku);
            if (idx !== -1) {
                //this.cart.user[idx].amount = parseInt(this.cart.user[idx].amount) + parseInt(item_anonymous.amount);
                this.cart.user[idx].amount = parseInt(item_anonymous.amount);
                this.cart.user[idx].status = parseInt(item_anonymous.status);
            } else {
                //SbC add to cart user
                this.cart.user.push(item_anonymous);   

                //SbC not yet in cart -> also save as orderline  
                //SbC when logging in no effect casue no order id yet
                const orderId = await this.returnOrderId(userId);
                (async() => await this.addOrderLine(item_anonymous, 'add'))();
            }

            if (this.shopping_cart_db_id !== null) {
                this.order_data.order_id = this.shopping_cart_db_id;
                this.order_data.order_guid = this.shopping_cart_db_guid;
            }
        })

        /* SbC depricated ? */
        await this.constructShoppingCart(userId, 'init');

        await this.saveShoppingCartToDB(userId, this.cart.user);

        this.cart.anonymous = [];
        this.saveShoppingCartToLS(userId, this.cart);
        
        this.shopping_cart_db = [];
        this.closeDialogCompareCarts();
    }

    deleteLSandDB() {
        // let id = this.order_data.client_id;
        // this.deleteShoppingCartFromDB(id);
        // this.deleteShoppingCartFromLS();
        // this.shopping_cart_db = [];
    }

    /**
     * SHOPPING CART actions
     * parallel to cart in order_data but used when no order nr is generated yet
     */

    async saveShoppingCartToDB(id, shopping_cart) {
        const orderId = await this.returnOrderId(id);

        if (id !== 0) {
            //if (orderId !== 0 && orderId !== '0') {
                if (orderId !== 0 && orderId !== '0' && orderId !== 99 && orderId !== '99') { 
                    this.order_data.order_id = orderId;
                }

                try {
                    //const returnData = await account.saveShoppingCart(id, shopping_cart, this.order_data.order_id, this.order_data.order_guid);
                    const returnData = await account.saveShoppingCart(id, shopping_cart, orderId, this.order_data.order_guid);
                    runInAction(() => {
                    })
                } catch (error) {
                    runInAction(() => {
                        this.state = "error"
                    })
                }
            //}
        }
    }

    async syncShoppingCartWithOrderlines(id, shopping_cart) {
        let orderlinesDirect;
        let sku;

        let orderId = await this.retrieveOrderIdDirect(id);
        if (orderId !== 0) {
            orderlinesDirect = await this.retrieveOrderlinesDirect(id);

            for (let i = 0; i < shopping_cart.length; i++) {
                sku = shopping_cart[i].sku;
                let idx = orderlinesDirect
                    .findIndex((item) => item.sku === sku);

                if (idx === -1) {
                    //SbC add to orderlines
                    (async() => await this.addOrderLine(shopping_cart[i], 'add'))();
                }
            }
        } 
    }

    async resetShoppingCartToDB(id) {
        const orderId = 99;
        const guid = null;
        const cart = [];
    
        try {
            const returnData = await account.saveShoppingCart(id, cart, orderId, guid);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    retrieveShoppingCart(id, use_case) {     
        if (id !== undefined && id !== 0 && id !== '0') {
            this.retrieveShoppingCartFromDB(id, use_case);
        } else {
            this.retrieveShoppingCartFromLS();
        }
    }

    async retrieveShoppingCartFromOrder(id) {
        //SbC adds items anonymous to cart
        if (this.cart.user.length > 0) {
            console.log('SbC needs alert popup');
        }
        this.cart.user = this.cart.anonymous;
        this.cart.anonymous = [];

        //SbC ad cart from not logged in yet
        //SbC not yet in cart -> also save as orderline  
        let userId = this.order_data.client_id;
        const orderId = await this.returnOrderId(userId);
        this.order_data.order_id = orderId;

        this.cart.user.map((item, idx) => {
            (async() => await this.addOrderLine(item, 'add'))();
        });
    }

    async retrieveShoppingCartFromDB(id, use_case) {
        try {
            const returnData = await account.retrieveShoppingCart(id);
            runInAction(() => {
                if (returnData.data.order_id !== null) {
                    this.shopping_cart_db_id = returnData.data.order_id;
                    this.shopping_cart_db_guid = returnData.data.guid;
                }
                this.compareShoppingCarts(returnData.data.cart, use_case);
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async returnOrderId(id) {
        let orderId;

        if (this.order_data.order_id === undefined) { 
            try {
                const returnData = await account.retrieveShoppingCart(id);
                runInAction(() => {
                    if (returnData.data.order_id !== null) {
                        orderId = returnData.data.order_id;
                        this.order_data.order_id = orderId;
                    }
                    return orderId;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        } else {
            orderId = this.order_data.order_id;
        }
        return orderId;
    }

    compareShoppingCarts(items, use_case) {
        if (this.cart.anonymous.length == 0) {
            //SbC LS shoppingcart no items 
            // items.map((item, idx) => {
            //     if (item.status == 'deletedx') {
            //         this.cart.user.map((item2, idx2) => {
            //             if (item.sku === item2.sku) {
            //                 //if (item.status === 'deletedx')  {
            //                     let id = this.order_data.client_id;
            //                     this.removeFromCart(id, item.sku);
            //                     items.splice(idx, 1);
            //                 // } else {
                                
            //                 // }
            //             }
            //         })  
            //     }
            // })

            this.cart.user = items;
            this.order_data.order_id = this.shopping_cart_db_id;
            this.order_data.order_guid = this.shopping_cart_db_guid;
        } else {   
            this.shopping_cart_db = items;
            let shopping_cart_db_cleaned = [];

            if (use_case == 'init') {
                //SbC refresh or F5 relaod
                this.useCartDB();
            } else {   
                /* TO REVIEW */
                // if (items.length == 0) {
                //     items.map((item) => {
                //         if (item.status !== 'deletedx') {
                //             shopping_cart_db_cleaned.push(item);
                //         }
                //     })
                //     this.openDialogCompareCarts(shopping_cart_db_cleaned);
                // }

                // //     //SbC DB shoppingcart has no items, LS > 0
                //     //SbC should transfer LS
                //     this.openDialogConcatCarts();
                // } else {
                    //SbC DB shoppingcart > 0, LS > 0
                    
                    //SbC TEST
                    //SbC always concat carts but notify if already cart in db
                    
                    let concat = false;
                    if (this.shopping_cart_db.length > 0) {
                        this.shopping_cart_db.map((item) => {
                            if (item.status !== 'deletedx') {
                                concat = true;
                            }
                        })
                        if (concat === true) {
                            this.openDialogCompareCarts(items);
                        } else {
                            this.concatLSandDB();
                        }
                    } else {
                        this.concatLSandDB();
                    }
                //}
            }
        }
    }

    async deleteShoppingCartFromDB(id) {
        try {
            const returnData = await account.deleteShoppingCart(id);
            runInAction(() => {
                this.cart.user = [];
                //this.order_data.shopping_cart['items'] = [];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    saveShoppingCartToLS(id, cart) {
        const json = JSON.stringify(cart);
        localStorage.setItem('SB_CART20', json)
    }

    retrieveShoppingCartFromLS() {
        // if (localStorage.getItem('SB_CART20')) {
        //     this.cart = JSON.parse(localStorage.getItem('SB_CART20'));
        // }
        if (localStorage.getItem('SB_ORDER')) {
            let data = JSON.parse(localStorage.getItem('SB_ORDER'));
            this.cart.anonymous = data.cart.anonymous;
        }
        this.cart.user = [];
    }

    deleteShoppingCartFromLS() {
        if (localStorage.getItem('SB_CART20')) {
            //SbC can be depricated
            let cart = JSON.parse(localStorage.getItem('SB_CART20'));
            cart.anonymous = [];
            //this.cart.anonymous = [];
            const json = JSON.stringify(cart);
            localStorage.setItem('SB_CART20', json)

            //SbC cart in SB_ORDER storage
            const loggedin = this.order_data.client_id !== 0 ? true : false;
            if (loggedin) {
                this.cart.user = [];
            } else {
                this.cart.anonymous = [];
            }
        }
    }

    async getOrderData(order_id) {
        let order_data = [];
        try {
            const returnData = await order.getOrderData(order_id);
            runInAction(() => {
                order_data = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return order_data;
    }

    async getOrderLinesData(order_id) {
        let open_order_data = [];
        try {
            const returnData = await order.getOrderItemsData(order_id);
            runInAction(() => {
                open_order_data = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return open_order_data;
    }

    async getDataInvoice(idInvoice) {
        let orderData = [];
        try {
            const returnData = await orders.getDataInvoice(idInvoice);
            runInAction(() => {
                orderData = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return orderData;
    }

    async deleteOrderLineShipping(orderId) {
        try {
            const returnData = await orders.deleteOrderLineShipping(orderId);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async checkOrderLineShipping(orderId) {
        let hasShippingLine = false;
        try {
            const returnData = await orders.getOrderLineShipping(orderId);
            runInAction(() => {
                let costs = returnData.data[0].costs;
                hasShippingLine = (costs !== '999' && costs !== 999) 
                    ?   true 
                    :   false
                ; 
                return hasShippingLine;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return hasShippingLine;
    }

    async setOrderRemark(id, remark) {
        try {
            const returnData = await order.setOrderRemark(id, remark);
            runInAction(() => {
                this.order_data.order_remark = remark;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    setOrderRemarkLS(remark) {
        this.order_data.order_remark = remark;
    }

    deleteUseCaseFromLS() {
        if (localStorage.getItem('SB_USE_CASE')) {
            localStorage.removeItem('SB_USE_CASE');
        }
    }

    getUseCaseFromLS() {
        let use_case;
        if (localStorage.getItem('SB_USE_CASE')) {
            use_case = localStorage.getItem('SB_USE_CASE');
        } else {
            use_case = '';
        }
        return use_case;
    }

    saveUseCaseToLS(use_case) {
        localStorage.setItem('SB_USE_CASE', use_case)
    }

    async removeOrder(user_id, order_id) {
        try {
            const book = await order.removeOrder(order_id);
            runInAction(() => {
                this.getAccountOrders(user_id);
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async registerAnonymousClient(data) {
        let returnData;
        try {
            returnData = await account.addAnonymousUser(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.order_data.client_id = returnData.data[0].id;
                    this.saveOrderDataProfile();
                    this.cart.user = this.cart.anonymous;
                    this.cart.anonymous = [];
                } 
                return returnData;
            })
        } catch (error) {
            runInAction(() => {

            })
        }
        return returnData;
    }

    async registerClientFromOrder(data) {
        //SbC depricated??
        try {
            let returnData = await account.addUserFromOrder(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    this.order_data.client_id = returnData.data[0].id;
                    this.saveOrderDataProfile();
                } else {

                } 
            })
        } catch (error) {
            runInAction(() => {

            })
        }
    }

    updateOrderEmail(email) {
        this.order_data.client_profile.email = email;
    }

    async monitorOrderData() {
        try {
            let returnData = await monitoring.monitorOrderData(this.order_data.order_id, this.order_data.client_profile);
            runInAction(() => {
                
            })
        } catch (error) {
            runInAction(() => {

            })
        }
    }

    async monitorStartPayment() {
        let device = {
            mobile: rdd.isMobile,
            operating: rdd.isIOS,
            browserName: rdd.browserName,
            browserVersion: rdd.browserVersion
        }
        try {
            let returnData = await monitoring.monitorStartPayment(this.order_data.order_id, device);
            runInAction(() => {
                
            })
        } catch (error) {
            runInAction(() => {

            })
        }
    }

    submitDeleteOrderLine(user_id, product) {
        let loggedin;
        if (user_id !== undefined && user_id !== 0) {
            loggedin = true;
        } else {
            loggedin = false;
        }
        
        (async() => await this.deleteOrderLine(product, 'delete'))(); 
        (async() => await this.removeFromCart(user_id, product.sku))(); 
        // this.calculateShipping();
        // this.calculateTotalCart(loggedin);
    }

    clearCartAfterPayment() {
        this.cart.user = [];
        this.order_data.order_id = 99;
    }

    clearAllShoppingCarts(is_logged_in) {
        // this.order_data.shopping_cart = {
        //     items: [],
        //     cart_total_incl: 0,
        //     vat_total: 0
        // };

        this.order_data.order_id = 0;
        this.order_data.order_guid = '';
        this.order_data.order_remark = '';

        this.resetCart();
        this.resetOrderData(is_logged_in);
        // this.resetBusinessData();
        // this.resetDeliveryAddresData();
        // this.resetPayment();
    }

    resetOrderSettings(key, value) { 
        this.order_settings[key] = value;
    }

    get calculateTotalCartItemsAnonymous() {
        let amount = 0;

        this.cart.anonymous.map((product) => {
            if (product.status !== 'deletedx') {
                amount = parseInt(amount) + parseInt(product.amount)
            }
        })

        return amount;
    }

    get calculateTotalCartItemsUser() {
        let amount = 0;

        this.cart.user.map((product) => {
            if (product.status !== 'deletedx') {
                amount = parseInt(amount) + parseInt(product.amount);
            }
        })

        return amount;
    }

    async addToCart(shop, id, book, action, amount, discount) {
        let items;
        let cart_item;
        let description;

        if (id !== undefined && id !== 0 && id !== '0') {
            let totalCart = await this.constructShoppingCart(id, 'add');
            items = totalCart;

        } else {
            items = this.cart.anonymous;
        }

        //SbC construct description
        let title = book.title;
        let collectionName = '';
        let collectionPart = '';
        let versionTag = '';
        
        if (book.collection_name && book.collection_name !== null) {
            collectionName = book.collection_name + ': ';
        }
        if (book.collection_part && book.collection_part !== null) {
            collectionPart = ' ' + book.collection_part;
        }
        if (book.version_tag && book.version_tag !== null) {
            versionTag = ' (' + book.version_tag + ')';
        }

        description = collectionName + title + collectionPart + versionTag;

        let idx = items.findIndex(item => item.sku === book.sku);
        let percDiscount = (discount && book.discount_active !== 0) ? discount : 0; 

        //SbC adj for IDx
        if (action === "add") {
            if ( idx !== -1 && idx !== null) {
                items[idx].amount = parseInt(items[idx].amount) + 1;
                items[idx].discount = percDiscount;
                items[idx].status = 'added';

                cart_item = items[idx];
            } else {
                cart_item = {
                    shop: shop,
                    sku: book.sku,
                    //id: book.id,
                    id: (book.id !== null) ? book.id : book.idx,
                    description: description,
                    amount: amount,
                    weight: book.weight,
                    price: book.price_incl,
                    discount_active: (book.discount_active === 1 || book.discount_active === '1') ? 1 : book.discount_active,
                    discount: percDiscount,
                    vat_perc: book.vat_perc,
                    status: 'added'
                }
                items.push(cart_item);
            }
        }

        if (action === "replace") {
            if (idx !== -1  && idx !== null) {
                items[idx].amount = amount;
                items[idx].discount = percDiscount;
                items[idx].status = 'added';

                cart_item = items[idx];
            }
        }

        if (id !== undefined && id !== 0 && id !== '0') {
            this.cart.user = items;
        } else {
            this.cart.anonymous = items;
        }
                
        this.saveShoppingCartToLS(id, this.cart);
        if (id !== undefined && id !== 0) {
            (async() => await this.saveShoppingCartToDB(id, this.cart.user))();
            
            //SbC test 
            //this.editOrderItems(id);
            if (action === "add") {
                (async() => await this.addOrderLine(cart_item, 'add'))();
            }
            if (action === "replace") {
               (async() => await this.addOrderLine(cart_item, 'replace'))();
            }
        } 

        this.lastAddedBook.sku = book.sku;
    }  

    //### CONSTRUCT SHOPPING CART

    async constructShoppingCart(id, action) {
        let orderlinesDirect = [];

        if (id !== 0) {
            let cartDirect = await this.retrieveShoppingCartDirect(id);
            let orderId = await this.retrieveOrderIdDirect(id);
            //if (orderId !== 0 && orderId === 99 && orderId === '99') {
            if (orderId !== 0) {
                orderlinesDirect = await this.retrieveOrderlinesDirect(id);
            } 

            if ((orderId === 99 || orderId === '99') && action !== 'add') {
                //SbC caused loosing orderline from anonymous when logging in using form-check-email
                //this.cart.user = [];
            };
            let totalCart = await this.concatConstructResults(this.cart.user, cartDirect, orderlinesDirect, action);

            //this.updateOrderLinesToLS(totalCart);

            this.calculateTotal();

            return totalCart;
        }
    }

    async retrieveShoppingCartDirect(id) {
        let cart;

        try {
            const returnData = await account.retrieveShoppingCart(id);
            runInAction(() => {
                cart = returnData.data.cart;
                return cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return cart;
    }

    async retrieveOrderIdDirect(id) {
        let orderId;

        try {
            const returnData = await account.retrieveOrderId(id);
            runInAction(() => {
                orderId = returnData.data[0];
                return orderId;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return orderId;
    }

    async retrieveOrderlinesDirect(id) {
        let orderlines = [];

        try {
            const returnData = await account.retrieveOrderItems(id);
            runInAction(() => {
                orderlines = returnData.data;
                return orderlines;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return orderlines;
    }

    async getBookISBN(id) {
        let isbn;
        try {
            const returnData = await books.getBookISBN(id);
            runInAction(() => {
                isbn = returnData.data[0].sku;
                return isbn;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return isbn;
    }

    async concatConstructResults(cart, cartDb, orderlines, action) {
        let localCart = cart;
        let onlineCart = cartDb;
        let sku;
        let idx;

        //sbC check orderlines with cart in db
        if (orderlines && orderlines.length > 0) {
            //console.log('SbC orderlines:', orderlines)
            for (let i = 0; i < orderlines.length; i++) {
                let id = orderlines[i].book_id;
                let idText = id.toString();
                if (idText.length < 8) {
                    sku = await this.getBookISBN(id);
                } else {
                    sku = id;
                }

                if (cartDb && cartDb.length > 0) {
                    idx = cartDb
                        .findIndex((item) => item.sku === sku);
                } else {
                    onlineCart = [];
                    idx = -1;
                }
                    
                if (idx === -1) {
                    orderlines[i].sku = sku;
                    orderlines[i].amount = 1;
                    onlineCart.push(orderlines[i]);
                }

                if (idx !== -1) {
                    cartDb[idx].status = 'added';
                    cartDb[idx].amount =  orderlines[i].amount;
                    //cartDb[idx].discount =  orderlines[i].discount;

                    onlineCart[idx].amount =  orderlines[i].amount;
                    onlineCart[idx].discount =  orderlines[i].discount; 

                    //this.cartChanges = true; 
                }
            }
        }

        //sbC check combined cart with cart in localstorage
        if (onlineCart && onlineCart.length > 0) {
            //console.log('SbC onlineCart:', onlineCart)
            this.cartChanges = false;
            for (let i = 0; i < onlineCart.length; i++) {
                let idx = localCart
                    .findIndex((item) => item.sku === onlineCart[i].sku);
                if (idx == -1) {
                    localCart.push(onlineCart[i]);
                    //this.cartChanges = true;
                }

                if (onlineCart[i].status == 'added' || onlineCart[i].status == 'ok') {
                    if (idx !== -1) {
                        if (localCart[idx].status !== 'added') {
                            //this.cartChanges = true;    
                        }
                        //localCart[idx].status = 'added';
                        localCart[idx].status = 'ok';
                        //localCart[idx].amount = 1;
                    }
                }

                if (action === 'init') {
                    if (idx !== -1) {
                        if (localCart[idx].amount !== onlineCart[i].amount) {
                            //this.cartChanges = true;    
                        }
                        
                        if (localCart[idx].status !== onlineCart[i].status) {
                            //this.cartChanges = true;    
                        }
                        if (onlineCart[i].status == 'deletedx' && localCart[idx].status !== 'ok') {
                            if (localCart[idx].status === 'added') {
                                localCart[idx].status = 'deletedx';
                                localCart[idx].amount = 0;
                            }
                        }
                        localCart[idx].amount = onlineCart[i].amount;
                        localCart[idx].discount = onlineCart[i].discount;
                    }
                }

                if (action === 'add') {
                    localCart[idx].status = 'added';
                    //localCart[idx].amount = 1;
                }
            }

            
        }

        this.cart.user = localCart;
        this.calculateTotalCart(true);

        return localCart;
    }

    setCartChanges(status) {
        this.cartChanges = status;
    }

}

decorate(StoreOrder, {
    cart: observable,
    cartTotalIncl: observable,
    country_default: observable,
    country_code_default: observable,
    countriesList: observable,
    discounts_overruled: observable,
    lastAddedBook: observable,
    order_data: observable,
    order_interface: observable,
    orderHistoryList: observable,
    partner_code: observable,
    payment_issuers: observable,
    payment_status: observable,
    shippingCosts: observable,
    shopping_cart: observable,
    shopping_cart_db: observable,
    shopping_cart_db_id: observable,
    shopping_cart_db_guid: observable,
    wishList: observable,
    wishList_db: observable,
    addToCart: action,
    addToWishList: action,
    calculateOrderVAT: action,
    calculateShipping: action,
    calculateTotal: action,
    checkDiscountOverruled: action,
    checkOnWishList: action,
    checkShippingCostsCustom: action,
    checkShippingCostsOverruled: action,
    clearAllShoppingCarts: action,
    clearCartAfterPayment: action,
    clearPartnerCode: action,
    closeDialogCompareCarts: action,
    closeDialogCompareWishLists: action,
    closeDialogConcat: action,
    closeErrorWindow: action,
    closePaymentWindow: action,
    concatLSandDB: action,
    copyDeliveryAddresData: action,
    createNewOrder: action,
    deleteShoppingCartFromDB: action,
    deleteShoppingCartFromLS: action,
    deleteUseCaseFromLS: action,
    deleteOrderLineShipping: action,
    eidtOrder: action,
    getAccountOrders: action,
    getAffiliateId: action,
    getCountries: action,
    getOrderAvailability: action,
    getOrderData: action,
    getOrderItemsData: action,
    //getOrderPaymentDetails: action,
    getOrderId: action,
    getOrderStatus: action,
    getPaymentStatus: action,
    getUseCaseFromLS: action,
    handleBankTransfer: action,
    handlePaymentProcess: action,
    monitorOrderData: action,
    monitorStartPayment: action,
    openDialogCompareCarts: action,
    openDialogComparewishLists: action,
    openDialogConcat: action,
    presetDataProfile: action,
    registerAnonymousClient: action,
    registerClientFromOrder: action,
    removeFromCart: action,
    removeFromWishList: action,
    removeOrder: action,
    resetCart: action,
    resetCartUser: action,
    resetOrder: action,
    resetOrderData: action,
    resetOrderStatus: action,
    resetBusinessData: action,
    resetDeliveryAddresData: action,
    resetOrderSettings: action,
    retrieveShoppingCart: action,
    retrieveShoppingCartFromDB: action,
    retrieveShoppingCartFromLS: action,
    retrieveWishList: action,
    retrieveWishListFromDB: action,
    retrieveWishListFromLS: action,
    returnOrderId: action,
    saveRowPrice: action,
    saveOrderDataProfile: action,
    saveUseCaseToLS: action,
    //sendOrderConfirmation: action,
    setAffiliateId: action,
    setCartChanges: action,
    setCartView: action,
    setClientId: action,
    setDataBusiness: action,
    setDataProfile: action,
    setDataDeliveryAddress: action,
    setDiscountOverruled: action,
    setOrderProfileFromUser: action,
    setOrderRemark: action,
    setOrderRemarkLS: action,
    setPartnerCode: action,
    setPaymentData: action,
    setSettingBusiness: action,
    setSettingDeliveryAddress: action,
    setShippingCosts: action,
    setTotalPrice: action,
    submitDeleteOrderLine: action,
    syncShoppingCartWithOrderlines: action,
    unloadPaymentWindow: action,
    updateOrderEmail: action,
    useCartDB: action,

    concatConstructResults: action,
    constructShoppingCart: action,
    retrieveShoppingCartDirect: action,
    retrieveOrderlinesDirect: action,

    calculateTotalCartItemsAnonymous: computed,
    calculateTotalCartItemsUser: computed,
    calculateTotalOrder: computed,
    calculateWeight: computed
})

export default StoreOrder;