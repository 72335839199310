import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import moment from "moment";
import { withTranslation } from 'react-i18next';

const BlockLatestUpdate = inject("stores") (
    observer (
        class BlockLatestUpdate extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    date: ''
                }
            }

            componentDidMount = () => {
                let now = new Date();
                let lastDate = moment(new Date());
                let updateDate = moment(lastDate).add(9, 'hours');
                                
                // if (moment(now).isBefore(updateDate)) {
                //     lastDate = moment(lastDate).add(-1, 'days').format("D MMM YYYY");
                // }

                lastDate = moment(updateDate).add(-2, 'days').format("D MMM YYYY");

                this.setState({
                    date: lastDate
                });
            }
  
            render() {
                return (
                    <div className="block block--latest-update">
                        Deze website is voor het laatst bijgewerkt op {this.state.date}
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockLatestUpdate);


