import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import Template from '../templates/template';
import * as content from '../utilities/content';

import SearchSelectSegment from '../components/search/search-select-segment';
import SearchResult from '../components/search/search-result';
import SearchMinimumChars from '../components/search/search-minimum-chars';

const SearchResults = inject("stores") (
    observer (
        class SearchResults extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    search: '',
                    shop: this.storeGeneral.searchShop,
                    feedbackChange: false,
                    reload: true
                };
            }

            componentDidMount = async () => {
                this.handleScroll();
                this.storeUi.setActiveMainNav(0);

                await (async() => this.getSearchParams(this.state.shop, this.props.location.search) )();

                const params = queryString.parse(this.props.location.search);

                this.storeUi.setPageView('total', this.storeUi.search_count);
                this.storeUi.setSearchOverlay(false);
                this.storeUi.setSearchInputFocus(false);

                //SbC on url reload
                this.getSearchResultsInit();
            }

            componentDidUpdate = async(prevProps, prevState) => {
                this.handleScroll();
                this.storeUi.setActiveMainNav(0);
                let section = '';

                const params = queryString.parse(this.props.location.search);
                let search_term = params.q;
                
                search_term = search_term.replace('?', "");
                search_term = search_term.replace('!', "");

                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    if (params.section) {
                        if ((params.section === 'sb' && this.storeGeneral.searchShop !== 'OWN') ||
                            (params.section === 'zb' && this.storeGeneral.searchShop !== 'SB') ||
                            (params.section === 'all' && this.storeGeneral.searchShop !== 'ALL')) {
                            section = params.section;
                            if (section === 'sb') {
                                this.storeGeneral.setShop("SHOP_SB");
                                this.storeGeneral.setActiveShop("SHOP_SB");
                                this.storeGeneral.setSearchShop('OWN');
                            }
                            if (section === 'zb') {
                                this.storeGeneral.setShop("SHOP_SB");
                                this.storeGeneral.setActiveShop("SHOP_SB");
                                this.storeGeneral.setSearchShop('SB');
                            }
                            if (section === 'all') {
                                this.storeGeneral.setShop("SHOP_ALL");
                                this.storeGeneral.setActiveShop("SHOP_ALL");
                                this.storeGeneral.setSearchShop('ALL');
                            }
                        }
                    }

                    if (this.state.search !== search_term) {
                        await (async() => this.getSearchParams(this.state.shop, this.props.location.search) )();
                    }

                    if (this.storeUi.search_value !== search_term) {
                        const path = "/search-results";
                        const str_search = "?q=" + this.storeUi.search_value;

                        this.props.history.push({
                            pathname: path,
                            search: str_search
                        })
                    }

                } else {
                    if (this.state.search !== search_term) {
                          await (async() => this.getSearchParams(this.state.shop, this.props.location.search) )();
                    }
                }

                this.storeUi.setPageView('total', this.storeUi.search_count);
            }

            componentWillUnmount = () => {
                const position = window.pageYOffset;
                this.storeUi.setFreezePos(position);
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            getSearchParams = async(shop, searchString) => {
                const params = queryString.parse(searchString);
                let section = '';

                if (params.q) {
                    let search_term = params.q;
                    search_term = search_term.replace('?', "");
                    search_term = search_term.replace('!', "");

                    if (params.section && params.section === 'sb') {
                        section = 'sb';
                        shop = 'OWN';

                        this.setState({
                            search: search_term,
                            section: section,
                            shop: shop
                        })
                    } else if (params.section && params.section === 'sball') {
                        section = 'sb';
                        shop = 'SB';

                        this.setState({
                            search: search_term,
                            section: section,
                            shop: shop
                        })
                    } else {
                        this.setState({
                            search: search_term,
                            feedbackChange: false,
                            reload: true
                        })
                    }                    

                    this.storeUi.setSearchValue(search_term);

                    let pc;
                    if (params.pc) {
                        pc = params.pc.trim();
                        this.storeOrder.setAffiliateId(pc);
                    }
                    if (params.PC) {
                        pc = params.PC.trim();
                        this.storeOrder.setAffiliateId(pc);
                    }
                }
            }

            getSearchResultsInit = async() => {
                const value = this.storeUi.search_value;
            
                let section = 'sb';
                await (async() => this.storeUi.getSearchCountSB(value, section))();
                await (async() => this.storeUi.getSearchResultsSimple('SHOP_SB', value, section, this.storeUi.page_view, false) )();

                section = '';
                await (async() => this.storeUi.getSearchCountSB(value, section))();
                await (async() => this.storeUi.getSearchResultsSB(value, '', this.storeUi.page_view))();
                await (async() => this.storeUi.getSearchResultsSimple('SHOP_SB', value, '', this.storeUi.page_view, false))();
                await (async() => this.storeUi.getSearchCount(value, section))();
                await (async() => this.storeUi.getSearchResultsSimple('SHOP_ALL', value, '', this.storeUi.page_view, false))();
            }
     
            render() {            
                return (
                    <Template
                        type = 'default'
                        header = {(this.storeUi.checkFeature('SHOP_ALL')) 
                            ?   this.storeGeneral.shop === 'SHOP_ALL' ? "search_results_all" : "search_results_sb"
                            :   "search_results"
                        }
                        useScroll = {true}
                    >
                        {this.storeUi.checkFeature('SHOP_ALL') &&
                            (this.storeUi.search_value.trim().length >= 3 || content.searchChars(this.storeUi.search_value.trim())) &&
                                <div className="content-segment--clean">
                                    <SearchSelectSegment
                                        updateSearch = {this.updateSearch}
                                        searchShop = {this.storeGeneral.searchShop}
                                        searchTerm = {this.state.search}
                                    />
                                </div>
                        }

                        {(this.storeUi.search_value.trim().length >= 3 || content.searchChars(this.storeUi.search_value.trim()))
                            ?   <SearchResult 
                                    searchShop = {this.storeGeneral.searchShop}
                                    searchTerm = {this.state.search}
                                    page = {this.storeUi.page_view.page}
                                    inview = {this.storeUi.page_view.inview}
                                    grid = {"full"}
                                />
                            :   <SearchMinimumChars

                                />
                        }
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(SearchResults);
