import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";

import Template from '../../templates/template';

const Business = inject("stores") (
    observer (
        class Business extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }
      
            render() {
                return (
                    <Template
                        type = {'default'}
                        header = {"business"}
                    >
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p>Wanneer je een boekhandel hebt, kun je al onze uitgaven bestellen via het Centraal Boekhuis te Culemborg.<br/>
                                Indien je géén boekhandel bent, maar een andere bedrijfsvorm hebt, kun je ook een zakelijk account aanmaken.</p>
                                <p>Tevens kun je partner worden: lees over partner worden:<br/>
                                <Link 
                                    className="link--read-more"
                                    to="/partners">{i18n.t("navigation_links.partners")}
                                </Link>
                                </p>
                                <p>Indien je vragen hebt kun je altijd contact met ons opnemen:<br/>
                                <Link 
                                    className="link--read-more"
                                    to="/contact">{i18n.t("navigation_links.contact")}
                                </Link>
                                </p>
                                <p>Of indien je liever wilt dat wij contact met je opnemen, kun je dit hier doorgeven:<br/>
                                <Link 
                                    className="link--read-more"
                                    to="/call-me-back">{i18n.t("navigation_links.call_me_back")}
                                </Link></p>
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Business;
