import React, { Component }  from 'react';
import { inject, observer } from "mobx-react";
import sizeMe from 'react-sizeme';

import Branding from '../header/branding';
import NavigationMain from '../header/navigation-main';
import NavigationSecundary from '../header/navigation-secundary';
import NavigationSb from '../header/navigation-sb';
import NavigationTools from '../header/navigation-tools';
import NavigationToggle from '../header/navigation-toggle';
import NavigationMobile from '../header/navigation-mobile';
import Search from '../header/search';

const Header = inject("stores") (
    observer (
        class Header extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;

                this.state = {
                    class_header: (this.storeGeneral.shop === 'SHOP_ALL' ? '--shop-all' : '--shop-sb'),
                    class_feature: ''
                }
            }

            componentDidMount = async() => {
                this.setState({
                    class_header: (this.storeGeneral.shop === 'SHOP_ALL' ? '--shop-all' : '--shop-sb'),
                    class_feature: await this.storeUi.checkFeature('SHOP_ALL') ? '--feature-all' : ''
                })
            }

            componentDidUpdate = (prevProps) => {
                const { width, height } = this.props.size;
                this.storeUi.setHeightHeader(this.props.size.height);

                if (prevProps.shop !== this.props.shop) {
                    this.setState({
                        class_header: (this.storeGeneral.shop === 'SHOP_ALL' ? '--shop-all' : '--shop-sb'),
                        class_feature: this.storeUi.checkFeature('SHOP_ALL') ? '--feature-all' : ''
                    })
                }

                if (prevProps.access_level !== this.props.access_level) {
                    this.setState({
                        class_feature: this.storeUi.checkFeature('SHOP_ALL') ? '--feature-all' : ''
                    })
                }
            }

            render() {
                return (
                    <header className={"wrapper-header " + this.state.class_header + ' ' + this.state.class_feature}>
                        <div className="header-content">
                            {this.storeUi.checkFeature('SHOP_ALL') && this.storeGeneral.shop === 'SHOP_ALL'
                            ?   <div className="header-content__payoff">Een dag zonder boek ...</div>
                            :   <div className="header-content__payoff">Uitgeverij & de grootste website voor zelfontwikkeling</div>
                            }
                            <div className="header-content__top">
                                <Branding 

                                />
                                <NavigationTools 

                                />
                            </div>
                            {(this.storeUi.checkFeature('SHOP_ALL') &&
                                this.storeUi.templateView !== "clean") &&
                                <NavigationToggle
                                    active_nav = {this.storeUi.active_togglenav} 
                                    activeShop = {this.storeGeneral.shop}
                                /> 
                            }
                            <div className="wrapper-navigation">
                                {this.storeUi.templateView !== "clean" &&
                                    <div className="navigation">
                                        <NavigationMain />   
                                        {!this.storeUi.checkFeature('SHOP_ALL') &&
                                            <NavigationSb />                        
                                        }
                                        <NavigationSecundary />
                                    </div>
                                }
                                <Search 
                                
                                />
                            </div>
                            <NavigationMobile />
                        </div>
                    </header>
                )
            }
        }
    )
)

export default sizeMe({ monitorHeight: true })(Header);

