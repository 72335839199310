import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import PropTypes from 'prop-types';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import ButtonDialog from '../../elements/button-dialog';
import ImgCover from '../../elements/img-cover';

const DialogConfirmation = inject("stores") (
    observer (
        class DialogConfirmation extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);

                // const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                // if (this.storeUser.user_data.is_loggedin) {    
                //     if (!this.storeOrder.order_interface.isDialogCompareCartsOpen) {
                //         (async() => await this.storeOrder.retrieveShoppingCartFromDB(id, 'init'))(); 
                //         (async() => await this.storeOrder.calculateTotal())();
                //         //this.storeOrder.calculateTotalCart();
                //     }
                // }
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.props.onClose();
                }
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                return (
                    <div className="overlay">
                        <div className="dialog dialog--confirmation">
                            <div className="dialog-header">
                                <h2>{this.props.title}</h2>
                                {this.props.subtitle &&
                                    <span className="dialog-header__subtitle">{this.props.subtitle}</span>
                                }
                            </div>
                            <div className="dialog-content">
                                {this.props.sku &&
                                    <ImgCover
                                        sku = {this.props.sku}
                                        aspect = {'--fixed-height'}
                                    />
                                }
                                {this.props.children}
                            </div>
                            {this.props.buttons &&
                                <div className="dialog-footer">
                                    {this.props.buttons.map((button, i) => (
                                        <ButtonDialog
                                            key = {i}
                                            caption = {button.text}
                                            onAction = {button.onClick}
                                            btnClass = {button.btnClass}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                );
            }
        }
    )
)

export default withTranslation()(DialogConfirmation);
