import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const SearchCountOverlay = inject("stores") (
    observer (
        class SearchCountOverlay extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = async () => {
                let section = '';
               
                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    // section = 'sb';
                    // await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                    // section = '';
                    // await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                    // await (async() => this.storeUi.getSearchCount(this.props.searchTerm, section) )();

                    if (this.props.searchShop === 'OWN') {
                        section = 'sb';
                        //await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();

                        if (this.storeUi.search_count === null) {
                            //this.storeGeneral.setSearchShop("ALL");
                            this.storeUi.setSearchFeedback(true);
                            //await (async() => this.storeUi.getSearchCount(this.props.searchTerm, '') )();
                        } else {
                            this.storeUi.setSearchFeedback(false);
                        }
                    }
                    if (this.props.searchShop === 'SB') {
                        section = '';
                        //await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();

                        if (this.storeUi.search_count === null) {
                            //this.storeGeneral.setSearchShop("ALL");
                            this.storeUi.setSearchFeedback(true);
                            //await (async() => this.storeUi.getSearchCount(this.props.searchTerm, '') )();
                        } else {
                            this.storeUi.setSearchFeedback(false);
                        }
                    }    
                    if (this.props.searchShop === 'ALL') {
                        //await (async() => this.storeUi.getSearchCount(this.props.searchTerm, '') )();
                    } 
                } else {
                    section = '';
                    //await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                }
            }

            componentDidUpdate  = async (prevProps) => {
                let section = '';

                if (prevProps.searchTerm !== this.props.searchTerm) {
                    // if (this.props.searchShop == 'own') {
                    //     section = 'sb';
                    //     await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                    // }

                    // if (this.props.searchShop == 'sb') {
                    //     section = '';
                    //     await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                    // }

                    // if (this.props.searchShop == 'all') {
                    //     await (async() => this.storeUi.getSearchCount(this.props.searchTerm, section) )();
                    // }
                    

                    // if (this.storeUi.checkFeature('SHOP_ALL')) {
                    //     if (this.props.searchShop === 'OWN') {
                    //         section = 'sb';
                    //         await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();

                    //         if (this.storeUi.search_count === null) {
                    //             this.storeGeneral.setSearchShop("ALL");
                    //             this.storeUi.setSearchFeedback(true);
                    //             await (async() => this.storeUi.getSearchCount(this.props.searchTerm, '') )();
                    //         } else {
                    //             this.storeUi.setSearchFeedback(false);
                    //         }
                    //     }
                    //     if (this.props.searchShop === 'SB') {
                    //         section = '';
                    //         await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();

                    //         if (this.storeUi.search_count === null) {
                    //             this.storeGeneral.setSearchShop("ALL");
                    //             this.storeUi.setSearchFeedback(true);
                    //             await (async() => this.storeUi.getSearchCount(this.props.searchTerm, '') )();
                    //         } else {
                    //             this.storeUi.setSearchFeedback(false);
                    //         }
                    //     }    
                    //     if (this.props.searchShop === 'ALL') {
                    //         await (async() => this.storeUi.getSearchCount(this.props.searchTerm, '') )();
                    //     } 
                    // } else {
                    //     section = '';
                    //     await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                    // }
                }

                // if (prevProps.searchShop !== this.props.searchShop) {
                //     if (this.storeUi.checkFeature('SHOP_ALL')) {
                //         if (this.props.searchShop === 'OWN') {
                //             section = 'sb';
                //             //await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                //         }
                //         if (this.props.searchShop === 'SB') {
                //             section = '';
                //             //await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                //         }
                //         if (this.props.searchShop === 'ALL') {
                //             //await (async() => this.storeUi.getSearchCount(this.props.searchTerm, '') )();
                //         } 
                //     } else {
                //         section = '';
                //         //await (async() => this.storeUi.getSearchCountSB(this.props.searchTerm, section) )();
                //     }
                // }
            }

            render() {
                return (
                    <React.Fragment>
                        <div className="content-segment-clean search-counter">
                            <span 
                                className="search-counter__count"
                            >
                                {this.props.searchShop === 'own' 
                                    ?   this.storeUi.search_count_own ? this.storeUi.search_count_own : 0
                                    :   ''
                                }
                                {this.props.searchShop === 'sb'
                                    ?   this.storeUi.search_count_sb ? this.storeUi.search_count_sb : 0
                                    :   ''
                                }
                                {this.props.searchShop === 'all'
                                    ?   this.storeUi.search_count_all ? this.storeUi.search_count_all : 0
                                    :   ''
                                }
                            </span>
                            <span 
                                className="search-counter__label"
                            >
                                {this.props.searchShop === 'own' 
                                    ?   (this.storeUi.search_count_own  === 1 || this.storeUi.search_count_own  === '1') ? 'resultaat' : 'resultaten'
                                    :   ''
                                }
                                {this.props.searchShop === 'sb'
                                    ?   (this.storeUi.search_count_sb === 1 || this.storeUi.search_count_sb === '1') ? 'resultaat' : 'resultaten'
                                    :   ''
                                }
                                {this.props.searchShop === 'all'
                                    ?   (this.storeUi.search_count_all === 1 || this.storeUi.search_count_all === '1') ? 'resultaat' : 'resultaten'
                                    :   ''
                                }
                            </span>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default SearchCountOverlay;
