import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import TemplateForms from '../../templates/template-forms';

import FormTextIncomplete from '../../elements/form-text-incomplete';
import RowText from '../form-rows/row-text';

const FormEventCouponCheck = inject("stores") (
    observer (
        class FormEventCouponCheck extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeForms = this.props.stores.storeForms;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                
                this.state = {
                    formControls: { 
                        coupon: {
                            type: "text",
                            label: i18n.t("forms.label.coupon"),
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        }
                    },
                    formIsValid: false,
                    formIsSubmitted: false,
                    btn_class: '--disabled',
                    error: false
                }
            }

            componentWillUnmount = () => {
                this.storeForms.clearFormFeedback();
            }

            componentDidMount = () => {
                this.initForm();
            }

            initForm = () => {
                const updatedControls = this.storeForms.initForm(this.state.formControls);

                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateRow = (name, value, valid) => {      
                this.storeForms.clearFormFeedback();

                const validCode = this.props.code;

                if (value && validCode && value.toLowerCase() === validCode.toLowerCase()) {
                    const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                    this.setState({
                        formControls: updatedControls,
                    }, () => this.validateForm()); 
                } else {
                    this.setState({
                        formIsValid: false,
                        btn_class: '--disabled'
                    });
                }
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            checkCouponCode = (e) => {
                e.preventDefault();

                if (this.state.formIsValid) {
                    this.props.handleSubmit();
                    this.afterSubmit('disable');
                }
            }

            afterSubmit = (action) => {  
                if (action === 'disable') {
                    this.setState({
                        formIsValid: false,
                        formIsSubmitted: true,
                        btn_class: '--disabled'
                    }); 
                }
            } 

            render() {
                return (
                    <TemplateForms
                        formClass = "contact"
                        type = 'vraag/opmerking'
                        msg_success = {i18n.t("form_feedback.success_subscription")}
                    >
                        <form className="form form--event">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                <React.Fragment>
                                    {((this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email") &&
                                        (key !== "guestFirstname" && key !== "guestMiddlename" && key !== "guestLastname")) &&
                                        <RowText 
                                            type = {this.state.formControls[key].type}
                                            name = {key}
                                            label = {this.state.formControls[key].label}
                                            value = {this.state.formControls[key].value}
                                            validationRules = {this.state.formControls[key].validationRules}
                                            onValidate = {this.validateRow}
                                        />
                                    }
                                </React.Fragment>
                            ))}
                            
                            {this.state.btn_class !== '--active' && !this.state.formIsSubmitted &&
                                <FormTextIncomplete />
                            }
                        </form>

                        <div className="form-row form-row--buttons">
                            <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.checkCouponCode(e)}>{i18n.t("button.goto_register_form")}</button>
                        </div>
                    </TemplateForms>
                )
            }
        }
    )
)

export default withTranslation()(FormEventCouponCheck);
