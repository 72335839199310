import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import ImgCover from '../../elements/img-cover';
import BookLang from '../../elements/book-lang';
import BlockBookStock from '../../components/block/block-book-stock';

import * as utilities from '../../utilities/formats';
import * as content from '../../utilities/content';

const SearchBlockTopScore = inject("stores") (
    observer (
        class SearchBlockTopScore extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeStock = this.props.stores.storeStock;

                this.minLength = 5;

                this.state = {
                    elevatedHeader: 'Beste match met score',
                    item: {}
                }
            }

            componentDidMount = async() => {
                let searchValue = this.props.searchValue.toLowerCase();
                // let avail;
                // let title ;
                // let topItem = {};
                // let hasTopItem = false;

                if (searchValue.length >= this.minLength) {
                    this.setState({
                        item: this.props.topItem
                    })
                    // topItem = this.props.itemsAll[0];
                    // hasTopItem = true;

                
                    // if (hasTopItem) {
                    //     this.setState({
                    //         item: topItem
                    //     })
                    // } else {
                    //     this.setState({
                    //         item: null
                    //     })
                    // }
                } else {
                    this.setState({
                        item: null
                    })
                }               
            }

            componentDidUpdate = async(prevProps) => {
                let searchValue = this.props.searchValue.toLowerCase();
                //let minLength = this.storeGeneral.settings_list["minLengthSearchSuggestion"];
                // let avail;
                // let title;
                let topItem = {};
                let hasTopItem = false;

                if (prevProps.searchValue !== this.props.searchValue) {
                    if (searchValue.length >= this.minLength) {
                        this.setState({
                            item: this.props.topItem
                        })

                        // topItem = this.props.itemsAll[0];
                        // hasTopItem = true;
                    
                        // if (hasTopItem) {
                        //     this.setState({
                        //         item: topItem
                        //     })
                        // } else {
                        //     this.setState({
                        //         item: null
                        //     })
                        // }
                    } else {
                        this.setState({
                            item: null
                        })
                    }
                }

                // if (prevProps.itemsAll[0].sku !== this.props.itemsAll[0].sku) {
                //     topItem = this.props.itemsAll[0];
                //     hasTopItem = true;
                
                //     if (hasTopItem) {
                //         this.setState({
                //             item: this.props.topItem
                //         })
                //     } else {
                //         this.setState({
                //             item: null
                //         })
                //     }
                // }
                // if (prevProps.topItem.sku !== this.props.topItem.sku) {
                //     topItem = this.props.topItem;
                //     hasTopItem = true;
                
                //     if (hasTopItem) {
                //         this.setState({
                //             item: this.props.topItem
                //         })
                //     } else {
                //         this.setState({
                //             item: null
                //         })
                //     }
                // }
            }

            handleOnClick = (result) => {
                this.storeUi.setCurrentBook(result.sku);

                //SbC set topnav to SHOP_ALL
                this.storeUi.setActiveToggleNav(3);
                this.storeGeneral.setActiveShop('SHOP_ALL');

                if (this.props.shop) {
                    this.storeGeneral.setActiveShop(this.props.shop);
                }
                
                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    //url = "/book/" + result.sku + "/" + id;
                    url = "/book/" + result.sku;
                } else {
                    url = "/book/" + result.sku;
                }
                this.props.history.push({
                    sku: result.sku,
                    pathname: url
                })

                this.storeUi.setSearchOverlay(false);
            }

            constructAuthor(firstname, prefix, lastname) {
                let author = '';

                if (this.state.item.author_firstname) {
                    author = this.state.item.author_firstname;
                    if (this.state.item.author_prefix) {
                        author = author + ' ' + this.state.item.author_prefix;
                    }
                    if (this.state.item.author_lastname) {
                        author = author + ' ' + this.state.item.author_lastname;
                    }
                } else {
                    if (this.state.item.author_lastname) {
                        author = this.state.item.author_lastname;
                    }
                    if (this.state.item.author_prefix) {
                        author = this.state.item.author_prefix + ' ' + author;
                    }
                }

                return author;
            }

            convertString(str) {
                let cStr = '';

                cStr = content.revertHTML(str);
                return cStr;
            }

            render() {
                return (
                    this.state.item  
                    ?   <React.Fragment>
                            <li 
                                className = {"--elevated --link"}
                                onClick = {(e) => this.handleOnClick(this.state.item)}
                                data-content = {this.state.elevatedHeader}
                            >
                                <div 
                                    className="search-item"
                                >
                                    <div 
                                        className="search-item__img"
                                    >
                                        <ImgCover
                                            sku = {this.state.item.sku}
                                        />
                                    </div>
                                    <div 
                                        className="search-item__data"
                                    >
                                        <div>
                                            {this.state.item.title &&
                                                <span
                                                    className="search-item__data--title"
                                                    dangerouslySetInnerHTML={{ __html: utilities.capitalizeTitle(this.state.item.title)}}
                                                />
                                            }
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.constructAuthor(this.state.item.author_firstname, this.state.item.author_prefix, this.state.item.author_lastname)}}
                                            />
                                            </div>
                                        <div 
                                            className="search-item__specs"
                                        >
                                            {this.state.item && 
                                                <ul className="block--specs-summary__list">
                                                    <li className="block--specs-summary__listitem">
                                                        <BookLang
                                                            book = {this.state.item}
                                                        />
                                                    </li>
                                                    <li className="block--specs-summary__listitem">{this.state.item.version}</li>
                                                    <li className="block--specs-summary__listitem">ISBN: {this.state.item.sku}</li>
                                                </ul>
                                            }

                                            <BlockBookStock 
                                                sku = {this.state.item.sku}
                                                book = {this.state.item}
                                                stock_code = {this.state.item.stock_code}
                                            ></BlockBookStock>
                                        </div>
                                    </div>
                                    
                                </div>
                            </li>
                        </React.Fragment>
                    : null
                )
            }
        }
    )
)

export default withRouter(SearchBlockTopScore);
