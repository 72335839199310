import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import DialogConfirmation from '../dialogs/dialog-confirmation';
import * as utilities from '../../utilities/formats';
import validate from '../../utilities/validation-rules';

import i18next from 'i18next';

const FeedbackOrderLineDiscount = inject("stores") (
    observer (
        class FeedbackOrderLineDiscount extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    amount: 1,
                    discount: 0,
                    discount_perc: 0,
                    discount_overruled: 0
                }
            }

            componentDidMount = async() => {
                this.setState({
                    amount: this.props.amount,
                    discount: this.props.discount,
                    discount_perc: this.props.discount_perc,
                    discount_overruled: this.props.discount_overruled
                })
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.sku !== this.props.sku
                || (prevProps.discountPerc !== this.props.discountPerc)
                || (prevProps['amount'] !== this.props['amount'] || this.state.amount !== this.props['amount'] ) 
                || (prevProps.discount !== this.props.discount)
                || (prevProps.discount_perc !== this.props.discount_perc)
                || (prevProps.discount_overruled !== this.props.discount_overruled)) {
                    this.setState({
                        discountPerc: this.props.discountPerc,
                        amount: this.props['amount'],
                        discount: this.props.discountPerc,
                        discount_perc: this.props.discount_perc,
                        discount_overruled: this.props.discount_overruled
                    })
                }
            }

            render() {
                return (
                    <React.Fragment>
                        {(this.state.discount_overruled === 0) &&
                            <div className="products-table__row--details">
                                {(this.state.discountPerc > 0)
                                    ?   <div className="products-table__row--discount --has-discount">Er is op dit product een korting van {Math.round(this.props.discountPerc)}% toegepast
                                            {((this.state.discount === this.state.discount_overruled && this.state.amount > 1 && this.props.discount_overruled === 1) || (this.props.discount_overruled !== 1)) &&
                                                this.state.amount > 1 &&
                                                    <span>&nbsp;(meerdere besteld)</span>
                                            }
                                        </div>
                                    :   null
                                }
                            </div>
                        }

                        {this.state.discount_overruled !== 0 &&
                            <div className="products-table__row--discount --has-discount">
                                Er is op dit product een korting van {this.state.discount_overruled}% toegepast.
                            </div>
                        }   
                   </React.Fragment>
                )
            }
        }
    )
)

export default FeedbackOrderLineDiscount;
