import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';

import * as utilities from '../../utilities/formats';
import Template from '../../templates/template';

import BlockViewVideo from '../../components/block/block-view-video';
import BlockViewBookPreview from '../../components/block/block-view-book-preview';
import { checkDiscountsOverruled } from '../../communicator/order';

const ContentDialog = inject("stores") (
    observer (
        class ContentDialog extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }

            render() {
                return (
                    this.props.children
                )
            }
        }
    )
)

const PaymentResult = inject("stores") (
    observer (
        class PaymentResult extends Component {

            constructor(props) {
                super(props);
                this.props = props;
              
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    preview_pdf : this.storeUi.banner.sku,
                    redirect: false,
                    guid: '',
                    order_id: 0
                }
            }

            componentDidMount = async () => {
                //const { history } = this.props;
                const guid = this.props.location && this.props.location.state && this.props.location.state.guid;
                
                const payment_status = await this.storeOrder.getOrderStatus(guid);
                const order_id = await this.storeOrder.getOrderId(guid);
                const order_data = await this.storeOrder.getOrderLinesData(order_id);
                const total_costs = this.calcTotalCosts(order_data);

                if (guid === null || guid === undefined) {
                    this.setState({
                        redirect: true
                    });
                } else {
                    this.setState({
                        guid: guid,
                        order_id: order_id,
                        total_costs: total_costs,
                        payment_status: payment_status
                    });
                }  

                // history.pushState(null, null, this.props.location.href);
                // window.onpopstate = function () {
                //     console.log('SbC stay')
                //     history.go(1);
                // };
                
                // history.listen((newLocation, action) => {
                //     if (action === "PUSH") {
                //       if (
                //         newLocation.pathname !== this.currentPathname ||
                //         newLocation.search !== this.currentSearch
                //       ) {
                //         // Save new location
                //         this.currentPathname = newLocation.pathname;
                //         this.currentSearch = newLocation.search;
              
                //         // Clone location object and push it to history
                //         history.push({
                //           pathname: newLocation.pathname,
                //           search: newLocation.search
                //         });
                //       }
                //     } else {
                //       // Send user back if they try to navigate back
                //       history.go(1);
                //     }
                // });
                
                
            }

            componentDidUpdate = async() => {
                const guid = this.props.location && this.props.location.state && this.props.location.state.guid;
                const payment_status = await this.storeOrder.getOrderStatus(guid);

                if (payment_status !== this.state.payment_status) {
                    this.setState({
                        payment_status: payment_status
                    });
                }

                this.storeOrder.clearAllShoppingCarts(this.storeUser.user_data.is_loggedin);
            }

            calcTotalCosts = (order_data) => {
                let total = 0;
                let total_row = 0;
                order_data.map((order_line) => {
                    total_row = order_line.costs * order_line.amount * (1 - (order_line.discount / 100));
                    total_row = Math.round(total_row * 100) / 100;
                    total = total + total_row;
                });
                
                return utilities.displayPrice(total);
            }

            continueShopping = () => {
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                this.storeOrder.deleteUseCaseFromLS();
                this.storeOrder.setCartView(id, "client");
                this.props.history.push("/");
            }

            gotoOrders = () => {
                let url = "/account/orders/";
                this.props.history.push(url);
            }

            resetOrder = (use_case) => {
                this.storeOrder.resetOrder(use_case, this.storeUser.user_data.is_loggedin);
                this.storeUi.setReturnState("");
            }

            gotoPayment = () => {
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                this.storeOrder.setCartView(id, "payment");
                this.storeUi.setUseCase("inorder");
                let url = "/shoppingcart/payment";
                this.props.history.push(url);
            }

            openDialogPaymentInfo = () => {
                this.storeUi.setContentComponent(
                    <ContentDialog
                        title = {'Betalingsinformatie bankoverschrijving'}
                    >
                        <div className="form-row form-row--feedback">
                            <span className="feedback__title">Je hebt gekozen voor handmatige overschijving.</span>
                            <p>Maak het totaalbedrag van <span className="amount amount--inline">{this.state.total_costs}</span> van je bestelling over naar:</p>
                            <ul>
                                <React.Fragment>
                                    <li className="feedback__section">Rabobank te Zeist, rekening: NL56 RABO 0357 5205 80<br/>
                                        ten name van Belfra Publishers for Success bv
                                    </li>
                                    <li className="feedback__section">
                                        of:<br/>
                                        Triodosbank te Zeist, rekening: NL35 TRIO 0391 1028 26<br/>
                                        ten name van Belfra Publishers for Success bv
                                    </li>
                                    <li className="feedback__section">
                                        Dolderseweg 49<br/>
                                        3734 BB Den Dolder<br/>
                                        onder vermelding van "order <strong>{this.state.order_id}</strong>"
                                    </li>
                                </React.Fragment>

                                {(this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isNL) && (this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isBE) &&
                                    <li className="feedback__section">
                                        <b>Vanuit het buitenland:</b><br/>
                                        Gebruik de volgende gegevens voor een overschrijving vanuit het buitenland:<br/>
                                        Swift-adres (BIC#): RABONL2U<br/>
                                        IBAN: NL56 RABO 0357 5205 80<br/>
                                        ten name van Belfra Publishers for Success bv
                                        onder vermelding van "order <strong>{this.state.order_id}</strong>"
                                    </li>
                                }

                                {(this.storeOrder.order_data.billing_address.country_code !== this.storeOrder.settings_list.isNL) && (this.storeOrder.order_data.billing_address.country_code == this.storeOrder.settings_list.isBE) &&
                                    <li className="feedback__section">
                                        <b>Vanuit Belgi&euml;</b><br/>
                                        Gebruik de volgende gegevens voor een overschrijving vanuit Belgi&euml;:<br/>
                                        BNP Paribas Fortis rekening: BE71 0016 6035 5969<br/>
                                        Swift-adres (BIC#): GEBABEBB<br/>
                                        ten name van Belfra Publishers for Success bv<br/>
                                        onder vermelding van "order <strong>{this.state.order_id}</strong>"
                                    </li>
                                }
                            </ul>
                        </div>
                    </ContentDialog>
                )
                this.storeUi.openDialogContent();
            } 

            setOrderId = () => {
                let order_id;
                if (this.props.type == "open_order") {
                    order_id = this.storeUi.order_to_process.id;
                } else {
                    order_id = this.storeOrder.order_data.order_id;
                }

                return order_id;
            }
     
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/"} 
                    />;
                }
                
                const payment_status = this.state.payment_status;
                               
                let use_case = this.storeOrder.getUseCaseFromLS();

                if (payment_status === "banktransfer") {
                    this.openDialogPaymentInfo();
                }

                //SbC:: payment_status: "payment_confirmed", "payment_completed",  "completed", "banktransfer"
                if (payment_status !== "void" && payment_status !== "pending") {
                    this.resetOrder(use_case, this.storeUser.user_data.is_loggedin);
                };

                if (!this.storeUser.user_data.is_loggedin && payment_status !== "void" && payment_status !== "pending") {
                    this.storeOrder.deleteShoppingCartFromLS();
                    this.resetOrder(use_case, this.storeUser.user_data.is_loggedin);
                }

                (this.storeUser.user_data.is_loggedin && payment_status === "void") && this.resetOrder(use_case, this.storeUser.user_data.is_loggedin);
                (this.storeUser.user_data.is_loggedin && use_case !== 'open_order') && this.storeOrder.deleteShoppingCartFromDB(this.storeUser.user_data.user_id);
                
                return (
                    <Template
                        type = 'single-column'
                        header = {(payment_status === 'payment_confirmed' || payment_status === 'payment_completed' || payment_status === 'banktransfer')
                            ?   (payment_status === 'banktransfer')
                                ?   "payment_pending"
                                :   "payment_success"
                            :   (payment_status === 'payment_error'|| payment_status === 'void')
                                ?   "payment_error"
                                :   "payment_processing"
                        }
                    >   
                        {(payment_status === 'payment_confirmed' || payment_status === 'payment_completed') &&
                            <div className="content-segment">
                                <div className="content-segment__content">
                                <p>Je bestelling wordt zo snel mogelijk verstuurd.</p>
                                    <div className="cart-confirmation--completed">    
                                        <div className="cart__actions">
                                            <button className="btn btn--primary" onClick={(e) => this.continueShopping(e)}>{i18n.t("button.backto_home")}</button>
                                        </div>
                                        <BlockViewVideo />
                                        <BlockViewBookPreview 
                                            sku = {this.state.preview_pdf}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {payment_status === "void" &&
                           <div className="content-segment">
                                {this.storeUser.user_data.is_loggedin
                                    ?   <div className="content-segment__content">
                                            <p>Je bestelling is opgeslagen in je account, maar nog niet betaald en kan daardoor niet verzonden worden.</p>
                                            <h3 className="error">Wat kun je doen?</h3>
                                            <p>Via je bestelgeschiedenis in je account kun je op een later tijdstip alsnog voor deze bestelling betalen. Hier kun je ook kiezen voor een andere betaalmethode. Neem contact met ons op indien je problemen blijft ondervinden bij het afronden van je betaling.</p>
                                            <div className="cart-confirmation--completed">
                                                <div className="cart__actions">
                                                    <Link 
                                                        to="/account/orders"
                                                        className="btn btn--primary">Ga naar 'Mijn bestellingen'
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    :   <div className="content-segment__content">
                                            <p>Je bestelling is niet betaald en kan daardoor niet verzonden worden.</p>
                                            <p>Je kunt het later nog een keer proberen of kies een andere betaalmethode.<br/>
                                            Neem contact met ons op indien je problemen blijft ondervinden bij het afronden van je betaling.</p>
                                            <div className="cart__actions">
                                                <button className="btn btn--primary" onClick={(e) => this.gotoPayment(e)}>Kies een andere betaalmethode</button>
                                            </div>
                                        </div>
                                }
                            </div>
                        }

                        {payment_status === "banktransfer" &&
                            <div className="content-segment">
                                <div className="content-segment__content">
                                    <p>Je hebt aangegeven per bankoverschrijving te betalen.<br/>Zodra je betaling is ontvangen wordt je bestelling verstuurd.</p>
                                    <a href="#" onClick={(e) => this.openDialogPaymentInfo()}>Bekijk bankoverschijvingsgegevens</a>
                                    <h4>Wil je toch liever op een andere manier betalen?</h4>
                                    <p>Je kunt via Mijn Gegevens al je bestellingen bekijken en hier ook op een andere manier betalen.</p>
                                    <div className="cart-confirmation--completed">    
                                        <div className="cart__actions">
                                            <button className="btn btn--primary" onClick={(e) => this.gotoOrders(e)}>{i18n.t("button.goto_orders")}</button>
                                            <button className="btn btn--primary" onClick={(e) => this.continueShopping(e)}>{i18n.t("button.backto_home")}</button>
                                        </div>
                                        <BlockViewVideo />
                                        <BlockViewBookPreview 
                                            sku = {this.state.preview_pdf} 
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </Template>
                )
            }
        }
    )
)

export default  withRouter(PaymentResult);
