import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18n from "i18next";

import * as sort from '../../utilities/sort';
import * as ui from '../../utilities/ui';

import BlockEBook from '../block/block-ebook20';
import BlockPagination from './block-pagination';
import LiSort from './block-li-sort';

const BlocksEBooks = inject("stores") (
    observer (
        class BlocksEBooks extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeEBooks = this.props.stores.storeEBooks;
                this.storeOrder = this.props.stores.storeOrder;
                
                this.state = {
                    listBooks: [],
                    sorting: 'id',
                    view: 'summaries'
                }
                
                this.refScroll = React.createRef();
            }

            componentDidMount = () => {
                this.setState({
                    listBooks: this.storeEBooks.eBooksList
                })
            }

            componentDidMount = async() => {
                if (this.state.listBooks.length == 0) {
                    await this.storeEBooks.getEBooks();
                    this.setState({
                        listBooks: this.storeEBooks.eBooksList
                    })
                }
            }

            handleDownload = (e, id) => {
                e.preventDefault();
                e.stopPropagation();

            }

            handleBlocksNav = (e, view) => {
                this.setState({
                    view: view
                })
                this.storeUi.setPageView('view', view)
            }

            handleSort = (type, sorting_dir) => {
                this.setState({
                    sorting: type,
                    sorting_dir: sorting_dir
                }, () => this.setView());
            }

            removeSort = () => {
                const type = 'id';
                const sorting_dir = 'DESC';

                this.setState({
                    sorting: type,
                    sorting_dir: sorting_dir
                }, () => this.setView());
            }

            setView = () => {
                let listBooks;
                listBooks = this.storeEBooks.eBooksList
                    .sort(sort.compareValues(this.state.sorting, this.state.sorting_dir))

                this.setState({
                    listBooks: listBooks
                })
                
                this.scrollToComp();
            }

            scrollToComp = () => {              
                let offsetTop = this.refScroll.current.offsetTop - 30;
                ui.handleScroll(offsetTop);
            }
  
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/book/" + this.state.ebook_sku} 
                    />;
                }

                let blockview;
                if (this.storeUi.page_view.view && this.storeUi.page_view.view !== '') {
                    blockview = this.storeUi.page_view.view;                
                } else {
                    if (this.state.view == '' ) {
                        if (this.state.listBooks !== null &&  this.state.listBooks.length < 5) {
                            blockview = 'summaries'
                        } else {
                            blockview = 'covers'
                        }
                    } else {
                        blockview = this.state.view
                    }
                }

                const Li = (view) => {
                    const activeClass = (view.view === blockview) ? ' --active' : '';
                    return (
                        <li className={"blocks-nav__listitem blocks-nav__listitem--" + view.view + activeClass} onClick={(e) => this.handleBlocksNav(e, view.view)}>
                            <span>{view.view}</span>
                        </li>
                    )
                }

                

                return (
                    <div className="blocks blocks--ebooks" ref={this.refScroll}>
                        <div className="blocks__content">
                            <React.Fragment>
                                <div className="blocks__nav">
                                    <div className="blocks__nav-actions">
                                        <div className="blocks-nav__list --column">
                                            <ul className="blocks-nav__list--sort">
                                                <li>Sorteer&nbsp;op:</li>
                                                <LiSort 
                                                    sort = 'title'
                                                    handleSort = {this.handleSort}
                                                    sorting = {this.state.sorting}
                                                />
                                                <LiSort 
                                                    sort = 'author_lastname'
                                                    handleSort = {this.handleSort}
                                                    sorting = {this.state.sorting}
                                                />
                                            </ul>
                                            {this.state.sorting !== 'id' &&
                                                <span  className="blocks-nav__list--unsort --link"  onClick={(e) => this.removeSort()}>Verwijder sortering</span>
                                            } 
                                        </div>
                                        <div className="blocks-nav__list">
                                            <ul className="blocks-nav__list--view">
                                                <Li 
                                                    view = 'summaries'
                                                />
                                                <Li 
                                                    view = 'covers'
                                                />
                                                <Li 
                                                    view = 'list'
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* <BlockPagination 
                                    setView = {this.setView}
                                    total = {this.state.type == 'search' ? this.storeUi.search_count : this.storeUi.count_total}
                                    page = {this.storeUi.page_view.page}
                                /> */}
                            
                                <ul className={"blocks__list blocks__list--ebooks --" + blockview}>
                                {this.state.listBooks.map((ebook, i) => ( 
                                    <BlockEBook
                                        ebook = {ebook}
                                        view = {blockview}
                                    />
                                ))}
                                </ul>

                                {/* <BlockPagination 
                                    setView = {this.setView}
                                    total = {this.state.type == 'search' ? this.storeUi.search_count : this.storeUi.count_total}
                                    page = {this.storeUi.page_view.page}
                                /> */}
                            </React.Fragment>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default BlocksEBooks;

