import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

const FeedbackNoSearchResults = inject("stores") (
    observer (
        class FeedbackNoSearchResults extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;
            }

            componentDidMount = () => {
            }

            hasAlternative = (arr) => {
                let hasAlt = 0;
                
                if (arr !== null && arr[0] && arr[0].response) {
                    hasAlt = arr[0].response.numFound;
                } else {
                    hasAlt = 1;
                }

                return hasAlt;
            }

            feedbackShop = (shop) => {
                let fb = '';
                switch(shop) {
                    case 'OWN':
                        fb = fb + 'Boeken eigen uitgeverij';
                        break;
                    case 'SB':
                        fb = fb + 'Zelfhulpboeken';
                        break;
                    case 'ALL':
                        fb = fb + 'Alle boeken';
                        break;
                    default:   
               }

               return fb;
            }

            render() {     
                return (
                    <React.Fragment>
                        {this.storeUi.search_count_own === 0 && this.storeUi.search_count_sb === 0 && this.storeUi.search_count_all === 0
                        ?   <span>We kunnen geen resultaten voor je zoekopdracht vinden.</span>
                        :   <React.Fragment>
                                <span>Er zijn geen resultaten in <strong>{this.feedbackShop(this.storeGeneral.searchShop)}</strong> gevonden.</span>
                            </React.Fragment>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(FeedbackNoSearchResults);
