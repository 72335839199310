import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";

import * as ui from '../../utilities/ui';

import BlockPaginationPages from './block-pagination-pages';

const BlockPagination = inject("stores") (
    observer (
        class BlockPagination extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    total: 0,
                    inview: 25,
                    page: 1,
                    pages: 5
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeProducts = this.props.stores.storeProducts;

                this.views = [10, 25, 50, 100, 250]
            }

            componentDidMount = () => {            
                //const total = this.storeUi.count_total;
                const total = this.storeUi.page_view.total;
                const inview = this.storeUi.page_view.inview;
                let pages = Math.ceil(total / inview);
                this.setState({
                    total: total,
                    pages: pages,
                    page: this.storeUi.page_view.page
                })
            }

            componentDidUpdate = (prevProps) => {
                let total;
                if (prevProps.searchShop !== this.props.searchShop) {
                    if (this.storeGeneral.searchShop === 'OWN') {
                        total = this.storeUi.search_count_own;
                    }
                    if (this.storeGeneral.searchShop === 'SB') {
                        total = this.storeUi.search_count_sb;
                    }
                    if (this.storeGeneral.searchShop === 'ALL') {
                        total = this.storeUi.search_count_all;
                    }

                    this.setState({
                        total: total
                    })
                }
                if (prevProps.total !== this.props.total) {
                    this.setState({
                        total: this.props.total
                    })
                }
                if (prevProps.page !== this.props.page) {
                    this.setState({
                        page: this.props.page
                    })
                }
            }

            setInView = (nr) => {
                this.setState({
                    inview: nr
                })
                this.storeUi.setPageView('inview', nr);

                if (Math.ceil(this.storeUi.page_view.total/nr) < this.storeUi.page_view.page) {
                    this.storeUi.setPageView('page', 1);
                }

                this.props.setView();
            }

            setPage = (nr, from, to, actual) => {
                this.setState({
                    page: nr
                })

                if (nr !== actual) {
                    this.storeUi.setPageView('page', nr);
                    this.props.setView();
                }

                ui.handleScroll('top');
                this.storeUi.setFreezeScrolling(false);
            }

            setRange = async (nr, from, to) => {
                this.props.setView();
            }
  
            render() {
                let total = this.state.total;

                if (this.props.type == 'search') {
                    if (this.storeGeneral.searchShop == 'OWN') {
                        total = this.storeUi.search_count_own;
                    }
                    if (this.storeGeneral.searchShop == 'SB') {
                        total = this.storeUi.search_count_sb;
                    }
                    if (this.storeGeneral.searchShop == 'ALL') {
                        total = this.storeUi.search_count_all;
                    }
                }
                
                const inview = this.storeUi.page_view.inview;
                let pages = Math.ceil(total / inview);

                return (
                    <div className={"block block--pagination" + (this.props.location === 'top' ? ' block--pagination--top' : '')}>
                        <div className="block--pagination__inview">
                            <span>Per pagina: </span>
                            <ul className="block--pagination__list">
                                {this.views.map((view) => (
                                    <li className={"block--pagination__listitem --link" + ((view == this.storeUi.page_view.inview) ? ' --active' : '')} onClick={(e) => this.setInView(view)}>{view}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="block--pagination__paging">
                            <ul className="block--pagination__list">
                                <BlockPaginationPages 
                                    type = {this.props.type}
                                    total = {total}
                                    pages = {pages}
                                    page = {this.props.page}
                                    setPage = {this.setPage}
                                    setRange = {this.setRange}
                                />
                            </ul>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default BlockPagination;

