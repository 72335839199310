import * as apifetch from './fetch.js';

export async function getEBookDetails2(sku) {
    const urlEndpoint = '/catalog/getBookDetailsBySku.php';

    const postData = {
        shop: 'SHOP_SB',
        book_sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData)
}

export async function getEBookDetails(sku) {
    const urlEndpoint = '/v2/catalog/details/' + sku;

    return apifetch.sbFetch(urlEndpoint, 'GET')
}

export async function getEBooks() {
    const urlEndpoint = '/v2/catalog/ebooks';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

