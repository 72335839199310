import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import NewWindow from 'react-new-window';
import { withRouter } from "react-router-dom";
import moment from "moment";
import i18n from "i18next";

import * as utilities from '../../utilities/formats';
import * as account from '../../communicator/account';
import DialogConfirmation from '../dialogs/dialog-confirmation';
import ImgCover from '../../elements/img-cover';

const PaidOrderLine = inject("stores") (
    observer (
        class PaidOrderLine extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                
                this.state = {
                    sku: 0
                }
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = async() => {
                this.getISBN(this.props.id);
            }

            componentDidUpdate = async(prevProps) => {
                if (prevProps.id !== this.props.id) {
                    this.getISBN(this.props.id);
                }
            }

            getISBN = async(id) => {
                let isbn;

                if (id.toString().length < 10) {
                    isbn = await this.storeBooks.getBookISBN(id);
                } else {
                    isbn = id;
                }

                this.setState({
                    sku: isbn
                })
            }

            render() {
                return (
                    <div className="order-summary__image">
                        <ImgCover
                            sku = {this.state.sku}
                        />
                    </div>
                )
            }
        }
    )
)

const AccountOrderPaid = inject("stores") (
    observer (
        class AccountOrderPaid extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.state = {
                    order_total_incl: 0,
                    order_data: [],
                    tracktrace: []
                };

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            async componentDidMount() {
                let open_order_data = await this.storeOrder.getOrderLinesData(this.props.order.order_id);
                let arr_tracktrace = this.props.order.tracktrace ? this.props.order.tracktrace.split(',') : [];

                this.setState({
                    order_data: open_order_data,
                    tracktrace: arr_tracktrace
                })
            }

            async componentDidUpdate(prevProps) {
                
                if (prevProps.order.order_id !== this.props.order.order_id) {
                    let open_order_data = await this.storeOrder.getOrderLinesData(this.props.order.order_id);

                    this.setState({
                        order_data: open_order_data
                    })
                }
            }

            async getOrderLineDetails(order_line) {
                let book = {};
                book = await this.storeBooks.returnBookDetails(order_line.book_id);
                let order_data = this.state.order_data;
                order_data.push(book);
                this.setState({
                    order_data: order_data
                })
            }
            // processOpenOrder = (e) => {
            //     let total = 0;
            //     let total_row = 0;
            //     this.state.order_data && this.state.order_data.map((order_line) => {
            //         total_row = order_line.costs * order_line.amount * (1 - (order_line.discount / 100));;
            //         total = total + total_row;
            //     });

            //     this.storeUi.setUseCaseOrder('open_order');
            //     this.storeUi.setOrderToProcess(this.props.order.order_id, total);
            //     this.storeUi.setOrderToProcessKey("billing_country_code", this.props.order.billing_country_code);
            //     this.props.setView("payment");
            // }

            formatCosts = (costs) => {
                return utilities.displayPrice(costs);
            }

            calculateRowPrice = (order_line) => {
                let total;
                total = order_line.costs * order_line.amount * (1 - (order_line.discount / 100));
                
                return utilities.displayPrice(total);
            }

            calcShippingCosts = (total) => {
                return utilities.displayPrice(total);
            }

            calcTotalCosts = () => {
                let total = 0;
                let total_row = 0;
                this.state.order_data && this.state.order_data.map((order_line) => {
                    if ((order_line.book_id !== null && parseInt(order_line.book_id) !== 0) || order_line.description == 'Verzendkosten') {
                        total_row = order_line.costs * order_line.amount * (1 - (order_line.discount / 100));
                        total_row = Math.round(total_row * 100) / 100;
                        total = total + total_row;
                    }
                });
                
                return utilities.displayPrice(total);
            }

            getLinkTrackTrace = (tt) => {
                const url_tracktrace = 'https://jouw.postnl.nl/track-and-trace/' + tt + '-' + this.props.order.delivery_country_code + '-' + this.props.order.delivery_zipcode;
                return url_tracktrace;
            }

            viewOrderPaid = () => {

            }

            render() {
                return (
                    <React.Fragment>
                        <div className="order-summary">
                            <span className="order-summary__date">{moment(this.props.order.order_date.date).format("DD-MM-YYYY")}</span>
                            <div className="order-summary__id">
                                Order: {this.props.order.order_id}
                            </div>
                            {(this.props.order.tracktrace !== '' && this.props.order.tracktrace !== undefined && this.props.order.tracktrace !== null) &&
                                <div className="order-summary__tracktrace">
                                    Track&Trace nummer: 
                                    {this.state.tracktrace.map((tt, idx) => (
                                        <React.Fragment>
                                            {idx !== 0 &&
                                                ', '
                                            }
                                            <a className="--link" href={this.getLinkTrackTrace(tt)} target="_blank">{tt}</a>
                                        </React.Fragment>
                                    ))}
                                    
                                </div>
                            }
                            <div>
                                {this.state.order_data && this.state.order_data.map((order_line) => (
                                    order_line.book_id !== '0' && order_line.book_id !== 0 && order_line.book_id !== '' && order_line.book_id !== null && order_line.amount !== 0 
                                    ?   <div className="order-summary__content">
                                            <PaidOrderLine
                                                order_line = {order_line}
                                                id = {order_line.book_id}
                                            />
                                            <div className="order-summary__info">
                                                <span>
                                                    <span dangerouslySetInnerHTML={{ __html: order_line.description}} />: <span className="amount">{this.formatCosts(order_line.costs)}</span>, {order_line.amount}x
                                                    {order_line.sku !== 'undefined' &&
                                                        <span className="order-summary__sku">ISBN: {order_line.sku}</span>
                                                    }
                                                    {order_line.discount > 0 &&
                                                        <span className="order-summary__discount">(korting: {order_line.discount}%)</span>
                                                    }
                                                </span>
                                                <span className="amount">{this.calculateRowPrice(order_line)}</span>
                                            </div>
                                        </div>
                                    :   order_line.description === 'Verzendkosten' &&
                                            <div className="order-summary__info">
                                                <span>Verzendkosten:</span>
                                                <span className="amount">{this.calcShippingCosts(order_line.costs)}</span>
                                            </div>
                                ))}
                                <div className="order-summary__info order-summary__total">
                                    <span>Totaalbedrag:</span>
                                    <span className="amount">{this.calcTotalCosts()}</span>
                                </div>
                            </div>
                            <div className="order-summary__actions">
                                <span></span>
                                <a 
                                    href={this.urlAPI + "/invoices/invoice-pdf.php?GUID=" + this.props.order.guid} 
                                    target="_blank"
                                    className="btn btn--secundary">
                                    {i18n.t("button.view_invoice_pdf")}
                                </a>
                                <a 
                                    href={this.urlAPI + "/invoices/invoice.php?GUID=" + this.props.order.guid} 
                                    target="_blank"
                                    className="btn btn--secundary">
                                    Bekijk factuur
                                </a>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(AccountOrderPaid);


