import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import SearchInput from '../search/search-input';

const Search = inject("stores") (
    observer (
        class Search extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;   
                this.storeUser = this.props.stores.storeUser;  
            }

            componentDidMount = () => {
            }

            componentDidUpdate = () => {
            }

            render() {

                return (
                    <div className="search">
                        <SearchInput 
                            firstname = {(this.storeUser.user_data && this.storeUser.user_data.profile)
                                ?   this.storeUser.user_data.profile.firstname
                                :   ''
                            }    
                            search_value = {this.storeUi.search_value} 
                        />
                    </div>
                )
            }
        }
    )
)

export default Search;
