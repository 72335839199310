import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import CategoriesLi from './block-categories-li';

const BlockCategories = inject("stores") (
    observer (
        class BlockCategories extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {};

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = async() => {
                let categories;
                if (this.storeBooks.categoriesList.length === 0) {
                    categories = await this.storeBooks.returnCategories(this.storeGeneral.shop);
                } else {
                    categories = this.storeBooks.categoriesList;
                }

                this.setState({
                    categories: categories
                })            
            }

            componentDidUpdate = async(prevProps) => {
                if (this.props.shop !== 'SHOP_ALL') {
                    if (this.storeBooks.categoriesListSB.length === 0) { 
                        const categories = await this.storeBooks.getCategories('SHOP_SB');
                    } 
                } else {
                    if (this.storeBooks.categoriesListALL.length === 0) { 
                        const categories = await this.storeBooks.getCategories('SHOP_ALL');
                    } 
                }
            }

            render() {  
                return (
                    <div className="block block--sidebar block--categories">
                        {!this.props.clean &&
                            <div className="block__block-title">
                                {i18n.t("block.categories.header")}
                            </div> 
                        }

                        <ul className={"block__block-list"}>
                            {this.props.shop === 'SHOP_SB' && this.storeBooks.categoriesListSB && this.storeBooks.categoriesListSB.map((cat, i) => ( 
                                <CategoriesLi 
                                    key = {i}
                                    shop = {this.props.shop}
                                    category = {cat}
                                    name = {cat.name}
                                    display_value = {cat.display_value}
                                />
                            ))}

                            {this.props.shop === 'SHOP_ALL' && this.storeBooks.categoriesListALL && this.storeBooks.categoriesListALL.map((cat, i) => ( 
                                <CategoriesLi 
                                    key = {i}
                                    shop = {this.props.shop}
                                    category = {cat}
                                    name = {cat.name}
                                    display_value = {cat.display_value}
                                />
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockCategories));

