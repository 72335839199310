import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import FormInputFile from '../../elements/form-input-file';

const BlockImageUpload = inject("stores") (
    observer (
        class BlockImageUpload extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    file: {},
                    reset: false
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            getFiles = (file) => {
                this.setState({ 
                    file: file, 
                    reset: false
                });

                console.log('SbC upload:', file)
                this.props.setImageUpload(file);
            }

            resetFile = () => {
                this.setState({ 
                    file: {} ,
                    reset: true
                });
            }
              
            render() {
                return (
                    <div className="block block--image-upload">
                        <FormInputFile
                            multiple = {false}
                            onDone = {this.getFiles} 
                            reset = {this.state.reset}
                        />

                        {this.state.file.base64 &&
                            <div className="block--image-upload__container">
                                <img src={this.state.file.base64} className="block--image-upload__preview" />
                                <span className="--link" onClick={(e) => this.resetFile()}>Verwijder</span>
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockImageUpload);


