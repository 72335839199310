import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import * as utilities from '../utilities/formats';

import Template from '../templates/template';

import BlockQuote from '../components/block/block-quote';
import Cart from './cart';
import CartProcess from './cart-process';

const ShoppingCart = inject("stores") (
    observer (
        class ShoppingCart extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeShipping = this.props.stores.storeShipping;
            }

            componentDidMount = async() => {
                window.scrollTo(0, 0);
                this.storeUi.setActiveNav('default');
                const userId = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                const orderId = await this.storeOrder.returnOrderId(userId);
                const orderStatus = await this.storeOrder.getPaymentStatus(orderId);

                if (orderStatus == 'payment_confirmed' || orderStatus == 'payment_completed') {
                    this.storeOrder.clearCartAfterPayment();
                    this.storeOrder.clearAllShoppingCarts(true);
                    this.storeOrder.saveShoppingCartToDB(userId, this.storeOrder.cart.user);
                } else {
                //if (orderId !== 99 && orderId !== '99') {
                    if (this.storeUser.user_data.is_loggedin) {    
                        if (!this.storeOrder.order_interface.isDialogCompareCartsOpen) {
                            let totalCart = await this.storeOrder.constructShoppingCart(userId, 'init');
                            (async() => await this.storeOrder.saveShoppingCartToDB(userId, totalCart))();
                        }
                    }
                //}
                }
            }

            componentDidUpdate = async(prevProps) => {
                if (this.storeOrder.order_interface.cart_view !== 'review' && this.storeOrder.order_interface.cart_view !== 'client') {
                    const userId = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                    
                    const orderId = await this.storeOrder.returnOrderId(userId);
                    const orderStatus = await this.storeOrder.getPaymentStatus(orderId);

                    if (orderStatus == 'payment_confirmed' || orderStatus == 'payment_completed') {
                        this.storeOrder.clearCartAfterPayment();
                        this.storeOrder.clearAllShoppingCarts(true);
                        this.storeOrder.saveShoppingCartToDB(userId, this.storeOrder.cart.user);
                    } else {
                    //if (orderId !== 99 && orderId !== '99') {
                        if (this.storeUser.user_data.is_loggedin) {    
                            if (!this.storeOrder.order_interface.isDialogCompareCartsOpen) {
                                let totalCart = await this.storeOrder.constructShoppingCart(userId, 'init');
                                (async() => await this.storeOrder.saveShoppingCartToDB(userId, totalCart))();
                            }
                        }

                        if (prevProps.match.params.view !== this.props.match.params.view) {
                            let totalCart = await this.storeOrder.constructShoppingCart(userId, 'init');
                            (async() => await this.storeOrder.saveShoppingCartToDB(userId, totalCart))();
                            if (userId !== 0) {
                                (async() => await this.storeOrder.syncShoppingCartWithOrderlines(userId, totalCart))();
                            }
                            
                            if (this.props.match.params.view == 'cart') {
                                this.storeUi.setUseCase("inshop");
                            }
                            this.storeOrder.setCartView(userId, this.props.match.params.view);
                            this.cartView(this.props.match.params.view)
                        }

                        //await this.storeOrder.calculateShipping(userId);

                        // SbC TEST
                        // SbC TODO what if no order id yet??
                        let orderId = this.storeOrder.order_data.order_id;
                        let shipping = await this.storeShipping.calculateShipping(orderId);
                    // } else {
                        //SbC reset cart after order closed
                        //console.log('SbC clear order', orderId)
                        //this.storeOrder.clearAllShoppingCarts(this.storeUser.user_data.is_loggedin);
                    }
                }
            }

            componentWillUnmount = async() => {
                window.scrollTo(0, 0);
                const userId = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                if (this.storeUser.user_data.is_loggedin) {    
                    let totalCart = await this.storeOrder.constructShoppingCart(userId, 'init');
                    (async() => await this.storeOrder.saveShoppingCartToDB(userId, totalCart))();
                    if (this.storeOrder.cartChanges) {
                        //console.log('SbC cart has changed!')
                    }
                }
            }

            cartView(view) {
                switch(view) {
                    case "cart": return <Cart />
                    case "cart-process": return <CartProcess />
                }
            }
     
            render() {
                return (
                    <Template
                        type = {this.storeUi.app_interface.use_case === "inshop" ? 'default' : 'single-column'}
                        header = "shopping_cart"
                        personalized = {this.storeUser.user_data.is_loggedin ? utilities.capitalizeFirstChar(this.storeUser.user_data.profile.firstname) : false}
                    >
                        <div className="content-segment">
                            
                            {this.storeUi.app_interface.use_case === "inshop" 
                            ?   this.cartView("cart")
                            :   this.cartView("cart-process")
                            }     

                            {this.storeGeneral.shop !== 'SHOP_ALL' &&
                                this.storeUi.app_interface.use_case === "inshop" &&
                                    <BlockQuote 
                                        quote = {this.storeUi.getQuote()}
                                        bg = {"--light"}
                                    /> 
                            }             
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(ShoppingCart);
