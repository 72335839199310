import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
} from 'react-share';

import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
} from 'react-share';

import ShareMastodon from '../content/share-mastodon';
  
const BlockSocialSharing = inject("stores") (
    observer (
        class BlockSocialSharing extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    sku: ''
                }

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.urlAPP = process.env.REACT_APP_URL;
                this.urlAPI = process.env.REACT_APP_URL_API;
            }

            componentDidMount = async () => {
                let partner_code = 0;
                let params;
                let url;
                let direct_url;

                if (this.props.type == 'book') {
                    if (this.storeAuth.user.is_partner) {
                        partner_code = this.storeUser.user_data.partner_code;
                    }  
                    // params = {
                    //     type: this.props.type,
                    //     pc: partner_code,
                    //     sku: this.props.sku,
                    //     title: this.storeBooks.current_book.title,
                    //     description: this.storeBooks.current_book.subtitle
                    // }

                    if (this.storeBooks.current_book) {
                        if (partner_code !== 0) {
                            url = 'https://www.succesboeken.nl/api/social/book.php?sku=' + this.props.sku + '&title=' + this.storeBooks.current_book.title + '&pc=' + partner_code;
                            direct_url = 'https://www.succesboeken.nl/book/' + this.props.sku + '?pc=' + partner_code;      
                        } else {
                            url = 'https://www.succesboeken.nl/api/social/book.php?sku=' + this.props.sku + '&title=' + this.storeBooks.current_book.title;
                            direct_url = 'https://www.succesboeken.nl/book/' + this.props.sku;
                        }
                    }
                }

                if (this.props.type == 'newsletter') {
                    let ext;
                    const url_newsletter = "/mailings/" + this.storeUi.settings_list.latestNewsletter;
                    const week = parseInt(url_newsletter.slice(url_newsletter.length - 4));
                    if (week >= 2030) {
                        ext = '.pdf';
                    } else {
                        ext = '.htm';
                    }
                    // params = {
                    //     type: this.props.type,
                    //     pc: partner_code,
                    //     sku: this.props.sku,
                    //     url: url_newsletter + ext,
                    //     title: this.storeUi.newsletter_active.title,
                    //     description: "Nieuwsbrief van Succesboeken.nl"
                    // }
                    direct_url = 'https://www.succesboeken.nl/api' + url_newsletter + ext;
                    url = direct_url;
                }

                if (this.props.type == 'video') {
                    // params = {
                    //     type: this.props.type,
                    //     pc: partner_code,
                    //     sku: this.props.sku,
                    //     title: "Interessante video's op Succesboeken.nl",
                    //     description: "Bekijk een aantal bijzondere video's"
                    // }
                    direct_url = 'https://www.succesboeken.nl/videos/' + this.props.reference;
                    url = direct_url;
                }

                if (this.props.type == 'videos') {
                    // params = {
                    //     type: this.props.type,
                    //     pc: partner_code,
                    //     sku: this.props.sku,
                    //     title: "Interessante video's op Succesboeken.nl",
                    //     description: "Bekijk een aantal bijzondere video's"
                    // }
                    direct_url = 'https://www.succesboeken.nl/videos';
                    url = direct_url;
                }
                
                this.setState({
                    sku: this.props.sku,
                    short_url: url,
                    direct_url: direct_url
                });
            }

            componentDidUpdate = async (prevProps) => {
                let partner_code = 0;
                let params;
                let url;
                let direct_url;

                if (this.props.type == 'book') {
                    if (prevProps.sku !== this.props.sku) {
                        if (this.storeAuth.user.is_partner) {
                            partner_code = this.storeUser.user_data.partner_code;
                        }  
                        // params = {
                        //     type: this.props.type,
                        //     pc: partner_code,
                        //     sku: this.props.sku,
                        //     title: this.storeBooks.current_book.title,
                        //     description: this.storeBooks.current_book.subtitle
                        // }

                        if (partner_code !== 0) {
                            url = 'https://www.succesboeken.nl/api/social/book.php?sku=' + this.props.sku + '&title=' + this.storeBooks.current_book.title + '&pc=' + partner_code;
                            direct_url = 'https://www.succesboeken.nl/book/' + this.props.sku + '?pc=' + partner_code;      
                        } else {
                            url = 'https://www.succesboeken.nl/api/social/book.php?sku=' + this.props.sku + '&title=' + this.storeBooks.current_book.title;
                            direct_url = 'https://www.succesboeken.nl/book/' + this.props.sku;
                        }

                        this.setState({
                            sku: this.props.sku,
                            short_url: url,
                            direct_url: direct_url
                        });
                    }
                }               
            }

            handleSocialTooltip = (social) => {
                this.props.onMouseEnter(social);
            }
  
            render() {
                return (
                    <div className="block--social-sharing">
                        <div 
                            onMouseEnter={(e) => this.handleSocialTooltip('Facebook')}
                            onMouseLeave={(e) => this.handleSocialTooltip()}
                        > 
                            <FacebookShareButton 
                                url = {this.state.short_url}
                            >
                                <FacebookIcon size={'1.75rem'} round={true}/>
                            </FacebookShareButton>
                        </div>
                        
                        <div 
                            onMouseEnter={(e) => this.handleSocialTooltip('LinkedIn')}
                            onMouseLeave={(e) => this.handleSocialTooltip()}
                        > 
                            <LinkedinShareButton 
                                url = {this.state.short_url}
                                summary = {"Summary of book ... "}
                            >
                                <LinkedinIcon size={'1.75rem'} round={true}/>
                            </LinkedinShareButton>
                        </div>
                        
                        <div 
                            onMouseEnter={(e) => this.handleSocialTooltip('X')}
                            onMouseLeave={(e) => this.handleSocialTooltip()}
                        > 
                            <TwitterShareButton 
                                url = {this.state.direct_url}
                                via = {"succesboeken.nl"}
                                hashtags = {[]}
                            >
                                {/* <TwitterIcon size={'1.75rem'} round={true}/> */}
                                <img 
                                    src="https://www.succesboeken.nl/api/assets/images/icons/ic-x-trans.png" 
                                    className="block--social-sharing__icon--twitter"
                                />
                            </TwitterShareButton>
                        </div>
                        
                        <div 
                            onMouseEnter={(e) => this.handleSocialTooltip('Whatsapp')}
                            onMouseLeave={(e) => this.handleSocialTooltip()}
                        > 
                            <WhatsappShareButton 
                                url = {this.state.direct_url}
                            >
                                <WhatsappIcon size={'1.75rem'} round={true}/>
                            </WhatsappShareButton>
                        </div>
                        
                        {/* <div 
                            onMouseEnter={(e) => this.handleSocialTooltip('Reddit')}
                            onMouseLeave={(e) => this.handleSocialTooltip()}
                        > 
                            <RedditShareButton 
                                url = {this.state.short_url}
                            >
                                <RedditIcon size={'1.75rem'} round={true}/>
                            </RedditShareButton>
                        </div> */}
                        
                        <div 
                            onMouseEnter={(e) => this.handleSocialTooltip('Telegram')}
                            onMouseLeave={(e) => this.handleSocialTooltip()}
                        > 
                            <TelegramShareButton 
                                url = {this.state.direct_url}
                            >
                                <TelegramIcon size={'1.75rem'} round={true}/>
                            </TelegramShareButton>
                        </div>
                        
                        {/* <div 
                            onMouseEnter={(e) => this.handleSocialTooltip('Tumblr')}
                            onMouseLeave={(e) => this.handleSocialTooltip()}
                        > 
                            <TumblrShareButton 
                                url = {this.state.short_url}
                            >
                                <TumblrIcon size={'1.75rem'} round={true}/>
                            </TumblrShareButton>
                        </div> */}

                        {/* <ShareMastodon 
                            url= {url}
                        >
                        </ShareMastodon> */}
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockSocialSharing);


