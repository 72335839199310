import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import ButtonDialog from '../../elements/button-dialog';
import ImgCover from '../../elements/img-cover';

const DialogCompareShoppingCarts = inject("stores") (
    observer (
        class DialogCompareShoppingCarts extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    cart_compare: []
                }
            }

            componentDidMount = () => {
                let cart_compare = [];

                if (this.props.cart_db.length > 0) {
                    this.props.cart_db.map((item) => {
                        if (item.status !== 'deletedx') {
                            cart_compare.push(item);
                        }
                    })
                }

                this.setState({
                    cart_compare: cart_compare
                })
            }

            render() {
                if(!this.props.show) {
                    return null;
                }

                return (
                    <div className="overlay">
                        <div className="dialog dialog--carts dialog--carts-scrollable">
                            <div className="dialog-header">
                                <h2>{this.props.title}</h2>
                            </div>
                            <div className="dialog-content">
                                {/* {this.state.cart_compare.length > 0
                                ?   <React.Fragment>
                                        <p>Er bestaan twee winkelmandjes:<br/>1 van een niet-ingelogde versie en 1 van een sessie waarbij je wel was ingelogd.</p>
                                        <p>Beide winkelwagentjes kunnen door verschillende personen aangemaakt zijn.</p>
                                    </React.Fragment>
                                :   <p>Er is mogelijk iemand anders geweest die een winkelmandje heeft gemaakt tijdens een niet-ingelogde sessie:</p>
                                } */}
                                {/* <h3>Winkelmandje van een niet-ingelogde sessie:</h3>
                                <ul className="dialog-content__list dialog-content__list--covers">
                                    {this.props.cart_current.map(item => (
                                        item.status !== 'deletedx' &&
                                            <li className="dialog-content__listitem">
                                                <ImgCover
                                                    sku = {item.sku}
                                                    aspect = {'--fixed-height'}
                                                />
                                                {item.amount > 1 &&
                                                    <span>
                                                        ({item.amount})        
                                                    </span>
                                                }
                                            </li>
                                    ))}
                                </ul>
                                 */}
                                {/* {this.state.cart_compare.length > 0 &&
                                <React.Fragment>
                                    <h3>Winkelmandje van een ingelogde sessie:</h3>
                                    <ul className="dialog-content__list dialog-content__list--covers">
                                        {this.props.cart_db.map(item => (
                                            (item.status !== 'deletedx' && parseInt(item.amount) >= 1) &&
                                                <li className="dialog-content__listitem">
                                                    <ImgCover
                                                        sku = {item.sku}
                                                        aspect = {'--fixed-height'}
                                                    />
                                                    {item.amount > 1 &&
                                                        <span>
                                                            ({item.amount})        
                                                        </span>
                                                    }
                                                </li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                                } */}

                                {/* {this.state.cart_compare.length > 0
                                ?    <p>Wil je deze winkelmandjes samenvoegen?</p>
                                :    <p>Mag dit winkelmandje van jou worden?</p>
                                } */}

                                <p>
                                    Er zaten nog producten in je winkelmandje van een vorig bezoek. Deze zijn nu samengevoegd.
                                </p>
                                <p>
                                    Uiteraard kun je je winkelmandje altijd nog wijzigen.
                                </p>
                            </div>
                            {this.props.buttons &&
                                <div className="dialog-footer">
                                    {this.props.buttons.map((button, i) => (
                                        <ButtonDialog
                                            key = {i}
                                            caption = {this.state.cart_compare.length > 0 ? button.text : (button.action === 'keep' ? 'Nee, wel bewaren' : 'Ok!')}
                                            onAction = {button.onClick}
                                            btnClass = {button.btnClass}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                );
            }
        }
    )
)

export default DialogCompareShoppingCarts;
