import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

const CartProcessStep = inject("stores") (
    observer (
        class CartProcessStep extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeOrder = this.props.stores.storeOrder;
            }

            render() {
                const active_view = (this.storeOrder.order_interface.cart_view == 'login')
                    ?   'client'
                    :   this.storeOrder.order_interface.cart_view
                const isActive = this.props.id == active_view 
                    ?   true 
                    :   false

                return (
                    <div className={"cart-step" + (isActive ? " --active" : '')}>
                        <span  className="cart-step__nr">{this.props.step}</span>
                        <span  className="cart-step__title">{this.props.title}</span> 
                    </div>
                )
            }
        }
    )
)

export default withRouter(CartProcessStep);
