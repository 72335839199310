import React, { PureComponent } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import * as content from '../../utilities/content';

import SearchOverlayItem from './search-overlay-item';
import FeedbackNoSearchResults from '../feedback/feedback-no-search-results';
import FeedbackNoSearchResultsSuggestion from '../feedback/feedback-no-search-results-suggestion';

const SearchResultOverlay = inject("stores") (
    observer (
        class SearchResultOverlay extends PureComponent {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    results: [],
                    shopAlternative: '',
                    skuAlternative: ''
                }
            }

            componentDidMount = async() => {
                let shop;
                let section;
                let shopAlternative;
                let skuAlternative;
                let count;
               
                switch(this.props.searchShop) {
                    case 'OWN':
                        shop = 'SHOP_SB';
                        section = 'sb'; 
                        //await (async() => this.storeUi.getSearchCountSB(this.storeUi.search_value, section) )();
                        break;
                    case 'SB':
                        shop = 'SHOP_SB';
                        section = ''; 
                        //await (async() => this.storeUi.getSearchCountSB(this.storeUi.search_value, section) )();
                        break;
                    case 'ALL':
                        shop = 'SHOP_ALL';
                        section = ''; 
                        //await (async() => this.storeUi.getSearchCount(this.storeUi.search_value, section) )();
                        break;   
                }

                // if (this.storeUi.search_input_focus || this.storeUi.search_overlay) {
                //     if (this.props.searchShop === 'OWN') {

                //     } 

                //     if (this.props.searchShop === 'ALL') {
                //         const searchAlternative = false;
                //         //const results3 = await this.storeUi.getSearchResultsSimple('SHOP_ALL', this.storeUi.search_value, '', this.storeUi.page_view, searchAlternative); 

                //         shopAlternative = 'Alle boeken';
                //         skuAlternative = 'ALL';
                //         this.setState({
                //             shopAlternative: shopAlternative,
                //             skuAlternative: skuAlternative
                //         })
                //     }
                // } 
            }

            componentDidUpdate = async(prevProps) => {
                let shop;
                let section;
                let shopAlternative;
                let skuAlternative;
                let results;

                if (prevProps.searchInput !== this.props.searchInput || 
                    prevProps.searchInputFocus !== this.props.searchInputFocus || 
                    prevProps.searchShop !== this.props.searchShop) {
                } 

                if (prevProps.count !== this.props.count) {

                } 

                window.scrollTo(0, 0);
            }

            setOverlayRef = (node) => {
                this.overlayRef = node;
                this.storeUi.setSearchOverlayRef(node);
            }

            feedbackShop = (shop) => {
                let fb = ' in ';
                switch(shop) {
                    case 'OWN':
                        fb = fb + 'Boeken eigen uitgeverij';
                        break;
                    case 'SB':
                        fb = fb + 'Zelfhulpboeken';
                        break;
                    case 'ALL':
                        fb = fb + 'Alle boeken';
                        break;
                    default:   
               }

               return fb;
            }

            onSubmit = () => {
                if (((this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own > 1) ||
                    (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb > 1) ||
                    (this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all > 1 ))) 
                {
                    let search_term;
                    search_term = this.storeUi.search_value;

                    this.storeUi.setPageView('page', 1);
                    this.storeUi.getSearchIntentions(search_term);
                    this.storeUi.setSearchOverlay(false);
                    this.storeUi.setSearchInputFocus(false);
                
                    this.storeUi.saveSearchQuery(search_term);
                    this.storeUi.setActiveMainNav(this.storeUi.app_interface.active_search_segment);

                    const path = "/search-results";
                    const str_search = "?q=" + search_term;

                    this.props.history.push({
                        pathname: path,
                        search: str_search
                    })
                } else {
                    let sku = this.storeUi.search_results_simple_all[0].sku;
                    let url = "/book/" + sku;
        
                    this.props.history.push({
                        sku: sku,
                        pathname: url
                    })

                    this.storeUi.setSearchOverlay(false);
                } 
            }

            handleChangeSegment = (shop) => {
                let search_term;
                let altShop;
                let altShopSearch;
                search_term = this.storeUi.search_value;

                switch(shop) {
                    case 'Alle zelfhulpboeken':
                        altShop = 'SHOP_SB';
                        altShopSearch = 'SB';
                        this.storeUi.setActiveToggleNav(2);
                        break;
                    case 'Alle boeken':
                        altShop = 'SHOP_ALL';
                        altShopSearch = 'ALL';
                        this.storeUi.setActiveToggleNav(3);
                        break;
                    default:   
                }

                this.storeGeneral.setShop(altShop);
                this.storeGeneral.setActiveShop(altShop);
                //this.storeGeneral.setSearchShop(altShopSearch);
                
                this.storeUi.setPageView('page', 1);
                this.storeUi.getSearchIntentions(search_term);
                this.storeUi.setSearchOverlay(false);
                this.storeUi.setSearchInputFocus(false);
               
                this.storeUi.saveSearchQuery(search_term);
                this.storeUi.setActiveMainNav(this.storeUi.app_interface.active_search_segment);

                const path = "/search-results";
                const str_search = "?q=" + search_term;

                this.props.history.push({
                    pathname: path,
                    search: str_search
                })
            }

            handleNav = (page) => {
                this.storeGeneral.setShop("SHOP_ALL");
                this.storeGeneral.setActiveShop("SHOP_ALL");
                //this.storeGeneral.setSearchShop('ALL');

                this.storeUi.setSearchOverlay(false);
                const path = "/";
                this.props.history.push({
                    pathname: path
                })
            }

            hasAlternative = (arr) => {
                let hasAlt = 0;
                
                if (arr !== null && arr[0] && arr[0].response) {
                    hasAlt = arr[0].response.numFound;
                } else {
                    hasAlt = 1;
                }

                return hasAlt;
            }

            render() {               
                return (
                    <React.Fragment>
                        {
                            ((this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own > 0) ||
                            (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb > 0) ||
                            (this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all > 0 )) &&
                            <React.Fragment>
                                <div
                                    className="search-overlay__header"
                                >
                                    <span>
                                        Andere resultaten in&nbsp;
                                        {this.storeGeneral.searchShop === 'OWN' &&
                                            <span
                                                className="search-overlay__header--section"
                                            >
                                                Boeken eigen uitgeverij
                                            </span>
                                        }
                                        {this.storeGeneral.searchShop === 'SB' &&
                                            <span
                                                className="search-overlay__header--section"
                                            >
                                                Alle zelfhulpboeken
                                            </span>
                                        }
                                        {this.storeGeneral.searchShop === 'ALL' &&
                                            <span
                                                className="search-overlay__header--section"
                                            >
                                                Alle boeken
                                            </span>
                                        }
                                    </span>
                                    <span
                                        className = "search-counter__link-all --link"
                                        onClick = {() => this.onSubmit()}
                                    >
                                        {(this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own > 1) &&
                                            <React.Fragment>
                                                Toon alle&nbsp;
                                                {this.storeUi.search_count_own}
                                                &nbsp;resultaten
                                            </React.Fragment>
                                        }
                                        {(this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb > 1) &&
                                            <React.Fragment>
                                                Toon alle&nbsp;
                                                {this.storeUi.search_count_sb}
                                                &nbsp;resultaten
                                            </React.Fragment>
                                                
                                        }
                                        {(this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all > 1) &&
                                            <React.Fragment>
                                                Toon alle&nbsp;
                                                {this.storeUi.search_count_all}
                                                &nbsp;resultaten
                                            </React.Fragment>
                                            
                                        }

                                        {((this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own === 1) ||
                                          (this.storeGeneral.searchShop === 'SB' &&this.storeUi.search_count_sb === 1) ||
                                          (this.storeGeneral.searchShop === 'ALL' &&this.storeUi.search_count_all === 1)) && 
                                            <React.Fragment>
                                                Toon meer over dit boek
                                            </React.Fragment>
                                        }
                                    </span>
                                </div>
                            </React.Fragment>
                        }
                    
                        {!this.storeUi.checkFeature('SHOP_ALL') &&
                            <div>
                                {((this.storeGeneral.searchShop === 'SB' &&
                                    this.storeUi.search_count_sb !== 0 && this.storeUi.search_count_sb !== null
                                    )) &&
                                    <React.Fragment>
                                        <ul className = "list--overlay2">
                                            {this.storeUi.search_results_simple_sb !== null && this.storeUi.search_results_simple_sb.length > 0 && this.storeUi.search_results_simple_sb.map((item,i) => (
                                            i < 10 &&
                                                <SearchOverlayItem
                                                    item = {item}
                                                />
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                }
                            </div>
                        }
                        
                        {this.storeUi.checkFeature('SHOP_ALL') &&
                            <React.Fragment>
                                {((this.storeGeneral.searchShop === 'OWN' &&
                                    this.storeUi.search_count_own !== 0 && this.storeUi.search_count_own !== null
                                    )) &&
                                    <React.Fragment>
                                        <ul className = "list--overlay2">
                                            {this.storeUi.search_results_simple_own !== undefined && this.storeUi.search_results_simple_own !== null && this.storeUi.search_results_simple_own.length > 0 && this.storeUi.search_results_simple_own.map((item,i) => (
                                                item.sku !== this.storeUi.searchTopItemTitleSku &&
                                                    i < 10 &&
                                                        <SearchOverlayItem
                                                            item = {item}
                                                        />
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                }

                                {((this.storeGeneral.searchShop === 'SB' &&
                                    this.storeUi.search_count_sb !== 0 && this.storeUi.search_count_sb !== null
                                    )) &&
                                    <React.Fragment>
                                        <ul className = "list--overlay2">
                                            {this.storeUi.search_results_simple_sb !== undefined && this.storeUi.search_results_simple_sb !== null && this.storeUi.search_results_simple_sb.length > 0 && this.storeUi.search_results_simple_sb.map((item,i) => (
                                                item.sku !== this.storeUi.searchTopItemTitleSku &&
                                                    i < 10 &&
                                                        <SearchOverlayItem
                                                            item = {item}
                                                        />
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                }

                                {((this.storeGeneral.searchShop === 'ALL' &&
                                    this.storeUi.search_count_all !== 0 && this.storeUi.search_count_all !== null
                                    )) &&
                                    <React.Fragment>
                                        <ul className = "list--overlay2">
                                            {this.storeUi.search_results_simple_all !== undefined && this.storeUi.search_results_simple_all !== null && this.storeUi.search_results_simple_all.length > 0 && this.storeUi.search_results_simple_all.map((item,i) => (
                                                item.sku !== this.storeUi.searchTopItemTitleSku &&
                                                    i < 10 &&
                                                        <SearchOverlayItem
                                                            item = {item}
                                                        />
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }   
                        
                        {((this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own > 0) ||
                            (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb > 0) ||
                            (this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all > 0)
                        ) &&
                            <div className="form-row form-row--buttons">
                                <button 
                                    className="btn btn--primary --search"
                                    onClick={(e) => this.onSubmit()}
                                >
                                    {this.storeGeneral.searchShop === 'OWN' &&  this.storeUi.search_results_simple_own && (
                                        this.storeUi.search_results_simple_own.length > 10
                                        ?   'Toon alle ' + this.storeUi.search_count_own + ' resultaten'
                                        :   this.storeUi.search_count_own > 1
                                            ?   'Toon meer informatie over deze ' + this.storeUi.search_count_own + ' resultaten'
                                            :   'Toon meer informatie over dit resultaat'
                                    )}
                                    {this.storeGeneral.searchShop === 'SB' &&  this.storeUi.search_results_simple_sb && (
                                        this.storeUi.search_results_simple_sb.length > 10
                                        ?   'Toon alle ' + this.storeUi.search_count_sb + ' resultaten'
                                        :   this.storeUi.search_count_sb > 1
                                            ?   'Toon meer informatie over deze ' + this.storeUi.search_count_sb + ' resultaten'
                                            :   'Toon meer informatie over dit resultaat'
                                    )}
                                    {this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_results_simple_all && (
                                        this.storeUi.search_results_simple_all.length > 10
                                        ?   'Toon alle ' + this.storeUi.search_count_all + ' resultaten'
                                        :   this.storeUi.search_count_all > 1
                                            ?   'Toon meer informatie over deze ' + this.storeUi.search_count_all + ' resultaten'
                                            :   'Toon meer informatie over dit resultaat'
                                    )}                                    
                                </button>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(SearchResultOverlay);
