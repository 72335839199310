import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const Disclaimer = inject("stores") (
    observer (
        class Disclaimer extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }
  
            render() {

                return (
                    <div className="footer__disclaimer disclaimer">
                        <span className="disclaimer__title">
                            {i18n.t("header.disclaimer")}
                        </span>
                        <span className="disclaimer__content">
                            {i18n.t("text.disclaimer")}
                        </span>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(Disclaimer);
