import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import ButtonDialog from '../../elements/button-dialog';

const DialogFeedbackForm20 = inject("stores") (
    observer (
        class DialogFeedbackForm20 extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeForms = this.props.stores.storeForms;
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.props.onClose();
                }
            }

            handleClose = (e) => {
                this.props.onClose();
            }

            render() {
                if (!this.props.show) {
                    return null;
                }

                return (
                    <div className="overlay">
                        <div className="dialog dialog--form">
                            <div className="btn--close" onClick={(e) => this.handleClose(e)}><div className="btn--icon --link icon--close">Sluit</div></div>
                            <div className="dialog-content">
                                {'success' in this.storeForms.form_feedback &&
                                    <React.Fragment>
                                        <span className="form__feedback--success">{i18n.t("form_feedback.success", {type: this.props.type})}</span>
                                        <span className="form__feedback--followup">{i18n.t("form_followup." + this.props.type)}</span>
                                    </React.Fragment>
                                }

                                {'error' in this.storeForms.form_feedback && (
                                    <React.Fragment>
                                    {'error_code' in this.storeForms.form_feedback &&
                                        <span className="form__feedback--error" dangerouslySetInnerHTML={{ __html: this.storeForms.form_feedback.error}} />
                                    }
                                    {('msg' in this.storeForms.form_feedback && this.storeForms.form_feedback.msg !== '')
                                        ?   <span className="form__feedback--error" dangerouslySetInnerHTML={{ __html: this.storeForms.form_feedback.msg}} />
                                        :   <span className="form__feedback--error">{i18n.t("form_feedback.error", {type: this.props.type})}</span>
                                    }
                                    </React.Fragment>
                                )}
                            </div>
                            {this.props.buttons &&
                                <div className="dialog-footer">
                                    {this.props.buttons.map((button) => (
                                        <ButtonDialog
                                            caption = {button.text}
                                            onAction = {button.onClick}
                                            btnClass = {button.btnClass}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    )
                }
            }
        )
    )
    
    export default DialogFeedbackForm20;
