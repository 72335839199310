import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import * as sort from '../../utilities/sort';
import * as ui from '../../utilities/ui';

import BlockViews from './block-views';
import LiSort from './block-li-sort';
import ButtonToggleExpand from '../../elements/button-toggle-expand';

const BlocksTop60 = inject("stores") (
    observer (
        class BlocksTop60 extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeBooksAll = this.props.stores.storeBooksAll;
                this.storeProducts = this.props.stores.storeProducts;

                this.state = {
                    view: this.props.default_view ? this.props.default_view : '',
                    sorting: this.storeUi.page_view.sorting,
                    sorting_dir: this.storeUi.page_view.order,
                    foldout: this.storeUi.foldout.top60,
                    weeknr: 0
                }
            }

            componentDidMount = async() => { 
                let weeknr = await this.storeBooks.getWeekTop60();

                if (this.storeBooks.bookList_top60.length === 0 || this.storeBooks.bookList_top60.length === '0') {
                    
                    let top60 = await this.storeBooks.getBooksTop60();
                    this.setState({
                        weeknr: weeknr
                    })
                }
                this.setView();
            }

            componentDidUpdate = async() => { 
                if (this.state.weeknr == 0) {
                    let weeknr = await this.storeBooks.getWeekTop60();
                    this.setState({
                        weeknr: weeknr
                    })
                }
            }

            handleBlocksNav = (e, view) => {
                this.setState({
                    view: view
                })
                this.storeUi.setPageView('view', view)
            }

            handleSort = (type, sorting_dir) => {
                this.storeUi.setPageView('sorting', type);
                this.storeUi.setPageView('order', sorting_dir);

                this.setState({
                    sorting: type,
                    sorting_dir: sorting_dir
                }, () => this.setView())
            }

            removeSort = () => {
                const sorting_dir = 'DESC';
                const type = 'ID';

                this.storeUi.setPageView('sorting', type);
                this.storeUi.setPageView('order', sorting_dir);

                this.setState({
                    book_list: this.storeBooks.bookList_top60,
                    sorting: 'ID'
                })

                ui.handleScroll('top');
            }

            setView = () => {
                let book_list;
                let book_list2;

                if (this.state.sorting !== 'release_date') {
                    book_list = this.storeBooks.bookList_top60
                        .sort(sort.compareValues(this.state.sorting, this.state.sorting_dir))
                } else {
                    book_list2 = this.storeBooks.bookList_top60;
                    book_list2.map((book, idx) => {
                        if (book_list2[idx] !== undefined) {
                            if ( book_list2[idx]['release_date']) {
                                return book_list2[idx]['sort_date'] = new Date(book_list2[idx]['release_date']['date']);
                            } else {
                                return book_list2[idx]['sort_date'] = new Date();
                            }
                        } else {
                            book_list2[idx] = {};
                        }
                    });

                    book_list = book_list2
                        .sort(sort.compareValues('sort_date', this.state.sorting_dir))
                }
                
                this.setState({
                    book_list: book_list
                })
                
                ui.handleScroll('top');
            }

            expandBlocks = (e) => {
                if (this.state.foldout) {
                    this.storeUi.setFoldout('top60', false)
                    ui.handleScroll('top');
                } else {
                    this.storeUi.setFoldout('top60', true)
                }
                this.setState(prevState => ({
                    foldout: !prevState.foldout
                }));
            }
  
            render() {             
                let blockview;
                let class_foldout = "";
                let items_inview = this.state.foldout ? 60 : 60;

                if (this.storeUi.page_view.view && this.storeUi.page_view.view !== '') {
                    blockview = this.storeUi.page_view.view;
                    if (blockview == 'covers') {
                        class_foldout = " blocks__list--covers-foldout3 " + (this.state.foldout ? "--expanded" : "--collapsed");
                    }
                } else {
                    if (this.state.view == '' ) {
                        blockview = 'covers';
                        class_foldout = " blocks__list--covers-foldout3 " + (this.state.foldout ? "--expanded" : "--collapsed");
                    } else {
                        blockview = this.state.view
                        if (blockview == 'covers') {
                            class_foldout = " blocks__list--covers-foldout3 " + (this.state.foldout ? "--expanded" : "--collapsed");
                        }
                        if (blockview == 'summaries') {
                            items_inview = this.state.foldout ? 60 : 10;
                            class_foldout = " blocks__list--summaries-foldout3 " + (this.state.foldout ? "--expanded" : "--collapsed");
                        }
                        if (blockview == 'list') {
                            items_inview = this.state.foldout ? 60 : 10;
                            class_foldout = " blocks__list--list-foldout3 " + (this.state.foldout ? "--expanded" : "--collapsed");
                        }
                    }
                }

                const Li = (view) => {
                    const activeClass = (view.view === blockview) ? ' --active' : '';
                    return (
                        <li className={"blocks-nav__listitem blocks-nav__listitem--" + view.view + activeClass} onClick={(e) => this.handleBlocksNav(e, view.view)}>
                            <span>{view.view}</span>
                        </li>
                    )
                }

                return (                  
                    <div className="blocks blocks--books">
                        <div className="blocks__blocks-title">
                            <span className="blocks__blocks-title--main-section">week {this.state.weeknr}</span>
                            <span 
                                className="blocks__blocks-title--main-cat"
                            >
                                Top 60 Bestsellers 
                            </span>
                        </div>
                     
                        <div className="blocks__content">
                            {this.state.book_list && this.state.book_list.length > 0
                            ?   <React.Fragment>                                   
                                    <div className="blocks__nav">
                                        <div className="blocks__nav-actions">
                                            <div className="blocks-nav__list --column">
                                                <ul className="blocks-nav__list--sort">
                                                    <li>Sorteer&nbsp;op:</li>
                                                    <LiSort 
                                                        sort = 'title'
                                                        handleSort = {this.handleSort}
                                                        sorting = {this.state.sorting}
                                                    />
                                                    <LiSort 
                                                        sort = 'price_incl'
                                                        handleSort = {this.handleSort}
                                                        sorting = {this.state.sorting}
                                                    />
                                                    {this.props.type !== 'author' && this.props.type !== 'author_sb' &&
                                                        <LiSort 
                                                            sort = 'author_lastname'
                                                            handleSort = {this.handleSort}
                                                            sorting = {this.state.sorting}
                                                        />
                                                    }
                                                    <LiSort 
                                                        sort = 'release_date'
                                                        handleSort = {this.handleSort}
                                                        sorting = {this.state.sorting}
                                                    />
                                                    
                                                </ul>  
                                                {this.state.sorting !== 'ID' &&
                                                    <span  className="blocks-nav__list--unsort --link"  onClick={(e) => this.removeSort()}>Verwijder sortering</span>
                                                }     
                                            </div>
                                            <div className="blocks-nav__list">
                                                <ul className="blocks-nav__list--view">
                                                    <Li 
                                                        view = 'summaries'
                                                    />
                                                    <Li 
                                                        view = 'covers'
                                                    />
                                                    <Li 
                                                        view = 'list'
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className={"blocks__list blocks__list--" + blockview + class_foldout}>
                                        {this.state.book_list.slice(0, items_inview).map((book, i) => ( 
                                            book !== undefined &&
                                            <li className="block block--book" key = {i}>
                                                <BlockViews 
                                                    view = {blockview}
                                                    book = {book}
                                                    page = {this.props.page}
                                                    ranking = {true}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                    
                                    <div className="blocks__list--actions">
                                        <ButtonToggleExpand
                                            foldout = {this.state.foldout}
                                            expandBlocks = {this.expandBlocks}
                                            txt_down = {"lists.show_more_top"}
                                            txt_up = {"lists.show_less_top"}
                                            amount = {60}
                                        />
                                    </div>
                                    </React.Fragment>
                            :   <p className="content--empty">De gegevens worden opgehaald</p>
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlocksTop60));

