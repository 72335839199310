import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";

const ContentText = inject("stores") (
    observer (
        class ContentText extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;
                
                this.state = {
                    content_text: ''
                }
            }

            async componentDidMount() {
                let content_text = await this.storeUi.getContentText(this.props.page);
                this.setState({
                    content_text: content_text
                })
            }

            componentDidUpdate = async (prevProps) => {
                if (prevProps.page !== this.props.page) {
                    let content_text = await this.storeUi.getContentText(this.props.page);
                    this.setState({
                        content_text: content_text
                    })
                }
            }

            openDialog = () => {
                this.storeUi.setContentComponent(
                    this.props.dialog
                )
                this.storeUi.openDialogContent();
            }
  
            render() {
                return (
                    <React.Fragment>
                        {this.state.content_text && this.state.content_text.map((content, i) => (
                            <React.Fragment key={i}>
                                {content.subheader &&
                                    <div className="content-segment__content">
                                        <div className="content-segment__header">
                                            <h2>{content.subheader}</h2>
                                        </div>
                                    </div>
                                }
                                <div className="content-segment__content">
                                    <p dangerouslySetInnerHTML={{ __html: content.paragraph }}></p>
                                </div> 
                                {i == this.props.dialog_position &&
                                    <div className="content-segment__content">
                                        <span onClick={() => this.openDialog()} className="link--read-more --link">{this.props.dialog_linktext}</span>
                                    </div>
                                }                                  
                            </React.Fragment>
                        ))}
                        
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(ContentText);

