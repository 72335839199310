import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const LiSort = inject("stores") (
    observer (
        class LiSort extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                
                this.state = {
                    sorting_dir: 'asc',
                    sort_class: '',
                    prev_sort: this.storeUi.page_view.order,
                    active_class: ''
                }

                
            }

            componentDidMount= () => {
                if (this.props.sorting === this.props.sort) {
                    this.setState({
                       sorting_dir: this.state.prev_sort,
                       active_class: ' --active',
                       sort_class: this.storeUi.page_view.order === 'asc' ? ' --up' : ' --down'
                    })
                } else {
                    this.setState({
                        active_class: '',
                        sort_class: ''
                    })
                }
            }

            componentDidUpdate(prevProps) {
                if (prevProps.sorting !== this.props.sorting) {
                    if (this.props.sorting === this.props.sort) {
                        this.setState({
                           active_class: ' --active'
                        })
                    } else {
                        this.setState({
                            active_class: '',
                            sort_class: ''
                        })
                    }                
                } 
            }

            handleSort = () => {
                this.storeUi.setPageView('page', 1);
                
                if (this.state.active_class !== '') {
                    this.setState({
                        sorting_dir: this.state.sorting_dir === 'asc' ? 'desc' : 'asc',
                        prev_sort: this.state.sorting_dir === 'asc' ? 'desc' : 'asc',
                        sort_class: this.state.sorting_dir === 'asc' ? ' --down' : ' --up'
                    }, () => {
                        // this.setState({
                        //     sort_class: this.state.prev_sort === 'asc' ? ' --up' : ' --down'
                        // })
                        this.props.handleSort(this.props.sort, this.state.sorting_dir)
                    })
                } else {
                    this.props.handleSort(this.props.sort, this.state.sorting_dir)
                    this.setState({
                        sorting_dir: this.state.sorting_dir,
                        sort_class: this.state.sorting_dir === 'asc' ? ' --up' : ' --down'
                    })
                }
            }

            render() {
                return (
                    <li className={"blocks-nav__sortitem blocks-nav__sortitem" + this.state.sort_class + this.state.active_class} onClick={(e) => this.handleSort()}>
                        <span>{i18n.t("blocks-nav." + this.props.sort)}</span>
                    </li>
                )
            }
        }
    )
)

export default LiSort;

