import React from 'react';

const RadioYesNo = props => {

    let formControl = "form-control";
    let direction = "--" + props.direction;
    let urlAPI = process.env.REACT_APP_URL_API;

    // if (props.touched && !props.valid) {
    //     formControl = 'form-control control-error';
    // }

    return (
        <div className={"form-group form-group--radio " + direction}>
            <ul className="list--yesno">
                {props.options.map(option => (
                    <li className={"list--yesno__listitem --link " + (option.value === 1 ? "icon icon--ok" : "icon icon--clear") + (parseInt(props.selected) === parseInt(option.value) ? " --selected" : '')} key={option.value}>
                        <label>
                            <input type="radio"
                                name = {props.name}
                                value = {option.value}
                                onChange = {props.onChange}
                                className = {formControl}
                                defaultChecked = {props.defaultValue === option.value}
                            />
                            {props.count == 1
                            ?   <span className="--link">{option.displayValueSingle}</span>
                            :   <span className="--link">{option.displayValue}</span>
                            }   
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default RadioYesNo;