import * as apifetch from './fetch.js';

// export async function handleOrderLineShipping(order_line, id, action) {
//     const urlEndpoint = '/v2/orders/' + id + '/shipping';
    
//     const postData = order_line;

//     return apifetch.sbFetch(urlEndpoint, 'POST', postData);
// }

export async function addOrderLineShipping(order_line, id, action) {
    const urlEndpoint = '/v2/orders/' + id + '/shipping/add';
    
    const postData = order_line;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function deleteOrderLineShipping(id) {
    const urlEndpoint = '/v2/orders/' + id + '/shipping';

    return apifetch.sbFetch(urlEndpoint, 'DELETE');
}

export async function editOrderLineShipping(order_line, id, action) {
    const urlEndpoint = '/v2/orders/' + id + '/shipping/edit';
    
    const postData = order_line;

    return apifetch.sbFetch(urlEndpoint, 'PUT', postData);
}

export async function getOrderLineShipping(id) {
    const urlEndpoint = '/v2/orders/' + id + '/shipping';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getDataInvoice(id) {
    const urlEndpoint = '/v2/orders/' + id + '/invoice';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}