import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

const ButtonBack = inject("stores") (
    observer (
        class ButtonBack extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    is_visible: true
                };
            }

            scrollBack = async(e) => {
                this.checkShop();
                //SbC for SEO
                // if (this.props.match.params.tab === this.storeBooks.current_book.title) {
                //     this.props.history.go(-2);
                // } else {
                //      this.props.history.goBack();
                // }
                this.props.history.goBack();
                this.storeUi.setFreezePaging(true);
                this.storeUi.setFreezeScrolling(true);
            }

            checkShop = async () => {
            }

            render() {  
                let class_visible = this.state.is_visible ? ' --fadeIn' : ' --fadeOut';
                const pos_top = 'calc(' + this.storeUi.app_interface.height_header + 'px + 1rem)'

                return (
                    <div 
                        className = {"scroller-back-wrapper" + class_visible} 
                        onClick = {(e) => this.scrollBack(e)}
                        style = {{top: pos_top}}
                    >
                        <div className="scroller-back-wrapper__icon" />
                    </div>
                )
            }
        }
    )
)

export default withRouter(ButtonBack);
