import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import BlockViews from './block-views';

const BlocksBookLi = inject("stores") (
    observer (
        class BlocksBookLi extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;
                
                this.state = {
                    elevatedHeader: ''
                }

                
            }

            componentDidMount= async() => {
                let elevatedHeader;

                if (this.props.book["[elevated]"]) {
                    elevatedHeader = await this.getElevatedHeader();

                    this.setState({
                        elevatedHeader: elevatedHeader
                    })
                }
            }

            componentDidUpdate = async(prevProps) => {
                let elevatedHeader;
                if (prevProps.book.sku !== this.props.book.sku) {
                    if (this.props.book["[elevated]"]) {
                        elevatedHeader = await this.getElevatedHeader();
    
                        this.setState({
                            elevatedHeader: elevatedHeader
                        })
                    }
                }
            }

            getElevatedHeader = async() => {
                let elevatedHeader = 'aanbevolen';
                let header = await this.storeUi.getElevatedSku(this.props.book.sku);
                
                if (header['elevatedHeader'] !== null) {
                    elevatedHeader = header['elevatedHeader'];
                }
                
                return elevatedHeader;
            }

            render() {
                return (
                    <li 
                        className={"block block--book" + (this.props.type === 'search' && this.props.book["[elevated]"] ? " --elevated" : "") + (this.props.book.sku === this.storeGeneral.settings_list.skuSoonAvailable ? " --soon" : "")} key={this.props.book.sku}
                        data-content = {this.state.elevatedHeader}
                    >
                        <BlockViews 
                            view = {this.props.view}
                            book = {this.props.book}
                            page = {this.props.page}
                            author = {this.props.author}
                        />
                    </li>
                )
            }
        }
    )
)

export default BlocksBookLi;

