import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import * as ui from '../../utilities/ui';

import BlockCategory from '../block/block-category';
import BlockPagination from './block-pagination';
import BlocksBookLi from './blocks-book-li';
import LiSort from './block-li-sort';
import Checkbox from '../../elements/form-input-checkbox';
import LoaderFeedback from '../../elements/loader-feedback';

const BlocksBooks = inject("stores") (
    observer (
        class BlocksBooks extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeBooksAll = this.props.stores.storeBooksAll;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCatalogs = this.props.stores.storeCatalogs;

                this.state = {
                    view: this.props.default_view ? this.props.default_view : '',
                    sorting: this.storeUi.page_view.sorting,
                    sorting_dir: this.storeUi.page_view.order,
                    formControls: {
                        stock_priority: {
                            label: this.props.type === 'search'
                                ?   i18n.t("forms.label.stock_priority")
                                :   i18n.t("forms.label.stock_priority_instock"),
                            value: this.storeCatalogs.stockPriority,
                            touched: false,
                            valid: true
                        }
                    },
                    cat: {},
                    elevatedHeader: 'aanbevolen'
                }

                this.controller = new AbortController();
                this.signal = this.controller.signal;
            }

            componentDidMount = async() => { 
                if (!this.storeUi.freezePaging) {
                    if (this.props.type == 'category' || this.props.type === 'subcategory' || this.props.type === 'search') {
                        this.storeUi.setPageView('page', 1);
                        this.setView();
                    }
                    if (this.props.type === 'new') {
                        this.storeUi.setPageView('page', 1);
                        this.setView();
                    }
                } else {
                    this.storeUi.setFreezePaging(false);
                    //this.setView();
                }
            }

            componentDidUpdate = async(prevProps) => {  
                if (prevProps.type !== this.props.type && this.props.type === 'category') {
                    this.storeUi.setActiveCategory(this.props.match.params.name);
                }

                if (prevProps.blockCat !== this.props.blockCat && this.props.type === 'category') {
                    this.storeUi.setActiveCategory(this.props.blockCat);
                }

                if (prevProps.type !== this.props.type && this.props.type === 'subcategory') {
                    this.storeUi.setActiveSubCategory(this.props.match.params.name);
                }

                if (prevProps.type !== this.props.type) {
                    this.setState({
                        type: this.props.type
                    });
                }   
               
                let cat_name = this.props.match.params.name;
                if (prevProps.blockCat !== this.props.blockCat || prevProps.subcategory !== this.props.subcategory ) {
                    const name = 'stock_priority';

                    this.setState({
                        formControls: {
                            ...this.state.formControls,
                            [name]: {
                                ...this.state.formControls[name],
                                value: this.storeCatalogs.stockPriority,
                                selected: this.storeCatalogs.stockPriority ? 1 : 0
                            }
                        }
                        
                    }, () => this.setView())
                }

                if (prevProps.shop !== this.props.shop) {
                    this.setView();
                }

                if (prevProps.author !== this.props.author) {
                    this.setView();
                } 
            }

            componentWillUnmount = () => {
                if (this.props.type === 'subcategory') {
                    this.state.query && this.state.query.cancel();
                }
                if (this.props.type === 'category') {
                    this.state.query && this.state.query.cancel();
                }
            }

            checkIfInGenre(book) {
                let active_genre = this.storeUi.app_interface.active_genre;
                
                let cats_in_genre = this.storeBooks.connCategoriesGenresList
                    .filter((conn) => conn.genre_id == active_genre);
                let isInGenre = false;

                return isInGenre;
            }

            handleBlocksNav = (e, view) => {
                this.setState({
                    view: view
                })
                this.storeUi.setPageView('view', view)
            }

            handleClick = (cat_name) => {
                this.storeUi.setActiveSubCategory(''); 
                this.storeUi.setPageView('page', 1);

                let url = "/category/" + cat_name;
                this.props.history.push(url);
            }

            handleSort = (type, sorting_dir) => {
                this.setState({
                    sorting: type,
                    sorting_dir: sorting_dir
                })

                this.storeUi.setPageView('sorting', type);
                this.storeUi.setPageView('order', sorting_dir);
                this.setView();
            }

            removeSort = () => {
                const sorting_dir = 'DESC';
                const type = 'ID';

                this.storeUi.setPageView('sorting', type);
                this.storeUi.setPageView('order', sorting_dir);
                this.setView();

                this.setState({
                    sorting: 'ID'
                })
            }

            handleCheckboxChange = (event) => {
                const name = 'stock_priority';

                this.storeCatalogs.setStockPriority(!this.state.formControls[name].value);
                this.storeUi.setPageView('page', 1);

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...this.state.formControls[name],
                            value: !this.state.formControls[name].value,
                            selected: !this.state.formControls[name].value ? 1 : 0
                        }
                    }
                    
                }, () => this.setView())
            }

            setView = async() => {
                let idx;
                let shop;
                let mainCats;
                let mainCat;
                let q;

                const priority = (this.state.formControls.stock_priority.value === 1 || this.state.formControls.stock_priority.value === true) ? 1 : 0; 
                this.storeUi.setStockPriority(priority);
                let cat_name = (this.storeUi.app_interface.active_category_name === undefined) ? this.props.match.params.name : this.storeUi.app_interface.active_category_name;
                //const cat_name = this.props.match.params.name;
                this.storeUi.setActiveCategory(cat_name);
                
                if (this.props.type === 'category') {                   
                    this.storeUi.getBookCountForCategory(cat_name);
                    
                    q = this.storeBooks.getBooksForCategoryWithAbort(cat_name, this.storeUi.page_view, priority, this.controller, this.signal);
                    q.then((result) => {  
                        this.storeBooks.setBookListCategory(result.data);
                    });
                }

                const breadcrumbs = this.storeCatalogs.getBreadcrumbs();

                if (this.props.type === 'subcategory') {
                    let subCat = this.props.match.params.name;

                    const result = await this.storeUi.checkShopForSubCategory(this.props.match.params.name);
                    let shop = result.shop;
                    if (shop === 'SHOP_SB') {
                        mainCats = await this.storeUi.returnMainCategory('SHOP_SB', subCat);

                        if (this.storeCatalogs.activeCategory !== '') {
                            mainCats.map((cat) => {
                                if (cat.category === this.storeCatalogs.activeCategory) {
                                    mainCat = cat.category;
                                    this.storeUi.setActiveCategory(mainCat);
                                }
                            })
                        } else {
                            if (mainCats[0]) {
                                mainCat = mainCats[0].category;
                                this.storeUi.setActiveCategory(mainCats[0].category);
                            }
                        }
                    } else {
                        let mainCatId = result.main_cat;
                        mainCat = await this.storeUi.getMainCategory(shop, mainCatId);
                    }

                    
                    const count = await this.storeUi.getBookCountForSubCategory(shop, mainCat, subCat);
                    this.storeUi.setActiveSubCategory(result.subcategory);
                    //console.log('SbC check subcat', shop, mainCats, mainCat, subCat, count)

                    //SbC should be used in newer version
                    this.storeCatalogs.setActiveSubCategory(result.subcategory);
                    this.storeCatalogs.setActiveCount(count);

                    q = this.storeBooks.getBooksForSubCategoryWithAbort(mainCat, subCat, this.storeUi.page_view, priority, this.controller, this.signal);

                    this.setState({
                        query: q
                    })
                    q.then((result) => {  
                        this.storeBooks.setBookListSubCategory(result.data);
                    });
                } 

                if (this.props.type === 'author') {
                    if (this.storeUi.checkFeature('SHOP_ALL')) {
                        shop = 'SHOP_ALL';
                    } else {
                        shop = 'SHOP_SB';
                    }
                    this.storeBooks.getBooksForAuthor(this.storeUi.page_view, this.props.match.params.id, '', '', shop);  
                } 

                if (this.props.type === 'search') {
                    //this.storeUi.getSearchResultsSB(this.storeUi.search_value, this.storeUi.app_interface.active_search_segment, this.storeUi.page_view, '');
                    if (this.storeUi.checkFeature('SHOP_ALL')) {
                        // if (this.storeGeneral.shop === 'SHOP_ALL') {
                        //     this.storeUi.getSearchResults(this.storeUi.search_value, '', this.storeUi.page_view);
                        // } else {
                        //     this.storeUi.getSearchResultsSB(this.storeUi.search_value, '', this.storeUi.page_view);
                        // }

                        if (this.storeGeneral.searchShop === 'ALL') {
                            this.storeUi.getSearchResults(this.storeUi.search_value, '', this.storeUi.page_view);
                        }
                        if (this.storeGeneral.searchShop === 'SB') {
                            this.storeUi.getSearchResultsSB(this.storeUi.search_value, '', this.storeUi.page_view);
                        }
                        if (this.storeGeneral.searchShop === 'OWN') {
                            this.storeUi.getSearchResultsSB(this.storeUi.search_value, 'sb', this.storeUi.page_view);
                        }
                    } else {
                        this.storeUi.getSearchResultsSB(this.storeUi.search_value, '', this.storeUi.page_view);
                    }
                }

                if (this.props.type === 'language') {
                    if (this.storeUi.active_language !== '') {
                        idx = this.storeUi.list_languages.findIndex(lang => lang.abbr === this.storeUi.active_language);
                    } else {
                        idx = this.storeUi.list_languages.findIndex(lang => lang.display_value === this.props.match.params.lang);
                    }
                    this.storeBooks.getBooksForLanguage(this.storeUi.list_languages[idx].query_value, this.storeUi.page_view, priority);
                }

                if (this.props.type === 'new') { 
                    const timeframe = this.props.match.params.view;                   
                    this.storeBooks.getBooksNew(timeframe, this.storeUi.page_view);
                }

                if (this.props.type === 'sb') {
                    this.storeBooks.getBooks('SHOP_SB', this.storeUi.page_view);
                }

                if (this.props.type === 'alternative') {
                    
                }

                if (this.props.type === 'products') {
                    const priority = (this.state.formControls.stock_priority.value === true) ? 1 : 0; 
                    if (this.props.subgroup === '') {
                        this.storeProducts.getProductsForCategory(this.storeUi.active_product_group, this.storeUi.page_view, priority);
                    } else {
                        this.storeProducts.getProductsForSubGroup(this.storeUi.active_product_group, this.storeUi.active_subgroup, this.storeUi.page_view, priority);
                    }
                    ui.handleScroll('top');
                }

                this.storeUi.setFreezePaging(false);
                
                if (!this.storeUi.freezeScrolling) {
                    ui.handleScroll('top');
                }
            }

            removeDuplicates = (arr_list) => {
                let arr_sku = [];
                let list_filtered = [];
                const result = arr_list.map(item => {
                    if (!arr_sku.includes(item.sku)) {
                        arr_sku.push(item.sku);
                        list_filtered.push(item);
                    } 
                });
                return list_filtered;
            }
  
            render() {
                const blockCat = (this.props.blockCat) ? this.props.blockCat : this.props.match.params.name;

                //const blockTitle = (this.props.blockTitle) ? this.props.blockTitle : 'Boeken';
                // SbC products
                const blockTitle = (this.storeUi.active_subgroup == '') ? this.storeUi.active_product_group : this.storeUi.active_subgroup;
                const blockSubTitle = (this.storeUi.active_subgroup == '') ? '' : this.storeUi.active_product_group;

                let booksList = [];
                let lang;
                let idx;

                const author = this.storeBooks.authorsList
                    .filter((author) => author.id === this.storeUi.app_interface.current_author);

                switch(this.props.type) {
                    case "all":
                        booksList = this.storeBooks.bookList_all;
                        break;
                    case "author":
                        if (this.storeUi.app_interface.current_author === 0) {
                            booksList = this.storeBooks.booksList;
                        } else {
                            booksList = this.storeBooks.authorBooksList
                        }
                        break;
                    case "author_sb":
                            if (this.storeUi.app_interface.current_author === 0) {
                                booksList = this.storeBooks.booksList;
                            } else {
                                booksList = this.storeBooks.authorBooksList
                                    .filter(book => book.sb_edition === "eigen uitgaven")
                            }
                            break;
                    case "category":
                        booksList =  this.storeBooks.bookList_category
                        break;
                    case "genre":
                        if (this.storeUi.app_interface.active_genre === 0) {
                            booksList = this.storeBooks.booksList;
                        } else {
                            booksList = this.storeBooks.booksList
                                .filter((book) => this.checkIfInGenre(book));
                            if (this.props.top === true) {
                                booksList = booksList
                                    .filter((book) => book.top);
                            }                                    
                        }   
                        break;
                    case "language":
                        booksList = this.storeBooks.bookList_language;
                        if (this.storeUi.active_language !== '') {
                            idx = this.storeUi.list_languages.findIndex(lang => lang.abbr === this.storeUi.active_language);
                        } else {
                            idx = this.storeUi.list_languages.findIndex(lang => lang.display_value === this.props.match.params.lang);
                        }
                        lang = this.storeUi.list_languages[idx].display_value;
                        break;
                    case "new":
                        booksList = this.storeBooks.bookList_new;
                        break;
                    case "new_this_month":
                        booksList = this.storeBooks.bookList_new;
                        break;
                    case "new_this_week":
                        booksList = this.storeBooks.bookList_new;
                        break;
                    case "newsletter":
                        booksList = this.storeBooks.newsletter_tips;
                        break;
                    case "products":
                        booksList = this.storeProducts.list_products;
                        break;
                    case "sb":
                        booksList = this.storeBooks.bookList_sb;
                        break;
                    case "search":
                        //booksList = this.storeUi.search_results;
                        if (this.props.searchShop === 'OWN') {
                            booksList = this.storeUi.search_results_own;
                        }
                        if (this.props.searchShop === 'SB') {
                            booksList = this.storeUi.search_results_sb;
                        }
                        if (this.props.searchShop === 'ALL') {
                            booksList = this.storeUi.search_results_all;
                        }
                        break;
                    case "alternative":
                        if (this.props.searchShop === 'SB') {
                            booksList = this.storeUi.search_results_sb;
                            //this.storeUi.setActiveToggleNav(2);
                        }
                        if (this.props.searchShop === 'ALL') {
                            booksList = this.storeUi.search_results_all;
                            //this.storeUi.setActiveToggleNav(3);
                        }
                        break;
                    case "subcategory":
                        booksList = this.storeBooks.bookList_subcat;        
                        break;
                    default:
                }

                let blockview;
                if (this.storeUi.page_view.view && this.storeUi.page_view.view !== '') {
                    blockview = this.storeUi.page_view.view;
                } else {
                    if (this.state.view == '' ) {
                        if (booksList !== null &&  booksList.length < 5) {
                            blockview = 'summaries'
                        } else {
                            blockview = 'covers'
                        }
                    } else {
                        blockview = this.state.view
                    }
                }

                const Li = (view) => {
                    const activeClass = (view.view === blockview) ? ' --active' : '';
                    return (
                        <li className={"blocks-nav__listitem blocks-nav__listitem--" + view.view + activeClass} onClick={(e) => this.handleBlocksNav(e, view.view)}>
                            <span>{view.view}</span>
                        </li>
                    )
                }
 
                return (
                    <div className="blocks blocks--books">

                        {(this.props.type === 'category' || 
                            this.props.type === 'subcategory' ||
                            this.props.type === 'language' ||
                            this.props.type === 'search') &&
                            (this.state.sorting === 'ID') &&
                            <div className="block block--stock-priority">
                                <Checkbox 
                                    name = {"stock_priority"} 
                                    label = {this.state.formControls["stock_priority"].label}
                                    value = {this.state.formControls["stock_priority"].value}
                                    isSelected = {this.state.formControls["stock_priority"].value}
                                    onCheckboxChange={this.handleCheckboxChange}
                                    className = {"--reduced"}
                                />
                            </div>
                        }

                        {this.props.type !== 'search' &&
                            <div className="blocks__blocks-title">
                                {this.props.type !== 'new' && this.props.type !== 'search' && this.props.type !== 'subcategory' && this.props.blockCat && this.props.blockTitle && blockCat !== '' &&
                                    <React.Fragment>
                                        <span 
                                            className = "blocks__blocks-title--main blocks__blocks-title--main-cat"
                                            dangerouslySetInnerHTML = {{ __html: this.storeGeneral.shop === 'SHOP_ALL' ? this.storeUi.active_category_display_value : blockCat}}
                                        />
                                        {(this.props.type === 'category' ||
                                        this.props.type === 'language') &&
                                            <span>({this.storeUi.count_total})</span>
                                        }

                                        {this.storeUi.checkFeature('SHOP_ALL') &&
                                            <BlockCategory 
                                                type = {this.props.type}
                                                category = {this.storeUi.active_category}
                                                category_name = {this.storeUi.active_category}
                                                cat_active = {this.storeUi.active_category}
                                                subs_active = {this.state.subs_active}
                                                subcategory = {this.props.subcategory}
                                            />
                                        }
                                    </React.Fragment>
                                }  

                                {(this.props.type === 'subcategory') &&
                                    <React.Fragment>
                                        {(!this.props.cats_main 
                                        || this.props.cats_main.length === 0 
                                        || this.props.cats_main.length === 1
                                        || (typeof this.props.cats_main === 'object' && this.props.cats_main !== null)
                                        ) &&
                                            <React.Fragment>
                                            <span 
                                                className = "blocks__blocks-title--sub --link"
                                                dangerouslySetInnerHTML = {{ __html: this.storeGeneral.shop === 'SHOP_ALL' ? this.storeUi.active_category_display_value : this.storeUi.active_category}}
                                                onClick = {() => this.handleClick(this.storeUi.active_category)}
                                            />
                                            
                                            {this.storeUi.checkFeature('SHOP_ALL') &&
                                                <BlockCategory 
                                                    type = {this.props.type}
                                                    category = {this.storeUi.active_category}
                                                    category_name = {this.storeUi.active_category}
                                                    cat_active = {this.storeUi.active_category}
                                                    subs_active = {this.state.subs_active}
                                                    subcategory = {this.props.subcategory}
                                                />
                                            }
                                            
                                            <span 
                                                className="blocks__blocks-title--main blocks__blocks-title--main-cat"
                                                dangerouslySetInnerHTML={{ __html: this.storeGeneral.shop === 'SHOP_ALL' ? this.storeUi.active_subcategory_display_value : this.storeUi.app_interface.active_subcategory_name}}
                                            />
                                            </React.Fragment>
                                        }

                                        {this.props.cats_main && this.props.cats_main.length > 1 &&
                                        typeof this.props.cats_main !== 'object' &&
                                            <span 
                                                className="blocks__blocks-title--main"
                                                dangerouslySetInnerHTML={{ __html: this.storeGeneral.shop === 'SHOP_ALL' ? this.storeUi.active_subcategory_display_value : this.storeUi.app_interface.active_subcategory_name}}
                                            />
                                        }
                                    </React.Fragment>
                                }

                                {this.props.type === 'language' &&
                                <React.Fragment>
                                    <div className="blocks__blocks-title">
                                        <span 
                                            className="blocks__blocks-title--main-section"
                                            dangerouslySetInnerHTML={{ __html: 'Anderstalige boeken'}}
                                        />
                                        <span 
                                            className="blocks__blocks-title--main-cat blocks__blocks-title--main-lang"
                                            dangerouslySetInnerHTML={{ __html: lang}}
                                        />
                                        <span>
                                            ({this.storeUi.count_total_lang})
                                        </span>
                                    </div> 
                                </React.Fragment>
                                }
                                
                                {(this.props.type === 'subcategory') &&
                                <React.Fragment>
                                    {/* <span>({this.storeUi.count_total})</span> */}
                                    <span>({this.storeCatalogs.getActiveCount()})</span>
                                </React.Fragment>
                                }

                                {this.props.type === 'products' &&
                                    <React.Fragment>
                                    <div className="blocks__blocks-title">
                                        {blockSubTitle !== ''
                                        ?   <span 
                                                className="blocks__blocks-title--main-section"
                                                dangerouslySetInnerHTML={{ __html: blockSubTitle}}
                                            />
                                        :   <span 
                                                className="blocks__blocks-title--main-section"
                                                dangerouslySetInnerHTML={{ __html: 'Producten'}}
                                            />
                                        }
                                        <span 
                                            className="blocks__blocks-title--main-cat"
                                            dangerouslySetInnerHTML={{ __html: blockTitle}}
                                        />
                                        <span>
                                            ({this.storeUi.count_total})
                                        </span>
                                        
                                    </div> 
                                    {this.storeUi.checkFeature('SHOP_ALL') &&
                                        <BlockCategory 
                                            type = {this.props.type}
                                            category = {this.storeUi.active_category}
                                            category_name = {this.storeUi.active_category}
                                            cat_active = {this.storeUi.active_category}
                                            subs_active = {this.state.subs_active}
                                            subcategory = {this.props.subcategory}
                                        />
                                    }
                                    </React.Fragment>
                                }
                            </div> 
                        }

                        {((this.props.type === 'author_sb' && this.props.blockTitle) 
                        || (this.props.type === 'author' && this.props.blockTitle)) &&
                        booksList.length > 0 &&
                            <div className="blocks__blocks-title">
                                <span className="blocks__blocks-title--main">{this.props.blockTitle}</span>
                            </div> 
                        }

                        <div className="blocks__content">
                            {/* {(this.props.type === 'category' || 
                                this.props.type === 'subcategory' ||
                                this.props.type === 'language' ||
                                this.props.type === 'search') &&
                                (this.state.sorting === 'ID') &&
                                <div className="block block--stock-priority">
                                    <Checkbox 
                                        name = {"stock_priority"} 
                                        label = {this.state.formControls["stock_priority"].label}
                                        value = {this.state.formControls["stock_priority"].value}
                                        isSelected = {this.state.formControls["stock_priority"].value}
                                        onCheckboxChange={this.handleCheckboxChange}
                                        className = {"--reduced"}
                                    />
                                </div>
                            } */}

                            {booksList && booksList.length > 0
                            ?   <React.Fragment>  
                                    <div className="blocks__filters">
                                    <div className={"blocks__nav" + (this.props.type === 'search' ? " blocks__nav--top" : "")}>
                                        <div className="blocks__nav-actions">
                                            <div className="blocks-nav__list --column">
                                                {(this.props.type === 'category' || 
                                                  this.props.type === 'subcategory' || 
                                                  this.props.type === 'products' || 
                                                  this.props.type === 'search' || 
                                                  this.props.type === 'language' || 
                                                  this.props.type === 'new' || 
                                                  this.props.type === 'author' ||
                                                  this.props.type === 'sb') &&
                                                    <ul className="blocks-nav__list--sort">
                                                        <li>Sorteer&nbsp;op:</li>
                                                        <LiSort 
                                                            sort = 'title'
                                                            handleSort = {this.handleSort}
                                                            sorting = {this.state.sorting}
                                                        />
                                                        <LiSort 
                                                            sort = 'price_incl'
                                                            handleSort = {this.handleSort}
                                                            sorting = {this.state.sorting}
                                                        />
                                                        {this.props.type !== 'author' && this.props.type !== 'author_sb' &&
                                                            <LiSort 
                                                                sort = 'author_lastname'
                                                                handleSort = {this.handleSort}
                                                                sorting = {this.state.sorting}
                                                            />
                                                        }
                                                        <LiSort 
                                                            sort = 'release_date'
                                                            handleSort = {this.handleSort}
                                                            sorting = {this.state.sorting}
                                                        />
                                                    </ul>       
                                                } 
                                                {this.state.sorting !== 'ID' &&
                                                    <span  className="blocks-nav__list--unsort --link" onClick={(e) => this.removeSort()}>Verwijder sortering</span>
                                                }
                                            </div>
                                            <div className="blocks-nav__list">
                                                <ul className="blocks-nav__list--view">
                                                    <Li 
                                                        view = 'summaries'
                                                    />
                                                    <Li 
                                                        view = 'covers'
                                                    />
                                                    <Li 
                                                        view = 'list'
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {(this.props.type === 'author' || 
                                      this.props.type === 'category' || 
                                      this.props.type === 'subcategory' || 
                                      this.props.type === 'products' || 
                                      this.props.type === 'new' || 
                                      this.props.type === 'search' || 
                                      this.props.type === 'language' ||
                                      this.props.type === 'sb') &&
                                        <BlockPagination 
                                            searchShop = {this.storeGeneral.searchShop}
                                            type = {this.props.type}
                                            setView = {this.setView}
                                            total = {this.state.type == 'search' 
                                                ? this.storeUi.search_count 
                                                : this.storeUi.count_total
                                            }
                                            page = {this.storeUi.page_view.page}
                                            location = {this.props.type === 'search' ? 'top' : ''}
                                        />
                                    }
                                    </div>

                                    {/* {(this.props.type === 'category' || 
                                      this.props.type === 'subcategory' ||
                                      this.props.type === 'language' ||
                                      this.props.type === 'search') &&
                                      (this.state.sorting === 'ID') &&
                                        <div className="block block--stock-priority">
                                            <Checkbox 
                                                name = {"stock_priority"} 
                                                label = {this.state.formControls["stock_priority"].label}
                                                value = {this.state.formControls["stock_priority"].value}
                                                isSelected = {this.state.formControls["stock_priority"].value}
                                                onCheckboxChange={this.handleCheckboxChange}
                                                className = {"--reduced"}
                                            />
                                        </div>
                                    } */}

                                    <ul className={"blocks__list blocks__list--" + blockview + " blocks__list--" + this.props.type}>
                                        {booksList.map((book, i) => ( 
                                            book !== undefined &&
                                                <React.Fragment>
                                                    {/* <li 
                                                        className={"block block--book" + (this.props.type === 'search' && book["[elevated]"] ? " --elevated" : "") + (book.sku === this.storeGeneral.settings_list.skuSoonAvailable ? " --soon" : "")} key={book.sku}
                                                    >
                                                        <BlockViews 
                                                            view = {blockview}
                                                            book = {book}
                                                            page = {this.props.page}
                                                            author = {this.props.author}
                                                        />
                                                    </li> */}
                                                <BlocksBookLi 
                                                    type = {this.props.type }
                                                    view = {blockview}
                                                    book = {book}
                                                    page = {this.props.page}
                                                    author = {this.props.author}
                                                />
                                                </React.Fragment>
                                        ))}
                                    </ul>

                                    {(this.props.type == 'author' || 
                                      this.props.type == 'category' || 
                                      this.props.type == 'subcategory' || 
                                      this.props.type == 'products' || 
                                      this.props.type === 'new' || 
                                      this.props.type === 'search' || 
                                      this.props.type === 'language' ||
                                      this.props.type === 'sb') &&
                                        <BlockPagination 
                                            searchShop = {this.storeGeneral.searchShop}
                                            type = {this.props.type}
                                            setView = {this.setView}
                                            total = {this.state.type == 'search' 
                                                ? this.storeUi.search_count 
                                                : this.storeUi.count_total
                                            }
                                            page = {this.storeUi.page_view.page}
                                        />
                                    }
                                    </React.Fragment>
                            :   <React.Fragment>
                                    {this.props.type === "author" || this.props.type === "author_sb"
                                    ?   <React.Fragment>
                                            {this.props.type === "author" && this.storeBooks.authorBooksList.findIndex(book => book.sb_edition === "eigen uitgaven") === -1 &&
                                                <p className="content--empty">Er zijn momenteel geen boeken van {this.props.author} beschikbaar</p>
                                            }

                                            {this.props.type === "author" && this.storeBooks.authorBooksList.findIndex(book => book.sb_edition === "eigen uitgaven") !== -1 &&
                                                <p className="content--empty">Er zijn momenteel geen andere boeken van {this.props.author} beschikbaar</p>
                                            }

                                            {this.props.type === "author_sb" &&
                                                <p className="content--empty">Er zijn momenteel geen boeken van {this.props.author} beschikbaar</p>
                                            }
                                        </React.Fragment>
                                    :   <React.Fragment>
                                            <LoaderFeedback 
                                                type = {this.props.type}
                                            />
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlocksBooks));

