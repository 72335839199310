import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import * as ui from '../../utilities/ui';
import Template from '../../templates/template';

import BlocksEvents from '../../components/blocks/blocks-events';

const Events = inject("stores") (
    observer (
        class Events extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }

            render() {
                return (
                    <Template
                        type = 'default'
                        header = "events"
                        nav_name = {"events"}
                    >
                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Ontmoet de auteur</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Alles wat je tot nu toe hebt bereikt is hoogstwaarschijnlijk gegaan via boeken en mondelinge overdracht (denk aan onderwijzers, schoolklas, colleges, cursussen, etc.) Het is net als bij je rijbewijs: na je schooltijd/collegetijd begint het leren pas echt. Daarom is het zo belangrijk om te blijven investeren in jezelf door bijv. goede boeken te lezen of door naar seminars te gaan. Schrijvers te ontmoeten die tientallen jaren een bepaalde materie hebben bestudeerd. En die tientallen jaren, vaak gecombineerd met een boek voor jou, samenvatten in 1 dag. Daarom moet je de stekker er niet uittrekken, maar 'ingeplugd' blijven. Vandaar onderstaande informatie.</p>
                                <p><strong>Momenteel zijn alle lezingen en/of seminars tot nader bericht uitgesteld.</strong></p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Tip ons</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Organiseer je een seminar - of weet je een seminar - met een schrijver waarvan wij op deze website een boek promoten, <a href="mailto:info@succesboeken.nl?subject=seminar promotie">laat het ons weten</a>.</p>
                            </div>
                        </div>

                        {/* <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Evenementen</h2>
                            </div>
                            <div className="content-segment__content">
                                <BlocksEvents 
                                    view = {"all"}
                                />
                            </div>
                        </div> */}
                    </Template>
                )
            }
        }
    )
)

export default Events;
