import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const LoaderFeedback = inject("stores") (
    observer (
        class LoaderFeedback extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;
            }

            render() {     
                return (
                    <div className="loader__feedback">
                        <p 
                            className="content--empty"
                            dangerouslySetInnerHTML={{ __html: this.storeGeneral.app_interface.feedback_loader}}
                        />
                        {this.storeUi.checkFeature('SHOP_ALL') &&
                            <React.Fragment>
                                {(this.props.type === 'search' && !this.storeUi.search_count && this.storeGeneral.searchShop === 'OWN') &&
                                    <div className="content-segment">
                                        <div className="content--empty-segment">
                                            Of selecteer hierboven <strong>'Alle zelfhulpboeken'</strong> of <strong>'Alle boeken'</strong> om het zoekbereik te vergroten.
                                        </div>
                                    </div>
                                }
                                {(this.props.type === 'search' && !this.storeUi.search_count && this.storeGeneral.searchShop === 'SB') &&
                                    <div className="content-segment">
                                        <div className="content--empty-segment">
                                            Of selecteer hierboven <strong>'Alle boeken'</strong> om het zoekbereik te vergroten.
                                        </div>
                                </div>
                                }
                            </React.Fragment>
                        }
                    </div>
                )
            }
        }
    )
)

export default LoaderFeedback;
