import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Template from '../../templates/template';

const Error404 = inject("stores") (
    observer (
        class Error404 extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }
    
            render() {
                return (
                    <Template
                        type = {'error'}
                        header = {"error404"}
                    >
                        <div className="content-segment">
                            <span
                                dangerouslySetInnerHTML={{ __html: i18n.t('error.404.content')}}
                            />
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default  withTranslation()(Error404);
