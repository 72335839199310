import React from 'react';
import i18n from "i18next";

const ButtonToggleExpand = (props) => {
 
    return (
        <span className={"link--read-more actionShowMore" + (props.foldout ? "--up" : "")} onClick={(e) => props.expandBlocks(e)}>
            {props.foldout 
                ?   i18n.t(props.txt_up, {amount: props.amount})
                :   i18n.t(props.txt_down, {amount: props.amount})
            }
        </span>
    );
}

export default ButtonToggleExpand;