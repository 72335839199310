import * as apifetch from './fetch.js';

export async function sendFormContact(form, data) {
    const urlEndpoint = '/v2/email/contact';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function sendFormEvent(ref, data) {
    const urlEndpoint = '/v2/events/' + ref + '/registration';

    const postData = data;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function sendFormCallMeBack(form, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/email/sendFormCallMeBack.php';

    const postData = {
        data: data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        let arrData = {'data': [{error : 'An error has occurred'}]};
        return arrData;
    }
}

export async function sendFormReview(form, book_data, form_data, upload_file) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/email/sendFormReview.php';

    const postData = {
        book: book_data,
        data: form_data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
                
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            console.log('SbC:: return file upload::', responseJson)
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        let arrData = {'data': [{error : 'An error has occurred'}]};
        return arrData;
    }
}

export async function sendFormPress(form, data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/email/sendFormPress.php';

    const postData = {
        data: data
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        let arrData = {'data': [{error : 'An error has occurred'}]};
        return arrData;
    }
}

export async function sendFormSendToFriend(form, data, book) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/email/sendFormSendToFriend.php';

    const postData = {
        data: data,
        book: book
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        let arrData = {'data': [{error : 'An error has occurred'}]};
        return arrData;
    }
}