import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as account from '../../communicator/account';
import validate from '../../utilities/validation-rules';

import RowText from '../form-rows/row-text';

const FormBusiness = inject("stores") (
    observer (
        class FormBusiness extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeForms = this.props.stores.storeForms;
                
                this.state = {
                    formControls: { 
                        company: {
                            type: "text",
                            value: this.props.data.company 
                                ?   this.props.data.company 
                                :   this.storeUser.user_data.business.company,
                            validationRules: {},
                            valid: true
                        },
                        nrKvK: {
                            type: "text",
                            value: this.props.data.nrKvK 
                                ?   this.props.data.nrKvK 
                                :   this.storeUser.user_data.business.nrKvK,
                            validationRules: {
                                minLength: 4
                            },
                            validationMsg: i18n.t("forms.validation.nrKvK"),
                            valid: true
                        },
                        nrVAT: {
                            type: "text",
                            value: this.props.data.nrVAT 
                                ?   this.props.data.nrVAT
                                :   this.storeUser.user_data.business.nrVAT,
                            validationRules: {
                                minLength: 3,
                            },
                            validationMsg: i18n.t("forms.validation.nrVAT"),
                            valid: true
                        }
                    },
                    VATcountry: this.props.country_code,
                    VATcheck: false,
                    VATfeedback: false,
                    VATdata: {},
                    VATerror: '',
                    VATvalid: true,
                    formIsValid: true
                }
            }

            componentDidMount() {
                this.validateForm();
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.country_code !== this.props.country_code) {
                    this.setState({
                        VATcountry: this.props.country_code
                    })
                }

                if (prevProps.is_business !== this.props.is_business) {
                    this.validateForm();
                }
            }

            validateRow = (name, value, valid, touched) => {  
                this.storeUi.clearFormFeedback();

                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid, touched);

                this.setState({
                    error: null,
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = async() => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid
                }, () => this.props.handleFormChange(this.state.formControls, this.state.formIsValid, 'business'))
            }

            async checkVATNummer() {
                let countryCode = '';

                if (this.storeUi.app_interface.use_case === "inorder") { 
                    if (this.storeOrder.order_data.client_profile.country_code === '') {
                        this.setState({
                            VATcheck: true,
                            VATfeedback: true,
                            VATdata: {},
                            VATerror: "Je moet een land selecteren bij je persoonlijke gegevens",
                            VATvalid: false
                        })
                    } else {
                        countryCode = this.storeOrder.order_data.client_profile.country_code
                    }
                } else {
                    if (this.props.country_code === '') {
                        this.setState({
                            VATcheck: true,
                            VATfeedback: true,
                            VATdata: {},
                            VATerror: "Je moet een land selecteren bij je persoonlijke gegevens",
                            VATvalid: false
                        })
                    } else {
                        countryCode = this.props.country_code
                    }
                }

                const nrVAT = this.state.formControls['nrVAT'].value;
                if (nrVAT !== '') {
                    const data = await this.storeUi.checkVATNumber(countryCode, nrVAT);

                    this.setState({
                        VATcheck: true,
                        VATfeedback: true,
                        VATdata: data,
                        VATvalid: true
                    })

                    if ('error' in data) {
                        const error_msg = "errors." + data.error;
                        this.setState({
                            VATdata: {},
                            VATerror: "De invoer is niet geldig. Of je hebt in je profielgegevens een land buiten de EU geselecteerd of het btw-nummer begint met een ongeldige landcode",
                            VATvalid: false
                        })
                    } else {
                        this.setState({
                            VATerror: ''
                        })
                    
                        if (!data.valid || data.valid === 'false') {
                            const idx = this.storeOrder.countriesList.findIndex(country => country.code == data.country_code);
                            const country = this.storeOrder.countriesList[idx].displayValue;
                            if (data.country_code === data.vat_country) {
                                this.setState({
                                    VATcheck: true,
                                    VATfeedback: true,
                                    VATdata: {},
                                    VATerror: "Dit is geen geldig btw-nummer in " + country + " (het land geselecteerd in je profielgegevens)",
                                    VATvalid: false
                                })
                            } else {
                                const idx_vat = this.storeOrder.countriesList.findIndex(country => country.code == data.vat_country);
                                const country_vat = this.storeOrder.countriesList[idx_vat].displayValue;
                                this.setState({
                                    VATcheck: true,
                                    VATfeedback: true,
                                    VATdata: {},
                                    VATerror: "De landcode van het btw-nummer (" + country_vat + ") komt niet overeen met het geselecteerde land in je profielgegevens (" + country + ")",
                                    VATvalid: false
                                })
                            }
                        }
                    }
                } else {
                    this.setState({
                        VATcheck: true,
                        VATfeedback: true,
                        VATdata: {},
                        VATerror: "Het invoerveld voor het btw-nummer kan niet leeg zijn",
                        VATvalid: false
                    })
                }
            }

            showCheckVAT = () => {
                const idx = this.storeOrder.countriesList.findIndex(country => country.code == this.props.country_code);
                const showCheck = (idx > -1 && this.storeOrder.countriesList[idx].eu === 1) ? true : false;

                return showCheck;
            }

            render() {
                return (
                    <form className="form">                      
                        {Object.keys(this.state.formControls).map((key, i) => (
                            <React.Fragment>
                                {this.state.formControls[key].type === "text" &&
                                    <RowText 
                                        type = {this.state.formControls[key].type}
                                        name = {key}
                                        value = {this.state.formControls[key].value}
                                        validationRules = {this.state.formControls[key].validationRules}
                                        validationMsg = {this.state.formControls[key].validationMsg}
                                        onValidate = {this.validateRow}
                                    />
                                }
                            
                                {key === 'nrVAT' && this.showCheckVAT() &&
                                    <React.Fragment>
                                        <span className="form-link--text" onClick={() => this.checkVATNummer()}>Controleer btw-nummer</span>
                                        {this.state.VATcheck && this.state.VATfeedback &&
                                            <div className="form-row form-row--feedback">
                                                {this.state.VATvalid === true &&
                                                    <React.Fragment>
                                                        <span>Dit btw-nummer behoort bij:</span>
                                                        <span>{this.state.VATdata.name}</span>
                                                        <span>{this.state.VATdata.address}</span>
                                                        <span>{this.state.VATdata.country}</span>
                                                    </React.Fragment>
                                                }
                                                {this.state.VATvalid === false &&
                                                    <span className="form-field--error">{this.state.VATerror}</span>
                                                }
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                                </React.Fragment>
                            
                            
                        ))}
                    </form>
                )
            }
        }
    )
)

export default withTranslation()(FormBusiness);
