import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import countries from "i18n-iso-countries";
import * as settings from '../communicator/settings';
import * as sort from '../utilities/sort';
import * as tools from '../communicator/tools';

class StoreGeneral {
    shop = 'SHOP_SB'
    activeShop = ''
    searchShop = ''
    searchSegment = ''
    app_interface = { 
        loader: false,
        feedback_loader: ''
    }

    settings_list = []
    social_links = []
    countriesList = []

    constructor() {
        this.getSettings();
        this.getCountries();
        this.getSearchShop();
    }

    startLoader() {
        this.app_interface.loader = true;
        this.app_interface.feedback_loader = 'We zoeken een resultaat voor je ...';
    }

    stopLoader() {
        this.app_interface.loader = false;
        this.app_interface.feedback_loader = 'Er zijn geen resultaten gevonden.<br/>Kies een andere categorie of gebruik een andere zoekterm';
    }

    async getCountries() {
        try {
            const returnData = await tools.getCountries();
            runInAction(() => {
                this.countriesList = returnData.data;
                this.translateCountries();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
   
    async getSettings() {
        let settingsList;
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {
                settingsList = returnData.data;
                settingsList.map(setting => {
                    this.settings_list[setting.SettingName] = setting.SettingValue;
                })
                this.setSocialLinks();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    getActiveShop() {
        let activeShop = '';

        /*
         * SbC DEPRICATED
         * Always enter on Zelfhulpboeken
         */

        // if (localStorage.getItem('SB_LASTVISIT')) {
        //     activeShop = localStorage.getItem('SB_LASTVISIT');
        //     this.shop = localStorage.getItem('SB_LASTVISIT');
        //     this.activeShop = localStorage.getItem('SB_LASTVISIT');
        // } else {
        //     this.shop = 'SHOP_SB';
        //     this.activeShop = 'SHOP_SB';
        // }

        return activeShop;
    }

    getSearchShop() {
        if (localStorage.getItem('SB_LASTVISIT')) {
            if (localStorage.getItem('SB_LASTVISIT') == 'SHOP_ALL') {
                this.searchShop = 'ALL';
            } else {
                this.searchShop = 'SB';
            }
            
        } else {
            this.searchShop = 'SB';
        }
    }

    setShop(shop) {
        this.shop = shop;
        if (shop == 'SHOP_ALL') {
            this.searchShop = 'ALL';
        } else {
            this.searchShop = 'SB';
        }
    }

    setActiveShop(shop) {
        this.activeShop = shop;
        localStorage.setItem('SB_LASTVISIT', shop)
        if (shop == 'SHOP_ALL') {
            this.searchShop = 'ALL';
        } else {
            this.searchShop = 'SB';
        }
    }

    setSearchShop(shop) {
        this.searchShop = shop;
    }

    setSearchSegment(segment) {
        this.searchSegment = segment;
    }

    translateCountries() {
        //SbC:: get country translations
        countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

        this.countriesList.map((country, i) => {
            //SbC:: missing values for AN and CS
            switch (country.code) {
                case 'AN':  
                    this.countriesList[i].displayValue = 'Nederlandse Antillen';
                    break;
                case 'CS':  
                    this.countriesList[i].displayValue = 'Servie en Montenegro';
                    break;
                default:    
                    this.countriesList[i].displayValue = countries.getName(country.code, "nl");
            }
        })

        this.countriesList = this.countriesList
            .sort(sort.compareValues('displayValue', 'asc'))
    }

    setSocialLinks() {
        this.social_links = [{
            brand: 'facebook',
            url: this.settings_list['urlFacebook']
        },{
            brand: 'twitter',
            url: this.settings_list['urlTwitter']
        },{
            brand: 'instagram',
            url: this.settings_list['urlInstagram']
        },{
            brand: 'youtube',
            url: this.settings_list['urlYoutube']
        },{
            brand: 'telegram',
            url: this.settings_list['urlTelegram']
        }]
    }
}

decorate(StoreGeneral, {
    activeShop: observable,
    searchShop: observable,
    searchSegment: observable,
    app_interface: observable,
    countriesList: observable,
    settings_list: observable,
    shop: observable,
    social_links: observable,
    getCountries: action,
    getSettings: action,
    setShop: action,
    setActiveShop: action,
    setSearchShop: action,
    setSocialLinks: action,
    setSocialLinks: action,
    startLoader: action,
    stopLoader: action
})

export default StoreGeneral;