import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withTranslation } from 'react-i18next';

import AudioPlayer from '../content/audioplayer';

const BlockPreviewAudio = inject("stores") (
    observer (
        class BlockPreviewAudio extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    filename: '',
                    playing: false
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount() {
                const preview_data = this.storeBooks.preview_audio;
                this.setState({
                    filename: this.storeBooks.preview_audio[0].filename,
                    playing: false
                })
            }

            componentDidUpdate(prevProps) {
                if (prevProps.play !== this.props.play) {
                    this.setState({
                        playing: this.props.play
                    })
                }
            }

            startAudio = (e, filename) => {
                this.setState({
                    filename: filename,
                    playing: true
                })
            }

            stopAudio = (filename) => {
                this.setState({
                    playing: false
                })
            }

            render() {
                const preview_data = this.storeBooks.preview_audio

                if (preview_data.length == 0) {
                    return null;
                }

                return (
                    <div className="blocks blocks--audio">
                        <div className="blocks__blocks-title">
                            <div className="blocks__blocks-title--main">
                                {this.props.blockTitle}
                            </div>
                        </div> 
                        <div className="blocks__content">
                            <div className="wrapper-media__player--audio">
                                <AudioPlayer
                                    type = "audio"
                                    duration = "preview"
                                    filename = {this.state.filename}
                                    playing = {this.state.playing}
                                />
                            </div>
                            <div className="wrapper-media__playlist">
                            <ul className="list--media-playlist">
                                {preview_data.map(preview => (
                                    <li>
                                        <span className="title --link" onClick={(e) => this.startAudio(e, preview.filename)}>
                                            {(preview.title !== '' && preview.title !== undefined)
                                                ?   preview.title
                                                :   preview.filename
                                            }
                                        </span>
                                    </li>
                                ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockPreviewAudio);

