import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import Template from '../templates/template';

import BlockBook from '../components/block/block-book';
import BlockQuote from '../components/block/block-quote';
import CartEmpty from '../components/cart/cart-empty';

const WishList = inject("stores") (
    observer (
        class WishList extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeWishlist = this.props.stores.storeWishlist;
            }

            componentDidMount = ()=>  {
                window.scrollTo(0, 0);
                this.storeUi.setActiveNav('default');
            }

            componentDidUpdate = () => {
            }

            continueShopping = () => {
                const id = (this.storeAuth.user.is_loggedin) ? this.storeAuth.user.user_id : 0;
                
                this.storeOrder.setCartView(id, "client");
                this.storeUi.setUseCase("inshop");
                this.props.history.push("/");
            }
      
            render() {
                let wishlist;
                const id = (this.storeAuth.user.is_loggedin) ? this.storeAuth.user.user_id : 0;

                if (id !== undefined && id !== 0) {
                    wishlist = this.storeWishlist.wishlist.user;
                } else {
                    wishlist = this.storeWishlist.wishlist.anonymous;
                }

                return (
                    <Template
                        type = 'default'
                        header = "wishlist"
                    >
                        <div className="content-segment">
                            {wishlist && wishlist.length > 0
                            ?   <div className="wishlist">
                                    <p>Wil je van een titel meer dan 1 item bestellen, dan kan dat in het winkelmandje.</p>
                                    <p>De volgende titels staan op je verlanglijstje:</p>
                                    <ul className="blocks__list--wishlist">
                                        {wishlist.map((book, i) => ( 
                                            <li className="block block--book" key={i}>
                                                <BlockBook 
                                                    type = "summary"
                                                    book = {book}
                                                    sku = {book.sku}
                                                    has_details = {false}
                                                />
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="cart__actions">
                                        <button className="btn btn--primary" onClick={(e) => this.continueShopping(e)}>{i18n.t("button.continue_shopping")}</button>
                                    </div>
                                </div>
                            :   <CartEmpty
                                    section = "wishlist"
                                />
                            }

                            {this.storeGeneral.shop !== 'SHOP_ALL' &&
                                <BlockQuote 
                                    quote = {this.storeUi.getQuote()}
                                    bg = {"--light"}
                                /> 
                            }
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(WishList));
