import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import NewWindow from 'react-new-window';
import { Link } from 'react-router-dom';
import { reaction } from 'mobx';
import i18n from "i18next";

import CartProcessStep from './cart-step';

const CartProcessSteps = inject("stores") (
    observer (
        class CartProcessSteps extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    steps: [{
                        nr: 1,
                        id: 'client',
                        title: "Vul je bestelgegevens in",
                    },{
                        nr: 2,
                        id: 'review',
                        title: "Controleer je bestelling",
                    },{
                        nr: 3,
                        id: 'payment',
                        title: "Betaling",
                    }],
                    active_step: false
                }

                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
            }

            render() {
                return (
                    <div className="cart-steps">
                        {this.state.steps.map(step => (
                            <CartProcessStep
                                step = {step.nr}
                                id = {step.id}
                                title = {step.title}
                                active_step = {this.storeOrder.order_interface.cart_view} 
                            />
                        ))}
                    </div>
                )
            }
        }
    )
)

export default withRouter(CartProcessSteps);
