import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';

import TextArea from '../../elements/form-input-textarea';
import RowText from '../form-rows/row-text';
import RowTextArea from '../form-rows/row-textarea';
import RowSelectBook from '../form-rows/row-select-book';
import SelectBook from '../../elements/form-input-select-book';
import FormTextIncomplete from '../../elements/form-text-incomplete';

const FormPress = inject("stores") (
    observer (
        class FormPress extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeForms = this.props.stores.storeForms;

                this.state = {
                    formControls: { 
                        firstname: {
                            type: "text",
                            label: i18n.t("forms.label.firstname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.firstname : '',
                            placeholder: i18n.t("forms.placeholder.firstname"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        lastname: {
                            type: "text",
                            label: i18n.t("forms.label.lastname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.lastname : '',
                            placeholder: i18n.t("forms.placeholder.lastname"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },         
                        email: {
                            type: "email",
                            label: i18n.t("forms.label.email"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.email : '',
                            placeholder: i18n.t("forms.placeholder.email"),
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            valid: false
                        },
                        phonenr: {
                            type: "text",
                            label: i18n.t("forms.label.phonenr"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.phonenr : '',
                            placeholder: i18n.t("forms.placeholder.phonenr"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        address: {
                            type: "text",
                            label: i18n.t("forms.label.address"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.address : '',
                            placeholder: i18n.t("forms.placeholder.address"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        housenr: {
                            type: "text",
                            label: i18n.t("forms.label.housenr"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.housenr : '',
                            placeholder: i18n.t("forms.placeholder.housenr"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        zipcode: {
                            type: "text",
                            label: i18n.t("forms.label.zipcode"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.zipcode : '',
                            placeholder: i18n.t("forms.placeholder.zipcode"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        city: {
                            type: "text",
                            label: i18n.t("forms.label.city"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.city : '',
                            placeholder: i18n.t("forms.placeholder.city"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        media: {
                            type: "text",
                            label: i18n.t("forms.label.media"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.media"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        },
                        book_select: {
                            type: "select-book",
                            label: i18n.t("forms.label.select_book"),
                            value: this.props.selected,
                            placeholder: i18n.t("forms.placeholder.select_book"),
                            validationRules: {},
                            valid: false
                        },
                        remark: {
                            type: "textarea",
                            label: i18n.t("forms.label.remark"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.remark"),
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        }
                    },
                    btn_class: '--disabled',
                    error: false,
                    formIsValid: false
                }
            }

            componentWillUnmount = () => {
                this.storeUi.clearFormFeedback();
            }

            componentDidMount = () => {
                this.initForm();
            }

            async componentDidUpdate(prevProps) {
                if (prevProps.selected !== this.props.selected) {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls['book_select']
                    };
                    updatedFormElement.value = this.props.selected;
                    updatedControls['book_select'] = updatedFormElement;
                    this.setState({
                        formControls: updatedControls
                    })
                }
            }

            initForm = () => {
                const updatedControls = this.storeForms.initForm(this.state.formControls);

                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateRow = (name, value, valid) => {        
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);

                this.props.handleSelected(name, value);

                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                });
            }

            sendRequestForm = (e) => {
                e.preventDefault();
                const idx = this.state.formControls.book_select.value - 1;
                if (this.state.formIsValid) {
                    this.storeUi.sendFormPress(this.state.formControls, this.storeUi.settings.sbEmailInfo, this.props.books_sb[idx]);
                }
            }

            render() {

                return (
                    <div className="form-wrapper">
                        <div className="form-wrapper__help">
                            <span className="form-wrapper__help--required">{i18n.t("form_feedback.required")}</span>
                        </div>

                        <div className="form-fieldset">
                                <form className="form form--press">
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        <React.Fragment>
                                            {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email") &&
                                                <RowText 
                                                    type = {this.state.formControls[key].type}
                                                    name = {key}
                                                    label = {this.state.formControls[key].label}
                                                    value = {this.state.formControls[key].value}
                                                    validationRules = {this.state.formControls[key].validationRules}
                                                    onValidate = {this.validateRow}
                                                />
                                            }

                                            {this.state.formControls[key].type === "select-book" &&
                                                <RowSelectBook 
                                                    name = {key}
                                                    label = {this.state.formControls[key].label}
                                                    value = {this.state.formControls[key].value}
                                                    placeholder = {this.state.formControls.book_select.placeholder}
                                                    options = {this.storeBooks.bookList_sb}
                                                    validationRules = {this.state.formControls.book_select.validationRules}
                                                    onValidate = {this.validateRow}
                                                    selected = {this.state.formControls.book_select.value}
                                                    defaultValue = {this.state.formControls.book_select.value}
                                                />
                                            }

                                            {this.state.formControls[key].type === "textarea" &&
                                                <RowTextArea 
                                                    name = {key}
                                                    label = {this.state.formControls[key].label}
                                                    value = {this.state.formControls[key].value}
                                                    placeholder = {i18n.t("forms.placeholder.question2", {count: parseInt(this.storeUi.settings_list["maxLengthProfile"])})}
                                                    maxLength = {this.storeUi.settings_list["maxLengthProfile"]}
                                                    validationRules = {this.state.formControls[key].validationRules}
                                                    onValidate = {this.validateRow}
                                                />
                                            }
                                        </React.Fragment>
                                    ))}

                                    {this.state.btn_class !== '--active' &&
                                        <FormTextIncomplete />
                                    }
                                </form>

                                <div className="form-row form-row--buttons">
                                    <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.sendRequestForm(e)}>{i18n.t("button.submit")}</button>
                                </div>
                                
                                <div className="form__feedback">
                                    {'success' in this.storeUi.form_feedback &&
                                        <span>{i18n.t("form_feedback.success", {type: 'verzoek'})}</span>
                                    }
                                    {'error' in this.storeUi.form_feedback &&
                                        <span>{i18n.t("form_feedback.error", {type: 'verzoek'})}</span>
                                    }
                                </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormPress);
