import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import ImgCover from '../../elements/img-cover';
import BookLang from '../../elements/book-lang';
import BlockBookStock from '../block/block-book-stock';

import * as utilities from '../../utilities/formats';
import * as content from '../../utilities/content';

const SearchBlockTopTitle = inject("stores") (
    observer (
        class SearchBlockTopTitle extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeStock = this.props.stores.storeStock;

                this.minLength = 5;

                this.state = {
                    hasTopItem: false,
                    elevatedHeader: 'Beste match met titel:'
                };
            }

            componentDidMount = async() => {
                let searchValue = this.props.searchValue.toLowerCase();
                // let avail;
                // let title ;
                let topItem = {};
                let hasTopItem = false;
                let elevatedHeader;
                
                let items = [];
                if (this.storeUi.search_results_simple_all.length === 0) {
                    items = this.storeUi.search_results_simple_sb;
                } else {
                    items = this.storeUi.search_results_simple_all;
                }

                items.map((item) => {
                    if (item.title) {
                        if (item.title.toLowerCase() === this.props.topItemTitle) {
                            hasTopItem = true;
                            topItem = item;
                        }
                    }
                })

                if (hasTopItem) {
                    if (topItem["[elevated]"] && topItem.sku) {
                        elevatedHeader = await this.getElevatedHeader(topItem.sku);
                    }

                    this.setState({
                        hasTopItem: true,
                        item: topItem,
                        elevatedHeader: elevatedHeader
                    })
                } else {
                    this.setState({
                        item: null,
                        elevatedHeader: null
                    })
                }
                // } else {
                //     this.setState({
                //         item: null
                //     })
                // }
            }

            componentDidUpdate = async(prevProps) => {
                let searchValue = this.props.searchValue.toLowerCase();               
                // //let minLength = this.storeGeneral.settings_list["minLengthSearchSuggestion"];
                // let avail;
                // let title;
                let topItem = {};
                let hasTopItem = false;
                let elevatedHeader;

                if ((prevProps.searchValue !== this.props.searchValue) || 
                    (prevProps.topItemTitle !== this.props.topItemTitle) ||
                    (prevProps.topItemTitleSku !== this.props.topItemTitleSku) ||
                    (prevProps.searchSegment !== this.props.searchSegment)) 
                {
                    if (this.props.searchValue.length >= this.minLength) {
                        let title = '';
                        let items = [];

                        if (this.storeUi.search_results_simple_all.length === 0) {
                            items = this.storeUi.search_results_simple_sb;
                        } else {
                            items = this.storeUi.search_results_simple_all;
                        }

                        let bestMatch = false;
                        items.map((item) => {
                            title = item.title && item.title.toLowerCase();

                            if (!bestMatch && (item.sku === this.props.topItemTitleSku || title === this.props.topItemTitle)) {
                                bestMatch = true;
                                hasTopItem = true;
                                topItem = item;
                            }
                        })
                    
                        if (hasTopItem) {
                            if (topItem["[elevated]"] && topItem.sku) {
                                elevatedHeader = await this.getElevatedHeader(topItem.sku);
                            }

                            this.setState({
                                hasTopItem: true,
                                item: topItem,
                                elevatedHeader: elevatedHeader
                            })
                        } else {
                            this.setState({
                                item: null,
                                elevatedHeader: null
                            })
                        }
                    } 
                }
            }

            getElevatedHeader = async(sku) => {
                let elevatedHeader = 'aanbevolen';
                let header = await this.storeUi.getElevatedSku(sku);
                
                if (header['elevatedHeader'] !== null) {
                    elevatedHeader = header['elevatedHeader'];
                }
                
                return elevatedHeader;
            }

            handleOnClick = (result) => {
                this.storeUi.setCurrentBook(result.sku);

                //SbC set topnav to SHOP_ALL
                //SbC TODO check SHOP
                this.storeUi.setActiveToggleNav(3);
                this.storeGeneral.setActiveShop('SHOP_ALL');

                if (this.props.shop) {
                    this.storeGeneral.setActiveShop(this.props.shop);
                }
                
                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    //url = "/book/" + result.sku + "/" + id;
                    url = "/book/" + result.sku;
                } else {
                    url = "/book/" + result.sku;
                }
                this.props.history.push({
                    sku: result.sku,
                    pathname: url
                })

                this.storeUi.setSearchOverlay(false);
            }

            constructAuthor(firstname, prefix, lastname) {
                let author = '';

                if (this.state.item.author_firstname) {
                    author = this.state.item.author_firstname;
                    if (this.state.item.author_prefix) {
                        author = author + ' ' + this.state.item.author_prefix;
                    }
                    if (this.state.item.author_lastname) {
                        author = author + ' ' + this.state.item.author_lastname;
                    }
                } else {
                    if (this.state.item.author_lastname) {
                        author = this.state.item.author_lastname;
                    }
                    if (this.state.item.author_prefix) {
                        author = this.state.item.author_prefix + ' ' + author;
                    }
                }

                return author;
            }

            convertString(str) {
                let cStr = '';

                cStr = content.revertHTML(str);
                return cStr;
            }

            render() {
                return (
                    (this.state.hasTopItem && this.state.item)
                    ?   <React.Fragment>
                            <li 
                                className={this.state.elevatedHeader ? "--elevated --link" : "--topitem --link"}
                                onClick = {(e) => this.handleOnClick(this.state.item)}
                                data-content = {this.state.elevatedHeader}
                            >
                                <div 
                                    className="search-item"
                                >
                                    <div 
                                        className="search-item__img"
                                    >
                                        <ImgCover
                                            sku = {this.state.item.sku}
                                        />
                                    </div>
                                    <div 
                                        className="search-item__data"
                                    >
                                        <div>
                                            {this.state.item.title &&
                                                <span
                                                    className="search-item__data--title"
                                                    dangerouslySetInnerHTML={{ __html: utilities.capitalizeTitle(this.state.item.title)}}
                                                />
                                            }
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.constructAuthor(this.state.item.author_firstname, this.state.item.author_prefix, this.state.item.author_lastname)}}
                                            />
                                        </div>
                                        <div 
                                            className="search-item__specs"
                                        >
                                            <ul className="block--specs-summary__list">
                                                <li className="block--specs-summary__listitem">
                                                    <BookLang
                                                        book = {this.state.item}
                                                    />
                                                </li>
                                                <li className="block--specs-summary__listitem">{this.state.item.version}</li>
                                                <li className="block--specs-summary__listitem">ISBN: {this.state.item.sku}</li>
                                            </ul>

                                            <BlockBookStock 
                                                sku = {this.state.item.sku}
                                                book = {this.state.item}
                                                stock_code = {this.state.item.stock_code}
                                            ></BlockBookStock>
                                        </div>
                                    </div>
                                    
                                </div>
                            </li>
                        </React.Fragment>
                    :   null
                )
            }
        }
    )
)

export default withRouter(SearchBlockTopTitle);
