import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';

const PartnerAgreementText = inject("stores") (
    observer (
        class PartnerAgreementText extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount() {
                window.scrollTo(0, 0)
            }
      
            render() {
                return (
                    <React.Fragment>
                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Definities</h2>
                            </div>
                            <div className="content-segment__content">
                                <dl>
                                    <dt>Succesboeken.nl:</dt>
                                    <dd>de besloten vennootschap Belfra Publishers bv gevestigd te Dolderseweg 49, 3734 BB Den Dolder met btw-nummer: NL 8067.90.246.B01, Kamer van Koophandel Utrecht 30147850.</dd>

                                    <dt>Succesboeken website:</dt>
                                    <dd>https://www.succesboeken.nl</dd>

                                    <dt>Partner:</dt>
                                    <dd>ieder bedrijf of ieder natuurlijke persoon vanaf de leeftijd van achttien (18) jaar die accoord gaat met de Succesboeken overeenkomst partner-program en door Succesboeken.nl als partner is geaccepteerd om bezoekers van zijn/haar site door middel van een link toegang te verschaffen tot de website www.succesboeken.nl.</dd>

                                    <dt>Producten:</dt>
                                    <dd>alle producten die worden aangeboden op de website van Succesboeken.nl.</dd>

                                    <dt>Link:</dt>
                                    <dd>een link ten behoeve van het partner-program van de partner naar de website van Succesboeken.nl.</dd>

                                    <dt>Bezoeker:</dt>
                                    <dd>iedere bezoeker van Internet die zich via de site van de Succesboeken.nl partner door middel van de link toegang verschaft tot de website van Succesboeken.nl en zodoende één of meerdere producten koopt van Succesboeken.nl.</dd>
                                </dl> 
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Aanmelding en acceptatie</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Aanmelding als Succesboeken.nl partner betekent dat u de overeenkomst partner-program aanvaardt en deze zult respecteren.</p>
                                <p>Om als Succesboeken.nl partner te worden ingeschreven dient u:</p>
                                <ol>
                                    <li>het aanmeldingsformulier volledig en naar waarheid in te vullen;</li>
                                    <li>te bevestigen dat u de overeenkomst partner-program heeft aanvaard door de button aan te klikken;</li>
                                    <li>als Succesboeken.nl partner te zijn geaccepteerd door Succesboeken.nl.</li>
                                </ol>
                                <p>De acceptatie als Succesboeken.nl partner zal binnen 10(tien) dagen na ontvangst van het aanvraagformulier worden bevestigd of afgewezen.</p>
                                <p>Succesboeken.nl kan een aanmelding om bepaalde redenen weigeren, zonder opgave van redenen.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Succesboeken.nl partner in de praktijk</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Succesboeken.nl partner zal er geen misverstand over laten bestaan dat de artikelen niet door de partner, maar door Succesboeken.nl worden verkocht. Succesboeken.nl partner is gerechtigd om de naam Succesboeken.nl en het logo van Succesboeken.nl zoals aangegeven, uitsluitend ten behoeve van het Succesboeken.nl partner-program op de door Succesboeken.nl van tijd tot tijd aan te geven wijze te gebruiken en daarbij alle aanwijzingen van Succesboeken.nl steeds op te volgen. Voorwaarde is bovendien dat geen wijzigingen worden aangebracht en het gebruik niet misleidend dan wel schadelijk voor Succesboeken.nl zal kunnen zijn. Succesboeken.nl partner zal te allen tijde de auteursrechten, merkenrechten en overige rechten van exclusieve aard van Succesboeken.nl en derden respecteren. Succesboeken.nl partner zal de content slechts gebruiken ten behoeve van het Succesboeken.nl partner-program en hierin geen wijzigingen aanbrengen. Succesboeken.nl partner draagt er te allen tijde zorg voor dat de prijzen van de producten die (eventueel) op zijn/haar site zijn vermeld in overeenstemming zijn met de prijzen zoals vermeld op de Succesboeken.nl website. Het is niet toegestaan dat de inhoud van de site van de Succesboeken.nl partner:</p>
                                <ul className="list--bullets">
                                    <li>een erotisch of pornografisch of gewelddadig karakter heeft;</li>
                                    <li>discrimineert naar ras, geslacht, godsdienst of levensovertuiging;</li>
                                    <li>illegale activiteiten ontplooit, bevordert of aanprijst;</li>
                                    <li>afbreuk doet aan de goede naam en reputatie van Succesboeken.nl;</li>
                                    <li>auteursrechten, merkrechten of andere rechten van Succesboeken.nl of enige derde worden geschonden.</li>
                                </ul>
                                <p>Succesboeken.nl partner zal te allen tijde in overeenstemming handelen met alle richtlijnen en instructies van Succesboeken.nl terzake van de installatie en het gebruik van de link alsmede terzake van de content. Indien de Succesboeken.nl website hinder ondervindt van de installatie en het gebruik van de link met de partner, is Succesboeken.nl gerechtigd om de link te blokkeren.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Commissie</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Succesboeken.nl partner kan aanspraak maken op commissie van <strong>20%</strong> van alle eigen uitgaven en eigen producten, en <strong>10%</strong> van uitgaven van derden die door Succesboeken.nl aan een bezoeker worden verkocht tijdens een winkelsessie op de Succesboeken.nl website waartoe de bezoeker zich toegang heeft verschaft door middel van de link en de bezoeker aan de betalingsverplichtingen terzake van deze artikelen jegens Succesboeken.nl heeft voldaan. Indien de bezoeker de producten retourneert en/of betaling daarvan ongedaan wordt gemaakt kan de Succesboeken.nl partner geen aanspraak (meer) maken op de betreffende commissie en dient de Succesboeken.nl partner reeds ontvangen commissie terzake aan Succesboeken.nl terug te betalen. Succesboeken.nl is te allen tijde gerechtigd om de betreffende bedragen te verrekenen met hetgeen zij op enig moment aan de Succesboeken.nl partner verschuldigd is of mocht worden. De commissie wordt berekend over de netto verkoopwaarde per product per kalenderkwartaal. Het eerste kwartaal kan langer zijn dan een kwartaal. Onder netto verkoopwaarde wordt verstaan, de prijs van de verkochte en betaalde producten, exclusief btw en verzendkosten. De commissiebedragen zijn exclusief btw. Indien de natuurlijke persoon c.q. rechtspersoon btw-plichtig is, zal over de netto commissie 21,0% btw worden berekend. Deze btw zal naast de netto commissie op de facturen worden vermeld. De registratie van de verkopen via de database van Succesboeken.nl is bindend voor Succesboeken.nl en de partner.</p>
                                <p><strong>Rekenvoorbeeld commissie:</strong><br/>
                                Verkoopprijs boek eigen uitgave € 19,95<br/>
                                Btw eruit 19,95 : 1.09 = € 18,30<br/>
                                Uw commissie <strong>20%</strong> van € 18,30 = € 3,66<br/>
                                <br/>
                                <strong>Rekenvoorbeeld commissie:</strong><br/>
                                Verkoopprijs boek uitgave derden € 19,95<br/>
                                Btw eruit 19,95 : 1.09 = € 18,30<br/>
                                Uw commissie <strong>10%</strong> van € 18,30 = € 1,83
                                </p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Commissie voor privépartners</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Privépartners zijn mensen die geen eigen website hebben, maar wel aan derden de boeken/producten promoten van Succesboeken.nl door middel van mailing (geen spam) en/of mondelinge aanbeveling.<br/>Alle hierboven vermelde condities gelden tevens voor privépartners, met dien verstande dat de commissies 5% resp. 10% bedragen.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Betaling</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Betaling van de commissie door Succesboeken.nl aan de Succesboeken.nl partner zal per kwartaal plaatsvinden op het rekeningnummer dat de partner heeft aangegeven op het aanmeldingsformulier. Succesboeken.nl is te allen tijde gerechtigd om hetgeen zij aan de partner verschuldigd is te verrekenen met enig bedrag dat Succesboeken.nl uit welken hoofde dan ook te vorderen heeft van de partner. Het eerste kwartaal kan langer zijn dan een kwartaal. Commissies onder € 5,00 per kwartaal worden om (bank)kostentechnische redenen niet uitgekeerd. Commissies boven € 5,00 maar onder € 10,00 per kwartaal worden maximaal 4 kwartalen om (bank)kostentechnische redenen opgespaard.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Beëindiging Succesboeken.nl partnership</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Succesboeken.nl is te allen tijde gerechtigd om - zonder opgave van redenen - het partnership te beëindigen, met inachtneming van een opzegtermijn van twee (2) weken. De partner zal hier per e-mail van op de hoogte worden gebracht. Onverminderd haar overige rechten is Succesboeken.nl onder meer gerechtigd om het partnership met onmiddellijke ingang te beëindigen in geval:</p>
                                <ul className="list--bullets">
                                    <li>de partner tekort komt in de nakoming van zijn/haar verplichtingen dan wel in strijd met deze overeenkomst handelt;</li>
                                    <li>de site van de partner naar oordeel van Succesboeken.nl in strijd zou kunnen zijn met de openbare orde of goede zeden dan wel op een of andere wijze aanstootgevend of gewelddadig zou kunnen zijn;</li>
                                    <li>Succesboeken.nl hiervan op een of andere wijze schade van zou kunnen ondervinden;</li>
                                    <li>de partner in het kader van het partner-program onrechtmatig handelt jegens Succesboeken.nl en/of derden, zoals auteurs, uitgevers, producenten, uitvoerend kunstenaars.</li>
                                </ul>
                                <p>Het partnership eindigt automatisch in geval:</p>
                                <ul className="list--bullets">
                                    <li>de partner de link heeft verbroken;</li>
                                    <li>het partner-program is stopgezet door Succesboeken.nl of door de partner;</li>
                                    <li>de partner of Succesboeken.nl in surceance van betaling is;</li>
                                    <li>de partner of Succesboeken.nl failliet is.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Gevolgen beëindiging Succesboeken.nl partnership</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Indien het partnerschap, om welke reden dan ook, is beëindigd:</p>
                                <ol>
                                    <li>worden de link(s) verbroken;</li>
                                    <li>kan de partner geen aanspraak (meer) maken op commissie;</li>
                                    <li>zal de partner onmiddellijk de content in zake Succesboeken.nl van zijn/haar site verwijderen;</li>
                                    <li>is de partner niet langer gerechtigd om de naam Succesboeken.nl en het logo van Succesboeken.nl en/of boekencovers te gebruiken.</li>
                                </ol>
                                <p>Indien het partnership is beëindigd zal de partner aanspraak kunnen maken op betaling van commissie terzake van verkopen die zijn verricht vóór de beëindigingsdatum, conform het voorgaande beschrevene. Indien Succesboeken.nl en de partner deze overeenkomst hebben beëindigd kunnen ze geen van beide meer aanspraak maken op enige vergoeding door de andere partij in verband met de beëindiging van het partnership en doen zij hierbij afstand van enig recht op enige (schade)vergoeding.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Aansprakelijkheid</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Succesboeken.nl partner is volledig verantwoordelijk en aansprakelijk voor de ontwikkeling en instandhouding, het functioneren en de inhoud van zijn/haar site. De partner vrijwaart Succesboeken.nl voor alle aanspraken dienaangaande. Tenzij er sprake is van opzet en/of grove schuld van Succesboeken.nl, is Succesboeken.nl niet aansprakelijk voor schade en/of kosten van de partner in verband met deelname aan het partner-program, zoals - maar niet beperkt tot - schade en kosten ten gevolge van het niet behoorlijk functioneren van de Succesboeken.nl website, technische storingen, etc. De partner staat er voor in dat wordt voldaan aan alle verplichtingen op grond van de fiscale wetgeving en vrijwaart Succesboeken.nl terzake van alle aanspraken dienaangaande.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Diversen</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>De partner zal zich nimmer voordoen als een agent of vertegenwoordiger van Succesboeken.nl en zal in het bijzonder geen toezeggingen doen of verplichtingen aangaan voor of namens Succesboeken.nl. De partner is niet gerechtigd om (een deel van) zijn/haar partnership over te dragen aan een derde. Succesboeken.nl is te allen tijde gerechtigd om deze partnerovereenkomst te veranderen. De partner zal hiervan per email op de hoogte worden gebracht. Ingeval de gewijzigde partnerovereenkomst voor de partner niet acceptabel is, is de partner gerechtigd het partnership te beëindigen. Indien de partner niet tot beëindiging van het partnership overgaat, wordt de Succesboeken.nl partner geacht de gewijzigde partnerovereenkomst te hebben aanvaard. Indien enige bepaling van deze overeenkomst in strijd mocht zijn met toepasselijk recht, zal deze bepaling zodanig worden gewijzigd dat zij in overeenstemming met het toepasselijk recht zal zijn met inachtneming van de strekking van de desbetreffende bepaling.</p>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__header">
                                <h2>Toepasselijk recht en bevoegde rechter</h2>
                            </div>
                            <div className="content-segment__content">
                                <p>Deze partnerovereenkomst en het partnership worden uitsluitend beheerst door Nederlands recht en geschillen zullen worden voorgelegd aan de Nederlandse rechter.</p>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default PartnerAgreementText;
