import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import * as utilities from '../utilities/formats';
import TextInput from '../elements/form-input-text';

import CartEmpty from '../components/cart/cart-empty';
import CartProduct from '../components/cart/cart-product';
import FeedbackOrderDelivery from '../components/feedback/feedback-order-delivery';

const RemarkShippingCosts = inject("stores") (
    observer (
        class RemarkShippingCosts extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidUpdate = (prevProps) => {

            }

            discountApplied = () => {
                let discount_applied = false;

                let is_reseller = this.storeOrder.order_data.client_settings.is_reseller;
                let discount_minamount = (is_reseller) ? this.storeOrder.settings_list["resellerMinAmount"] : 2;

                {this.storeUser.user_data.is_loggedin &&
                    this.storeOrder.cart.user.map((product) => {
                        if (product.amount > 0) {
                            discount_applied = (product.discount > 0) ? true : discount_applied; 
                            discount_applied = (this.storeOrder.order_settings.discounts_overruled === 1 && product.discount > 0) ? true : discount_applied;
                        }
                    })
                }
                return discount_applied;
            }
        
            render() {
                const discount_applied = this.discountApplied()
                
                return (
                    <span>
                        {this.storeAuth.user.is_loggedin
                        ?   this.storeAuth.user.is_reseller
                            ?   discount_applied
                                ?   'Aangezien er op één of meerdere producten korting is toegepast, worden er altijd verzendkosten berekend, tenzij het totaalbedrag groter is dan € 250,-'
                                :   'Voor orders vanaf € 20,- incl. btw binnen Nederland zijn de verzendkosten voor onze rekening'
                            :   discount_applied
                                ?   'Aangezien er op één of meerdere producten korting is toegepast, worden er altijd verzendkosten berekend, tenzij het totaalbedrag groter is dan € 250,-'
                                :   'Voor orders vanaf € 20,- incl. btw binnen Nederland zijn de verzendkosten voor onze rekening'
                        :   'Voor orders vanaf € 20,- incl. btw binnen Nederland zijn de verzendkosten voor onze rekening'
                        }

                        {(this.storeOrder.order_data.shopping_cart.cart_total_incl > 19.5 && this.storeOrder.order_data.shopping_cart.cart_total_incl < 20)
                        ?   '; helaas is een computer hier niet flexibel in.'
                        :   '.'
                        }
                    </span>
                )
            }
        }
    )
)

const Cart = inject("stores") (
    observer (
        class Cart extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeShipping = this.props.stores.storeShipping;

                this.state = {
                    formControls: { 
                        remark: {
                            label: i18n.t("forms.label.remark_order"),
                            value: this.storeOrder.order_data.order_remark,
                            placeholder: i18n.t("forms.placeholder.remark_order"),
                            touched: false,
                            valid: true                        
                        }
                    }
                }
            }

            async componentDidMount() {
                window.scrollTo(0, 0);
                this.storeOrder.deleteUseCaseFromLS();
                const userId = this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.user_id : 0;

                if (userId === 0) {
                    this.storeOrder.resetOrderData(true);
                }

                // const payment_status = await this.storeOrder.getOrderStatus(this.props.storeOrder.order_data.order_guid);
                // console.log('SbC check order status', payment_status);

                if (this.storeOrder.settings_list === undefined || this.storeOrder.settings_list.length == 0) {
                    await this.storeOrder.getSettings();
                    //(async() => await this.storeOrder.getSettings() )();
                }

                if (this.storeOrder.order_data.order_id !== undefined && this.storeOrder.order_data.order_id !== 0) {
                    await this.checkDiscountsOverruled();
                    await this.checkShippingCostsOverruled();
                    await this.checkShippingCostsCustom();
                }

                //await this.storeOrder.calculateShipping(userId);
                // SbC TODO what if no order id yet ??
                let orderId = this.storeOrder.order_data.order_id;
                (async() => await this.storeShipping.calculateShipping(orderId) )();

                let view = 'cart';
                this.storeOrder.setCartView(userId, view);
                let url = "/shoppingcart/" + view;
                this.props.history.push(url);
            }

            componentDidUpdate = async() => {
                let payment_status;
                let payment2_status = 'current';

                const userId = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                if (this.storeOrder.order_data.order_guid !== '' && this.storeOrder.order_data.order_guid !== null) {
                    payment_status = await this.storeOrder.getOrderStatus(this.storeOrder.order_data.order_guid);
                }

                /* SbC check for payment from other device */
                let order_id = this.storeOrder.order_data.order_id;
                if  (order_id !== 0 && order_id !== '0' && order_id !== 99 && order_id !== '99') {
                    payment2_status = await this.storeOrder.getPaymentStatus(order_id);
                    
                    if (payment2_status === 'payment_completed' || payment2_status === 'payment_confirmed' || payment2_status === 'banktransfer') {
                        this.storeOrder.clearCartAfterPayment();
                    }
                }
                /* */

                if (payment_status === 'payment_confirmed' || payment_status === 'payment_completed' || payment_status === 'banktransfer') {
                    this.storeOrder.clearAllShoppingCarts(this.storeUser.user_data.is_loggedin, this.storeOrder.order_data.order_guid);
                    let view = 'cart';
                    this.storeOrder.setCartView(userId, view);
                    let url = "/shoppingcart/" + view;
                    this.props.history.push(url);
                }

                //await this.storeOrder.calculateShipping(userId);
                await this.checkDiscountsOverruled();
                await this.checkShippingCostsOverruled();
            }

            componentWillUnmount = () => {
                this.storeOrder.setCartChanges(false);
            }
            
            continueShopping = () => {
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                this.storeOrder.setCartView(id, "client");
                this.storeUi.setUseCase("inshop");
                this.props.history.push("/");
            }

            checkDiscountsOverruled = async() => {
                let discount_overruled = false;
                discount_overruled = await this.storeOrder.checkDiscountsOverruled();
            }

            checkShippingCostsOverruled = async() => {
                let shipping_overruled = false;
                shipping_overruled = await this.storeOrder.checkShippingCostsOverruled();
            }

            checkShippingCostsCustom = async() => {
                let shipping_custom = false;
                shipping_custom = await this.storeOrder.checkShippingCostsCustom();
            }

            checkFeedbackDelivery = () => {
                let count = 0;
                let feedback = false;
                
                if (this.storeUser.user_data.is_loggedin) {
                    this.storeOrder.cart.user.map((product, i) => (
                        (product.status !== 'deletedx' && product.amount !== 0) && count++
                    )
                )}

                if (!this.storeUser.user_data.is_loggedin) {
                    this.storeOrder.cart.anonymous.map((product, i) => (
                        (product.status !== 'deletedx' && product.amount !== 0) && count++
                    )
                )}
                
                return count > 1 ? true : false;
            }

            gotoCart = async() => {
                let id;
                let data = [];

                this.storeUi.setUseCase("inorder");

                if (this.storeUser.user_data.is_loggedin) {
                    id = this.storeUser.user_data.user_id;
                    data = [{
                        'is_partner' : this.storeAuth.user.is_partner,
                        'is_reseller': this.storeAuth.user.is_reseller
                    }]
                } else {
                    id = 0;
                    data = [{
                        'is_partner' : 0,
                        'is_reseller': 0
                    }]
                }

                this.storeOrder.setClientId(id, data);

                let order_id = this.storeOrder.order_data.order_id; 

                //Sbc check!!
                const orderId = await this.storeOrder.returnOrderId(id);
                if (orderId !== 0 && orderId !== '0' && orderId !== 99 && orderId !== '99') {
                    order_id = orderId;
                } 

                if (orderId !== 0 && orderId !== '0' && orderId !== 99 && orderId !== '99') {
                    let order_availability = await this.storeOrder.getOrderAvailability();

                    if (order_availability === 'open') {
                        await this.storeOrder.editOrder(id, this.state.formControls.remark.value);

                        let hasShippingLine = await this.storeOrder.checkOrderLineShipping(order_id);
                        if (!hasShippingLine) {
                            await this.storeOrder.addOrderLineShipping();
                        }
                    } 
                    if (order_availability === 'closed') {
                        order_id = await this.storeOrder.createNewOrder(id, this.state.formControls.remark.value);
                    }
                } else {
                    if (this.storeUser.user_data.is_loggedin) {
                        order_id = await this.storeOrder.createNewOrder(id, this.state.formControls.remark.value);
                    }
                }
                this.storeOrder.calculateTotal();
                this.storeOrder.setOrderRemarkLS(this.state.formControls.remark.value);

                // if (this.storeUi.app_interface.return_state == "review") {
                //     this.storeOrder.setCartView("review");
                //     this.props.history.push("/shoppingcart/review");
                // } else {
                    this.storeOrder.setCartView(id, "client");
                    this.props.history.push("/shoppingcart/client");
                //}
            }

            handleChange = (event) => {     
                const name = event.target.name;
                const value = event.target.value;
        
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
          
                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls,
                });
            }

            render() {
                let country;
                let country_code;
                let idx;

                if (this.storeOrder.order_data.client_settings.has_delivery_address === true) {
                    idx = this.storeOrder.countriesList.findIndex(country => country.code === this.storeOrder.order_data.delivery_address.country_code)
                    country_code = this.storeOrder.order_data.delivery_address.country_code;
                } else {
                    if (this.storeAuth.user.is_loggedin) {
                        idx = this.storeOrder.countriesList.findIndex(country => country.code === this.storeUser.user_data.profile.country_code)
                        country_code = this.storeOrder.order_data.client_profile.country_code;
                    } else {
                        idx = this.storeOrder.countriesList.findIndex(country => country.code === this.storeOrder.order_data.client_profile.country_code)
                        country_code = this.storeOrder.order_data.client_profile.country_code;
                    }
                }

                if (idx !== -1) {
                    country = this.storeOrder.countriesList[idx].displayValue;
                }

                let items = this.storeUser.user_data.is_loggedin 
                ?   this.storeOrder.cart.user 
                :   this.storeOrder.cart.anonymous

                idx = items
                    .findIndex(item => item.status !== 'deletedx' && item.amount !== 0)

                return (
                    <React.Fragment>
                        {(items.length > 0 && idx !== -1)
                        ?   <div className="cart">
                                <ul className="products-table">
                                    <li className="products-table__header">
                                        <div className="products-table__cell products-table__cell--sku">ISBN</div>
                                        <div className="products-table__cell products-table__cell--title">Titel</div>
                                        <div className="products-table__cell products-table__cell--amount">Prijs</div>
                                        <div className="products-table__cell products-table__cell--count">Aantal</div>
                                        <div className="products-table__cell products-table__cell--amount">Totaal</div>
                                    </li>
                                    {this.storeUser.user_data.is_loggedin && this.storeOrder.cart.user.map((product, i) => (
                                        (product.status !== 'deletedx' && product.amount !== 0 && product.sku !== 0 && product.sku !== undefined)
                                        ?   <CartProduct
                                                key = {i}
                                                product = {product}
                                                amount =  {product.amount}
                                                discount_perc =  {product.discount}
                                                sku = {product.sku}
                                                editable = {true}
                                                discount_overruled = {this.storeOrder.order_settings.discounts_overruled}
                                                reload = {this.storeUi.app_interface.reload}
                                            />
                                        :   (process.env.REACT_APP_ENV === 'dev')  
                                            ?   <div><span>{product.description}</span><br/></div> 
                                            :   null
                                    ))}

                                    {!this.storeUser.user_data.is_loggedin && this.storeOrder.cart.anonymous.map((product, i) => ( 
                                        (product.status !== 'deletedx' && product.amount !== 0)
                                        ?   <CartProduct
                                                key = {i}
                                                product = {product}
                                                amount =  {product.amount}
                                                discount_perc = {product.discount}
                                                sku = {product.sku}
                                                editable = {true}
                                                discount_overruled = {0}
                                                reload = {this.storeUi.app_interface.reload}
                                            />
                                        :   (process.env.REACT_APP_ENV === 'dev') 
                                            ?   <div><span>{product.description}</span><br/></div>
                                            :   null
                                    ))}
                                </ul>
                                <ul className="totals-table">
                                    <li className="totals-table__row">
                                        <div className="products-table__cell">Totaal</div>
                                    <div className="products-table__cell products-table__cell--amount">
                                        {/* <span className="amount">{utilities.displayPrice(this.storeOrder.order_data.shopping_cart.cart_total_incl)}</span> */}
                                        <span className="amount">{utilities.displayPrice(this.storeOrder.cartTotalIncl)}</span>
                                    </div>
                                    </li>
                                    <li className="totals-table__row">
                                        <div className="products-table__cell">Verzendkosten *</div>
                                        <div className="products-table__cell products-table__cell--amount">
                                            {/* <span className="amount">{utilities.displayPrice(this.storeOrder.order_data.shipping_costs)}</span> */}
                                            <span className="amount">{utilities.displayPrice(this.storeOrder.shippingCosts)}</span>
                                        </div>
                                    </li>
                                    {process.env.REACT_APP_ENV === 'dev' &&
                                        <li className="totals-table__row">
                                            <div className="products-table__cell products-table__cell--descr">(Gewicht: {this.storeOrder.calculateWeight} gr)</div>
                                        </li>
                                    }
                                    <li className="totals-table__row totals-table__row--totals">
                                        <div className="products-table__cell">
                                            <span className="products-table__cell--totals">Totale kosten (incl. btw)</span>
                                        </div>
                                        <div className="products-table__cell products-table__cell--amount">
                                            <span className="products-table__cell--totals">
                                                <span className="amount">{utilities.displayPrice(this.storeOrder.calculateTotalOrder)}</span>
                                            </span>
                                        </div>
                                    </li>
                                    {/* <li className="totals-table__row">
                                        <div className="products-table__cell --small">(btw {this.storeUi.settings_list["btwNull"]}%</div>
                                        <div className="products-table__cell products-table__cell--amount --small"><span className="amount">{this.storeOrder.calculateVATOrder('btwNull', this.storeUi.settings_list)})</span></div>
                                    </li> */}
                                    <li className="totals-table__row">
                                        <div className="products-table__cell --small">Btw {this.storeUi.settings_list["btwLow"]}%</div>
                                        <div className="products-table__cell products-table__cell--amount --small"><span className="amount">{utilities.displayPrice(this.storeOrder.calculateOrderVAT(this.storeUser.user_data.is_loggedin, 'btwLow'))}</span></div>
                                    </li>
                                    <li className="totals-table__row">
                                        <div className="products-table__cell --small">Btw {this.storeUi.settings_list["btwHigh"]}%</div>
                                        <div className="products-table__cell products-table__cell--amount --small"><span className="amount">{utilities.displayPrice(this.storeOrder.calculateOrderVAT(this.storeUser.user_data.is_loggedin, 'btwHigh'))}</span></div>
                                    </li>
                                </ul>
                                
                                {country_code === 'NL'
                                    ?   <div className="cart__text--descr-shipping">
                                            {this.storeOrder.order_settings.no_shipping_costs === 1
                                                ?   <React.Fragment>
                                                        <span>Er zullen binnen Nederland geen verzendkosten berekend worden.</span>
                                                        <br/>
                                                        <span>Voor verzending naar andere landen geldt een ander tarief.</span>
                                                    </React.Fragment>
                                                :   <React.Fragment>
                                                        <span dangerouslySetInnerHTML={{ __html: i18n.t('cart.shipping_nl', {country: country})}} />
                                                        <br/>
                                                        <span>Voor verzending naar andere landen geldt een ander tarief.</span>
                                                        <br/>
                                                        <RemarkShippingCosts />
                                                    </React.Fragment>
                                            }
                                        </div>
                                    :   <div className="cart__text--descr-shipping" dangerouslySetInnerHTML={{ __html: i18n.t('cart.shipping_abroad', {country: country})}}></div>
                                }

                                <div className="cart__remark">
                                    {Object.keys(this.state.formControls).map((key, i) => (
                                        <div className="form-row form-row--input" key={i}>
                                            <label className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}>{this.state.formControls[key].label}</label>
                                            <TextInput 
                                                name = {key} 
                                                placeholder = {this.state.formControls[key].placeholder}
                                                value = {this.state.formControls[key].value}
                                                touched = {this.state.formControls[key].touched}
                                                msg = {''}
                                                onChange = {this.handleChange}
                                                valid = {this.state.formControls[key].valid}
                                            />
                                        </div>
                                    ))}
                                </div>
                                
                                {this.checkFeedbackDelivery() &&
                                    <FeedbackOrderDelivery

                                    />
                                }
                                
                                <div className="cart__actions">
                                    <button 
                                        className="button button--secundary" 
                                        onClick={(e) => this.continueShopping(e)}
                                    >
                                        {i18n.t("button.continue_shopping")}
                                    </button>
                                    <button 
                                        className="button button--primary" 
                                        onClick={(e) => this.gotoCart(e)}
                                    >
                                        {i18n.t("button.order")}
                                    </button>
                                </div>
                                
                            </div>
                        :   <CartEmpty
                                section = "cart"
                                orderId = {this.storeOrder.order_data.order_id}
                            />
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Cart));
