import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const AudioPlayer = inject("stores") (
    observer (
        class AudioPlayer extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
            }

            componentDidMount() {
                this.audioRef.pause(); 
            }

            componentDidUpdate(prevProps) {
                if (prevProps.playing !== this.props.playing) {
                    if (this.props.playing) {
                        this.audioRef.currentTime = 0;
                        this.audioRef.play(); 
                    } else {
                        this.audioRef.pause(); 
                    }
                } else {
                    //SbC causes the player to autoplay
                    //this.audioRef.play();
                }
            }

            setAudio(audiosrc) {         
                return  <audio 
                    controls="controls"
                    src={audiosrc}
                    ref={(audioRef) => {
                        this.audioRef = audioRef;
                    }}
                />
            }

            render() {
                const filename = this.props.filename
                const location = (this.props.duration === "preview") ? this.urlAPI  + "/public/preview-audio/" : this.urlAPI  + "/public/audiobooks/"
                const audiosrc = location + filename

                return (
                    <div className="audioplayer-wrapper">
                        <div className="audioplayer">
                            {this.setAudio(audiosrc)}
                        </div>
                    </div>
                )
            }
        }
    )
)

export default AudioPlayer;

