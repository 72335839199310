import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const FooterPayments = inject("stores") (
    observer (
        class FooterPayments extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidUpdate = (prevProps) => {

            }

            render() {

                return (
                    <div className="footer__payments">
                        <ul className="list list--payments">
                            {this.storeUi.payment_providers.length > 0 && this.storeUi.payment_providers.map((method,i) => ( 
                                method.description !== 'Overboeking' && method.description !== 'EPS' &&
                                <li key={i} className={"list--payments__logo list--payments__logo--" + method.description}>
                                    <img src={this.urlAPI +"/public/images/payment/logo-" + method.description + ".png"} alt={"logo " + method.description} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default FooterPayments;