import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';

import BlockVideo from '../../components/block/block-video';

import BlockSocialSharing from '../../components/block/block-social-sharing';
import BlockVideoFilter from '../../components/block/block-video-filter';

const VideosOverview = inject("stores") (
    observer (
        class VideosOverview extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    social_tooltip: i18n.t("share.page_videos"),
                    video_focus: 999
                }
            }

            componentDidMount = async() => {
                window.scrollTo(0, 0);
                this.storeUi.getVideos();

                this.storeUi.setActiveMainNav('');
                this.storeUi.setActiveVideo(-1);

                if (this.props.match.params.video === undefined) {
                    this.setState({
                        video_focus: 999
                    })
                } else {
                    const reference = this.props.match.params.video;
                    const video = await this.storeUi.getVideoByReference(reference);

                    if (video && video.length > 0) {
                        this.setState({
                            video_focus: reference,
                            video: video[0]
                        })
                    }
                }
            }

            onMouseEnterSocial = (social) => {
                this.setState({
                    social_tooltip: (social !== '' && social !== undefined) ? social : i18n.t("share.page_videos")
                })
            }

            render() {
                return (
                    <Template
                        type = 'default'
                        header = {this.state.video_focus === 999 ? "videos-overview" : "video"}
                        nav_name = {"videos"}
                    >
                        <MetaTags>
                            <title dangerouslySetInnerHTML={{ __html: "Video's"}} />
                            <meta 
                                name="title" 
                                content={"Video's"} 
                            />
                            <meta 
                                name="description" 
                                content={"Video's"} 
                            />
                            <meta 
                                property="og:title" 
                                content={"Video's"}  
                            />
                            <meta 
                                property="og:description" 
                                content={"Video's"} 
                            />
                            <meta 
                                property="og:image" 
                                content="https://www.succesboeken.nl/api/public/images/branding/sb_logo-img.png" 
                            />
                            <link 
                                rel="canonical" 
                                href={this.urlAPP + "/videos"} 
                            />
                        </MetaTags>

                        {this.state.video_focus !== 999 &&
                            <React.Fragment>
                            <div className="content-segment">
                                <div className="block--focus-video">
                                    <BlockVideo
                                        video = {this.state.video}
                                        isDialogVideoOpen = {this.storeUi.isDialogVideoOpen}
                                        active_video = {this.storeUi.active_video}
                                    />
                                </div>
                            </div>

                            <div className="content-segment">
                                <div className="content-segment__content">
                                    <p><span className="book-tools__tool-title">{i18n.t("share.page_video")}</span></p>
                                    <BlockSocialSharing 
                                        type = 'video'
                                        sku = {"0"} 
                                        page = {"videos"}
                                        reference = {this.state.video_focus}
                                        onMouseEnter = {this.onMouseEnterSocial}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                        }

                        {this.state.video_focus === 999 &&
                            <div className="content-segment">
                                <ContentText
                                    page = {"videos"}
                                />
                            </div>
                        }

                        <div className="content-segment">
                            <div className="content-segment__header">
                                {this.state.video_focus !== 999 &&
                                    <h1>Alle video's</h1>
                                }
                            </div>
                        </div>

                        <div className="content-segment">
                            <ContentText
                                page = {"videos-overview"}
                            />
                        </div>
                        
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p>Bekijk ook ons YouTube kanaal:<br/>
                                    <a href="https://www.youtube.com/user/Succesboeken/videos" className="link--read-more" target="_blank">Succesboeken.nl op YouTube</a>
                                </p>
                            </div>
                        </div>

                        {this.state.video_focus === 999 &&
                            <div className="content-segment">
                                <div className="content-segment__content">
                                    <p><span className="book-tools__tool-title">{i18n.t("share.page_videos")}</span></p>
                                    <BlockSocialSharing 
                                        type = 'videos'
                                        sku = {"0"} 
                                        page = {"videos"}
                                        onMouseEnter = {this.onMouseEnterSocial}
                                    />
                                </div>
                            </div>
                        }

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <BlockVideoFilter
                                
                                />
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="blocks__list--video">
                                {this.storeUi.videos && this.storeUi.videos.map((video, idx) => (                              
                                    <BlockVideo
                                        id = {video.id} 
                                        video = {video}
                                        //isDialogVideoOpen = {this.storeUi.isDialogVideoOpen}
                                        idx = {idx}
                                        //active_video = {this.storeUi.active_video}
                                    />
                                ))}
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(VideosOverview);
