import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as ui from '../../utilities/ui';

import Template from '../../templates/template';
import ContentText from '../../components/content/content-text';
import FormPress from '../../components/forms/form-press';

const Press = inject("stores") (
    observer (
        class Press extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    foldout: false,
                    book_selected: 0
                }
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.formRef = React.createRef();
            }

            componentDidMount = () => {
                ui.handleScroll('top');
                this.storeBooks.getSbPublications('current');
            }

            expandList = (e) => {
                this.setState(prevState => ({
                    foldout: !prevState.foldout
                }));
            }

            selectBook = (e, idx) => {
                this.setState({
                    book_selected: idx + 1
                })

                ui.handleScroll(this.formRef.offsetTop - 40);
            }

            handleSelected = (name, value) => {
                if (name == 'book_select' && value !== this.state.book_selected) {
                    this.setState({
                        book_selected: parseInt(value)
                    })
                }
            }
     
            render() {
                return (
                    <Template
                        type = 'default'
                        header = "media"
                        noScroll = {true}
                    >
                        <div className="content-segment">
                            <ContentText
                                page = {"media"}
                            />
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">
                                <ul className={"content-segment__list content-segment__list--textlines " + (this.state.foldout ? "--expanded" : "--collapsed")}>
                                    {this.storeBooks.bookList_sb.map((book, i) => (
                                        <li>
                                            <div className="content-segment__listitem-wrapper">
                                                <span className="content-segment__listitem-count">{i + 1}</span>
                                                <span className={"--link" + ((this.state.book_selected === i + 1) ? ' --selected' : '')} onClick={(e) => this.selectBook(e, i)}>
                                                    <span dangerouslySetInnerHTML={{ __html: book.title}} /> {book.author_lastname && '('}{book.author_firstname} {book.author_lastname}{book.author_lastname && ')'}</span>
                                            </div>
                                        </li>
                                    ))}
                                    <li className="actionShowMore" onClick={(e) => this.expandList(e)}>
                                        {this.state.foldout 
                                            ?   i18n.t("lists.show_less_books_sb")
                                            :   i18n.t("lists.show_more_books_sb")
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="content-segment" ref={ (ref) => this.formRef=ref }>
                            <div className="content-segment__header">
                                <h2>{i18n.t("content.press.header_request")}</h2>
                            </div>
                            <div className="content-segment__content">
                                <FormPress
                                    books_sb = {this.storeBooks.bookList_sb}
                                    selected = {this.state.book_selected}
                                    handleSelected = {this.handleSelected}
                                />
                            </div>
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(Press);
