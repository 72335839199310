import * as ui from './ui';

export function formatTitle(title) {
    let formattedTitle = title.replace(/\s/g, '-');
  
    const chars = {
        '(':'-',
        ')':'',
        '&':'-',
        '?':'',
        '!':'',
        '.':'-',
        'é':'e',
        'ê':'e',
        'ë':'e',
        'è':'e',
        'ì':'i',
        'í':'i',
        'î':'i',
        'ï':'i',
        'ñ':'n',
        'ð':'o',
        'ò':'o',
        'ó':'o',
        'ô':'o',
        'õ':'o',
        'ö':'o',
        'á':'a',
        'à':'a',
        'â':'a',
        'ã':'a',
        'ä':'a'
    }

    formattedTitle = formattedTitle.replace(/[()&?!.éêëèìíîïñðòóôõöáàâãä]/g, m => chars[m]);

    formattedTitle = formattedTitle.replace(/---/g, "-");
    formattedTitle = formattedTitle.replace(/--/g, "-");

    return formattedTitle;
}

export function setContentType(book) {
    const productTypes = ["Audio", "CD", "MP3 audio", "Audioboek", "CD-audio", "CD-Rom", "CD pack", "DVD"];
    let type = 'boek';

    if (book.version) {
        const result = productTypes.findIndex(item => book.version.toLowerCase() === item.toLowerCase());
        
        if (result !== -1) {
            type = 'product';
        } else {
            type = 'boek';
        }
    }

    return type;
}

export function searchChars(value) {
    let search = false;

    const arr = ["CD"];
    const result = arr.findIndex(item => value.toLowerCase() === item.toLowerCase());
    
    if (result !== -1) {
        search = true;
    } 

    return search;
}

export function convertHTML(str) {
    // let replacements = {
    //     "&": "&amp;",
    //     "<": "&lt;",
    //     ">": "&gt;",
    //     "'": "&apos;",
    //     "<>": "&lt;&gt;",
    // }
    // return str.replace(/(&|<|>|""|'|<>)/gi, function(noe) {
    //     return replacements[noe];
    // });
    return str;
}

export function revertHTML(str) {
    let newStr = '';
    let replacements = {
        "&apos;": "'"
    }

    if (str !== null && str !== undefined) {
        newStr = str.replace(/&apos;/g, "'");
    }

    return newStr;
}