import * as apifetch from './fetch.js';

export async function getCountries() {
    const urlEndpoint = '/tools/countries';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function checkFile(isbn, type) {
    const urlEndpoint = '/tools/checkfile';

    const postData = {
        isbn: isbn,
        type: type
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export function checkFileWithAbort(isbn, type, controller, signal) {
    const urlEndpoint = '/tools/checkfile';

    const postData = {
        isbn: isbn,
        type: type
    }

    return apifetch.abortFetch(urlEndpoint, 'POST', postData, controller, signal);
}

export async function checkPdfExtra(isbn) {
    const urlEndpoint = '/tools/checkpdfextra';

    const postData = {
        isbn: isbn
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function checkVATNumber(country_code, vat_nr) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/utilities/vat/checkVATNumber.php';

    const postData = {
        country_code: country_code,
        vat_nr: vat_nr   
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();

        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson.data;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error VAT: ', error.message);
    }
}

export async function getShippingCosts(weight) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/order/getShippingCosts.php';

    const postData = {
        weight: weight   
    }

    try {
        let response = await fetch(urlEndpoint, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error getShippingCosts: ', error.message);
    }
}

export async function getShippingCostsV2(orderId) {
    const urlEndpoint = '/v2/calculations/shipping/' + orderId;

    const postData = {
        orderId: orderId   
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}
