import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const SitemapCats = inject("stores") (
    observer (
        class SitemapCats extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    foldout: false
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount = async() => {
                const categories = await this.storeBooks.returnCategories(this.props.shop);
                this.setState({
                    categories: categories
                })
            }

            handleOnClickCat = (e, cat_name) => {
                this.storeGeneral.setShop(this.props.shop);
                this.storeBooks.getBooksForCategory(cat_name, this.storeUi.page_view);
                this.storeUi.setActiveCategory(cat_name);
                this.storeUi.setActiveSubCategory('');
                this.storeUi.setNavMobile(false);
                let url = "/category/" + cat_name;
                this.props.history.push(url);
            }

            expandBlocks = (e) => {
                this.setState(prevState => ({
                    foldout: !prevState.foldout
                }));
            }
  
            render() {
                return (
                    <ul className={"sitemap__list sitemap__list--cats " + (this.state.foldout ? "--expanded" : "--collapsed")}>
                        {this.state.categories && this.state.categories.map((cat, i) => ( 
                            <li key={i} className="--link" onClick={(e)=>this.handleOnClickCat(e, cat.name)}>
                                {this.props.shop == 'SHOP_ALL'
                                ?   <span dangerouslySetInnerHTML={{ __html: cat.display_value}} />
                                :   <span dangerouslySetInnerHTML={{ __html: cat.name}} />
                                }
                            </li>
                        ))}
                        <li className="actionShowMore" onClick={(e) => this.expandBlocks(e)}>
                            {this.state.foldout 
                                ?   i18n.t("lists.show_less_categories")
                                :   i18n.t("lists.show_more_categories")
                            }
                        </li>
                    </ul>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(SitemapCats));


