import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router-dom";

const NavigationTools = inject("stores") (
    observer (
        class NavigationTools extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeCart = this.props.stores.storeCart;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeWishlist = this.props.stores.storeWishlist;
            }

            componentDidMount = () => {

            }

            componentDidUpdate = (prevProps) => {
               
            }
            
            handleOnClick = (e, path) => {
                const id = (this.storeAuth.user.is_loggedin) ? this.storeAuth.user.user_id : 0;

                if (path === '/login') {
                    if (this.storeUi.app_interface.use_case === 'inshop') {
                        this.props.history.push(path);
                    } else {
                        const url = '/shoppingcart/client';
                        this.props.history.push(url);
                        this.storeUi.setDirectLogin(true);
                        this.storeOrder.setCartView(id, 'client');
                    }
                } else {
                    if (path === '/shoppingcart') {
                        this.storeCart.getCart();
                        this.storeUi.setUseCase("inshop");
                    }
                    if (path === '/wishlist') {
                        this.storeUi.setUseCase("inshop");
                    }
                    if (path === '/account') {
                        this.storeUi.setUseCase("inshop");
                        this.storeUser.refreshUserData();
                    }
                    this.props.history.push(path);
                }
            }

            handleLogOut = (e) => {
                this.storeUser.resetUserData();
                this.storeAuth.setLogoutState();
                this.storeUser.setLogoutState();
                this.storeUser.setLoginState(false);
                this.storeOrder.resetOrderData(false);
                this.storeOrder.retrieveShoppingCartFromLS();
                this.storeOrder.resetOrderSettings('discounts_overruled', 0);
                this.storeUi.setUseCase("inshop");
                this.storeUi.setReturnState("");
                this.storeUi.setPartnerCode(0);
                this.storeWishlist.retrieveWishlist();
                this.storeUi.setFeatureAll(false);
                
                // SbC DEPRICATE when going live with SHOP_ALL
                // this.storeGeneral.setActiveShop("SHOP_SB");
                // this.storeUi.setActiveToggleNav(2);
                
                // const idx = this.storeUi.features_list
                //     .findIndex(feature => feature.sku === 'SHOP_ALL');
                // if (idx !== -1 && this.storeUi.features_list[idx].access_level !== 0) {
                //     this.storeGeneral.setShop("SHOP_SB");
                // }
                
                let url = "/";
                this.props.history.push(url);
            }

            handleNavMobile = (e) => {
                this.storeUi.setNavMobile();
            }

            render() {
                const id = (this.storeAuth.user.is_loggedin) ? this.storeAuth.user.user_id : 0;

                return (
                    <div className="navigation--tools">
                        <ul className="navigation--tools__list">
                            <li className="navigation--tools__listitem navigation--tools__listitem--wishlist" onClick={(e)=>this.handleOnClick(e, '/wishlist')}>
                                {id === 0 && this.storeWishlist.wishlist.anonymous && this.storeWishlist.wishlist.anonymous.length > 0 &&
                                    <span className="navigation--tools__counter">
                                        <span>
                                            {this.storeWishlist.wishlist.anonymous.length}
                                        </span>
                                    </span>
                                }
                                {id !== 0 && this.storeWishlist.wishlist.user && this.storeWishlist.wishlist.user.length > 0 &&
                                    <span className="navigation--tools__counter">
                                        <span>
                                            {this.storeWishlist.wishlist.user.length}
                                        </span>
                                    </span>
                                }
                                <span className="navigation--tools__tool">Verlanglijstje</span>
                            </li>
                            <li className="navigation--tools__listitem navigation--tools__listitem--cart" onClick={(e)=>this.handleOnClick(e, '/shoppingcart')}>
                                {this.storeAuth.user.is_loggedin && this.storeOrder.calculateTotalCartItemsUser > 0 &&
                                    <span className="navigation--tools__counter"><span>{this.storeOrder.calculateTotalCartItemsUser}</span></span>
                                }
                                {!this.storeAuth.user.is_loggedin && this.storeOrder.calculateTotalCartItemsAnonymous > 0 &&
                                    <span className="navigation--tools__counter"><span>{this.storeOrder.calculateTotalCartItemsAnonymous}</span></span>
                                }
                                <span className="navigation--tools__tool">Winkelwagentje</span>
                            </li>
                            {this.storeAuth.user.is_loggedin
                            ?   <React.Fragment>
                                    <li className="navigation--tools__listitem navigation--tools__listitem--profile" onClick={(e)=>this.handleOnClick(e, '/account')}><span className="navigation--tools__tool">Mijn gegevens</span></li>
                                    <li className="navigation--tools__listitem navigation--tools__listitem--logout" onClick={(e)=>this.handleLogOut(e)}><span className="navigation--tools__tool">Uitloggen</span></li>
                                </React.Fragment>
                            :   <li className="navigation--tools__listitem navigation--tools__listitem--login" onClick={(e)=>this.handleOnClick(e, '/login')}><span className="navigation--tools__tool">Inloggen</span></li>
                            }
                            <li className="navigation--tools__listitem navigation--tools__listitem--mobile-nav-trigger" onClick={(e)=>this.handleNavMobile(e)}>
                            </li>
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(NavigationTools);
