import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { NavLink } from 'react-router-dom';
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import * as ui from '../../utilities/ui';

const NavigationMain = inject("stores") (
    observer (
        class NavigationMain extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                
                this.state = {
                    dd_active: false
                }
            }

            componentDidMount = () => {
                if (this.storeGeneral.shop === '') {
                    this.storeGeneral.setShop('SHOP_SB');
                }
            }

            handleNavClick = (e, id, path) => {
                this.storeUi.setActiveMainNav(id);
                this.storeUi.setActiveToggleNav(2);
                this.storeUi.setActiveSubNav(0);
                this.storeUi.setPageView('page', 1);

                this.props.history.push(path);

                ui.smoothScrollTo(0, 0, 500); 
            }

            render() {
                const nav_id = this.storeUi.active_nav

                const Li = (item) => {
                    const activeClass = (item.id === nav_id) ? ' --active' : '';
                    return (
                        <li className={"navigation--main__listitem" + activeClass} onClick={(e) => this.handleNavClick(e, item.id, item.path)}>
                            <span dangerouslySetInnerHTML={{ __html: item.title}}></span>
                        </li>
                    )
                }

                return (
                    <div className="navigation--main">
                        <ul className="navigation--main__list">
                            {this.storeUi.main_nav.map((item, i) => {
                                let nav_item;
                                (item.use == "all" && (item.show && item.show.includes(this.storeGeneral.shop)))
                                ?   nav_item = <Li 
                                        key = {i}
                                        id = {item.id}
                                        title = {i18n.t("navigation." + item.name)}
                                        path = {item.path}
                                    />
                                :   item.use == "restricted" && this.storeAuth.user.is_loggedin && (
                                        nav_item = <Li 
                                            key = {i}
                                            id = {item.id}
                                            title = {i18n.t("navigation." + item.name)}
                                            path = {item.path}
                                        />
                                    )
                                return nav_item
                            })}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default  withRouter(withTranslation()(NavigationMain));