import * as apifetch from './fetch.js';

export async function createNewOrder(account_id, partner_code) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/order/createNewOrder.php';

    if (partner_code && partner_code !== undefined) {
    } else {
        partner_code = 0;
    }

    const postData = {
        account_id: account_id,
        partner_code: partner_code
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();

        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error order id: ', error.message);
    }
}

export async function handleOrderItem(order_line, action) {
    let urlEndpoint;
    
    //SbC DEPRICATED
    if (action === 'edit') {
        //urlEndpoint = '/order/handleOrderItem.php';
    }
    if (action === 'add') {
        //urlEndpoint = '/order/handleOrderItem.php';
    }
    //SbC only in use for DELETE
    if (action === 'delete') {
        urlEndpoint = '/order/deleteOrderItem.php';
    }

    const postData = order_line;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function addOrderLine(order_line, id, action) {
    //console.log('SbC orderline add', order_line)
    const urlEndpoint = '/v2/orders/' + id + '/add';
    
    const postData = order_line;

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function editOrderLine(order_line, id, action) {
    //console.log('SbC orderline edit', order_line)
    const urlEndpoint = '/v2/orders/' + id + '/edit';
    
    const postData = order_line;

    //console.log('SbC edit', postData)

    return apifetch.sbFetch(urlEndpoint, 'PUT', postData);
}

export async function setOrderStatus(account_id, order_id, order_status) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/order/setOrderStatus.php';

    const postData = {
        account_id: account_id,
        order_id: order_id,
        order_status: order_status
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error order status: ', error.message);
    }
}

export async function getAccountOrders(account_id) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/accounts/getAccountOrders.php';

    const postData = {
        account_id: account_id
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error orders for account: ', error.message);
    }
}

export async function saveOrderDataProfile(order_id, order_data) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/order/saveOrderProfile.php';

    const postData = {
        order_id: order_id,
        order_data: order_data
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;
            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error order profile data: ', error.message);
    }
}

export async function getOrderData(order_id) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/order/getOrderData.php';

    const postData = {
        order_id: order_id
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error order data: ', error.message);
    }
}

export async function getOrderItemsData(order_id) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/order/getOrderItemsData.php';

    const postData = {
        order_id: order_id
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            })
        });
        
        let responseJson = await response.json();
        if (responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error order lines data: ', error.message);
    }
}

export async function setOrderRemark(order_id, remark) {
    const urlEndpoint = '/order/setOrderRemark.php';
    const postData = {
        order_id: order_id,
        remark: remark
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function removeOrder(order_id) {
    const urlEndpoint = '/order/removeOrder.php';
    const postData = {
        order_id: order_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function activatePCUse(pc) {
    const urlEndpoint = '/order/activatePCUse.php';
    const postData = {
        pc: pc
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrderId(guid) {
    const urlEndpoint = '/order/getOrderId.php';

    const postData = {
        guid: guid
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkDiscountsOverruled(order_id) {
    const urlEndpoint = '/order/checkDiscountsOverruled.php';

    const postData = {
        order_id: order_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkShippingCostsOverruled(order_id) {
    const urlEndpoint = '/order/checkShippingCostsOverruled.php';

    const postData = {
        order_id: order_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

// SbC identical to checkShippingCostsOverruled but better naming
export async function checkNoShippingOrder(orderId) {
    const urlEndpoint = '/order/checkShippingCostsOverruled.php';

    const postData = {
        order_id: orderId
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkShippingCostsCustom(order_id) {
    const urlEndpoint = '/order/checkShippingCostsCustom.php';

    const postData = {
        order_id: order_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getCustomShippingCosts(order_id) {
    const urlEndpoint = '/order/getCustomShippingCosts.php';

    const postData = {
        order_id: order_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getOrderAvailability(order_id) {
    const urlEndpoint = '/order/getOrderAvailability.php';

    const postData = {
        order_id: order_id
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}