import * as apifetch from './fetch.js';

export async function getEvents() {
    const urlEndpoint = '/v2/events';

    return apifetch.sbFetch(urlEndpoint, 'GET')
}

export async function getEvent(ref) {
    const urlEndpoint = '/v2/events/' + ref;

    return apifetch.sbFetch(urlEndpoint, 'GET')
}
