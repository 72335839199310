import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import {isIOS} from 'react-device-detect';

import TemplateSidebarRight from '../../templates/template-sidebar-right';

const BlockNewsletter = inject("stores") (
    observer (
        class BlockNewsletter extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    latest: {}
                }
            }

            componentDidMount = async() => {
                const newsletters = await this.storeUi.getNewsletters()
                
                const latest_newsletter = newsletters
                    .filter(newsletter => newsletter.tag === this.storeGeneral.settings_list.tagLatestNewsletter);

                this.setState({
                    latest: latest_newsletter[0]
                })
            } 

            handleClick = (url, view) => {
                document.body.classList.add('dialog-open');
                this.storeUi.openDialogNewsletter(url);
                this.props.history.push('/?mailing=' + this.storeGeneral.settings_list.tagLatestNewsletter + '&view=' + view);
            }
  
            render() {
                const url = this.urlAPI + "/mailings/" + this.storeGeneral.settings_list.latestNewsletter;
                const url_tag = this.urlAPI + "/newsletters/newsletter.php?mailing=" + this.storeGeneral.settings_list.tagLatestNewsletter + "&origin=site";

                return (
                    <TemplateSidebarRight
                        class_modifier = "newsletter"
                        block_title = {i18n.t("block.newsletter.header")}
                    >
                        <div className="block__block-content">
                            <React.Fragment>
                                <div className="block__block-subtitle">
                                    {i18n.t("block.newsletter.link_latest")}
                                    <span className="newsletter__actions">
                                        {this.state.latest && this.state.latest.has_php === 1 &&
                                            <span className="link--read-more --link" onClick={() => this.handleClick(url_tag, 'web')}>web</span>
                                        }
                                        {this.state.latest && this.state.latest.has_pdf === 1 && (
                                            <React.Fragment>
                                            {isIOS 
                                            ?   <a href={url + ".php?origin=site"} target="_blank" className="link--read-more">
                                                    pdf
                                                </a>
                                            :   <span className="link--read-more" onClick={() => this.handleClick(url + ".php?origin=site", 'pdf')}>
                                                    pdf
                                                </span>
                                            }
                                            </React.Fragment>
                                        )}
                                    </span>
                                </div>
                            </React.Fragment>
                            <div className="block__block-content">
                                <Link 
                                    className="link--read-more"
                                    to="/overview-newsletters">{i18n.t("block.newsletter.link_all")}
                                </Link>
                                <br/>
                                <Link 
                                    className="link--read-more"
                                    to="/newsletter">{i18n.t("block.newsletter.link_subscribe_header")}
                                </Link>
                                <Link 
                                    className="link--subscribe"
                                    to="/newsletter"
                                    dangerouslySetInnerHTML={{ __html: i18n.t("block.newsletter.link_subscribe")}} 
                                />
                            </div>
                        </div>
                    </TemplateSidebarRight>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockNewsletter));


