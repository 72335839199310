import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import SearchIntentions from '../search/search-intentions';

const FeedbackNoSearchResultsSuggestion = inject("stores") (
    observer (
        class FeedbackNoSearchResultsSuggestion extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeGeneral = this.props.stores.storeGeneral;

                this.state = {
                    txt: ''
                }
            }

            componentDidMount = () => {
                if ((this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own === 0) ||
                (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb === 0) ||
                (this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all === 0)) {
                    this.setState({
                        txt: 'verder '
                    })
                }
            }

            handleChangeSegment = (shop) => {
                let search_term;
                let altShop;
                let altShopSearch;
                search_term = this.storeUi.search_value;

                switch(shop) {
                    case 'Alle zelfhulpboeken':
                        altShop = 'SHOP_SB';
                        altShopSearch = 'SB';
                        this.storeUi.setActiveToggleNav(2);
                        break;
                    case 'Alle boeken':
                        altShop = 'SHOP_ALL';
                        altShopSearch = 'ALL';
                        this.storeUi.setActiveToggleNav(3);
                        break;
                    default:   
                }

                this.storeGeneral.setShop(altShop);
                this.storeGeneral.setActiveShop(altShop);
                //this.storeGeneral.setSearchShop(altShopSearch);
                
                this.storeUi.setPageView('page', 1);
                this.storeUi.getSearchIntentions(search_term);
                this.storeUi.setSearchOverlay(false);
                this.storeUi.setSearchInputFocus(false);
               
                this.storeUi.saveSearchQuery(search_term);
                this.storeUi.setActiveMainNav(this.storeUi.app_interface.active_search_segment);

                const path = "/search-results";
                const str_search = "?q=" + search_term;

                this.props.history.push({
                    pathname: path,
                    search: str_search
                })
            }

            render() {     
                return (
                    <React.Fragment>
                        <div className="search-overlay__alternatives">
                            <div className="search-overlay__suggestions">
                                {/* <p><strong>Wat kun je {this.state.txt}doen?</strong></p> */}
                                <p><strong>Niet gevonden wat je zocht?</strong></p>
                                <ul>
                                    <li>Controleer de spelling van je zoekwoord(en)</li>
                                    <li>Probeer een ander zoekwoord</li>
                                    <li>Kies hiernaast een (andere) categorie</li>
                                    {(this.storeGeneral.searchShop !== 'ALL' && this.storeUi.search_count_all > 10) &&
                                        <li>Kijk ook bij
                                            <span
                                                className = "--link"
                                                onClick = {() => this.handleChangeSegment('Alle boeken')}
                                            >
                                                Alle boeken
                                            </span>
                                            voor meer resultaten
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(FeedbackNoSearchResultsSuggestion);
