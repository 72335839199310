import React, { Component } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import * as ui from '../utilities/ui';

class ScrollerTop extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            is_visible: false
        };
    }

    componentDidMount() {
        let scrollComponent = this;
        document.addEventListener("scroll", function(e) {
            scrollComponent.toggleVisibility();
        });
        smoothscroll.polyfill();
    }

    toggleVisibility() {
        if (window.pageYOffset > 300) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    scrollToTop() {
        ui.smoothScrollTo(0, 0, 500); 

        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // });
    }

    render() {  
        let class_visible = this.state.is_visible ? ' --fadeIn' : ' --fadeOut';

        return (
            <div className={"scroller-wrapper" + class_visible} onClick={(e) => this.scrollToTop()}>
                <div className="scroller-wrapper__icon" />
            </div>
        )
    }
}

export default ScrollerTop;
