import * as apifetch from './fetch.js';

export async function getBooks(shop, page_view) {
    const urlEndpoint = '/v2/catalog/sb';
    
    const postData = {
        page_view: page_view
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export function getBooksWithAbort(shop, page_view, controller, signal) {
    const urlEndpoint = '/v2/catalog/sb';
    
    const postData = {
        page_view: page_view
    }

    return apifetch.abortFetch(urlEndpoint, 'POST', postData, controller, signal);
}

export async function getBookCountForBooksSB(shop, page_view) {
    const urlEndpoint = '/v2/catalog/sb/count';
    
    const postData = {
        page_view: page_view
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookCountForCategory(shop, cat, stock_priority) {
    const urlEndpoint = '/catalog/getBookCount.php';

    const postData = {
        shop: shop,
        category: cat,
        subcategory: '',
        stock_priority: stock_priority
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookCountForSubCategory(shop, cat, subcat, stockPriority) {
    const urlEndpoint = '/catalog/getBookCount.php';

    const postData = {
        shop: shop,
        category: cat,
        subcategory: subcat,
        stock_priority: stockPriority
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookCountForLanguage(shop, lang) {
    const urlEndpoint = '/catalog/getBookCount.php';

    const postData = {
        shop: shop,
        language: lang
    }
    
    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBooksForCategory(shop, cat, page_view, stock_priority) {
    const urlEndpoint = '/catalog/getBooksForCategory.php';

    const postData = {
        shop: shop,
        category: cat,
        page_view: page_view,
        stock_priority: stock_priority
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export function getBooksForCategoryWithAbort(shop, cat, page_view, stock_priority, controller, signal) {
    const urlEndpoint = '/catalog/getBooksForCategory.php';

    const postData = {
        shop: shop,
        category: cat,
        page_view: page_view,
        stock_priority: stock_priority
    }

    return apifetch.abortFetch(urlEndpoint, 'POST', postData, controller, signal);
}

export async function getBooksForLanguage(shop, lang, page_view, stock_priority) {
    const urlEndpoint = '/catalog/getBooksForLanguage.php';

    const postData = {
        shop: shop,
        language: lang,
        page_view: page_view,
        stock_priority: stock_priority
    }
    
    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBooksForSubCategory(shop, cat, subcat, page_view, stock_priority) {
    const urlEndpoint = '/catalog/getBooksForSubCategory.php';

    const postData = {
        shop: shop,
        category: cat,
        subcategory: subcat,
        page_view: page_view,
        stock_priority: stock_priority
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export function getBooksForSubCategoryWithAbort(shop, cat, subcat, page_view, stock_priority, controller, signal) {
    const urlEndpoint = '/catalog/getBooksForSubCategory.php';

    const postData = {
        shop: shop,
        category: cat,
        subcategory: subcat,
        page_view: page_view,
        stock_priority: stock_priority
    }

    return apifetch.abortFetch(urlEndpoint, 'POST', postData, controller, signal);
}

export async function getBooksForSubCategoryAll(shop, subcat, page_view, stock_priority) {
    const urlEndpoint = '/catalog/getBooksForSubCategory.php';

    const postData = {
        shop: shop,
        category: 0,
        subcategory: subcat,
        page_view: page_view,
        stock_priority: stock_priority
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBooksAll() {
    const urlEndpoint = '/catalog/getBooksAll.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function xgetBooksNew(view) {
    const urlEndpoint = '/catalog/getBooksNew.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getBooksNew(shop, view, page_view) {
    let urlEndpoint;
    if (shop === 'SHOP_SB') {
        urlEndpoint = '/v2/catalog/new';
    } else {
        urlEndpoint = '/v2/catalog/new/' + view;
    }
   
    const postData = {
        shop: shop,
        page_view: page_view
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBookCountForBooksNew(shop, view, page_view) {
    let urlEndpoint;
    if (shop === 'SHOP_SB') {
        urlEndpoint = '/v2/catalog/new/count';
    } else {
        urlEndpoint = '/v2/catalog/new/' + view + '/count';
    }

    const postData = {
        shop: shop,
        page_view: page_view
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getBooksNewest() {
    const urlEndpoint = '/catalog/getBooksNewest.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getBooksNewestAuto() {
    const urlEndpoint = '/catalog/getBooksNewestAuto.php';

    return apifetch.sbFetch(urlEndpoint, 'GET');
}

export async function getTop10s() {
    const urlEndpoint = '/v2/catalog/top10s';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getTop10NUR(tag) {
    const urlEndpoint = '/v2/catalog/top10NUR';

    const postData = {
        tag: tag
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function getBooksTop10(tag, nur, isAutofill) {
    const urlEndpoint = '/v2/catalog/top10';

    const postData = {
        tag: tag,
        nur: nur,
        isAutofill: isAutofill
    }

    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function getBooksTop20() {
    const urlEndpoint = '/v2/catalog/top20';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getBooksTop60() {
    const urlEndpoint = '/v2/catalog/top60';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getWeekTop60() {
    const urlEndpoint = '/v2/catalog/top60week';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getBooksRelated(sku) {
    const urlEndpoint = '/catalog/getBooksRelated.php';

    const postData = {
        sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}