import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as books_all from '../communicator/books-all';

class StoreBooksAll {

    constructor() {
    }

    async getCategoryDetails(cat) {
        let details;
        if (cat !== undefined) {
            try {
                const returnData = await books_all.getCategoryDetails(cat);
                runInAction(() => {
                    details = returnData.data[0];
                    return details;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        } else {
            details = {};
        }
        return details;
    }
}

decorate(StoreBooksAll, {
    getCategoryDetails: action
})

export default StoreBooksAll;