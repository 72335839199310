import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import * as rdd from "react-device-detect";
import {isIOS} from 'react-device-detect';

const BlockAnkeilerNewsletter = inject("stores") (
    observer (
        class BlockAnkeilerNewsletter extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    latest: {}
                }
            }

            componentDidMount = async() => {
                const newsletters = await this.storeUi.getNewsletters()
                
                const latest_newsletter = newsletters
                    .filter(newsletter => newsletter.tag === this.storeGeneral.settings_list.tagLatestNewsletter);

                this.setState({
                    latest: latest_newsletter[0]
                })
            } 

            handleClick = (url) => {
                document.body.classList.add('dialog-open');
                this.storeUi.openDialogNewsletter(url);
            }
 
            render() {
                const url = this.urlAPI + "/mailings/" + this.storeGeneral.settings_list.latestNewsletter;
                const url_tag = this.urlAPI + "/newsletters/newsletter.php?mailing=" + this.storeGeneral.settings_list.tagLatestNewsletter + "&origin=site";

                return (
                    rdd.isMobile && 
                        <div className="blocks blocks--books blocks--newsletter ankeiler--newsletter">
                            <div className="blocks__blocks-title">
                                <div className="blocks__blocks-title--main">
                                    Onze nieuwsbrief
                                </div> 
                                <div className="block__block-content">
                                    <span className="newsletter__subtitle">Laatste nieuwsbrief</span>
                                    <span className="newsletter__actions">
                                        {this.state.latest && this.state.latest.has_php === 1 &&
                                            <span 
                                                className="link--read-more --link" 
                                                onClick={() => this.handleClick(url_tag, 'web')}
                                            >
                                                web
                                            </span>
                                        }
                                        {this.state.latest && this.state.latest.has_pdf === 1 && (
                                            isIOS 
                                            ?   <a 
                                                    className="link--read-more"
                                                    href={url + ".php?origin=site"} 
                                                    target="_blank" 
                                                   
                                                >
                                                    pdf
                                                </a>
                                            :   <span 
                                                    className="link--read-more" 
                                                    onClick={() => this.handleClick(url + ".php?origin=site", 'pdf')}
                                                >
                                                    pdf
                                                </span>
                                           
                                        )}
                                    </span>
                                </div>

                                <div className="block__block-content">
                                    <Link 
                                        className="link--read-more"
                                        to="/overview-newsletters"
                                        dangerouslySetInnerHTML={{ __html: i18n.t("block.newsletter.link_all")}} 
                                    />
                                </div>

                                <div className="block__block-content">          
                                    <Link 
                                        className="link--subscribe link--subscribe-subtitle link--read-more"
                                        to="/newsletter"
                                        dangerouslySetInnerHTML={{ __html: i18n.t("block.newsletter.link_subscribe_ankeiler")}} 
                                    />
                                </div>
                            </div>
                        </div>
                )
            }
        }
    )
)

export default BlockAnkeilerNewsletter;

