import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';

import * as content from '../../utilities/content';

import BlocksBooks from '../blocks/blocks-books';
import FeedbackNoSearchResults from '../feedback/feedback-no-search-results';
import FeedbackNoSearchResultsSuggestion from '../feedback/feedback-no-search-results-suggestion';

const SearchResult = inject("stores") (
    observer (
        class SearchResult extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    shopAlternative: '',
                    skuAlternative: ''
                }
            }

            componentDidMount = () => {
                let section = '';
                let searchTerm = '';

                const searchString = this.props.location.search;
                const params = queryString.parse(searchString);
                if (params.q) {
                    searchTerm = params.q;
                    searchTerm = searchTerm.replace('?', "");
                    searchTerm = searchTerm.replace('!', "");
                }

                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    if (this.props.searchShop === 'OWN') {
                        section = 'sb';
                        (async() => this.storeUi.getSearchResultsSB(searchTerm, section, this.storeUi.page_view) )();
                    }
                    if (this.props.searchShop === 'SB') {
                        section = '';
                        if (this.storeUi.search_count_sb !== 0) {
                            (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                        } else {
                            (async() => this.storeUi.getSearchResults(searchTerm, '', this.storeUi.page_view) )(); 
                        }
                    }
                    if (this.props.searchShop === 'ALL') {
                        (async() => this.storeUi.getSearchResults(searchTerm, '', this.storeUi.page_view) )(); 
                    } 
                } else {
                    (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                }
            }

            componentDidUpdate  = async (prevProps) => {
                let section = '';
                let searchTerm = this.props.searchTerm;

                if (prevProps.page !== this.props.page) {
                    if (this.storeUi.checkFeature('SHOP_ALL')) {
                        if (this.props.searchShop === 'OWN') {
                            section = 'sb';
                            (async() => this.storeUi.getSearchResultsSB(searchTerm, section, this.storeUi.page_view) )();
                        }
                        if (this.props.searchShop === 'SB') {
                            section = '';
                            (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                        }    
                        if (this.props.searchShop === 'ALL') {
                            (async() => this.storeUi.getSearchResults(searchTerm, '', this.storeUi.page_view) )(); 
                        }
                    } else {
                        (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                    }
                } else if (prevProps.inview !== this.props.inview) {
                    if (this.storeUi.checkFeature('SHOP_ALL')) {
                        if (this.props.searchShop === 'OWN') {
                            section = 'sb';
                            (async() => this.storeUi.getSearchResultsSB(searchTerm, section, this.storeUi.page_view) )();
                        }
                        if (this.props.searchShop === 'SB') {
                            section = '';
                            (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                        }    
                        if (this.props.searchShop === 'ALL') {
                            (async() => this.storeUi.getSearchResults(searchTerm, '', this.storeUi.page_view) )(); 
                        } 
                    } else {
                        (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                    }
                } else if (prevProps.searchTerm !== this.props.searchTerm) {
                    if (this.storeUi.checkFeature('SHOP_ALL')) {
                        if (this.props.searchShop === 'OWN') {
                            section = 'sb';
                            (async() => this.storeUi.getSearchResultsSB(searchTerm, section, this.storeUi.page_view) )();
                        }
                        if (this.props.searchShop === 'SB') {
                            section = '';
                            (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                        }    
                        if (this.props.searchShop === 'ALL') {
                            (async() => this.storeUi.getSearchResults(searchTerm, '', this.storeUi.page_view) )(); 
                        } 
                    } else {
                        (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                    }
                } else if (prevProps.searchShop !== this.props.searchShop) {
                    if (this.storeUi.checkFeature('SHOP_ALL')) {
                        if (this.props.searchShop === 'OWN') {
                            section = 'sb';
                            (async() => this.storeUi.getSearchResultsSB(searchTerm, section, this.storeUi.page_view) )();
                        }
                        if (this.props.searchShop === 'SB') {
                            section = '';
                            if (this.storeUi.search_count_sb !== 0) {
                                (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                            } else {
                                (async() => this.storeUi.getSearchResults(searchTerm, '', this.storeUi.page_view) )(); 
                            }
                        }
                        if (this.props.searchShop === 'ALL') {
                            (async() => this.storeUi.getSearchResults(searchTerm, '', this.storeUi.page_view) )(); 
                        } 
                    } else {
                        (async() => this.storeUi.getSearchResultsSB(searchTerm, '', this.storeUi.page_view) )();
                    }
                }
            }

            handleChangeSegment = (shop) => {
                this.storeGeneral.setSearchShop(shop);
                this.storeUi.setSearchOverlay(false);
                const searchTerm = this.storeUi.search_value;

                let path = "/search-results";
                const strSearch = "?q=" + searchTerm;

                // if (shop == 'Alle zelfhulpboeken') {
                //     this.storeGeneral.setShop("SHOP_SB");
                //     this.storeGeneral.setActiveShop("SHOP_SB");
                //     this.storeGeneral.setSearchShop('SB');
                //     this.storeUi.setActiveToggleNav(2);
                // }

                // if (shop == 'Alle boeken') {
                //     this.storeGeneral.setShop("SHOP_ALL");
                //     this.storeGeneral.setActiveShop("SHOP_ALL");
                //     this.storeGeneral.setSearchShop('ALL');
                //     this.storeUi.setActiveToggleNav(3);
                // }

                this.props.history.push({
                    pathname: path,
                    search: strSearch
                })
            }

            render() {
                return (
                    <React.Fragment>
                        <div className="content-segment">
                            {!this.storeUi.checkFeature('SHOP_ALL') &&
                                (this.storeUi.search_value.trim().length >= 3 || content.searchChars(this.storeUi.search_value.trim())) &&
                                <React.Fragment>
                                    {(this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb > 0) &&
                                        <div className="search-results">
                                            <BlocksBooks 
                                                blockTitle = {i18n.t("blocks.search_results.header")}
                                                type = "search"
                                                default_view = "summaries"
                                                page = "search"
                                                searchShop = {this.props.searchShop}
                                                searchTerm = {this.props.searchTerm}
                                            />
                                        </div>
                                    }
                                </React.Fragment>
                            }

                            {!this.storeUi.checkFeature('SHOP_ALL') &&
                                (this.storeUi.search_value.trim().length >= 3 || content.searchChars(this.storeUi.search_value.trim())) &&
                                    (this.storeUi.search_results_simple || this.storeUi.search_results_simple === null) &&
                                        ((this.storeGeneral.searchShop === 'SB' &&
                                            this.storeUi.search_count_sb === 0 || this.storeUi.search_count_sb === null
                                        )) &&
                                        <React.Fragment>
                                            <p
                                                className="content--empty"
                                            >
                                                We kunnen geen resultaten voor je zoekopdracht vinden.
                                            </p>
                                            <FeedbackNoSearchResultsSuggestion
                                            
                                            />
                                        </React.Fragment>
                            }
                            
                            {this.storeUi.checkFeature('SHOP_ALL') &&
                                (this.storeUi.search_value.trim().length >= 3 || content.searchChars(this.storeUi.search_value.trim())) &&
                                    <React.Fragment>
                                        {((this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own > 0) ||
                                        (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb > 0) ||
                                        (this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all > 0)) &&
                                        <div className="search-results">
                                                <BlocksBooks 
                                                    blockTitle = {i18n.t("blocks.search_results.header")}
                                                    type = "search"
                                                    default_view = "summaries"
                                                    page = "search"
                                                    searchShop = {this.props.searchShop}
                                                    searchTerm = {this.props.searchTerm}
                                                />
                                            </div>
                                        }

                                        {((this.storeGeneral.searchShop === 'OWN' &&
                                            this.storeUi.search_count_own === 0 || this.storeUi.search_count_own === null
                                            ) ||
                                            (this.storeGeneral.searchShop === 'SB' &&
                                                this.storeUi.search_count_sb === 0 || this.storeUi.search_count_sb === null
                                            ) ||
                                            (this.storeGeneral.searchShop === 'ALL' &&
                                                this.storeUi.search_count_all === 0 || this.storeUi.search_count_all === null
                                            )) &&
                                            <div 
                                                className = 'search-overlay__column--feedback'
                                            >
                                                <FeedbackNoSearchResults

                                                />
                                            </div>
                                        }
                                        
                                        {this.storeGeneral.searchShop === 'OWN' && 
                                            <React.Fragment>
                                                {(this.storeUi.search_count_own === 0 && this.storeUi.search_count_sb !== 0) &&
                                                    <React.Fragment>
                                                        <span
                                                            className = "search-overlay__alternatives-header"
                                                        >
                                                            Hieronder staan de eerste resultaten uit
                                                                <span
                                                                    className = "--link"
                                                                    onClick = {() => this.handleChangeSegment('Alle zelfhulpboeken')}
                                                                >
                                                                    {'Alle zelfhulpboeken'}
                                                                </span>
                                                        </span>
                                                        <div className="search-results">
                                                            <BlocksBooks 
                                                                blockTitle = {i18n.t("blocks.search_results.header")}
                                                                type = "alternative"
                                                                default_view = "summaries"
                                                                page = "search"
                                                                searchShop = {'SB'}
                                                                searchTerm = {this.props.searchTerm}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }

                                        {this.storeGeneral.searchShop === 'OWN' && 
                                            <React.Fragment>
                                                {(this.storeUi.search_count_own === 0 && this.storeUi.search_count_sb === 0  && this.storeUi.search_count_all !== 0) &&
                                                    <React.Fragment>
                                                        <span
                                                            className = "search-overlay__alternatives-header"
                                                        >
                                                            Hieronder staan de eerste resultaten uit
                                                                <span
                                                                    className = "--link"
                                                                    onClick = {() => this.handleChangeSegment('Alle boeken')}
                                                                >
                                                                    {'Alle boeken'}
                                                                </span>
                                                        </span>
                                                        <div className="search-results">
                                                            <BlocksBooks 
                                                                blockTitle = {i18n.t("blocks.search_results.header")}
                                                                type = "alternative"
                                                                default_view = "summaries"
                                                                page = "search"
                                                                searchShop = {'ALL'}
                                                                searchTerm = {this.props.searchTerm}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }

                                        {this.storeGeneral.searchShop === 'SB' && 
                                            <React.Fragment>
                                                {(this.storeUi.search_count_sb === 0  && this.storeUi.search_count_all !== 0) &&
                                                    <React.Fragment>
                                                        <span
                                                            className = "search-overlay__alternatives-header"
                                                        >
                                                            Hieronder staan de eerste resultaten uit
                                                                <span
                                                                    className = "--link"
                                                                    onClick = {() => this.handleChangeSegment('Alle boeken')}
                                                                >
                                                                    {'Alle boeken'}
                                                                </span>
                                                        </span>
                                                        <div className="search-results">
                                                            <BlocksBooks 
                                                                blockTitle = {i18n.t("blocks.search_results.header")}
                                                                type = "alternative"
                                                                default_view = "summaries"
                                                                page = "search"
                                                                searchShop = {'ALL'}
                                                                searchTerm = {this.props.searchTerm}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }

                                        {(this.storeUi.search_count_own === 0 && this.storeUi.search_count_sb === 0 && this.storeUi.search_count_all === 0) &&
                                            <FeedbackNoSearchResultsSuggestion
                                                
                                            />
                                        }
                                    </React.Fragment>
                            }
                        </div>
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(SearchResult);
