import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import moment from "moment";

import validate from '../../utilities/validation-rules';
import TemplateForms from '../../templates/template-forms';

import TextArea from '../../elements/form-input-textarea';
import TextInput from '../../elements/form-input-text';

const FormSendToFriend = inject("stores") (
    observer (
        class FormSendToFriend extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    formControls: { 
                        relations: [{
                            firstname: {
                                value: '',
                                placeholder: i18n.t("forms.placeholder.firstname_relation"),
                                validationRules: {
                                    isRequired: true
                                },
                            },
                            lastname: {
                                value: '',
                                placeholder: i18n.t("forms.placeholder.lastname_relation"),
                                valid: true
                            },
                            email: {
                                value: '',
                                placeholder: i18n.t("forms.placeholder.email_relation"),
                                touched: false,
                                validationRules: {
                                    isRequired: true,
                                    isEmail: true
                                }
                            }
                        }],
                        firstname: {
                            label: i18n.t("forms.label.firstname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.firstname : '',
                            placeholder: i18n.t("forms.placeholder.firstname"),
                            touched: false,
                            validationRules: {
                                isRequired: true
                            },
                            required: true
                        },
                        lastname: {
                            label: i18n.t("forms.label.lastname"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.lastname : '',
                            placeholder: i18n.t("forms.placeholder.lastname"),
                            touched: false,
                            valid: true
                        },         
                        email: {
                            label: i18n.t("forms.label.email"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.profile.email : '',
                            placeholder: i18n.t("forms.placeholder.email"),
                            touched: false,
                            validationRules: {
                                isRequired: true,
                                isEmail: true
                            },
                            required: true
                        },
                        message: {
                            label: i18n.t("forms.label.message"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.message"),
                            valid: true
                        },
                        partner_code: {
                            label: i18n.t("forms.label.partner"),
                            value: this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.partner_code : '',
                            placeholder: i18n.t("forms.placeholder.partner"),
                            touched: true,
                            valid: true
                        }, 
                        captcha: {
                            label: i18n.t("forms.label.captchar"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.captcha"),
                            touched: false,
                            validationRules: {
                                isRequired: true,
                                passCaptcha: true
                            },
                            required: true
                        } 
                    },
                    formIsValid: false,
                    btn_class: '--disabled',
                    error: false
                }
            }

            componentWillUnmount = () => {
                this.storeUi.clearFormFeedback();
            }

            componentDidMount = () => {
                const updatedControls = {
                    ...this.state.formControls
                };
                let updatedFormElement = {};
                let formIsValid = true;
                
                Object.keys(this.state.formControls).map((key, i) => {
                    if (key !== 'relations') {
                        updatedFormElement = {
                            ...updatedControls[key]
                        };

                        updatedFormElement.value = this.state.formControls[key].value;
                        if (updatedFormElement.value !== null && updatedFormElement.value !== undefined) {
                            updatedFormElement.valid = validate(updatedFormElement.value, updatedFormElement.validationRules);
                        }
                        updatedControls[key] = updatedFormElement;
                    }
                })

                //SbC:: check if form is valid
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                this.setState({
                    formControls: updatedControls
                });
            }

            addRelation = (e) => {
                e.preventDefault();
                this.storeUi.clearFormFeedback();

                const updatedControls = {
                    ...this.state.formControls
                };

                let updatedFormElement = {
                    ...updatedControls['relations']
                };
                
                let relation = [{
                    firstname: {
                        value: '',
                        placeholder: i18n.t("forms.placeholder.firstname_relation"),
                        validationRules: {
                            isRequired: true
                        },
                    },
                    lastname: {
                        value: '',
                        placeholder: i18n.t("forms.placeholder.lastname_relation"),
                        valid: true
                    },
                    email: {
                        value: '',
                        placeholder: i18n.t("forms.placeholder.email_relation"),
                        touched: false,
                        validationRules: {
                            isRequired: true,
                            isEmail: true
                        },
                        required: true
                    }
                }];

                let arr = [];
                  
                Object.keys(updatedFormElement).map((key, i) => {
                    arr.push(updatedFormElement[key]);
                })
                updatedFormElement = arr.concat(relation);
                updatedControls['relations'] = updatedFormElement;

                this.setState({
                    formControls: updatedControls
                })
            }

            handleChange = (event, action) => {   
                this.storeUi.clearFormFeedback();

                const name = event.target.name;
                const value = event.target.value;

                const updatedControls = {
                ...this.state.formControls
                };
                const updatedFormElement = {
                ...updatedControls[name]
                };
                updatedFormElement.value = value;
                
                if (name !== 'captcha') {
                    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
                    updatedFormElement.touched = true;
                }

                if (name === 'captcha') {
                    if (value.length > 6) {
                        updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
                        updatedFormElement.touched = true;
                    } else {
                        updatedFormElement.valid = false;
                    }
                } 
        
                updatedControls[name] = updatedFormElement;

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                //SbC check if at least one valid email for relation
                formIsValid = this.state.formControls.relations[0].email.valid ? formIsValid : false;
                //SbC check if at least one valid firstname for relation
                formIsValid = this.state.formControls.relations[0].firstname.valid ? formIsValid : false;
                
                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                })

            }

            handleChangeRelation = (event, idx) => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls.relations[idx]
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
          
                updatedControls[name] = updatedFormElement;

                const allControls = {
                    ...this.state.formControls
                };
                allControls.relations[idx] = updatedControls;

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in this.state.formControls) {
                    if (this.state.formControls[inputIdentifier].required) {
                        formIsValid = this.state.formControls[inputIdentifier].valid && formIsValid;
                    }
                }

                //SbC check if at least one valid email for relation
                formIsValid = this.state.formControls.relations[0].email.valid ? formIsValid : false;
                //SbC check if at least one valid firstname for relation
                formIsValid = this.state.formControls.relations[0].firstname.valid ? formIsValid : false;

                this.setState({
                    formControls: allControls,
                    formIsValid: formIsValid,
                    btn_class: formIsValid ? '--active' : '--disabled'
                })
            }

            sendSendToFriendForm = async(e) => {
                e.preventDefault();

                if (this.state.formIsValid && this.state.formControls.relations[0].email.valid) {
                    const feedback = await this.storeUi.sendFormSendToFriend(this.state.formControls, this.storeBooks.current_book);
                    if ('success' in this.storeUi.form_feedback) {
                        this.clearSendToFriendForm();
                    }
                }
            }

            clearSendToFriendForm = () => {
                //SbC clear message
                const updatedControls = {
                    ...this.state.formControls
                };

                let name = 'message';
                let updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = '';
                updatedControls[name] = updatedFormElement;
                //end

                name = 'captcha';
                updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = '';
                updatedFormElement.touched = false;
                updatedFormElement.valid = false;
                updatedControls[name] = updatedFormElement;
                //end

                //SbC clear recievers
                updatedFormElement = [
                    ...updatedControls['relations']
                ];
                updatedFormElement.map((el, i) => {
                    let updatedFormEl = el;
                    for (let inputIdentifier in updatedFormEl) {
                        el[inputIdentifier].value = '';
                        el[inputIdentifier].valid = false;
                        el[inputIdentifier].touched = false;
                    }
                })
                updatedControls['relations'] = updatedFormElement;
                //end

                this.setState({
                    formControls: updatedControls,
                    formIsValid: false,
                    btn_class: '--disabled'
                })
            }

            render() {
                return (
                    <TemplateForms
                        type = 'sendtofriend'
                        msg_success = {i18n.t("form_feedback.success_review")}
                    >                        
                        <div className="form form--sendtofriend">
                            <form>                                
                                <p>Ontvanger(s):</p>
                                {this.state.formControls.relations && this.state.formControls.relations.map((relation, idx) => (
                                    <div className="form-row form-row--input">
                                    {Object.keys(relation).map((key, i) => (
                                        <div className="form-column" key={i}>
                                            <TextInput 
                                                name = {key} 
                                                placeholder = {relation[key].placeholder}
                                                value = {relation[key].value}
                                                onChange = {(e) => this.handleChangeRelation(e, idx)}
                                                touched = {this.state.formControls[key].touched}
                                                valid = {relation[key].valid}
                                            />
                                        </div>
                                    ))}
                                    </div>
                                ))}
                                <div className="form-row form-row--buttons">
                                    <button className="button button--tertiary button--text" onClick={(e) => this.addRelation(e)}>Voeg ontvanger toe</button>
                                </div>
                                
                                <p>Dit is wat de ontvanger te zien zal krijgen:</p>
                                <div className="form-row form-row--preview">
                                    <div className="form-column">
                                        <p>Beste (hier wordt automatisch de voornaam van de ontvanger ingevuld),</p>
                                        <p>Dit boek (of product) kwam ik tegen op Succesboeken.nl met de titel:<br/>
                                        <strong>{this.storeBooks.current_book && this.storeBooks.current_book.title}</strong>.</p>
                                        <p>Ik denk dat het ook interessant voor jou is.</p>
                                        <p>Op de website Succesboeken.nl vind je ook nog andere interessante boeken of producten.</p>
                                    </div>
                                </div>
                                
                                <p>Hieronder kun je dit bericht nog verder aanvullen:</p>
                                <div className="form-row form-row--input">
                                    <div className="form-column">
                                        <TextArea 
                                            name="message"
                                            placeholder = {this.state.formControls.message.placeholder}
                                            value = {this.state.formControls.message.value}
                                            onChange = {(e) => this.handleChange(e)}
                                            valid = {this.state.formControls.message.valid}
                                        />
                                    </div>
                                </div>
                                
                                <p>Met vriendelijke groet,</p>
                                {Object.keys(this.state.formControls).map((key, i) => (
                                    <React.Fragment>
                                        {key != 'message' && key != 'relations' && key != 'partner_code' && key != 'captcha' &&
                                            <div className="form-row form-row--input" key={i}>
                                                <div className="form-column">
                                                    <label className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}>{this.state.formControls[key].label}</label>
                                                </div>
                                                <div className="form-column">
                                                    <TextInput 
                                                        name = {key} 
                                                        placeholder = {this.state.formControls[key].placeholder}
                                                        value = {this.state.formControls[key].value}
                                                        onChange = {this.handleChange}
                                                        onBlur = {(e) => this.handleChange(e, 'blur')}
                                                        touched = {this.state.formControls[key].touched}
                                                        valid = {this.state.formControls[key].valid}
                                                    />
                                                </div>
                                            </div>
                                        }   

                                        {key == 'partner_code' &&
                                            <input type="hidden" name= {key} value={this.state.formControls[key].value} />
                                        }
                                    </React.Fragment>
                                ))}

                                <div className="form-row form-row--input form-row--input-captcha">
                                    Om geautomatiseerde (robot) e-mails te voorkomen, willen we weten of je echt een mens bent.
                                </div>
                                <div className="form-row form-row--input">
                                    <div className="form-column">
                                        <label className={"form-label --required"}>Vul hier de dag van vandaag in</label>
                                    </div>
                                    <div className="form-column">
                                        <TextInput 
                                            name = "captcha" 
                                            placeholder = {this.state.formControls["captcha"].placeholder}
                                            value = {this.state.formControls["captcha"].value}
                                            onChange = {this.handleChange}
                                            onBlur = {(e) => this.handleChange(e, 'blur')}
                                        />
                                    </div>
                                </div>
                                {!this.state.formControls.captcha.valid && this.state.formControls.captcha.touched &&
                                    <span className="form-field--error --pw">
                                        De dag van vandaag klopt nog niet.
                                    </span>
                                }
                                <div className="form-row form-row--input form-row--input-captcha">
                                    En geniet van deze dag!
                                </div>
                                
                                <div className="form-row form-row--buttons">
                                    {(this.state.formIsValid && this.state.btn_class === '--active')
                                    ?   <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.sendSendToFriendForm(e)}>{i18n.t("button.submit")}</button>
                                    :   <span className={"btn btn--primary " + this.state.btn_class}>{i18n.t("button.submit")}</span>
                                    }
                                </div>
                            </form>

                            {/* <div className="form__feedback">
                                {'success' in this.storeUi.form_feedback &&
                                    <span className="form__feedback--success">
                                        {i18n.t("form_feedback.success", {
                                            type: this.state.formControls.relations.length == 1 ? 'aanbeveling' : 'aanbevelingen', 
                                            count: this.state.formControls.relations.length
                                        })}
                                    </span>
                                }
                                {'error' in this.storeUi.form_feedback &&
                                    <span className="form__feedback--error">
                                        {i18n.t("form_feedback.error", {
                                            type: this.state.formControls.relations.length == 1 ? 'aanbeveling' : 'aanbevelingen',  
                                            count: this.state.formControls.relations.length
                                        })}
                                    </span>
                                }
                            </div> */}
                        </div>
                    </TemplateForms>  
                )
            }
        }
    )
)

export default withTranslation()(FormSendToFriend);
