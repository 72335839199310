import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import ImgCover from '../../elements/img-cover';
import BlockPreviewPdf from '../block/block-preview-pdf';

const BlockViewBookPreview = inject("stores") (
    observer (
        class BlockViewBookPreview extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    has_pdf: true,
                    sku: this.storeUi.banner.sku,
                    redirect: false
                }
            }

            handleOnClick = (e) => {
                const sku = this.storeUi.banner.sku;
                this.storeUi.setCurrentBook(sku);
                
                //this.storeBooks.getBookDetailsBySku(sku);
                
                this.setState({
                    redirect: true
                })
            }
  
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/book/" + this.storeUi.banner.sku} 
                    />;
                }

                return (
                    <div className="block block--view-pdf">
                        <h2>Lees het eerste hoofdstuk van ons nieuwe boek</h2>
                        <span  onClick={(e) => this.handleOnClick()}
                            target="_blank"
                            className="--link">
                            <ImgCover
                                sku = {this.storeUi.banner.sku}
                            />
                        </span>
                        {this.state.has_pdf &&
                            <div className="book-tools__tool">
                                <div className="book-tools__tool-link book-tools__tool-link--pdf">
                                    <a href={this.urlAPI + "/public/preview-pdf/" + this.storeUi.banner.sku + ".pdf"}
                                        target="_blank"
                                        className="link--pdf"
                                    >
                                        PDF PREVIEW
                                    </a>
                                </div>
                                <a 
                                    href={this.urlAPI + "/public/preview-pdf/" + this.storeUi.banner.sku + ".pdf"}
                                    target="_blank"
                                    className="--link"
                                >
                                    <span className="book-tools__tool-title" onClick={(e) => this.handleOnClick()}>{i18n.t("book_tools.pdf")}</span>
                                </a>
                            </div>
                        }
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockViewBookPreview);


