import React from 'react';

const Radio = props => {

    let formControl = "form-control";
    let direction = "--" + props.direction;

    return (
        <div className={"form-group form-group--radio " + direction}>
            {props.options.map(option => (
                <div className="form-group__option" key={option.value}>
                    <label>
                        <input type="radio"
                            name = {props.name}
                            value = {option.value}
                            onChange = {props.onChange}
                            className = {formControl}
                            defaultChecked = {props.defaultValue === option.value}
                        />
                        <span>{option.displayValue}</span>
                    </label>
                </div>
            ))}
        </div>
    );
}

export default Radio;