import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import i18n from "i18next";

import * as sort from '../../utilities/sort';

import BlockBook from '../block/block-book';
import BlockCover from '../block/block-cover';
import BlockListitem from '../block/block-listitem';

const BlockViews = inject("stores") (
    observer (
        class BlockViews extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirect: false,
                    view: this.props.view
                }

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
            }

            handleOnClick = (e, sku) => {
                this.storeUi.setCurrentBook(sku);
                this.setState({
                    redirect: true,
                    book_sku: sku
                });
            }

            renderView(view) {
                switch(view) {
                    case "summaries":
                    default:
                        return <BlockBook 
                            type = "summary"
                            book = {this.props.book}
                            sku = {this.props.book.sku}
                            page = {this.props.page}
                            author = {this.props.author}
                            has_details = {true}
                        />;
                    case "covers":
                        return  <BlockCover 
                            book = {this.props.book}
                            sku = {this.props.book.sku}
                            ranking = {this.props.ranking} 
                        />    
                    case "list":
                        return <BlockListitem 
                            book = {this.props.book}
                            sku = {this.props.book.sku}
                        />
                }
            }
              
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/book/" + this.state.book_sku} 
                    />;
                }

                return (
                    this.renderView(this.props.view)
                )
            }
        }
    )
)

export default BlockViews;

