import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockProductGroup = inject("stores") (
    observer (
        class BlockProductGroup extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeProducts = this.props.stores.storeProducts;

                this.state = {
                    subgroups_list: []
                }
            }

            async componentDidMount() {
                if (this.props.product_group_name == this.storeUi.active_product_group) {
                    const subgroupsList = await this.storeProducts.getProductSubGroups(this.props.product_group.name);
                    this.setState({
                        subgroups_list: subgroupsList
                    })
                }
            }

            componentDidUpdate = async (prevProps) => {
                if (prevProps.active_group !== this.props.active_group) {
                    if (this.props.product_group_name === this.storeUi.active_product_group) {
                        const subgroupsList = await this.storeProducts.getProductSubGroups(this.props.product_group.name);
                        this.setState({
                            subgroups_list: subgroupsList
                        })
                    } 
                }
            }

            handleOnClickArrow = (e, id, cat_name) => {
                e.preventDefault();
                this.setState({
                    is_active: !this.state.is_active
                })
            }

            handleOnClickSubGroup = async (e, subgroup_name) => {
                this.storeUi.setActiveSubGroup(subgroup_name);    
                this.storeUi.setNavMobile(false);
                this.storeUi.setPageView('page', 1);
             
                let url = "/products/" + subgroup_name;
                this.props.history.push(url);
            }

            render() {
                return (
                    <React.Fragment>
                        {this.props.product_group_name === this.storeUi.active_product_group &&
                            <React.Fragment>
                                <ul className="block--product-groups__subgroups">
                                    {this.state.subgroups_list.map((subgroup, i) => ( 
                                        <li 
                                            className={" block--product-groups__subgroup" + (subgroup.name == this.storeUi.active_subgroup ? ' --active' : '')}
                                            onClick={(e) => this.handleOnClickSubGroup(e, subgroup.name)}
                                        >
                                            {subgroup.name}
                                        </li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockProductGroup));

