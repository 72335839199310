import * as apifetch from './fetch.js';

export async function checkShopForCategory(cat) {
    const urlEndpoint = '/catalog/checkShopForCategory.php';

    const postData = {
        category: cat
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkShopForSubCategory(cat) {
    const urlEndpoint = '/catalog/checkShopForSubCategory.php';

    const postData = {
        subcategory: cat
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getCategories(shop) {
    const urlEndpoint = '/catalog/getCategories.php';

    const postData = {
        shop: shop
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getCategoriesPopular() {
    const urlEndpoint = '/v2/categories/popular';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getMainCategory(shop, cat) {
    const urlEndpoint = '/catalog/getMainCategory.php';

    const postData = {
        shop: shop,
        cat: cat
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getSubCategories(shop, cat_name) {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/catalog/getSubCategories.php';

    const postData = {
        shop: shop,
        cat_name: cat_name
    }

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error subcategories: ', error.message);
    }
}

// export async function getMediaGroups() {
//     const urlAPI = process.env.REACT_APP_URL_API;
//     const urlEndpoint = urlAPI + '/products/getProductGroups.php';

//     try {
//         let apiStr = urlEndpoint;
//         let response = await fetch(apiStr, {
//             method: 'GET',
//             headers: new Headers({
    
//             }),
//         });
//         let responseJson = await response.json();
//         if(responseJson !== null) {
//             let arrData = [];
//             arrData = responseJson;

//             return arrData;
//         }
//     } catch (error) {
//         console.log('SbC fetch error media groups: ', error.message);
//     }
// }




