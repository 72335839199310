import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const BookRanking = inject("stores") (
    observer (
        class BookRanking extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    ranking: false
                }
            }

            componentDidMount = () => {
                const ranking = this.findRanking(this.props.sku);
                this.setState({
                    ranking: ranking,
                    has_ranking: ranking == 0 ? false : true
                })
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.sku !== this.props.sku) {
                    const ranking = this.findRanking(this.props.sku);
                    this.setState({
                        ranking: ranking,
                        has_ranking: ranking == 0 ? false : true
                    })
                }
            }

            findRanking = () => {
                const indexOfObject = this.storeBooks.bookList_top60.findIndex((obj) => {
                    if (obj && obj.sku === this.props.sku) {
                        return true;
                    }
                    return false;
                });
                return (indexOfObject + 1);
            }

            render() {
                if (!this.state.ranking || !this.storeUi.checkFeature('SHOP_ALL')) {
                    return null;
                }

                return (
                    this.state.ranking && this.state.ranking !== 0 &&
                        <span className="list--ranking__ic--rank">{this.state.ranking}</span>
                )
            }
        }
    )
)

export default BookRanking;