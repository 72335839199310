import React, { Component } from 'react';

class SocialLink extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <div className={"social-link social-link--" + this.props.medium + " --link"} onClick={this.props.handleSocialLink}></div>
        )
    }
}

export default SocialLink;
