import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import {withRouter} from 'react-router';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Template from '../templates/template';
import ContentText from '../components/content/content-text';

import BlocksBooks from '../components/blocks/blocks-books';

const TabCompany = inject("stores") (
    observer (
        class TabCompany extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    view: this.props.view
                }

                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
                this.storeUi.getBookCountForBooksSB('SHOP_SB', this.storeUi.page_view);
            }

            renderView(view) {               
                switch(view) {
                    case "our_books":
                    default:
                        return (
                            <div className="content-wrapper__segment">
                                <p>Van onze eigen uitgaven is altijd een inkijkexemplaar van ten minste het eerste hoofdstuk beschikbaar.</p>
                                                              
                                <BlocksBooks 
                                    blockTitle = {i18n.t("blocks.own_books.header")}
                                    type = "sb"
                                    page = "our-publications"
                                />
                            </div>
                        )
                    case "tips":
                        return (
                            <div className="content-segment">
                                <ContentText
                                    page = {"tip-book"}
                                />
                            </div>
                        ) 
                    case "own_book":
                        return (
                            <div className="content-wrapper__segment">
                                <ContentText
                                    page = {"own-book"}
                                />
                            </div>
                            
                        )
                }
            }
              

            render() {
                return (
                    this.renderView(this.props.view)
                )
            }
        }
    )
)

const OurPublications = inject("stores") (
    observer (
        class OurPublications extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.controller = new AbortController();
                this.signal = this.controller.signal;

                this.state = ({
                    view: "our_books",
                    scroll: true
                })

                this.storeUi.setActiveMainNav(0);
                this.storeUi.setActiveToggleNav(1);
            }

            componentDidMount = async() => {
                this.storeUi.setPageView('inview', 250);
                const page_view = this.storeUi.page_view;
                const books = this.storeBooks.getBooksWithAbort('SHOP_SB', page_view, this.controller, this.signal);

                this.setState({
                    query: books
                })
                books.then((result) => {  
                    this.storeBooks.setBookListSb(result.data);
                    // this.setState({
                    //     bookList: result.data
                    // })
                });

                this.handleScroll();
                if ( this.props.match.params.tab !== undefined) {
                    this.setState({
                        view: this.props.match.params.tab
                    });
                }
            }

            componentDidUpdate = (prevProps) => {
                if (this.state.scroll) {
                    this.handleScroll();
                }
                if (prevProps.match.params.tab !== this.props.match.params.tab) {
                    this.setState({
                        view: this.props.match.params.tab
                    });
                } 

                this.storeUi.setFreezeScrolling(false);
            }

            componentWillUnmount = () => {
                this.state.query.cancel();
                this.storeUi.setPageView('inview', 25);

                const position = window.pageYOffset;
                this.storeUi.setFreezePos(position);
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }

            handleContentTab = (e, view) => {
                this.storeUi.setActiveToggleNav(1);

                let tab = "/our-publications/" + view;
                this.props.history.push(tab);
                this.setState({
                    view: view,
                    scroll: false
                })
            }
      
            render() {
                const Li = (view) => {
                    const activeClass = ((view.view === this.state.view) || (view.view == 'our_books' && this.state.view == undefined)) ? ' --active' : '';
                    return (
                        <li className={"content-tabs__listitem content-tabs__listitem--" + view.view + activeClass} onClick={(e) => this.handleContentTab(e, view.view)}>
                            <span>{i18n.t("tabs." + view.view)}</span>
                        </li>
                    )
                }

                return (
                    <Template
                        type = {'default'}
                        header = {"books_sb"}
                        expanded = {["authors"]}
                        nav_name = {"books_sb"}
                        useScroll = {true}
                    >
                        <div className="content-segment">
                            <ContentText
                                page = {"our-company"}
                            />
                        </div>

                        <div className="content-segment">
                            <div className="segment__content">
                                <div className="book__tabs">
                                    <ul className="content-tabs__list">
                                        <Li 
                                            view = 'our_books'
                                        />
                                        <Li 
                                            view = 'tips'
                                        />
                                        <Li 
                                            view = 'own_book'
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="content-segment">
                            <TabCompany
                                view = {this.state.view}
                            />
                        </div>                               
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(OurPublications);
