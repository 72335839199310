import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import BlockVideo from '../../components/block/block-video';

const BlockViewVideo = inject("stores") (
    observer (
        class BlockViewVideo extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    filename: "",
                    playing: false
                }
            }

            componentDidMount = async() => {
                const idxPreviewVideo = this.storeUi.settings_list['teaserPreviewVideo']
                const video = await this.storeUi.getVideoById(idxPreviewVideo);

                this.setState({
                    video: video[0]
                })
            }
  
            render() {
                return (
                    <div className="block block--view-video">
                        <h2>Heb je nog even tijd voor onderstaande video?</h2>
                        <div className="blocks__content">
                            <div className="wrapper-media__player--video">
                                {this.state.video &&
                                    <BlockVideo
                                        video = {this.state.video}
                                        isDialogVideoOpen = {this.storeUi.isDialogVideoOpen}
                                        active_video = {this.storeUi.active_video}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(BlockViewVideo);


