import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import moment from "moment";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import AccountCommissionsOverview from './account-commissions-overview';
import AccountCommissionsPayouts from './account-commissions-payouts';

const AccountCommissions = inject("stores") (
    observer (
        class AccountCommissions extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
                this.storePartners = this.props.stores.storePartners;

                this.state = {
                    commission_current: this.storeUser.user_data.commission,
                    commission_3party_current: this.storeUser.user_data.commission_3party
                }
            }

            componentDidMount = async() => {
                const partnerFees = await this.storePartners.getPartnerFees();

                let commission_current;
                if (isNaN(this.storeUser.user_data.commission_current) || this.storeUser.user_data.commission_current === null) {
                    commission_current = this.storeUser.user_data.commission;
                } else {
                    commission_current = this.storeUser.user_data.commission_current;
                }

                let commission_3party_current;
               if (isNaN(this.storeUser.user_data.commission_3party_current) || this.storeUser.user_data.commission_3party_current === null) {
                    commission_3party_current = this.storeUser.user_data.commission_3party;
                } else {
                    commission_3party_current = this.storeUser.user_data.commission_3party_current;
                }

                this.setState({
                    commission_current: commission_current,
                    commission_3party_current: commission_3party_current
                })
            }

            componentDidUpdate = (prevProps) => {

            }

            render() {
                const vat_nr = this.storeUser.user_data.profile.nrVAT;

                return (
                    <React.Fragment>
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <span>Partner code: {this.storeUser.user_data.partner_code}</span>
                                <span><strong>Vergoedingen over:</strong></span>
                                <ul>
                                    <li>Eigen uitgaven van Succesboeken.nl: {this.state.commission_current}%. 
                                        <Link 
                                            to="/our-publications"
                                            className="link--read-more"
                                        >Zie hier de lijst met eigen uitgaven</Link>
                                    </li>
                                    <li>Uitgaven van derden: {this.state.commission_3party_current}%</li>
                                    {/* <li>Voor een aantal producten geldt een afwijkend percentage</li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="content-segment">
                            <div className="content-segment__content">  
                                {vat_nr !== ''
                                    ?   <span>Bedragen worden getoond inclusief btw omdat het btw-nummer is ingevuld bij je zakelijke gegevens (zie tab <Link to="/account/profile">Mijn gegevens</Link>)</span>
                                    :   <span>Bedragen worden getoond exclusief btw omdat er geen btw-nummer is ingevuld bij je zakelijke gegevens (zie tab <Link to="/account/profile">Mijn gegevens</Link>)</span>
                                }
                            </div>
                        </div>
                        
                        <AccountCommissionsOverview

                        />

                        <AccountCommissionsPayouts

                        />
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(AccountCommissions);


