import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import MetaTags from 'react-meta-tags';

import Template from '../../templates/template';
import FormContact from '../../components/forms/form-contact';

const Contact = inject("stores") (
    observer (
        class Contact extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount() {
                window.scrollTo(0, 0)
            }
      
            render() {
                return (
                    <Template
                        type = {'single-column'}
                        header = {"contact"}
                    >
                        <MetaTags>
                            <title>
                                Contact
                            </title>
                            <meta 
                                name="description" 
                                content="Neem contact met ons op" 
                            />
                            <meta 
                                property="og:title" 
                                content = 'Succesboeken.nl | Contact' 
                            />
                            <meta 
                                property="og:image" 
                                content="https://www.succesboeken.nl/api/public/images/branding/sb_logo-img.png" 
                            />
                            <link 
                                rel="canonical" 
                                href = "https://www.succesboeken.nl/remarks" 
                            />
                        </MetaTags>
                                
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <FormContact />
                            </div>
                        </div>                           
                    </Template>
                )
            }
        }
    )
)

export default Contact;
