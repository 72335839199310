import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import ImgCoverRecently from '../../elements/img-cover-recently';

const BlocksRecentlyViewed = inject("stores") (
    observer (
        class BlocksRecentlyViewed extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeHistory = this.props.stores.storeHistory;
                this.storeUser = this.props.stores.storeUser;
            }

            render() {
                let listRecentlyViewed;
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                if (id !== undefined && id !== 0) {
                    listRecentlyViewed = this.storeHistory.listRecentlyViewed.user;
                } else {
                    listRecentlyViewed = this.storeHistory.listRecentlyViewed.anonymous;
                }

                return (
                    listRecentlyViewed.length > 0 &&
                        <div className="blocks blocks--books blocks__list--recently-viewed">
                            <div className="blocks__blocks-title">
                                <div className="blocks__blocks-title--main">
                                    {i18n.t("blocks.recently_viewed.header")}
                                </div>
                            </div> 
                            <div className="blocks__content">
                                <ul className="blocks__list blocks__list--covers blocks__list--covers-foldout --collapsed ">
                                    {listRecentlyViewed.map((book, i) => ( 
                                        <ImgCoverRecently
                                            key = {book.sku}
                                            sku = {book.sku}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlocksRecentlyViewed));

