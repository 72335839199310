import React, { Component } from 'react';
import { reaction, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import RowText from '../form-rows/row-text';
import FormFeedbackRequired from '../../elements/forms/form-feedback-required';

const FormLogin = inject("stores") (
    observer (
        class FormLogin extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeWishlist = this.props.stores.storeWishlist;
                this.storeForms = this.props.stores.storeForms;
                this.storePartners = this.props.stores.storePartners;

                this.state = {
                    formControls: {          
                        email: {
                            type: "email",
                            value: '',
                            validationRules: {
                                isRequired: true,
                                isEmail: false
                            },
                            valid: false
                        },
                        password: {
                            type: "password",
                            value: '',
                            validationRules: {
                                isRequired: true
                            },
                            valid: false
                        }
                    },
                    formIsValid: false,
                    btn_class: '--disabled'
                }
            }

            validateRow = (name, value, valid) => {  
                const updatedControls = this.storeForms.validateRow(this.state.formControls, name, value, valid);
                this.storeUi.clearFormFeedback();
                
                if (name == 'email') {
                    this.props.rememberEmail(value);
                }

                this.setState({
                    formControls: updatedControls,
                }, () => this.validateForm()); 
            }

            validateForm = () => {
                const formIsValid = this.storeForms.validateForm(this.state.formControls);

                this.setState({
                    formIsValid: formIsValid,
                    btn_class: formIsValid 
                        ?   '--active' 
                        :   '--disabled'
                });
            }

            loginUser = async(e) => {
                //if (this.state.formIsValid) {
                    let login = await this.storeAuth.checkLogin(this.state.formControls);
                    if (!('error' in login)) {
                        this.storeUser.setLoginState(true);
                        this.storeOrder.setClientId(login.id);
                        this.storeUser.setUserData(login);
                        this.storeAuth.setAuth(login);
                        this.storeOrder.setOrderProfileFromUser(login);
                        if ((login.is_partner === 'on' || login.is_partner === 1) && login.partner_code !== '') {
                            this.storeUi.setPartnerCode(login.partner_code);
                            this.storeOrder.setAffiliateId(login.partner_code);
                        } 
                        this.storeUi.getUserDiscounts();

                        if (this.storeUi.checkFeature('SHOP_ALL')) {
                            this.storeUi.setFeatureAll(true);
                        }
                        
                        this.storeWishlist.retrieveWishlistFromDB(login.id);

                        if (this.storeUi.app_interface.use_case === "inshop") {
                            //this.storeOrder.retrieveShoppingCartFromDB(login.id, 'login');
                        }

                        this.storePartners.getPartnerFees();
                        
                        this.props.onNext();

                    } else {
                        this.storeUser.setLoginState(false);
                        this.storeAuth.setLoginState(false);

                        this.storeUi.setFormFeedback('error', login.error);
                        this.storeUi.openDialogFeedbackForm('vraag/opmerking');
                    }
                //}
            }

            render() {

                return (
                    <div className="form-wrapper">
                        <FormFeedbackRequired />
                        
                        <div className="form-fieldset">
                            <form className="form">
                                {Object.keys(this.state.formControls).map((key, i) => (
                                    <React.Fragment>
                                        {(this.state.formControls[key].type === "text" || this.state.formControls[key].type === "email" || this.state.formControls[key].type === "password") &&
                                            <RowText 
                                                type = {this.state.formControls[key].type}
                                                name = {key}
                                                value = {this.state.formControls[key].value}
                                                validationRules = {this.state.formControls[key].validationRules}
                                                onValidate = {this.validateRow}
                                            />
                                        }
                                    </React.Fragment>
                                ))}
                            </form>
                            
                            <div className="form-row form-row--buttons">
                                <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.loginUser(e)}>{i18n.t("button.login")}</button>
                            </div>
                            
                            {'error' in this.storeUi.form_feedback && (
                                <div className="form-row form-row--error">
                                   <p 
                                        dangerouslySetInnerHTML={{ __html: i18n.t("errors.error_login")}}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormLogin);
