import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as catalog from '../communicator/catalog';

class StoreCatalog {

    constructor() {
    }

    async getNotifications() {
        let notifications = [];

        try {
            const returnData = await catalog.getNotifications();
            runInAction(() => {
                notifications = returnData.data;
                return notifications;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return notifications;
    }
}

decorate(StoreCatalog, {
    getNotifications: action
})

export default StoreCatalog;