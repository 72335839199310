import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import BlockLoggedIn from '../block/block-loggedin';
import BlockCategories from '../block/block-categories';
import BlockOtherLanguages from '../block/block-other-languages';
import BlockProductGroups from '../block/block-product-groups';
import BlockAuthors from '../block/block-authors';

const SidebarLeft = inject("stores") (
    observer (
        class SidebarLeft extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                }

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {

            }

            componentDidUpdate = () => {

            }
  
            render() {
                return (
                    <div className="content-wrapper__sidebar content-wrapper__sidebar--left">
                        <BlockLoggedIn 
                        />

                        <BlockCategories
                            //expanded = {this.props.expanded && this.props.expanded.indexOf('categories') !== -1 ? true : false} 
                            shop = {this.storeGeneral.shop}
                            expanded = {true}
                            active_togglenav = {this.storeUi.active_togglenav}
                        />
                        <BlockOtherLanguages 
                            expanded = {true}
                        />
                          
                        {(this.storeGeneral.shop !== 'SHOP_ALL') &&
                            <React.Fragment>
                                {(this.props.template == "default" || this.props.template == "error") &&
                                    <React.Fragment>
                                        <BlockProductGroups 
                                            //expanded = {this.props.expanded && this.props.expanded.indexOf('products') !== -1 ? true : false} 
                                            expanded = {true}
                                        />
                                        <BlockAuthors 
                                            clean = {false}
                                            //expanded = {this.props.expanded && this.props.expanded.indexOf('authors') !== -1 ? true : false}
                                            expanded = {true}
                                        />
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </div>
                )
            }
        }
    )
)

export default SidebarLeft;
