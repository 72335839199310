import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import BlocksCategories from '../components/blocks/blocks-categories';

import BlocksBooks from '../components/blocks/blocks-books';

const Genre = inject("stores") (
    observer (
        class Genre extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    genre_name: "",
                    genre_id: null
                }
                
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            async componentDidMount() {
                const idx = this.storeBooks.genresList.findIndex(genre => genre.name === this.props.match.params.name)

                let str = this.props.match.params.name;

                if (this.storeBooks.genresList.length > 0) {
                    this.setState({
                        genre_name: str.toLowerCase(),
                        genre_id: this.storeBooks.genresList[idx].id
                    }) 
                }
            }
      
            render() {
                return (
                    <div className="content-wrapper">
                        <div className="content-wrapper__content">
                            <div className="content-wrapper__segments">
                                <div className="content-wrapper__segment">
                                <BlocksBooks 
                                        blockTitle = {i18n.t("top_books_genre.header") + this.state.genre_name}
                                        type = "genre"
                                        top = {true}
                                        page = "genre"
                                    />
                                </div>

                                <div className="content-wrapper__segment">
                                    <BlocksCategories 
                                        blockTitle = {i18n.t("blocks_books_in_genre.cats") + this.state.genre_name} 
                                        genre_id = {this.state.genre_id}
                                    />
                                        
                                </div>

                                <div className="content-wrapper__segment">
                                    <BlocksBooks 
                                        blockTitle = {i18n.t("blocks.books_in_genre.header") + this.state.genre_name}
                                        type = "genre"
                                        page = "genre"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default Genre;
