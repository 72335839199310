import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import Template from '../templates/template';

import BlockCatsPopular from '../components/block/block-cats-popular';
import BlockLatestUpdate from '../components/block/block-latest-update';
import BlocksNewsletter from '../components/blocks/blocks-newsletter';
import BlocksRecentlyViewed from '../components/blocks/blocks-recently-viewed';
import BlockQuote from '../components/block/block-quote';
import CarouselHome from '../components/content/carousel-home';
import BlocksTop60 from '../components/blocks/blocks-top60';
import BlocksTop10 from '../components/blocks/blocks-top10';
import BlockBooksPromo from '../components/block/block-books-promo';

import * as ui from '../utilities/ui';
import BlockAnkeilerNewsletter from '../components/block/block-ankeiler-newsletter';

const Start = inject("stores") (
    observer (
        class Start extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    redirectISBN: false,
                    redirectPayment: false,
                    id_quote: 0
                }
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeOrder = this.props.stores.storeOrder;
            }

            componentDidMount = () => {
                if (this.storeGeneral.shop === '') {
                    this.storeGeneral.setShop('SHOP_SB');
                }

                this.storeUi.setActiveMainNav(0);
                if (this.storeGeneral.activeShop === 'SHOP_SB') {
                    this.storeUi.setActiveToggleNav(2);
                } else {
                    this.storeUi.setActiveToggleNav(3);
                }
                
                this.storeUi.setUseCase("inshop");
                this.storeOrder.setPartnerCode(this.props.location.search);
                this.redirectParams(this.props.location.search);

                ui.handleScroll('top');

                this.getCarousel();
            }

            componentDidUpdate = () => {
                this.redirectParams(this.props.location.search);
            }

            redirectParams(searchString) {
                const params = queryString.parse(searchString)
                
                //SbC add more complex redirects 
                if (params.isbn) {
                    this.setState({
                        redirectISBN: true,
                        isbn: params.isbn
                    });
                }

                if (params.ISBN) {
                    this.setState({
                        redirectISBN: true,
                        isbn: params.ISBN
                    });
                }

                let pc;
                if (params.pc) {
                    pc = params.pc.trim();
                    this.storeOrder.setAffiliateId(pc);
                }
                if (params.PC) {
                    pc = params.PC.trim();
                    this.storeOrder.setAffiliateId(pc);
                }

                if (params.guid) {
                    this.setState({
                        redirectPayment: true,
                        guid: params.guid
                    });
                }

                if (params.quote) {
                    this.setState({
                        id_quote: params.quote
                    });
                }
            }

            getCarousel() {
                let value = this.storeUi.getCarousel();
                this.storeUi.setCarousel(value); 
            }

            goPause = async (e) => {
                this.storeUi.setCarousel('pause'); 
            }

            goPlay = (e) => {
                this.storeUi.setCarousel('play'); 
            }

            render() {
                if (this.state.redirectISBN) {
                    return <Redirect 
                        push to={"/book/" + this.state.isbn} 
                    />;
                }

                if (this.state.redirectPayment) {
                    return <Redirect to={{
                            pathname: '/payment-result',
                            state: { guid: this.state.guid }
                        }}
                    />;
                }

                return (                  
                    <Template
                        type = 'default'
                        expanded = {["categories", "products"]}
                    >   
                        <React.Fragment>
                            {/* <BlockNotification 

                            /> */}
                            <BlockLatestUpdate 

                            />

                            {this.storeGeneral.shop !== 'SHOP_ALL' &&
                                <BlockQuote 
                                    id = {this.state.id_quote}
                                    quote = {this.storeUi.getQuote(this.state.id_quote)}
                                />
                            }

                            {/* {this.storeGeneral.shop === 'SHOP_SB' && (
                                this.storeUi.checkFeature('SHOP_ALL') &&
                                    <div className="content-wrapper__segment">
                                        <BlockSelectSegment

                                        />
                                    </div>
                            )} */}

                            {this.storeGeneral.shop === 'SHOP_SB' && (
                                <div className="content-wrapper__segment">
                                    <BlockBooksPromo
                                
                                    />
                                </div>
                            )}

                            {this.storeGeneral.shop === 'SHOP_SB' && (
                                <React.Fragment>
                                    <div className="content-wrapper__segment">
                                        <BlockAnkeilerNewsletter />
                                    </div>

                                    <div className="content-wrapper__segment">
                                        <BlocksNewsletter />
                                    </div>
                                </React.Fragment>
                            )}

                            {this.storeGeneral.shop === 'SHOP_ALL' &&
                                <React.Fragment>
                                    <div className="content-wrapper__segment">
                                        <Suspense fallback={<p>loading..</p>}>
                                            <BlocksTop60 />
                                        </Suspense>
                                    </div>

                                    <div className="content-wrapper__segment">
                                        <BlocksTop10 
                                            tag = {"top10thrillers"}
                                            type = {"foldout"}
                                        />
                                    </div>
                                </React.Fragment>
                            }

                            {/* 
                                <div className="content-wrapper__segment">
                                    <BlocksGenres />
                                </div>
                             */}

                            {/* 
                                <div className="content-wrapper__segment">
                                    <BlocksCategories 
                                        blockTitle = {i18n.t("blocks.categories.header")}
                                    />
                                </div> 
                            */}

                            {this.storeUi.checkFeature('SHOP_ALL') &&
                                <div className="content-wrapper__segment content-wrapper__segment--popular">
                                    <BlockCatsPopular />
                                </div>
                            }

                            <div className="content-wrapper__segment">
                                <BlocksRecentlyViewed />
                            </div>
                          
                        </React.Fragment>
                    </Template>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(Start));
