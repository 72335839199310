import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

const DialogNavFoldout = inject("stores") (
    observer (
        class DialogNavFoldout extends Component {

            constructor(props) {
                super(props);

                this.props = props;
                this.storeUi = this.props.stores.storeUi;
            }

            componentDidMount = () => {
                document.addEventListener("keydown", this.escFunction, false);
            }
            
            componentWillUnmount = () => {
                document.removeEventListener("keydown", this.escFunction, false);
            }

            escFunction = (event) => {
                if (event.keyCode === 27) {
                    this.storeUi.showTooltip('tip', 0);
                    this.storeUi.showTooltip('explanation', 0);
                }
            }

            render() {
                // if ((this.props.type == 'tip' && !this.storeUi.toggle_nav[this.props.id - 1].showTip) ||
                //     (this.props.type == 'explanation' && !this.storeUi.toggle_nav[this.props.id - 1].showExplanation)) {
                //     return null;
                // }

                if ((this.props.type == 'tip' && !this.storeUi.toggle_nav[this.props.id - 1].showTip)) {
                //     (this.props.type == 'explanation' && !this.storeUi.toggle_nav[this.props.id - 1].showExplanation)) {
                    return null;
                }

                return (
                    <div 
                        className = {"content__tooltip-dialog" + (this.props.mobile ? " content__tooltip-dialog--mobile" : "")}
                    >
                        <div 
                            className="dialog-header"
                            dangerouslySetInnerHTML={{ __html: this.storeUi.toggle_nav[this.props.id - 1].title}}
                        />
                        {this.props.type == 'tip' &&
                            <div 
                                //className = "dialog-content" 
                                dangerouslySetInnerHTML={{ __html: this.storeUi.toggle_nav[this.props.id - 1].textLong}}
                            />
                        }
                        {this.props.type == 'explanation' &&
                            <div 
                                className="dialog-content"
                                dangerouslySetInnerHTML={{ __html: this.storeUi.toggle_nav[this.props.id - 1].textLong}}
                            />
                        }
                    </div>
                )
            }
        }
    )
)

export default DialogNavFoldout;
