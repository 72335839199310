import React, { Component } from 'react';
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import * as account from '../../communicator/account';
import validate from '../../utilities/validation-rules';

import TextInput from '../../elements/form-input-text';
import Select from '../../elements/form-input-select';

const FormAddress = inject("stores") (
    observer (
        class FormAddress extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    formControls: { 
                        delivery_name: {
                            label: i18n.t("forms.label.delivery_name"),
                            value: this.storeOrder.order_data.delivery_address.delivery_name,
                            placeholder: i18n.t("forms.placeholder.delivery_name"),
                            validationRules: {
                                isRequired: true,
                            },
                            required: true
                        },
                        delivery_company: {
                            label: i18n.t("forms.label.delivery_company"),
                            value: this.storeOrder.order_data.delivery_address.delivery_company,
                            placeholder: i18n.t("forms.placeholder.delivery_company")
                        },
                        address: {
                            label: i18n.t("forms.label.address"),
                            value: this.storeOrder.order_data.delivery_address.address,
                            placeholder: i18n.t("forms.placeholder.delivery_address"),
                            validationRules: {
                                isRequired: true,
                            },
                            required: true
                        },
                        housenr: {
                            label: i18n.t("forms.label.housenr"),
                            value: this.storeOrder.order_data.delivery_address.housenr,
                            placeholder: i18n.t("forms.placeholder.housenr"),
                            validationRules: {
                                isRequired: true,
                            },
                            required: true
                        },
                        addition: {
                            label: i18n.t("forms.label.addition"),
                            value: this.storeOrder.order_data.delivery_address.addition,
                            placeholder: i18n.t("forms.placeholder.addition")
                        },
                        zipcode: {
                            label: i18n.t("forms.label.zipcode"),
                            value: this.storeOrder.order_data.delivery_address.zipcode,
                            placeholder: i18n.t("forms.placeholder.delivery_zipcode"),
                            validationRules: {
                                isRequired: true,
                            },
                            required: true
                        },
                        city: {
                            label: i18n.t("forms.label.city"),
                            value: this.storeOrder.order_data.delivery_address.city,
                            placeholder: i18n.t("forms.placeholder.delivery_city"),
                            validationRules: {
                                isRequired: true,
                            },
                            required: true
                        },
                        country_select: {
                            label: i18n.t("forms.label.country"),
                            value: this.storeOrder.order_data.delivery_address.country_code !== '' && this.storeOrder.order_data.delivery_address.country_code !== undefined && this.storeOrder.order_data.delivery_address.country_code !== null
                                ?   this.storeOrder.order_data.delivery_address.country_code
                                :   'NL',
                            placeholder: i18n.t("forms.placeholder.country"),
                            touched: false,
                            validationRules: {
                                //isRequired: true,
                            },
                            options: this.storeOrder.countriesList
                        }
                    },
                    formIsValid: false
                }
            }

            componentDidMount = () => {
                const updatedControls = {
                    ...this.state.formControls
                };
                let updatedFormElement = {};

                Object.keys(this.state.formControls).map((key, i) => {
                    updatedFormElement = {
                        ...updatedControls[key]
                    };
                    updatedFormElement.value = this.state.formControls[key].value;
                    if (updatedFormElement.value !== null && updatedFormElement.value !== undefined) {
                        updatedFormElement.valid = validate(updatedFormElement.value, updatedFormElement.validationRules);
                    }
                    updatedControls[key] = updatedFormElement;
                });

                //SbC:: check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid ? formIsValid : false;
                    }
                }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid
                }, () => this.props.handleFormChange(updatedControls, formIsValid, 'address'));
            } 

            componentDidUpdate = (prevProps) => {
                if (prevProps.toggle !== this.props.toggle) {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    let updatedFormElement = {};

                    Object.keys(this.state.formControls).map((key, i) => {
                        updatedFormElement = {
                            ...updatedControls[key]
                        };
                        if (key !== 'country_select') {
                            updatedFormElement.value = '';
                        }
                        if (key === 'country_select') {
                            updatedFormElement.value = 'NL';
                        }
                        if (updatedFormElement.value !== null && updatedFormElement.value !== undefined) {
                            updatedFormElement.valid = validate(updatedFormElement.value, updatedFormElement.validationRules);
                        }
                        updatedControls[key] = updatedFormElement;
                    });

                    let formIsValid = true;
                    for (let inputIdentifier in updatedControls) {
                        if (updatedControls[inputIdentifier].required) {
                            formIsValid = updatedControls[inputIdentifier].valid ? formIsValid : false;
                        }
                    }

                    this.setState({
                        formControls: updatedControls,
                        formIsValid: formIsValid
                    }, () => this.props.handleFormChange(updatedControls, formIsValid, 'address'));
                }
            }

            handleChange = event => {     
                const name = event.target.name;
                const value = event.target.value;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
          
                updatedControls[name] = updatedFormElement;

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        if (!updatedControls[inputIdentifier].valid) {
                            formIsValid = false;
                        }
                    }
                }
       
                this.setState({
                  formControls: updatedControls,
                  formIsValid: formIsValid
                }, () => this.props.handleFormChange(updatedControls, formIsValid, 'address'));
            }
          
            render() {
                return (
                    <form className="form">
                        {Object.keys(this.state.formControls).map((key, i) => (
                            <React.Fragment>
                                {key !== "country_select" &&
                                    <div className="form-row form-row--input">
                                        <label className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}>{this.state.formControls[key].label}</label>
                                        <TextInput 
                                            name = {key} 
                                            placeholder = {this.state.formControls[key].placeholder}
                                            value = {this.state.formControls[key].value}
                                            onChange = {this.handleChange}
                                            valid = {this.state.formControls[key].valid}
                                        />
                                    </div>
                                }

                                {key === "country_select" &&
                                    <div className="form-row form-row--select">
                                        {this.state.formControls['country_select'].label}
                                        <Select 
                                            name="country_select"
                                            value = {this.state.formControls.country_select.value}
                                            options = {this.state.formControls.country_select.options ? this.state.formControls.country_select.options : []}
                                            touched = {this.state.formControls.country_select.touched}
                                            onChange = {this.handleChange}
                                            valueSelected = {this.state.formControls.country_select.value}
                                            defaultValue = {this.state.formControls.country_select.value}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        ))}
                    </form>  
                )
            }
        }
    )
)

export default FormAddress;
