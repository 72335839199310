import * as apifetch from './fetch.js';

export async function getCategoryDetails(sku) {
    const urlEndpoint = '/catalog/getCategoryDetails.php';

    const postData = {
        shop: 'SHOP_ALL',
        sku: sku
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData)
}


