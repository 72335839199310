import React, { Component } from 'react';
import i18n from "i18next";

class FormTextConditional extends Component {
    render() {
        return (
            <div className="form-row form-row--help">
                <span className="form-row--help__text">{i18n.t("form_suggestions.conditional")}</span>
            </div>
        )
    }
}

export default FormTextConditional;
