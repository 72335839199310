import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const BlockCategory = inject("stores") (
    observer (
        class BlockCategory extends Component {
            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCatalogs = this.props.stores.storeCatalogs;

                this.state = {
                    subcats_list: [],
                    subcats: ''
                }
            }

            async componentDidMount() {
                if (this.props.type === 'products') {
                    try {
                        const subgroupsList = await this.storeProducts.getProductSubGroups(this.props.category_name);
                    } catch (error) {
                        
                    }
                } else {
                    try {
                        const subcatsList = await this.storeBooks.getSubCategories(this.props.category_name);
                        this.setState({
                            subcats_list: subcatsList
                        })
                    } catch (error) {
                        
                    }
                }
            }

            componentDidUpdate = async (prevProps) => {
                if (this.props.type === 'category') {
                    this.storeUi.setActiveSubCategory('');
                }

                // if (prevProps.cat_active !== this.storeUi.active_subcats) {
                //     const subcatList = await this.storeBooks.getSubCategories(this.props.cat_active);
                //     this.setState({
                //         subcats_list: subcatList
                //     })
                // }

                if (prevProps.category !== this.props.category) {
                    const subcatList = await this.storeBooks.getSubCategories(this.props.category);
                    this.setState({
                        subcats_list: subcatList,
                        subcats: 'loaded'
                    })
                }

                if (this.props.type === 'subcategory' 
                    && (this.state.subcats !== 'loaded') 
                    && this.props.cat_active == ''
                    && this.storeGeneral.shop == 'SHOP_ALL') {
                        const subCat = this.props.subcategory;
                        const mainCats = await this.storeUi.returnMainCategory('SHOP_ALL', subCat);
                        const mainCatId = parseInt(mainCats.main_cat);
                        const mainCat = await this.storeUi.getMainCategory('SHOP_ALL', mainCatId);
                        const subcatList = await this.storeBooks.getSubCategories(mainCat.category);
                        this.setState({
                            subcats_list: subcatList,
                            subcats: 'loaded'
                        })
                }
            }

            handleOnClickSubCat = async (e, subcat_name) => {
                this.storeCatalogs.setActiveSubCategory(subcat_name);

                this.storeUi.setActiveProductGroup('');
                this.storeUi.setActiveSubGroup('');
                this.storeUi.setActiveSubCategory(subcat_name);   
                this.storeCatalogs.setActiveSubCategory(subcat_name);  
                this.storeUi.setNavMobile(false);
                this.storeUi.setPageView('page', 1);
                
                let url = "/subcategorie/" + subcat_name;
                this.props.history.push(url);
            }

            handleOnClickSubGroup = async (e, subcat_name) => {
                // this.storeUi.setActiveProductGroup('');
                // this.storeUi.setActiveSubGroup('');
                this.storeUi.setActiveCategory('');  
                this.storeUi.setActiveSubCategory(''); 
                this.storeCatalogs.setActiveSubCategory('');    
                // this.storeUi.setNavMobile(false);
                // this.storeUi.setPageView('page', 1);
                
                // let url = "/products/" + subcat_name;
                // this.props.history.push(url);

                this.storeUi.setActiveSubGroup(subcat_name);    
                this.storeUi.setNavMobile(false);
                this.storeUi.setPageView('page', 1);
             
                let url = "/products/" + subcat_name;
                this.props.history.push(url);
            }

            render() {
                return (
                    <React.Fragment>
                        {this.state.subcats_list && this.state.subcats_list.length > 0 &&
                            <ul className="block--categories__subcats">
                                {this.state.subcats_list.map((subcat, i) => ( 
                                    <li 
                                        className={"block--categories__subcat" + (subcat.name == this.storeCatalogs.getActiveSubCategory() ? ' --active' : '')}
                                        onClick={(e) => this.handleOnClickSubCat(e, subcat.name)}
                                        dangerouslySetInnerHTML={{ __html: this.storeGeneral.shop === 'SHOP_ALL' ? subcat.display_value : subcat.name}}
                                    />
                                ))}
                            </ul>
                        }

                        {this.props.type === 'products' && this.storeProducts.productSubGroups.length > 0 &&
                            <ul className="block--categories__subcats">
                                {this.storeProducts.productSubGroups.map((subcat, i) => ( 
                                    <li 
                                        className={"block--categories__subcat" + (subcat.name == this.storeCatalogs.getActiveSubCategory() ? ' --active' : '')}
                                        onClick={(e) => this.handleOnClickSubGroup(e, subcat.name)}
                                        dangerouslySetInnerHTML={{ __html: this.storeGeneral.shop === 'SHOP_ALL' ? subcat.display_value : subcat.name}}
                                    />
                                ))}
                            </ul>
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(BlockCategory));

