import React from 'react';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const PageHeader = props => {
    const header = "page_headers." + props.text;
    const headerCustom = props.textCustom;
    const personalized = " van " + props.personalized;
    
    return (
        <div className="content-segment">
            <div className="content-segment__header">
                {(props.personalized && props.personalized !== '')
                    ?   <React.Fragment>
                            <h1>
                                {i18n.t(header, {from_person: personalized})}
                            </h1>
                            {props.subtitle &&
                                <span
                                    classsName="content-segment__header-subtitle" 
                                    dangerouslySetInnerHTML={{ __html: i18n.t(props.subtitle)}} 
                                />
                            }
                        </React.Fragment>
                    :   <React.Fragment>
                            {headerCustom && headerCustom !== ''
                            ?   <h1>{headerCustom}</h1> 
                            :   <h1 
                                    dangerouslySetInnerHTML={{ __html: i18n.t(header)}} 
                                />
                            
                            }
                            {props.subtitle &&
                                <span
                                    classsName="content-segment__header-subtitle" 
                                    dangerouslySetInnerHTML={{ __html: i18n.t(props.subtitle)}} 
                                />
                            }
                        </React.Fragment>
                }
            </div>
        </div>
    );
}

export default withTranslation()(PageHeader);