import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as events from '../communicator/events';

class StoreEvents {
    events = []
    event = {}

    constructor() {

    }

    async getEvents() {
        try {
            const returnData = await events.getEvents();
            runInAction(() => {
                this.events = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getEvent(ref) {
        try {
            const returnData = await events.getEvent(ref);
            runInAction(() => {
                this.event = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
}

decorate(StoreEvents, {
    events: observable,
    event: observable,
    getEvents: action,
    getEvent: action
})

export default StoreEvents;