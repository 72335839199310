import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import BlocksAuthors from '../components/blocks/blocks-authors';

const Authors = inject("stores") (
    observer (
        class Authors extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeBooks = this.props.stores.storeBooks;
            }
      
            render() {
                return (
                    <div className="content-wrapper">
                        <div className="content-wrapper__content">
                            Al ONZE AUTEURS

                            <p>xxx xxx</p>

                            <BlocksAuthors 
                                view = {"all"}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default Authors;
