import * as apifetch from './fetch.js';

export async function getUserDiscounts() {
    const urlAPI = process.env.REACT_APP_URL_API;
    const urlEndpoint = urlAPI + '/order/getUserDiscounts.php';

    try {
        let apiStr = urlEndpoint;
        let response = await fetch(apiStr, {
            method: 'GET',
            headers: new Headers({
    
            }),
        });
        let responseJson = await response.json();
        if(responseJson !== null) {
            let arrData = [];
            arrData = responseJson;

            return arrData;
        }
    } catch (error) {
        console.log('SbC fetch error categories: ', error.message);
    }
}
