import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import * as utilities from '../../utilities/formats';
import validate from '../../utilities/validation-rules';

import TextInput from '../../elements/form-input-text';
import i18next from 'i18next';
import TemplateCartProduct from '../../templates/template-cart-product';
import FeedbackOrderLineDiscount from '../feedback/feedback-orderline-discount';

const CartProduct = inject("stores") (
    observer (
        class CartProduct extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeDiscounts = this.props.stores.storeDiscounts;
                this.storeShipping = this.props.stores.storeShipping;

                this.state = {
                    formControls: { 
                        amount: {
                            value: this.props.product.amount,
                            valid: false,
                            validationRules: {
                                isInteger: true
                            }
                        }
                    },
                    publicationSB: false,
                    discount: 0,
                    discount_perc: 0,
                    discount_overruled: 0,
                    book: {}
                }
            }

            componentDidMount = async() => {
                (async() => await this.storeUi.getUserDiscounts())();
                (async() => await this.getProduct())();
               
                const userId = this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.user_id : 0;
                let discount = await this.calculateDiscount();

                this.calculateRowPrice(this.props.product.price);
                (async() => await this.calculateShipping() )();
            }

            componentDidUpdate = async(prevProps) => {
                let discount;
                const userId = this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.user_id : 0;

                if (prevProps.sku !== this.props.sku) {
                    (async() => await this.getProduct())();
                    
                    (async() => await this.calculateShipping() )();

                    if (this.props.discount_overruled === 1 || this.props.discount_overruled === '1') {
                        //let discounts_set = this.setDiscountOverruled();
                    }

                    this.calculateRowPrice(this.props.product.price);
                }

                if ((prevProps.discount_overruled !== this.props.discount_overruled)) {
                    if (this.props.discount_overruled === 1 || this.props.discount_overruled === '1') {
                        //let discounts_set = await this.setDiscountOverruled();
                        let discount = await this.calculateDiscount();
                        this.calculateRowPrice(this.props.product.price);
                    } else {
                        //SbC
                        let discount = await this.calculateDiscount();
                        this.storeOrder.saveShoppingCartToDB(this.storeOrder.order_data.client_id, this.storeOrder.cart.user);
                        
                        //SbC from 1 -> 0
                        this.setState({
                            discount_perc: 0,
                            discount_overruled: this.props.discount_overruled
                        }, () => this.calculateRowPrice(this.props.product.price))
                    }
                }

                if ((prevProps.reload !== this.props.reload) && this.storeUi.app_interface.reload === true ) {
                    (async() => await this.getProduct())();
                    
                    (async() => await this.calculateShipping() )();

                    this.storeUi.reloadOrderLines(false);

                    if (this.state.formControls.amount.value === null || this.state.formControls.amount.value === '') {
                        const name = 'amount';
                        const value = this.state.oldAmount;

                        this.handleChange(name, value);
                    }

                    this.calculateRowPrice(this.props.product.price);                    
                }

                if ((prevProps.amount !== this.props.amount)) {
                    const name = 'amount';
                    const value = this.props.amount;
                    (async() => await this.calculateDiscount())();

                    //SbC TEST
                    //this.storeOrder.saveShoppingCartToDB(this.storeOrder.order_data.client_id, this.storeOrder.cart.user);

                    const updatedControls = {
                        ...this.state.formControls
                    };

                    this.setState({
                        formControls: {
                            ...this.state.formControls,
                            [name]: {
                                ...updatedControls[name],
                                value
                            }
                        },
                        ['amount']: {
                            ...this.state.formControls['amount'],
                            value
                        }
                    }, async() => await this.calculateShipping())
                }

                if (prevProps.discount_perc !== this.props.discount_perc) {
                    // SbC CHECK DEPRICATED???
                    // this.setState({
                    //     discount_perc: this.props.discount_perc
                    // })
                    
                    //const value = this.props.amount;
                    (async() => await this.calculateDiscount())();
                    this.calculateRowPrice(this.props.product.price);
                    (async() => await this.calculateShipping() )();
                }
            }

            calculateShipping = async() => {
                let shipping;
                // SbC TO BE REPLACED BY store shipping
                //shipping = await this.storeOrder.calculateShipping(userId);

                let orderId = this.storeOrder.order_data.order_id;
                shipping = await this.storeShipping.calculateShipping(orderId);
            }

            setDiscountOverruled = async() => {
                // let cart_line = [];
                // const order_lines_data = await this.storeOrder.getOrderLinesData(this.storeOrder.order_data.order_id);

                // cart_line = order_lines_data
                //     .filter((order_line) => parseInt(order_line.book_id) === parseInt(this.props.product.id) || order_line.book_id === this.props.product.sku);

                
                // if (cart_line.length > 0) {
                //     const discount = cart_line[0] 
                //         ?   cart_line[0].discount 
                //         :   0;

                //     this.storeOrder.setDiscountOverruled(cart_line[0].book_id, cart_line[0].book_id, discount);
                //     this.setState({
                //         discount_overruled: discount
                //     }, async() => await this.calculateDiscount());
                // }
            }

            async getProduct() {
                let shop;
                let book;

                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    shop = 'SHOP_ALL';
                } else {
                    shop = 'SHOP_SB';
                }

                if (this.props.product.sku !== 0 && this.props.product.sku !== undefined) {
                    book = await this.storeBooks.returnBookDetailsBySku(shop, this.props.product.sku);
                } else {
                    book = {};
                }

                const name = 'amount';
                const value = this.props.product.amount;

                const updatedControls = {
                    ...this.state.formControls
                };

                this.setState({
                    book: book,
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                            ...updatedControls[name],
                            value
                        }
                    },
                    ['amount']: {
                        ...this.state.formControls['amount'],
                        value
                    }
                }, async() => book && await this.calculateDiscount());
            }

            calculateRowPrice = (unit_price) => {
                let total = 0;
                // let discount = (this.state.discount_applied) ? this.state.discount : 0;
                // //discount = (this.state.discount_applied_extra) ? this.state.discount_extra : discount;
                // discount = (this.state.discount_overruled) ? this.state.discount_overruled : discount;

                let calculatedDiscount = 0;

                if (this.storeAuth.user.is_loggedin) {
                    calculatedDiscount = this.state.discountPerc;
                }

                if (unit_price) {
                    let price = unit_price * this.state.formControls.amount.value * (1 - (calculatedDiscount / 100));
                    price = Math.round(price * 100) / 100;
                    total = utilities.formatPrice(price);
                    this.storeOrder.saveRowPrice(this.storeUser.user_data.is_loggedin, this.props.product.id, this.props.product.sku, total, calculatedDiscount);
                } else {
                    total = 'UNKNOWN';
                }

                return utilities.displayPrice(total);
            }

            calculateDiscount = async() => {
                const orderId = this.storeOrder.order_data.order_id;
                const calculatedDiscount = await this.storeDiscounts.calcItemDiscount(orderId, this.props.product);

                this.setState({
                    discount: calculatedDiscount,
                    discountPerc: calculatedDiscount,
                    discount_perc: calculatedDiscount
                });

                this.storeOrder.setDiscountOverruled(this.props.product.id, this.props.product.sku, calculatedDiscount);

                //TEST FOR DISAPPPEARING DISCOUNT
                this.storeOrder.saveShoppingCartToDB(this.storeOrder.order_data.client_id, this.storeOrder.cart.user);
            }

            displayPrice = (price) => {
                const total = utilities.formatPrice(price);
                return utilities.displayPrice(total);
            }

            handleBlur = event => {  
                if (this.state.formControls.amount.value === null || this.state.formControls.amount.value === '') {
                    this.storeUi.openDialogDelete(this.props.product);
                }
            }

            handleChange = (name, value) => {   
                const userId = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                (async() => await this.calculateDiscount())();

                if (parseInt(value) === 0 || parseInt(value) === '') {
                    this.setState({
                        oldAmount: this.state.formControls.amount.value
                    });
                    this.storeUi.openDialogDelete(this.props.product);
                } else {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls[name]
                    };
                    updatedFormElement.value = value;
                    updatedFormElement.touched = true;
                    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
            
                    updatedControls[name] = updatedFormElement;
        
                    if (updatedFormElement.valid) {
                        this.setState({
                            formControls: {
                                ...this.state.formControls,
                                [name]: {
                                    ...updatedControls[name],
                                    value
                                }
                            }
                        }, () => {
                            let discount;
                            for (let i = 0; i < this.storeOrder.cart.user.length; i++) {
                                if (this.props.sku === this.storeOrder.cart.user[i].sku) {
                                    discount = this.storeOrder.cart.user[i].discount;
                                }
                            } 

                            //SbC update cart DB and orderlines
                            (async() => await this.storeOrder.addToCart(this.storeGeneral.shop, userId, this.state.book, 'replace', value, discount) )();
                            
                            //this.storeOrder.calculateShipping(userId);
                            let orderId = this.storeOrder.order_data.order_id;
                            let shipping = this.storeShipping.calculateShipping(orderId);
                        });
                    }
                } 
            }

            render() {
                const book = this.state.book;

                return (
                    <li key={this.props.key} className="products-table__row">
                        <TemplateCartProduct
                            sku = {this.props.product.sku}
                            product = {this.props.product}
                            editable = {this.props.editable}
                            book = {book}
                        >
                            <div className="products-table__cell products-table__cell--price-wrapper">
                                <div className="products-table__cell products-table__cell--amount">
                                    <span className="amount">
                                        {book && this.displayPrice(book.price_incl)}
                                    </span>
                                </div>
                                <div className="products-table__cell products-table__cell--count">
                                    {this.props.editable === true
                                    ?   <TextInput 
                                            name = {'amount'} 
                                            placeholder = {this.state.formControls['amount'].placeholder}
                                            value = {this.state.formControls.amount.value}
                                            valid = {true}
                                            onChange = {(e) => this.handleChange(e.target.name, e.target.value)}
                                            onBlur = {this.handleBlur}
                                            className = {"--int-small"}
                                        />
                                    :   this.props.product.amount
                                    }
                                </div>
                                <div className="products-table__cell products-table__cell--amount"><span className="amount">{book && this.calculateRowPrice(book.price_incl)}</span></div>
                            </div>
                        </TemplateCartProduct>

                        {this.props.product.status !== 'deletedx' &&
                            <FeedbackOrderLineDiscount
                                sku = {this.props.sku}
                                amount = {this.state.formControls.amount.value}
                                discountPerc = {this.state.discountPerc}
                                discount = {this.state.discount ? this.state.discount : 0}
                                discount_overruled = {this.state.discount_overruled}
                                discount_perc = {this.state.discount_perc}             
                            />
                        }
                    </li>
                )
            }
        }
    )
)

export default CartProduct;
