import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { runInAction } from "mobx";

import FormCheckEmail from '../forms/form-check-email';
import FormProfile from '../forms/form-profile';
import FormPassword from '../forms/form-password';
import FormRetrievePassword from '../forms/form-retrieve-password';
import FormAddress from '../forms/form-address';
import FormBusiness from '../forms/form-business';

import Radio from '../../elements/form-input-radio';
import FormTextIncomplete from '../../elements/form-text-incomplete';

const CartProfile = inject("stores") (
    observer (
        class CartProfile extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeShipping = this.props.stores.storeShipping;

                this.state = {
                    btn_class: '--disabled',
                    login: false,
                    formControls: {
                        want_account: {
                            value: 0,
                            placeholder: 'Create account',
                            defaultValue: 1,
                            options: [
                                { value: 0, displayValue: 'Nee' },
                                { value: 1, displayValue: 'Ja' }
                            ]
                        },
                        diff_address: {
                            value: 0,
                            placeholder: 'Has delivery address?',
                            defaultValue: this.storeOrder.order_data.client_settings.has_delivery_address === true ? 1 : 0 ,
                            options: [
                                { value: 0, displayValue: 'Nee' },
                                { value: 1, displayValue: 'Ja' }
                            ]
                        },
                        is_business: {
                            value: 0,
                            placeholder: 'Is business?',
                            defaultValue: this.storeOrder.order_data.client_settings.is_business === true ? 1 : 0 ,
                            options: [
                                { value: 0, displayValue: 'Nee' },
                                { value: 1, displayValue: 'Ja' }
                            ]
                        }
                    },
                    address_card: this.storeOrder.order_data.client_settings.has_delivery_address,
                    business_card: this.storeUi.app_interface.use_case === "inorder" 
                        ?   this.storeOrder.order_data.client_settings.is_business 
                        :   false,
                    pw: '',
                    pw_card: (!this.storeUser.user_data.is_loggedin) ? true : false,
                    valid_pw: (this.storeUser.user_data.is_loggedin) ? true : false,
                    valid_address: this.storeOrder.order_data.client_settings.has_delivery_address ? this.storeOrder.order_data.client_settings.has_delivery_address : true,
                    valid_profile: false,
                    save_to_account: false,
                    emailAvailable: (this.storeUser.user_data.is_loggedin) ? true : false,
                    retrieve: false,
                    toggle: false,
                    email: '',
                    syncedEmail: '',
                    show_forms: (this.storeUser.user_data.is_loggedin) ? true : false,
                }
            }

            componentDidUpdate() {              
                if (this.storeUser.user_data.is_loggedin && !this.state.valid_pw) {
                    this.setState({
                        valid_pw: true
                    })
                }
            }
            
            componentWillUnmount() {
                //this.storeUi.setDirectLogin(false);
            }

            setCartView = async(view) => {
                this.storeAuth.setLoginState(true);

                //SbC prevent overwriting closed order when on multiple devices
                let order_availability = await this.storeOrder.getOrderAvailability();
                if (order_availability === 'closed') {
                   let order_id = await this.storeOrder.createNewOrder(this.storeOrder.order_data.client_id, this.storeOrder.order_data.order_remark);
                }

                //SbC TEST order id 0
                if (this.storeOrder.order_data.order_id === 0) {
                    let order_id = await this.storeOrder.createNewOrder(this.storeOrder.order_data.client_id, this.storeOrder.order_data.order_remark);
                }
                
                if (this.state.formIsValid) {
                    if (!this.storeOrder.order_data.client_settings.has_delivery_address) {
                        this.storeOrder.copyDeliveryAddresData();
                    }
                    if (this.storeOrder.order_data.client_settings.has_delivery_address && this.storeOrder.order_data.delivery_address.address === '') {
                        this.storeOrder.copyDeliveryAddresData();
                    }
                    
                    if (this.storeOrder.order_data.client_id !== 0) {
                        //SbC next line depricated -> already calculated??
                        //(async() => await this.storeOrder.calculateShipping() )();
                        (async() => await this.storeOrder.saveOrderDataProfile() )();
                    }

                    if (this.state.save_to_account) {
                        if (this.state.pw !== '' && this.state.pw !== undefined) {
                            (async() => await this.registerClientFromOrder() )();
                            //SbC nog niet voldoende, want haalt data daarna nog niet op in Mijn gegevens
                            //this.storeUser.setLoginState(true);
                        } else {
                            (async() => await this.registerAnonymousClient() )();
                        }
                        //(async() => await this.storeOrder.saveOrderDataProfile() )();
                    }
                    
                    if (!this.state.save_to_account && !this.storeUser.user_data.is_loggedin && this.storeOrder.order_data.client_id === 0) {
                        (async() => await this.registerAnonymousClient() )();
                        (async() => await this.storeOrder.saveOrderDataProfile() )();
                    }
                   
                    const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                    //SbC causes double orderline ... CHECK not anymore ??...
                    this.storeOrder.setCartView(id, view);
                    let url = "/shoppingcart/" + view;
                    this.props.history.push(url);
                }
            }

            registerClientFromOrder = async() => {
                const profile_data = {
                    email: this.storeOrder.order_data.client_profile['email'],
                    pw: this.state.pw,
                    firstname: this.storeOrder.order_data.client_profile['firstname'],
                    lastname: this.storeOrder.order_data.client_profile['lastname'],
                    telephone: this.storeOrder.order_data.client_profile['phonenr'],
                    address: this.storeOrder.order_data.client_profile['address'],
                    housenr: this.storeOrder.order_data.client_profile['housenr'],
                    addition: this.storeOrder.order_data.client_profile['addition'],
                    zipcode: this.storeOrder.order_data.client_profile['zipcode'],
                    city: this.storeOrder.order_data.client_profile['city'],
                    country: this.storeOrder.order_data.client_profile['country'],
                    country_code: this.storeOrder.order_data.client_profile['country_code']
                }
                await this.storeOrder.registerClientFromOrder(profile_data);
            }

            registerAnonymousClient = async() => {
                const profile_data = {
                    email: this.storeOrder.order_data.client_profile['email'],
                    pw: this.state.pw,
                    firstname: this.storeOrder.order_data.client_profile['firstname'],
                    lastname: this.storeOrder.order_data.client_profile['lastname'],
                    telephone: this.storeOrder.order_data.client_profile['phonenr'],
                    address: this.storeOrder.order_data.client_profile['address'],
                    housenr: this.storeOrder.order_data.client_profile['housenr'],
                    addition: this.storeOrder.order_data.client_profile['addition'],
                    zipcode: this.storeOrder.order_data.client_profile['zipcode'],
                    city: this.storeOrder.order_data.client_profile['city'],
                    country: this.storeOrder.order_data.client_profile['country'],
                    country_code: this.storeOrder.order_data.client_profile['country_code']
                }
                try {
                    let returnData = await this.storeOrder.registerAnonymousClient(profile_data);
                    runInAction(() => {
                        let data = returnData.data[0];
                        if (!('error' in returnData.data[0])) {
                            this.storeAuth.setLoginState(true);
                            this.storeUser.setLoginState(true);

                            this.storeOrder.setClientId(returnData.data[0].id, returnData.data[0]);

                            data.user_id = data.id;
                            data.is_loggedin = true;
                            
                            this.storeAuth.setAuth(data);
                            this.storeUser.setUserData(returnData.data[0]);
                            this.storeOrder.setOrderProfileFromUser(returnData.data[0]);

                            //SbC concat wishlists;
                            this.storeOrder.compareWishLists('concat');
                            //SbC concat shoppingcarts;
                            this.storeOrder.concatLSandDB();
                        } 
                    })
                } catch (error) {
                    runInAction(() => {
        
                    })
                }
            }

            gotoCart = () => {
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                this.storeOrder.setCartView(id, "client");
                this.storeUi.setUseCase("inshop"); 
            }

            toggleSection = () => {
                const url = '/shoppingcart/login';
                this.props.history.push(url);
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                this.storeOrder.setCartView(id, 'login');
            }

            toggleAddress = (event) => {
                const name = event.target.name;
                const value = event.target.value;
                let idx;

                this.setState({
                    formControls: {
                        ...this.state.formControls,
                        [name]: {
                        ...this.state.formControls[name],
                        value: value
                      }
                    }
                }, () => this.toggleAddressData()); 
            }

            toggleAddressData = async() => {
                this.storeOrder.setSettingDeliveryAddress(!this.state.address_card);
                this.storeOrder.resetDeliveryAddresData();

                this.setState({
                    toggle: !this.state.toggle,
                    address_card: !this.state.address_card,
                    valid_address: this.state.formControls.diff_address.value === 1 ? false : true
                }, () => this.checkForm()) 
            }

            toggleBusinessData = () => {
                this.storeOrder.setSettingBusiness(!this.state.business_card, this.storeUser.user_data.business)
                this.setState({
                    business_card: !this.state.business_card
                });
            }

            async checkEmail() {
                let current_email = this.storeOrder.order_data.client_profile.email;
                if (current_email != '') {
                //(async () => {
                    let emailAvailable = await this.storeUser.checkEmailExists(current_email);
                    this.setState({
                        emailAvailable: emailAvailable,
                        //formIsValid: emailAvailable ? this.state.formIsValid : false,
                        //btn_class: emailAvailable ? this.state.btn_class : '--disabled'
                    })
                //})
                }
            }

            async checkEmailProfile(email) {
                if (email != '') {
                    let emailAvailable = await this.storeUser.checkEmailExists(email);
                    this.setState({
                        emailAvailable: emailAvailable
                    })
                }
            }

            handleFormChange = (formControls, formIsValid) => {
                this.setState({
                    valid_profile: formIsValid,
                    country_code: formControls.country_select.value
                }, () => this.checkForm());

                this.storeOrder.setDataProfile(formControls);
                if (this.storeUser.user_data.is_loggedin) {
                    this.storeUser.changeUserProfile(formControls)
                }

                if (!this.storeUser.user_data.is_loggedin) {
                    this.checkEmail();
                }

                const userId = this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.user_id : 0;
                //this.storeOrder.calculateShipping(userId);

                let orderId = this.storeOrder.order_data.order_id;
                (async() => await this.storeShipping.calculateShipping(orderId) )();
            }

            handleFormChangeAddress = (formControls, formIsValid) => {
                this.storeOrder.setDataDeliveryAddress(formControls);
                const userId = this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.user_id : 0;
                
                //this.storeOrder.calculateShipping(userId);
                let orderId = this.storeOrder.order_data.order_id;
                (async() => await this.storeShipping.calculateShipping(orderId) )();

                this.setState({
                    valid_address: this.state.address_card ? (formIsValid ? true : false) : true,
                }, () => this.checkForm())
            }

            checkForm = () => {
                this.setState({
                    formIsValid: (this.state.valid_address && this.state.valid_profile && this.state.valid_pw) ? true : false, 
                    btn_class: (this.state.valid_address && this.state.valid_profile && this.state.valid_pw) ? '--active' : '--disabled'
                })
            }

            handleFormChangeBusiness = (formControls) => {
                this.storeOrder.setDataBusiness(formControls);
                this.storeUser.setDataBusiness(formControls);   

                if (this.storeUser.user_data.is_loggedin) {
                    this.storeUser.changeBusinessProfile(formControls)
                }
            }

            handleFormChangePassword = (formControls, formIsValid) => {
                this.setState({
                    pw: formControls.password.value,
                    valid_pw: formIsValid,
                    btn_class: (formIsValid && this.state.valid_profile) ?  '--active' : '--disabled'
                })
            }

            handleFormChangeCheck = (formControls, formIsValid) => {
                if (!this.storeUser.user_data.is_loggedin) {
                    this.checkEmailProfile(formControls.email.value);
                }
            }

            initPassword = (valid_pw) => {
                this.setState({
                    valid_pw: valid_pw ? this.state.valid_pw : false,
                    btn_class: valid_pw ? this.state.btn_class : '--disabled'
                })
            }
            
            rememberEmail = (email) => {
                this.setState({
                    email: email
                }, () => this.checkEmailProfile(email))
            }

            toggleRetrievePassword = (e) => {
                this.setState({
                    retrieve: !this.state.retrieve
                })
            }

            showFormProfile = (show) => {
                this.setState({
                    show_forms: show
                })
            }

            syncEmail = (value) => {
                this.setState({
                    syncedEmail: value
                })
            }

            render() {              
                return (
                    !this.state.retrieve 
                    ?   <div className="cart-data">
                            <div className="content-section--register --active">
                                <div className="form-wrapper">
                                    {!this.storeUser.user_data.is_loggedin &&
                                    <React.Fragment>
                                        <div className="form-wrapper__help">
                                            <span className="form-wrapper__help--required">{i18n.t("form_feedback.required")}</span>
                                        </div>
                                        <div className="form-fieldset">
                                            {this.storeUi.app_interface.login_direct
                                                ?   <div className="form-wrapper__row">
                                                        <span className="form__fieldset-title">Log in om je gegevens op te halen</span>
                                                    </div>
                                                :   <div className="form-wrapper__row">
                                                        <span className="form__fieldset-title">Vul je e-mailadres in</span>
                                                    </div>
                                            }
                                            <FormCheckEmail 
                                                rememberEmail = {this.rememberEmail}
                                                handleFormChange = {this.handleFormChangeCheck}
                                                showFormProfile = {this.showFormProfile}
                                                syncedEmail = {this.state.syncedEmail}
                                            />
                                            {((!this.state.emailAvailable && this.state.email !== '') || this.storeUi.app_interface.login_direct) &&
                                                <div className="form-wrapper__row">
                                                    <div className="form__alternative-actions">
                                                        <span className="link--read-more" onClick={(e) => this.toggleRetrievePassword(e)}>Ben je je wachtwoord vergeten?</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                    }

                                    {this.state.show_forms && 
                                        <React.Fragment>

                                            {(!this.storeUser.user_data.is_loggedin && this.state.emailAvailable && this.state.email !== '' && !this.storeUi.app_interface.login_direct) &&
                                            <React.Fragment>
                                                <div className="form-wrapper__help">
                                                    <span className="form-wrapper__help--required">{i18n.t("form_feedback.required")}</span>
                                                </div>
                                                <div className="form-fieldset">
                                                    {!this.state.login && !this.storeUser.user_data.is_loggedin &&
                                                        <React.Fragment>
                                                            <div className="form-wrapper__row">
                                                                <span className="form__fieldset-title">Maak zelf een wachtwoord aan</span>
                                                                {/* <span className="form__fieldset-subtitle">Hiermee kun je makkelijker winkelen en je bestellingen beheren.</span> */}
                                                            </div>                                          
                                                            <div className={"form-fieldset form-fieldset--password " + (this.state.pw_card ? "expanded" : "collapsed")}>
                                                                {this.state.emailAvailable 
                                                                ?   <FormPassword 
                                                                        initPassword = {this.initPassword}
                                                                        handleFormChange = {this.handleFormChangePassword}
                                                                        use_case = {"incart"}
                                                                        required = {true}
                                                                    />
                                                                :   <div className="form-wrapper">
                                                                        <form className="form">
                                                                            Het hierboven ingevulde e-mailadres is al in gebruik. Je kunt met dit e-mailadres geen nieuw account maken. Log eerst in met dit e-mailadres (rechtsboven) om je gegevens op te halen, of vul hierboven een ander e-mailadres in.
                                                                        </form>
                                                                    </div>
                                                                }
                                                            </div>
                                                    
                                                        </React.Fragment>
                                                    }     
                                                </div>
                                            </React.Fragment>
                                            }

                                            {(this.storeUser.user_data.is_loggedin || (this.state.emailAvailable && this.state.email !== '')) && !this.storeUi.app_interface.login_direct &&
                                            <React.Fragment>
                                                <div className="form-wrapper__help">
                                                    <span className="form-wrapper__help--required">{i18n.t("form_feedback.required")}</span>
                                                </div>

                                                <div className="form-fieldset">
                                                    <div className="form-wrapper__row">
                                                        <span className="form__fieldset-title">Vul je persoonsgegevens in</span>

                                                        {/* {this.storeUser.user_data.is_loggedin && this.storeUi.app_interface.use_case === "inorder" && */}
                                                        {this.storeUser.user_data.is_loggedin &&
                                                            <span className="form__fieldset-subtitle">Wijzigingen die je hier aanbrengt zullen ook in je profielgegevens aangepast worden.</span>
                                                        }
                                                    </div>
                                                    <FormProfile 
                                                        use_case = {"incart"}
                                                        view = {"cart"}
                                                        handleFormChange = {this.handleFormChange} 
                                                        client_profile = {this.storeOrder.order_data.client_profile}
                                                        loggedin = {this.storeUser.user_data.is_loggedin}
                                                        option_subscription = {true}
                                                        email = {this.state.email}
                                                        emailConnected = {true}
                                                        syncEmail = {this.syncEmail}
                                                        syncedEmail = {this.state.syncedEmail}
                                                    />
                                                </div>

                                                <div className="form-fieldset">
                                                    <div className="form-wrapper__row">
                                                        <span className="form__fieldset-title">Wil je een ander adres voor aflevering opgeven?</span>
                                                        <span className="form__fieldset-subtitle">Het is niet mogelijk een PostNL of DHL afhaalpunt op te geven</span>
                                                        <Radio 
                                                            name = "diff_address" 
                                                            placeholder = {this.state.formControls.diff_address.placeholder}
                                                            value = {this.state.formControls.diff_address.value}
                                                            options = {this.state.formControls.diff_address.options}
                                                            direction = {"horizontal"}
                                                            onChange = {this.toggleAddress}
                                                            defaultValue = {this.state.formControls.diff_address.defaultValue}
                                                        />
                                                    </div>
                                                    
                                                    <div className={"form-fieldset form-fieldset--address " + (this.state.address_card ? "expanded" : "collapsed")}>
                                                        <FormAddress 
                                                            use_case = {"incart"}
                                                            handleFormChange = {this.handleFormChangeAddress} 
                                                            toggle = {this.state.toggle}
                                                        />
                                                    </div> 
                                                </div>

                                                <div className="form-fieldset">
                                                    <div className="form-wrapper__row">
                                                        <span className="form__fieldset-title">Ben je een zakelijke klant?</span>
                                                        <span className="form__fieldset-subtitle">(dit o.a in verband met btw-uitsplitsing)</span>
                                                        <Radio 
                                                            name = "is_business" 
                                                            placeholder = {this.state.formControls.is_business.placeholder}
                                                            value = {this.state.formControls.is_business.value}
                                                            options = {this.state.formControls.is_business.options}
                                                            direction = {"horizontal"}
                                                            onChange = {this.toggleBusinessData}
                                                            defaultValue = {this.state.formControls.is_business.defaultValue}
                                                        />
                                                    </div>

                                                    <div className={"form-fieldset form-fieldset--address " + (this.state.business_card ? "expanded" : "collapsed")}>
                                                        <FormBusiness 
                                                            handleFormChange = {this.handleFormChangeBusiness} 
                                                            data = {this.storeUser.user_data.business}
                                                            country_code = {this.state.country_code}
                                                            is_business = {this.state.formControls.is_business.value}
                                                        />
                                                    </div>    
                                                </div>

                                                {!this.storeUi.app_interface.login_direct &&
                                                    (this.storeUser.user_data.is_loggedin && !this.state.login && !this.state.retrieve && this.state.btn_class !== '--active') &&
                                                        <FormTextIncomplete />
                                                }

                                                {this.state.show_forms &&
                                                    (!this.storeUser.user_data.is_loggedin && !this.state.login && !this.state.retrieve && this.state.btn_class !== '--active') &&
                                                        <FormTextIncomplete />
                                                }
                                            </React.Fragment>
                                            }

                                        </React.Fragment>
                                    }
                                </div>
                            </div>  
                            
                            {!this.state.login &&
                                <div className="cart__actions">
                                    <button className="btn btn--secundary" onClick={(e) => this.gotoCart()}>{i18n.t("button.backto_overview")}</button>
                                    {((this.storeUser.user_data.is_loggedin || (this.state.emailAvailable && this.state.email !== '')) && !this.storeUi.app_interface.login_direct) &&
                                        <button className={"btn btn--primary " + this.state.btn_class} onClick={(e) => this.setCartView("review")}>{i18n.t("button.goto_review")}</button>
                                    }
                                </div>
                            }


                            
                        </div>
                    :   <div className="cart-data">
                            <div className="content-section--register --active">
                                <div className="form-wrapper">
                                    <FormRetrievePassword 
                                        email = {this.state.email}
                                    />
                                    <div className="form__alternative-actions">
                                        <span className="link--back" onClick={(e) => this.toggleRetrievePassword(e)}>Terug</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CartProfile));
