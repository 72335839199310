import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

import Template from '../../templates/template';

const Address = inject("stores") (
    observer (
        class Address extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeBooks = this.props.stores.storeBooks;
            }

            componentDidMount() {
                window.scrollTo(0, 0)
            }
      
            render() {
                return (
                    <Template
                        type = {'default'}
                        header = {"address"}
                    >
                        <div className="content-segment">
                            <div className="content-segment__content">
                                <p><strong>Belfra Publishers for Success bv</strong><br/>
                                (Succesboeken.nl)</p>
                                <p>Dolderseweg 49<br/>
                                3734 BB Den Dolder</p>
                                <dl className="list--dl">
                                    <dt className="list--dl__dt">KvK:</dt>
                                    <dd className="list--dl__dd">30147850</dd>

                                    <dt className="list--dl__dt">Btw nr.:</dt>
                                    <dd className="list--dl__dd">8067.90.246.B01</dd>

                                    <dt className="list--dl__dt">E-mail:</dt>
                                    <dd className="list--dl__dd">info@succesboeken.nl</dd>

                                    <dt className="list--dl__dt">Tel.:</dt>
                                    <dd className="list--dl__dd">030 2285600</dd>
                                </dl>
                                <p>Succesboeken.nl is een handelsnaam van Belfra Publishers for Success bv te Den Dolder.</p>
                            </div>
                        </div> 
                    </Template>
                )
            }
        }
    )
)

export default Address;
