import React, { Component } from 'react';
import { runInAction, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import validate from '../../utilities/validation-rules';
import TextInput from '../../elements/form-input-text';

const FormPassword = inject("stores") (
    observer (
        class FormPassword extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    formControls: { 
                        password: {
                            type: "password",
                            label: this.props.pw_reset 
                                ?   i18n.t("forms.label.password_new") 
                                :   this.props.use_case === 'incart' 
                                    ?   i18n.t("forms.label.password_create")
                                    :   i18n.t("forms.label.password"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.password"),
                            touched: false,
                            validationRules: {
                                isRequired: true,
                                minLength: 5
                            },
                            validationMsg: "custom",
                            is_secret: true,
                            secret: true,
                            required: this.props.required ? true : false
                        },
                        password2: {
                            type: "password",
                            label: this.props.pw_reset ? i18n.t("forms.label.repeat_password_new") : i18n.t("forms.label.repeat_password"),
                            value: '',
                            placeholder: i18n.t("forms.placeholder.repeat_password"),
                            touched: false,
                            validationRules: {
                                isRequired: true,
                                minLength: 5
                            },
                            validationMsg: "custom",
                            is_secret: true,
                            secret: true,
                            required: this.props.required ? true : false
                        }
                    },
                    error: false,
                    error_pw: false
                }

                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
            }

            componentDidMount = () => {
                if (this.props.pw_reset) {
                    const password_current = {
                        label: i18n.t("forms.label.password_current"),
                        value: '',
                        placeholder: i18n.t("forms.placeholder.password_current"),
                        touched: false,
                        validationRules: {
                            isRequired: true,
                            minLength: 5
                        },
                        validationMsg: "custom",
                        is_secret: true,
                        secret: true,
                        required: false
                    };

                    const updatedControls = {
                        password_current: password_current,
                        ...this.state.formControls
                    }
                    this.setState({
                        formControls: {
                            ...updatedControls
                        }
                    });
                }

                let validInit = this.checkPasswords(this.state.formControls.password.value, this.state.formControls.password2.value)

                if (this.propsinitPassword) this.props.initPassword(validInit) ;
            }

            checkPasswordCurrent = async(pw) => {
                let valid = false;
                const user_id = this.storeUser.user_data.user_id;
                const user_email = this.storeUser.user_data.profile.email;
                
                valid = await this.storeUi.checkPassword(user_id, user_email, pw);

                return valid;
            }

            handleChange = event => {     
                const name = event.target.name;
                const value = event.target.value;
                let matchingPw;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                if (updatedFormElement.validationMsg === "custom") {
                    let validated = validate(value, updatedFormElement.validationRules, "msg");
                    updatedFormElement.valid = validated.value;
                    updatedFormElement.validationMsg = validated.msg;
                } else {
                    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
                }
                updatedControls[name] = updatedFormElement;

                //SbC check if passwords match
                if (updatedControls.password.value == '' && updatedControls.password2.value === '') {
                    matchingPw = true;
                } else {
                    matchingPw = this.checkPasswords(updatedControls.password.value, updatedControls.password2.value);
                }
                if (updatedControls.password.touched && updatedControls.password2.touched) {
                    this.setState({
                        error_pw: !matchingPw
                    });
                }

                //SbC check if form is valid
                let formIsValid = true;
                for (let inputIdentifier in updatedControls) {
                    if (updatedControls[inputIdentifier].required) {
                        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                    }
                }

                if ((formIsValid && !matchingPw) || !updatedControls.password.touched || !updatedControls.password2.touched) {
                    formIsValid = false;
                }
     
                this.setState({
                    formControls: updatedControls
                }, () => this.checkIfPwChangeRequired(updatedControls, formIsValid));
            }

            checkIfPwChangeRequired = async(updatedControls, formIsValid) => {
                let isValid = formIsValid;
                if (this.props.pw_reset) {
                    const pw_current = this.state.formControls.password_current.value;
                    const check_pw_current = await this.checkPasswordCurrent(pw_current);
                    isValid = (check_pw_current === 0) ? false : formIsValid;
                } 
                this.props.handleFormChange(updatedControls, isValid, 'password')
            }

            showSecret = (key) => {
                const name = key;
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.secret = !this.state.formControls[name].secret;
                updatedControls[name] = updatedFormElement;
               
                this.setState({
                    formControls: updatedControls
                }); 
            }

            checkPasswords = (pw1, pw2) => {
                let matching = (pw1 === pw2 && pw1 !== '' && pw2 !== '') ? true : false;
                return matching;
            }

            render() {

                return (
                    <div className="form-wrapper">
                        <form className="form">
                            {Object.keys(this.state.formControls).map((key, i) => (
                                <React.Fragment>
                                    <div className="form-row form-row--input" key={i}>
                                    <label 
                                        className={"form-label" + (this.state.formControls[key].required ? ' --required' : '')}
                                        dangerouslySetInnerHTML={{ __html: this.state.formControls[key].label}}
                                    />
                                        <TextInput 
                                            type = {this.state.formControls[key].type} 
                                            name = {key} 
                                            placeholder = {this.state.formControls[key].placeholder}
                                            value = {this.state.formControls[key].value}
                                            onChange = {this.handleChange}
                                            valid = {this.state.formControls[key].valid}
                                            touched = {this.state.formControls[key].touched}
                                            msg = {this.state.formControls[key].validationMsg}
                                            is_secret = {this.state.formControls[key].is_secret}
                                            secret = {this.state.formControls[key].secret ? true: false} 
                                            showSecret = {() => this.showSecret(key)}
                                        />
                                    </div>

                                    {key === "password2" && this.state.error_pw &&
                                        <span className="form-field--error --pw">De wachtwoorden zijn niet gelijk</span>
                                    }
                                </React.Fragment>
                            ))}

                            {this.state.error &&
                                <div className="form-row form-row--error">
                                    <p>Er is een fout opgetreden. Controleer je invoergegevens.</p>
                                </div>
                            }
                        </form>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(FormPassword);
