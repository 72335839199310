import * as apifetch from './fetch.js';

export async function getProductGroups() {
    const urlEndpoint = '/v2/products/groups';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getProductSubGroups(group_name) {
    const urlEndpoint = '/v2/products/' + group_name + '/subgroups';

    return apifetch.authFetch(urlEndpoint, 'GET');
}

export async function getProductsForCategory(product_cat, page_view, stock_priority) {
    const urlEndpoint = '/v2/products/list';

    const postData = {
        product_cat: product_cat,
        page_view: page_view,
        stock_priority: stock_priority
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getProductsForSubGroup(product_cat, subgroup, page_view, stock_priority) {
    const urlEndpoint = '/products/getProductsForSubGroup.php';

    const postData = {
        product_cat: product_cat,
        subgroup: subgroup,
        page_view: page_view,
        stock_priority: stock_priority
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getProductCountForCategory(product_cat) {
    const urlEndpoint = '/v2/products/count';

    const postData = {
        collection: 'product_group',
        product_cat: product_cat
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getProductCountForSubGroup(product_cat, subgroup) {
    const urlEndpoint = '/v2/products/count';

    const postData = {
        collection: 'product_subgroup',
        product_cat: product_cat,
        subgroup: subgroup
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function checkMainGroup(name) {
    const urlEndpoint = '/v2/products/checkmain';

    const postData = {
        name: name
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}

export async function getMainGroup(name) {
    const urlEndpoint = '/v2/products/main';

    const postData = {
        name: name
    }

    return apifetch.sbFetch(urlEndpoint, 'POST', postData);
}